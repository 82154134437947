import React from 'react'
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import RightIcon from '../../assets/icons/icon-outlined-directional-right-large.svg'
import BasicLayout from '../../layouts/basic'
import AppContext from '../../services/context'
//
const Notice = () => {
  const { appState } = React.useContext(AppContext)
  const data = appState.noticeDataList
  //
  return (
    <BasicLayout>
      <div className="page-headline">
        <Container>
          <h1>공지사항</h1>
        </Container>
      </div>
      <div className="page-content notices">
        <Container>
          <div>
            <Tabs className="tabs-secondary" selectedTabClassName="active">
              <TabList className="flex tab-list-secondary">
                <Tab className="tab-secondary px-8">전체</Tab>
                <Tab className="tab-secondary px-8">공지/이벤트</Tab>
                <Tab className="tab-secondary px-8">서비스</Tab>
                <Tab className="tab-secondary px-8">운영</Tab>
                <Tab className="tab-secondary px-8">기타</Tab>
                <div className="flex-grow" />
              </TabList>
              {data.all && (
                <TabPanel>
                  {data.all.map((item, index) => (
                    <NoticeListItem
                      key={index}
                      index={data.all.length - index}
                      item={item}
                    />
                  ))}
                  {data.all.length < 1 && <Empty />}
                </TabPanel>
              )}
              {data.notices && (
                <TabPanel>
                  {data.notices.map((item, index) => (
                    <NoticeListItem
                      key={index}
                      index={data.notices.length - index}
                      item={item}
                    />
                  ))}
                  {data.notices.length < 1 && <Empty />}
                </TabPanel>
              )}
              {data.services && (
                <TabPanel>
                  {data.services.map((item, index) => (
                    <NoticeListItem
                      key={index}
                      index={data.services.length - index}
                      item={item}
                    />
                  ))}
                  {data.services.length < 1 && <Empty />}
                </TabPanel>
              )}
              {data.operations && (
                <TabPanel>
                  {data.operations.map((item, index) => (
                    <NoticeListItem
                      key={index}
                      index={data.operations.length - index}
                      item={item}
                    />
                  ))}
                  {data.operations.length < 1 && <Empty />}
                </TabPanel>
              )}
              {data.others && (
                <TabPanel>
                  {data.others.map((item, index) => (
                    <NoticeListItem
                      key={index}
                      index={data.others.length - index}
                      item={item}
                    />
                  ))}
                  {data.others.length < 1 && <Empty />}
                </TabPanel>
              )}
            </Tabs>
          </div>
        </Container>
      </div>
    </BasicLayout>
  )
}

const NoticeListItem = ({ item, index }) => {
  const date = new Date(item.createdAt)
  return (
    <Link
      to={`/notice/${item.id}`}
      className="w-full flex flex-row notice-item"
    >
      <span className="hidden md:block index-col">{index}</span>
      <div className="flex flex-col flex-grow items-start md:flex-row">
        <span className="tag-col">
          <span className="tag">{item.tag}</span>
        </span>
        <span className="title-col flex-grow">{item.title}</span>
      </div>
      <span className="date-col">
        {`${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`}
      </span>
      <span className="hidden md:block icon-col">
        <img src={RightIcon} alt="target" />
      </span>
    </Link>
  )
}

const Empty = () => (
  <div className="empty">
    <p className="fir">등록된 공지사항이 없습니다</p>
  </div>
)

export default Notice
