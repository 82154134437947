import React from 'react'
import { useWatch, useFormContext } from 'react-hook-form'

const SelectDiv = ({
  className = '', selected, onClick, children,
}) => {
  const selectedColor = selected ? 'border-main text-main' : 'border-line text-textSecondary'
  return (
    <div className={`${className} flex items-center justify-center md:flex-1 h-12 rounded cursor-pointer ${selectedColor}`} style={{ borderWidth: 1 }} onClick={onClick}>
      {children}
    </div>
  )
}
const LengthSelector = () => {
  const { setValue, getValues, ...methods } = useFormContext()

  const onClick = (value) => {
    setValue('ad_campaign_length', value)
  }
  //
  const ad_campaign_length_value = useWatch({
    name: 'ad_campaign_length',
    control: methods.control,
  })
  //
  return (
    <div
      className="create-ad-part mmd:mb-0"
    >
      <h3 className="xs:text-base mb-[20px]">희망하는 광고 영상의 길이를 선택해주세요</h3>
      <div className="flex flex-col md:flex-row py-15">
        <SelectDiv selected={ad_campaign_length_value === 15} onClick={() => onClick(15)}><span className="mt-[-3px]">15초</span></SelectDiv>
        <SelectDiv selected={ad_campaign_length_value === 30} onClick={() => onClick(30)} className="mt-[16px] md:ml-[48px] md:mt-0"><span className="mt-[-3px]">30초</span></SelectDiv>
      </div>
    </div>
  )
}

export default LengthSelector
