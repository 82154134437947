import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'

import { TwoButtonModal } from '../components/modals'

const useAuthModal = (query = {}) => {
  const [visible, setVisible] = React.useState(false)
  const history = useHistory()
  const location = useLocation()

  const open = React.useCallback(() => {
    setVisible(true)
  }, [])

  const onClickLogin = () => {
    const previous = encodeURIComponent(location.pathname)
    const obj = { previous, ...query }
    const strQuery = qs.stringify(obj)
    history.push(`/sign-in?${strQuery}`)
  }

  const renderAuthModal = (content) => visible && (
    <TwoButtonModal
      title="로그인이 필요합니다"
      primaryText="로그인"
      secondaryText="취소"
      onClickPrimary={onClickLogin}
      onClickSecondary={() => {
        setVisible(false)
      }}
      onClose={() => {
        setVisible(false)
      }}
    >
      {content && <p className="text-lg mb-0 whitespace-pre-wrap text-center">{content}</p>}
    </TwoButtonModal>
  )

  return { open, renderAuthModal }
}

export default useAuthModal
