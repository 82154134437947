import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { useForm, FormProvider } from 'react-hook-form'
import { useSnackbar } from 'notistack'

import BasicLayout from '../../layouts/basic'
import targetRight from '../../assets/icons/icon-outlined-directional-right-large.svg'
import { FindId } from '../../services/api'
import IdentityCheckPopupGroup from './components/identityCheckPopupGroup'
import naverIcon from '../../assets/icons/icon-login-naver.svg'
import kakaoIcon from '../../assets/icons/icon-login-kakao.svg'
import googleIcon from '../../assets/icons/icon-login-google.svg'
//
const methodObj = {
  google: {
    name: '구글',
    icon: googleIcon,
  },
  kakao: {
    name: '카카오',
    icon: kakaoIcon,
  },
  naver: {
    name: '네이버',
    icon: naverIcon,
  },
}
const RecoverID = () => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const methods = useForm({ criteriaMode: 'all', mode: 'onSubmit' })
  const [state, setState] = React.useState({
    findIdDisabled: true,
    showResult: false,
    userId: '',
    signUpMethod: 'email',
  })
  //
  const onIdentityCheck = React.useCallback(() => {
    setState({ ...state, findIdDisabled: false })
  }, [state])
  //
  const onSubmit = React.useCallback(async () => {
    const identityData = await methods.getValues('identity_check')
    const result = await FindId(identityData)
    if (!result) {
      if (result.code === 'NOT_EXIST_MEMBER') {
        enqueueSnackbar('회원 정보가 존재하지 않습니다.', { variant: 'error' })
        return
      }
    }
    setState({
      ...state,
      showResult: true,
      userId: result.userId,
      signUpMethod: result.method,
    })
  }, [state, methods, enqueueSnackbar])
  //
  return (
    <BasicLayout>
      <Container className="auth-paper">
        <div className="flex flex-col justify-items-stretch items-center">
          <div className="header text-center">
            <h1>아이디 찾기</h1>
            <p>
              <span>본인 인증 완료 후 아이디를 찾을 수 있습니다</span>
            </p>
          </div>
          <div className="content" style={{ minWidth: '50%' }}>
            {/**/}
            {state.showResult ? (
              <>
                <p className="result-title text-center">
                  회원님의 아이디 입니다
                </p>
                {state.signUpMethod && state.signUpMethod === 'email' ? (
                  <p className="result-info text-center">{state.userId}</p>
                ) : (
                  <div className="flex flex-row items-center justify-center result-info">
                    <img
                      src={methodObj[state.signUpMethod]?.icon}
                      alt="error"
                    />
                    <p className="text-center ml-2">
                      {methodObj[state.signUpMethod]?.name}
                      로 가입한 회원입니다
                    </p>
                  </div>
                )}

                <button
                  className="w-full recover-action primary large"
                  onClick={() => history.push('/sign-in')}
                >
                  로그인 하기
                </button>
                <div className="w-full flex justify-between bottom-wrapper">
                  <span>비밀번호를 잊으셨나요?</span>
                  <Link
                    style={{ color: 'var(--text-secondary)' }}
                    className="target right-icon"
                    to="/recover-password"
                  >
                    비밀번호 찾기
                    <img src={targetRight} alt="target-right" />
                  </Link>
                </div>
              </>
            ) : (
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <IdentityCheckPopupGroup onIdentityCheck={onIdentityCheck} />
                  <button
                    className="w-full recover-action primary large"
                    disabled={state.findIdDisabled}
                  >
                    아이디 찾기
                  </button>
                </form>
              </FormProvider>
            )}
          </div>
        </div>
      </Container>
    </BasicLayout>
  )
}

export default RecoverID
