import React from 'react'
import { useFormContext } from 'react-hook-form'
import InputValidation from './inputValidation'

const FormUploadBox = ({
  name, validators, children, actionName, onUpload, reversed, accept, ...props
}) => {
  const hiddenFieldName = `x_${name}_${actionName}`
  const ref = React.useRef(null)
  const [state, setState] = React.useState({
    validated: !validators?.required,
    value: '',
  })
  const methods = useFormContext()
  React.useEffect(() => {
    if (methods.getValues('x_clientBusinessDocs_url')) {
      setState((prev) => ({ ...prev, validated: true, value: methods.getValues('x_clientBusinessDocs_url') }))
    }
  }, [])
  //
  const { onChange, ...inputProps } = methods.register(name)
  const { ...hiddenInputProps } = methods.register(hiddenFieldName, {
    validate: { [actionName]: () => state.validated },
  })
  const inputClasses = ['flex-grow', 'textinput-with-button']
  if (props.className) inputClasses.push(props.className)
  //
  const onInputChange = async (e) => {
    await onChange(e)
    if (!e.target.files[0]) {
      await setState({ ...state, value: '' })
      return
    }
    if (!onUpload) return

    methods.trigger(name)
    const result = await onUpload(e.target.files[0])
    if (result === false) return
    await setState({
      ...state,
      value: e.target.files[0].name,
      validated: !!result,
    })
    await methods.setValue(hiddenFieldName, result, { shouldValidate: true })
  }
  //
  const onButtonClick = async (e) => {
    e.preventDefault()
    await ref.current.click()
  }
  //
  return (
    <div className="textbox-action flex flex-row">
      {reversed && (
        <>
          <button className="light large reversed" onClick={onButtonClick}>
            {children}
          </button>
          <input {...props} value={state.value} className={inputClasses.join(' ')} disabled />
          <input type="hidden" {...hiddenInputProps} />
          <input
            type="file"
            style={{ display: 'none' }}
            {...inputProps}
            accept={accept}
            ref={ref}
            onChange={onInputChange}
          />
        </>
      )}
      {!reversed && (
        <>
          <input {...props} value={state.value} className={inputClasses.join(' ')} disabled />
          <input type="hidden" {...hiddenInputProps} />
          <input
            type="file"
            style={{ display: 'none' }}
            {...inputProps}
            accept={accept}
            ref={ref}
            onChange={onInputChange}
          />
          <button className="light large" onClick={onButtonClick}>
            {children}
          </button>
        </>
      )}
    </div>
  )
}

export const FormUploadBoxGroup = ({
  validators,
  validations,
  name,
  label,
  actionName,
  groupName,
  children,
  onUpload,
  accept,
  ...props
}) => {
  const labelClasses = []
  if (validators?.required) labelClasses.push('required')
  //
  return (
    <>
      <label htmlFor={name} className={labelClasses.join(' ')}>
        {label}
      </label>
      <FormUploadBox
        className="larger"
        name={name}
        validators={validators}
        actionName={actionName}
        onUpload={onUpload}
        accept={accept}
        {...props}
      >
        {children}
      </FormUploadBox>
      <InputValidation groupName={groupName} names={[name, `x_${name}_${actionName}`]} validations={validations} />
    </>
  )
}

export default FormUploadBox
