import React from 'react'
import { useFormContext } from 'react-hook-form'
import { PASS_AUTH_URL } from '../../../services/config'
import { FormPopupGroup } from '../../../components/popup'
import { getIdentityCheckHash } from '../../../services/api'
//
const form_el = document.createElement('form')
const encode_data_el = document.createElement('input')
const m_el = document.createElement('input')
//
const IdentityCheckPopupGroup = ({ onIdentityCheck }) => {
  const methods = useFormContext()
  //
  const updateIdentityCheckForm = (hash) => {
    form_el.setAttribute('method', 'POST')
    form_el.setAttribute('action', PASS_AUTH_URL)
    form_el.setAttribute('target', 'identity_check')
    //
    encode_data_el.setAttribute('type', 'hidden')
    encode_data_el.setAttribute('name', 'EncodeData')
    encode_data_el.setAttribute('value', hash)
    m_el.setAttribute('type', 'hidden')
    m_el.setAttribute('name', 'm')
    m_el.setAttribute('value', 'checkplusService')
    //
    form_el.append(encode_data_el)
    form_el.append(m_el)
  }
  //
  const onInit = async () => {
    const result = await getIdentityCheckHash()
    return result
  }
  //
  const onEvent = async (event, res) => {
    if (event === 'OPENED') {
      updateIdentityCheckForm(res.preValue)
      document.body.append(form_el)
      form_el.submit()
      // document.body.remove(form_el)
    } else if (event === 'SUCCESS') {
      await methods.setValue('full_name', res.name.replace(/\+/g, ' '), {
        shouldValidate: true,
      })
      await methods.setValue('phone_no', res.mobile, { shouldValidate: true })
      if (onIdentityCheck) {
        await onIdentityCheck()
      }
    }
  }
  //
  return (
    <FormPopupGroup
      name="identity_check"
      label="본인 인증"
      onInit={onInit}
      onEvent={onEvent}
      validators={{ required: true }}
      validations={['required']}
    >
      본인 인증하기
    </FormPopupGroup>
  )
}

export default IdentityCheckPopupGroup
