import React from 'react'
import imgActionButton from '../assets/icons/fab.svg'

const FloatingActionButton = ({ src, onClick }) => {
  const imgSrc = src || imgActionButton
  return (
    <div className="skb-fab" onClick={onClick}>
      <img src={imgSrc} alt="question_mark" />
    </div>
  )
}

export default FloatingActionButton
