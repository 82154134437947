import React, { useMemo } from 'react'
import cx from 'classnames'
import { useCookies } from 'react-cookie'
import { useQuery } from 'react-query'
import _ from 'lodash'

import { getAddresses, requestRecommendData } from '../../../services/api'
import Slider from '../../../components/slider'
import { formatNumber, roundNumber, getFullCityName } from '../../../services/utils'

import arrowDownIcon from '../../../assets/icons/icon-chevron-down.svg'
import star1st from '../../../assets/icons/star-1st@2x.png'
import star2nd from '../../../assets/icons/star-2nd@2x.png'
import star3rd from '../../../assets/icons/star-3rd@2x.png'
import RecommendLoading from './recommendLoading'
//
const RecommendMapSlider = ({ onChangeRadius, distance }) => {
  const [radius, setRadius] = React.useState(1000)
  const value = useMemo(() => {
    if (distance === 'depth3') {
      return ((radius - 500) / 500)
    }
    if (distance === 'depth2') {
      switch (radius) {
          case 3000:
            return 1
          case 5000:
            return 2
          case 7000:
            return 3
          case 10000:
            return 4
          default:
            return 1
      }
    } else {
      switch (radius) {
          case 10000:
            return 1
          case 15000:
            return 2
          case 20000:
            return 3
          case 25000:
            return 4
          case 30000:
            return 5
          default:
            return 1
      }
    }
  }, [distance, radius])

  const setValue = (val) => {
    let tempRadius
    if (distance === 'depth3') {
      tempRadius = val * 500 + 500
    } else if (distance === 'depth2') {
      switch (val) {
          case 1:
            tempRadius = 3000
            break
          case 2:
            tempRadius = 5000
            break
          case 3:
            tempRadius = 7000
            break
          case 4:
            tempRadius = 10000
            break
          default:
            tempRadius = 3000
      }
    } else {
      switch (val) {
          case 1:
            tempRadius = 10000
            break
          case 2:
            tempRadius = 15000
            break
          case 3:
            tempRadius = 20000
            break
          case 4:
            tempRadius = 25000
            break
          case 5:
            tempRadius = 30000
            break
          default:
            tempRadius = 10000
      }
    }
    setRadius(tempRadius)
    onChangeRadius(tempRadius)
  }
  const getLabel = (val) => {
    let km = 0
    if (distance === 'depth3') {
      km = ((val * 500 + 500) / 1000).toFixed(1)
    } else if (distance === 'depth2') {
      km = val === 1 ? 3 : 10
    } else {
      km = val === 1 ? 10 : 30
    }
    return `${km}km`
  }
  return (
    <Slider
      className={cx(
        'absolute bottom-5 right-2 w-1/2 z-20',
        'mlg:bottom-2 mlg:right-unset mlg:w-[95%]',
        'mlg:left-1/2 mlg:transform mlg:-translate-x-1/2',
      )}
      value={value}
      step={distance === 'depth2' ? 4 : 5}
      setValue={setValue}
      getLabel={getLabel}
    />
  )
}

//
const RecommendMap = ({
  step, form, handleRecommendCities, onClickPrev, onClickNext, onClickClose,
}) => {
  const [cookies] = useCookies(['jwt_token'])

  const mapWrapperRef = React.useRef(null)
  const mapRef = React.useRef(null)
  const markersRef = React.useRef([])
  const centerMarkerRef = React.useRef(null)
  const circleRef = React.useRef(null)

  // const [radius, setRadius] = React.useState(1000) // km 단위
  const [cities, setCities] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [selectedCities, setSelectedCities] = React.useState([])
  const [currentForm, setCurrentForm] = React.useState(null)
  const [requestForm, setRequestForm] = React.useState(null)
  const [strRadius, setStrRadius] = React.useState(0)
  const [open, setOpen] = React.useState(false)
  //
  const { isFetching, data: recommendResult } = useQuery({
    queryKey: ['recommend', requestForm],
    queryFn: () => requestRecommendData(cookies.jwt_token, requestForm),
    enabled: !!requestForm,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5분
  })
  const recommendRequestId = recommendResult?.recommendRequestId
  const aiRecommend = recommendResult?.aiRecommend
  const recommendCities = recommendResult?.recommend
  const totalCitiesCount = recommendResult?.totalCount || 0

  React.useEffect(() => {
    if (recommendCities) {
      setCities(recommendCities)
    }
  }, [recommendCities])
  //
  const getMarkerCx = (selected) => cx(
    'flex justify-center py-2 rounded-lg mlg:w-fit mlg:px-2',
    {
      'bg-main': selected,
      'bg-white border-[2px] border-main': !selected,
    },
  )

  const getLabelCx = (selected) => cx(
    'text-sm font-bold whitespace-nowrap mlg:text-xs',
    {
      'text-white': selected,
      'text-textPrimary': !selected,
    },
  )
  //
  const getRecommendCities = React.useCallback((save, radius) => {
    if (!currentForm) return
    if (circleRef.current) {
      circleRef.current.setMap(null)
    }
    const { lat, lon } = currentForm
    circleRef.current = new window.Tmapv2.Circle({
      center: new window.Tmapv2.LatLng(lat, lon),
      radius,
      fillColor: '#ff0000',
      fillOpacity: 0.2,
      strokeColor: '#ff0000',
      strokeWeight: 1,
      strokeOpacity: 0.5,
      map: mapRef.current,
    })
    let zoom = 10
    if (radius <= 1500) {
      zoom = 14
    } else if (radius <= 3000) {
      zoom = 13
    } else if (radius <= 5000) {
      zoom = 12
    } else if (radius <= 10000) {
      zoom = 11
    }
    mapRef.current.setZoom(zoom)
    setRequestForm({
      ...currentForm,
      radius,
      save,
    })
  }, [currentForm, cookies.jwt_token])
  //
  React.useEffect(() => {
    if (currentForm && isLoaded) {
      const tempRadius = currentForm?.distance === 'depth3' ? 1000 : currentForm?.distance === 'depth2' ? 3000 : 25000
      getRecommendCities(true, tempRadius)
      setStrRadius(tempRadius / 1000)
    }
  }, [currentForm, isLoaded, getRecommendCities])
  //
  React.useEffect(() => {
    if (!form) return
    if (!currentForm || !_.isEqual(currentForm, form)) {
      setCurrentForm(form)
    }

    const { lat, lon } = form
    const center = new window.Tmapv2.LatLng(lat, lon)
    const markerOptions = {
      position: center,
      animation: window.Tmapv2.MarkerOptions.DROP,
    }
    if (mapRef.current) {
      mapRef.current.setCenter(center)
      if (centerMarkerRef.current) {
        centerMarkerRef.current.setPosition(center)
      } else {
        centerMarkerRef.current = new window.Tmapv2.Marker({
          ...markerOptions,
          map: mapRef.current,
        })
      }
      return
    }
    setTimeout(() => {
      mapRef.current = new window.Tmapv2.Map(mapWrapperRef.current, {
        width: '100%',
        height: '400px',
        center,
        zoom: 15,
      })
      mapRef.current.setZoomLimit(10, 19)
      if (centerMarkerRef.current) {
        centerMarkerRef.current.setMap(mapRef.current)
        centerMarkerRef.current.setPosition(center)
      } else {
        centerMarkerRef.current = new window.Tmapv2.Marker({
          ...markerOptions,
          map: mapRef.current,
        })
      }
      setIsLoaded(true)
    }, 500)
  }, [form])
  //
  React.useEffect(() => {
    if (!mapRef.current || cities.length === 0) return
    const newArr = []
    cities.forEach((city) => {
      if (!city.location?.coordinates) return
      const isSelected = selectedCities.some((selectedCity) => selectedCity._id === city._id)
      const iconHTML = `
      <div class="${getMarkerCx(isSelected)}">
        <span class="${getLabelCx(isSelected)}">${city.name}</span>
      </div>
    `
      const [lon, lat] = city.location.coordinates
      const position = new window.Tmapv2.LatLng(lat, lon)
      const found = markersRef.current.some((marker) => {
        if (marker.getPosition().equals(position)) {
          marker.setIconHTML(iconHTML)
          newArr.push(marker)
          return true
        }
        return false
      })
      if (found) return

      const size = city.name.length * 14
      newArr.push(new window.Tmapv2.Marker({
        position,
        map: mapRef.current,
        title: city.name,
        animation: window.Tmapv2.MarkerOptions.ANIMATE_FADEIN,
        iconSize: new window.Tmapv2.Size(size, 36),
        iconHTML,
      }))
    })
    markersRef.current.forEach((marker) => {
      if (!newArr.includes(marker)) {
        marker.setMap(null)
      }
    })
    markersRef.current = newArr
  }, [cities, selectedCities])
  //
  const onChangeRadius = React.useCallback((radius) => {
    if (currentForm) {
      getRecommendCities(false, radius)
      if (radius === 1500 || radius === 2500) {
        setStrRadius(`${(radius / 1000).toFixed(1)}`)
      } else {
        setStrRadius(`${radius / 1000}`)
      }
    }
  }, [currentForm, getRecommendCities])
  //
  const onClickCity = React.useCallback((city) => {
    const index = selectedCities.findIndex((c) => c._id === city._id)
    if (index === -1) {
      setSelectedCities([...selectedCities, city])
    } else {
      setSelectedCities(selectedCities.filter((c) => c._id !== city._id))
    }
  }, [selectedCities])
  //
  const wrapperCx = cx('recommend-map', {
    hidden: step !== 1,
    'a maps': step === 1,
  })
  const selectedCitiesInRadius = selectedCities.filter((city) => cities.some((c) => c._id === city._id))
  //
  const onClickDone = React.useCallback(async () => {
    if (selectedCitiesInRadius.length === 0) return
    const d1AddressFullNames = []
    const fullCityNames = selectedCitiesInRadius.map((city) => {
      let arr = []
      const d1AddressFullName = getFullCityName(city.name.split(' ')[0])
      if (!d1AddressFullNames.includes(d1AddressFullName)) {
        d1AddressFullNames.push(d1AddressFullName)
      }
      arr.push(d1AddressFullName)
      arr = arr.concat(city.name.split(' ').slice(1))
      return arr.join(' ')
    })
    const addressResult = await getAddresses()
    if (addressResult === false) return
    const searchAddresss = []
    addressResult.forEach((d1_itemData) => {
      if (d1AddressFullNames.includes(d1_itemData.name)) {
        searchAddresss.push(d1_itemData)
      }
    })
    const result = []
    searchAddresss.forEach((d1_itemData) => {
      d1_itemData.children.forEach((d2_itemData) => {
        const d2_address = `${d1_itemData.name} ${d2_itemData.name}`
        if (fullCityNames.includes(d2_address)) {
          result.push({
            key: d2_itemData.id,
            value: d2_address,
            depth: 2,
          })
        }
        d2_itemData.children.forEach((d3_itemData) => {
          const d3_address = `${d2_address} ${d3_itemData.name}`
          if (fullCityNames.includes(d3_address)) {
            result.push({
              key: d3_itemData.id,
              value: d3_address,
              depth: 3,
            })
          }
        })
      })
    })
    handleRecommendCities(recommendRequestId, result)
    onClickClose()
    // else onClickNext()
  }, [selectedCities, recommendRequestId, handleRecommendCities, onClickClose, onClickNext])

  const getComment = React.useCallback((city) => {
    if (!city) return ''
    // const srtCategory = RECOMMEND_FORM.category.find((c) => c.value === currentForm.category)?.label
    // return comment.replace(/업종/g, `${srtCategory} 업종`)
    const liCx = 'text-[14px] mlg:text-xs'
    const spanCx = 'ml-2 font-medium text-textSecondary'
    const tempAgeArr = []
    city.ageRatio.forEach((ratio, i) => {
      if (i < 1) return
      if (i >= 7) {
        tempAgeArr[5] += ratio
      } else {
        tempAgeArr.push(ratio)
      }
    })
    const strAge = tempAgeArr.map((ratio, i) => {
      const age = i * 10 + 10
      return `${age}대${i === 5 ? ' 이상' : ''} (${Math.round(ratio * 100)}%)`
    }).join(', ')

    const strCategoryPreference = city.categoryPreference > 2 ? '상' : city.categoryPreference > 1 ? '중' : '하'

    return (
      <ul className="flex flex-col gap-1 ml-4 text-textPrimary list-disc">
        <li className={liCx}>
          전체 인구수
          <span className={spanCx}>
            약
            {' '}
            {formatNumber(roundNumber(city.population))}
            명
          </span>
        </li>
        <li className={liCx}>
          전체 가구수
          <span className={spanCx}>
            약
            {' '}
            {formatNumber(roundNumber(city.householdCount))}
          </span>
        </li>
        <li className={liCx}>
          성비
          <span className={spanCx}>
            남성
            {city.maleRatio}
            %, 여성
            {city.femaleRatio}
            %
          </span>
        </li>
        <li className={liCx}>
          연령대별 인구비율
          <span className={spanCx}>{strAge}</span>
        </li>
        <li className={liCx}>
          가구당 연평균 소득
          <span className={spanCx}>
            약
            {' '}
            {formatNumber(roundNumber(city.salary))}
            만원
          </span>
        </li>
        <li className={liCx}>
          {`500세대 이상 대단지 아파트 (${city.apartmentCount})`}
          <span className={spanCx}>{city.apartment.join(',')}</span>
        </li>
        <li className={liCx}>
          매장과의 거리
          <span className={spanCx}>
            {city.distance < 1000 ? `${roundNumber(city.distance)}m` : `${(city.distance / 1000).toFixed(2)}km`}
          </span>
        </li>
        <li className={liCx}>
          업종 관심도
          <span className={spanCx}>{strCategoryPreference}</span>
        </li>
      </ul>
    )
  }, [currentForm])

  const reportData = React.useMemo(() => {
    const obj = {
      household: 0,
      salary: 0,
    }
    if (!cities || cities.length === 0) {
      return obj
    }
    cities.forEach((city) => {
      obj.household += city.householdCount
      obj.salary += city.salary
    })
    obj.salary = Math.round(obj.salary / cities.length)
    return obj
  }, [cities])

  const strAiRecommend = React.useMemo(() => {
    if (!aiRecommend) return ''
    return aiRecommend?.map((city) => city.regionName).join(' > ')
  }, [aiRecommend])

  const strRecommend = React.useMemo(() => {
    if (!recommendCities) return ''
    return recommendCities?.filter((data, i) => i < 3)?.map((city) => city.regionName).join(' > ')
  }, [recommendCities])

  return (
    <div className={wrapperCx}>
      <h1 className="mb-4 text-[28px] leading-10 text-textPrimary font-bold text-center whitespace-pre-wrap mlg:text-[18px] mlg:leading-7">
        {'지역 현황 및 상권 특성을\n종합적으로 분석한 광고 적합 지역을 확인하세요.'}
      </h1>
      <div className="relative min-h-[400px] mb-12" ref={mapWrapperRef}>
        <RecommendMapSlider onChangeRadius={onChangeRadius} distance={currentForm?.distance} />
      </div>
      <div className="flex flex-col">
        <h2 className="self-center text-[24px] text-textPrimary font-bold">상권분석 요약</h2>
        <div className="flex flex-col text-[18px] text-textPrimary divide-y divide-[#d8d8d8] border border-[#d8d8d8]">
          <div className="flex h-[66px] bg-[#f0f0f0] divide-x divide-[#d8d8d8]">
            <div className="flex-1 flex flex-center mlg:text-sm">AI 상권분석 기반 추천</div>
            <div className="flex-1 flex flex-center mlg:text-sm">광고주 선택 기반 추천</div>
          </div>
          <div className="flex h-[66px] divide-x divide-[#d8d8d8]">
            <div className="flex-1 flex flex-center mlg:text-sm">{strAiRecommend}</div>
            <div className="flex-1 flex flex-center mlg:text-sm">{strRecommend}</div>
          </div>
        </div>
        <div className="my-6 px-[45px] py-[32px] bg-[#fbfbfb] mlg:p-[20px]">
          <p className="whitespace-pre-wrap">
            {'고객님이 선택하신 반경 '}
            <span className="text-[#491bc7] font-bold">{`${strRadius}km 내에 총 ${totalCitiesCount}개의`}</span>
            {' 행정동에 광고가능 가구수 합계는 '}
            <span className="text-[#491bc7] font-bold">{`${formatNumber(roundNumber(reportData.household))} 가구`}</span>
            {'입니다.\n가구당 연평균 소득은 '}
            <span className="text-[#491bc7] font-bold">{`${formatNumber(roundNumber(reportData.salary))} 만원`}</span>
            {' 입니다.'}
          </p>
          <p>
            {'AI가 고객 분석 결과 해당 지역 소비자의 업종의 관심도는 '}
            <span className="text-[#491bc7] font-bold">{`${strAiRecommend}`}</span>
            {' 순으로 나타났으며,\n고객님이 설정하신 로직에 따른 지역은 '}
            <span className="text-[#491bc7] font-bold">{`${strRecommend}`}</span>
            의 순 입니다.
          </p>
          <p>아래 각 데이터를 비교하셔서 광고 지역을 줄이시거나 광고 지역 반경을 조정하시어 다른 범위로 광고 지역을 추천 받으실 수 있습니다.</p>
        </div>
        <h2 className="self-center mt-10 text-[24px] text-textPrimary font-bold">매장 주변 광고지역 추천</h2>
      </div>
      <span className="ml-2 text-sm lg:hidden text-textTertiary">※ 추천 순으로 나열되었습니다</span>
      <table className="table-fixed w-full mt-2">
        <thead>
          <tr className="bg-[#f0f0f0]">
            <th className="w-[55px]">선택</th>
            <th className="w-[180px] mlg:w-[120px]">지역</th>
            <th>상권 평가</th>
          </tr>
        </thead>
        <tbody>
          {cities.filter((val, i) => i < 10).map((city, index) => (index >= 5 && !open ? null : (
            <React.Fragment key={city.code}>
              <tr className="cursor-pointer" onClick={() => onClickCity(city)}>
                <td className="relative w-[35px]">
                  <label className="checkbox" htmlFor={`${city.code}_checkbox`} style={{ paddingLeft: 0 }}>
                    <input
                      type="checkbox"
                      id={`${city.code}_checkbox`}
                      checked={selectedCities.some(
                        (c) => c.code === city.code,
                      )}
                      readOnly
                    />
                    <span className="checkmark !left-1" />
                  </label>
                  <div className="absolute inset-0" />
                </td>
                <td className="text-[14px] font-semibold">
                  <div className="flex flex-col items-center mlg:text-xs">
                    <img
                      className={cx('inline-block mr-2', city.score >= 80 ? 'w-[120px] mlg:w-[80px]' : city.score >= 60 ? 'w-[96px] mlg:w-[64px]' : 'w-[72px] mlg:w-[48px]')}
                      src={city.score >= 80 ? star1st : city.score >= 60 ? star2nd : star3rd}
                      alt="별"
                    />
                    {city.name}
                  </div>
                </td>
                <td className="text-start whitespace-pre-wrap py-2">
                  {getComment(city)}
                </td>
              </tr>
            </React.Fragment>
          )))}
        </tbody>
      </table>
      {cities.length > 3 && (
        <button className="flex items-center mx-auto text-textTertiary" onClick={() => setOpen((prev) => !prev)}>
          <img className={open ? '!mx-0 transform rotate-180' : '!mx-0'} src={arrowDownIcon} alt="화살표" />
          {' '}
          {open ? '추천지역 접기' : '추천지역 더보기'}
        </button>
      )}
      {cities.length === 0 && (
        <div className="flex flex-center border pt-8 pb-6">
          <p className="whitespace-pre-wrap text-center text-lg">{'범위 내 조회된 내역이 없습니다.\n지도에서 검색 범위를 더 넓혀주세요.'}</p>
        </div>
      )}
      <div className="flex justify-center space-x-4 mt-12 mlg:flex-col mlg:space-y-4 mlg:space-x-0">
        <button type="button" className="secondary min-w-[100px]" onClick={onClickPrev}>
          조건 다시 선택
        </button>
        {/* <button
          type="button"
          className="secondary font-bold min-w-[240px]"
          onClick={() => history.push('/consult')}
        >
          전화 상담하기
        </button> */}
        <button
          type="button"
          className="primary font-bold min-w-[240px]"
          onClick={() => onClickDone(true)}
          disabled={selectedCitiesInRadius.length === 0}
        >
          선택지역 확정
        </button>
      </div>
      {isFetching && <RecommendLoading />}
    </div>
  )
}

export default RecommendMap
