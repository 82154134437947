import React, { useEffect } from 'react'
import { Tooltip, OverlayTrigger, Accordion } from 'react-bootstrap'
import { useWatch, useFormContext } from 'react-hook-form'
import purify from 'dompurify'

import AppContext from '../../../services/context'
import { formatNumber, formatDate } from '../../../services/utils'
import SelectCoupons from './selectCoupons'
import { FormActionTextboxGroup } from '../../../components/formActionTextbox'
import { FormRadiobox } from '../../../components/radio'
import { ContentModal } from '../../../components/modals'
import InputValidation from '../../../components/inputValidation'
import Checkbox, { FormCheckbox } from '../../../components/checkbox'
import { text } from '../../../terms'
import { calcRegions } from '../../../services/api'
import InfoIcon from '../../../assets/icons/icon-filled-suggested-question-circle.svg'
import targetRight from '../../../assets/icons/icon-outlined-directional-right-medium.svg'
import Spinner1 from '../../../assets/icons/loading-spinner.svg'
import icKakaoPay from '../../../assets/icons/ic-kakaopay.svg'
import icNaver from '../../../assets/icons/ic-naver.png'
// import icCard from '../../../assets/icons/ic-card.png'
import ic11pay from '../../../assets/icons/ic-11pay.png'

const CampaignPaymentView = ({
  type, meta = {}, openGiftModal, noPayment = false,
}) => {
  const { appState } = React.useContext(AppContext)
  const isFranchise = appState?.userInfo?.company?.companyType === 2
  //
  React.useEffect(() => {
    // register values with validations...
  }, [])
  //
  return (
    <div className="media-details-section">
      {(type === 'requested' || type === 'omycompany') && <RequestedContentsSection meta={meta} />}
      {type === 'auto' && <AutoContentsSection />}
      <div className="block w-full lg:hidden section-divider" />
      {meta?.id?.includes('ohmycompany') ? (
        <>
          <CalcSection meta={meta} />
          <div className="block w-full lg:hidden section-divider" />
          <PaymentMethodSection only11Pay />
          <div className="block w-full lg:hidden section-divider" />
          <TCSection />
        </>
      ) : !appState.adCampaign?.ad_campaign_is_paid ? (
        <>
          {!noPayment && (
            <>
              <PointCouponSection meta={meta} />
              <div className="block w-full lg:hidden section-divider" />
            </>
          )}
          <CalcSection meta={meta} />
          <div className="block w-full lg:hidden section-divider" />
          <GiftSection openGiftModal={openGiftModal} />
          {!noPayment && (
            <>
              <div className="block w-full lg:hidden section-divider" />
              <PaymentMethodSection only11Pay={isFranchise} />
              <div className="block w-full lg:hidden section-divider" />
              <TCSection />
            </>
          )}
        </>
      ) : null}
    </div>
  )
}

const RequestedContentsSection = ({ meta }) => {
  const contentRef = React.useRef()

  React.useEffect(() => {
    const contents = purify.sanitize(meta.description)
    contentRef.current.innerHTML = contents
  }, [contentRef, meta.description])

  return (
    <section className="info-section">
      <h3 className="title">상세 정보</h3>
      <div className="divider-tertiary" style={{ margin: 0 }} />
      <div className="white-holder-2 flex flex-row">
        <p className="subtitle">{meta.title}</p>
      </div>

      <div className="white-holder-2 flex flex-row">
        <span>{meta.subTitle}</span>
      </div>
      <div ref={contentRef} className="white-holder-2 description pt-0" style={{ whiteSpace: 'pre-wrap' }}>
        {meta.description}
      </div>
      <div className="gray-holder flex flex-row justify-between items-center">
        <span>{meta?.id?.includes('ohmycompany') ? '제작비용' : '예산 금액'}</span>
        <span>{`${formatNumber(meta.amount)} 원`}</span>
      </div>
    </section>
  )
}

const AutoContentsSection = () => {
  const { appState } = React.useContext(AppContext)
  const { setValue, getValues, ...methods } = useFormContext()
  const countRef = React.useRef(null)
  const adCampaign = appState.adCampaign.step3
  const [countLoading, setCountLoading] = React.useState(false)
  //
  const ad_campaign_regions_count_value = useWatch({
    name: 'ad_campaign_regions_count',
    control: methods.contorl,
  })
  //
  React.useEffect(() => {
    (async () => {
      if (adCampaign.ad_campaign_regions === 0) {
        countRef.current.textContent = 0
        return
      }
      await setCountLoading(true)
      const result = (await calcRegions(adCampaign.ad_campaign_regions)).data || 0
      const count_value = await getValues('ad_campaign_regions_count')
      await setCountLoading(false)
      if (count_value === result) return
      await setValue('ad_campaign_regions_count', result)
    })()
  }, [getValues, setValue, adCampaign.ad_campaign_regions])
  // const [regionCount, setRegionsCount] = React.useState(0)
  //
  return (
    <section className="info-section">
      <h3 className="title">상세 정보</h3>
      <div className="divider-tertiary" style={{ margin: 0 }} />
      <div className="white-holder-2 flex flex-row">
        <span>{adCampaign.ad_campaign_name || '광고 이름'}</span>
      </div>
      <div className="white-holder-2 flex flex-row">
        <span>{adCampaign.ad_campaign_asset?.assetName || '소재 이름'}</span>
      </div>
      <div className="content-holder " style={{ maxWidth: 600 }}>
        <video
          className="media w-full"
          controls
          controlsList="nodownload"
          onContextMenu={(e) => e.preventDefault()}
          src={adCampaign.ad_campaign_asset?.vidoeUrl}
        />
      </div>
      {adCampaign.ad_campaign_product?.name && (
        <div className="white-holder-2 flex flex-col">
          <span className="label">추가 옵션</span>
          <span className="value">{adCampaign.ad_campaign_product?.name}</span>
        </div>
      )}
      <div className="white-holder-2 flex flex-col">
        <span className="label">선택한 지역</span>
        <div className="white-holder-4">
          {(adCampaign.ad_campaign_regions || []).map((item, index) => (
            <span key={index}>{item.value}</span>
          ))}
        </div>
      </div>
      <div className="white-holder-3 flex flex-col">
        <span className="label">광고 기간</span>
        <div>
          {adCampaign.ad_campaign_dates?.length === 2 && (
            <span className="value">
              {`${formatDate(adCampaign.ad_campaign_dates[0])} ~ ${formatDate(adCampaign.ad_campaign_dates[1])} (${
                adCampaign.ad_campaign_period
              }일)`}
            </span>
          )}
        </div>
      </div>
      <div className="gray-holder flex flex-row justify-between items-center">
        <span>예산 금액</span>
        <span>{`${formatNumber(adCampaign.ad_campaign_budget)} 원`}</span>
      </div>
      <div className="flex flex-row white-holder">
        <div className="flex-grow">
          <span>광고 대상 수</span>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 250 }}
            trigger={['hover', 'focus', 'click']}
            overlay={(
              <Tooltip>
                {
                  '선택하신 지역에서 광고가 노출될 수 있는 전체 모수를 의미합니다.\n예를 들어 광고 대상 수가 1,000명이면 1,000명의 총 시청가능 가구를 대상으로 광고를 노출시킵니다.'
                }
              </Tooltip>
            )}
          >
            <button type="button">
              <img src={InfoIcon} alt="info" />
            </button>
          </OverlayTrigger>
        </div>
        <span style={{ color: 'var(--text-primary)' }}>
          {!countLoading && (
            <>
              <span ref={countRef}>{formatNumber(ad_campaign_regions_count_value)}</span>
              <span>&nbsp;+</span>
            </>
          )}
          {countLoading && <img src={Spinner1} alt="spinner" className="spinner" hight="30px" width="30px" />}
        </span>
      </div>

      <div className="flex flex-row white-holder">
        <div className="flex-grow">
          <span>광고 노출 횟수</span>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 250 }}
            trigger={['hover', 'focus', 'click']}
            overlay={
              <Tooltip>15초 기준 입력하신 예산에 따라 광고가 노출될수 있는 가능한 총 노출 수를 의미합니다.</Tooltip>
            }
          >
            <button type="button">
              <img src={InfoIcon} alt="info" />
            </button>
          </OverlayTrigger>
        </div>
        <span style={{ color: 'var(--text-primary)' }}>{formatNumber(adCampaign.ad_campaign_target_impression)}</span>
        <span>&nbsp;회</span>
      </div>
    </section>
  )
}

const PointCouponSection = ({ meta }) => {
  const methods = useFormContext()
  const { appState } = React.useContext(AppContext)
  const point = appState.userInfo?.point || 0
  //
  const ad_campaign_point_current_value = useWatch({
    name: 'ad_campaign_point_current_val',
    contorl: methods.control,
  })
  const ad_campaign_budget_value = useWatch({
    name: 'ad_campaign_budget',
    contorl: methods.control,
  })
  const ad_campaign_discount_method = useWatch({
    name: 'ad_campaign_discount_method',
    contorl: methods.control,
  })
  const onSelectPanel = (key) => {
    methods.setValue('ad_campaign_discount_method', key)
  }
  const onClickRadio = (e) => {
    e.preventDefault()
  }

  const onClickPoint = React.useCallback(() => {
    const editedPoint = Number(ad_campaign_point_current_value)
    if (Number.isNaN(editedPoint) || editedPoint < 0) {
      methods.setValue('ad_campaign_point_val', 0)
      methods.setValue('ad_campaign_point_current_val', 0)
      return
    }
    if (editedPoint > point) {
      methods.setValue('ad_campaign_point_val', point)
      methods.setValue('ad_campaign_point_current_val', point)
    } else {
      methods.setValue('ad_campaign_point_val', editedPoint)
    }
  }, [point, methods, ad_campaign_point_current_value])
  //

  return (
    <section className="coupon-point-section">
      <h3 className="title">포인트 및 쿠폰</h3>
      <div className="divider-tertiary" style={{ margin: 0 }} />
      <div className="note">
        <span style={{ marginBottom: 8 }}>꼭 알아두세요!</span>
        <span style={{ color: 'var(--text-secondary)' }}>• 포인트와 쿠폰은 중복 사용이 불가능합니다</span>
      </div>
      <div className="holder-1">
        <Accordion defaultActiveKey={null} activeKey={ad_campaign_discount_method} onSelect={onSelectPanel}>
          <Accordion.Item eventKey="point">
            <Accordion.Header>
              <FormRadiobox name="ad_campaign_discount_method" value="point" onChange={onClickRadio}>
                포인트
              </FormRadiobox>
            </Accordion.Header>
            <Accordion.Body>
              <div className="white-holder-5 flex flex-row">
                <span className="bold-text flex-grow">보유 포인트</span>
                <span className="bold-text">{formatNumber(point)}</span>
                <span className="gray-text">&nbsp;포인트</span>
              </div>
              <div style={{ marginBottom: 16 }}>
                <span className="bold-text">사용 포인트</span>
              </div>
              <FormActionTextboxGroup
                name="ad_campaign_point_current_val"
                className="use_point larger"
                type="number"
                min="0"
                onClick={onClickPoint}
                enableButton
              >
                사용
              </FormActionTextboxGroup>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="coupon">
            <Accordion.Header>
              <FormRadiobox name="ad_campaign_discount_method" value="coupon" onChange={onClickRadio}>
                쿠폰
              </FormRadiobox>
            </Accordion.Header>
            <Accordion.Body>
              <div style={{ marginBottom: 16 }}>
                <span className="bold-text">보유 쿠폰</span>
              </div>
              <SelectCoupons name="ad_campaign_discount" budget={meta?.amount || ad_campaign_budget_value} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </section>
  )
}

const CalcSection = ({ meta }) => {
  const { setValue, ...methods } = useFormContext()
  const { appState } = React.useContext(AppContext)
  const [vat, setVat] = React.useState(0)
  //
  const ad_campaign_budget_value = useWatch({
    name: 'ad_campaign_budget',
    contorl: methods.control,
  })
  const ad_campaign_discount_value = useWatch({
    name: 'ad_campaign_discount',
    contorl: methods.control,
  })
  const ad_campaign_point = useWatch({
    name: 'ad_campaign_point_val',
    contorl: methods.control,
  })
  const ad_campaign_discount_method = useWatch({
    name: 'ad_campaign_discount_method',
    contorl: methods.control,
  })
  const ad_campaign_cost_value = useWatch({
    name: 'ad_campaign_cost',
    contorl: methods.control,
  })
  const franchise_budget_value = useWatch({
    name: 'franchise_budget',
    contorl: methods.control,
  })
  const isFranchise = appState.userInfo.company?.companyType === 2 && franchise_budget_value
  const franchise_headoffice_budget = isFranchise ? ad_campaign_budget_value - (franchise_budget_value || 0) : 0

  const budget = meta?.amount || ad_campaign_budget_value
  const agency_discount_ratio = React.useMemo(() => {
    if (appState.userInfo?.company?.companyFees === null || appState.userInfo?.company?.companyFees === undefined) {
      return 10
    }
    return appState.userInfo?.company?.companyFees
  }, [appState.userInfo?.company?.companyFees])
  const ad_campaign_agency_discount_value = typeof meta?.agencyDiscount === 'number' ? meta.agencyDiscount : (budget * (agency_discount_ratio / 100))
  //
  React.useEffect(() => {
    (async () => {
      if (meta?.id === 'thedream_2406') {
        await setValue('ad_campaign_cost', 0)
        return
      }
      const discount = ad_campaign_discount_method === 'point' ? ad_campaign_point : ad_campaign_discount_value
      let cost = budget - (discount || 0) - franchise_headoffice_budget
      if (cost < 0) cost = 0
      if (Number(appState.userInfo?.userType) === 1) cost -= ad_campaign_agency_discount_value
      const tempVat = Math.round(cost * 0.1)
      setVat(tempVat)
      cost += tempVat
      await setValue('ad_campaign_cost', cost)
    })()
  }, [
    budget,
    meta?.amount,
    appState.userInfo?.userType,
    ad_campaign_budget_value,
    ad_campaign_discount_value,
    ad_campaign_discount_method,
    ad_campaign_point,
    ad_campaign_agency_discount_value,
    franchise_budget_value,
    setVat,
    setValue,
  ])
  //
  return (
    <section className="calc-section">
      <h3 className="title">결제 금액</h3>
      <div className="divider-tertiary" style={{ margin: 0 }} />
      <div className="white-holder-2 flex flex-row justify-between">
        <span className="gray-text">예산 금액</span>
        <span className="bold-text" style={{ color: 'var(--text-secondary)' }}>
          {`${formatNumber(budget)} 원`}
        </span>
      </div>
      {meta?.id === 'thedream_2406' ? (
        <div className="white-holder-2 flex flex-row justify-between">
          <span className="gray-text">프로모션 혜택</span>
          <span className="bold-text" style={{ color: 'var(--primary-color)' }}>
            {`- ${formatNumber(200000)} 원`}
          </span>
        </div>
      ) : (
        <>
          <div className="white-holder-2 flex flex-row justify-between">
            <span className="gray-text">포인트 및 쿠폰</span>
            <span className="bold-text" style={{ color: 'var(--primary-color)' }}>
              {`- ${formatNumber(
                ad_campaign_discount_method === 'point' ? ad_campaign_point : ad_campaign_discount_value,
              )} 원`}
            </span>
          </div>
          {!meta?.id?.includes('ohmycompany') && Number(appState.userInfo?.userType) === 1 && (
            <div className="white-holder-2 flex flex-row justify-between">
              <span className="gray-text">에이전시 수수료</span>
              <span className="bold-text" style={{ color: 'var(--primary-color)' }}>
                {`- ${formatNumber(ad_campaign_agency_discount_value)} 원`}
              </span>
            </div>
          )}
          {appState.userInfo.company?.companyType === 2 && franchise_budget_value && (
            <div className="white-holder-2 flex flex-row justify-between">
              <span className="gray-text">본사부담금</span>
              <span className="bold-text" style={{ color: 'var(--primary-color)' }}>
                {`- ${formatNumber(budget - franchise_budget_value)} 원`}
              </span>
            </div>
          )}
          <div className="white-holder-2 flex flex-row justify-between">
            <span className="gray-text">부가가치세</span>
            <span className="bold-text" style={{ color: 'var(--text-secondary)' }}>
              {formatNumber(vat)}
            </span>
          </div>
        </>
      )}
      {/**/}
      <div className="orange-holder flex flex-row justify-between items-center">
        <span>최종 결제 금액</span>
        <span>{`${formatNumber(ad_campaign_cost_value)} 원`}</span>
      </div>
    </section>
  )
}

const GiftSection = ({ openGiftModal }) => {
  //
  const onSelectGift = () => {
    openGiftModal()
  }
  //
  return (
    <section className="payment-method-section">
      <h3 className="title">추가 상품 선택</h3>
      <div className="divider-tertiary" style={{ margin: 0 }} />
      <h4 className="mt-4 ml-5 lg:ml-[60px]">우리가게 위치 등록 서비스(무료)</h4>
      <div className="note !pb-3">
        <p className="text-textPrimary leading-7 whitespace-pre-wrap">
          {
            'Tmap, T전화, 내비게이션 등 20여개 지도 서비스에 우리가게 위치를 등록 대행해 드리는 서비스 입니다.\n우리가게 홍보에 꼭 필요한 서비스이나 개인이 직접 등록시 놓치기 쉽고 시간이 많이 소요됩니다.\nBtv우리동네광고 청약고객에게만 특별히 제공되는 대행 서비스이오니, 꼭 신청해 보세요\n(※ 등록 신청 후 반영까지 최대 4주 소요 됩니다)'
          }
        </p>
      </div>
      <div className="flex items-center mt-6">
        <h4 className="text-textPrimary text-lg pl-5 pr-4 mb-0 lg:pl-[60px]">비용</h4>
        <span className="">무료</span>
      </div>
      <div className="flex items-center mt-6">
        <h4 className="text-textPrimary text-lg pl-5 pr-8 lg:pl-[60px]">신청 여부</h4>
        <div className="flex space-x-4">
          <FormRadiobox className="font-[500]" name="ad_campaign_gift" value="true" onSelect={onSelectGift}>
            신청
          </FormRadiobox>
          <FormRadiobox className="font-[500]" name="ad_campaign_gift" value="false">
            미신청
          </FormRadiobox>
        </div>
      </div>
      <div className="white-holder-2 checkbox flex space-x-4">
        <FormCheckbox name="p_info_consent">개인(법인) 정보 수집 및 제 3자 제공 동의</FormCheckbox>
        <a
          style={{ cursor: 'pointer', padding: 0, marginTop: -2 }}
          className="smaller right-icon"
          target="_blank"
          rel="noopener noreferrer"
          href="https://skb-public.s3.ap-northeast-2.amazonaws.com/personal_information_vass_service_231024.pdf"
        >
          자세히
          <img src={targetRight} alt="target" />
        </a>
      </div>
    </section>
  )
}

const PaymentMethodSection = ({ only11Pay = false }) => {
  const { appState } = React.useContext(AppContext)
  const { paymentInfo } = appState
  const methods = useFormContext()
  //
  const ad_campaign_payment_method_value = useWatch({
    name: 'ad_campaign_payment_method',
    contorl: methods.contorl,
  })
  //
  useEffect(() => {
    if (only11Pay) {
      methods.setValue('ad_campaign_payment_method', 'skpay')
    }
  }, [only11Pay])
  //
  const onPaymentMethodSelect = async (e, method) => {
    e.preventDefault()
    await methods.setValue('ad_campaign_payment_method', method)
  }
  //
  const onClickPaymentText = async () => {
    const tempText = window.prompt('결제 테스트를 위한 코드를 입력하세요')
    if (tempText === process.env.REACT_APP_BTV_PAYMENT_TEST_CODE) {
      await methods.setValue('ad_campaign_cost', 100, { shouldValidate: true })
    }
  }
  //
  return (
    <section className="payment-method-section">
      <h3 className="title" onDoubleClick={onClickPaymentText}>
        결제 수단
      </h3>
      <div className="divider-tertiary" style={{ margin: 0 }} />
      <div className="actions flex flex-row items-start">
        {!only11Pay && (
          <button
            className={`large${ad_campaign_payment_method_value === 'bankbook' ? ' secondary' : ''} mlg:!text-xs`}
            onClick={(e) => onPaymentMethodSelect(e, 'bankbook')}
          >
            무통장 입금
          </button>
        )}
        <div className="flex flex-col items-center mr-4">
          <button
            className={`large${ad_campaign_payment_method_value === 'skpay' ? ' secondary' : ''} !mr-0 mlg:!text-xs`}
            onClick={(e) => onPaymentMethodSelect(e, 'skpay')}
          >
            신용카드
          </button>
          <img className="mt-2 w-11" src={ic11pay} alt="11-pay" />
        </div>
        <div className="flex flex-col items-center">
          <button
            className={`large${ad_campaign_payment_method_value === 'inicis' ? ' secondary' : ''} !mr-0 mlg:!text-xs`}
            onClick={(e) => onPaymentMethodSelect(e, 'inicis')}
          >
            간편결제
          </button>
          <div className="mt-2 flex gap-2">
            <img className="w-10" src={icKakaoPay} alt="kakao-pay" />
            <img className="w-10" src={icNaver} alt="naver-pay" />
          </div>
        </div>
      </div>
      {ad_campaign_payment_method_value !== '' && (
        <div className="note">
          {ad_campaign_payment_method_value === 'bankbook' && paymentInfo !== null && (
            <>
              <span style={{ marginBottom: 8 }}>꼭 알아두세요!</span>
              <span>{`• ${paymentInfo.data?.bank}`}</span>
              <span>{`• ${paymentInfo.data?.account}`}</span>
              <span>{`• ${paymentInfo.data?.name}`}</span>
            </>
          )}
          {ad_campaign_payment_method_value === 'skpay' && (
            <>
              <span style={{ marginBottom: 8 }}>꼭 알아두세요!</span>
              <span>{`• ${paymentInfo.data?.skpay[0]}`}</span>
              <span>{`• ${paymentInfo.data?.skpay[1]}`}</span>
              <span>{`• ${paymentInfo.data?.skpay[2]}`}</span>
            </>
          )}
          {ad_campaign_payment_method_value === 'inicis' && (
            <>
              <span style={{ marginBottom: 8 }}>꼭 알아두세요!</span>
              <span>{`• ${paymentInfo.data?.inicis[0]}`}</span>
              <span>{`• ${paymentInfo.data?.inicis[1]}`}</span>
              <span>{`• ${paymentInfo.data?.inicis[2]}`}</span>
            </>
          )}
        </div>
      )}
    </section>
  )
}

const TCSection = () => {
  const toggleAllRef = React.useRef(null)
  const methods = useFormContext()
  const [state, setState] = React.useState(false)
  //
  const refresh = async () => {
    const values = await methods.getValues(['ad_campaign_age_restriction', 'ad_campaign_payment_method_agreement'])
    if (
      values.every((item) => {
        if (item === false) {
          toggleAllRef.current.checked = false
          return false
        }
        return true
      })
    ) {
      toggleAllRef.current.checked = true
    }
  }
  //
  const onToggleAll = async (e) => {
    await methods.setValue('ad_campaign_payment_method_agreement', e.target.checked, {
      shouldValidate: true,
    })
    await methods.setValue('ad_campaign_age_restriction', e.target.checked, {
      shouldValidate: true,
    })
    await refresh()
  }
  //
  const onFormCheckboxToggle = async () => {
    await refresh()
  }
  //
  const onShowInfo = (e) => {
    e.preventDefault()
    setState(true)
  }
  //
  return (
    <section className="tc-section">
      <h3 className="title">
        <span style={{ color: 'var(--error-color)' }}>*</span>
        이용약관동의
      </h3>
      <div className="divider-tertiary" style={{ margin: 0 }} />
      <div className="checkboxes">
        <InputValidation
          names={['ad_campaign_age_restriction', 'ad_campaign_payment_method_agreement']}
          validations={['required']}
          groupName="ad_campaign_checkboxes"
        />
        <Checkbox className="primary" onToggle={onToggleAll} ref={toggleAllRef}>
          모두 동의합니다
        </Checkbox>
        {/**/}
        <FormCheckbox
          name="ad_campaign_age_restriction"
          validators={{ required: true }}
          onToggle={onFormCheckboxToggle}
        >
          만 14세 이상 가입 동의 (필수)
        </FormCheckbox>
        {/**/}
        <div className="checkbox flex flex-row justify-between">
          <FormCheckbox
            name="ad_campaign_payment_method_agreement"
            validators={{ required: true }}
            onToggle={onFormCheckboxToggle}
          >
            {'11pay 결제를 위한\n개인정보 제3자 제공 동의 (필수)'}
          </FormCheckbox>
          <button onClick={onShowInfo} style={{ cursor: 'pointer', padding: 0 }} className="smaller right-icon">
            약관보기
            <img src={targetRight} alt="target" />
          </button>
          <ContentModal
            id="payment_method_agreement"
            title="개인정보 수집 및 이용"
            isShown={state}
            onClose={() => setState(false)}
          >
            <pre className="w-full h-full" style={{ whiteSpace: 'pre-line' }}>
              {text}
            </pre>
          </ContentModal>
        </div>
        {/**/}
      </div>
    </section>
  )
}

export default CampaignPaymentView
