import React from 'react'
import { Accordion, Container, Navbar } from 'react-bootstrap'

import cx from 'classnames'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import logo from '../../assets/imgs/logo-btv-only.png'
import logoSkBroadband from '../../assets/imgs/logo-sk-broadband.png'

import { hideFab } from '../../services/fab'

const AD_MEDIA_DATA = [
  {
    title: '프로모션 안내',
    contents: [
      {
        category: '프로모션 안내', new: true, title: '24년 6월~8월 B tv 프로모션을 안내드립니다.', buttonText: '다운로드', fileUrl: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/00.+24%E1%84%82%E1%85%A7%E1%86%AB+6%E1%84%8B%E1%85%AF%E1%86%AF_B+tv+%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%A9+%E1%84%91%E1%85%B3%E1%84%85%E1%85%A9%E1%84%86%E1%85%A9%E1%84%89%E1%85%A7%E1%86%AB+%E1%84%8B%E1%85%A1%E1%86%AB%E1%84%82%E1%85%A2_240529_v1.0.pdf',
      },
    ],
  },
  {
    title: 'IPTV 광고매체 소개서',
    contents: [
      {
        category: '매체 소개서', new: true, title: 'B tv 매체 통합 안내 ', description: '<B tv VOD, B tv ad, B tv 우리동네 광고, N Pay, 비즈줌, 올리브영 DOOH>의 매체소개서 입니다.', fileUrl: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/01.+SKB+ALL+ADs_2401.pdf', button: { text: '이 달의 판매정책', fileUrl: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/01.+SKB+ALL+ADs_24%E1%84%82%E1%85%A7%E1%86%AB_B+tv_%E1%84%90%E1%85%A9%E1%86%BC%E1%84%92%E1%85%A1%E1%86%B8_%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%A9%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%8E%E1%85%A2%E1%86%A8_Ver.1.0.1.xlsx' },
      },
      {
        category: '매체 소개서', title: 'Addressable TV 광고', description: 'IPTV 3사 통합 <Addressable TV 광고> 매체소개서 입니다.', fileUrl: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/02.+Addressable+TV+%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%A9+%E1%84%8F%E1%85%A2%E1%86%B7%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB+%E1%84%8C%E1%85%B5%E1%86%B8%E1%84%92%E1%85%A2%E1%86%BC+%E1%84%92%E1%85%AD%E1%84%80%E1%85%AA%E1%84%8B%E1%85%AA+%E1%84%92%E1%85%AD%E1%84%8B%E1%85%B2%E1%86%AF%E1%84%8C%E1%85%A5%E1%86%A8+%E1%84%8C%E1%85%B5%E1%86%B8%E1%84%92%E1%85%A2%E1%86%BC+%E1%84%87%E1%85%A1%E1%86%BC%E1%84%8B%E1%85%A1%E1%86%AB_v2.0.pdf', button: { text: '이 달의 판매정책', fileUrl: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/02.+2024%E1%84%82%E1%85%A7%E1%86%AB%E1%84%83%E1%85%A9_(IPTV3%E1%84%89%E1%85%A1)+Addr.TV+%E1%84%91%E1%85%A1%E1%86%AB%E1%84%86%E1%85%A2%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%8E%E1%85%A2%E1%86%A8.xlsx' },
      },
      {
        category: '매체 소개서', title: '모바일 B tv 광고', description: '<모바일 B tv 광고> 매체소개서 입니다.', fileUrl: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/03.+2024%E1%84%82%E1%85%A7%E1%86%AB_%E1%84%86%E1%85%A9%E1%84%87%E1%85%A1%E1%84%8B%E1%85%B5%E1%86%AF+B+tv_%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5.pdf',
      },
      {
        category: '매체 소개서', title: 'Branded+ 채널', description: '모바일 B tv로 제공되는 <Branded+ 채널> 소개서 입니다.', fileUrl: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/03.+branded+%E1%84%8E%E1%85%A2%E1%84%82%E1%85%A5%E1%86%AF+%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%A9+%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5_2406.pdf',
      },
      {
        category: '매체 소개서', title: 'T:ON 광고', description: '채널재핑광고<T:ON> 소개서 입니다.', fileUrl: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/04.+TON+%E1%84%89%E1%85%A1%E1%86%BC%E1%84%91%E1%85%AE%E1%86%B7%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2+%E1%84%86%E1%85%B5%E1%86%BE+%E1%84%8B%E1%85%AE%E1%86%AB%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%80%E1%85%B5%E1%84%89%E1%85%AE%E1%86%AF-1.pdf',
      },
      {
        category: '신규상품', title: '프랜차이즈 Package', description: '<프랜차이즈 광고주>를 위한 소개서 입니다.', buttonText: '다운로드', fileUrl: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/05.+2024%E1%84%82%E1%85%A7%E1%86%AB+%E1%84%91%E1%85%B3%E1%84%85%E1%85%A2%E1%86%AB%E1%84%8E%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B3+Package.pdf',
      },
      {
        category: '신규상품', title: 'AI Meta VOD 중간광고', description: 'AI Meta로 제공되는 <VOD 중간광고> 소개서 입니다.', buttonText: '다운로드', fileUrl: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/06.+AI+Meta+%E1%84%8C%E1%85%AE%E1%86%BC%E1%84%80%E1%85%A1%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%A9+%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5_20240125.pdf',
      },
      {
        category: '광고 집행\n프로세스', title: 'B tv AD,VOD', description: 'B tv ad+와 VOD의 광고집행 프로세스 입니다.', buttonText: '다운로드', fileUrl: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/07.+B+tv+%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%A9+%E1%84%8C%E1%85%B5%E1%86%B8%E1%84%92%E1%85%A2%E1%86%BC+%E1%84%91%E1%85%B3%E1%84%85%E1%85%A9%E1%84%89%E1%85%A6%E1%84%89%E1%85%B3_v1.1.pptx',
      },
    ],
  },
  {
    title: 'CATV',
    contents: [
      {
        category: '매체 소개서', title: 'CATV 광고 매체 소개서', description: 'CATV 광고의 매체 소개서 입니다.', fileUrl: '', url: '/cable-tv',
      },
      {
        category: '매체 소개서', title: '재핑광고 매체 소개서', description: '채널재핑광고 매체 소개서 입니다.', fileUrl: '',
      },
    ],
  },
  {
    title: '영상제작 가이드',
    contents: [
      {
        category: '영상제작 가이드', title: 'B tv AD+', buttonText: '다운로드', fileUrl: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/B+tv+%E1%84%86%E1%85%A2%E1%84%8E%E1%85%A6%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5_%E1%84%8C%E1%85%A6%E1%84%8C%E1%85%A1%E1%86%A8%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3.pdf',
      },
    ],
  },
]

const AdMediaPage = () => {
  hideFab()
  const [activeItems, setActiveItems] = React.useState([1])

  const onSelectPanel = (id) => {
    if (activeItems.includes(id)) {
      setActiveItems(activeItems.filter((item) => item !== id))
    } else {
      setActiveItems([...activeItems, id])
    }
  }

  return (
    <>
      <Navbar expand="xl" fixed className="border-b border-[#c5c5c5]">
        <Container id="admedia-header-container" className="lg:!px-4" style={{ paddingTop: '0 !important' }}>
          <LinkContainer to="/">
            <Navbar.Brand>
              <div className="flex flex-center">
                <img
                  src={logo}
                  alt="logo"
                  className="d-inline-block align-top w-[56px] h-[25px] lg:w-[70px] lg:h-[31px]"
                />
              </div>
            </Navbar.Brand>
          </LinkContainer>
        </Container>
      </Navbar>
      <main
        className="flex flex-1 flex-col bg-white pt-8 px-[16px] lg:px-0 lg:pb-20"
      >
        <Container className="admedia font-[WantedSans] lg:!px-4">
          <div className="hidden lg:flex lg:items-center lg:mt-[80px] lg:mb-4 lg:gap-2">
            <div className="w-[28px] h-[6px] bg-brand" />
            <span className="text-[#6e6e6e] font-[TSansBeta] font-[400]">다운로드</span>
          </div>
          <h1 className="mb-[24px] text-4xl lg:mb-[63px]">
            <span className="text-[#3618CE]">B tv</span>
            {' '}
            광고 매체 소개서
          </h1>
          <Accordion activeKey={activeItems} onSelect={onSelectPanel}>
            {AD_MEDIA_DATA.map((item, index) => (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header className="font-[TSansBeta] font-[400]">{item.title}</Accordion.Header>
                <Accordion.Body className="py-2">
                  <ul className="mlg:divide-y mlg:divide-[#ccc]">
                    {item.contents.map((content, idx) => (
                      <li key={idx} className="flex items-center mlg:py-[24px] mlg:gap-[16px]">
                        <div className="flex-1 flex flex-col items-start lg:py-[20px] lg:gap-[10px]">
                          <div className="flex flex-col gap-2 lg:flex-row-reverse lg:items-center">
                            <div className="flex items-center gap-x-2">
                              <span className="px-2 py-1 text-[10px] text-brand border-[1px] border-brand rounded-full">{content.category}</span>
                              {content.new && (
                                <div className={cx(
                                  'flex items-center justify-center text-white font-[500] text-[10px] w-[18px] h-[18px] rounded-full bg-brand',
                                  'lg:w-[46px] lg:h-[23px]',
                                )}
                                >
                                  N
                                  <span className="hidden lg:inline-block">EW</span>
                                </div>
                              )}
                            </div>
                            <span className={cx('text-wrap font-[500] text-[15px] lg:text-[18px]', !content.description ? 'flex-1' : 'flex-1 text-end lg:flex-none lg:!text-start')}>{content.title}</span>
                          </div>
                          {content.description && (
                            <div className="flex-1 mt-1 text-start text-[9px] font-[400] lg:text-[14px] text-[#aaa] text-wrap">
                              {content.description}
                            </div>
                          )}
                          {content.button && (
                            <SubDownloadButton fileUrl={content.button.fileUrl}>
                              {content.button.text}
                            </SubDownloadButton>
                          )}
                          {/* <div className="flex gap-2">
                            {content.button && (
                              <DownloadButton className="hidden lg:flex" fileUrl={content.button.fileUrl}>
                                {content.button.text}
                              </DownloadButton>
                            )}
                            {index === 2 && idx === 1 ? null : (
                              <DownloadButton className="hidden lg:flex" fileUrl={content.fileUrl} url={content.url}>{content.buttonText || '매체소개서'}</DownloadButton>
                            )}
                          </div> */}
                        </div>
                        <div className="shrink-0 flex flex-col gap-2">
                          <DownloadButton fileUrl={content.fileUrl}>다운로드</DownloadButton>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Container>
      </main>
      <footer className="bg-[#2c2c2c] px-[30px] py-[35px] xl:hidden">
        <p className="mb-8 text-[13px] text-[#8b8b8b] text-start">
          <strong>고객센터</strong>
          <br />
          대표 : 106(무료) ARS 안내
          <br />
          케이블방송/케이블인터넷 : 146600(무료)
          <br />
          대표이사 사장 박진효
          <br />
          사업자등록번호 214-86-18758 사업자정보확인
          <br />
          통신판매신고번호 서울 중구 제 2008-1608호
          <br />
          서울시 중구 퇴계로 24 SK남산그린빌딩
        </p>
        <img
          src={logoSkBroadband}
          alt="logo"
          className="d-inline-block align-top w-[136px] h-[48px]"
        />
      </footer>
      <footer className="hidden items-end justify-center bg-[#2c2c2c] px-[30px] py-[68px] xl:flex">
        <p className="mr-[80px] text-white text-start text-[15px] mb-0">
          고객센터 대표 : 106(무료) ARS 안내 케이블방송/케이블인터넷 : 146600(무료)
          <br />
          대표이사 사장 박진효 사업자등록번호 214-86-18758 사업자정보확인 통신판매신고번호 서울 중구 제 2008-1608호
          <br />
          서울시 중구 퇴계로 24 SK남산그린빌딩

        </p>
        <img
          src={logoSkBroadband}
          alt="logo"
          className="d-inline-block align-top w-[179px] h-[63px]"
        />
      </footer>
    </>
  )
}

const DownloadIcon = ({ width = 16, height = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
  >
    <path d="M12 12V0" stroke="currentColor" strokeWidth="4.5" />
    <path d="M6 10l6 6 6-6" fill="currentColor" stroke="none" />
    <rect x="3" y="19" width="18" height="1" fill="currentColor" />
  </svg>
)

const DownloadButtonComponent = ({ className, children }) => (
  <div
    className={cx(
      'shrink-0 flex justify-center items-center px-[8px] py-[8px] text-[#000] text-[13px] lg:border-[1px] lg:border-[#000]',
      'transition-colors duration-300 lg:pl-[18px] lg:pr-[10px] lg:py-[8px] hover:bg-[#333] hover:text-white',
      className,
    )}
  >
    <span className="hidden lg:inline-block flex-1 text-center mr-2">{children}</span>
    <div className="hidden lg:block">
      <DownloadIcon />
    </div>
    <div className="lg:hidden">
      <DownloadIcon width={20} height={20} />
    </div>
  </div>
)

const DownloadButton = ({
  className, fileUrl, url, children,
}) => (url ? (
  <Link to={url}>
    <DownloadButtonComponent className={className}>{children}</DownloadButtonComponent>
  </Link>
) : (
  <a
    href={fileUrl}
    target="_blank"
    rel="noreferrer"
  >
    <DownloadButtonComponent className={className}>{children}</DownloadButtonComponent>
  </a>
))

const SubDownloadButtonComponent = ({ className, children }) => (
  <div
    className={cx(
      'shrink-0 flex items-center pr-[12px] pl-[7px] py-[6px] mt-2 text-brand bg-[#3617CE]/10',
      'transition-colors duration-300 lg:pr-[18px] lg:pl-[10px] lg:py-[8px]',
      className,
    )}
  >
    <div className="mt-1">
      <DownloadIcon />
    </div>
    <span className="flex-1 text-center ml-2 text-[10px] lg:text-[12px]">{children}</span>
  </div>
)

const SubDownloadButton = ({
  className, fileUrl, url, children,
}) => (url ? (
  <Link to={url}>
    <SubDownloadButtonComponent className={className}>{children}</SubDownloadButtonComponent>
  </Link>
) : (
  <a
    href={fileUrl}
    target="_blank"
    rel="noreferrer"
  >
    <SubDownloadButtonComponent className={className}>{children}</SubDownloadButtonComponent>
  </a>
))

export default AdMediaPage
