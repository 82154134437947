import React from 'react'
import { Modal } from 'react-bootstrap'
import moment from 'moment'

import { getNoticeData } from '../../../services/api'

//
const Notice = () => {
  const [isShown, setIsShown] = React.useState(false)
  const [state, setState] = React.useState({})
  React.useEffect(() => {
    (async () => {
      const result = await getNoticeData()
      if (result && result.data?.active) {
        const date = localStorage.getItem('popupDate')
        if (!date || !moment(date).isSame(moment(), 'D')) {
          setIsShown(true)
          setState(result)
        }
      }
    })()
  }, [])
  //
  const onClose = (noMoreToday = false) => {
    if (noMoreToday) localStorage.setItem('popupDate', moment().format('YYYY-MM-DD'))
    setIsShown(false)
  }
  //
  return (
    <Modal
      className="modal-content-transparent"
      dialogClassName="max-w-[90vw] mx-auto lg:max-w-[650px]"
      show={isShown}
      onHide={() => {
        onClose()
      }}
      keyboard={false}
      size="lg"
      backdrop="static"
      centered
    >
      <Modal.Body style={{ overflowY: 'visible' }}>
        <div className="relative flex flex-col items-stretch overflow-visible">
          {state.data?.link ? (
            <a href={state.data?.link}>
              <img src={state.data?.imageUrl} alt="공지사항" style={{ width: '100%', margin: 0, padding: 0 }} />
            </a>
          ) : (
            <img src={state.data?.imageUrl} alt="공지사항" />
          )}
          <div className="flex border-t border-[#aaa]">
            <button
              className="cta-button flex-1 bg-white border-r border-[#aaa] rounded-none mmd:text-sm"
              onClick={() => {
                onClose(true)
              }}
              style={{ marginBottom: 0 }}
            >
              오늘 하루 보지 않기
            </button>
            <button
              className="cta-button flex-1 bg-white rounded-none"
              onClick={() => {
                onClose()
              }}
              style={{ marginBottom: 0 }}
            >
              닫기
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default Notice
