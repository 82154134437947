import React from 'react'
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs'

const VideoTabs = ({ data, vids }) => {
  const vidRef1 = React.useRef()
  const vidRef2 = React.useRef()
  const vidRef3 = React.useRef()
  //
  const onSelect = async (index) => {
    setTimeout(async () => {
      switch (index) {
          case 0:
            await vidRef1.current?.play()
            break
          case 1:
            await vidRef2.current?.play()
            break
          case 2:
            await vidRef3.current?.play()
            break
          default:
            break
      }
    }, 100)
  }
  //
  return (
    <Tabs
      className="tabs-primary video-tags xl:hidden"
      selectedTabClassName="active"
      onSelect={onSelect}
    >
      <TabList className="grid grid-cols-3 tab-list-primary">
        {data.map((item, index) => (
          <Tab className="tab-primary  flex-grow" key={index}>
            {item.title}
          </Tab>
        ))}
      </TabList>
      <TabPanel className=" flex flex-col">
        <video
          ref={vidRef1}
          src={vids[0]}
          muted
          loop
          autoPlay
          playsInline
          controlsList="nodownload"
          onContextMenu={(e) => e.preventDefault()}
        />
        <span className="content">{data[0].content}</span>
      </TabPanel>
      <TabPanel className=" flex flex-col">
        <video
          ref={vidRef2}
          src={vids[1]}
          muted
          loop
          autoPlay
          playsInline
          controlsList="nodownload"
          onContextMenu={(e) => e.preventDefault()}
        />
        <span className="content">{data[1].content}</span>
      </TabPanel>
      <TabPanel className=" flex flex-col">
        <video
          ref={vidRef3}
          src={vids[2]}
          muted
          loop
          autoPlay
          playsInline
          controlsList="nodownload"
          onContextMenu={(e) => e.preventDefault()}
        />
        <span className="content whitespace-pre-wrap" style={{ wordBreak: 'keep-all' }}>
          {`${data[2].content}\n`}
          <span style={{ marginTop: -15, color: 'red' }}>
            {data[2].point}
          </span>
        </span>
      </TabPanel>
    </Tabs>
  )
}

export default VideoTabs
