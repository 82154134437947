/* eslint-disable react/display-name */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import { transNumToKoreanMan } from '../../../services/utils'

const FranchiseLineGraph = ({ data, hasLegend = true, type }) => {
  const unit = type === 'count' ? '건' : '원'
  return (
    <ResponsiveLine
      data={data}
      margin={{
        top: 80,
        right: 120,
        bottom: 50,
        left: 60,
      }}
      xScale={{
        type: 'point',
      }}
      yScale={{
        type: 'linear',
        min: 0,
        max: 'auto',
      }}
      yFormat=" >-.2f"
      curve="linear"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '연/월',
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '청약 건수',
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      enableGridX={false}
      colors={{ datum: 'color' }}
      enablePoints
      pointSize={6}
      pointColor={{ theme: 'grid.line.stroke' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh
      tooltip={({
        point: {
          data: { x, y },
          serieId,
          serieColor,
        },
      }) => (
        <div className="flex items-center px-2.5 py-1.5 gap-2 bg-white border">
          <div className="w-3 h-3" style={{ backgroundColor: serieColor }} />
          <span className="text-textPrimary">{`${serieId} ${x}`}</span>
          <span className="text-textPrimary font-bold">{`${transNumToKoreanMan(y, type === 'expense')}${unit}`}</span>
        </div>
      )}
      legends={
        !hasLegend
          ? []
          : [
            {
              valueFormat: (value) => `${value}${unit}`,
              anchor: 'top-right',
              direction: 'column',
              justify: false,
              translateX: 130,
              translateY: -50,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 28,
              itemTextColor: '#999',
              itemDirection: 'left-to-right',
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]
      }
    />
  )
}

export default FranchiseLineGraph
