import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'

import { useQuery } from 'react-query'
import { useCookies } from 'react-cookie'
import AppContext from '../../../services/context'
import LabeledSection from '../../../components/labeledSection'
import LoadingLayout from '../../../layouts/loadingLayout'
import usePageIndicator from '../../../hooks/usePageIndicator'
import {
  formatNumber, getFranchiseAdsRow, transNumToKoreanMan, useWindowSize,
} from '../../../services/utils'
import StatusDot from '../../../components/statusDot'
import AdsCard from './AdsCard'
import { getCampaignsByFranchiseNotice, useFranchiseNoticeCampaigns } from '../../../services/api/notice'
import { useMyFranchiseAdsList } from '../../../services/api/media'
//
const AdsTable = ({ notice }) => {
  const { appState } = React.useContext(AppContext)
  const [cookies] = useCookies(['jwt_token'])
  // const data = appState.franchiseAdsList
  const [width] = useWindowSize()

  const { data, isLoading } = useMyFranchiseAdsList(cookies.jwt_token, !notice)
  const {
    data: noticeAdsData,
    isLoading: noticeAdsLoading,
  } = useFranchiseNoticeCampaigns(cookies.jwt_token, notice?._id)

  const { pageOption, setPageOption, renderPageIndicator } = usePageIndicator(appState.franchiseAdsOptions.pageOption)
  //
  // const renderSBAButton = () => (!notice ? null : (
  //   <a href="https://naver.com">
  //     <button className="secondary small">SBA 전체 광고 현황 보기</button>
  //   </a>
  // ))
  //
  const tableData = !notice ? data : noticeAdsData

  const {
    ongoingCount, completedCount, totalBudget, totalFranchiseBudget,
  } = !notice || !noticeAdsData ? {} : tableData.docs.reduce(
    (acc, cur) => {
      if (cur.statusCode === 'WAIT') {
        acc.ongoingCount += 1
      } else {
        acc.completedCount += 1
      }
      acc.totalBudget += cur.budget
      acc.totalFranchiseBudget += cur.franchiseBudget
      return acc
    },
    {
      ongoingCount: 0,
      completedCount: 0,
      totalBudget: 0,
      totalFranchiseBudget: 0,
    },
  )

  const detailCx = 'text-xs text-textTertiary'
  //
  return (!notice && (isLoading || !data)) || (!!notice && (noticeAdsLoading || !noticeAdsData)) ? <LoadingLayout /> : (
    <>
      {!!notice && (
        <div className="flex flex-col gap-3">
          {/* <Link className="self-end" to="/franchise/notice/write">
            <button className="brand small !py-3 mlg:!px-2">본사 집행 캠페인 세부 참여자 대리점 리스트 보기</button>
          </Link> */}
          <table className="mb-4 border-collapse border">
            <thead className="font-bold bg-bgColor2">
              <tr className="text-[12px] lg:text-[16px]">
                <th className="w-[40%] py-2 text-center">공지명</th>
                <th className="py-2 text-center">청약중</th>
                <th className="py-2 text-center">청약완료</th>
                <th className="py-2 text-center whitespace-pre-wrap">
                  {'청약금액\n'}
                  <span className="text-textTertiary text-[12px] lg:text-[15px]">(매장/본사)</span>
                </th>
              </tr>
            </thead>
            <tbody className="text-textPrimary bg-white">
              <tr className="text-[14px] lg:text-[18px] font-bold">
                <td className="py-2 text-center">{notice?.title}</td>
                <td className="py-2 text-center">{ongoingCount}</td>
                <td className="py-2 text-center">{completedCount}</td>
                <td className="py-2 text-center whitespace-pre-wrap">
                  {`${transNumToKoreanMan(totalBudget)}원\n`}
                  <span className="text-textTertiary text-[12px] lg:text-[15px]">
                    {`(${transNumToKoreanMan(totalFranchiseBudget)}원/${transNumToKoreanMan(
                      totalBudget - totalFranchiseBudget,
                    )}원)`}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <div className="franchise-card p-4 flex flex-col min-h-[500px] mb-12 mlg:!border-0 mlg:!p-0 mlg:!rounded-none">
        <LabeledSection label={notice ? '집행 현황' : '내 광고 현황'}>
          {width >= 798 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <div className="flex flex-col">
                      <span>대리점명</span>
                      <span className={detailCx}>&nbsp;</span>
                    </div>
                  </th>
                  <th>
                    <div className="flex flex-col">
                      <span>매장명</span>
                      <span className={detailCx}>&nbsp;</span>
                    </div>
                  </th>
                  <th>
                    <div className="flex flex-col">
                      <span>상태</span>
                      <span className={detailCx}>(대기/집행 중/종료)</span>
                    </div>
                  </th>
                  <th>
                    <div className="flex flex-col">
                      <span>광고기간</span>
                      <span className={detailCx}>&nbsp;</span>
                    </div>
                  </th>
                  <th>
                    <div className="flex flex-col">
                      <span>광고비</span>
                      <span className={detailCx}>(매장/본사)</span>
                    </div>
                  </th>
                  <th>
                    <div className="flex flex-col">
                      <span>노출수</span>
                      <span className={detailCx}>(보너스 포함)</span>
                    </div>
                  </th>
                  <th>
                    <div className="flex flex-col">
                      <span>제공보너스</span>
                      <span className={detailCx}>&nbsp;</span>
                    </div>
                  </th>
                  {/* <th>
                  <div className="flex flex-col">
                    <span>집행현황</span>
                    <span className={detailCx}>&nbsp;</span>
                  </div>
                </th> */}
                  <th>
                    <div className="flex flex-col">
                      <span>비고</span>
                      <span className={detailCx}>&nbsp;</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.docs.length > 0
                  && tableData.docs.map((item, index) => (
                    <tr key={index} className="text-textPrimary">
                      <td>{item.franchiseName || ''}</td>
                      <td>{item.franchiseTeamName}</td>
                      <td>
                        <div className="flex items-center gap-1">
                          <StatusDot status={item.statusCode} />
                          {item.status}
                        </div>
                      </td>
                      <td>{item.period}</td>
                      <td>
                        <div className="flex flex-col">
                          <span>{item.amount}</span>
                          <span className={detailCx}>{`(${item.amountDetail})`}</span>
                        </div>
                      </td>
                      <td>{formatNumber(item.targetImpression)}</td>
                      <td>{item.bonusPercent}</td>
                      {/* <td>
                      <a
                        className="underline text-[14px] text-textTertiary"
                        href="https://naver.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        SBA 링크
                      </a>
                    </td> */}
                      <td>
                        {!!item.franchiseNotice && (
                          <Link
                            to={`/franchise/notice/${item.franchiseNotice}`}
                            className="underline text-[14px] text-textTertiary"
                          >
                            공지사항 링크
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          ) : (
            <div className="flex flex-col gap-4">
              {tableData.docs.length > 0
              && tableData.docs.map((item, index) => <AdsCard key={item._id} adData={item} />)}
            </div>
          )}
          {(!tableData || tableData.docs.length === 0) && (
            <div className="franchise-empty">
              <p className="fir">청약한 광고가 없습니다</p>
            </div>
          )}
        </LabeledSection>
        <div className="flex-1" />
        <div className="flex justify-center items-center mt-4">{renderPageIndicator()}</div>
      </div>
    </>
  )
}

export default AdsTable
