import React from 'react'
import ReactPlayer from 'react-player'

const VideoToggle = ({
  h3, p, v1src, v2src, v3src, v4src, btn1, btn2, btn3, btn4,
}) => {
  const videoRef = React.useRef()
  const [state, setState] = React.useState({
    src: v1src,
    active: 1,
  })
  const [playing, setPlaying] = React.useState(true)
  //
  const onClick = async (e, id) => {
    switch (id) {
        case 1:
          await setState({ active: 1, src: v1src })
          break
        case 2:
          await setState({ active: 2, src: v2src })
          break
        case 3:
          await setState({ active: 3, src: v3src })
          break
        case 4:
          await setState({ active: 4, src: v4src })
          break
        default:
          break
    }
    // videoRef.current.play()
  }
  const onReady = () => {
    setPlaying(true)
  }
  //
  return (
    <div className="video-toggle flex flex-col lg:flex-row">
      <div>
        <p>{p}</p>
        <h3>{h3}</h3>
        <div className="hidden lg:block lg:mr-16 banner-buttons">
          <button
            className={`lg:mr-2 mb-10 light${state.active === 1 ? ' active' : ''}`}
            onClick={(e) => onClick(e, 1)}
          >
            {btn1}
          </button>
          <button
            className={`lg:mr-2 mb-10 light${state.active === 2 ? ' active' : ''}`}
            onClick={(e) => onClick(e, 2)}
          >
            {btn2}
          </button>
          <button
            className={`lg:mr-2 mb-10 light${state.active === 3 ? ' active' : ''}`}
            onClick={(e) => onClick(e, 3)}
          >
            {btn3}
          </button>
          {btn4 && (
            <button
              className={`lg:mr-2 mb-10 light${state.active === 4 ? ' active' : ''}`}
              onClick={(e) => onClick(e, 4)}
            >
              {btn4}
            </button>
          )}
        </div>
        <div className="flex flex-row lg:hidden tab-buttons">
          <button className={state.active === 1 ? 'active' : ''} onClick={(e) => onClick(e, 1)}>
            {btn1}
          </button>
          <button className={state.active === 2 ? 'active' : ''} onClick={(e) => onClick(e, 2)}>
            {btn2}
          </button>
          <button className={state.active === 3 ? 'active' : ''} onClick={(e) => onClick(e, 3)}>
            {btn3}
          </button>
          {btn4 && (
            <button className={state.active === 4 ? 'active' : ''} onClick={(e) => onClick(e, 4)}>
              {btn4}
            </button>
          )}
        </div>
      </div>
      <div className="flex video-wrapper lg:flex-grow">
        {/* <video ref={videoRef} controls={true} src={state.src}></video> */}
        <ReactPlayer
          url={state.src}
          ref={videoRef}
          width="100%"
          height="100%"
          controls
          playing={playing}
          loop
          muted
          onReady={onReady}
          playsinline
        />
      </div>
    </div>
  )
}

export default VideoToggle
