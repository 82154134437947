/* eslint-disable new-cap */
import React from 'react'

import { CONFIG } from '../services/config'
// let geometryList = []
//
const drawGeometry = async (map, geometry, key, resultPolygon, setResultPolygon) => {
  if (!geometry) {
    return
  }
  const resultGeometry = geometry.features[0].geometry
  const resultArea = resultGeometry.coordinates[0]
  let drawInfoArr = []
  const positionBounds = new window.Tmapv2.LatLngBounds() // 맵에 결과물 확인 하기 위한 LatLngBounds객체 생성

  // if (resultPolygon.length > 0) {
  //   resultPolygon = []
  // }

  if (resultGeometry.type === 'MultiPolygon') {
    resultGeometry.coordinates.forEach((item) => {
      drawInfoArr = []
      const resultArea_array = item[0]
      resultArea_array.forEach((item2) => {
        // 경로들의 결과값(구간)들을 포인트 객체로 변환
        const latlng = new window.Tmapv2.Point(item2[0], item2[1])
        // 포인트 객체를 받아 좌표값으로 변환
        const convertPoint = new window.Tmapv2.Projection.convertEPSG3857ToWGS84GEO(latlng)
        // 포인트객체의 정보로 좌표값 변환 객체로 저장
        const convertChange = new window.Tmapv2.LatLng(convertPoint._lat, convertPoint._lng)

        drawInfoArr.push(convertChange)
        positionBounds.extend(convertChange)
      })
      const polygon = new window.Tmapv2.Polygon({
        paths: drawInfoArr,
        strokeColor: 'red',
        fillColor: 'pink', // 다각형 내부 색상
        map, // 지도 객체
      })
      setResultPolygon((prev) => [...prev, { polygon, key }])
    })
  } else {
    resultArea.forEach((item) => {
      // 경로들의 결과값(구간)들을 포인트 객체로 변환
      const latlng = new window.Tmapv2.Point(item[0], item[1])
      // 포인트 객체를 받아 좌표값으로 변환
      const convertPoint = new window.Tmapv2.Projection.convertEPSG3857ToWGS84GEO(latlng)
      // 포인트객체의 정보로 좌표값 변환 객체로 저장
      const convertChange = new window.Tmapv2.LatLng(convertPoint._lat, convertPoint._lng)

      drawInfoArr.push(convertChange)
      positionBounds.extend(convertChange)
    })
    const polygon = new window.Tmapv2.Polygon({
      paths: drawInfoArr,
      strokeColor: 'red',
      fillColor: 'pink', // 다각형 내부 색상
      map, // 지도 객체
    })
    setResultPolygon((prev) => [...prev, { polygon, key }])
  }

  // map.panToBounds(positionBounds) // 확장된 bounds의 중심으로 이동시키기
  map.fitBounds(positionBounds)
  map.zoomOut()
}

const removeGeometry = (key, geometryList, setGeometryList, resultPolygon, setResultPolygon) => {
  if (resultPolygon.length > 0) {
    const newList = resultPolygon.filter((item) => {
      if (item.key === key) {
        item.polygon.setMap(null)
        return false
      }
      return true
    })
    setResultPolygon(newList)
    const newGeometryList = geometryList
    delete newGeometryList[key]
    setGeometryList(newGeometryList)
  }
}

const removeUnusedGeometry = (regions, geometryList, setGeometryList, resultPolygon, setResultPolygon) => {
  if (resultPolygon.length > 0) {
    const newList = resultPolygon.filter((item) => {
      if (!regions.find((region) => region.key === item.key)) {
        item.polygon.setMap(null)
        return false
      }
      return true
    })
    setResultPolygon(newList)
    const newGeometryList = geometryList
    regions.forEach((region) => {
      if (!newGeometryList[region.key]) {
        delete newGeometryList[region.key]
      }
    })
    setGeometryList(newGeometryList)
  }
}

const removeJe = (str) => {
  // "제" 다음에 오는 숫자를 추출하는 정규식
  const pattern = /제(\d+)/
  const match = pattern.exec(str)

  if (match) {
    // "제"를 제거한 후의 문자열 반환
    return str.replace(pattern, match[1])
  }
  // 매칭되는 패턴이 없을 경우 기본값 반환
  return str
}

const getGeomtry = async (map, item, type, geoList, setGeoList) => {
  if (item.key in geoList) return geoList[item.key]
  //
  let searchKeyword = item.value
  let regionName = item.value
  if (searchKeyword.includes('-')) {
    [searchKeyword] = searchKeyword.split('-')
    regionName = searchKeyword
    searchKeyword = regionName.split(' ').slice(-1).pop()
  } else {
    searchKeyword = item.value.split(' ').slice(-1).pop()
  }
  const jeRemovedRegionName = removeJe(regionName)
  const jeRemovedSearchKeyword = removeJe(searchKeyword)
  const REQUEST_URL = new URL('tmap/geofencing/regions', 'https://apis.openapi.sk.com')
  REQUEST_URL.searchParams.set('appKey', CONFIG.TMAP_APP_KEY)
  REQUEST_URL.searchParams.set('version', 1)
  REQUEST_URL.searchParams.set('format', 'json')
  REQUEST_URL.searchParams.set('count', 100)
  REQUEST_URL.searchParams.set('searchType', 'KEYWORD')
  REQUEST_URL.searchParams.set('searchKeyword', jeRemovedSearchKeyword)
  //
  switch (item.depth) {
      case 1:
        REQUEST_URL.searchParams.set('categories', 'city_do')
        break
      case 2:
        REQUEST_URL.searchParams.set('categories', 'gu_gun')
        break
      case 3:
      default:
      // REQUEST_URL.searchParams.set("categories", "legalDong")
        REQUEST_URL.searchParams.set('categories', 'adminDong')
        break
  }
  //
  const response = await fetch(REQUEST_URL)
  if (!response) return
  const result = await response.json()
  if (!result || result.error) return
  //
  const region = result.searchRegionsInfo.find((checkItem) => checkItem.regionInfo.description === jeRemovedRegionName)
  if (!region) return
  //
  //
  const REQUEST_URL_2 = new URL(`tmap/geofencing/regions/${region.regionInfo.regionId}`, 'https://apis.openapi.sk.com')
  REQUEST_URL_2.searchParams.set('appKey', CONFIG.TMAP_APP_KEY)
  REQUEST_URL_2.searchParams.set('version', 1)
  REQUEST_URL_2.searchParams.set('format', 'json')
  REQUEST_URL_2.searchParams.set('resCoordType', 'EPSG3857')
  //
  const response_2 = await fetch(REQUEST_URL_2)
  if (!response_2) return
  const result_2 = await response_2.json()
  if (!result_2 || result_2.error) return
  //
  await setGeoList({ ...geoList, [item.key]: result_2 })
  return result_2
}
//
const setMarker = (map, lat, lon) => {
  const position = new window.Tmapv2.LatLng(lat, lon)
  map.setCenter(position)
  map.setZoom(16)
  const marker = new window.Tmapv2.Marker({ position, map })
  marker.create()
}
//
const Map = ({ width = '100%', height = '100%' }, forwardedRef) => {
  const mapRef = React.useRef(null)
  const [map, setMap] = React.useState(null)
  const [geometryList, setGeometryList] = React.useState({})
  const [resultPolygon, setResultPolygon] = React.useState([])
  //
  React.useEffect(() => {
    (async () => {
      if (mapRef.current?.children && mapRef.current?.children.length === 0) {
        await setMap(
          new window.Tmapv2.Map(mapRef.current, {
            width,
            height,
            zoom: 15,
          }),
        )
      }
    })()
  }, [])
  //
  React.useImperativeHandle(
    forwardedRef,
    () => ({
      getGeomtry: (item, type) => getGeomtry(map, item, type, geometryList, setGeometryList),
      drawGeometry: (geometry, key) => drawGeometry(map, geometry, key, resultPolygon, setResultPolygon),
      removeGeometry: (key) => removeGeometry(key, geometryList, setGeometryList, resultPolygon, setResultPolygon),
      // eslint-disable-next-line max-len
      removeUnusedGeometry: (regions) => removeUnusedGeometry(regions, geometryList, setGeometryList, resultPolygon, setResultPolygon),
      setMarker: (lat, lon) => setMarker(map, lat, lon),
    }),
    [map, geometryList, resultPolygon],
  )
  //
  return <div ref={mapRef} className="a maps" />
}

export const Maps = React.forwardRef(Map)
