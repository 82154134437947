import React from 'react'
import cx from 'classnames'

import { transNumToKoreanMan } from '../../../services/utils'
import { FRANCHISE_REGIONS } from '../../../services/constants'

const SKT_REGION_COUNT = 6

const RegionBubble = ({
  region, isCurrent = false, isWinner = false, hasArrow = false,
}) => {
  const bubbleCx = cx(
    'flex items-center justify-center rounded-full lg:rounded-lg',
    isWinner
      ? cx('w-[45px] py-1 text-[12px] font-bold text-white bg-brand shadow-sm', 'lg:w-[70px] lg:py-1.5 lg:text-[16px]')
      : cx(
        'w-[36px] py-[3px] text-[9px] font-normal text-textPrimary',
        'lg:w-[60px] lg:py-1 lg:text-[14px]',
        isCurrent ? 'bg-[#666]' : 'bg-[#ddd]',
      ),
    // hasArrow가 true인 경우 중간에 삼각형 모양을 추가
    hasArrow
      ? cx(
        'relative before:absolute before:bottom-0 before:left-1/2 before:transform',
        'before:-translate-x-1/2 before:translate-y-[2px]',
        'before:w-0 before:h-0 before:border-t-8 before:border-r-8 before:-rotate-45',
        isWinner ? 'before:border-brand' : isCurrent ? 'before:border-[#666]' : 'before:border-[#ddd]',
      )
      : '',
  )

  return <div className={bubbleCx}>{FRANCHISE_REGIONS[region]}</div>
}

const CompetitionGraph = ({ type, data, winnerValue }) => {
  const unit = React.useMemo(() => (type === 'count' ? '건' : '원'), [type])

  const arrData = React.useMemo(() => {
    const result = Array.from(Array(SKT_REGION_COUNT)).map(() => [])
    if (!data || winnerValue === 0) return result
    data.forEach((regionData) => {
      const value = regionData[type]
      const index = Math.max(Math.floor(((value - 0.1) / winnerValue) * SKT_REGION_COUNT), 0)

      result[index].push({
        region: regionData.region,
        value,
      })
    })
    return result
  }, [winnerValue, type, data])

  return (
    <div className="flex-1 flex items-end mt-2">
      {Array.from(Array(SKT_REGION_COUNT + 1)).map((_, i) => {
        const isEmptyArea = i === SKT_REGION_COUNT
        const isWinnerElement = i === SKT_REGION_COUNT - 1
        const indexData = arrData[i]

        return (
          <div key={`${i}`} className="flex-1 flex flex-col items-center">
            <div className="flex flex-col mb-[12px] gap-1">
              {!isEmptyArea
                && indexData
                && indexData.map((regionData, j) => (
                  <RegionBubble
                    key={regionData.region}
                    region={regionData.region}
                    isWinner={isWinnerElement}
                    isCurrent={isEmptyArea}
                    hasArrow={j === indexData.length - 1}
                  />
                ))}
            </div>
            {isWinnerElement ? (
              <div className="relative w-full h-1 bg-[#ccc]">
                <div className={cx('absolute top-1/2 left-0 w-1/2 h-1 bg-brand', 'transform -translate-y-1/2')} />
                <div
                  className={cx(
                    'absolute top-1/2 left-1/2 w-3 h-3 bg-brand rounded-full',
                    'transform -translate-x-1/2 -translate-y-1/2',
                  )}
                />
              </div>
            ) : (
              <div className={cx('w-full h-1', isEmptyArea ? 'bg-[#ccc]' : 'bg-brand')} />
            )}
            <div className="h-5 mt-2">
              {!isEmptyArea && indexData && indexData.length > 0 && (
                <span
                  className={cx(
                    'text-[10px] lg:text-[14px]',
                    isWinnerElement ? 'text-brand font-bold' : 'text-textPrimary',
                  )}
                >
                  {`${transNumToKoreanMan(indexData[0].value, type === 'expense')}${unit}`}
                </span>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default React.memo(CompetitionGraph)
