import React from 'react'
import moment from 'moment'

import { useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import BasicLayout from '../../layouts/basic'
import { DesignModal } from '../making_assets/components/designPaper'
import { copyTrialAsset } from '../../services/api'
import TemplateCarousel from './components/TemplateCarousel'
import { hideFab } from '../../services/fab'
import useAuthModal from '../../hooks/useAuthModal'
import { TwoButtonModal } from '../../components/modals'
import AppContext from '../../services/context'
//
import imgStep1 from '../../assets/imgs/img_trial_step1@2x.webp'
import imgStep2 from '../../assets/imgs/img_trial_step2@2x.webp'
import imgStep3 from '../../assets/imgs/img_trial_step3@2x.webp'
import imgStep4 from '../../assets/imgs/img_trial_step4@2x.webp'
import imgTvTop from '../../assets/imgs/img_trial_tv_top@2x.webp'
//
const localStoragekey = 'trialDone'
const steps = [
  {
    step: 1,
    title: '템플릿 선택',
    description: '마음에 드시는 템플릿을 선택하여 편집실로 이동합니다.',
    image: imgStep1,
  },
  {
    step: 2,
    title: '영상 편집',
    description: '편집실에서 광고 이미지 및 문구를 입력합니다.',
    image: imgStep2,
  },
  {
    step: 3,
    title: '영상 제작',
    description: '우측 상단 완료하기 버튼을 클릭하여 영상 제작을 진행합니다.',
    image: imgStep3,
  },
  {
    step: 4,
    title: '성우 음성 입력',
    description: '성우 음성을 입히고 광고 제작을 완료합니다.',
    image: imgStep4,
  },
]
//
const Step = ({
  step, title, description, image,
}) => (
  <div className="flex flex-col">
    <div className="flex items-center space-x-2">
      <div className="px-3 pt-1 pb-1.5 font-bold bg-[#491ac7] text-white rounded-full">{`Step ${step}`}</div>
      <span className="text-lg font-bold">{title}</span>
    </div>
    <span className="mt-1 mb-2 lg:text-base xl:text-lg">{description}</span>
    <img src={image} alt="단계이미지" />
  </div>
)
//
const Trial = () => {
  const { appState } = React.useContext(AppContext)
  const { open, renderAuthModal } = useAuthModal()
  const history = useHistory()
  const [isModalShown, setIsModalShown] = React.useState(false)
  const [design, setDesign] = React.useState(null)
  const [trialModalVisible, setTrialModalVisible] = React.useState(false)
  //
  const onSelect = async (e, data) => {
    await setDesign(data)
    await setIsModalShown(true)
  }
  //
  const onPostTemplate = async (e, {
    designId, subtemplateId, assetId, videoDuration,
  }) => {
    const trialDone = localStorage.getItem(localStoragekey)
    const today = moment().format('YYYY-MM-DD')
    if (trialDone === today) {
      await setIsModalShown(false)
      if (appState.userInfo?.userId) {
        setTrialModalVisible(true)
      } else {
        open()
      }
      return
    }
    const result = await copyTrialAsset(designId, subtemplateId, assetId, videoDuration)
    if (!result) return
    //
    history.push({
      pathname: '/portal/media/build/trial',
      state: {
        userId: '6438fce1c8ca7bfab2f2d058',
        assetId: result._id,
      },
    })
  }
  hideFab()
  //
  return (
    <BasicLayout className="bg-white">
      <div className="page-headline" style={{ padding: 0 }}>
        <Container>
          <div className="flex justify-between items-center py-16">
            <div>
              <h1>TV 광고 제작을 체험해보세요!</h1>
              <div className="mt-10 text-2xl">
                <span>보다 쉽고 빠르게 광고 제작을 경험할 수 있는 기회를 제공합니다.</span>
                <br />
                <span>광고 제작 과정을 체험해보면서 자신만의 창의적인 광고를 만들어보세요!</span>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {/* <div className="mlg:w-full flex flex-col items-center mlg:bg-main mlg:py-4"> */}
      <div className="w-full py-[70px] bg-white">
        <Container>
          <div className="flex flex-col ml-4">
            <h2 className="mlg:text-center mlg:text-2xl text-3xl">광고영상 제작 체험관</h2>
            <span className="mlg:text-lg mlg:text-center mlg:whitespace-pre-wrap text-xl font-medium">
              {'다양한 템플릿을 통해 TV 광고 영상 제작 과정을\n체험해볼 수 있는 서비스입니다.'}
            </span>
          </div>
        </Container>
      </div>
      <div className="bg-bgColor py-10">
        <Container className="relative">
          <div className="flex flex-col items-center py-12">
            <h2 className="mb-14 mlg:text-2xl text-3xl">영상제작 순서 안내</h2>
            <div className="grid px-6 lg:px-0 lg:grid-cols-2 gap-x-6 gap-y-[30px]">
              {steps.map((step) => (
                <Step
                  key={step.step}
                  step={step.step}
                  title={step.title}
                  description={step.description}
                  image={step.image}
                />
              ))}
            </div>
          </div>
          <img
            className="hidden xl:block absolute top-[-240px] right-0 w-[283px]"
            src={imgTvTop}
            alt="imgTV"
          />
        </Container>
      </div>
      <Container className="pt-[50px]">
        <div className="flex flex-col items-center">
          <h2 className="mmd:hidden mb-6 text-center whitespace-pre-wrap mt-14 text-2xl">
            {
              '마음에 드시는 템플릿을 선택해주세요,\n로그인하시면 더 많은 업종별 템플릿을 보실수 있습니다.'
            }
          </h2>
          <h2 className="md:hidden mb-6 text-xl text-center whitespace-pre-wrap mt-14">
            {
              '마음에 드시는 템플릿을 선택해주세요,\n로그인하시면 더 많은\n업종별 템플릿을 보실수 있습니다.'
            }
          </h2>
        </div>
      </Container>
      <div className="px-4 mx-auto max-w-[100%] lg:max-w-[calc(100vw-180px)]">
        <TemplateCarousel onSelect={onSelect} />
      </div>
      {design && (
        <DesignModal
          isShown={isModalShown}
          data={design}
          onClose={() => setIsModalShown(false)}
          onPostTemplate={onPostTemplate}
        />
      )}
      {renderAuthModal(
        '오늘은 이미 체험하셨습니다.\n로그인 후 나의 광고 > 새로운 광고 만들기 > 소재 등록하기 를 이용해주세요.',
      )}
      {trialModalVisible && (
        <TwoButtonModal
          title="이용 안내"
          primaryText="나의광고로 이동"
          secondaryText="취소"
          onClickPrimary={() => {
            history.push('/portal/media')
          }}
          onClickSecondary={() => {
            setTrialModalVisible(false)
          }}
          onClose={() => {
            setTrialModalVisible(false)
          }}
        >
          {'나의 광고 > 새로운 광고 만들기 > 소재 등록하기 를 이용해주세요.'}
        </TwoButtonModal>
      )}
    </BasicLayout>
  )
}

export default Trial
