import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import cx from 'classnames'

import Checkbox from '../../../components/checkbox'
import { getAddresses } from '../../../services/api'
//
const MapMultiSelectBox = ({
  name,
  onAddressSelected,
  onAddressDeselected,
  selectedKey,
  multiple = true,
}) => {
  const methods = useFormContext()
  const depth1div = React.useRef()
  const depth2div = React.useRef()
  const [addresses, setAddresses] = React.useState({})
  const [selected, setSelected] = React.useState({
    depth1: null,
    depth2: null,
  })
  const selectedAddresses = methods && useWatch({ name })
  const isRenderD2 = () => selected.depth1 && Object.keys(selected.depth1.children)?.length > 0
  const isRenderD3 = () => selected.depth2 && Object.keys(selected.depth2.children)?.length > 0
  //
  React.useEffect(() => {
    (async () => {
      if (Object.keys(addresses).length > 1) return
      const result = await getAddresses()
      if (result === false) return
      result.forEach((d1_itemData) => {
        addresses[d1_itemData.id] = {
          key: d1_itemData.id,
          value: d1_itemData.name.trim().toLowerCase(),
          children: {},
          depth: 1,
        }
      })
      await setAddresses(addresses)
      await setSelected({ depth1: null, depth2: null })
    })()
  }, [addresses])
  //
  const on1stDepthClick = async (e, d1_item) => {
    e.preventDefault()
    if (Object.keys(addresses[d1_item.key].children).length < 1) {
      const result = await getAddresses(d1_item.key)
      if (result === false) return
      result.forEach((d2_itemData) => {
        const d2_item = {
          key: d2_itemData.id,
          value: [d1_item.value, d2_itemData.name]
            .join(' ')
            .trim()
            .toLowerCase(),
          children: {},
          depth: 2,
          parent: d1_item.key,
        }
        d2_itemData.children.forEach((d3_itemData) => {
          d2_item.children[d3_itemData.id] = {
            key: d3_itemData.id,
            value: [d2_item.value, d3_itemData.name]
              .join(' ')
              .trim()
              .toLowerCase(),
            depth: 3,
            parent: d2_item.key,
          }
        })
        addresses[d1_item.key].children[d2_item.key] = d2_item
      })
      await setAddresses(addresses)
    }
    await setSelected({ ...selected, depth1: d1_item, depth2: null })
    if (depth1div.current) {
      depth1div.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (!multiple && onAddressSelected) await onAddressSelected(d1_item)
  }
  //
  const on2ndDepthClick = async (e, d2_item) => {
    const d1_item = selected.depth1
    e.preventDefault()
    if (d2_item.key === 'all') {
      if (selectedAddresses.some((item) => item.key === d1_item.key)) {
        await onAddressDeselected(d1_item)
      } else {
        const deselectItems = []
        await onAddressSelected(d1_item)
        if (d1_item.children) {
          selectedAddresses.forEach((item) => {
            const tempD2 = d1_item.children[item.key]
            if (tempD2) deselectItems.push(tempD2)
            Object.values(d1_item.children).forEach((item2) => {
              if (item2.children[item.key]) deselectItems.push(item2.children[item.key])
            })
          })
          // eslint-disable-next-line no-restricted-syntax
          for (const item2 of deselectItems) {
            // eslint-disable-next-line no-await-in-loop
            await onAddressDeselected(item2)
          }
        }
      }
      return
    }
    if (Object.keys(d2_item.children).length < 1) {
      const item = { ...d2_item }
      delete item.children
      if (onAddressSelected) await onAddressSelected(item)
      return
    }
    await setSelected({ ...selected, depth2: d2_item })
    if (depth2div.current) {
      depth2div.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (!multiple && onAddressSelected) await onAddressSelected(d2_item)
  }
  //
  const on3rdDepthClick = async (e, d3_item) => {
    const d2_item = selected.depth2
    if (d3_item.key === 'all') {
      if (e.target.checked) {
        const deselectItems = []
        await onAddressSelected(d2_item)
        if (d2_item.children) {
          selectedAddresses.forEach((item) => {
            if (d2_item.children[item.key]) {
              deselectItems.push(item)
            }
          })
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const item2 of deselectItems) {
          // eslint-disable-next-line no-await-in-loop
          await onAddressDeselected(item2)
        }
      } else {
        await onAddressDeselected(d2_item)
      }
      return
    }
    if (!multiple) {
      if (onAddressSelected) await onAddressSelected(d3_item)
      return
    }
    //
    if (e.target.checked) {
      const allSelected = Object.values(d2_item.children)?.every(
        (checkItem) => {
          if (checkItem.key === d3_item.key) return true
          return selectedAddresses.some((item) => item.key === checkItem.key)
        },
      )
      let selectedItem = { ...d2_item }
      delete selectedItem.children
      if (!allSelected) selectedItem = { ...d3_item }
      if (onAddressSelected) await onAddressSelected(selectedItem)
    } else {
      onAddressDeselected(d3_item)
    }
  }
  //
  const isD3_itemChecked = (d3_item) => (
    multiple ? (
      selectedAddresses.some((item) => item.key === d3_item.key)
    ) : selectedKey === d3_item.key
  )
  //
  return (
    <div className="hidden lg:flex flex-col map-multiselect-box">
      <div ref={depth1div} className="depth-1 grid grid-cols-5 xlg:grid-cols-6">
        {addresses && Object.values(addresses).map((item) => (
          <button
            key={item.key}
            onClick={(e) => on1stDepthClick(e, item)}
            className={cx({
              selected: selected.depth1?.key === item.key,
              '!text-main': selectedAddresses.some((item2) => item2.key === item.key),
            })}
          >
            {item.value}
          </button>
        ))}
      </div>
      {isRenderD2() && (
        <div ref={depth2div} className="depth-2">
          <button
            onClick={(e) => on2ndDepthClick(e, { key: 'all' })}
            className={cx('!font-bold', {
              'selected !text-main': selectedAddresses.some((item) => item.key === selected.depth1.key),
            })}
          >
            전체
          </button>
          {selected?.depth1?.children && Object.values(selected.depth1.children).map((item) => {
            const disabled = selectedAddresses.some((item2) => item2.key === selected.depth1.key)
            return (
              <button
                key={item.key}
                onClick={(e) => on2ndDepthClick(e, item)}
                className={cx({
                  selected: selected.depth2?.key === item.key,
                })}
                style={{ backgroundColor: disabled ? '#f9f9f9' : 'unset' }}
                disabled={disabled}
              >
                {item.value.split(' ')[1]}
              </button>
            )
          })}
        </div>
      )}
      {isRenderD3() && (
        <div className="depth-3">
          <Checkbox
            onToggle={(e) => on3rdDepthClick(e, { key: 'all' })}
            checked={selectedAddresses.some((item) => item.key === selected.depth2.key)}
            className="!font-bold"
          >
            전체
          </Checkbox>
          {selected?.depth2?.children && Object.values(selected.depth2.children).map((item) => (
            <Checkbox
              key={item.key}
              onToggle={(e) => on3rdDepthClick(e, item)}
              checked={isD3_itemChecked(item)}
              disabled={selectedAddresses.some((item2) => item2.key === selected.depth2.key)}
            >
              {item.value.split(' ')[2]}
            </Checkbox>
          ))}
        </div>
      )}
    </div>
  )
}

export default MapMultiSelectBox
