import React from 'react'
import { Container } from 'react-bootstrap'

import BasicLayout from '../../layouts/basic'

import AdsTable from '../franchise/components/AdsTable'
import AppContext from '../../services/context'
//
const NoticeAdsPage = () => {
  const { appState } = React.useContext(AppContext)
  const notice = appState.currentFranchiseNotice
  console.log(notice)
  return (
    <BasicLayout className="community-editor">
      <Container className="px-4 py-12 mlg:bg-white lg:px-0">
        <div className="mxl:my-12">
          <AdsTable notice={notice} />
        </div>
      </Container>
    </BasicLayout>
  )
}

export default NoticeAdsPage
