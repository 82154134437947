import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import Footer from '../../components/footer'
import { fetchAgencyInfo } from '../../services/api'
import CampaignPaymentView from './components/campaignPaymentView'
import CampaignPaymentActions from './components/campaignPaymentActions'

const RequestedAdCampaign = ({ match }) => {
  const paymentActionsRef = React.useRef()
  const history = useHistory()
  const methods = useForm({
    criteriaMode: 'all',
    mode: 'onSubmit',
  })
  const { register, setValue } = methods
  const [meta, setMeta] = React.useState({})
  //
  React.useEffect(() => {
    (async () => {
      const { id } = match.params
      const result = await fetchAgencyInfo(id)
      setMeta({
        id,
        ...result.data,
      })
    })()
  }, [match])

  React.useEffect(() => {
    (async () => {
      await register('ad_campaign_payment_method', { required: true })
      await setValue('ad_campaign_payment_method', 'bankbook')
    })()
  }, [register, setValue])
  //
  const onSubmit = async (data) => {
    await paymentActionsRef.current?.initiatePayment({ ...data, isRequested: true })
  }
  //
  return (
    <>
      <Navbar>
        <div className="container">
          <Nav className="me-auto">
            <Nav.Link
              className="go-back"
              onClick={() => history.push('/portal/media')}
            >
              나의광고 로 돌아가기
            </Nav.Link>
          </Nav>
        </div>
      </Navbar>
      {/**/}
      <main className="flex flex-grow flex-col">
        <div
          className="page-headline"
          style={{ backgroundColor: 'var(--line-color)' }}
        >
          <div className="container">
            <h1 style={{ color: 'var(--text-primary)' }}>영상 제작 대행</h1>
          </div>
        </div>
        <div className="page-content create-ad">
          <div className="container">
            {/**/}
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="payment-holder">
                  <div className="payment-content">
                    {/**/}
                    <CampaignPaymentView type="requested" meta={meta} />
                    {/**/}
                    <CampaignPaymentActions
                      ref={paymentActionsRef}
                      type="requested"
                      resultParams={{ requested: true }}
                    />
                    {/**/}
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default RequestedAdCampaign
