import React from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'
import { ProgressBar } from 'react-bootstrap'
import { postAsset } from '../../services/api'
import DesignPaper, { DesignModal } from './components/designPaper'
import AppContext from '../../services/context'
import ailoadingImg from '../../assets/imgs/image-template-loading.svg'
//
const Tab1 = ({
  cats,
  loadState,
  loadValue,
  selected,
  designs,
  onLoad,
  onSelect,
  onReset,
}) => {
  const [cookies] = useCookies(['jwt_token'])
  const { appState } = React.useContext(AppContext)
  const history = useHistory()
  const [isModalShown, setIsModalShown] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(true)
  const [state, setState] = React.useState({
    activeDesign: null,
  })
  //
  React.useEffect(() => {
    (async () => {
      if (Object.keys(selected).length >= 1) await setIsDisabled(false)
      else await setIsDisabled(true)
    })()
  }, [selected, setIsDisabled])
  //
  const onStartClick = (e) => {
    if (onLoad) onLoad(e)
  }
  //
  const onResetClick = (e) => {
    if (onReset) onReset(e)
  }
  //
  const onPostTemplate = async (e, data) => {
    await setIsModalShown(false)
    const result = await postAsset(cookies.jwt_token, data._id)
    if (!result) return
    //
    history.push({
      pathname: '/portal/media/build',
      state: {
        userId: appState.userInfo.id,
        assetId: result._id,
      },
    })
  }
  //
  const onTemplateSelect = async (e, data) => {
    await setState({ ...state, activeDesign: data })
    await setIsModalShown(true)
  }
  //
  return (
    <div className="tab1">
      {loadState === 0 && (
        <>
          <div className="tab1-header">
            <h3>어떤 소재로 어떤 효과를 얻고 싶으신가요?</h3>
            <p>1개~5개 를 선택해주시면 추천에 큰 도움이 됩니다 :)</p>
          </div>
          <div className="tab1-content">
            {cats && cats.map((item) => (
              <button
                key={item.id}
                className={`light${item.id in selected ? ' active' : ''}`}
                onClick={(e) => onSelect(e, item)}
              >
                {item.name}
              </button>
            ))}
          </div>
        </>
      )}
      {loadState !== 0 && (
        <>
          <div className="tab1-header">
            <h3>추천 템플릿</h3>
          </div>
          <p className="tab1-subtitle">선택한 내용</p>
          <div className="tab1-content">
            {Object.values(selected).map((item) => (
              <button key={item.id} className="light active">
                {item.name}
              </button>
            ))}
          </div>
        </>
      )}
      {/**/}
      {loadState === 2 && (
        <>
          <div
            className="grid grid-cols-4 gap-4 designs-holder"
            style={{ clear: 'both' }}
          >
            {designs.map((item, index) => (
              <DesignPaper
                data={item}
                key={index}
                onSelect={onTemplateSelect}
              />
            ))}
          </div>
          {designs.length === 0 && (
            <div className="designs-holder">
              <Empty />
            </div>
          )}
        </>
      )}
      {/**/}
      <div className="flex flex-row action">
        {loadState === 0 && (
          <button
            className="large w-full primary"
            disabled={isDisabled}
            onClick={onStartClick}
          >
            추천 템플릿 보기
          </button>
        )}
        {loadState === 2 && (
          <button className="large w-full primary" onClick={onResetClick}>
            다시 선택하기
          </button>
        )}
      </div>
      {/**/}
      {loadState === 1 && (
        <div className="ai-loading flex flex-col items-center text-center">
          <img src={ailoadingImg} alt="media" />
          <ProgressBar now={loadValue} className="loading" />
          <p>
            <span>회원님께서 맘에 들어할 템플릿을 찾고 있습니다!</span>
            <br />
            <span>잠시만 기다려주세요 :)</span>
          </p>
        </div>
      )}
      <DesignModal
        isShown={isModalShown}
        data={state.activeDesign}
        onClose={() => setIsModalShown(false)}
        onPostTemplate={onPostTemplate}
      />
    </div>
  )
}

const Empty = () => (
  <div className="empty">
    <p className="fir">해당되는 템플릿이 없습니다</p>
    <p className="sec">
      <span>선택하신 내용에 해당되는 템플릿이 없습니다.</span>
      <br />
      <span>다른 항목을 선택해 다시 시도해주세요.</span>
    </p>
  </div>
)

export default Tab1
