import React from 'react'
//
let h1 = null
let pSpan = null
//
const SignUpHeader = ({ userType, isSocial = false }) => {
  //
  if (userType === 'INDIVISUAL' || isSocial) {
    h1 = '일반 회원만 가입해주세요'
    pSpan = '일반 회원만 가입해주세요'
  } else {
    h1 = '에이전시 회원가입'
    pSpan = '에이전시 회원만 가입해주세요'
  }
  //
  return (
    <>
      <h1 className="hidden lg:block">{h1}</h1>
      <p>
        <span>{pSpan}</span>
        {!isSocial && <br />}
        {!isSocial && <span className="required">는 필수로 입력해야 하는 정보입니다</span>}
      </p>
    </>
  )
}

export default SignUpHeader
