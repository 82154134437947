import React from 'react'
import { Container } from 'react-bootstrap'

import { FormProvider, useForm } from 'react-hook-form'
import BasicLayout from '../../layouts/basic'
import logo from '../../assets/imgs/btv-logo.png'
import expoQRCode from '../../assets/imgs/expo-qrcode.jpg'
import { FormTextboxGroup } from '../../components/formTextbox'
import { FormCheckbox } from '../../components/checkbox'
import targetRight from '../../assets/icons/icon-outlined-directional-right-medium.svg'
import { submitExpoForm } from '../../services/api'

const ExpoPage = () => {
  const methods = useForm({
    criteriaMode: 'onSubmit',
    mode: 'onSubmit',
  })

  const { name, tel, p_info_consent } = methods.watch()

  const onSubmit = (data) => {
    submitExpoForm(data)
      .then((res) => {
        if (!res) {
          alert('등록에 실패했습니다.')
          return
        }
        alert(
          '등록되었습니다.\nBtv우리동네광고 청약시 사용하실 수 있는 할인쿠폰을\n10/13(금)까지 문자로 보내드리겠습니다.\n감사합니다.',
        )
        methods.reset()
      })
      .catch(() => {
        alert('등록에 실패했습니다.')
      })
  }

  return (
    <BasicLayout className="bg-white">
      {/* <div className="page-headline">
        <Container className="pl-12">
          <h1>이벤트</h1>
        </Container>
      </div>
      <div className="flex xl:hidden bg-main py-6 pl-10">
        <h1 className="mb-0 text-white">이벤트</h1>
      </div> */}
      <Container className="py-10">
        <div className="flex flex-col items-center gap-2 px-4 my-6">
          <img src={logo} alt="logo" className="align-top w-[150px] h-[19px] lg:w-[300px] lg:h-[38px]" />
          <p className="mt-4 mb-2 text-lg mlg:whitespace-pre-wrap lg:text-xl font-bold text-center">
            <span className="text-[#f22]">할인쿠폰</span>
            {'을 받으실 수 있도록\n전화번호를 등록해주세요'}
          </p>
          {/* <p className="text-xl lg:text-2xl font-bold">☕️ 무료커피도 즐기세요</p> */}
          <div className="flex items-center gap-[80px] mt-8">
            <div className="hidden lg:flex flex-col items-center">
              <img src={expoQRCode} alt="qrcode" className="align-top w-[150px] h-[150px] lg:w-[280px] lg:h-[280px]" />
              <p className="mt-2 text-lg lg:text-xl font-bold">QR코드로 등록하셔도 됩니다</p>
            </div>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit, (e) => console.log(e))}>
                <div className="flex flex-col w-[320px]">
                  <FormTextboxGroup
                    label="이름"
                    name="name"
                    placeholder="이름을 입력해주세요."
                    maxLength={10}
                    validators={{
                      required: true,
                      pattern: /^[가-힣a-zA-Z]+$/,
                    }}
                    validations={['required', 'pattern']}
                  />
                  <FormTextboxGroup
                    label="전화번호"
                    name="tel"
                    placeholder='전화번호를 입력해주세요("-"없이 입력)'
                    maxLength={11}
                    type="tel"
                    validators={{
                      required: true,
                      pattern: /^\d{8,11}$/,
                    }}
                    validations={['required', 'pattern']}
                  />
                  <div className="checkbox flex flex-row justify-between mt-2">
                    <FormCheckbox name="p_info_consent" validators={{ required: true }}>
                      개인정보 수집 및 이용 동의 (필수)
                    </FormCheckbox>
                    <a
                      style={{ cursor: 'pointer', padding: 0 }}
                      className="smaller right-icon"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://skb-public.s3.ap-northeast-2.amazonaws.com/personal_information_coupon_231004.pdf"
                    >
                      자세히
                      <img className="transform translate-y-[3px] ml-0" src={targetRight} alt="target" />
                    </a>
                  </div>
                  <button
                    className="w-full mt-4 primary large"
                    type="submit"
                    disabled={!name || !tel || !p_info_consent}
                  >
                    등록하기
                  </button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </Container>
    </BasicLayout>
  )
}

export default ExpoPage
