import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import BasicLayout from '../../layouts/basic'
import { FormTextboxGroup } from '../../components/formTextbox'
import { ChangeUserPwd, ValidateUserId } from '../../services/api'
import IdentityCheckPopupGroup from './components/identityCheckPopupGroup'
import MessageModal from '../../components/modals'
//
const RecoverPwd = () => {
  const history = useHistory()
  const [state, setState] = React.useState({
    showValidateUserId: true,
    identity_check: null,
    userId: '',
    modalShown: false,
    modalMessage: '',
    modalType: 'warning',
    showResult: false,
  })
  //
  const onValidateResult = async (result, data) => {
    if (result.code === 'SUCCESS') {
      await setState({
        ...state,
        showValidateUserId: false,
        identity_check: data.identity_check,
        userId: data.id,
      })
    } else {
      switch (result.code) {
          case 'NOT_EXIST_MEMBER':
            await setState({
              ...state,
              modalMessage: '존재하지 않는 아이디 입니다 아이디를 확인해주세요',
              modalShown: true,
              modalType: 'warning',
            })
            break
          case 'NOT_VALID_USERID':
            await setState({
              ...state,
              modalMessage:
              '회원 정보가 일치하지 않습니다 입력한 정보를 확인해주세요',
              modalShown: true,
              modalType: 'warning',
            })
            break
          default:
            break
      }
    }
  }
  //
  const onChangeResult = async () => {
    await setState({ ...state, showResult: true })
  }
  //
  const onModalClose = async () => {
    if (state.modalType === 'success') {
      history.push('/sign-in')
      return
    }
    await setState({ ...state, modalShown: false })
  }
  //
  return (
    <BasicLayout>
      <Container className="auth-paper">
        <div className="flex flex-col justify-items-stretch items-center">
          <div className="header text-center">
            <h1>비밀번호 찾기</h1>
            <p>
              <span>
                {'아이디와 본인 인증 완료 후 비밀번호를 재설정 할 수 있습니다 '}
              </span>
            </p>
          </div>
          <div className="grid xl:grid-cols-4 w-full">
            <div className="xl:col-start-2 xl:col-span-2">
              {state.showValidateUserId && !state.showResult && (
                <ValidateUserIdForm onValidateResult={onValidateResult} />
              )}
              {/**/}
              {!state.showValidateUserId && !state.showResult && (
                <ChangeUserPwdForm
                  userId={state.userId}
                  identityCheck={state.identity_check}
                  onChangeResult={onChangeResult}
                />
              )}
              {!state.showValidateUserId && state.showResult && (
                <div className="w-full text-center content">
                  <p className="result-info text-center">
                    <span>회원님의 비밀번호가 성공적으로 변경되었습니다</span>
                    <br />
                    <span>새로운 비밀번호로 로그인 할 수 있습니다</span>
                  </p>
                  <button
                    className="w-full recover-action primary large"
                    onClick={() => history.push('/sign-in')}
                  >
                    로그인 하기
                  </button>
                </div>
              )}
            </div>
          </div>
          {/**/}
        </div>
        <MessageModal
          type={state.modalType}
          isShown={state.modalShown}
          onClose={onModalClose}
        >
          {state.modalMessage}
        </MessageModal>
      </Container>
    </BasicLayout>
  )
}

const ValidateUserIdForm = ({ onValidateResult }) => {
  const methods = useForm({ criteriaMode: 'all', mode: 'onSubmit' })
  const [state, setState] = React.useState({
    findPwdDisabled: true,
  })
  //
  const onIdentityCheck = async () => {
    await setState({ ...state, findPwdDisabled: false })
  }
  //
  const onSubmit = async (data) => {
    const result = await ValidateUserId(data)
    if (!result) return
    if (onValidateResult) await onValidateResult(result, data)
  }
  //
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormTextboxGroup
          className="larger"
          label="아이디"
          name="id"
          placeholder="아이디를 입력해주세요"
          validators={{ required: true }}
          validations={['required']}
        />
        <IdentityCheckPopupGroup onIdentityCheck={onIdentityCheck} />
        <button
          className="w-full recover-action primary large"
          disabled={state.findPwdDisabled}
        >
          다음
        </button>
      </form>
    </FormProvider>
  )
}

const ChangeUserPwdForm = ({ userId, identityCheck, onChangeResult }) => {
  const methods = useForm({ criteriaMode: 'all', mode: 'onSubmit' })
  //
  const onSubmit = async (data) => {
    const result = await ChangeUserPwd({
      userId,
      identity_check: identityCheck,
      password: data.password,
    })
    if (!result) return
    if (onChangeResult) await onChangeResult()
  }
  //
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormTextboxGroup
          label="변경할 비밀번호"
          name="password"
          type="password"
          autoComplete="off"
          placeholder="비밀번호를 입력해주세요"
          validators={{
            required: true,
            pattern: /^((?=.*\d)(?=.*[a-z])(?=.*[\W]).{8,16})$/,
          }}
          validations={['required', 'pattern']}
        />
        <FormTextboxGroup
          label="변경할 비밀번호 확인"
          name="confirm_password"
          type="password"
          autoComplete="off"
          placeholder="비밀번호를 입력해주세요"
          validators={{
            required: true,
            validate: {
              unmatched: (v) => v === methods.getValues('password'),
            },
          }}
          validations={['required', 'unmatched']}
        />
        <button className="w-full recover-action primary large">
          비밀번호 재설정하기
        </button>
      </form>
    </FormProvider>
  )
}

export default RecoverPwd
