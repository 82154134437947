import React from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import moment from 'moment'

import BasicLayout from '../../layouts/basic'
import AppContext from '../../services/context'

const format = 'YYYY.MM.DD'

const EventViews = ({ title, data, done = false }) => {
  const history = useHistory()

  const onClick = (board) => {
    if (done) window.alert('종료된 이벤트입니다.')
    else if (board.link) {
      if (board.link.includes('btvtownad.com')) {
        history.push(board.link.replace('https://btvtownad.com', ''))
      } else {
        window.open(board.link, '_blank')
      }
    } else {
      history.push(`/board/${board.boardId}`)
    }
    // history.push(`/board/${boardId}`)
  }
  return (
    <div>
      <h3 className="ml-2 mb-2">{title}</h3>
      <div className="flex flex-col gap-4 overflow-scroll event-list md:flex-row md:gap-3">
        {data.map((item) => (
          <div key={item.boardId} className="cursor-pointer" onClick={() => onClick(item)}>
            <img className="w-full md:w-[320px] aspect-[32/25] mx-0 object-contain" src={item.image} alt="event" />
            <span className="block text-sm font-medium mt-1 ml-2">
              {`이벤트 기간: ${moment(item.startDate).format(format)} ~ ${moment(item.endDate).format(format)}`}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

const EventPage = () => {
  const { appState } = React.useContext(AppContext)
  const [events, setEvents] = React.useState([])
  const [doneEvents, setDoneEvents] = React.useState([])

  React.useEffect(() => {
    if (appState.events) {
      const obj = { events: [], doneEvents: [] }
      appState.events.forEach((event) => {
        if (moment(event.endDate).add(1, 'd').isBefore(moment())) {
          obj.doneEvents.push(event)
        } else {
          obj.events.push(event)
        }
      })
      setEvents(obj.events)
      setDoneEvents(obj.doneEvents)
    }
  }, [appState.events])
  return (
    <BasicLayout className="bg-white">
      <div className="page-headline">
        <Container className="pl-12">
          <h1>이벤트</h1>
        </Container>
      </div>
      <div className="flex xl:hidden bg-main py-6 pl-10">
        <h1 className="mb-0 text-white">이벤트</h1>
      </div>
      <Container className="py-10">
        <div className="flex flex-col gap-8 px-4 my-6">
          {events.length > 0 && <EventViews title="진행 중 이벤트" data={events} />}
          {doneEvents.length > 0 && <EventViews title="종료된 이벤트" data={doneEvents} done />}
        </div>
      </Container>
    </BasicLayout>
  )
}

export default EventPage
