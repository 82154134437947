import React from 'react'

import {
  CompanyNameSection,
  CategorySection,
  AddressSection,
  TelSection,
  DescSection,
  ImageSection,
  ReferrerSection,
  BusinessSection,
} from '../promotion'
import AppContext from '../../../services/context'

const StoreInfo = ({ renderUploadView }) => {
  const { appState } = React.useContext(AppContext)
  return (
    <div className="create-ad-part">
      <h3 className="xs:text-base">매장 정보를 입력해주세요</h3>
      <div className="bg-white">
        <CompanyNameSection />
        {appState.userInfo?.userType === '1' && <BusinessSection />}
        <CategorySection name="companySector" />
        <div className="text-sm note w-full lg:text-base">
          <span>※ 분양광고는 한국광고자율심의기구의 심의필증을 첨부하여야 청약 가능합니다.</span>
          <span className="lg:pl-5 font-bold">
            미 첨부시 자동 반려처리 되오니 문의 필요시 채널톡(우측하단 아이콘)으로 문의 바랍니다
          </span>
        </div>
        <AddressSection />
        <TelSection />
        <DescSection />
        <ReferrerSection />
        <ImageSection renderUploadView={renderUploadView} />
      </div>
    </div>
  )
}

export default StoreInfo
