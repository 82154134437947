import React from 'react'
import MyPageLayout from '../../layouts/myPageLayout'
import AppContext from '../../services/context'
import NaverIcon from '../../assets/icons/icon-login-naver.svg'
import googleIcon from '../../assets/icons/icon-login-google.svg'
import kakaoIcon from '../../assets/icons/icon-login-kakao.svg'
//
const Account = () => {
  const { appState } = React.useContext(AppContext)
  //
  return (
    <MyPageLayout navigationTitle="계정">
      <div className="grid grid-cols-1 lg:grid-cols-4">
        <div className="col-span-2">
          <div className="header">
            <h3>계정</h3>
          </div>
          <div className="gray-holder flex flex-col">
            <span>회원 유형</span>
            <span className="">
              {Number(appState.userInfo?.userType) === 0
                ? '일반 회원'
                : '에이전시 회원'}
            </span>
          </div>
          <div className="field-holder flex flex-col">
            <span>로그인 정보</span>
            {appState.userInfo?.method === 'skb' && (
              <button disabled className="left-icon">
                e-mail 로그인
              </button>
            )}
            {appState.userInfo?.method === 'naver' && (
              <button disabled className="left-icon">
                <img src={NaverIcon} alt="icon" />
                네이버 로그인
              </button>
            )}
            {appState.userInfo?.method === 'kakao' && (
              <button disabled className="left-icon">
                <img src={kakaoIcon} alt="icon" />
                카카오 로그인
              </button>
            )}
            {appState.userInfo?.method === 'google' && (
              <button disabled className="left-icon">
                <img src={googleIcon} alt="icon" />
                구글 로그인
              </button>
            )}
          </div>
          {appState.userInfo?.method === 'skb' && (
            <div className="field-holder flex flex-col">
              <span>아이디</span>
              <span>{appState.userInfo?.userId}</span>
            </div>
          )}
        </div>
      </div>
    </MyPageLayout>
  )
}

export default Account
