import React from 'react'
import { Carousel } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import banner1 from '../../../assets/imgs/home_banner1.jpg'
import banner2 from '../../../assets/imgs/home_banner2.jpg'
import banner3 from '../../../assets/imgs/home_banner3.jpg'
import banner4 from '../../../assets/imgs/home_banner4.jpg'
import banner5 from '../../../assets/imgs/home_banner5.jpg'
import banner1Mobile from '../../../assets/imgs/home_banner1_mobile.png'
import banner2Mobile from '../../../assets/imgs/home_banner2_mobile.png'
import banner3Mobile from '../../../assets/imgs/home_banner3_mobile.png'
import banner4Mobile from '../../../assets/imgs/home_banner4_mobile.png'
import banner5Mobile from '../../../assets/imgs/home_banner5_mobile.jpg'

//
const HeaderCarousal = ({ toInterviewSection }) => (
  <>
    <Carousel className="hidden md:block" interval={3000}>
      <Carousel.Item className="relative">
        <Link to="/estimate">
          <div
            className="background-image cursor-pointer"
            style={{ backgroundImage: `url(${banner5})` }}
            alt="banner"
          />
        </Link>
      </Carousel.Item>
      <Carousel.Item className="relative">
        <div
          className="background-image cursor-pointer"
          style={{ backgroundImage: `url(${banner1})` }}
          alt="banner"
          onClick={toInterviewSection}
        />
      </Carousel.Item>
      <Carousel.Item className="relative">
        <div className="background-image" style={{ backgroundImage: `url(${banner2})` }} alt="banner" />
      </Carousel.Item>
      <Carousel.Item className="relative">
        <Link to="/trial">
          <div
            className="background-image background-image-3"
            style={{ backgroundImage: `url(${banner3})` }}
            alt="banner"
          />
        </Link>
      </Carousel.Item>
      <Carousel.Item className="relative">
        <Link to="/community">
          <div
            className="background-image background-image-4"
            style={{ backgroundImage: `url(${banner4})` }}
            alt="banner"
          />
        </Link>
      </Carousel.Item>
    </Carousel>
    <Carousel className="block md:hidden" interval={3000}>
      <Carousel.Item className="relative">
        <Link to="/estimate">
          <img
            className="background-image cursor-pointer object-cover object-center"
            src={banner5Mobile}
            alt="banner"
            onClick={toInterviewSection}
          />
        </Link>
      </Carousel.Item>
      <Carousel.Item className="relative">
        <img
          className="background-image cursor-pointer object-cover object-center"
          src={banner1Mobile}
          alt="banner"
          onClick={toInterviewSection}
        />
      </Carousel.Item>
      <Carousel.Item className="relative">
        <img
          className="background-image cursor-pointer object-cover object-center"
          src={banner2Mobile}
          alt="banner"
          onClick={toInterviewSection}
        />
      </Carousel.Item>
      <Carousel.Item className="relative">
        <Link to="/trial">
          <img
            className="background-image cursor-pointer object-cover object-center"
            src={banner3Mobile}
            alt="banner"
            onClick={toInterviewSection}
          />
        </Link>
      </Carousel.Item>
      <Carousel.Item className="relative">
        <Link to="/community">
          <img
            className="background-image cursor-pointer object-cover object-center"
            src={banner4Mobile}
            alt="banner"
            onClick={toInterviewSection}
          />
        </Link>
      </Carousel.Item>
    </Carousel>
  </>
)

export default HeaderCarousal
