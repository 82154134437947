import React from 'react'
import { Container } from 'react-bootstrap'

import BasicLayout from '../../layouts/basic'
import NoticeWriteForm from './components/noticeWriteForm'
import AppContext from '../../services/context'
//
const NoticeEditPage = () => {
  const { appState } = React.useContext(AppContext)
  const notice = appState.currentFranchiseNotice

  return (
    <BasicLayout className="community-editor">
      <Container>
        <div className="pb-12">
          <NoticeWriteForm notice={notice} />
        </div>
      </Container>
    </BasicLayout>
  )
}

export default NoticeEditPage
