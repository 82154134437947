import React from 'react'
import { Container } from 'react-bootstrap'
import {
  useForm, useFormContext, FormProvider, useWatch,
} from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'

import useUploadStoreImages from './hooks/useUploadStoreImages'
import {
  getReferrers,
  checkIsFirst,
  createPromotion,
  updatePromotion,
  checkBusinessIdValidity,
} from '../../services/api'
import AppContext from '../../services/context'
import BasicLayout from '../../layouts/basic'
import SectionWithTitle from './components/sectionWithTitle'
import { FormTextboxGroup } from '../../components/formTextbox'
import InputAddress from './components/inputAddress'
import PromotionView from './components/promotionView'
import LoadingIndicator from '../../components/LoadingIndicator'
import MessageModal from '../../components/modals'
import CategoryButton from '../../components/categoryButton'
import { FormActionTextboxGroup } from '../../components/formActionTextbox'

//
const VideoSection = ({ campaign }) => (
  <>
    <SectionWithTitle className="xl:hidden" title={campaign?.campaignName}>
      <video
        className="w-full"
        src={campaign?.videoUrl}
        preload="auto"
        controls
        controlsList="nodownload"
        onContextMenu={(e) => e.preventDefault()}
      />
    </SectionWithTitle>
    <div className="hidden xl:block xl:py-[20px]">
      <h1 className="font-black">{campaign?.campaignName}</h1>
      <div className="mx-[-48px] bg-line h-[2px] mb-8" />
      <video
        className="w-full"
        src={campaign?.videoUrl}
        preload="auto"
        controls
        controlsList="nodownload"
        onContextMenu={(e) => e.preventDefault()}
      />
    </div>
  </>
)
//
export const CompanyNameSection = () => (
  <SectionWithTitle title="상호명" description="사업장명, 우리가게 이름을 알려주세요." required>
    <FormTextboxGroup
      name="companyName"
      autoComplete="off"
      placeholder="상호명을 입력해주세요"
      maxLength={50}
      validators={{
        required: true,
      }}
      validations={['required']}
    />
  </SectionWithTitle>
)
//
export const BusinessSection = () => {
  const { appDispatch } = React.useContext(AppContext)
  const { setValue, control } = useFormContext()
  const { enqueueSnackbar } = useSnackbar()
  const clientBusinessId = useWatch({ name: 'clientBusinessId', control })
  const businessValidated = useWatch({ name: 'x_clientBusinessId_validated', control })
  // const businessValidated2 = useWatch({ name: 'clientBusinessDocs', control })
  //
  React.useEffect(() => {
    if (businessValidated) {
      checkIsFirst({ clientBusinessId }).then((result) => {
        if (result) {
          appDispatch({ type: 'updateAdCampaignStore', payload: { isFirst: result.isFirst } })
        }
      })
    }
  }, [businessValidated])
  //
  const onBusinessIdAction = async (e, value) => {
    const formattedValue = value.replace(/-/gi, '')
    const predicate = await checkBusinessIdValidity(formattedValue, '0')
    if (value !== formattedValue) {
      setValue('clientBusinessId', formattedValue)
    }
    if (predicate) {
      enqueueSnackbar('사업자번호 인증 성공', { variant: 'success' })
    } else {
      enqueueSnackbar('사업자번호 인증 실패', { variant: 'error' })
    }
    return predicate
  }
  //
  return (
    <SectionWithTitle title="광고주 사업자등록번호" description="광고주의 사업자등록번호를 입력해주세요.">
      <FormActionTextboxGroup
        // label="광고주 사업자등록번호"
        name="clientBusinessId"
        className="business-id-check larger"
        placeholder="사업자등록번호를 입력해주세요"
        actionName="validated"
        onClick={onBusinessIdAction}
        validators={{
          pattern: /^((?=.*\d).{10,10})$/,
        }}
        validations={['pattern']}
      >
        번호확인
      </FormActionTextboxGroup>
    </SectionWithTitle>
  )
}
//
export const CategorySection = ({ name }) => {
  const { appState } = React.useContext(AppContext)
  const { register, setValue, control } = useFormContext()
  const companySector = useWatch({ name, control })
  //
  const onCategoryClick = (sector) => {
    setValue(name, sector)
  }
  //
  return (
    <SectionWithTitle title="업종" description="서비스 분야, 우리가게 서비스에 어울리는 업종을 알려주세요." required>
      <div className="flex flex-wrap gap-[16px] mt-2 mb-[49px]">
        {appState.galleryCategories.map((item) => (
          <CategoryButton
            key={item.code}
            isTradMarket={item.code === '1'}
            selected={item.code === companySector}
            onClick={() => onCategoryClick(item.code)}
          >
            {item.title}
          </CategoryButton>
        ))}
      </div>
      <input type="hidden" {...register(name, { required: true })} />
    </SectionWithTitle>
  )
}
//
export const CategorySection2 = ({ name }) => {
  const { appState } = React.useContext(AppContext)
  const { register, setValue } = useFormContext()
  const { onChange, ...rest } = register(name)
  const onChangeSelect = (e) => {
    setValue(name, e.target.value)
    onChange(e)
  }
  //
  return (
    <SectionWithTitle title="업종" description="서비스 분야, 우리가게 서비스에 어울리는 업종을 알려주세요." required>
      <div className="w-full select">
        <select {...rest} onChange={onChangeSelect} className="w-full" style={{ backgroundColor: 'white' }}>
          {appState.galleryCategories.map((item) => {
            const { code, title } = item
            return (
              <option key={code} value={code}>
                {title}
              </option>
            )
          })}
        </select>
      </div>
      <input type="hidden" {...register(name, { required: true })} />
    </SectionWithTitle>
  )
}
//
export const AddressSection = () => (
  <SectionWithTitle title="주소" description="주소, 우리가게의 정확한 위치를 알려주세요." required>
    <InputAddress />
  </SectionWithTitle>
)
//
export const TelSection = ({ required = false }) => {
  const validations = ['pattern']
  if (required) validations.push('required')

  const valildators = {
    pattern: /^\d{2,4}\d{2,4}\d{4}$/,
  }
  if (required) valildators.required = true

  return (
    <SectionWithTitle title="가게 연락처" description="연락처, 우리가게의 전화번호를 알려 주세요." required={required}>
      <FormTextboxGroup
        name="storeTel"
        autoComplete="off"
        maxLength={11}
        placeholder={'연락처를 입력해주세요("-"없이 입력)'}
        validators={valildators}
        validations={validations}
      />
    </SectionWithTitle>
  )
}
//
export const DescSection = () => {
  const { register } = useFormContext()
  return (
    <SectionWithTitle title="가게 소개" description="우리가게 소개, 우리가게의 이벤트나 정보를 널리 알려 보세요.">
      <div className="textbox-action" style={{ marginBottom: 16 }}>
        <textarea
          className="flex-grow large border-[1px] border-disabledLine rounded-[4px] text-[16px] font-medium"
          {...register('storeDesc')}
          placeholder="고객에게 알리고 싶은 가게의 정보를 입력해주세요"
          rows={4}
        />
      </div>
    </SectionWithTitle>
  )
}
//
export const ReferrerSection = () => {
  const [cookies] = useCookies(['referrer'])
  const { register, setValue } = useFormContext()
  const [referrers, setReferrers] = React.useState([])
  const { appState } = React.useContext(AppContext)

  React.useEffect(() => {
    getReferrers().then((data) => {
      setReferrers(data)
      if (appState.adCampaign.step1?.referrer) {
        setValue('referrer', appState.adCampaign.step1.referrer)
      } else if (cookies.referrer) {
        const result = data.some((item) => {
          if (item.key === cookies.referrer) {
            setValue('referrer', item.key)
            return true
          }
          return false
        })
        if (!result) setValue('referrer', 'none')
      } else {
        setValue('referrer', 'none')
      }
    })
  }, [])

  return (
    <SectionWithTitle title="추천 업체" description="BTV 우리동네 광고를 소개한 업체를 선택해주세요.">
      <div className="textbox-action" style={{ marginBottom: 16 }}>
        <div className="flex flex-col gap-2 mb-4">
          <select className="select-option py-2" {...register('referrer')}>
            <option value="none">해당 사항 없음</option>
            {referrers.map((item) => (
              <option key={item?.key} value={item?.key}>
                {item?.name}
              </option>
            ))}
          </select>
          {/* <Radio
            {...register('referrer')}
            value="none"
            onSelect={onClickOption('none')}
          >
            해당 사항 없음
          </Radio>
          {referrers.map((item) => (
            <Radio
              key={item.key}
              {...register('referrer')}
              value={item.key}
              onSelect={onClickOption(item.key)}
            >
              {item.name}
            </Radio>
          ))} */}
        </div>
      </div>
    </SectionWithTitle>
  )
}
//
export const ImageSection = ({ renderUploadView }) => (
  <SectionWithTitle title="소개 이미지" description="우리가게 이미지, 우리가게의 멋진 모습을 등록해 주세요.">
    {renderUploadView()}
  </SectionWithTitle>
)
//
const PromotionSection = () => (
  <SectionWithTitle title="프로모션" switchName="promotionVisible">
    <PromotionView />
  </SectionWithTitle>
)
//
const Promotion = ({ defaultValues }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [doneModalVisible, setDoneModalVisible] = React.useState(false)

  const [cookies] = useCookies(['jwt_token'])
  const history = useHistory()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const methods = useForm({
    criteriaMode: 'all',
    mode: 'onSubmit',
    defaultValues,
  })
  const { images, renderUploadView } = useUploadStoreImages(defaultValues?.storeImages || [])

  React.useEffect(() => {
    if (!defaultValues?._id) {
      methods.setValue('promotionValue', true)
    }
  }, [])

  const onClickBack = () => {
    history.goBack()
  }

  const onSubmit = async (data) => {
    const { campaignId } = defaultValues
    setIsLoading(true)
    try {
      let result
      if (defaultValues?._id) {
        result = await updatePromotion(cookies.jwt_token, {
          ...data,
          galleryId: defaultValues?._id,
          storeImages: images,
          videoUrl: defaultValues.videoUrl,
          duration: defaultValues.duration,
        })
      } else if (campaignId) {
        result = await createPromotion(cookies.jwt_token, {
          ...data,
          campaignId,
          storeImages: images,
        })
      }
      setIsLoading(false)
      if (!result) {
        enqueueSnackbar('오류가 발생했습니다', { variant: 'error' })
        return
      }
      setDoneModalVisible(true)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
      enqueueSnackbar('오류가 발생했습니다', { variant: 'error' })
    }
  }
  //
  return (
    <BasicLayout>
      <div className="bg-white xl:pt-20 xl:pb-[170px]">
        <Container className="xl:border xl:border-line xl:p-[24px] xl:bg-line">
          <div className="bg-white px-[24px] xl:py-[40px] xl:px-[130px]">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <VideoSection campaign={defaultValues || location.state} />
                <CompanyNameSection />
                <CategorySection name="companySector" />
                <AddressSection />
                <TelSection />
                <DescSection />
                <ImageSection renderUploadView={renderUploadView} />
                <PromotionSection />
                <div className="flex mmd:!flex-col-reverse ad-buttons-holder justify-center mlg:!bg-white mlg:mt-[-8px] !mb-4 mt-11">
                  <button
                    type="button"
                    className="secondary larger rounded mmd:!py-[5px] mmd:!min-h-[50px] mmd:!mb-4 mmd:!text-base xl:min-w-[346px]"
                    onClick={onClickBack}
                  >
                    이전으로
                  </button>
                  <button
                    type="submit"
                    className="primary larger mmd:rounded-[10px] mmd:!py-[5px] mmd:!min-h-[50px] mmd:!mb-4 mmd:rounded-br-none mmd:!text-base xl:min-w-[346px]"
                  >
                    등록하기
                  </button>
                </div>
              </form>
            </FormProvider>
          </div>
        </Container>
      </div>
      {isLoading && <LoadingIndicator />}
      <MessageModal type="success" isShown={doneModalVisible} onClose={() => history.replace('/')}>
        <h3 className="text-center">등록이 완료되었습니다</h3>
        <p className="mb-14 leading-7">
          <br />
          <span>광고 송출이 시작되면 우리가게 정보를 광고갤러리에서 확인하실 수 있습니다.</span>
          <br />
          <span>* 청약 완료 후 광고 심의/승인(최대 3일) 후 최종적으로 광고가 송출됩니다.</span>
          <br />
          <span>{'* 마이페이지 > 프로모션정보 메뉴에서 수정가능'}</span>
        </p>
      </MessageModal>
    </BasicLayout>
  )
}

export default Promotion
