import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import deleteIcon from '../../../assets/icons/291-6.svg'
//
const addItem = async (name, setValue, getValues, newItem) => {
  const currItems = await getValues(name)
  const items = currItems.filter((item) => item.key !== newItem.key)
  items.unshift(newItem)
  await setValue(name, items, { shouldValidate: true })
}
//
const deleteItem = async (name, setValue, getValues, alterItem, removeGeometry) => {
  const currItems = await getValues(name)
  const items = currItems.filter((item) => item.key !== alterItem.key)
  if (removeGeometry) removeGeometry(alterItem.key)
  await setValue(name, items, { shouldValidate: true })
}
//
const clearAll = async (name, setValue) => {
  await setValue(name, [], { shouldValidate: true })
}
//
const FormMapSelectionBox = ({
  name, validators, onAddressClick, removeGeometry,
}, forwardedRef) => {
  const { setValue, getValues, register } = useFormContext()
  const hiddenInputProps = register(name, validators)
  const inputRef = React.useRef(hiddenInputProps.ref)
  //
  const ad_campaign_regions_value = useWatch({
    name,
  })
  //
  const onAddressButtonClick = async (e, item) => {
    e.preventDefault()
    if (onAddressClick) onAddressClick(e, item)
  }
  //
  React.useImperativeHandle(
    forwardedRef,
    () => ({
      inputRef,
      //
      addItem: async (item) => {
        await addItem(name, setValue, getValues, item)
      },
      deleteItem: async (item) => {
        await deleteItem(name, setValue, getValues, item, removeGeometry)
      },
      clearAll: async () => {
        await clearAll(name, setValue)
      },

      //
    }),
    [name, setValue, getValues],
  )
  //
  return (
    <>
      <input type="hidden" {...hiddenInputProps} />
      {ad_campaign_regions_value.length === 0 && (
        <span className="text-textTertiary text-sm">원하는 동네를 선택해주세요</span>
      )}
      {ad_campaign_regions_value.map((item) => (
        <div key={item.key} className="float-left flex mr-[8px] mb-[8px] rounded-[2px] border border-line bg-bgColor">
          <button
            className="py-[10px] pr-0 pl-[8px] m-0 font-medium mmd:text-xs mmd:py-[5px]"
            onClick={(e) => onAddressButtonClick(e, item)}
          >
            {item.value}
          </button>
          <button
            className="py-[10px] px-0 mmd:py-[5px]"
            onClick={async (e) => {
              e.preventDefault()
              await deleteItem(name, setValue, getValues, item, removeGeometry)
            }}
          >
            <img
              className="min-w-[24px] mmd:min-w-[14px] mmd:mx-0 mmd:mb-[-2px] overflow-scroll"
              src={deleteIcon}
              alt="delete"
            />
          </button>
        </div>
      ))}
    </>
  )
}

export default React.forwardRef(FormMapSelectionBox)
