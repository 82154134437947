import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Navbar, Nav } from 'react-bootstrap'

import { useWindowSize } from '../../../services/utils'
import { getAddresses } from '../../../services/api'
import FormMapSelectionBox from './formMapSelectionBox'

const headers = ['시∙도', '시∙군∙구', '동∙읍∙면']

const MapMultiSelectorMobile = ({
  name,
  onAddressSelected,
  onAddressDeselected,
  onPressBack,
  selectedRegion,
  onClickDone,
  multiple = true,
}) => {
  const ref = React.useRef()
  const [width] = useWindowSize()
  const [state, setState] = React.useState(0)

  const methods = useFormContext()
  const [addresses, setAddresses] = React.useState({})
  const [selected, setSelected] = React.useState({
    depth1: null,
    depth2: null,
  })
  const selectedAddresses = methods && useWatch({ name })
  const isRenderD2 = () => selected.depth1 && Object.keys(selected.depth1.children)?.length > 0
  const isRenderD3 = () => selected.depth2 && Object.keys(selected.depth2.children)?.length > 0

  //
  React.useEffect(() => {
    setState(
      ref.current?.clientHeight,
    )
  }, [width])
  //
  React.useEffect(() => {
    (async () => {
      if (Object.keys(addresses).length > 1) return
      const result = await getAddresses()
      if (result === false) return
      result.forEach((d1_itemData) => {
        addresses[d1_itemData.id] = {
          key: d1_itemData.id,
          value: d1_itemData.name.trim().toLowerCase(),
          children: {},
          depth: 1,
        }
      })
      await setAddresses(addresses)
      await setSelected({ depth1: null, depth2: null })
    })()
  }, [addresses])
  //
  const on1stDepthClick = async (e, d1_item) => {
    e.preventDefault()
    if (Object.keys(addresses[d1_item.key].children).length < 1) {
      const result = await getAddresses(d1_item.key)
      if (result === false) return
      result.forEach((d2_itemData) => {
        const d2_item = {
          key: d2_itemData.id,
          value: [d1_item.value, d2_itemData.name]
            .join(' ')
            .trim()
            .toLowerCase(),
          children: {},
          depth: 2,
          parent: d1_item.key,
        }
        d2_itemData.children.forEach((d3_itemData) => {
          d2_item.children[d3_itemData.id] = {
            key: d3_itemData.id,
            value: [d2_item.value, d3_itemData.name]
              .join(' ')
              .trim()
              .toLowerCase(),
            depth: 3,
            parent: d2_item.key,
          }
        })
        addresses[d1_item.key].children[d2_item.key] = d2_item
      })
      await setAddresses(addresses)
    }
    await setSelected({ ...selected, depth1: d1_item, depth2: null })
    if (!multiple && onAddressSelected) await onAddressSelected(d1_item)
  }
  //
  const on2ndDepthClick = async (e, d2_item) => {
    const d1_item = selected.depth1
    e.preventDefault()
    if (d2_item.key === 'all') {
      if (selectedAddresses.some((item) => item.key === d1_item.key)) {
        await onAddressDeselected(d1_item)
      } else {
        await setSelected({ ...selected, depth2: { ...d2_item, children: [] } })
        const deselectItems = []
        await onAddressSelected(d1_item)
        if (d1_item.children) {
          selectedAddresses.forEach((item) => {
            const tempD2 = d1_item.children[item.key]
            if (tempD2) deselectItems.push(tempD2)
            Object.values(d1_item.children).forEach((item2) => {
              if (item2.children[item.key]) deselectItems.push(item2.children[item.key])
            })
          })
          // eslint-disable-next-line no-restricted-syntax
          for (const item2 of deselectItems) {
            // eslint-disable-next-line no-await-in-loop
            await onAddressDeselected(item2)
          }
        }
      }
      return
    }
    if (Object.keys(d2_item.children).length < 1) {
      const item = { ...d2_item }
      delete item.children
      if (onAddressSelected) await onAddressSelected(item)
      return
    }
    await setSelected({ ...selected, depth2: d2_item })
    if (!multiple && onAddressSelected) await onAddressSelected(d2_item)
  }
  //
  const on3rdDepthClick = React.useCallback(
    async (d3_item, isSelected) => {
      const d2_item = selected.depth2
      if (d3_item.key === 'all') {
        if (!isSelected) {
          const deselectItems = []
          await onAddressSelected(d2_item)
          if (d2_item.children) {
            selectedAddresses.forEach((item) => {
              if (d2_item.children[item.key]) {
                deselectItems.push(item)
              }
            })
          }
          // eslint-disable-next-line no-restricted-syntax
          for (const item2 of deselectItems) {
          // eslint-disable-next-line no-await-in-loop
            await onAddressDeselected(item2)
          }
        } else {
          await onAddressDeselected(d2_item)
        }
        return
      }
      if (!multiple) {
        if (onAddressSelected) await onAddressSelected(d3_item)
        return
      }
      //
      if (!isSelected) {
        const allSelected = Object.values(d2_item.children)?.every(
          (checkItem) => {
            if (checkItem.key === d3_item.key) return true
            return selectedAddresses.some((item) => item.key === checkItem.key)
          },
        )
        let selectedItem = { ...d2_item }
        delete selectedItem.children
        if (!allSelected) selectedItem = { ...d3_item }
        if (onAddressSelected) await onAddressSelected(selectedItem)
      } else {
        onAddressDeselected(d3_item)
      }
    },
    [selected],
  )
  //
  const isD3_itemChecked = React.useCallback(
    (d3_item) => (
      multiple ? (
        selectedAddresses.some((item) => item.key === d3_item.key)
      ) : selectedRegion.key === d3_item.key
    ),
    [selectedAddresses, selected],
  )
  //
  const renderRegionList = (i) => {
    if (i === 0) {
      return addresses && Object.values(addresses).map((item) => (
        <button
          key={item.key}
          onClick={(e) => on1stDepthClick(e, item)}
          className={`font-medium ${selected.depth1?.key === item.key ? 'text-main' : ''}`}
        >
          {item.value}
        </button>
      ))
    }
    if (i === 1 && isRenderD2()) {
      const isAllChecked = selectedAddresses.some((item) => item.key === selected.depth1.key)
      return (
        <>
          <button
            onClick={(e) => on2ndDepthClick(e, { key: 'all' })}
            className={`font-medium ${isAllChecked ? 'text-main' : ''}`}
          >
            전체
          </button>
          {selected?.depth1?.children && Object.values(selected.depth1.children).map((item) => (
            <button
              key={item.key}
              onClick={(e) => on2ndDepthClick(e, item)}
              className={`font-medium ${selected.depth2?.key === item.key ? 'text-main' : ''}`}
              disabled={isAllChecked}
            >
              {item.value.split(' ')[1]}
            </button>
          ))}
        </>
      )
    }
    if (i === 2 && isRenderD3()) {
      const isAllChecked = selectedAddresses.some((item) => item.key === selected.depth2.key)
      return (
        <>
          <button
            onClick={() => on3rdDepthClick({ key: 'all' }, isAllChecked)}
            className={`font-medium ${isAllChecked ? 'text-main' : ''}`}
          >
            전체
          </button>
          {selected?.depth2?.children && Object.values(selected.depth2.children).map((item) => {
            const isSelected = isD3_itemChecked(item)
            return (
              <button
                key={item.key}
                onClick={() => on3rdDepthClick(item, isSelected)}
                className={`font-medium ${isSelected ? 'text-main' : ''}`}
                disabled={isAllChecked}
              >
                {item.value.split(' ')[2]}
              </button>
            )
          })}
        </>
      )
    }
  }
  //
  return (
    <div className="fixed lg:hidden top-0 left-0 bottom-0 right-0 flex flex-col bg-white z-[2000]">
      <Navbar fixed="top" ref={ref}>
        <div
          className="flex flex-grow nav-layout-header"
          style={{ paddingLeft: 25, borderBottom: 'solid 1px #eeecea' }}
        >
          <Nav className="me-auto">
            <Nav.Link className="go-back" onClick={onPressBack}>
              지역 직접 선택
            </Nav.Link>
          </Nav>
        </div>
      </Navbar>
      <div className="flex-grow grid grid-cols-3 border-b border-line overflow-hidden" style={{ marginTop: state }}>
        {headers.map((header, i) => (
          <div key={i} className={`flex flex-col items-center overflow-scroll ${i === 1 ? 'border-x border-line' : ''}`}>
            <span className="text-textTertiary">{header}</span>
            {renderRegionList(i)}
          </div>
        ))}
      </div>
      <div className="flex py-[15px] px-[25px] min-h-[120px] max-h-[120px]">
        <div className="flex-grow p-2 mr-2 border overflow-scroll">
          {multiple ? (
            <FormMapSelectionBox
              name={name}
            />
          ) : <div className="float-left px-2 rounded-[2px] border border-line bg-bgColor">{selectedRegion.value}</div>}
        </div>
        <button className="primary larger rounded-[10px] rounded-br-none p-3 min-w-[94px]" style={{ fontSize: 14 }} onClick={onClickDone || onPressBack}>
          선택 완료
        </button>
      </div>
    </div>
  )
}

export default MapMultiSelectorMobile
