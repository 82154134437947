import React from 'react'
import { useSnackbar } from 'notistack'
//
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import fileDownload from 'js-file-download'

import ToggleMenu, { menuActions } from '../../../components/toggleMenu'
import { formatDate, getCompanySectorText } from '../../../services/utils'
//
import dotsIcon from '../../../assets/icons/icon-outlined-application-more.svg'
import readyIcon from '../../../assets/icons/icon-filled-suggested-check-circle.svg'
import infoIcon from '../../../assets/icons/icon-filled-suggested-exclamation-circle-26-px.svg'
import noteIcon from '../../../assets/icons/icon-outlined-suggested-exclamation-circle-medium.svg'
import fileIcon from '../../../assets/icons/icon-filled-application-file.svg'
import { patchCampaign, deleteAssets } from '../../../services/api'
import AppContext from '../../../services/context'
import writeIcon from '../../../assets/icons/icon-outlined-editor-form.svg'
//
import closeIcon from '../../../assets/icons/icon_outlined_suggested_close-circle_medium.svg'
import downloadIcon from '../../../assets/icons/icon-outlined-application-download-medium.svg'
import deleteIcon1 from '../../../assets/icons/icon-outlined-editor-delete-medium.svg'
import deleteIcon2 from '../../../assets/icons/icon-outlined-editor-delete-medium-disable.svg'
import editIcon1 from '../../../assets/icons/icon-outlined-editor-edit-medium.svg'
import editIcon2 from '../../../assets/icons/icon-outlined-editor-edit-medium-disable.svg'
//
const DOWNLOAD_MENU = {
  label: '다운로드',
  action: menuActions.DOWNLOAD,
  disabled: false,
  enabledIcon: downloadIcon,
  disabledIcon: downloadIcon,
}
const MENU = [
  {
    label: '수정하기',
    action: menuActions.MODIFY,
    disabled: false,
    enabledIcon: editIcon1,
    disabledIcon: editIcon2,
  },
  {
    label: '이름변경',
    action: menuActions.CHANGE_NAME,
    disabled: false,
    enabledIcon: editIcon1,
    disabledIcon: editIcon2,
  },
  {
    label: '삭제하기',
    action: menuActions.DELETE,
    disabled: false,
    enabledIcon: deleteIcon1,
    disabledIcon: deleteIcon2,
  },
]

const CardComponent = (
  {
    data, title, icon, actions, applied = false, selectedMenu, onClickMenu, onClickChangeName, onDeleteAsset,
  },
  ref,
) => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { appState } = React.useContext(AppContext)
  const [cookies] = useCookies(['jwt_token'])
  const Actions = actions

  //
  const onClickDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `${filename}.mp4`)
      })
  }

  const onMenuClick = async (e, menuItem) => {
    if (menuItem.action === menuActions.DOWNLOAD) {
      onClickDownload(data.vidoeUrl, data.assetName)
    } else if (menuItem.action === menuActions.MODIFY) {
      history.push({
        pathname: '/portal/media/build',
        state: {
          userId: appState.userInfo.id,
          assetId: data._id,
        },
      })
    } else if (menuItem.action === menuActions.CHANGE_NAME) {
      onClickChangeName(data._id, data.assetName)
    } else if (menuItem.action === menuActions.DELETE) {
      const result = await deleteAssets(cookies.jwt_token, data._id)
      if (result?.title) {
        onDeleteAsset(data._id)
        enqueueSnackbar(result.title, { variant: 'error' })
      }
    } else if (menuItem.action === menuActions.AI_MODIFY) {
      const {
        companyName,
        storeAddress,
        storeAddressDetail,
        storeTel,
        companySector,
      } = appState.adCampaign.step1
      const state = { assetId: data._id }
      if (appState.userInfo.userName) state.userName = appState.userInfo.userName
      if (companyName) state.storeName = companyName
      if (storeAddress) state.storeAddress = `${storeAddress || ''} ${storeAddressDetail || ''}`
      if (storeTel) state.storeTel = storeTel
      if (companySector) state.storeSector = getCompanySectorText(companySector)
      // console.log(state)
      history.push({
        pathname: '/portal/media/ai-build',
        state,
      })
    }
  }
  //
  const wrapperClassNames = ['asset-card', 'releaive', 'flex', 'flex-col', 'flex-grow', 'h-full']
  if (applied) wrapperClassNames.push('applied')

  // eslint-disable-next-line max-len
  let menu = MENU
  if (
    Number(appState.userInfo?.userType) === 1
    && (data.type === 2 || (data.type === 1 && data.assetStatus >= 3))
    && data.vidoeUrl
  ) {
    menu = [DOWNLOAD_MENU, ...MENU]
  }

  if (data.type === 3) {
    menu = [
      {
        label: '수정하기',
        action: menuActions.AI_MODIFY,
        disabled: false,
        enabledIcon: editIcon1,
        disabledIcon: editIcon2,
      },
      MENU[1],
      MENU[2],
    ]
  }

  return (
    <div ref={ref} className={wrapperClassNames.join(' ')}>
      <div className="flex flex-none flex-row status">
        <img src={icon} alt="status" />
        <span className="flex-grow-1 status-title">{title}</span>
      </div>
      {/**/}
      <video
        className="media"
        controls
        controlsList="nodownload"
        onContextMenu={(e) => e.preventDefault()}
        src={data.vidoeUrl}
        alt="media"
      />
      {/**/}
      <div className="flex flex-row media-title ">
        <span className="flex-grow title">{data.assetName}</span>
        <ToggleMenu
          menu={menu}
          onClick={onMenuClick}
          isSelected={selectedMenu === data._id}
          onClickMenu={() => onClickMenu(data._id)}
        >
          <img src={dotsIcon} alt="dots" />
        </ToggleMenu>
      </div>
      {/**/}
      <div className="grid grid-cols-2 grid-rows-2 dates">
        <span>마지막 수정일</span>
        <span>{formatDate(data.createdAt)}</span>
        <span>최초 생성일</span>
        <span>{formatDate(data.updatedAt)}</span>
      </div>
      <Actions data={data} />
    </div>
  )
}

const AssetCardComponent = React.forwardRef(CardComponent)

const EditActions = ({ data }) => {
  const history = useHistory()
  const { appState } = React.useContext(AppContext)
  //
  const onActionClick = async () => {
    history.push({
      pathname: '/portal/media/build',
      state: {
        userId: appState.userInfo.id,
        assetId: data._id,
      },
    })
  }
  //
  return (
    <div className="actions">
      <button onClick={onActionClick} className="large w-full secondary left-icon">
        <img src={writeIcon} alt="icon" />
        소재 완성하기
      </button>
    </div>
  )
}

const DefaultActions = ({ data }) => {
  const [cookies] = useCookies(['jwt_token'])
  const history = useHistory()
  const { appState, appDispatch } = React.useContext(AppContext)
  //
  const onAddVoiceClick = async () => {
    history.push('/portal/media/voice', { assetId: data._id })
  }
  //
  const onRegisterClick = async () => {
    const result = await patchCampaign(cookies.jwt_token, appState.adCampaign.id, { assetId: data._id }, 2)
    if (!result) return
    await appDispatch({
      type: 'updateAdCampaign',
      payload: { ...result, asset: data },
    })
    history.push('/portal/media/create/3')
  }
  //
  return (
    <div className="flex gap-[1rem]">
      <button onClick={onAddVoiceClick} className="hidden xl:block large flex-1 secondary px-2">
        성우 추가하기
      </button>
      <button onClick={onRegisterClick} className="large flex-1 primary px-2">
        소재 등록하기
      </button>
    </div>
  )
}

const AIDefaultActions = ({ data }) => {
  const [cookies] = useCookies(['jwt_token'])
  const history = useHistory()
  const { appState, appDispatch } = React.useContext(AppContext)
  //
  const onRegisterClick = async () => {
    const result = await patchCampaign(cookies.jwt_token, appState.adCampaign.id, { assetId: data._id }, 2)
    if (!result) return
    await appDispatch({
      type: 'updateAdCampaign',
      payload: { ...result, asset: data },
    })
    history.push('/portal/media/create/3')
  }
  //
  return (
    <div className="flex gap-[1rem]">
      <button onClick={onRegisterClick} className="large flex-1 primary px-2">
        소재 등록하기
      </button>
    </div>
  )
}

const AIEditActions = ({ data }) => {
  const history = useHistory()
  const { appState } = React.useContext(AppContext)
  //
  const onActionClick = async () => {
    const {
      storeAddress,
      storeAddressDetail,
      storeTel,
      companySector,
    } = appState.adCampaign.step1
    const state = { assetId: data._id }
    if (appState.userInfo.userName) state.userName = appState.userInfo.userName
    if (storeAddress) state.storeAddress = `${storeAddress || ''} ${storeAddressDetail || ''}`
    if (storeTel) state.storeTel = storeTel
    if (companySector) state.storeSector = getCompanySectorText(companySector)
    // console.log(state)
    history.push({
      pathname: '/portal/media/ai-build',
      state,
    })
  }
  //
  return (
    <div className="actions">
      <button onClick={onActionClick} className="large w-full secondary left-icon">
        <img src={writeIcon} alt="icon" />
        소재 완성하기
      </button>
    </div>
  )
}

const UnlinkActions = () => {
  const [cookies] = useCookies(['jwt_token'])
  const { appState, appDispatch } = React.useContext(AppContext)
  //
  const onActionClick = async () => {
    const result = await patchCampaign(cookies.jwt_token, appState.adCampaign.id, { asset: null }, 2)
    if (!result) return
    await appDispatch({
      type: 'updateAdCampaign',
      payload: { ...result, asset: null },
    })
  }
  //
  return (
    <div className="actions">
      <button onClick={onActionClick} className="large w-full light left-icon">
        <img src={closeIcon} alt="icon" />
        적용 해지하기
      </button>
    </div>
  )
}

const Blank = () => <div />

const AssetCard = ({
  data, selectedMenu, onClickMenu, onClickChangeName, onDeleteAsset,
}, ref) => {
  const { appState } = React.useContext(AppContext)
  if (data._id === appState.adCampaign?.step2?.ad_campaign_asset_id) {
    return (
      <AssetCardComponent
        ref={ref}
        data={data}
        title="적용된 소재입니다"
        icon={readyIcon}
        actions={UnlinkActions}
        selectedMenu={selectedMenu}
        onClickMenu={onClickMenu}
        onClickChangeName={onClickChangeName}
        onDeleteAsset={onDeleteAsset}
        applied
      />
    )
  }
  if (data.type === 2) {
    return (
      <AssetCardComponent
        ref={ref}
        data={data}
        title="내 컴퓨터에서 불러온 소재입니다"
        icon={fileIcon}
        actions={DefaultActions}
        selectedMenu={selectedMenu}
        onClickMenu={onClickMenu}
        onClickChangeName={onClickChangeName}
        onDeleteAsset={onDeleteAsset}
      />
    )
  }
  if (data.type === 1 && (data.assetStatus === null || data.assetStatus === 0)) {
    return (
      <AssetCardComponent
        ref={ref}
        data={data}
        title="소재 제작 중 입니다"
        icon={infoIcon}
        actions={EditActions}
        selectedMenu={selectedMenu}
        onClickMenu={onClickMenu}
        onClickChangeName={onClickChangeName}
        onDeleteAsset={onDeleteAsset}
      />
    )
  }
  if (data.type === 1 && data.assetStatus in [2, 1]) {
    return (
      <AssetCardComponent
        ref={ref}
        data={data}
        title="렌더링을 하고 있습니다"
        icon={noteIcon}
        actions={Blank}
        selectedMenu={selectedMenu}
        onClickMenu={onClickMenu}
        onClickChangeName={onClickChangeName}
        onDeleteAsset={onDeleteAsset}
      />
    )
  }
  if (data.type === 1 && data.assetStatus >= 3) {
    return (
      <AssetCardComponent
        ref={ref}
        data={data}
        title="소재 제작 완료 했습니다"
        icon={readyIcon}
        actions={DefaultActions}
        selectedMenu={selectedMenu}
        onClickMenu={onClickMenu}
        onClickChangeName={onClickChangeName}
        onDeleteAsset={onDeleteAsset}
      />
    )
  }
  if (data.type === 3) {
    if (data.assetStatus < 3) {
      return (
        <AssetCardComponent
          ref={ref}
          data={data}
          title="AI 광고 소재 제작 중 입니다"
          icon={infoIcon}
          actions={AIEditActions}
          selectedMenu={selectedMenu}
          onClickMenu={onClickMenu}
          onClickChangeName={onClickChangeName}
          onDeleteAsset={onDeleteAsset}
        />
      )
    }
    return (
      <AssetCardComponent
        ref={ref}
        data={data}
        title="AI 광고 소재 제작 완료 했습니다"
        icon={readyIcon}
        actions={AIDefaultActions}
        selectedMenu={selectedMenu}
        onClickMenu={onClickMenu}
        onClickChangeName={onClickChangeName}
        onDeleteAsset={onDeleteAsset}
      />
    )
  }
  console.log(data)
  return <div />
}

export default React.forwardRef(AssetCard)
