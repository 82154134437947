import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Menu from '../../components/menu'
import TutorialCarousel from './components/TutorialCarousel'
import NavigationLayout from '../../layouts/navigationLayout'
import { tutorialAdMenu, tutorialAssetMenu } from '../../services/menus'
import { adResources, assetResources } from './tutorialRes'

const TutorialAsset = () => {
  const history = useHistory()
  const [activeIndex, setActiveIndex] = React.useState(0)
  const location = useLocation()
  const type = location.pathname.split('/')[2]
  //
  const onAction = (e, id) => {
    setActiveIndex(id)
  }
  const onActionMobile = (e, id) => {
    history.push(`/tutorial/${type}/${id}`)
  }

  const onActionGoback = () => {
    history.goBack()
  }
  //
  const onClickLastArrow = React.useCallback(
    (direction) => {
      if (direction === 'right' && activeIndex < adResources.length - 1) {
        setActiveIndex(activeIndex + 1)
        return true
      }
      if (direction === 'left' && activeIndex > 0) {
        setActiveIndex(activeIndex - 1)
        return true
      }
      return false
    },
    [activeIndex],
  )
  const navTitle = type === 'ad' ? '광고 만들기 튜토리얼' : '소재 제작 튜토리얼'
  const menuItems = type === 'ad' ? tutorialAdMenu : tutorialAssetMenu
  const resources = type === 'ad' ? adResources : assetResources
  return (
    <NavigationLayout navigationTitle={navTitle}>
      <div className="flex flex-grow flex-col tutorial tutorial-asset">
        <div className="menus block lg:hidden">
          <Menu items={menuItems} onAction={onActionMobile} />
        </div>
        <div className="container flex-col hidden lg:flex">
          <div className="window-header bg-white">
            <h2>{navTitle}</h2>
          </div>
          <div className="flex flex-row bg-white">
            <div className="menus">
              <Menu
                items={menuItems}
                onAction={onAction}
                activeLink={activeIndex}
              />
            </div>
            <div className="flex flex-col flex-grow tutorial-content">
              <div className="flex flex-grow justify-center items-center video-wrapper">
                <TutorialCarousel
                  data={resources[activeIndex].largeImages}
                  onClickLastArrow={onClickLastArrow}
                />
              </div>
              <div className="flex justify-end lg-button-wrapper">
                <button className="button larger dark" onClick={onActionGoback}>그만 보기</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NavigationLayout>
  )
}

export default TutorialAsset
