import React from 'react'
import { useHistory } from 'react-router-dom'
import { Carousel } from 'react-bootstrap'
import HoverVideoPlayer from 'react-hover-video-player'

import classNames from 'classnames'
import { getBestGallery } from '../../../services/api'
import { getPromotionText, getThumbnail } from '../../../services/utils'

const BestGalleries = () => {
  const history = useHistory()
  const [bestGalleries, setBestGalleries] = React.useState([])
  const [activeIndex, setActiveIndex] = React.useState(0)
  const { length } = bestGalleries

  React.useEffect(() => {
    getBestGallery().then((result) => {
      setBestGalleries(result)
    })
  }, [])
  //
  const onClick = React.useCallback((id) => {
    history.push(`/community/${id}`, { galleryId: id })
  }, [])
  //
  const handleSelect = async (selectedIndex) => {
    await setActiveIndex(Math.abs(selectedIndex % length))
  }

  return (
    <div className="best-gallery flex flex-col xxs:px-3 px-6 py-6 lg:px-10 lg:py-10">
      <div className="self-center lg:max-w-[90vw] xl:max-w-[1134px] md:h-[105vw] lg:w-[1134px] lg:pb-[30px] lg:h-[unset]">
        <h3 className="text-white font-black text-2xl mb-[1rem] lg:text-[40px] lg:mb-6">이달의 광고</h3>
        <Carousel
          className="xxs:-mx-3 -mx-6 lg:mx-[unset] lg:h-[440px]"
          controls={false}
          activeIndex={activeIndex}
          onSelect={handleSelect}
          indicators={false}
          pause="hover"
          interval={5000}
        >
          {bestGalleries.map((item, index) => (
            <Carousel.Item
              key={index}
              className="cursor-pointer sm:h-[100vw] lg:h-full xxs:px-3 px-6 lg:px-[unset]"
              onClick={() => onClick(item._id)}
            >
              <div
                className={classNames(
                  'flex flex-col w-[85vw] min-h-[530px] bg-white xxs:p-2 p-[11px]',
                  `lg:flex lg:flex-row lg:w-[unset] lg:min-h-[440px] lg:h-full gallery-${index}`,
                )}
              >
                <div className="w-full lg:self-center lg:w-1/2 xl:w-auto xl:h-full aspect-video">
                  <div className="w-full h-full rounded-xl overflow-hidden">
                    <HoverVideoPlayer
                      className="w-full h-full"
                      videoSrc={item.videoUrl}
                      muted={false}
                      pausedOverlay={(
                        <img
                          src={getThumbnail(item.videoUrl)}
                          alt=""
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      )}
                      loadingOverlay={(
                        <div className="flex flex-center w-full h-full bg-white">
                          <div className="video-loading" />
                        </div>
                      )}
                      hoverTarget={() => document.getElementsByClassName(`gallery-${index}`)[0]}
                    />
                  </div>
                </div>
                <div className="flex flex-col flex-grow lg:ml-8">
                  <h3 className="text-md mt-3 mb-1">{item.companyName}</h3>
                  <p className="text-xs line-clamp-1">{`${item.storeAddress.address} ${item.storeAddress.addressDetail}`}</p>
                  {item.promotionType && item.promotionValue && item.promotionVisible && (
                    <p className="text-main text-md mb-0">
                      {getPromotionText(item.promotionType, item.promotionValue)}
                    </p>
                  )}
                  <div className="flex-grow">
                    <p className="text-xs text-secondary line-clamp-3 lg:line-clamp-[10] lg:whitespace-pre-wrap">
                      {`${item.promotionVisible && item.promotionDesc ? item.promotionDesc : item.storeDesc || ''}`}
                    </p>
                  </div>
                  <div className="flex justify-between items-end lg:flex-col lg:items-start">
                    {item.storeImages && item.storeImages.length > 0 && (
                      <div className="flex gap-4 w-full h-[144px] overflow-x-scroll">
                        {item.storeImages.map((image, i) => (
                          <img key={i} className="!w-[144px] aspect-square rounded object-cover" src={image} alt="" />
                        ))}
                      </div>
                    )}
                    {item.promotionLink && item.promotionVisible && (
                      <a
                        className="button primary medium max-h-[44px] min-w-[120px] px-0 text-[12px] ml-2 lg:mt-4 lg:ml-[unset]"
                        href={item.promotionLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        이벤트 바로가기
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <div className="indicators w-full flex flex-col items-center mt-2 lg:mt-[unset]">
        <div>
          {bestGalleries.map((_, index) => (
            <div
              key={index}
              className={`dot${activeIndex === index ? ' active' : ''} cursor-pointer`}
              onClick={() => handleSelect(index)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default BestGalleries
