import React from 'react'
import Header from '../components/header'
import img from '../assets/imgs/4296.svg'
import spinner from '../assets/icons/loading-loop-spinner.svg'

const Loading = () => (
  <>
    <Header />
    <main className="flex flex-grow flex-col items-center justify-center relative">
      <img src={img} alt="loading-container" />
      <img src={spinner} alt="loading" className="spinner absolute" />
    </main>
  </>
)

export default Loading
