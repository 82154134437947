import React from 'react'

const StatusDot = ({ status }) => {
  let color = 'bg-[#FFD336]'
  if (status === 'ONGOING') {
    color = 'bg-[#82DA58]'
  } else if (status === 'END') {
    color = 'bg-[#CACACA]'
  }
  return <div className={`w-[9px] h-[9px] rounded-full ${color}`} />
}

export default StatusDot
