import React from 'react'

import cx from 'classnames'
import RecommendForm from './recommendForm'
import RecommendMap from './recommendMap'
import RecommendBudget from './recommendBudget'

const RecommendTab = ({
  width, handleRecommendCities, handleRecommendBudget, recommendCallback,
}) => {
  const [step, setStep] = React.useState(0)
  const [form, setForm] = React.useState(null)

  const goMapSection = React.useCallback((formData) => {
    setForm(formData)
    setStep(1)
  }, [])

  const onClickPrev = React.useCallback(() => {
    setStep((prev) => prev - 1)
  }, [])

  return (
    <div className="relative">
      {step === 0 && (
        <h1 className={cx(
          'mt-2 mb-12 text-[28px] leading-10 text-textPrimary font-bold text-center whitespace-pre-wrap',
          'mlg:text-[18px] mlg:leading-6',
        )}
        >
          {'B tv 우리동네광고는\n우리 매장에 가장 효과적인 광고 지역을\nAI로 분석하여 추천해 드립니다.\n그럼 매장 정보와 광고에 필요한 몇 가지 조건을 입력해 보세요~'}
        </h1>
      )}
      <div className="create-ad-part">
        <RecommendForm step={step} goMapSection={goMapSection} onClickClose={recommendCallback} />
        <RecommendMap
          width={width}
          step={step}
          form={form}
          handleRecommendCities={handleRecommendCities}
          onClickPrev={() => setStep((prev) => prev - 1)}
          onClickNext={() => setStep(2)}
          onClickClose={recommendCallback}
        />
        {handleRecommendBudget && (
          <RecommendBudget
            step={step}
            category={form?.category}
            handleRecommendBudget={handleRecommendBudget}
            onClickPrev={onClickPrev}
            onClickClose={recommendCallback}
          />
        )}
      </div>
    </div>
  )
}

export default RecommendTab
