import React from 'react'
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs'

import { ContentModal } from '../../../components/modals'

const TipModal = ({ isModalShown, onClickClose }) => (
  <ContentModal
    dialogClassName="w-[552px]"
    style={{ padding: 0 }}
    isShown={isModalShown}
    onClose={onClickClose}
    bottonTitle="닫기"
  >
    <div className="w-full overflow-y-hidden">
      <Tabs
        className="overflow-visible"
        selectedTabClassName="bg-[#ffdeb4]"
      >
        <TabList className="grid grid-cols-2 w-full bg-bgColor">
          {['이용팁', '주의사항'].map((item, i) => (
            <Tab key={i} className="flex flex-center h-[67px] font-[20px] font-black text-textPrimary cursor-pointer">{item}</Tab>
          ))}
        </TabList>
        <TabPanel>
          <div className="h-[490px] py-4 px-4 overflow-y-scroll">
            <Section title="성우 선택">성우의 음성을 미리 들어보시고 광고 분위기에 맞는 성우를 선택해 주세요</Section>
            <Section title="음성 길이">내용이 길면 전달력이나 집중력이 떨어질 수 있으니 되도록 짭게 내용을 구성해 주세요</Section>
            <Section title="단어, 표현">광고에 어울리는 핵심 단어나 함축적인 표현으로 내용을 구성하시면 좋습니다.</Section>
            <Section title="음성 개수">광고의 내용 전개 및 자막 내용을 고려하여 음성 개수를 결정하세요.</Section>
            <Section title="띄어 읽기">띄어 읽기를 해야 하는 경우 &apos;,&apos;를 넣어 주세요(예시로 시스템에 맞게 특수 인식 기호 삽입)</Section>
            <Section title="영어, 숫자">내용에 영어나 숫자 등이 포함된 경우 한글로 소리나는데로 적어 주시면 좋습니다(예: KBS:케이비에스, 1234:일이삼사)</Section>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="h-[490px] py-4 px-4 overflow-y-scroll">
            <Section title="심의 기준 준수">방송광고 심의 기준에 따라 과장, 허위 등의 광고를 할 수 없습니다. 입증되거나 검증될 수 있는 사항을 넣어 주시면 좋습니다(예: 업계 최고, 1개월 50% 수익률 등의 경우 입증되어야 하며, 그렇지 못한 경우 심의에서 반려될 수도 있습니다)</Section>
            <Section title="음성과 광고 내용 일치">음성이 해당되는 광고 영상 재생 시간 내에 종료되는 것이 좋습니다.</Section>
            <Section title="음성 이용 선택">자막 내용만으로 전달력이 충분한 경우 음성을 추가하지 않으셔도 좋습니다.</Section>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  </ContentModal>
)

const Section = ({ title, children }) => (
  <div className="flex flex-col mb-2">
    <h3 className="mb-1 font-black">{title}</h3>
    <p>{children}</p>
  </div>
)

export default TipModal
