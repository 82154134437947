import React from 'react'
import cx from 'classnames'

const LabeledSection = ({
  className = '',
  containerClassName = '',
  labelWrapperClassName = '',
  labelClassName = '',
  label,
  description,
  hasDivider = false,
  children,
  renderButtons,
}) => (
  <div className={className}>
    <div
      className={cx('flex items-center justify-between mb-4', {
        'pb-4 border-b border-line': hasDivider,
      })}
    >
      <div className={labelWrapperClassName}>
        <div className={cx('text-lg font-bold lg:text-xl', labelClassName)}>{label}</div>
        <div className="text-sm text-gray-400">{description}</div>
      </div>
      {renderButtons && renderButtons()}
    </div>
    <div className={cx('flex flex-col', containerClassName)}>{children}</div>
  </div>
)

export default LabeledSection
