import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import BasicLayout from '../../layouts/basic'
import AppContext from '../../services/context'
import LeftIcon from '../../assets/icons/icon-outlined-directional-left-white.svg'

const NoticePage = () => {
  const { appState } = React.useContext(AppContext)
  const contentRef = React.useRef()
  const history = useHistory()
  const params = useParams()
  const notice = appState.notices[params.id]
  const date = new Date(notice.createdAt)
  //
  const goBack = () => {
    history.replace('/notice')
  }
  //
  React.useEffect(() => {
    contentRef.current.innerHTML = notice.contents
  }, [contentRef, notice.contents])
  //
  return (
    <BasicLayout>
      <Container>
        <div className="notice-page">
          <div className="header flex flex-col">
            <div className="breadcrumb-holder">
              <span>공지사항</span>
              <span style={{ marginLeft: 10, marginRight: 10 }}>/</span>
              <span className="tag-text">{notice.tag}</span>
            </div>
            <div className="flex flex-row justify-between">
              <h3>{notice.title}</h3>
              <span className="date">
                {`${date.getFullYear()}.${
                  date.getMonth() + 1
                }.${date.getDate()}`}
              </span>
            </div>
          </div>
          <div className="content flex flex-row">
            <div ref={contentRef} className="flex-grow-1" />
          </div>
          <div className="divider-tertiary" />
          <div className="flex direction lg:grid lg:grid-cols-3 ">
            <div className="hidden lg:block" />
            <button
              className="primary large w-full right-icon ml-8 mr-8 lg:ml-0 lg:mr-0"
              onClick={goBack}
            >
              <img src={LeftIcon} alt="target" />
              목록으로
            </button>
            <div className="hidden lg:block" />
          </div>
        </div>
      </Container>
    </BasicLayout>
  )
}

export default NoticePage
