import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import purify from 'dompurify'
import moment from 'moment'
import axios from 'axios'
import fileDownload from 'js-file-download'

import AppContext from '../../../services/context'
import { formatDate } from '../../../services/utils'
import downloadIcon from '../../../assets/icons/icon-download.svg'
import { ContentModal } from '../../../components/modals'
import useTeamNameModal from '../hooks/useTeamNameModal'
import NoticeInfo from './noticeInfo'

const NoticeContent = () => {
  const { appState } = React.useContext(AppContext)
  const notice = appState.currentFranchiseNotice
  const history = useHistory()
  const params = useParams()

  const contentRef = React.useRef()
  const {
    isModalShown, openModal, closeModal, onClickModalDone, textValue, handleChange,
  } = useTeamNameModal({
    noticeId: params.id,
    franchiseBudget: notice.franchiseBudget,
    headOfficeBudget: notice.headOfficeBudget,
  })
  //
  React.useEffect(() => {
    const contents = purify.sanitize(notice.contents)
    contentRef.current.innerHTML = contents
  }, [contentRef, notice.contents])

  const onClickDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, filename)
      })
  }

  const status = React.useMemo(() => {
    let _status = '청약 대기 중'
    if (moment(notice.startAt).isBefore(moment())) {
      _status = '청약 접수 중'
    } else if (moment(notice.endAt).isBefore(moment())) {
      _status = '청약 마감'
    }
    return _status
  }, [notice])

  return (
    <div className="flex flex-col">
      <div className="bg-white border franchise-card !pt-8 !pb-12 my-8 mlg:!border-0 mlg:!p-0 mlg:!rounded-none">
        <div className="hidden lg:block post-header">
          <h2>{notice.title}</h2>
          <span className="date text-textSecondary">{formatDate(notice.createdAt, '.')}</span>
        </div>
        <div className="flex flex-col lg:hidden post-header">
          {/* {(post.user === appState.userInfo?.id) && (
            <div className="flex self-end">
              <div
                onClick={() => {
                  history.push(`/board/${params.boardId}/edit`, post)
                }}
              >
                <span style={{ color: 'var(--text-secondary)' }}>수정</span>
              </div>
              <span className="btn-delete" onClick={onClickDelete}>
                삭제
              </span>
            </div>
          )} */}
          <h2>{notice.title}</h2>
          <div className="flex flex-row justify-between">
            <span className="author">{notice.user?.userId}</span>
            <span className="date">{formatDate(notice.createdAt, '.')}</span>
          </div>
        </div>
        <div className="divider-tertiary" style={{ marginBottom: 0 }} />
        <div className="post-container pt-4">
          <div ref={contentRef} className="post-content" />
          {notice.type === 'CAMPAIGN' && (
            <>
              <NoticeInfo notice={notice} status={status} />
              <div className="bg-line h-[1px] my-4" />
            </>
          )}
          {notice.attachedFiles && notice.attachedFiles.length > 0 && (
            <div className="flex flex-col gap-2">
              {notice.attachedFiles.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-3 space-x-1 border !border-brand rounded-[6px]"
                >
                  <span className="text-brand">{file.fileName}</span>
                  <button
                    className="py-0 px-0 mt-[-4px]"
                    onClick={async (e) => {
                      e.preventDefault()
                      onClickDownload(file.fileUrl, file.fileName)
                    }}
                  >
                    <img className="max-w-[30px] mmd:w-[14px]" src={downloadIcon} alt="delete" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center">
        <div className="flex gap-4">
          <Link to="/franchise/notice">
            <button className="large secondary !border-brand !text-brand">목록으로 가기</button>
          </Link>
          {notice.user === appState.userInfo?.id && (
            <button
              className="large brand"
              onClick={() => {
                history.push(`/franchise/notice/${params.id}/edit`, notice)
              }}
            >
              수정하기
            </button>
          )}
          {appState.userInfo?.companyRank === 1 && (
            <button
              className="large brand"
              disabled={status !== '청약 접수 중'}
              onClick={() => {
                openModal()
              }}
            >
              광고 청약하기
            </button>
          )}
        </div>
      </div>
      <ContentModal
        title="대리점명 입력하기"
        isShown={isModalShown}
        buttonVariant="brand"
        onClose={closeModal}
        onClickButton={onClickModalDone}
      >
        <div className="textbox-wrapper w-full">
          <input className="w-full" type="text" value={textValue} onChange={handleChange} />
        </div>
      </ContentModal>
    </div>
  )
}

export default NoticeContent
