import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { getProgress } from '../../services/api'
import { hideFab } from '../../services/fab'
//
const Build = () => {
  const location = useLocation()
  const history = useHistory()
  const iframeRef = React.useRef()
  const [cookies] = useCookies(['jwt_token'])
  hideFab()
  //
  React.useEffect(() => {
    const eventListener = (e) => {
      if (e.data?.projectId) {
        getProgress(cookies.jwt_token, e.data?.projectId).then((result) => {
          if (typeof result.assetStatus !== 'number') {
            return
          }
          if (result.assetStatus === 0) {
            history.goBack()
          } else {
            history.push({
              pathname: '/portal/media/result',
              state: {
                assetId: e.data?.projectId,
              },
            })
          }
        })
      }
    }
    window.addEventListener('message', eventListener)
    // const url = `https://skb.vplate.io/app/${location.state.assetId}?user=${location.state.userId}`
    const url = `https://skb-app.vplate.io/${location.state.assetId}?type=${location.state.userId}&token=${cookies.jwt_token?.accessToken}`
    iframeRef.current.src = url
    return () => {
      window.removeEventListener('message', eventListener)
    }
  }, [cookies.jwt_token, location.state, history])
  //
  return <iframe ref={iframeRef} title="build" className="h-full w-full flex-grow" />
}

export default Build
