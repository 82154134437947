import React from 'react'
import { Textbox } from './formTextbox'
//
const AutoSuggestBox = ({
  onRequested, onSelected, renderValue, ...props
}) => {
  const ref = React.useRef(null)
  const inputRef = React.useRef(null)
  const [suggestions, setSuggestions] = React.useState([])
  const [value, setValue] = React.useState('')
  const [isListShown, setIsListShown] = React.useState(false)
  const timer = React.useRef(null)
  //
  const onBlur = async (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      await setIsListShown(false)
    }
  }
  //
  React.useEffect(() => {
    document.addEventListener('click', onBlur)
    return () => document.removeEventListener('click', onBlur)
  }, [])
  //
  const callSuggestionApi = async (tempValue) => {
    const result = await onRequested(tempValue)
    if (result === false) return
    setIsListShown(result.length > 0)
    setSuggestions(result)
  }
  //
  const onChange = async (e) => {
    await setValue(e.target.value)
    if (value !== e.target.value && e.target.value.length > 0) {
      if (!onRequested) return
      if (suggestions.length > 0) setSuggestions([])
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        callSuggestionApi(e.target.value)
      }, 500)
    }
  }
  //
  const onFocus = async () => {
    if (suggestions.length > 0 && isListShown === false) await setIsListShown(true)
  }
  //
  const onSuggestionClick = async (e, item) => {
    e.preventDefault()
    await setIsListShown(false)
    inputRef.current.value = item.value
    if (onSelected) onSelected(item)
  }
  //
  return (
    <div className="search-textbox flex flex-row" ref={ref}>
      <Textbox
        {...props}
        ref={inputRef}
        onChange={onChange}
        onFocus={onFocus}
      />
      {isListShown && (
        <ul className="search-textbox-suggestions">
          {suggestions.map((item, index) => (
            <li key={index}>
              <div onClick={(e) => onSuggestionClick(e, item)}>
                {renderValue ? renderValue(item.value) : item.value}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default AutoSuggestBox
