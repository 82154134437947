import React from 'react'

// import FloatingActionButtons from '../components/fab'
import SideBarMenu from '../components/SideBarMenu'

const FabContext = React.createContext(true)

export const FabProvider = ({ children }) => {
  const [appState, appDispatch] = React.useReducer((state, action) => {
    switch (action.type) {
        case 'showFab':
          return true
        case 'hideFab':
          return false
        default:
          return state
    }
  }, true)

  const value = React.useMemo(() => ({ appState, appDispatch }), [appState])

  return (
    <FabContext.Provider value={value}>
      {children}
      {appState && <SideBarMenu />}
    </FabContext.Provider>
  )
}

export const hideFab = () => {
  const { appDispatch } = React.useContext(FabContext)
  React.useEffect(() => {
    appDispatch({ type: 'hideFab' })
    return () => {
      appDispatch({ type: 'showFab' })
    }
  })
}
