import React from 'react'
import moment from 'moment'

import { getStrTime } from '../../../services/utils'

const CONSULT_TIMES = [1100, 1120, 1140, 1300, 1320, 1340]
const format = 'YYYY-MM-DD'
const today = moment().format(format)

const TimeItem = ({
  time, selected, disabled, onClick,
}) => {
  const bgStyle = disabled
    ? 'bg-disabledBg border-0'
    : selected
      ? 'bg-main text-white border-0 font-bold' : ''
  const strTime = getStrTime(time, true)

  const onClickItem = () => {
    if (!disabled) onClick(time)
  }

  return (
    <div
      className={`flex justify-center items-center h-[50px] m-2 w-[122px] text-sm border rounded-full cursor-pointer ${bgStyle}`}
      onClick={onClickItem}
    >
      {strTime}
    </div>
  )
}

const useTimeSelector = ({ date }) => {
  const [selectedTime, setSelectedTime] = React.useState(null)

  React.useEffect(() => {
    setSelectedTime(null)
  }, [date])

  const onClick = (time) => {
    if (time === selectedTime) {
      setSelectedTime(null)
    } else {
      setSelectedTime(time)
    }
  }

  const renderTimeSelector = () => (
    <div className="relative lg:flex-grow">
      <div className="lg:absolute lg:inset-0 flex flex-wrap border p-2 overflow-scroll">
        {CONSULT_TIMES.map(((time) => (
          <TimeItem
            key={time}
            time={time}
            selected={time === selectedTime}
            disabled={moment(date).format(format) === today
              && moment().add(30, 'minute').diff(moment().hour(Math.floor(time / 100)).minute(time % 100)) > 0}
            onClick={onClick}
          />
        )))}
      </div>
    </div>
  )

  return { selectedTime, renderTimeSelector }
}

export default useTimeSelector
