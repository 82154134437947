import React from 'react'
import { Carousel /* ProgressBar */ } from 'react-bootstrap'
import leftIcon from '../../../assets/icons/icon-outlined-directional-left-circle.svg'
import rightIcon from '../../../assets/icons/icon-outlined-directional-right-circle.svg'
import btnRightIcon from '../../../assets/icons/icon-outlined-directional-right-large-primary.svg'
// import playIcon from "../../../assets/icons/icon-outlined-directional-caret-right.svg"
// import pauseIcon from "../../../assets/icons/icon-outlined-suggested-pause.svg"
//
const VideoBanner = ({ data = [] }) => {
  const [activeIndex, setActiveIndex] = React.useState(0)
  //
  const handleSelect = async (selectedIndex) => {
    await setActiveIndex(Math.abs(selectedIndex % data.length))
  }
  const onChange = async (e, state) => {
    let index = null
    if (state) index = Math.abs((activeIndex + 1) % data.length)
    else index = Math.abs((data.length + activeIndex - 1) % data.length)
    await setActiveIndex(index)
  }

  const filteredData = data.filter((item) => item !== null)

  return (
    <div className="banner-2 relative">
      <Carousel controls={false} indicators={false} activeIndex={activeIndex} onSelect={handleSelect} pause="hover">
        {filteredData
          && filteredData.map((item, index) => (
            <Carousel.Item key={index}>
              <div className="flex flex-col-reverse flex-grow items-start lg:flex-row">
                <div className="lg:min-w-[300px] content">
                  <div className="flex flex-row indicators lg:hidden">
                    {data
                      && data.map((_, index2) => (
                        <span key={index2} className={activeIndex === index2 ? 'active' : ''}>
                          {`0${index2 + 1}`}
                        </span>
                      ))}
                  </div>
                  <h3 className="whitespace-pre-wrap">{item.title}</h3>
                  <p style={{ marginBottom: 46, whiteSpace: 'pre-wrap' }}>{item.subtitle}</p>
                  {item.button && item.link && (
                    <a href={item.link} target="_blank" className="btn-right-icon" rel="noreferrer">
                      {item.button}
                      <img src={btnRightIcon} alt="right" />
                    </a>
                  )}
                </div>
                {item.image && (
                  <div className="w-full lg:w-50 lg:h-full img-section">
                    <img className="w-full h-full" src={item.image} alt="banner" />
                  </div>
                )}
              </div>
            </Carousel.Item>
          ))}
      </Carousel>
      <div className="hidden flex-row absolute indicators lg:flex">
        {data
          && data.map((item, index) => (
            <span key={index} className={activeIndex === index ? 'active' : ''}>
              {`0${index + 1}`}
            </span>
          ))}
      </div>
      <div className="flex flex-row absolute controls">
        <button onClick={(e) => onChange(e, true)}>
          <img src={leftIcon} alt="left" />
        </button>
        <button onClick={(e) => onChange(e, true)}>
          <img src={rightIcon} alt="right" />
        </button>
      </div>
    </div>
  )
}

export default VideoBanner
