import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { useWindowSize } from '../services/utils'

const BasicLayout = ({ className, noFooter = false, children }, forwardedRef) => {
  const [width] = useWindowSize()
  const ref = React.useRef()
  const [state, setState] = React.useState(0)
  //
  React.useEffect(() => {
    setState(ref.current.clientHeight)
  }, [width])
  return (
    <>
      <Header sticky ref={ref} />
      <main
        ref={forwardedRef}
        className={`flex flex-grow flex-col ${className}`}
        style={{
          marginTop: state,
        }}
      >
        {children}
      </main>
      {!noFooter && <Footer />}
    </>
  )
}

export default React.forwardRef(BasicLayout)
