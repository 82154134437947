import React from 'react'
import { useHistory } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { useCookies } from 'react-cookie'
import { isMobile } from 'react-device-detect'
import { useSnackbar } from 'notistack'

import {
  deleteInvoice, getInvoiceInfo, getInvoiceToken, postSKPayResult,
} from '../../../services/api'
import { SKPAY_REDIRECT_URL } from '../../../services/config'
import AppContext from '../../../services/context'
import MessageModal from '../../../components/modals'
//
const CampaignPaymentActions = ({ type, resultParams }, forwardedRef) => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const { appState, appDispatch } = React.useContext(AppContext)
  const { getValues } = useFormContext()
  const [cookies] = useCookies(['jwt_token'])

  let orderNumber = null
  let inicisFormStatus = null

  // const [redirect, setRedirect] = React.useState(false)
  const [errorModalInfo, setErrorModalInfo] = React.useState({
    title: '',
    isShown: false,
  })
  // const [doneModalInfo, setDoneModalInfo] = React.useState({
  //   title: '',
  //   isShown: false,
  // })
  //
  const onCancelClick = async () => {
    history.replace('/portal/media')
  }
  //
  const bankbookPayment = React.useCallback(
    async (data) => {
      console.log(data)
      const result = await getInvoiceToken(cookies.jwt_token, data)
      //
      if (!result) return
      // if (type === 'auto') {
      //   setDoneModalInfo({
      //     title: '무통장 입금\n입금 대기 중 입니다.',
      //     isShown: true,
      //   })
      //   return
      // }
      const { invoiceId } = result
      //
      await history.push({
        pathname: '/portal/media/create/result',
        state: {
          done: true,
          invoice_id: invoiceId,
          ...resultParams,
        },
      })
    },
    [cookies.jwt_token, type, history],
  )
  //
  const skpayPayment = React.useCallback(
    async (data) => {
      //
      const encryptedUserAgent = window.SKpaySDK.generateUserAgent()
      //
      const result = await getInvoiceToken(cookies.jwt_token, {
        encryptedUserAgent,
        ...data,
      })
      //
      if (!result) return
      const { invoiceId } = result
      const values = await getValues()
      //
      const successCallback = async (paymentId) => {
        const payResult = await postSKPayResult(cookies.jwt_token, {
          paymentId,
          ...values,
          ad_campaign_invoice_id: invoiceId,
        })
        if (payResult.code === 'ERROR') {
          await setErrorModalInfo({
            title: payResult.message,
            isShown: true,
          })
          return
        }
        //
        if (!payResult) return
        if (data.ad_campaign_discount_method === 'point') {
          const point = appState.userInfo.point || 0
          if (point > 0) {
            appDispatch({
              type: 'updateUserInfo',
              payload: point - data.ad_campaign_point_val,
            })
          }
        }
        // if (type === 'auto') {
        //   setDoneModalInfo({
        //     title: '결제가 완료되었습니다.',
        //     isShown: true,
        //   })
        //   return
        // }
        await history.push({
          pathname: '/portal/media/create/result',
          state: {
            done: true,
            invoice_id: invoiceId,
            ...resultParams,
          },
        })
      }
      //
      if (data.ad_campaign_cost === 0) {
        await successCallback(invoiceId)
        return
      }
      //
      const options = {
        offerToken: result.offerToken,
        orderNumber: result.invoiceId,
        authorizationGrant: result.authToken.authorizationGrant,
        redirectUri: SKPAY_REDIRECT_URL,
      }
      await window.SKpaySDK.performPaymentWithUI(options, async (skpayResult) => {
        if (!skpayResult) {
          enqueueSnackbar('결제를 취소했습니다.', { variant: 'error' })
        }
        await successCallback(skpayResult.paymentId)
      })
    },
    [cookies.jwt_token, type, history, getValues, appState, appDispatch],
  )
  //
  const fetchOrderInfo = async () => {
    const invoiceInfo = await getInvoiceInfo(cookies.jwt_token, orderNumber)
    if (!invoiceInfo) {
      enqueueSnackbar('주문 정보를 찾을 수 없습니다.', { variant: 'error' })
      return
    }
    if (invoiceInfo.status === 0) {
      await deleteInvoice(cookies.jwt_token, orderNumber)
      enqueueSnackbar('결제를 취소했습니다.', { variant: 'error' })
      return
    }
    const data = await getValues()
    if (data.ad_campaign_discount_method === 'point') {
      const point = appState.userInfo.point || 0
      if (point > 0) {
        appDispatch({
          type: 'updateUserInfo',
          payload: point - data.ad_campaign_point_val,
        })
      }
    }
    history.push({
      pathname: '/portal/media/create/result',
      state: {
        done: true,
        invoice_id: orderNumber,
        ...resultParams,
      },
    })
  }
  //
  const checkInicisFormStatus = () => {
    const node = document.querySelector('.inipay_modal-backdrop')
    if (node) return true

    const form_node = document.querySelector('#pay_form')
    if (form_node) form_node.remove()

    clearInterval(inicisFormStatus)
    if (!orderNumber) {
      enqueueSnackbar('주문 정보를 찾을 수 없습니다.', { variant: 'error' })
      return
    }

    fetchOrderInfo()
    return false
  }
  //
  const inicisPayment = React.useCallback(
    async (_data) => {
      const data = {
        ..._data,
        isMobile,
        resultParams,
      }
      const result = await getInvoiceToken(cookies.jwt_token, data)
      const { inicisPayRequestData } = result
      orderNumber = isMobile ? inicisPayRequestData.P_OID : inicisPayRequestData.oid
      if (data.ad_campaign_cost === 0) {
        fetchOrderInfo()
        return
      }
      // const invoiceInfo = await getInvoiceInfo(cookies.jwt_token, orderNumber)
      const form = document.createElement('form')
      form.method = 'post'
      form.acceptCharset = 'UTF-8'
      form.hidden = true
      form.id = 'pay_form'
      if (isMobile) {
        form.acceptCharset = 'EUC-KR'
        form.action = 'https://mobile.inicis.com/smart/payment/'
      }

      Object.keys(inicisPayRequestData).forEach((key) => {
        const input = document.createElement('input')
        input.name = key
        input.value = inicisPayRequestData[key]
        input.hidden = true
        form.appendChild(input)
      })

      document.querySelector('#payment-action').appendChild(form)
      if (isMobile) {
        form.submit()
      } else {
        window.INIStdPay.pay('pay_form')
        inicisFormStatus = setInterval(checkInicisFormStatus, 1000)
      }
    },
    [cookies.jwt_token],
  )
  //
  React.useImperativeHandle(
    forwardedRef,
    () => ({
      initiatePayment: async (data) => {
        if (data.ad_campaign_payment_method === 'bankbook') {
          await bankbookPayment(data)
        } else if (data.ad_campaign_payment_method === 'skpay') {
          await skpayPayment(data)
        } else if (data.ad_campaign_payment_method === 'inicis') {
          await inicisPayment(data)
        }
      },
    }),
    [bankbookPayment, skpayPayment],
  )
  //
  return (
    <div id="payment-action" className="flex flex-col lg:grid lg:grid-cols-2 lg:gap-8 md:ml-32 md:mr-32 actions-section">
      <button type="cancel" className="secondary larger" onClick={onCancelClick}>
        이전으로
      </button>
      {!appState.adCampaign?.ad_campaign_is_paid ? (
        <button type="submit" className="primary larger ">
          결제하기
        </button>
      ) : (
        <button type="submit" className="primary larger ">
          적용하기
        </button>
      )}
      <MessageModal type="error" isShown={errorModalInfo.isShown} onClose={() => setErrorModalInfo({ ...errorModalInfo, isShown: false })}>
        <h3>{errorModalInfo.title}</h3>
      </MessageModal>
      {/* <MessageModal
        type="success"
        isShown={doneModalInfo.isShown}
        onClose={() => setRedirect(true)}
      >
        <h3>{doneModalInfo.title}</h3>
      </MessageModal> */}
      {/* {redirect && <Redirect to="/" />} */}
    </div>
  )
}
//
export default React.forwardRef(CampaignPaymentActions)
