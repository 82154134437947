import React from 'react'
import moment from 'moment'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { useWatch, useFormContext } from 'react-hook-form'

import InfoIcon from '../../../assets/icons/icon-filled-suggested-question-circle.svg'
import InputValidation from '../../../components/inputValidation'
import { FormDateRangeBox } from '../../../components/dateRangeBox'
import { getMinDateByBusinessDays } from '../../../services/dateUtils'
// import AppContext from '../../../services/context'
// import { convertImpressionRule } from '../../../services/utils'
//
const PeriodSelector = () => {
  // const { appState } = React.useContext(AppContext)
  const { setValue, getValues, ...methods } = useFormContext()
  const [state, setState] = React.useState({ isError: false })
  //
  React.useEffect(() => {
    setState({
      isError:
        methods.formState.isSubmitted
        && (methods.formState.errors?.ad_campaign_dates || methods.formState.errors?.ad_campaign_period),
    })
  }, [methods.formState])
  //
  // const bonusRule = appState.adCampaign?.step1?.isFirst ? '1+1' : '3+1'
  // const { conditionMonth } = convertImpressionRule(bonusRule)
  const ad_campaign_dates_value = useWatch({
    name: 'ad_campaign_dates',
    control: methods.control,
  })
  const ad_campaign_period_value = useWatch({
    name: 'ad_campaign_period',
    control: methods.control,
  })
  //
  React.useEffect(() => {
    (async () => {
      if (ad_campaign_dates_value.length !== 2) return
      if (ad_campaign_dates_value.some((item) => item === null)) return
      const diffDays = moment(ad_campaign_dates_value[1]).diff(ad_campaign_dates_value[0], 'day') + 1
      const diffMonths = moment(moment(ad_campaign_dates_value[1]).add(1, 'day')).diff(ad_campaign_dates_value[0], 'month')
      await setValue('ad_campaign_period', diffDays, {
        shouldValidate: true,
      })
      await setValue('ad_campaign_period_month', diffMonths, {
        shouldValidate: true,
      })
      const minBudget = Math.max(Math.floor(((100000 / 31) * diffDays) / 10000) * 10000, 100000)
      await setValue('ad_campaign_min_budget', minBudget, {
        shouldValidate: true,
      })
      const [budget, count, recommendedBudget, isFranchise] = await getValues([
        'ad_campaign_budget',
        'ad_campaign_regions_count',
        'ad_campaign_recommended_budget',
        'is_franchise',
      ])
      const newRecommendedBudget = Math.ceil((count * 15 * 0.65 * 5 * (diffDays / 30)) / 10000) * 10000
      await setValue('ad_campaign_recommended_budget', newRecommendedBudget)
      if (!budget && !recommendedBudget && !isFranchise) {
        await setValue('ad_campaign_budget', Math.max(newRecommendedBudget, minBudget), {
          shouldValidate: true,
        })
      }
    })()
  }, [ad_campaign_dates_value, setValue, getValues])
  //
  return (
    <div className={`create-ad-part period-selector${state.isError ? ' error' : ''}`}>
      <h3 className="xs:text-base">희망하는 광고 기간을 선택해주세요</h3>
      <div className="flex flex-col justify-items-stretch justify-center">
        {/**/}
        <FormDateRangeBox
          placeholder="광고 시작일과 광고 종료일을 선택해주세요"
          name="ad_campaign_dates"
          minDate={getMinDateByBusinessDays(3)}
          validators={{
            required: true,
          }}
          renderNote={() => (
            <div className="note w-full">
              <span>꼭 알아두세요!</span>
              <span>• 최소 게재 기간은 30일 입니다.</span>
              <span>• 광고 심사를 위해, 광고 시작일은 금일부터 영업일 기준 +3일 이후로 선택해 주세요</span>
              <span style={{ color: 'var(--text-secondary)', marginLeft: '0.5rem' }}>
                예시) 광고 신청일이 7월 8일인 경우 광고 시작일을 7월 11일로 설정
              </span>
            </div>
          )}
        />
        {/**/}
        <div className="note">
          <span>꼭 알아두세요!</span>
          <span style={{ color: 'var(--text-secondary)' }}>• 최소 게재 기간은 30일 입니다.</span>
          <span style={{ color: 'var(--text-secondary)' }}>
            • 광고 심사를 위해, 광고 시작일은 금일부터 영업일 기준 +3일 이후로 선택해 주세요
          </span>
          {/* <span className="text-primary">
            {`• 게재 기간 ${conditionMonth}개월 이상, 월 50만원 이상 설정 시 ${bonusRule} 노출량 해택이 제공됩니다.`}
          </span> */}
        </div>
        {/**/}
        <div className="flex flex-row amount">
          <div className="flex-grow">
            <span>광고 기간</span>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 250 }}
              trigger={['hover', 'focus', 'click']}
              overlay={<Tooltip>선택하신 지역에 광고를 노출시키고자 하시는 전체 기간을 의미합니다.</Tooltip>}
            >
              <button type="button">
                <img src={InfoIcon} alt="info" />
              </button>
            </OverlayTrigger>
          </div>
          <span>{ad_campaign_period_value}</span>
          <span>&nbsp;일</span>
        </div>
      </div>
      <input type="hidden" {...methods.register('ad_campaign_period', { min: 30 })} />
      <InputValidation names={['ad_campaign_period', 'ad_campaign_dates']} validations={['required', 'min']} />
    </div>
  )
}

export default PeriodSelector
