import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import qs from 'query-string'

import AppContext from '../../services/context'
import BasicLayout from '../../layouts/basic'
import targetRight from '../../assets/icons/icon-outlined-directional-right-large.svg'
import SocialAuth from './components/socialAuth'

const SignUpMethod = () => {
  const history = useHistory()
  const { search } = useLocation()
  const { appDispatch } = React.useContext(AppContext)
  //
  const onEmailCLick = async () => {
    await appDispatch({
      type: 'updateSignUpMode',
      payload: { type: 'method', content: 'EMAIL' },
    })
    history.push(`/sign-up/step/2?${qs.stringify(search)}`)
  }
  //
  return (
    <BasicLayout>
      <Container className="auth-paper">
        <div className="flex flex-col justify-items-stretch items-center">
          <div className="header text-center">
            <h1>회원가입</h1>
            <p>
              <span>Btv 우리동네광고 신규회원 가입하고</span>
              <br />
              <span>다양한 서비스를 시작해보세요</span>
            </p>
          </div>
          <div className="grid xl:grid-cols-4 w-full">
            <div className="xl:col-start-2 xl:col-span-2">
              <SocialAuth />
              <div className="divider-secondary">
                <div>
                  <div />
                </div>
                <div>
                  <span>또는</span>
                </div>
              </div>
              <button className="w-full secondary large" onClick={onEmailCLick}>
                아이디로 가입하기
              </button>

              <div className="w-full flex justify-between bottom-wrapper">
                <span>이미 BTV 우리동네광고 회원이신가요?</span>
                <Link style={{ color: 'var(--text-secondary)' }} className="target right-icon" to={{ pathname: '/sign-in', search }}>
                  로그인 하기
                  <img src={targetRight} alt="target-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </BasicLayout>
  )
}

export default SignUpMethod
