import React from 'react'
import { useSnackbar } from 'notistack'

import UploadStoreImages from '../components/uploadStoreImages'
import { uploadStoreImage } from '../../../services/api'

const useUploadStoreImages = (defaultImages = []) => {
  const { enqueueSnackbar } = useSnackbar()
  const [images, setImages] = React.useState(defaultImages)
  const uploadRef = React.useRef(null)

  const onClickAdd = () => {
    if (images.length >= 5) {
      enqueueSnackbar('최대 5개까지 입력 가능합니다', { variant: 'warning' })
      return
    }
    uploadRef.current.click()
  }

  const onClickDelete = (index) => {
    setImages((prev) => prev.filter((item, i) => index !== i))
  }

  const onInputChange = (e) => {
    const files = Array.from(e.target.files).filter((file) => file.size <= 30000000)
    if (files.length !== e.target.files.length) {
      enqueueSnackbar('30MB가 넘는 파일은 업로드 할 수 없습니다', { variant: 'warning' })
      return
    }
    uploadStoreImage(files).then((result) => {
      if (result) {
        setImages((prev) => [...prev, ...result])
      }
    })
  }
  const renderUploadView = () => (
    <UploadStoreImages
      ref={uploadRef}
      images={images}
      onClickAdd={onClickAdd}
      onClickDelete={onClickDelete}
      onInputChange={onInputChange}
    />
  )

  return { images, renderUploadView }
}

export default useUploadStoreImages
