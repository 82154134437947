import React from 'react'
import Timeline from '../../../components/timeline'

const VideoToggle = ({
  data, vid1, vid2, vid3,
}) => {
  const videoRef = React.useRef()
  const [state, setState] = React.useState({
    src: vid1,
    active: 0,
  })
  //
  const onClick = async (e, index) => {
    switch (index) {
        case 0:
          await setState({ active: 0, src: vid1 })
          break
        case 1:
          await setState({ active: 1, src: vid2 })
          break
        case 2:
          await setState({ active: 2, src: vid3 })
          break
        default:
          break
    }
    videoRef.current?.play()
  }
  //
  React.useEffect(() => {
    if (!videoRef.current) return
    videoRef.current?.play()
  }, [])
  //
  return (
    <div className="hidden xl:grid xl:grid-cols-3 xl:gap-16">
      <div className="col-section">
        <Timeline data={data} onClick={onClick} />
      </div>
      <div className="col-span-2 col-section flex flex-row justify-end">
        <div>
          <video
            ref={videoRef}
            muted
            autoPlay
            playsInline
            src={state.src}
            controlsList="nodownload"
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
      </div>
    </div>
  )
}

export default VideoToggle
