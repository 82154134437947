/* eslint-disable react/display-name */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import { formatNumber, transNumToKoreanMan } from '../../../services/utils'

const WrappedCenteredMetric = (type, selectedRegions, isDesktop) => function ({ dataWithArc, centerX, centerY }) {
  const total = dataWithArc.reduce((a, b) => a + b.value, 0)
  const selectedTotal = dataWithArc.reduce((a, b) => {
    if (selectedRegions.includes(b.id) || selectedRegions.length === 0) {
      return a + b.value
    }
    return a
  }, 0)
  const unit = type === 'count' ? '건' : '원'
  return (
    <text
      x={centerX}
      y={isDesktop ? centerY + 15 : centerY + 5}
      textAnchor="middle"
      dominantBaseline="end"
      style={{
        fontWeight: 'bold',
      }}
    >
      <tspan fill={isDesktop ? '#3618CE' : '#fff'} style={{ fontSize: isDesktop ? 28 : 18 }}>
        {transNumToKoreanMan(selectedTotal, type === 'expense')}
        {unit}
      </tspan>
      <tspan fill={isDesktop ? '#666' : '#fff'} style={{ fontSize: isDesktop ? 14 : 10 }}>
          &nbsp;/&nbsp;
        {transNumToKoreanMan(total, type === 'expense')}
        {unit}
      </tspan>
    </text>
  )
}

const FranchisePieGraph = ({
  data, margin, type, selectedRegions, isDesktop = true,
}) => {
  const unit = type === 'count' ? '건' : '원'
  return (
    <ResponsivePie
      data={data}
      margin={margin}
      valueFormat={(value) => `${value}${unit}`}
      fit={false}
      innerRadius={0.85}
      activeOuterRadiusOffset={8}
      colors={{ datum: 'data.color' }}
      enableArcLinkLabels={false}
      enableArcLabels={false}
      layers={[
        'arcs',
        'arcLabels',
        'arcLinkLabels',
        'legends',
        WrappedCenteredMetric(type, selectedRegions, isDesktop),
      ]}
      tooltip={({ datum: { label, value, color } }) => (
        <div className="flex items-center px-2.5 py-1.5 gap-2 bg-white border">
          <div className="w-3 h-3" style={{ backgroundColor: color }} />
          <span className="text-textPrimary">{label}</span>
          <span className="text-textPrimary font-bold">{`${formatNumber(value)}${unit}`}</span>
        </div>
      )}
      // arcLinkLabelsSkipAngle={10}
      // arcLinkLabelsTextColor="#333333"
      // arcLinkLabelsThickness={2}
      // arcLinkLabelsColor={{ from: 'color' }}
      // arcLabelsSkipAngle={10}
      // arcLabelsTextColor={{
      //   from: 'color',
      //   modifiers: [['darker', 2]],
      // }}
      legends={
        !isDesktop
          ? []
          : [
            {
              anchor: 'top-right',
              direction: 'column',
              justify: false,
              translateX: 10,
              translateY: -120,
              valueFormat: (value) => `${value}${unit}`,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 28,
              itemTextColor: '#999',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000',
                  },
                },
              ],
            },
          ]
      }
    />
  )
}

export default FranchisePieGraph
