import React from 'react'
import moment from 'moment'
import { Table } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs'
import { useForm, FormProvider } from 'react-hook-form'

import MyPageLayout from '../../layouts/myPageLayout'
import { FormActionTextboxGroup } from '../../components/formActionTextbox'
import { getCoupons, postCoupon } from '../../services/api'
import MessageModal from '../../components/modals'
//
const dateFormat = 'YYYY/MM/DD'

const Coupon = () => {
  const methods = useForm({
    criteriaMode: 'all',
    mode: 'onSubmit',
  })
  const [data, setData] = React.useState({
    tab1: [],
    tab2: [],
  })
  const [cookies] = useCookies(['jwt_token'])
  const [modal, setModal] = React.useState({
    isShown: false,
    title: '',
    body: '',
    status: 'success',
  })

  const fetchCoupons = React.useCallback(async () => {
    const result = await getCoupons(cookies.jwt_token)
    if (!result) return
    const tab1Data = []
    const tab2Data = []
    const today = moment()
    result.forEach((item) => {
      if (item.used || today.isAfter(moment(item.expireDate))) tab2Data.push(item)
      else tab1Data.push(item)
    })
    await setData({ tab1: tab1Data, tab2: tab2Data })
  }, [cookies.jwt_token])
  //
  React.useEffect(() => {
    fetchCoupons()
  }, [fetchCoupons])
  //
  const onRegisterClick = React.useCallback(
    async (e, val) => {
      const result = await postCoupon(cookies.jwt_token, val)
      if (result === false) {
        await setModal({
          isShown: true,
          title: '잘못된 요청입니다.',
          status: 'warning',
        })
        return false
      }
      if (result.code === 'FAIL' && result.message) {
        await setModal({
          isShown: true,
          title: result.message,
          status: 'warning',
        })
        return false
      }
      if (!result.data || result.data.length === 0) {
        await setModal({
          isShown: true,
          title: '잘못된 쿠폰번호 입니다.\n쿠폰번호를 확인해주세요.',
          status: 'warning',
        })
        return false
      }

      fetchCoupons()
      await setModal({
        isShown: true,
        title: '쿠폰 등록을 완료했습니다.',
        status: 'success',
      })
      return true
    },
    [cookies.jwt_token, fetchCoupons],
  )
  //
  const onModalClose = async () => {
    await setModal({ ...modal, isShown: false })
  }
  //
  return (
    <MyPageLayout navigationTitle="쿠폰">
      <div className="header">
        <h3>쿠폰</h3>
      </div>
      <div className="coupon-page">
        <div className="register_coupons">
          <div className="form-wrapper">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(() => {})}>
                <FormActionTextboxGroup
                  id="register_coupons"
                  className="large"
                  label="쿠폰 등록"
                  name="register_coupons"
                  autoComplete="off"
                  placeholder="쿠폰번호 입력 완료"
                  onClick={onRegisterClick}
                  validators={[]}
                >
                  쿠폰 등록하기
                </FormActionTextboxGroup>
              </form>
            </FormProvider>
          </div>
          <div className="divider" />
        </div>
        {/**/}
        <div className="list-section">
          <Tabs className="tabs-primary" selectedTabClassName="active">
            <TabList className="flex tab-list-primary">
              <Tab className="tab-primary px-8">보유</Tab>
              <Tab className="tab-primary px-8">사용/만료</Tab>
              <div className="hidden flex-grow lg:block" />
            </TabList>
            {/**/}
            <TabPanel>
              <CouponTable data={data.tab1} />
              <CouponItems data={data.tab1} />
            </TabPanel>
            <TabPanel>
              <CouponTable data={data.tab2} />
              <CouponItems data={data.tab2} />
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <MessageModal type={modal.status} isShown={modal.isShown} onClose={onModalClose}>
        <h3 className="text-center" style={{ whiteSpace: 'pre-wrap' }}>
          {modal.title}
        </h3>
        <p className="text-center">{modal.body}</p>
      </MessageModal>
    </MyPageLayout>
  )
}

const CouponTable = ({ data }) => (
  <Table responsive>
    <thead>
      <tr>
        <th>#</th>
        <th>쿠폰이름</th>
        <th>할인금액</th>
        <th>조건</th>
        <th>유효기간</th>
      </tr>
    </thead>
    <tbody>
      {data.length > 0
        && data.map((item, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{item.couponPolicy?.title}</td>
            <td>{item.couponPolicy?.discountPrice}</td>
            <td>{item.couponPolicy?.description}</td>
            <td>{moment(item.expireDate).format(dateFormat)}</td>
          </tr>
        ))}
      {data.length === 0 && (
        <tr className="empty">
          <td colSpan="5">쿠폰 현황이 없습니다</td>
        </tr>
      )}
    </tbody>
  </Table>
)

const CouponItems = ({ data }) => (
  <div className="flex flex-col lg:hidden">
    {data.map((item) => (
      <div key={item._id} className="flex flex-col table-mobile-items">
        <div>
          <span style={{ fontSize: 14, color: 'var(--text-primary)' }}>{item.couponPolicy?.title}</span>
        </div>
        <div>
          <span style={{ color: 'var(--text-secondary)' }}>{item.couponPolicy?.title}</span>
        </div>
        <div>
          <span style={{ color: 'var(--text-secondary)' }}>{item.couponPolicy?.discountPrice}</span>
        </div>
        <div>
          <span style={{ color: 'var(--text-tertiary)' }}>{moment(item.expireDate).format(dateFormat)}</span>
        </div>
      </div>
    ))}
  </div>
)

export default Coupon
