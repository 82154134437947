export const text = `
B tv 우리동네광고 서비스 이용약관


제1조 (목적 및 준용) 

① 본 약관은  SK브로드밴드(주)(이하 “회사”)의 B tv 우리동네광고(이하 “지역광고”라 한다)에서 제공하는 서비스(이하 “서비스”라 한다)를 이용함에 있어 조건, 절차 등 기타 필요한 사항을 규정 함을 목적으로 합니다.

② 본 약관에서 정하지 아니한 사항은 회사의 인터넷멀티미디어방송 서비스 이용약관, 종합유선방송 이용약관의 내용을 준용합니다.

③ 본 약관 및 약관의 변경사항은 회사의 홈페이지(www.skbroadband.com 또는 btvtownad.co.kr)에 게시합니다.

제2조 (정의)

① “큐톤광고”란 회사와 인터넷멀티미디어방송콘텐츠사업자 또는 방송채널사용사업자간 약속된 시간에 광고를 제공하는 방송내용을 말합니다.

② “지역광고”란 큐톤 광고를 통해 이용자가 희망하는 지역에서 광고를 제공하는 것을 말합니다.
  
③ “이용자”란 회사와 서비스 이용계약을 체결하고 서비스를 이용하는 고객을 말합니다.

제3조 (이용신청) 

① 이용자는 회사에 본인의 실명으로 서비스의 이용을 신청하여야 하며, 신청서를 이용 신청 시에 제출하여야 합니다.

② 제 1항의 서비스 신청과 관련하여 인터넷 등을 이용한 직접 이용 신청 또는 영업대리점이나 영업사원을 통한 간접 이용을 신청할 수 있으며, 그 증빙은 녹취 또는 전자문서의 형태로 갈음할 수 있습니다.

③ 회사는 이용자와 요금납부자가 다를 경우 요금납부자의 동의 여부를 전화 등의 방법으로 확인합니다. 

제4조 (계약의 성립) 

① 이용계약은 이용자의 이용 신청에 대한 회사의 승낙에 의해 성립하며, 회사는 고객에게 요금, 위약금 등 계약의 중요 사항에 대해 설명합니다.

② 회사는 이용자의 이용 신청에 대하여 다음 각 호의 1과 같은 경우에는 승낙하지 아니하거나 유보할 수 있으며, 승낙 후에도 송출 전에는 승낙을 취소할 수 있고, 송출 후 다음 각 호의 사유가 발견된 경우에는 회사가 본 계약을 해지할 수 있습니다. 이 경우 회사는 그 사유를 이용자에게 통지하여야 합니다.

1. 타인의 명의로 신청하였거나 기타 신청내용 또는 제출서류가 허위인 경우

2. 회사가 청구한 요금을 체납하고 있는 경우

3. 설비의 여유가 없거나 기술상 서비스 제공이 불가능한 경우

4. 가입신청자나 요금납부자가 신용정보의이용및보호에관한법률, 신용정보집중기관의 신용정보관리규약, 신용정보사의 신용정보 공통관리규약 등에 따라 채무불이행정보, 공공기록정보, 금융질서 문란자 정보 등에 등록되어 있는 경우

5. 법정대리인의 동의가 없는 미성년자, 한정치산자 등의 이용신청의 경우

6. 법정대리인에 의하지 아니한 금치산자의 이용신청의 경우

7. 기타 사업자가 필요하다고 인정되는 경우

③ 회사는 이용신청에 대하여 승낙 시 서비스 제공일을 전화, e-mail 혹은 문자 서비스(SMS) 등의 방법으로 이용자에게 통지합니다.

제5조 (이용 요금 등) 

① 이용자는 회사에서 제공하는 지역광고 서비스를 이용하기 위해 지역 광고의 게제 전 또는 후에 이용요금을 회사에 지급하여야 합니다.

② 지역광고의 서비스 이용요금은 [별표 1]과 같습니다. 

③ 요금 또는 이용조건이 변경된 경우에 회사는 요금 또는 이용조건의 변경사항을 회사 홈페이지에 변경 7일 전에 공지하고, 이용자는 이를 확인한 후 이용할 수 있습니다. 이 경우 변경된 약관은 기존 이용자를 포함한 모든 서비스 이용자에게 적용됩니다.

제6조 (계약 사항의 변경) 

① 이용자가 다음 중 하나에 해당하는 계약 사항 변경 사유가 발생한 경우 즉시 이를 회사에 통보하여야 하며 변경사항을 증빙하기 위한 별도의 서류를 제출하여야 합니다.

 1. 이용계약의 승계(상속, 합병, 분할 등)

② 이용자가 다음 중 하나에 해당하는 계약 사항을 변경하고자 하는 때에는 회사에 통보하여야 합니다.

 1. 이용자 및 요금납부자의 성명, 상호, 주소, 청구지 주소, 전화번호의 변경

 2. 서비스 종류 및 계약 기간 등의 변경

③ 제 1항과 관련하여 서비스 이용상 변경 사항이 발생함에 따라 추가 금액이 발생할 수 있으며, 이용자는 이에 대한 비용 또는 요금을 납부해야 합니다.

제7조 (일시 이용중단) 

① 이용자가 서비스를 이용하지 아니할 정당한 사유가 발생하여 일정기간 이용을 정지하고자 할 경우에는 일시정지 사유 및 기간을 명시하여 일시 정지 개시 희망일 전까지 회사에 신청하여야 합니다.
 
② 이용자는 월 1회 범위 내에서 회사에 일시 이용 중단을 신청할 수 있습니다.

③ 일시 이용중단 기간 중 서비스 이용료는 부과하지 않습니다.

④ 회사는 이용자가 신청한 일시 이용중단 해소 일자에 서비스를 재개합니다.

제8조 (서비스 제공 중단) 

① 회사는 다음 중 하나에 해당하는 경우, 일정기간 동안 서비스 제공을 중단할 수 있습니다.

1. 이용자가 제10조(이용자의 의무)를 위반한 경우

2. 이용자가 요금과 가산금의 이행최고를 받고도 최초의 요금납기일 다음날로부터 1월 이내에 납입하지 않은 경우

3. 과학기술정보통신부의 시정요구가 있거나, 불법 선거운동과 관련하여 선거관리위원회의 유권해석을 받은 경우

② 회사는 서비스 제공을 중단하는 경우 그 사유 등을 이용자에게 7일전까지 통지하여야 합니다. 단, 긴급을 요하는 경우는 그러하지 않습니다.

③ 회사는 제2항의 서비스제공중단 통지에 대하여 이용자의 이의신청이 있는 경우 서비스 제공중단을 보류하고 7일 이내에 그 결과를 통지합니다.

④ 회사는 서비스 제공중단 사유가 해소되면 지체 없이 서비스의 이용을 가능하게 하여야 합니다.

⑤ 서비스 제공중단 중에는 서비스 이용료는 부과하지 않습니다.

제9조 (회사의 의무) 

① 회사는 이용자의 개인정보를 이용자의 동의 없이 공개하거나 제3자에게 제공할 수 없습니다. 다만, 관계법령에 의하여 공개하는 경우에는 그러하지 아니합니다.

② 회사는 이용자로부터 제기되는 정당한 의견이나 불만사항을 신속하고 적절하게 처리해야 하며, 처리가 곤란한 경우에는 이용자에게 그 사유를 통지하여야 합니다.

③ 회사는 이용자와 송출일을 약정한 후 송출 지연이 예상될 경우 그 사실을 이용자에게 전화, 문자서비스(SMS), 팩스, E-Mail, 방문, 우편 등의 방법으로 통지하여야 합니다.

④ 회사는 국가 비상상태, 정전, 서비스 설비의 장애 상황발생, 서비스 개선을 위한 정기점검, 이용자 가입 데이터베이스 갱신을 위한 정기점검 등의 사유로 이용자가 서비스를 이용할 수 없을 경우에는 미리 방송 또는 사업자가 지정한 홈페이지에 그 사유 및 기간을 명시하여 사전에 공지하고, 서비스 재개를 위하여 가능한 조치를 취하며, 그 사유가 해소되면 즉시 서비스의 이용이 가능하게 합니다.

제10조 (이용자의 의무) 

① 이용자는 약관을 준수하여야 합니다.

② 이용자는 지역 광고를 제작 시 다음 각 호의 관계 법령을 준수하여야 합니다.

1. 저작권법

2. 국민건강증진법, 축산물위생관리법, 의료법, 약사법 등의 방송광고에 관한 규정

3. 방송법, 인터넷멀티미디어방송사업법에 따른 방송광고심의에 관한 규정

③ 이용자는 다음 각 호에 해당하는 행위를 해서는 안됩니다.

1. 회사 및 제3자의 명예를 훼손하거나 업무를 방해하는 행위 또는 회사 및 제3자에게 손해를 가하는 행위

2. 회사 또는 제3자의 지적재산권, 초상권 등 기타 권리를 침해하는 행위

3. 회사의 서비스 및 제공된 애플리케이션을 이용하여 얻은 정보를 회사의 사전 승낙없이 복제, 유통, 조장하거나 상업적으로 이용하는 행위 또는 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위

④ 이용자는 상품의 요금 등을 지정된 기일 이내에 정해진 요금납부 방법에 따라 납입하여야 합니다.

⑤ 이용자는 다음 각호의 1에 해당하는 사유가 발생한 때에는 회사에게 그 변경을 통지해야 하며, 이를 소홀히 하여 발생하는 불이익에 대한 책임은 이용자에게 있습니다.

1. 이용자의 명의, 주소, 사업자등록번호, 전화번호, 요금청구지 주소의 변경

2. 상품의 가입, 해지 또는 일시 중단

3. 요금납입 방법의 변경

⑥ 이용자는 회사가 제반 법령 준수 등을 위해 제18조에 따라 지역광고 제작물의 수정을 요청하거나 수정방안을 제시하는 경우 지역광고 제작물을 수정해야합니다. 


제11조 (요금의 납부 방법 및 납기일) 

① 요금의 납부는 아래와 같은 방법 중 하나를 선택하도록 합니다.

1. 지로, 자동이체, 계좌이체

2. 신용카드 납부

3. 회사가 정한 타 상품 요금에 합산

② 이용요금은 선불 또는 후불로 납부합니다.

제12조 (요금의 이의 신청) 

① 이용자 또는 요금납부자는 청구된 요금에 이의가 있는 경우 청구일로부터 6개월 이내에 이의 신청을 할 수 있습니다. 다만, 회사의 책임 있는 사유로 과오납이 발생한 경우, 고객은 본문에 관계없이 청구된 요금 등에 대하여 이의신청을 할 수 있습니다.

② 회사는 제1항의 이의신청 접수 후 그 타당성 여부를 조사하여 10일 이내에 처리결과를 통지합니다. 다만, 부득이한 사유로 기간 내에 통지할 수 없는 경우에는 처리기간을 다시 지정하여 통지합니다.

③ 회사는 요금납부자의 이의신청이 타당한 경우 과오납된 금액을 즉시 정산하여 환불합니다. 다만, 요급납부자의 요청이 있는 경우에는 차기 요금에서 이를 정산할 수 있습니다.

제13조 (계약의 해지)

① 이용자가 계약을 서비스 개시 전 또는 개시 후에 해지하는 경우 해지 희망일 1일 전까지 신청하여야 하며, 이용자는 해지 희망일 1일 전까지 [별표 2]의 구비서류를 제출하여야 합니다. 신청방법은 이용자 본인 또는 대리인이 직접 전화 접수, e-mail 등으로 신청할 수 있습니다.

② 회사는 이용자(본인 또는 대리인)의 해지 신청시 [별표 2]의 구비서류를 확인한 후 해지신청을 접수하고, 해지 희망일에 해지처리를 하며, 해지처리 이후에는 더 이상 과금하지 아니합니다.

③ 회사가 요금 완납 이전에 해지처리를 했더라도 이용자는 해지 전의 미납요금에 대해서는 납입의 의무를 지고 미납으로 인한 불이익을 당할 수 있습니다.

④ 회사는 제1항의 규정에 의거하여 해지신청 접수 및 해지 완료시 아래의 방법 중 하나 이상의 방법으로 이용자에게 안내합니다.

1. 문자서비스(SMS)

2. E-mail

3. 전화 (휴대폰 또는 집전화)

4. 기타

⑤ 회사의 책임 있는 사유로 해지 희망일 이후에 해지처리가 되는 경우에는 해지 희망일에 해지된 것으로 하여 이용료를 정산하며, 회사의 책임 있는 사유로 부당하게 요금을 수령한 경우 부당 수령일 경과일수에 따라 부당 수령액과 부당 수령액에 대한 법정이자를 포함한 금액을 이용자에게 반환합니다.

⑥ 회사는 다음 중 하나에 해당하는 경우에는 계약을 해지할 수 있습니다.

1. 타인의 명의를 사용하거나 허위서류를 제출한 경우

2. 이용자에게 책임 있는 사유에 의한 서비스 제공 중단 후 1개월 이내에 이용자가 서비스제공 중단의 사유를 해소하지 않는 경우

3. 회사의 사전 승인 없이 서비스를 이용하여 영업활동을 하거나 타인에게 서비스를 제공한 경우

4. 본 계약 조항을 위반한 경우

⑦ 이용자는 다음에 해당하는 경우 위약금 없이 계약을 해지할 수 있으며, [별표 2] 구비서류에 기재된 증빙서류를 회사에 제출하여야 합니다. 이를 제출하지 않을 경우 위약금이 청구될 수 있습니다.

1. 회사의 책임 있는 사유로 이용자가 청약한 지역광고의 집행시작일이 1개월 이상 지연된 경우

2. 이용자의 군 입대, 사망 등으로 인해 서비스 이용이 불가능한 경우

3. 서비스 이용 계약시 이용자에게 위약금에 대한 사항을 안내하였음을 증명하지 못할 경우

제14조 (계약의 연장)

① 계약기간 만료일 30일 전에 회사는 이용자에게 개별적으로 기존 계약의 연장 여부를 묻는 통지를 합니다. 이용자가 통지를 받은 날로부터 30일 이내에 별도의 의사표시를 하지 않으면 기존 계약은 동일한 조건으로 연장됩니다.

② 제1항에 따라 계약이 연장된 이용자가 연장된 계약기간 중에 서비스를 해지하더라도 위약금은 부과하지 않습니다.

③ 회사는 판촉활동 등을 위해 제공한 무료서비스 제공기간 만료 후 이용자의 동의없이 유료 서비스로 전환하여서는 아니되며, 유료서비스로 전환하기 위해서는 무료 서비스 종료 최소 7일 전까지 반드시 유료전환 여부에 대한 사항을 전화, 이메일 또는 문자서비스(SMS) 등을 통해 이용자에게 통지하고 동의를 얻어야 합니다.(다만, 제공된 무료서비스가 유료약정에 따른 것인 경우는 제외합니다.)

제15조 (손해 배상 및 면책)

① 회사는 회사에게 책임있는 사유로 이용자가 청약한 지역광고의 약정된 송출 기간 내에 노출 횟수를 채우지 못하는 경우 이용자와 협의하여 송출기간을 연장하거나, 잔여 노출 횟수에 해당하는 청약금액을 이용자에게 반환하고 송출을 종료합니다.

② 제1항의 규정에도 불구하고 회사는 다음 중 하나에 해당하는 경우 손해배상의 책임이 면제됩니다.

1. 전시, 사변, 천재지변, 법령의 변경 기타 이에 준하는 불가항력 사유에 의한 경우

2. 서비스를 제공할 당시 과학기술 수준으로 서비스의 결함 존재를 발견할 수 없었거나, 법령이 정하는 기준을 준수하였음에도 서비스 장애 등의 이유로 서비스를 이용하지 못한 경우

3. 서비스의 효율적 제공을 위한 시스템 및 망 관련 공사 등의 사유로 이용자에게 사전에 통지한 경우

③ 회사는 이용자가 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스 자료에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서는 그 책임이 면제됩니다.

④ 회사는 이용자 상호간 또는 이용자와 제3자 상호간에 서비스를 매개로 하여 물품거래 등을 한 경우에는 그 책임이 면제됩니다.

⑤ 회사는 이용자가 지역광고 제작 시 입력한 텍스트와 이미지, 비디오 클립에 의해 제10조 2항의 관련 법령을 위반한 경우에는 그 책임이 면제됩니다.

제16조 (이용자 정보 보호)

① 이용자의 개인정보 보호 및 취급에 관하여는 회사 서비스 홈페이지 (www.skbroadband.com)를 통해 공시된 개인정보취급방침에 따릅니다.

제17조 (저작권의 귀속 및 이용 제한)

① 이용자가 지역광고 제작 시 직접 입력한 텍스트와 이미지, 비디오 클립 외에 제공된 모든 지적 재산은 회사 또는 회사의 협력사의 재산입니다.

② 이용자가 제작한 지역광고 저작물이 정보통신망법, 저작권법 등 관계 법령에 위반되는 내용을 포함하는 경우, 권리자는 권리자임을 소명하는 자료와 권리침해사실을 소명하는 자료를 첨부하여 이메일 또는 우편으로 회사에 해당 저작물의 송출 중단 및 삭제를 요청할 수 있습니다.

③ 회사는 권리자에게 진행상황을 통지하고 관련법에 따라 조치를 취하며, 이 사실을 신청인에게 통지하고 웹페이지 게시 등의 방법으로 이해관계자가 알 수 있도록 합니다.

④ 회사는 권리자의 요청이 없는 경우라도 권리 침해가 인정될만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 저작물에 대한 접근을 임시적으로 차단하는 조치 등을 취할 수 있습니다.

⑤ 회사는 제2항에 따른 정보의 삭제 요청에도 불구하고 권리의 침해여부를 판단하기 어렵거나 이해 당사자 간에 다툼이 예상되는 경우에는 30일 이내의 기간을 정하여 해당 정보에 임시 조치를 할 수 있습니다.

제18조 (지역광고 송출)

① 회사는 이용자가 직접 입력한 텍스트와 이미지, 비디오 클립 등을 포함하여 제작된 지역광고 제작물에 대해 회사 또는 협력사를 통해 제반 법령 준수 여부, 미풍양속 위반 여부, 회사 운영 규정 위반 여부에 대해 점검하고, 위반 시 이의 수정 또는 수정 방안을 제시할 수 있습니다.

② 회사는 제반 법령 준수 등을 위해 회사 및 회사의 협력사를 통해 검수가 완료된 지역광고 제작물만을 송출합니다.

③ 이용자가 회사의 검수 결과를 수용하지 못하는 경우 이용자는 계약을 해지할 수 있습니다. 
  
제19조 (광고 심의 기준)

① 회사의 지역광고 심의 기준은 [별표 3]과 같습니다.

② 지역광고 심의 기준에 포함되어 있지 않거나, 판단이 모호한 지역광고의 경우 회사는 지역광고심의위원회를 구성하여 위원회의 결정에 따라 해당 지역광고의 노출 여부를 판단할 수 있습니다.
 
제20조 (지역광고 제작물)

① 회사에서 제공한 광고 영상 템플릿을 이용하여 이용자가 제작한 지역광고 제작물은 지역광고 웹사이트에서 예시용으로 공개될 수 있습니다.

② 이용자는 자신의 지역광고 제작물의 웹사이트 상 공개를 거부할 수 있으며, 웹사이트 관리자에게 이메일 등으로 거부 의사를 통보 시 회사는 해당 광고 제작물의 공개를 중단합니다.

부칙

제1조 (시행일) 본 약관은 2021년 4월 8일부터 시행합니다.



[별표 1] 이용 요금

광고 단가(15초 기준): 6.5원 이상(부가세 포함 7.15원 이상)
요금 하한: 월 100,000원(부가세 포함 110,000원)
지역 광고 노출 지역: 구나 동 단위

※ 광고 단가는 이용자의 광고를 시청자가 시청하는 기준이며, 타게팅(광고 노출 시간대, 대상 채널, 광고지역 등)에 따라 조정될 수 있습니다.

※ 지역 광고 노출 지역은 구나 동 단위의 B tv 시청자 위치 정보 차이에 의해 일부 차이가 발생될 수 있습니다.



[별표 2] 구비 서류

1. 개인

본인: 가입신청서, 본인 신분증
대리인: 가입신청서, 명의인 신분증, 위임장 및 인감증명서, 대리인 신분증

2. 개인사업자 및 법인

개인사업자
본인: 가입신청서, 본인(대표자) 신분증, 사업자등록증
대리인: 가입신청서, 위임장 및 인감증명서, 사업자등록증, 대리인 신분증

상장/비상장/공공법인
개인: 가입신청서, 사업자등록증, 법인인감증명서, 대리인신분증, 위임장

국가기관 또는 지방자치단체: 가입신청서, 고유번호증(또는 공문), 대리인신분증, 직인이 날인된 위임장



[별표 3] 광고 심의 기준

1. 일반 기준
1.1. 다음 각 호의 어느 하나에 해당하는 경우에는 광고를 할 수 없다.
1.1.1. 법령에서 광고를 금지하고 있는 경우
1.1.2. 법령에서 금지된 내용
1.1.3. 선교에 관한 전문편성을 행하는 방송의 경우를 제외하고, 종교에 관한 광고는 종교단체의 행사를 고지하는 내용이나 종교 관련 제품의 판매에 관한 내용 이외의 종교신앙에 관한 내용을 다루어서는 아니된다.
1.1.4. 광고는 법령 또는 선거관리위원회의 결정사항에 따른 경우를 제외하고 정당의 행사안내, 행사고지, 정책홍보, 당원모집 공고 등 정치활동에 관한 내용을 다루어서는 아니된다.
1.2. 다음 각 호의 상품과 용역에 대해서는 방송광고를 할 수 없다.
1.2.1. 식품위생법 시행령에 따른 단란주점영업 및 유흥주점영업
1.2.2. 사설비밀조사업 및 사설탐정
1.2.3. 혼인매개, 이성교제 소개업. 다만, 결혼중개업의 관리에 관한 법률에 따라 신고한 국내결혼중개업은 제외
1.2.4. 점술, 심령술, 사주, 관상 등의 감정 및 미신과 관련된 내용
1.2.5. 무기, 폭약류 및 이와 식별이 어려운 모조품
1.2.6. 도박 및 이와 유사한 사행행위
1.2.7. 담배 및 흡연과 관련된 광고
1.2.8. 조제분유, 조제우유
1.2.9. 음란한 내용의 간행물, 영상제작물, 공연물, 전기통신을 통한 음성정보영상정보 및 문자정보
1.2.10. 성 기구(성적 만족감 충족을 목적으로 제작 사용되는 도구를 말한다) 및 그 밖에 청소년에게 음란한 행위를 조장하는 성 관련 용품
1.2.11. 금융관련법령에 의해 인허가 받지 않거나 등록하지 않은 금융업 안마시술소
1.2.12. 알콜 성분 17도 이상의 주류
1.2.13. 위 규정에서 금지하고 있는 상품과 용역 등을 주된 소재로 다룬 광고는 할 수 없음. 다만, 소비자에게 광고효과를 주지 않는 경우에는 그러하지 아니 함
1.2.14. 회사의 서비스 내용으로 오인될 수 있거나, 서비스에 피해를 줄 수 있는 광고
1.2.15. 회사와 경쟁관계에 있는 광고
1.2.16. 회사의 이미지를 손상시킬 수 있는 광고
1.3. 국가공무원법 또는 지방공무원법에 따른 공무원은 방송광고에 출연할 수 없다. 다만, 공익을 목적으로 하는 법령 또는 선거관리위원회의 결정에 따른 정치광고에 출연하는 경우에는 그러하지 아니하다.
1.4. 광고 심의 규정에 포함되지 않거나, 판단이 모호한 경우 회사는 광고심의위원회를 구성하고, 위원회의 결정에 따라 광고 노출 여부를 판단할 수 있다.

2. 광고 시간의 제한
2.1. 주류 광고의 경우 07:00~22:00시에는 광고를 할 수 없음.
2.2. 대부업 광고는 평일 07:00~09:00, 13:00~20:00, 주말 07:00~20:00시에는 광고를 할 수 없음.
2.3. 청소년 유해매체물(영화, 음반, 비디오, 간행물 등을 포함한다)의 광고는 어린이 및 청소년을 대상으로 하는 방송 프로그램의 광고 시간 또는 전후 자막광고 시간에 광고를 할 수 없음.
2.4. 어린이 의약품의 광고 및 유료전화 정보서비스의 광고는 어린이를 주시청 대상으로 하는 방송 프로그램의 광고시간 또는 전후 토막 광고시간에 편성 또는 방송 할 수 없음.

3. 식품 광고 심의 기준
3.1. 식품광고는 식품위생법에 의해서 신고 또는 허가 받은 사항과 다른 내용의 광고를 하여서는 아니된다. 다만, 제조방법에 대하여 연구하거나 발견한 사실에 대한 식품학·영양학 등의 문헌을 인용하여 문헌의 내용을 정확히 표시하고, 연구자의 성명, 문헌명, 발표 연월일을 명시하는 경우는 제외한다.
3.2. 식품의 영양소 함량에 대한 내용을 광고할 경우에는 다음과 같은 표현을 하여서는 아니 된다.
3.2.1. 객관적인 근거 없이 무가당, 저당도, 저지방, 저칼로리 등의 영양소 함량을 표현하는 내용
3.2.2. 영양소의 함량을 구체적으로 명기함이 없이 특정 영양소의 함량이 일반적인 식품보다 현저한 차이가 있다고 강조하는 내용
3.3. 식품광고는 국민의 건강을 위하여 소비자를 기만하거나 오인하게 하는 다음의 표현을 하여서는 아니된다.
3.3.1. 의약품으로 오인할 우려가 있는 내용
3.3.2. 의약품처럼 질병의 치료나 예방에 효능이 있다거나 이를 암시하는 내용
3.3.3. 식품위생법에서 규정하고 있는 유용성 등에 대한 허용범위를 벗어나는 내용
3.3.4. 화학적 합성품을 사용한 식품을 마치 천연원료를 사용한 식품인 것처럼 표현하는 내용
3.3.5. 직접 사용해 본 경험이 있다는 소비자가 추천, 보증 또는 감사를 표현하는 내용
3.3.6. 의사, 한의사, 식품영양사 등 의료인이나 전문인이 추천 또는 보증하는 내용
3.4. 어린이용 가공식품 광고는 다음과 같은 표현을 하여서는 아니된다.
3.4.1. 정상적인 식사를 대용할 수 있다는 표현
3.4.2. 모유를 대체한다거나 모유 수유를 경감할 수 있다는 표현

4. 주류 광고 심의 기준
4.1. 주류에 관한 광고는 건전한 사회질서와 국민건강, 그리고 청소년의 건실한 생활을 해치지 않도록 특별히 유의하여야 한다.
4.2. 주류광고는 건강을 해치거나 청소년의 건실한 생활을 해칠 우려가 있는 다음의 표현을 하여서는 아니 된다.
4.2.1. 음주가 사회적 인정이나 성공에 필요하다고 주장하거나 이를 암시하는 표현
4.2.2. 적당한 음주가 건강에 좋다는 표현
4.2.3. 음주가 근심, 걱정을 없애준다거나 치료에 도움이 된다는 표현
4.2.4. 운동, 등산, 작업중에 음주하는 것을 묘사하는 표현
4.2.5. 음주를 운전이나 위험한 일에 긍정적으로 연결하는 표현
4.3. 주류광고에 등장하는 인물은 19세 이상이어야 한다.
4.4. 주류가 아닌 다른 상품의 광고에서도 제1항의 각 호에서 금지된 내용의 표현을 하여서는 아니된다.

5. 농약 광고 심의 기준
5.1. 농약에 관한 광고는 다음 각 호의 표현을 하여서는 아니된다.
5.1.1. 농약의 명칭 또는 효과에 관하여 오해를 가져올 수 있는 표현
5.1.2. 농약의 오용과 남용을 조장하는 표현
5.1.3. 농촌진흥청(소속시험장연구소·도농업기술원 포함) 및 농업에 관한 시험 연구기관 또는 검사기관에서 추천·지도 또는 선용하고 있다는 등의 표현
5.1.4. 구입량 및 구입기간 등을 구체적으로 명시하지 아니한 "구입주문쇄도" 등의 표현
5.1.5. 사은품 또는 현상품을 제공한다는 표현
5.1.6. 농약의 사용을 직접적으로 강요하거나 농약의 사용이 농사에 필수적이라고 주장하는 표현
5.1.7. 농약의 위험성을 부정하거나 안전을 강조하는 표현

6. 건강관련 제품 광고 심의 기준
6.1. 건강보조기구나 건강관련상품을 광고할 경우에는 ‘건강기능식품’, ‘건강보조기구’ 등 명확한 내용이 표기되어야 하며 다음과 같은 표현을 하여서는 아니된다.
6.1.1. 의약품이나 의료기구로 오인할 우려가 있는 내용
6.1.2. 의약품이나 의료기구와 같은 효능·효과를 주장하는 내용
6.1.3. 건강상태에 대한 불필요한 공포감이나 불안감을 조장하는 내용
6.1.4. 객관적인 근거없이 안전성을 보장한다는 표현
6.2. 건강관련제품의 효능·효과는 공공기관에 의해 객관적으로 입증된 경우가 아니면 표현하여서는 아니된다.
6.3. 건강관련제품의 생산·보급이 국가나 공공기관의 추천이나 지원을 받고 있지 않으면 이를 주장하거나 암시하는 표현을 하여서는 아니된다.

7. 화장품 광고 심의 기준
7.1. 화장품 광고(기능성 화장품 포함)는 소비자를 오도 또는 기만할 우려가 있는 다음의 표현을 하여서는 아니 된다.
7.1.1. 의약품으로 오인하게 하는 표현이나 이를 암시하는 표현
7.1.2. 기능성 화장품이 아닌 것을 기능성 화장품으로 오인하게 하는 표현
7.1.3. 주성분이 아닌 원료나 성분을 과장하여 주성분으로 오인하게 하는 표현
7.1.4. 허가 받은 사항 이외의 효능을 나타내는 표현
7.1.5. 부작용이나 주의사항을 부정하는 표현
7.1.6. 사용전과 사용 후를 뚜렷하게 대비하여 효능을 과신하게 하는 표현

8. 수입상품 광고 심의 기준
8.1. 수입상품에 대한 광고는 원산지 및 수입자 및 판매자를 밝혀야 한다.
8.2. 수입상품의 광고는 수입상품임을 과시하여 근거 없이 우수성을 과신하게 하거나 문화적, 경제적 사대성향을 조장하여서는 아니 된다.

9. 의약품 등 광고 심의 기준
9.1. 의약품 등(의약부외품, 의료용구, 위생용품 포함, 이하 '의약품 등'이라 한다)의 광고는 국민건강을 위하여 약품의 오용과 남용을 조장하여서는 아니 된다.
9.2. 의약품 등의 광고는 오용과 남용을 조장하는 다음의 표현을 하여서는 아니 된다.
9.2.1. 허가 받은 사항 이외의 효능을 나타내는 표현
9.2.2. 부작용이나 주의사항을 부정하는 표현
9.2.3. 계속 사용을 부당하게 권장하는 표현
9.2.4. 근거 없이 특정 연령, 특정 계층을 대상으로 의약품의 남용을 조장하는 표현
9.2.5. 근거 없이 효능의 속효성과 지속성을 강조하는 표현
9.3. 의약품 등의 광고는 소비자를 현혹할 우려가 있는 다음의 표현을 하여서는 아니 된다.
9.3.1. 약품의 사용전과 사용 후를 뚜렷하게 대비하여 효능을 과신하게 하는 표현
9.3.2. 주성분이 아닌 원료나 성분을 과장하여 주성분으로 오인 또는 약효를 과신하도록 하는 표현
9.3.3. 의약품의 복용이 건강유지나 장수 등 신체적, 정신적 건강에 필수적임을 암시하는 표현
9.3.4. 의약품을 화장품이나 식품으로 오인하게 하는 표현
9.3.5. 질병증상에 대한 진단, 처방 및 치료방법을 제시하여 소비자로 하여금 자가진단과 치료를 유도하는 표현
9.3.6. 의료인, 의약전문인 또는 보조인을 통하여 효능, 성능을 입증하거나 추천, 지정, 권장하는 표현
9.3.7. 사용결과에 대한 감사장, 추천장, 체험담을 이용하거나 "주문쇄도"라는 등으로 소비자를 현혹하는 표현
9.3.8. 국내외의 의료기관, 연구기관 등의 명칭으로 추천 또는 실험결과를 표시하거나 암시하는 표현
9.4. 의약품 등의 광고는 소비자에게 공포감이나 불쾌감을 주는 다음의 표현을 하여서는 아니된다.
9.4.1. 질병에 대한 불안감, 공포감을 조성하거나 위협적으로 호소하는 표현
9.4.2. 소비자가 병에 걸린 것처럼 인식하게 하는 막연한 증상이나 포괄적 증상의 표현
9.4.3. 환자를 조롱하거나 질병을 저속하고 품위없이 다루는 표현
9.4.4. 인체해부도 및 수술 장면을 사실적으로 묘사하는 표현. 지나치게 희화적인 표현이나 모델의 연기

10. 의료 광고 심의 기준
10.1. 의료법인, 의료기관 또는 의료인이 아니면서 의료에 관한 광고를 하여서는 아니 된다.
10.2. 의료에 관한 광고는 국민의 건강한 생활을 보호하기 위하여 다음의 표현을 하여서는 아니 된다.
10.3. 특정 의료기관이나 의료인의 기능, 진료방법, 조산방법이나 경력 또는 약효 등에 관한 표현
10.4. 학술목적 이외의 목적으로 예방의학적, 임상의학적 연구결과, 기능, 약효, 진료 등에 관한 표현
10.5. 의학 또는 과학적으로 검증되지 아니한 건강비법 등의 표현

11. 통신판매 광고 심의 기준

11.1. 통신판매광고는 소비자의 피해나 소비자와의 분쟁을 막기 위하여 다음 사항을 명시하여야 한다.
11.1.1. 소비자가 광고주 및 상품제공자와 연락할 수 있는 정확한 연락처
11.1.2. 상품의 종류, 제원 및 판매가
11.1.3. 판매가격 이외의 추가비용 여부 및 그 내역
11.1.4. 대금지급 및 상품인도의 시기와 그 방법
11.1.5. 애프터 서비스나 반품의 방법 및 그 기간
11.1.6. 상품판매량, 신청기간의 제한 등 기타 특별한 통신판매조건이 있는 경우에는 그 내용
11.2. 소비자에게 제공된 상품은 광고내용과 반드시 일치하여야 한다.
11.3. 청소년에게 위해로운 상품은 통신판매광고를 하여서는 아니 된다.

12. 금융 등 광고 심의 기준
12.1. 금융에 대한 광고는 과장, 생략이나 기타 방법을 사용해 소비자가 오인하지 않도록 다음의 사항을 준수하여야 한다.
12.1.1. 소득세나 기타 세금 혜택에 대해 언급할 때에는 실제로 그것이 어떻게 적용되는지에 대한 구체적인 설명을 하여야 한다.
12.1.2. 예금이자는 광고가 되는 시점에서 통용되는 것이어야 한다.
12.1.3. 소비자들이 오해할 소지가 있고 명시되지 않은 변수에 근거한 이윤의 계산을 하여서는 아니 된다.
12.1.4. 변동가능 한 이자율이나 조건 등에 대한 표현은 부정확하거나 오해의 소지가 있어서는 아니된다.
12.1.5. 보험과 관련된 이율이나 조건 등에 대한 표현은 부정확하거나 오해의 소지가 있어서는 아니된다.
12.1.6. 생명보험 광고에서 보험 약정 총액을 제시할 때에는 가입자의 나이, 성별, 보험기간, 지불해야 하는 보험료의 액수와 횟수 등 그 액수를 수령하는데 필요한 조건들을 함께 명시해야 한다.
12.2. 보험상품에 대한 광고는 보험업법 등 관련 법규를 준수하여야 하며, 다음 각 호의 사항을 포함하여야 한다.
12.2.1. 보험계약 체결 이전에 상품설명서 및 약관을 읽어 볼 것을 권유하는 내용
12.2.2. 기존에 체결한 보험계약을 해지하고 다른 보험계약을 체결하는 경우 보험인수의 거절, 보험료 인상 또는 보장내용의 변경이 있을 수 있다는 내용
12.2.3. 보험상품에 대한 광고는 주계약 및 특약별 보험료의 납입기간과 보험기간이 다른 경우 이를 구분하여야 하며, 보험계약 체결 후 1년3년·5년 별로 납입한 보험료 및 해약환급금 내용 예시
12.2.4. 생명보험 광고에서 보험 약정 총액을 제시할 때에는 가입자의 나이, 성별, 보험기간, 지불해야 하는 보험료의 액수와 횟수 등 그 액수를 수령하는데 필요한 조건
12.2.5. 그 밖에 보험업법 시행령 제42조의4제1항 및 제2항에 따른 사항
12.3. 사채, 사금융 등 대부업 관련 광고는 관련법규를 준수하여야 하며, 허용되는 경우에도 다음 각 호의 사항을 포함하여야 한다.
12.3.1. 명칭 또는 대표자 성명
12.3.2. 대부업 등록번호
12.3.3. 대부이자율(연 이자율로 환산한 것을 포함한다) 및 연체이자율
12.3.4. 이자 외에 추가비용이 있는 경우 그 내용
12.3.5. 그 밖에 시청자 보호를 위하여 대부업 등의 동륵 및 금융이용자 보호에 관한 법률 시행령(이하 이 조에서 "대부업법시행령"이라 한다) 제6조제2항 각 호로 정한 사항
12.4. 대부업에 대한 방송광고는 다음 각 호의 방식에 따라 방송하여야 한다.
12.4.1. 대부업자등의 상호 글자는 상표의 글자보다 크게 하고, 쉽게 알아볼 수 있도록 할 것
12.4.2. 등록번호, 전화번호, 대부이자율, 대부계약과 관련된 부대비용 및 대부업법시행령 별표1에 따른 과도한 차입의 위험성 경고 문구 등은 상호 글자 크기와 같게 하고, 그 밖의 광고사항과 쉽게 구별할 수 있도록 할 것
12.4.3. 그 밖에 대부업법시행령 별표 1에 따른 대부업자등의 광고 표시기준을 준수할 것

13. 투자자문업 광고 심의 기준
13.1.1. 자본시장과 금융투자업에 관한 법률 및 증권거래법 규정에 의한 투자자문업, 투자일임업에 대한 광고에 있어서는 다음 각 호의 표현을 하여서는 아니 된다.
13.1.2. 일정 수익 또는 수익률의 실현을 약속하거나 보장하는 표현
13.1.3. 고객 또는 제3자가 당해 투자자문회사를 추천하거나 보증하는 내용을 표시하는 내용
13.1.4. 투자자문계약재산 또는 투자일임계약재산의 특정기간 운용실적을 발췌하여 광고하는 행위
13.1.5. 투자자문회사가 이용하는 특정 투자분석기법이 투자자문계약재산 또는 투자일임계약재산의 운용실적을 일정 수준 이상으로 확보하여 준다고 광고하는 행위

14. 음성정보 서비스 광고 심의 기준
14.1. 음성정보서비스의 광고는 제공되는 서비스의 내용, 국제 혹은 국내전화요금의 여부, 단위당 사용료 등을 밝혀야 한다.

15. 학원 등 사설교육 과정 광고 심의 기준
15.1. 학원 등 사설교육과정 광고는 소비자를 오도할 수 있는 다음과 같은 표현을 하여서는 아니 된다.
15.1.1. 공공교육기관으로 오인할 수 있는 내용
15.1.2. 근거 없이 정부나 지방단체 또는 공공교육기관 등과 특별한 관계가 있는 것처럼 오인할 수 있는 내용
15.1.3. 인가 받지 않은 교육시설을 마치 인가를 받은 것처럼 오인할 수 있는 내용
15.1.4. 근거 없이 고수익을 보장한다거나 합격, 취직을 약속하는 내용
15.1.5. 근거 없이 성적의 향상이나 학습효과의 증진을 과장하는 내용
15.1.6. 과정이수 후 취득할 수 있는 자격증의 가치를 과장하는 내용

16. 자격취득용 수업교재, 학습지 광고 심의 기준
16.1. 각종 자격취득용 수험교재나 학습지 등의 광고는 다음 각 호의 표현을 하여서는 아니 된다.
16.2. 근거 없이 자격증 소지자의 의무채용을 명시하거나, 고소득, 취업보장을 주장하는 내용
16.3. 확정되지 않은 자격시험제도를 이미 확정된 것처럼 묘사하는 내용
16.4. 누구나 쉽게 특정 시험에 합격할 수 있는 것처럼 주장하는 내용
16.5. 근거 없이 대학교수 등 관계 전문가에 의해서 집필, 추천, 보증된 것처럼 표현하거나 이를 암시하는 내용
16.6. 근거 없이 성적이나 학습효과가 높아진다는 내용
16.7. 합격률, 적중률 등을 표현하는 내용
16.8. 수석합격자 등이 여러 학습지를 동시에 구독하였을 경우에는 이들을 특정 학습지의 광고모델로 사용하여서는 아니 된다.

17. 대리점 모집 광고 심의 기준
17.1. 대리점 등의 개설에 따른 수익 주장은 객관적인 근거에 의하여야 하고 실증되지 않은 수익을 주장하는 광고를 하여서는 아니 된다.
17.1.1. 객관적인 근거 없이 고수익이 보장된다거나 성장률, 수익률 등을 과장하는 내용
17.1.2. 단기에 투자자본의 회수가 가능하다고 주장하는 내용
17.1.3. 무경험자, 초보자도 전혀 실패할 염려가 없다고 주장하는 내용

18. 영화 비디오물 광고 심의 기준
18.1. 영화, 비디오물 및 공연물에 대한 광고는 다음 각 호의 표현을 하여서는 아니 된다.
18.1.1. 잔인한 폭력, 살인, 고문 등 시청자의 정서를 해치는 표현
18.1.2. 신체부위를 지나치게 노출시키거나 음란하고 자극적인 표현
18.2. 영화, 비디오물 및 공연물에 대한 광고는 관람등급 또는 이용등급을 구체적으로 밝혀야 한다. 다만, 학습용 교재의 경우에는 그러하지 아니하다.

19. 여행 관광 광고 심의 기준
19.1. 여행, 관광 등의 광고는 여행객 또는 관광객이 오인하지 아니하도록 다음의 사항을 밝혀야 한다.
19.1.1. 규정요금 이외에 추가로 소요되는 비용의 유무와 그 내용
19.1.2. 숙박, 교통수단, 식사내용 등 제공되는 용역의 내용 및 조건
19.2. 여행 및 관광 등의 광고는 일부에만 적용되는 최저가격을 일반가격으로 오인하게 하는 표현을 하여서는 아니 된다.

20. 부동산 광고 심의 기준
20.1. 부동산 광고는 소비자를 현혹하거나 오도하는 다음의 표현을 하여서는 아니 된다.
20.1.1. 시공자, 시행자, 분양자를 명시하지 않는 내용
20.1.2. 근거 없이 투자수익을 보장하거나 투기를 조장하는 내용
20.1.3. 미래의 재산가치에 대하여 최상급 표현을 사용하거나 높은 가치를 가질 것처럼 표현하는 내용
20.1.4. 근거리, 도보통학 가능, 시내 30분거리 등 거리나 위치에 관한 불명확한 내용
20.1.5. "전세값으로 내 집 마련", "저렴한 분양가" 등 가격과 관련하여 소비자를 오인하게 하는 내용
20.1.6. "명문학군“ 등 교육환경과 관련된 근거 불확실한 내용
20.1.7. 광고주가 근거 없이 국가나 지방자치단체 등과 관계가 있는 것처럼 표현하는 내용
20.2. 토지분양에 관한 광고는 다음 각 호의 사항을 포함하여야 한다. 다만, 2분 미만의 방송광고는 예외로 한다.
20.2.1. 분양대상 토지의 소재지지목·지번 및 면적
20.2.2. 국토의 계획 및 이용에 관한 법률상 용도지역용도지구 및 용도구역
20.2.3. 토지거래허가구역 여부 등 법령상 거래규제 사항
20.2.4. 매매시 소유권 이전 형태(지분등기 또는 분할등기 여부)
20.3. 금융기관으로부터의 대출자금을 이용할 수 있다고 표현하는 경우에는 대출약정 혹은 대출알선 여부, 대출약정 금융기관명 등을 명시하여야 한다.
20.4. 수익형 부동산에 관한 광고에서 수익금액 또는 수익률을 광고하는 경우, 수익금액 또는 수익률의 산출 방법을 포함하여야 하며, 수익을 보장하는 경우에는 그 기간 및 방법을 포함하여야 한다.

21. 젖병 젖꼭지 제품 광고 심의 기준
21.1. 젖병·젖꼭지제품에 관한 광고는 그 제품의 사용으로 모유수유의 기능 및 효과를 대체할 수 있다거나, 산모의 건강 등에 도움을 줄 수 있다는 표현을 하여서는 아니 된다.

22. 구인 구직 또는 직업 안내 등 광고 심의 기준

22.1. 기부금품 모집과 관련한 광고는 건전한 기부문화를 조성할 수 있는 내용에 한하며, 모집의 주체, 모집목적, 모집기간, 모집금품의 사용처 등을 구체적으로 명시하여야 한다.
22.2. 기부금품 모집광고는 허위의 사실을 적시하여서는 아니 되며, 부정적인 방법으로 기부를 강요하여서도 아니 된다.
22.3. 기부금품 모집광고는 모집자의 인터넷 홈페이지 등 참조처를 표시할 수 있으며, 그 밖의 다른 제한 사항은 기부금품의 모집 및 사용에 관한 법률 등 관련법령을 따른다.
23. 구인 구직 또는 직업 안내 등 광고 심의 기준
23.1. 구인, 구직 또는 직업안내 광고는 인신매매나 윤락행위 등 범죄에 이용되지 아니하도록 특별히 유의하여야 하며 청소년들이 범죄조직에 소개될 우려가 있는 광고를 하여서는 아니 된다.
23.2. 미성년자들의 유흥업소 취업이나 출입을 유도하는 광고를 하여서는 아니 된다.
23.3. 직업소개소 또는 결혼상담소 광고는 인허가 사항 및 소재지 등 책임소재를 명확히 밝혀야 한다.
23.4. 구인, 구직광고는 공식적으로 인정될 수 없거나 명확한 근거가 없는 단체명을 사용하여서는 아니 된다.

24. 상조업 광고 심의 기준
24.1. 상조업에 대한 광고는 다음 각 호의 사항을 명시하여야 한다.
24.1.1. 중도해약환급금에 대한 환급기준 및 환급시기
24.1.2. 총 고객환급 의무액, 상조 관련 자산 및 이에 대한 공인회계사의 회계감사검토를 수감하였는지 여부. 이 경우 최근 사업연도의 재무 관련 자료를 기준으로 명시하여야 한다.
24.1.3. 고객불입금에 대한 관리방법(할부거래에 관한 법률 제27조에 따른 소비자피해보상보험계약 등 체결 기관 및 선수금 보전 비율)
`
