import React from 'react'
import { useFormContext } from 'react-hook-form'
import InputValidation from './inputValidation'

export const TextBoxComponent = ({ handleBlur, ...props }, forwardedRef) => {
  const methods = useFormContext()
  const inputClasses = ['flex-grow']
  if (props.className) inputClasses.push(props.className)

  const onBlur = (e) => {
    if (props.onBlur) props.onBlur(e)
    if (props.name && handleBlur && methods?.trigger) {
      handleBlur()
      methods.trigger(props.name)
    }
  }

  return <input ref={forwardedRef} {...props} onBlur={onBlur} className={inputClasses.join(' ')} />
}

export const Textbox = React.forwardRef(TextBoxComponent)

const FormTextbox = ({
  name, validators, handleBlur, ...props
}) => {
  const { register } = useFormContext()
  const { ...inputProps } = register(name, validators)
  //
  return (
    <div className="textbox flex flex-row">
      <Textbox {...props} {...inputProps} handleBlur={handleBlur} />
    </div>
  )
}

export const FormTextboxGroup = ({
  validators, validations, name, label, labelClass, groupName, onBlur, ...props
}) => {
  const { getValues } = useFormContext()
  const [validatedNames, setValidatedNames] = React.useState([])
  const labelClasses = []
  if (validators?.required) labelClasses.push('required')
  if (labelClass) labelClasses.push(labelClass)
  //
  const handleBlur = React.useCallback(() => {
    if (!validatedNames.includes(name)) {
      setValidatedNames([...validatedNames, name])
    }
    if (onBlur) onBlur()
  }, [name, validatedNames, onBlur])
  let validationsList = validations
  if (name === 'confirm_password' && getValues('confirm_password') === '') {
    validationsList = ['required']
  }
  return (
    <>
      {label && (
        <label htmlFor={name} className={labelClasses.join(' ')}>
          {label}
        </label>
      )}
      <FormTextbox
        className="larger"
        name={name}
        validators={validators}
        handleBlur={['password', 'confirm_password'].includes(name) && handleBlur}
        {...props}
      />
      <InputValidation
        groupName={groupName}
        names={[name]}
        validations={validationsList}
        validatedNames={validatedNames}
      />
    </>
  )
}

export default FormTextbox
