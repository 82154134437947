import React from 'react'
import Slider from 'react-slick'

import leftIcon from '../assets/icons/icon-outlined-directional-left-circle.svg'
import rightIcon from '../assets/icons/icon-outlined-directional-right-circle.svg'
//
const VideoCarousel2 = ({ data, isPlayOnInit = false }) => {
  const sliderRef = React.useRef(null)
  const wrapperRef = React.useRef(null)
  //
  React.useEffect(() => {
    if (data?.length > 0) {
      const vids = document.getElementsByClassName('vid-group-22')
      if (vids && vids.length > 0) {
        for (let i = 0; i < vids.length; i += 1) {
          vids[i].pause()
          vids[i].controls = false
        }
        const activeVid = document.getElementsByClassName('vid-group-22 vid-0')
        if (activeVid && activeVid.length > 0) {
          activeVid[0].controls = true
          if (isPlayOnInit) activeVid[0].play()
        }
      }
    }
  }, [isPlayOnInit, data])
  //
  React.useEffect(() => {
    if (wrapperRef.current) {
      const wrapper = wrapperRef.current
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // video.play()
          } else {
            const vids = document.getElementsByClassName('vid-group-22')
            if (vids) {
              for (let i = 0; i < vids.length; i += 1) vids[i].pause()
            }
          }
        },
        {
          root: null,
          threshold: 0.5,
        },
      )
      observer.observe(wrapper)

      return () => {
        observer.unobserve(wrapper)
      }
    }
  }, [wrapperRef])
  //
  const onChange = async (e, state) => {
    if (state) sliderRef.current.slickNext()
    else sliderRef.current.slickPrev()
  }
  //
  const sliderSetting = {
    dots: true,
    infinite: true,
    arrows: false,
    focusOnSelect: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '30%',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: '150px',
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: '30px',
        },
      },
    ],
    afterChange: () => {
      const vids = document.getElementsByClassName('vid-group-22')
      if (vids) {
        for (let i = 0; i < vids.length; i += 1) {
          vids[i].pause()
          vids[i].controls = false
        }
      }
      const activeDiv = document.getElementsByClassName('slick-active slick-center')
      const activeVid = activeDiv[0].getElementsByClassName('vid-group-22')
      if (activeVid && activeVid.length > 0) {
        activeVid[0].play()
        activeVid[0].controls = true
      }
    },
  }
  //
  return (
    <div ref={wrapperRef} className="video-carousel relative">
      <div className="video-carousel-wrapper flex flex-row justify-center mx-auto max-w-full">
        <div className="arrow-wrapper hidden lg:flex justify-center z-50">
          <button
            onClick={(e) => onChange(e, false)}
            style={{ padding: 0, paddingRight: 24, margin: 0 }}
          >
            <img src={leftIcon} alt="left" style={{ margin: 0 }} />
          </button>
        </div>
        <div className="flex-grow max-w-full lg:max-w-[calc(100vw-180px)]">
          <Slider ref={sliderRef} {...sliderSetting}>
            {data.map((item, index) => (
              <div key={item.video} className="px-2">
                <video
                  className={`vid-group-22 vid-${index}`}
                  controls
                  controlsList="nodownload"
                  onContextMenu={(e) => e.preventDefault()}
                  src={item.video}
                  onEnded={() => {
                    sliderRef.current.slickNext()
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>

        <div className="arrow-wrapper hidden lg:flex justify-center">
          <button
            onClick={(e) => onChange(e, true)}
            style={{ padding: 0, paddingLeft: 24, margin: 0 }}
          >
            <img src={rightIcon} alt="right" style={{ margin: 0 }} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default VideoCarousel2
