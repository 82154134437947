import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { getProgress } from '../../services/api'
import { hideFab } from '../../services/fab'
import { TRIAL_TOKEN } from '../../services/config'
//
const BuildTrial = () => {
  const location = useLocation()
  const history = useHistory()
  const iframeRef = React.useRef()
  hideFab()
  //
  React.useEffect(() => {
    const eventListener = (e) => {
      if (e.data?.projectId) {
        getProgress(TRIAL_TOKEN, e.data?.projectId).then((result) => {
          if (typeof result.assetStatus !== 'number') {
            return
          }
          if (result.assetStatus === 0) {
            history.goBack()
          } else {
            console.log('Build Trial push excuted')
            history.push({
              pathname: '/portal/media/result/trial',
              state: {
                assetId: e.data?.projectId,
              },
            })
          }
        })
      }
    }
    window.addEventListener('message', eventListener)
    const url = `https://skb-app.vplate.io/${location.state.assetId}?type=6438fce1c8ca7bfab2f2d058&token=${TRIAL_TOKEN.accessToken}`
    iframeRef.current.src = url
    return () => {
      window.removeEventListener('message', eventListener)
    }
  }, [location.state, history])
  //
  return (
    <iframe ref={iframeRef} title="build" className="h-full w-full flex-grow" />
  )
}

export default BuildTrial
