import React from 'react'
import moment from 'moment'
import { addDays } from 'date-fns'
import { ko } from 'date-fns/locale'
import { DateRange } from 'react-date-range'
import { useFormContext, useWatch } from 'react-hook-form'
import { Navbar, Nav } from 'react-bootstrap'

import { useWindowSize } from '../services/utils'
import RightIcon from '../assets/icons/icon-outlined-directional-right-small.svg'
import LeftIcon from '../assets/icons/icon-outlined-directional-left-small.svg'

const format = 'YYYY년 MM월 DD일'
const formatYM = 'YYYY년 M월'

const Header = ({ date, changeShownDate }) => {
  const onClick = (e, st) => {
    e.preventDefault()
    if (st) changeShownDate(moment(date).subtract(1, 'M').toDate())
    else changeShownDate(moment(date).add(1, 'M').toDate())
  }
  //
  return (
    <div className="datepicker-header">
      <button onClick={(e) => onClick(e, true)}>
        <img src={LeftIcon} alt="prev" />
      </button>
      <div className="flex flex-grow">
        <h4>{`${moment(date).format(formatYM)}`}</h4>
        <h4>{`${moment(date).add(1, 'M').format(formatYM)}`}</h4>
      </div>
      <button onClick={(e) => onClick(e, false)}>
        <img src={RightIcon} alt="next" />
      </button>
    </div>
  )
}

const DatePickerInputComponent = (props, forwardedRef) => {
  //
  const inputClasses = ['flex-grow', 'xlarger', 'w-full']
  if (props.className) inputClasses.push(props.className)
  //
  return (
    <input
      placeholder={props.placeholder}
      ref={forwardedRef}
      className={inputClasses.join(' ')}
      value={props.value}
      readOnly
    />
  )
}

const DatePickerInput = React.forwardRef(DatePickerInputComponent)

const DateRangeBox = ({
  children, setDateValue, renderNote, noDateLimit, ...props
}) => {
  const headerRef = React.useRef()
  const footerRef = React.useRef()
  const [showCalendar, setShowCalendar] = React.useState(false)
  const [text, setText] = React.useState('')
  const [width] = useWindowSize()
  const [state, setState] = React.useState(0)

  const initialDateRange = {
    startDate: addDays(new Date(), 3),
    endDate: addDays(new Date(), 33),
  }
  const [dateRange, setDateRange] = React.useState([
    { ...initialDateRange, key: 'selection', color: '#ff9000' },
  ])
  //
  React.useEffect(() => {
    if (showCalendar && width <= 790) {
      setState({
        header: headerRef.current?.clientHeight,
        footer: footerRef.current?.clientHeight,
      })
    }
  }, [width, showCalendar])
  React.useEffect(() => {
    if (props.data && props.data.length === 2 && props.data.every((item) => Boolean(item))) {
      const startDate = props.data[0] ? new Date(props.data[0]) : null
      const endDate = props.data[1] ? new Date(props.data[1]) : null
      setDateRange([
        {
          startDate,
          endDate,
          key: 'selection',
          color: '#ff9000',
        },
      ])
      setText(`${moment(startDate).format(format)} ~ ${moment(endDate).format(format)}`)
    }
  }, [props.data])

  const onChange = async ({ selection }) => {
    setDateRange([selection])
  }
  //
  const onClose = () => {
    if (props.data && props.data.length === 2) {
      const startDate = props.data[0] ? new Date(props.data[0]) : null
      const endDate = props.data[1] ? new Date(props.data[1]) : null
      setDateRange([
        {
          startDate,
          endDate,
          key: 'selection',
          color: '#ff9000',
        },
      ])
    } else {
      setDateRange([{ ...initialDateRange, key: 'selection', color: '#ff9000' }])
    }
    setShowCalendar(false)
  }
  const onClick = () => {
    setShowCalendar(!showCalendar)
  }
  const applyDateRange = React.useCallback(() => {
    if (setDateValue) setDateValue([dateRange[0].startDate, dateRange[0].endDate])
    setText(
      `${moment(dateRange[0].startDate).format(format)} ~ ${moment(dateRange[0].endDate).format(
        format,
      )}`,
    )
    setShowCalendar(false)
  }, [dateRange, setDateValue])

  const dateDiff = moment(dateRange[0].endDate).diff(moment(dateRange[0].startDate), 'day') + 1

  const navigatorRenderer = (date, changeShownDate) => (
    <Header date={date} changeShownDate={changeShownDate} />
  )
  return (
    <>
      <div className="date-range-box relative hidden lg:block">
        <div className="relative cursor-pointer">
          <DatePickerInput value={text} {...props} />
          <div className="absolute inset-0" onClick={onClick} />
        </div>
        {showCalendar && (
          <div className="fixed inset-0 flex flex-center z-[20]">
            <div className="fixed inset-0 bg-[#000] opacity-20" />
            <div className="calendar-wrapper">
              <div className="p-4">
                <DateRange
                  locale={ko}
                  monthDisplayFormat="yyyy년 MMM"
                  showDateDisplay={false}
                  showSelectionPreview={false}
                  showMonthAndYearPickers={false}
                  ranges={dateRange}
                  months={2}
                  minDate={props.minDate}
                  navigatorRenderer={navigatorRenderer}
                  direction="horizontal"
                  onChange={onChange}
                />
              </div>
              <div className="additional-views flex flex-col items-end">
                {renderNote && renderNote()}
                <div className="button-wrapper flex flex-row">
                  <button type="button" className="button secondary" onClick={onClose}>
                    닫기
                  </button>
                  <button
                    type="button"
                    className="button primary"
                    disabled={!noDateLimit && dateDiff < 30}
                    onClick={applyDateRange}
                  >
                    {`기간적용(${dateDiff})일`}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="date-range-box block lg:hidden">
        <div className="relative cursor-pointer">
          <DatePickerInput value={text} {...props} />
          <div className="absolute inset-0" onClick={onClick} />
          {showCalendar && (
            <div className="fixed lg:hidden top-0 left-0 bottom-0 right-0 bg-white z-[2000]">
              <Navbar fixed="top" ref={headerRef}>
                <div
                  className="flex flex-grow nav-layout-header"
                  style={{ paddingLeft: 25, borderBottom: 'solid 1px #eeecea' }}
                >
                  <Nav className="me-auto">
                    <Nav.Link className="go-back" onClick={onClose}>
                      광고 기간 선택
                    </Nav.Link>
                  </Nav>
                </div>
              </Navbar>
              <div
                className="h-full"
                style={{ paddingTop: state.header, paddingBottom: state.footer }}
              >
                <div className="border-b border-line h-full overflow-scroll pb-4">
                  <DateRange
                    className="w-full"
                    locale={ko}
                    monthDisplayFormat="yyyy년 MMM"
                    showDateDisplay={false}
                    showSelectionPreview={false}
                    showMonthAndYearPickers={false}
                    ranges={dateRange}
                    minDate={props.minDate}
                    maxDate={addDays(new Date(), 210)}
                    navigatorRenderer={() => {}}
                    scroll={{ enabled: true }}
                    direction="vertical"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div
                ref={footerRef}
                className="fixed bottom-0 left-0 right-0 flex flex-col items-stretch py-4 px-[16px] bg-white border-t border-line"
              >
                <button
                  type="button"
                  className="button primary"
                  disabled={!noDateLimit && dateDiff < 30}
                  onClick={applyDateRange}
                >
                  {`기간적용(${dateDiff})일`}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export const FormDateRangeBox = ({
  name, validators, noDateLimit, ...props
}) => {
  const { register, setValue, control } = useFormContext()
  const val = useWatch({ name, control })
  let validate = {}
  if (validators?.required) {
    validate = {
      ...validators.validate,
      required: (value) => value[0] !== null && value[1] !== null,
    }
  }
  const { ...hiddenInputProps } = register(name, { ...validators, validate })
  //
  const setDateValue = async (update) => {
    await setValue(name, update, { shouldValidate: true })
  }
  //
  return (
    <>
      <DateRangeBox
        setDateValue={setDateValue}
        noDateLimit={noDateLimit}
        {...props}
        name={name}
        data={val}
      />
      <input type="hidden" {...hiddenInputProps} />
    </>
  )
}

export default DateRangeBox
