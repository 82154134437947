import React from 'react'
import Draggable from 'react-draggable'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import ReactPlayer from 'react-player'
import { isFirefox } from 'react-device-detect'

import { hideFab } from '../../services/fab'
import { preparePreview, saveVoices } from '../../services/api'
import { CONFIG } from '../../services/config'

import NavigationLayout from '../../layouts/navigationLayout'
import SectionTitle from '../../components/SectionTitle'
import SelectCharacter, { defaultCharacter, characters } from './components/SelectCharacter'
import VoiceBlock from './components/VoiceBlock'
import { TwoButtonModal } from '../../components/modals'
import ModifyModal from './components/ModifyModal'
import TipModal from './components/TipModal'

import imgEmpty from '../../assets/imgs/illust-voice-block-none.png'
import imgEmpty2x from '../../assets/imgs/illust-voice-block-none@2x.png'
import imgEmpty3x from '../../assets/imgs/illust-voice-block-none@3x.png'

// const contentRegexp = /^[가-힣,.]+$/
const maxWidth = 1134
const unitLength = 19
const initialVoiceBlock = {
  length: 0,
  content: '',
}

const AddVoice = ({ asset }) => {
  hideFab()
  const [cookies] = useCookies(['jwt_token'])
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const videoUrl = asset?.originalVideoUrl || asset?.vidoeUrl

  const [character, setCharacter] = React.useState(defaultCharacter)
  const [videoTime, setVideoTime] = React.useState(0)
  const [voiceBlocks, setVoiceBlocks] = React.useState([])
  const [newVoiceBlock, setNewVoiceBlock] = React.useState(initialVoiceBlock)
  const [modal, setModal] = React.useState({
    preview: false, warning: false, modify: false, tip: false,
  })
  const [selectedBlock, setSelectedBlock] = React.useState(null)
  const [progressText, setProgressText] = React.useState(null)
  const [previewVideoUrl, setPreviewVideoUrl] = React.useState(null)
  const [position, setPosition] = React.useState({ x: 0, y: 0 })
  const videoRef = React.useRef(null)
  const timeBar = React.useRef(null)

  const timeCorrectionCheck = React.useCallback(
    (isUpdate, checkingBlock, selectedIndex) => {
      const isCorrect = voiceBlocks.every((item, i) => {
        if (isUpdate && i === selectedIndex) {
          return true
        }
        const cb = { ...item, endTime: item.startTime + item.length }
        return checkingBlock.endTime <= cb.startTime || checkingBlock.startTime >= cb.endTime
      })
      return isCorrect
    },
    [voiceBlocks],
  )

  React.useEffect(() => {
    if (!asset) {
      history.replace('/')
      return
    }
    if (isFirefox) {
      window.alert('파이어폭스는 지원하지 않습니다.\n크롬을 이용해주세요')
      history.goBack()
      return
    }
    if (asset.voiceBlocks && asset.voiceBlocks.length > 0) setVoiceBlocks(asset.voiceBlocks)
  }, [])

  const addVoice = (e) => {
    if (e) e.preventDefault()
    const newBlock = { ...newVoiceBlock }
    // if (!newBlock.content.match(contentRegexp)) {
    //   enqueueSnackbar('한글만 입력해주세요 (KBS:케이비에스, 1234:일이삼사)', { variant: 'warning' })
    //   return
    // }
    const endTime = newBlock.startTime + newBlock.length
    if (endTime > asset.duration) {
      enqueueSnackbar('기존에 추가한 음성과 시간이 겹쳤습니다.', { variant: 'warning' })
      return
    }
    const isCorrect = timeCorrectionCheck(false, { ...newBlock, endTime })
    if (isCorrect) {
      setVoiceBlocks((prev) => [...prev, newBlock])
      setNewVoiceBlock(initialVoiceBlock)
      setVideoTime(endTime)
      setPosition({ x: (endTime / asset.duration) * maxWidth, y: 0 })
      videoRef.current.seekTo(endTime, 'seconds')
    } else {
      enqueueSnackbar('기존에 추가한 음성과 시간이 겹쳤습니다.', { variant: 'warning' })
    }
  }

  const setModalVisible = (type, visible) => {
    setModal((prev) => ({
      ...prev,
      [type]: visible,
    }))
  }

  const handleModify = (newBlock) => {
    // if (!newBlock.content.match(contentRegexp)) {
    //   enqueueSnackbar('영어와 숫자는 한글로 입력해주세요. (예: KBS:케이비에스, 1234:일이삼사)', { variant: 'warning' })
    //   return
    // }
    const length = newBlock.content.length * 0.15
    const endTime = newBlock.startTime + length
    if (endTime > asset.duration) {
      enqueueSnackbar('영상 길이을 넘었습니다.', { variant: 'error' })
      return
    }
    const isCorrect = timeCorrectionCheck(true, {
      ...newBlock, endTime,
    }, selectedBlock)
    if (isCorrect) {
      const tempBlocks = [...voiceBlocks]
      tempBlocks[selectedBlock] = { ...newBlock, length }
      setVoiceBlocks(tempBlocks)
      setModalVisible('modify', false)
      setVideoTime(endTime)
      setSelectedBlock(null)
      videoRef.current.seekTo(endTime, 'seconds')
    } else {
      enqueueSnackbar('기존에 추가한 음성과 시간이 겹쳤습니다.', { variant: 'error' })
    }
  }

  const renderTimeArray = React.useCallback(() => {
    let arr
    if (asset?.duration === 5) {
      arr = ['0', '0.5', '1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5']
    } else {
      arr = Array.from(Array(16)).map(
        (_, i) => (asset?.duration === 15 ? i : i * 2),
      )
    }
    return arr.map((item) => (
      <span key={item} className="flex-1 text-[22px] text-textSecondary text-center">
        {item}
        s
      </span>
    ))
  }, [asset?.duration])

  const onChangeContent = React.useCallback(
    (e) => {
      const { value } = e.target
      const length = value.length * 0.15
      setNewVoiceBlock({
        length,
        content: value,
        startTime: videoTime,
        actor_id: character,
      })
    },
    [videoTime, character],
  )

  const onDragStart = React.useCallback(
    (i) => () => {
      setSelectedBlock(i)
    },
    [],
  )

  const onDrag = React.useCallback(
    (type) => (e, data) => {
      let time = (data.x / unitLength) * 0.5
      if (asset.duration !== 30) time *= (asset.duration / 30)
      videoRef.current.seekTo(time, 'seconds')
      if (type === 'block') {
        setPosition({ x: data.x, y: 0 })
      } else {
        setNewVoiceBlock((prev) => ({
          ...prev,
          startTime: time,
        }))
      }
    },
    [asset, videoRef],
  )

  const onStop = React.useCallback(
    (type, i) => (e, data) => {
      let time = (data.x / unitLength) * 0.5
      if (asset.duration !== 30) time *= (asset.duration / 30)
      if (type === 'block') {
        const { length } = voiceBlocks[i]
        const endTime = time + length
        if (endTime > asset.duration) {
          enqueueSnackbar('영상 길이을 넘었습니다.', { variant: 'error' })
          setSelectedBlock(null)
          return
        }

        const isCorrect = timeCorrectionCheck(true, {
          startTime: time,
          endTime,
        }, selectedBlock)
        if (!isCorrect) {
          enqueueSnackbar('기존에 추가한 음성과 시간이 겹쳤습니다.', { variant: 'error' })
          setSelectedBlock(null)
          return
        }
      }
      videoRef.current.seekTo(time, 'seconds')
      setVideoTime(time)
      setPosition({ x: data.x, y: 0 })
      if (type === 'block') {
        setVoiceBlocks((prev) => {
          const temp = [...prev]
          temp[i] = { ...temp[i], startTime: time }
          return temp
        })
        setSelectedBlock(null)
      }
    },
    [asset, videoRef, voiceBlocks, selectedBlock],
  )

  const onProgress = (data) => {
    setVideoTime(Number(data.playedSeconds.toFixed(1)))
    let x = data.playedSeconds * unitLength * 2
    if (asset.duration !== 30) x *= (30 / asset.duration)
    setPosition({ x, y: 0 })
    setNewVoiceBlock((prev) => ({
      ...prev,
      startTime: data.playedSeconds,
    }))
  }

  const getStrVideoTime = React.useCallback(() => {
    const strTime = `${videoTime.toFixed(1)}s`
    // if (videoTime < 10) strTime = `0${strTime}`
    return strTime
  }, [videoTime])

  const onClickCharacter = (id) => {
    setCharacter(id)
    setNewVoiceBlock((prev) => ({
      ...prev,
      actor_id: id,
    }))
  }

  const onClickModify = (index) => {
    setSelectedBlock(index)
    setModalVisible('modify', true)
  }

  const onClickDelete = (index) => {
    setSelectedBlock(index)
    setModalVisible('warning', true)
  }

  const onClickPreview = () => {
    if (voiceBlocks.some((block) => {
      const actor = characters.find((item) => item.id === block.actor_id)
      return !actor
    })) {
      enqueueSnackbar('기존 성우는 더 이상 이용이 불가합니다. 빨간색 테두리가 있는 성우 블록을 수정해주세요.', { variant: 'error' })
      return
    }
    const fileName = videoUrl.split('/').pop()
    const pid = `${Date.now()}_${fileName}`

    const es = new EventSource(`${CONFIG.STAGE_API_ENDPOINT}/media/typecast/progress`)
    es.addEventListener(pid, (event) => {
      const parsedData = JSON.parse(event.data)
      const { code, data } = parsedData
      switch (code) {
          case 'START':
            setProgressText('작업을 준비 중입니다.')
            break
          case 'API_CALLBACK':
            setProgressText(`오디오 생성 중입니다. (${data}/${voiceBlocks.length})`)
            break
          case 'MERGE':
            setProgressText('비디오/오디오 병합 중입니다.')
            break
          case 'SUCCESS':
            setProgressText('')
            setPreviewVideoUrl(data)
            break
          default:
            break
      }
    })
    setModalVisible('preview', true)
    const tempVoiceBlocks = voiceBlocks.map((block) => ({
      ...block,
      startTime: block.startTime * 1000,
    }))
    preparePreview(cookies.jwt_token, { progressId: pid, videoUrl, voiceBlocks: tempVoiceBlocks })
      .then((result) => {
        if (!result) {
          enqueueSnackbar('오류가 발생했습니다.', { variant: 'error' })
        }
      }).catch(() => {
        enqueueSnackbar('오류가 발생했습니다.', { variant: 'error' })
      })
  }

  const onClickClose = () => {
    setModalVisible('preview', false)
    setPreviewVideoUrl(null)
  }

  const onClickSave = React.useCallback(
    (isTemp) => {
      const data = {
        asset_id: asset._id,
        voiceBlocks,
      }
      if (!isTemp) {
        data.videoUrl = videoUrl
        data.mergedVideoUrl = previewVideoUrl
      }
      saveVoices(cookies.jwt_token, data).then((result) => {
        if (!result) {
          enqueueSnackbar('오류가 발생했습니다.', { variant: 'error' })
          return
        }
        if (!isTemp) history.push('/portal/media/create/2')
        else {
          enqueueSnackbar('임시 저장되었습니다.')
        }
      }).catch(() => {
        enqueueSnackbar('오류가 발생했습니다.', { variant: 'error' })
      })
    },
    [asset?._id, voiceBlocks, videoUrl, previewVideoUrl, cookies.jwt_token],
  )

  return (
    <NavigationLayout navigationTitle="성우 추가" backButtonVisible={false}>
      <div className="flex flex-col w-full pb-[100px] bg-white">
        <div className="h-[50vh] flex flex-col flex-center xl:hidden">
          <h3>모바일에서는 지원하지 않는 기능입니다</h3>
          <button className="btn larger primary" type="button" onClick={() => history.goBack()}>돌아가기</button>
        </div>
        <div className="hidden xl:flex flex-col self-center w-full max-w-[1140px]">
          <div className="flex flex-col  py-8">
            <form onSubmit={addVoice}>
              <div className="flex items-center gap-4">
                <div className="flex-1">
                  <SectionTitle>{asset?.assetName}</SectionTitle>
                  <ReactPlayer
                    ref={videoRef}
                    width="640px"
                    className="media"
                    url={videoUrl}
                    controlsList="nodownload"
                    controls
                    onProgress={onProgress}
                    progressInterval={50}
                  />
                </div>
                <div className="flex flex-col items-end">
                  <div className="flex gap-6">
                    <button type="button" className="btn secondary medium px-12" onClick={() => setModalVisible('tip', true)}>
                      이용팁 &amp; 주의사항
                    </button>
                    <button type="button" className="btn dark medium" onClick={() => onClickSave(true)}>
                      임시 저장하기
                    </button>
                  </div>
                  <div className="flex flex-col gap-3 my-4">
                    <div>
                      <SectionTitle>캐릭터</SectionTitle>
                      <SelectCharacter
                        character={character}
                        onClickCharacter={onClickCharacter}
                      />
                    </div>
                    {/* <div className="flex gap-4">
                      <div className="flex-1">
                        <SectionTitle>음색</SectionTitle>
                        <div className="select">
                          <select className="w-full">
                            <option>행복한</option>
                            <option>슬픈</option>
                          </select>
                        </div>
                      </div>
                      <div className="flex-1">
                        <SectionTitle>속도</SectionTitle>
                        <div className="select">
                          <select className="w-full">
                            <option>아주 빠른</option>
                          </select>
                        </div>
                      </div>
                    </div> */}
                    <div>
                      <SectionTitle>내용</SectionTitle>
                      <div className="textbox-wrapper">
                        <input className="w-full" type="text" value={newVoiceBlock.content} onChange={onChangeContent} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-4">
                <button
                  type="button"
                  className="btn primary medium"
                  disabled={voiceBlocks.length === 0}
                  onClick={onClickPreview}
                >
                  미리보기
                </button>
                <button
                  className="btn primary medium"
                  disabled={!newVoiceBlock.content}
                >
                  음성 추가하기
                </button>
              </div>
            </form>
          </div>
          <div className="flex flex-col border-b-[2px] border-line">
            <div className="border-b-[2px] border-line pb-1">
              <div className="flex" style={{ marginLeft: asset?.duration === 5 ? -50 : -32, marginRight: asset?.duration === 5 ? -50 : -32 }}>
                {renderTimeArray()}
              </div>
            </div>
            <div className="relative min-h-[280px]">
              {voiceBlocks.length === 0 && newVoiceBlock.content.length === 0 ? (
                <div className="flex flex-col flex-center h-[280px]">
                  <img
                    src={imgEmpty}
                    srcSet={`${imgEmpty2x} 2x, ${imgEmpty3x} 3x`}
                    alt="empty"
                  />
                  <p className="mt-4 mb-0 text-[20px] font-medium text-textTertiary text-center leading-7">
                    성우 음성을 추가하고
                    <br />
                    광고의 설득력을 높여보세요
                  </p>
                </div>
              ) : (
                <div className="flex flex-col items-start flex-1">
                  {voiceBlocks.map((voiceBlock, i) => (
                    <VoiceBlock
                      key={i}
                      voiceBlock={voiceBlock}
                      isSelected={selectedBlock === i}
                      totalLength={asset?.duration}
                      unitLength={unitLength}
                      maxWidth={maxWidth}
                      onStart={onDragStart(i)}
                      onDrag={onDrag('block')}
                      onBlockStop={onStop('block', i)}
                      onClickDelete={() => onClickDelete(i)}
                      onClickModify={() => onClickModify(i)}
                    />
                  ))}
                  {newVoiceBlock.content && (
                    <VoiceBlock
                      voiceBlock={newVoiceBlock}
                      totalLength={asset?.duration}
                      maxWidth={maxWidth}
                    />
                  )}
                </div>
              )}
              <Draggable
                axis="x"
                nodeRef={timeBar}
                defaultPosition={{ x: 0, y: 0 }}
                position={position}
                grid={[unitLength, 0]}
                scale={1}
                bounds={{ left: 0, right: maxWidth }}
                onDrag={onDrag('bar')}
                onStop={onStop('bar')}
              >
                <div ref={timeBar} className="absolute top-0 w-[2px] h-full bg-main cursor-grab active:cursor-grabbing">
                  <div className="absolute top-[40px] h-[26px] pl-2 pr-3 pt-[1px] font-bold text-[14px] text-white bg-main rounded-r-full">
                    {getStrVideoTime()}
                  </div>
                </div>
              </Draggable>
            </div>
          </div>
          <button
            className="h-btn large primary self-center w-[225px] mt-12"
            disabled={voiceBlocks.length === 0}
            onClick={onClickPreview}
          >
            미리보기
          </button>
        </div>
      </div>
      {modal.preview && (
        <TwoButtonModal
          title="영상 미리보기"
          primaryText="확정하기"
          secondaryText="수정하기"
          onClickPrimary={() => onClickSave(false)}
          onClickSecondary={onClickClose}
          onClose={onClickClose}
        >
          <div className="flex flex-center w-[480px] aspect-video">
            {progressText && (<span className="text-xl font-medium">{progressText}</span>)}
            {previewVideoUrl && (
              <video
                className="w-full h-full"
                controls
                controlsList="nodownload"
                onContextMenu={(e) => e.preventDefault()}
              >
                <source src={previewVideoUrl} type="video/mp4" />
              </video>
            )}
          </div>
        </TwoButtonModal>
      )}
      {modal.warning && (
        <TwoButtonModal
          title="선택하신 음성을 삭제하시겠습니까?"
          primaryText="삭제하기"
          secondaryText="취소하기"
          onClickPrimary={() => {
            setSelectedBlock(null)
            setVoiceBlocks((prev) => prev.filter((_, i) => i !== selectedBlock))
            setModalVisible('warning', false)
          }}
          onClickSecondary={() => {
            setSelectedBlock(null)
            setModalVisible('warning', false)
          }}
          onClose={() => {
            setSelectedBlock(null)
            setModalVisible('warning', false)
          }}
        />
      )}
      {modal.modify && (
        <ModifyModal
          voiceBlock={voiceBlocks[selectedBlock]}
          onClose={() => setModalVisible('modify', false)}
          handleModify={handleModify}
        />
      )}
      <TipModal
        isModalShown={modal.tip}
        onClickClose={() => setModalVisible('tip', false)}
      />
    </NavigationLayout>
  )
}

export default AddVoice
