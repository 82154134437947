import React from 'react'
// import dotsIcon from "../assets/icons/icon-outlined-application-more.svg"
import { Link } from 'react-router-dom'
import playIcon from '../assets/icons/icon-filled-directional-play-circle.svg'
import pauseIcon from '../assets/icons/icon-filled-suggested-pause-circle.svg'
import pendingIcon from '../assets/icons/icon-filled-suggested-clock-circle.svg'
import successIcon from '../assets/icons/icon-filled-suggested-check-circle.svg'
import warningIcon from '../assets/icons/icon-filled-suggested-warning-circle.svg'
import infoIcon from '../assets/icons/icon-filled-suggested-exclamation-circle-26-px.svg'
import createIcon from '../assets/icons/icon-outlined-editor-form.svg'
// import emptyMedia from "../assets/icons/empty-media.svg"
import targetRight from '../assets/icons/icon-outlined-directional-right-small.svg'
// import ToggleMenu from "./toggleMenu"
//
const statusIcons = {
  play: playIcon,
  pause: pauseIcon,
  pending: pendingIcon,
  done: successIcon,
  warning: warningIcon,
  info: infoIcon,
  create: createIcon,
}

export const PlainAdCard = ({
  className, statusTitle, status, children,
}) => {
  const statusIcon = statusIcons[status] || infoIcon
  //
  return (
    <div
      className={`ad-campaign-card relative flex flex-col flex-grow h-full ${className}`}
    >
      <div className="flex flex-none flex-row status">
        <img src={statusIcon} alt="status" />
        <span className="flex-grow-1 status-title">{statusTitle}</span>
      </div>
      {children}
    </div>
  )
}

export const CreateAdCard = ({
  className,
  to,
  href,
  onClick,
  title,
  buttonText,
  children,
  toText,
}) => {
  const onButtonClick = (e) => onClick && onClick(e)
  //
  return (
    <PlainAdCard className={className} status="create">
      <div className="info flex flex-col flex-grow">
        <h3>{title}</h3>
        {children}
        {to && (
          <Link
            style={{ color: 'var(--text-secondary)' }}
            className="right-icon"
            to={to}
          >
            {toText}
            <img src={targetRight} alt="target-right" />
          </Link>
        )}
        {href && (
          <a
            href={href}
            target="_blank"
            rel="noreferrer"
            style={{ color: 'var(--text-secondary)', display: 'table-cell' }}
            className="right-icon"
            to={to}
          >
            {toText}
            <img src={targetRight} alt="target-right" />
          </a>
        )}
      </div>
      <div className="flex flex-row actions flex-end">
        <button className=" w-full primary large" onClick={onButtonClick}>
          {buttonText}
        </button>
      </div>
    </PlainAdCard>
  )
}
