import React from 'react'
import { Container } from 'react-bootstrap'
import { Tab, Tabs, TabList } from 'react-tabs'
import { Link } from 'react-router-dom'

import Header from '../components/header'
import Footer from '../components/footer'
import { useWindowSize } from '../services/utils'

const MediaLayout = ({
  children,
  order,
  onSort1Click,
  onSort2Click,
  onChangeSelect,
  selectList,
  selectValue,
  selectedTab,
  onClickTab,
}) => {
  //
  const [width] = useWindowSize()
  const ref = React.useRef()
  const [marginTop, setMarginTop] = React.useState(0)

  React.useEffect(() => {
    setMarginTop(ref.current.clientHeight)
  }, [width])
  //
  const onChangeOrder = (e) => {
    if (e.target.value === '0') {
      onSort2Click()
    } else {
      onSort1Click()
    }
  }
  //
  return (
    <>
      <Header sticky ref={ref} />
      <main
        style={{
          marginTop,
        }}
      >
        <div className="page-headline">
          <Container>
            <h1>나의광고</h1>
          </Container>
        </div>
        {/**/}
        <div className="media page-content">
          <Container>
            <div>
              <Tabs
                className="media-tabs tabs-secondary overflow-visible"
                selectedTabClassName="active"
                selectedIndex={selectedTab}
                onSelect={(index) => onClickTab(index)}
              >
                <TabList className="flex tab-list-secondary">
                  <Tab className="tab-secondary px-8">모든 광고</Tab>
                  <Tab className="tab-secondary px-8">광고 제작</Tab>
                  <Tab className="tab-secondary px-8">광고 심사</Tab>
                  <Tab className="tab-secondary px-8">광고 집행</Tab>
                  <div className="flex-grow" />
                  <div className="hidden xl:block">
                    <Link to="/tutorial/ad">
                      <button className="tutorial">
                        광고 만드는 방법 보기
                      </button>
                    </Link>
                  </div>
                </TabList>
                <div className="flex flex-row sort-commands justify-between items-center">
                  <select
                    className="select-option"
                    value={selectValue?.value}
                    onChange={onChangeSelect}
                  >
                    {selectList.map((item, i) => (
                      <option key={i} value={item?.value}>
                        {item?.title}
                      </option>
                    ))}
                  </select>
                  <div className="hidden lg:flex flex-row">
                    <button
                      className={`light ${order === 'createdAt' ? 'font-bold' : ''}`}
                      onClick={onSort2Click}
                    >
                      최초 생성일 순
                    </button>
                    <div className="divider-vertical" />
                    <button
                      className={`light ${order === 'updatedAt' ? 'font-bold' : ''}`}
                      onClick={onSort1Click}
                    >
                      최근 수정일 순
                    </button>
                  </div>
                  <div className="lg:hidden">
                    <select onChange={onChangeOrder}>
                      <option value="0">최초 생성일 순</option>
                      <option value="1">최근 수정일 순</option>
                    </select>
                  </div>
                </div>
                {children}
              </Tabs>
            </div>
          </Container>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default MediaLayout
