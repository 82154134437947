export const ASSET_UPLOAD_MESAGES = ['코덱', '해상도', '프레임', '길이', '오디오', '완료']

export const PRODUCT_TYPE = {
  NORMAL: 'NORMAL',
  CHANNEL: 'CHANNEL',
  HOUR: 'HOUR',
}

export const PRODUCT_NAME = {
  NORMAL: '상품 선택 안함',
  CHANNEL: '채널팩',
  HOUR: '광고 시간대 선택',
}

export const RECOMMEND_FORM = {
  objective: [
    {
      label: '가게알리기',
      value: 'marketing',
    },
    {
      label: '상품소개하기',
      value: 'product',
    },
    {
      label: '할인이벤트',
      value: 'sales',
    },
    {
      label: '정보전달',
      value: 'info',
    },
  ],
  category: [
    { value: '1', label: '전통시장' },
    { value: '2', label: '외식업' },
    { value: '3', label: '카페/디저트' },
    { value: '4', label: '패스트푸드' },
    { value: '5', label: '마트/신선식품' },
    { value: '6', label: 'PC/통신기기/가전' },
    { value: '7', label: '기타 도/소매업' },
    { value: '8', label: '패션/잡화' },
    { value: '9', label: '미용/뷰티' },
    { value: '10', label: '학원/교육' },
    { value: '11', label: '스포츠/피트니스' },
    { value: '12', label: '취미/레저' },
    { value: '13', label: '자동차관리' },
    { value: '14', label: '하우징/인테리어' },
    { value: '15', label: '기타서비스' },
    { value: '16', label: '공공서비스' },
    { value: '17', label: '브랜드' },
    { value: '18', label: '분양광고' },
  ],
  age: [
    { value: '1', label: '10대' },
    { value: '2', label: '20대' },
    { value: '3', label: '30대' },
    { value: '4', label: '40대' },
    { value: '5', label: '50대' },
    { value: '6', label: '60대 이상' },
  ],
  gender: [
    { value: 'mf', label: '전체' },
    { value: 'm', label: '남성' },
    { value: 'f', label: '여성' },
  ],
  household: [
    { value: '1', label: '1인가구' },
    { value: '2', label: '2인가구' },
    { value: '3', label: '3인가구' },
    { value: '4', label: '4인가구 이상' },
  ],
  distance: [
    // { value: 'depth3', label: '읍/면/동 단위' },
    // { value: 'depth2', label: '시/군/구 단위' },
    // { value: 'depth1', label: '광역시/도 단위' },
    { value: 'depth3', label: '0~3km' },
    { value: 'depth2', label: '3km~10km' },
    { value: 'depth1', label: '10km~30km' },
  ],
  salary: [
    { value: 'N', label: '선택안함' },
    { value: 'Y', label: '소득상위지역 우선추천' },
  ],
  channel: [
    { value: 'visit', label: '방문' },
    { value: 'delivery', label: '배달' },
    { value: 'online', label: '온라인' },
  ],
  prevMarketing: [
    { value: 'none', label: '없음' },
    { value: 'baemin', label: '배달의 민족' },
    { value: 'coupang', label: '쿠팡잇츠' },
    { value: '기타', label: '기타' },
  ],
  priority: [
    {
      value: null,
      label: '선택하세요',
    },
    {
      value: 'age',
      label: '광고대상 연령',
    },
    {
      value: 'gender',
      label: '광고대상 성별',
    },
    {
      value: 'household',
      label: '광고대상 가구',
    },
    {
      value: 'salary',
      label: '소득수준',
    },
  ],
}

export const SUBCATEGORY = {
  2: [
    // 외식업
    { value: '매장영업', label: '매장영업' },
    { value: '배달/포장 전문', label: '배달/포장 전문' },
  ],
  3: [
    // 카페/디저트
    { value: '카페/베이커리/떡', label: '카페/베이커리/떡' },
    { value: '키즈카페', label: '키즈카페' },
    { value: '동물카페', label: '동물카페' },
    { value: '스터디카페', label: '스터디카페' },
    { value: '기타', label: '기타' },
  ],
  4: [
    // 패스트푸드
    { value: '피자', label: '피자' },
    { value: '치킨', label: '치킨' },
    { value: '버거', label: '버거' },
    { value: '기타', label: '기타' },
  ],
  5: [
    // 마트/신선식품
    { value: '마트(종합소매)', label: '마트(종합소매' },
    { value: '편의점', label: '편의점' },
    { value: '식재료(정육/과일/야채 등)', label: '식재료(정육/과일/야채 등' },
    { value: '기타', label: '기타' },
  ],
  6: [
    // PC/통신기기/가전
    { value: '휴대폰', label: '휴대폰' },
    { value: '가전제품', label: '가전제품' },
    { value: 'PC', label: 'PC' },
    { value: '기타', label: '기타' },
  ],
  7: [
    // 기타 도/소매업
    { value: '가정용품', label: '가정용품' },
    { value: '학생용품', label: '학생용품' },
    { value: '의료보조(보청기 등)', label: '의료보조(보청기 등' },
    { value: '기타', label: '기타' },
  ],
  8: [
    // 패션/잡화
    { value: '의류', label: '의류' },
    { value: '가방/액세서리', label: '가방/액세서리' },
    { value: '신발', label: '신발' },
    { value: '안경', label: '안경' },
    { value: '기타', label: '기타' },
  ],
  9: [
    // 미용/뷰티
    { value: '헤어', label: '헤어' },
    { value: '바디케어', label: '바디케어' },
    { value: '네일', label: '네일' },
    { value: '기타', label: '기타' },
  ],
  10: [
    // 학원/교육
    { value: '취학전', label: '취학전' },
    { value: '초등', label: '초등' },
    { value: '중등', label: '중등' },
    { value: '고등', label: '고등' },
    { value: '성인', label: '성인' },
    { value: '기타', label: '기타' },
  ],
  11: [
    // 스포츠/피트니스
    { value: '헬스/피트니스/요가', label: '헬스/피트니스/요가' },
    { value: '자전거', label: '자전거' },
    { value: '기타', label: '기타' },
  ],
  12: [
    // 취미/레저
    { value: '골프(용품/의류/CC/아카데미)', label: '골프(용품/의류/CC/아카데미' },
    { value: '게임', label: '게임' },
    { value: '여행', label: '여행' },
    { value: '체험', label: '체험' },
    { value: '공연/축제', label: '공연/축제' },
    { value: '기타', label: '기타' },
  ],
  13: [
    // 자동차관리
    { value: '자동차 관리', label: '자동차 관리' },
    { value: '판매', label: '판매' },
    { value: '정비', label: '정비' },
    { value: '공유/렌트', label: '공유/렌트' },
    { value: '네비/용품', label: '네비/용품' },
    { value: '기타', label: '기타' },
  ],
  14: [
    // 하우징/인테리어
    '침구/가구',
    '조명/인테리어',
    '분양/청약',
    '부동산/중개',
    '이사',
    '철물/설비/누수',
    '기타',
  ],
  15: [
    // 기타서비스
    '세무/법무',
    '디자인/제작/개발',
    '애견',
    '플랫폼업',
    '예식',
    '기타',
  ],
  16: [
    // 공공서비스
    '지자체',
    '공공기관',
    '공익',
    '선거',
    '기타',
  ],
  17: [
    // 브랜드
    '브랜드(삼성, 애플 등)',
    '기타',
  ],
}

export const FRANCHISE_REGIONS = {
  capital: '수도권',
  western: '서부',
  midland: '중부',
  daegu: '대구',
  busan: '부산',
  jeju: '제주',
}

export const FRANCHISE_REGION_OPTIONS = [
  { value: 'capital', label: '수도권' },
  { value: 'western', label: '서부' },
  { value: 'midland', label: '중부' },
  { value: 'daegu', label: '대구' },
  { value: 'busan', label: '부산' },
  { value: 'jeju', label: '제주' },
]

export const FRANCHISE_NOTICE_TYPE = {
  NORMAL: '일반',
  CAMPAIGN: '청약',
}

export const FRANCHISE_NOTICE_TYPE_OPTIONS = [
  { value: 'NORMAL', label: '일반' },
  { value: 'CAMPAIGN', label: '청약' },
]

export const chartColors = ['hsl(250, 79%, 45%)', '#F6D858', '#04E762', '#DC0073', '#008BF8', '#D76A03']
