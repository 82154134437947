import React from 'react'
import { useHistory } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'

import Header from '../components/header'
import Footer from '../components/footer'
import { useWindowSize } from '../services/utils'

const NavigationLayout = ({
  children,
  navigationTitle,
  onPressBackButton,
  backButtonVisible = true,
  footerVisible = true,
}) => {
  const ref = React.useRef()
  const mobileHeaderRef = React.useRef()
  const history = useHistory()
  const [width] = useWindowSize()
  const [state, setState] = React.useState(0)
  //
  React.useEffect(() => {
    setState(
      width >= 1193
        ? ref.current?.clientHeight
        : mobileHeaderRef.current?.clientHeight,
    )
  }, [width])
  //
  const onPressBack = () => {
    if (onPressBackButton) {
      onPressBackButton()
    } else {
      history.goBack()
    }
  }
  return (
    <>
      <Header className="hidden xl:flex" sticky ref={ref} />
      <Navbar fixed="top" ref={mobileHeaderRef}>
        <div
          className="flex flex-grow xl:hidden nav-layout-header"
          style={{ paddingLeft: 25, borderBottom: 'solid 1px #eeecea' }}
        >
          {backButtonVisible ? (
            <Nav className="me-auto">
              <Nav.Link className="go-back" onClick={onPressBack}>
                {navigationTitle}
              </Nav.Link>
            </Nav>
          ) : (
            <div className="flex justify-center w-full">{navigationTitle}</div>
          )}
        </div>
      </Navbar>
      <main
        className="flex flex-grow flex-col"
        style={{
          marginTop: state,
        }}
      >
        {children}
      </main>
      {footerVisible && <Footer />}
    </>
  )
}

export default NavigationLayout
