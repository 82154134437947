import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import icArrowLeft from '../assets/icons/icon-outlined-directional-left-small.svg'
import icFilmEditing from '../assets/icons/ic-film-editing@2x.png'
import icDollar from '../assets/icons/ic-dollar@2x.png'

const SideBarMenu = () => {
  const [isOpen, setIsOpen] = React.useState(true)

  return (
    <div className={cx(
      'fixed right-0 top-[calc(50%+50px)] w-[71px] bg-white shadow-lg rounded-l-xl',
      'transform -translate-y-1/2 transition-transform duration-200 ease-in-out z-10',
      'lg:w-[121px] lg:top-1/2',
      isOpen ? 'translate-x-0' : 'translate-x-full',
    )}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={cx(
          'absolute left-0 top-1/2 flex items-center justify-center w-[10px] h-[46px]',
          '!p-0 bg-white rounded-l-[5px] rounded-r-none',
          'transform -translate-y-1/2 -translate-x-[10px]',
          'lg:w-[16px] lg:-translate-x-[16px]',
        )}
      >
        <img
          className={cx(
            'w-2 !m-0 transform transition-transform duration-200 ease-in-out',
            'lg:w-3',
            isOpen ? 'rotate-180' : 'rotate-0',
          )}
          src={icArrowLeft}
          alt="왼쪽화살표"
        />
      </button>
      <div className="rounded-l-xl overflow-hidden">
        <div className="p-2 bg-brand text-[8px] text-white text-center font-bold lg:text-[12px]">QUICK MENU</div>
        <div className="flex flex-col px-[4px] divide-y divide-bgColor2 lg:px-[12px]">
          <Link to="/trial">
            <div className="flex flex-col items-center py-[18px] gap-2.5">
              <img className="w-[30px] h-[30px] lg:w-[45px] lg:h-[45px]" src={icFilmEditing} alt="영상조작아이콘" />
              <p className="text-[8px] font-bold !mb-0 whitespace-pre-wrap text-center lg:text-[12px]">{'영상제작 체험관\n바로가기'}</p>
            </div>
          </Link>
          <Link to="/estimate">
            <div className="flex flex-col items-center py-[18px] gap-2.5">
              <img className="w-[30px] h-[21px] lg:w-[55px] lg:h-[39px]" src={icDollar} alt="돈모양아이콘" />
              <p className="text-[8px] font-bold !mb-0 whitespace-pre-wrap text-center lg:text-[12px]">광고비 알아보기</p>
            </div>
          </Link>
          <div className="flex flex-col items-center py-[18px]">
            <span className="text-[8px] font-bold lg:text-[13px]">상담 문의</span>
            <span className="text-[8px] font-bold lg:text-[18px]">09시 ~ 18시</span>
            <Link to="/consult">
              <div className="mt-1 px-[4px] py-1 text-[8px] text-white font-bold bg-brand rounded lg:text-[14px] lg:px-2">
                상담 예약하기
              </div>
            </Link>
          </div>
          {/* <Link to='/estimate'>
            <div className="flex flex-col items-center py-4 gap-2.5">
              <img className="w-[53px] h-[53px]" src={icFilmEditing} alt="영상조작아이콘" />
              <p className="text-[14px] font-bold whitespace-pre-wrap text-center">{'영상제작 체험관\n바로가기'}</p>
              <a href="#3" className="block text-lg">
                상담 문의
                <br />
                11시 ~ 14시
              </a>
            </div>
          </Link> */}
        </div>
      </div>
    </div>
  )
}

export default SideBarMenu
