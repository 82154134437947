import { useQuery } from 'react-query'
import { CONFIG } from '../config'
import { getFranchiseAdsRow } from '../utils'

export const getFranchiseNotices = async (token, regions) => {
  const REQUEST_URL = new URL('/franchise/notice', CONFIG.API_ENDPOINT)
  REQUEST_URL.searchParams.set('regions', regions)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getFranchiseNotice = async (token, id) => {
  const REQUEST_URL = new URL(`/franchise/notice/${id}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const writeFranchiseNotice = async (token, data) => {
  const REQUEST_URL = new URL('/franchise/notice', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const uploadFranchiseNoticeFile = async (token, file) => {
  const REQUEST_URL = new URL('/franchise/notice/file', CONFIG.API_ENDPOINT)
  //
  const formData = new FormData()
  formData.append('file', file)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
    },
    body: formData,
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const editFranchiseNotice = async (token, data, id) => {
  const REQUEST_URL = new URL(`/franchise/notice/${id}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const deleteFranchiseNotice = async (token, id) => {
  const REQUEST_URL = new URL(`/franchise/notice/${id}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getCampaignsByFranchiseNotice = async (token, noticeId) => {
  const REQUEST_URL = new URL(`/franchise/notice/${noticeId}/campaign`, CONFIG.API_ENDPOINT)
  REQUEST_URL.searchParams.set('limit', 10)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const useFranchiseNoticeCampaigns = (token, noticeId) => useQuery({
  queryKey: ['franchiseAdsList', noticeId],
  queryFn: async () => {
    const result = await getCampaignsByFranchiseNotice(token, noticeId)
    return getFranchiseAdsRow(result)
  },
  enabled: !!noticeId,
  refetchOnWindowFocus: false,
  // staleTime: 1000 * 60 * 5,
})
