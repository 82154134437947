import React from 'react'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useParams } from 'react-router-dom'

import Comment from './comment'
import cmntIcon from '../../../assets/icons/icon-filled-application-comment-26-px-text-1.svg'
import AppContext from '../../../services/context'
import {
  postCommunitySubComment,
  deleteCommunityComment,
} from '../../../services/api'
import useCommentWritter from '../hooks/useCommentWritter'
//
const CommentWrapper = ({ commentData, postId, deleteCallback }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { appState } = React.useContext(AppContext)
  const [cookies] = useCookies(['jwt_token'])
  const { enqueueSnackbar } = useSnackbar()
  const [writter, renderWritterInput, initWritterInput] = useCommentWritter()
  const params = useParams()
  //
  const [, setIsEditing] = React.useState(false)
  const [addingSubComment, setAddingSubComment] = React.useState('')
  const [addedSubComments, setAddedSubComments] = React.useState([])
  const addCommentRef = React.useRef(null)
  const inputRef = React.useRef(null)
  //
  const onClickEdit = () => {
    setIsEditing((prev) => {
      if (!prev && inputRef.current) {
        if (inputRef.current && addCommentRef.current) {
          addCommentRef.current.className = 'block'
          inputRef.current.focus()
        }
      } else if (inputRef.current && addCommentRef.current) {
        addCommentRef.current.className = 'hidden'
      }
      return !prev
    })
  }
  const onChangeAddingSubComment = async (e) => {
    await setAddingSubComment(e.target.value)
  }
  const onClickRegSubComment = async () => {
    if (!addingSubComment) return
    let result = null
    try {
      if (appState.userInfo === null) {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available')
          return
        }
        const token = await executeRecaptcha('comment')
        result = await postCommunitySubComment(
          null,
          params.boardId,
          postId,
          addingSubComment,
          commentData._id,
          token,
          writter,
        )
        initWritterInput()
      } else {
        result = await postCommunitySubComment(
          cookies.jwt_token,
          params.boardId,
          postId,
          addingSubComment,
          commentData._id,
        )
      }
      if (!result) return
      result.user = {
        _id: result.user,
        userId: appState.userInfo?.userId,
      }
      setAddedSubComments((prev) => [...prev, result])
      await setIsEditing(false)
      if (inputRef.current && addCommentRef.current) {
        addCommentRef.current.className = 'hidden'
      }
      await setAddingSubComment('')
    } catch (e) {
      enqueueSnackbar('서버 오류가 발생했습니다', { variant: 'error' })
    }
  }
  const onClickDelete = async () => {
    const resultMsg = await deleteCommunityComment(
      cookies.jwt_token,
      params.boardId,
      postId,
      commentData._id,
    )
    if (resultMsg) {
      enqueueSnackbar(resultMsg.title, { variant: 'success' })
    }
    deleteCallback(commentData._id)
  }
  //
  const tempArr = commentData.comments || []
  const subComments = [...tempArr, ...addedSubComments]

  return (
    <div className="comment-wrapper flex flex-col">
      <div className="comment-item">
        <Comment
          postId={postId}
          commentData={commentData}
          onClickDelete={onClickDelete}
        />
        <div className="subcmt-wrapper">
          <button
            className="add-comment left-icon p-0 mb-2"
            onClick={onClickEdit}
          >
            <img src={cmntIcon} alt="cmnt" />
            답글 달기
          </button>
          <div ref={addCommentRef} className="hidden">
            {!appState.userInfo?.userId ? (
              renderWritterInput()
            ) : (
              <div className="commenter-id">{appState.userInfo?.userId}</div>
            )}
            <div
              className="add-comment-section textbox-action flex flex-row"
              style={{ marginBottom: 16 }}
            >
              <textarea
                ref={inputRef}
                onChange={onChangeAddingSubComment}
                className="flex-grow large border border-line"
                placeholder="댓글을 남겨주세요."
                value={addingSubComment}
                rows={1}
              />
              <button
                className="large dark"
                onClick={async (e) => onClickRegSubComment(e)}
                disabled={addingSubComment === ''}
              >
                등록
              </button>
            </div>
          </div>
        </div>
      </div>
      {subComments.length > 0 && (
        <div className="sub-comments-section">
          {subComments.map((item, index) => (
            <Comment
              key={index}
              postId={postId}
              commentData={item}
              onClickDelete={onClickDelete}
              isSubCmnt
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default CommentWrapper
