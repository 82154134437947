import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { FormTextboxGroup } from '../../../components/formTextbox'
import InputValidation from '../../../components/inputValidation'
import DaumPost from './daumPost'
import iconClose from '../../../assets/icons/icon-filled-suggested-close-circle.svg'
import {
  getAdminDong, getRegionByFullAddr, getRegionData, searchAddresses,
} from '../../../services/api'

const InputAddress = ({ mode = 'full' }) => {
  const [modalVisible, setModalVisible] = React.useState(false)
  const [daumModalVisible, setDaumModalVisible] = React.useState(false)
  const [regions, setRegions] = React.useState([])
  const [companyName, setCompanyName] = React.useState('')

  const { register, getValues, setValue } = useFormContext()

  useEffect(() => {
    const _companyName = getValues('companyName')
    if (_companyName) {
      setCompanyName(_companyName)
    }
  }, [])

  const onClickGetRegionByName = async () => {
    const regionData = await getRegionData(companyName)
    if (regionData && regionData.length) {
      setRegions(regionData)
    } else {
      setRegions([])
    }
  }
  const onClickSearch = () => {
    setModalVisible(true)
    if (companyName) {
      onClickGetRegionByName()
    }
  }
  const onClickRegion = async (region) => {
    const {
      fullAddressRoad, zipCode, telNo, frontLat, frontLon, middleAddrName,
    } = region
    setValue('storePostalCode', zipCode, { shouldValidate: true, shouldDirty: true })
    setValue('storeAddress', fullAddressRoad)
    setValue('storeLat', frontLat)
    setValue('storeLon', frontLon)
    if (telNo) {
      const strTelNo = telNo.split('-').join('')
      if (/^\d{2,4}\d{2,4}\d{4}$/.test(strTelNo)) {
        setValue('storeTel', telNo.split('-').join(''))
      }
    }
    setModalVisible(false)

    // 동 단위 주소 체계 저장을 위한 코드
    let adminDong = await getAdminDong(fullAddressRoad)
    let result = await searchAddresses(adminDong)
    if (result.length === 0 && /[0-9]/.test(adminDong)) {
      const tempArr = adminDong.split('')
      let index
      tempArr.some((item, i) => {
        if (!Number.isNaN(Number(item))) {
          index = i
          return true
        }
        return false
      })
      tempArr.splice(index, 0, '제')
      adminDong = tempArr.join('')
      result = await searchAddresses(adminDong)
    }
    if (result === false) return
    const addressData = result.find((item) => item.name === adminDong && item.full.includes(middleAddrName))
    if (addressData) {
      setValue('storeAddressKey', addressData.id)
    }
  }

  const onClickSearchByPostalCode = () => {
    // setModalVisible(false)
    setDaumModalVisible((prev) => !prev)
  }

  const handleComplete = async (data) => {
    let fullAddress = data.address
    let extraAddress = ''

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }

    setValue('storePostalCode', data.zonecode, { shouldValidate: true, shouldDirty: true })
    setValue('storeAddress', fullAddress)
    setModalVisible(false)
    setDaumModalVisible(false)
    setCompanyName('')
    setRegions([])

    const regionData = await getRegionByFullAddr(fullAddress)
    if (regionData) {
      setValue('storeLat', regionData.lat)
      setValue('storeLon', regionData.lon)
    }

    // 동 단위 주소 체계 저장을 위한 코드
    let searchName = data.bname
    let result = await searchAddresses(searchName)
    if (result.length === 0) {
      searchName = data.bname1
      result = await searchAddresses(searchName)
    }
    if (result === false) return
    const addressData = result.find((item) => item.name === searchName && item.full.includes(data.sigungu))
    if (addressData) {
      setValue('storeAddressKey', addressData.id)
    }
  }

  return (
    <div className="flex flex-col gap-[16px]">
      {mode === 'light' ? (
        <div className="flex items-start textbox">
          <input
            className="w-full p-2 larger !bg-white"
            type="text"
            placeholder="주소"
            disabled
            {...register('storeAddress', { required: true })}
          />
          <button
            type="button"
            className="btn primary min-w-[120px] h-[42px] ml-4 hover:text-white"
            onClick={onClickSearch}
          >
            위치 검색
          </button>
        </div>
      ) : (
        <>
          <div className="flex items-start textbox">
            <div>
              <input
                className="w-[150px] larger !bg-white"
                type="text"
                placeholder="우편번호"
                disabled
                {...register('storePostalCode', { required: true })}
              />
              <InputValidation names={['storePostalCode']} validations={['required']} validatedNames={['required']} />
            </div>
            <button
              type="button"
              className="btn primary h-[58px] ml-4 hover:text-white"
              onClick={() => {
                onClickSearch()
                onClickGetRegionByName()
              }}
            >
              상호명 검색
            </button>
          </div>
          <div className="w-full">
            <input
              className="w-full larger !bg-white"
              type="text"
              placeholder="주소"
              disabled
              {...register('storeAddress', { required: true })}
            />
          </div>
          <div>
            <FormTextboxGroup
              name="storeAddressDetail"
              autoComplete="off"
              placeholder="상세주소를 입력해주세요"
              maxLength={50}
              validators={{
                required: true,
              }}
              validations={['required']}
            />
          </div>
        </>
      )}
      {daumModalVisible && (
        <div className="fixed inset-0 flex flex-center z-50">
          <div className="relative w-full h-full shadow-lg lg:w-[500px] lg:h-[390px]">
            <DaumPost onComplete={handleComplete} />
            <img
              className="absolute top-[-30px] right-0 cursor-pointer"
              src={iconClose}
              alt="x"
              onClick={() => setDaumModalVisible(false)}
            />
          </div>
        </div>
      )}
      {modalVisible && (
        <div className="fixed inset-0 w-full h-full flex flex-center z-20">
          <div className="fixed inset-0 bg-[#000] opacity-20" />
          <div className="relative flex flex-col w-full h-full bg-white shadow-lg lg:w-[500px] lg:h-auto lg:max-h-[480px] overflow-scroll">
            <div className="flex flex-col flex-center py-[24px]">
              <h3 className="m-0">상호명으로 주소 찾기</h3>
              <p className="text-sm text-textSecondary mt-2 text-center">
                매장 주소와 일치하는 주소를 선택해주세요
                <br />
                내 매장 주소가 목록에 없다면 주소로 검색해주세요
              </p>
              <div className="flex items-start textbox mt-2">
                <input
                  className="w-full p-2 !bg-white"
                  type="text"
                  placeholder="상호명을 입력하세요"
                  onChange={(e) => setCompanyName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      onClickGetRegionByName()
                    }
                  }}
                />
                <button
                  type="button"
                  className="btn primary min-w-[60px] h-[42px] ml-4 hover:text-white"
                  onClick={onClickGetRegionByName}
                >
                  검색
                </button>
              </div>
            </div>
            {regions && regions.length > 0 ? (
              <div className="max-h-[200px] overflow-scroll">
                {regions.map(
                  (region, i) => !!region.fullAddressRoad && (
                    <div
                      key={i}
                      className={`flex bg-[#fff] py-[16px] px-[16px] font-medium cursor-pointer hover:bg-[#f0f0f0] ${
                        i === regions.length ? '' : 'border-b-[1px] border-line'
                      }`}
                      onClick={() => onClickRegion(region)}
                    >
                      {region.fullAddressRoad}
                    </div>
                  ),
                )}
              </div>
            ) : (
              <div className="flex flex-col flex-center bg-white pb-4 h-[200px]">
                <div className="text-center py-[16px] mb-4">
                  상호명에 해당하는 검색 결과가 없습니다.
                  <br />
                  다시 검색하거나 주소로 검색해주세요.
                </div>
              </div>
            )}
            <div className="relative flex flex-col-reverse flex-center w-full lg:flex-row gap-4 my-4 mlg:px-4">
              <button
                type="button"
                className="flex-1 max-w-[160px] w-full secondary large mlg:max-w-full"
                onClick={() => {
                  setCompanyName('')
                  setModalVisible(false)
                  setRegions([])
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="flex-1 max-w-[160px] w-full primary large mlg:max-w-full"
                onClick={onClickSearchByPostalCode}
              >
                주소 검색
              </button>
            </div>
            <img
              className="absolute top-[-30px] right-0 cursor-pointer"
              src={iconClose}
              alt="x"
              onClick={() => setModalVisible(false)}
            />
          </div>
        </div>
      )}
      <input type="hidden" {...register('storeLat')} />
      <input type="hidden" {...register('storeLon')} />
      <input type="hidden" {...register('storeKey')} />
    </div>
  )
}

export default InputAddress
