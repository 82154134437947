import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs'
import { useFormContext, useWatch } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import cx from 'classnames'

import moment from 'moment'
import FormMapSelectionBox from './formMapSelectionBox'
import DeleteIcon from '../../../assets/icons/icon-outlined-editor-delete-small.svg'
import InfoIcon from '../../../assets/icons/icon-filled-suggested-question-circle.svg'
import { Maps } from '../../../components/maps'
import InputValidation from '../../../components/inputValidation'
import MapMultiSelectBox from './mapMultiSelectBox'
import MapMultiSelectorMobile from './mapMultiSelectorMobile'
import AutoSuggestBox from '../../../components/autoSuggestBox'
import {
  searchAddresses, calcRegions, searchNameAddresses, getRegionMultipliers,
} from '../../../services/api'
import Spinner1 from '../../../assets/icons/loading-spinner.svg'
import { formatNumber } from '../../../services/utils'
//
const regionValueName = 'ad_campaign_regions'
//
const MapSelector = ({ editable = true, bottomViewVisible = true }) => {
  const mapRef = React.useRef(null)
  const formMapSelectionBoxRef = React.useRef(null)
  const countRef = React.useRef(null)
  const [countLoading, setCountLoading] = React.useState(false)
  const [tabIndex, setTabIndex] = React.useState(0)
  const [regionMultipliers, setRegionMultipliers] = React.useState([])
  const [drawRegions, setDrawRegions] = React.useState([])
  //
  const { setValue, getValues, ...methods } = useFormContext()
  const { enqueueSnackbar } = useSnackbar()
  //
  const ad_campaign_regions_value = useWatch({
    name: regionValueName,
  })
  const ad_campaign_regions_count_value = useWatch({
    name: 'ad_campaign_regions_count',
    control: methods.contorl,
  })
  const ad_campaign_region_multiplier_value = useWatch({
    name: 'ad_campaign_region_multiplier',
    control: methods.contorl,
  })
  //
  const [state, setState] = React.useState({ isError: false })
  //
  React.useEffect(() => {
    getRegionMultipliers().then((result) => {
      if (!result) return
      setRegionMultipliers(result)
    })
  }, [])
  //
  React.useEffect(() => {
    let maxMultiplier = 1
    const regions = ad_campaign_regions_value
    if (regions && regions.length > 0) {
      regions.forEach((region) => {
        const resultValue = regionMultipliers.find((obj) => obj.key === region.key || obj.key === region.parent)
        if (resultValue && resultValue.multiplier > maxMultiplier) {
          maxMultiplier = resultValue.multiplier
        }
      })
    }
    setValue('ad_campaign_region_multiplier', maxMultiplier)
  }, [regionMultipliers, ad_campaign_regions_value, ad_campaign_region_multiplier_value])
  //
  React.useEffect(() => {
    const isError = methods.formState.isSubmitted && methods.formState.errors?.ad_campaign_regions_count
    setState({ isError })
  }, [methods.formState])
  //
  React.useEffect(() => {
    if (mapRef.current) {
      mapRef.current.removeUnusedGeometry(ad_campaign_regions_value)
      ad_campaign_regions_value.forEach(async (item) => {
        if (drawRegions.some((obj) => obj.key === item.key)) return
        setDrawRegions((prev) => [...prev, item])
        const geo = await mapRef.current.getGeomtry(item)
        if (mapRef.current) mapRef.current.drawGeometry(geo, item.key)
      })
    }
  }, [ad_campaign_regions_value])
  //
  React.useEffect(() => {
    (async () => {
      const result = await getValues('ad_campaign_dates')
      if (result.length !== 2) return
      if (result.some((item) => item === null)) return
      if (!ad_campaign_regions_count_value) return
      const diffDays = moment(result[1]).diff(result[0], 'day') + 1
      const minBudget = Math.max(Math.floor(((100000 / 31) * diffDays) / 10000) * 10000, 100000)
      await setValue('ad_campaign_min_budget', minBudget, {
        shouldValidate: true,
      })
      const [budget, recommendedBudget, isFranchise] = await getValues([
        'ad_campaign_budget',
        'ad_campaign_recommended_budget',
        'is_franchise',
      ])
      const newRecommendedBudget = Math.ceil((
        ad_campaign_regions_count_value * 15 * 0.65 * 5
        * (diffDays / 30)) / 10000) * 10000
      await setValue('ad_campaign_recommended_budget', newRecommendedBudget)
      if (!budget && !recommendedBudget && !isFranchise) {
        await setValue('ad_campaign_budget', Math.max(newRecommendedBudget, minBudget), {
          shouldValidate: true,
        })
      }
    })()
  }, [ad_campaign_regions_count_value, setValue, getValues])
  //
  const onClickTab = (index) => {
    setTabIndex(index)
  }
  //
  const onAddressRequested = async (value) => {
    const result = await searchAddresses(value.trim())
    if (result === false) return
    const data = result.map((item) => ({
      key: item.id,
      value: item.full.trim().toLowerCase(),
      depth: item.full.trim().split(' ').length,
    }))
    return data
  }
  //
  const alertDuplicated = async () => {
    enqueueSnackbar('검색하신 지역은 이미 타기팅에 포함된 지역(동)입니다.', { variant: 'warning' })
  }
  //
  const onAddressSelected = React.useCallback(
    () => async (address) => {
      const duplicated = ad_campaign_regions_value.some((item) => item.key === address.key)
      if (duplicated) {
        alertDuplicated()
        return
      }
      await formMapSelectionBoxRef.current.addItem(address)
      // const geo = await mapRef.current.getGeomtry(address, type)
      // await mapRef.current.drawGeometry(geo)
      regionMultipliers.forEach((obj) => {
        if (obj.key === address.key || obj.key === address.parent) {
          const currentRegionMultiplier = getValues('ad_campaign_region_multiplier')
          if (obj.multiplier > currentRegionMultiplier) {
            setValue('ad_campaign_region_multiplier', obj.multiplier)
          }
        }
      })
    },
    [regionMultipliers, ad_campaign_regions_value],
  )
  //
  const onAddressDeselected = async (address) => {
    await formMapSelectionBoxRef.current.deleteItem(address)
  }
  //
  const onClearAddressesClick = async (e) => {
    if (e.clientX === 0 && e.clientY === 0) {
      return
    }
    e.preventDefault()
    await formMapSelectionBoxRef.current.clearAll()
  }
  //
  const onAddressClick = async () => {
    // const geo = await mapRef.current.getGeomtry(item)
    // await mapRef.current.drawGeometry(geo)
  }
  //
  const removeGeometry = (key) => {
    if (drawRegions.length > 0 && mapRef.current) {
      mapRef.current.removeGeometry(key)
    }
  }

  //
  const onNameSearchRequested = async (value) => {
    const result = await searchNameAddresses(value.trim())
    if (!result) return
    const data = result.map((item) => ({
      key: item.id,
      value: item.full.trim().toLowerCase(),
      depth: item.full.trim().split(' ').length,
    }))
    return data
  }
  //
  const renderApartListValue = (value) => {
    const [region, apartment] = value.split('-')
    return (
      <span>
        <span className="font-bold">{region}</span>
        {`-${apartment}`}
      </span>
    )
  }
  //
  React.useEffect(() => {
    (async () => {
      if (ad_campaign_regions_value.length === 0) {
        if (countRef.current) countRef.current.textContent = 0
        return
      }
      await setCountLoading(true)
      const result = (await calcRegions(ad_campaign_regions_value)).data || 0
      const count_value = await getValues('ad_campaign_regions_count')
      await setCountLoading(false)
      if (count_value === result) return
      await setValue('ad_campaign_regions_count', result, {
        shouldValidate: true,
      })
    })()
  }, [ad_campaign_regions_value, getValues, setValue])
  //
  const renderMap = () => (
    <>
      <div className="flex flex-col md:flex-row map-holder">
        <div className="map-view h-[225px] md:h-[300px]">
          <Maps ref={mapRef} />
        </div>
        <div className="map-tags flex-grow h-[95px] md:ml-6 mt-6 md:mt-0 md:h-[300px] border p-2 overflow-scroll">
          <FormMapSelectionBox
            ref={formMapSelectionBoxRef}
            onAddressClick={onAddressClick}
            name={regionValueName}
            removeGeometry={removeGeometry}
          />
        </div>
      </div>
      {/**/}
      {bottomViewVisible && (
        <>
          <div className="note">
            <span>꼭 알아두세요!</span>
            <span style={{ color: 'var(--text-secondary)' }}>
              • 광고 대상 수가 2,000이상 이어야 광고를 신청할 수 있습니다
            </span>
          </div>
          {/**/}
          <div className="flex flex-row amount">
            <div className="flex-grow">
              <span>광고 대상 수</span>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 250 }}
                trigger={['hover', 'focus', 'click']}
                overlay={(
                  <Tooltip>
                    {
                      '선택하신 지역에서 광고가 노출될 수 있는 전체 모수를 의미합니다.\n예를 들어 광고 대상 수가 1,000명이면 1,000명의 총 시청가능 가구를 대상으로 광고를 노출시킵니다.'
                    }
                  </Tooltip>
                )}
              >
                <button type="button">
                  <img src={InfoIcon} alt="info" />
                </button>
              </OverlayTrigger>
            </div>
            {!countLoading && (
              <>
                <span ref={countRef}>
                  {formatNumber(
                    ad_campaign_regions_count_value <= 100
                      ? 100
                      : Math.ceil(ad_campaign_regions_count_value / 100) * 100,
                  )}
                </span>
                <span>&nbsp;+</span>
              </>
            )}
            {countLoading && <img src={Spinner1} alt="spinner" className="spinner" hight="30px" width="30px" />}
          </div>
        </>
      )}
      <input type="hidden" {...methods.register('ad_campaign_regions_count', { min: 2000 })} />
      <input type="hidden" {...methods.register('ad_campaign_region_multiplier')} />
      <InputValidation names={['ad_campaign_regions_count']} validations={['min']} />
    </>
  )

  //
  return (
    <div
      className={cx('create-ad-part', `map-selector${state.isError ? ' error' : ''}`, {
        '!border-0 !p-0': !editable,
      })}
    >
      {editable && (
        <>
          <h3 className="xs:text-base">광고를 노출하고 싶은 지역을 선택해주세요</h3>
          <Tabs className="tabs-primary" selectedTabClassName="active" onSelect={onClickTab} selectedIndex={tabIndex}>
            <TabList className="flex tab-list-primary">
              <Tab className="tab-primary">지역 검색</Tab>
              <Tab className="tab-primary">지역 직접 선택</Tab>
              <Tab className="tab-primary">
                <div className="flex items-center justify-start lg:justify-between pl-2.5 xxs:pl-1">
                  <span className="tab-primary-text">아파트 검색</span>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 250 }}
                    trigger={['hover', 'focus', 'click']}
                    overlay={(
                      <Tooltip>
                        {
                          '동단위 타기팅으로 해당 아파트가 포함된 지역(동) 단위로 타기팅 됩니다.\n현재 동단위 타기팅보다 더 세밀한 지역 타기팅 준비중입니다.'
                        }
                      </Tooltip>
                    )}
                  >
                    <button className="p-0 mb-[-5px] xs:mb-[1px]" type="button">
                      <img
                        className="xs:w-[18px] xs:h-[18px] xxs:w-[15px] xxs:h-[15px] xxs:mx-[2px]"
                        src={InfoIcon}
                        alt="info"
                      />
                    </button>
                  </OverlayTrigger>
                </div>
              </Tab>
            </TabList>

            <TabPanel>
              <AutoSuggestBox
                placeholder="검색하고자 하는 동 이름을 입력해 주세요"
                onRequested={onAddressRequested}
                onSelected={onAddressSelected('region_search')}
              />
            </TabPanel>
            <TabPanel>
              <MapMultiSelectBox
                name={regionValueName}
                onAddressSelected={onAddressSelected('region_select')}
                onAddressDeselected={onAddressDeselected}
              />
              <MapMultiSelectorMobile
                name={regionValueName}
                onAddressSelected={onAddressSelected('region_select')}
                onAddressDeselected={onAddressDeselected}
                onPressBack={() => {
                  setTabIndex(0)
                }}
              />
            </TabPanel>
            <TabPanel>
              <AutoSuggestBox
                placeholder="검색하고자 하는 아파트 이름을 입력해 주세요"
                onRequested={onNameSearchRequested}
                onSelected={onAddressSelected('apartment_search')}
                renderValue={renderApartListValue}
              />
            </TabPanel>
          </Tabs>
        </>
      )}
      {/**/}
      <div className="flex flex-row xs:mb-2">
        <span className="flex-grow self-center text-lg font-bold xs:text-base">선택한 지역</span>
        <button
          className="light medium right-icon mb-2 xs:h-[48px] xs:py-2 xs:px-4"
          disabled={ad_campaign_regions_value.length < 1}
          onClick={onClearAddressesClick}
        >
          모두 삭제
          <img src={DeleteIcon} alt="delete" />
        </button>
      </div>
      {/**/}
      {renderMap()}
    </div>
  )
}

export default MapSelector
