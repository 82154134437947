/* eslint-disable max-len */
import React from 'react'
import moment from 'moment'
//
function setCookie(cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${cname}=${cvalue};${expires};path=/`
}

function getCookie(cname) {
  const name = `${cname}=`
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
//
export function setAdCampaign(data) {
  // localStorage.setItem("ad_campaign", JSON.stringify(data))
  setCookie('ad_campaign', JSON.stringify(data), 1)
  return data
}
//
export function buildAdCampaign(data) {
  const startAt = data.adStartAt ? new Date(data.adStartAt) : null
  const endAt = data.adEndAt ? new Date(data.adEndAt) : null
  const period = startAt && endAt ? moment(endAt).diff(startAt, 'day') + 1 : 0
  //
  const step1 = {
    ad_campaign_regions:
      (data.regionsObj || []).map((item) => ({
        key: item.id,
        value: item.name,
        depth: item.depth,
      })) || [],
    ad_campaign_dates: [startAt, endAt],
    ad_campaign_budget: data.amount || 0,
    ad_campaign_regions_count: 0,
    ad_campaign_period: period,
    ad_campaign_target_impression: data.targetImpression || 0,
    ad_campaign_bonus_impression: data.bonusImpression || 0,
    ad_campaign_bonus_multiplier: data.bonusMultiplier || 0,
    ad_campaign_product_id: data.product?._id,
    ad_campaign_product: data.product,
    ad_campaign_product_multiplier: data.productMultiplier || 1,
    ad_campaign_region_multiplier: data.regionMultiplier || 1,
    ad_campaign_recommend_request_id: data.recommendRequest,
    galleryId: data.galleryId,
    companyName: data.companyName,
    companySector: data.companySector,
    storeAddress: data.storeAddress,
    storeAddressDetail: data.storeAddressDetail,
    storeAddressKey: data.storeAddressKey,
    storePostalCode: data.storePostalCode,
    storeLat: data.storeLat,
    storeLong: data.storeLong,
    storeImages: data.storeImages,
    storeTel: data.storeTel,
    storeDesc: data.storeDesc,
    isFirst: false,
    business: data.business,
    clientBusinessId: data.clientBusinessId,
    x_clientBusinessId_validated: !!data.clientBusinessId,
    clientBusinessDocs: data.clientBusinessDocs,
    x_clientBusinessDocs_url: data.clientBusinessDocs,
    referrer: data.referrer,
    is_franchise: !!data.franchiseName,
    franchise_budget: data.franchiseBudget,
    fixedBudget: data.fixedBudget,
    promotion: data.promotion,
  }
  const step2 = {
    ad_campaign_asset_id: data.asset?._id || null,
    ad_campaign_asset: data.asset || null,
  }
  //
  return {
    id: data._id,
    name: data.campaignName,
    updatedAt: data.updatedAt,
    status: data.status,
    ad_campaign_is_paid: data.payment,
    step1: {
      ad_campaign_id: data._id,
      ...step1,
    },
    step2: {
      ad_campaign_id: data._id,
      ...step2,
    },
    step3: {
      ad_campaign_id: data._id,
      ad_campaign_name: data.campaignName,
      ad_campaign_age_restriction: false,
      ad_campaign_payment_method_agreement: false,
      ad_campaign_payment_method: '',
      // ad_campaign_skpay_encrypted_user_agent: "",
      // ad_campaign_payment_done: false,
      ad_campaign_invoice_id: '',
      ad_campaign_agency_discount: 0,
      ad_campaign_point: 0,
      ad_campaign_coupon: null,
      ad_campaign_discount: 0,
      ad_campaign_discount_method: null,
      ad_campaign_cost: step1.ad_campaign_budget,
      ...step1,
      ...step2,
    },
  }
}
//
export function getAdCampaign() {
  // const data = JSON.parse(localStorage.getItem("ad_campaign")) || {}
  let data = null
  try {
    data = JSON.parse(getCookie('ad_campaign'))
    if (!data) throw new Error('nulled value.')
  } catch (e) {
    data = buildAdCampaign({})
    setAdCampaign(data)
  }
  return data
}
//
export function resetAdCampaign() {
  // localStorage.removeItem("ad_campaign")
  return getAdCampaign()
}
//
export function transformAdCampaign(data, step) {
  let result = {}
  switch (step) {
      case 1:
        result.regions = data.ad_campaign_regions.map((item) => item.key)
        result.regionsObj = data.ad_campaign_regions.map((item) => ({
          id: item.key,
          name: item.value,
          depth: item.depth,
        }))
        const [adStartAt, adEndAt] = data.ad_campaign_dates
        result.adStartAt = adStartAt
        result.adEndAt = adEndAt
        result.amount = data.ad_campaign_budget
        result.targetImpression = data.ad_campaign_target_impression
        result.bonusImpression = data.ad_campaign_bonus_impression
        result.bonusMultiplier = data.ad_campaign_bonus_multiplier
        result.productId = data.ad_campaign_product_id
        result.productMultiplier = data.ad_campaign_product_multiplier
        result.regionMultiplier = data.ad_campaign_region_multiplier
        result.clientBusinessId = data.clientBusinessId
        result.clientBusinessDocs = data.x_clientBusinessDocs_url
        result.referrer = data.referrer
        result.recommendRequest = data.ad_campaign_recommend_request_id
        break
      case 2:
        result.assetId = data.assetId
        break
      case 3:
        break
      default:
        result = { ...data }
  }
  return result
}
//
export function formatNumber(input) {
  if (!input) return 0
  return parseInt(input, 10).toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
  })
}
//
export function roundNumber(num) {
  if (num >= 1000) {
    return Math.floor(num / 100) * 100
  }
  if (num >= 100) {
    return Math.floor(num / 10) * 10
  }
  return num
}
//
export function formatDate(date, separator = '/') {
  if (!date || !moment(date).isValid()) {
    return 'invalid date'
  }
  const tempDate = new Date(date)
  const month = tempDate.getMonth() + 1
  const _date = tempDate.getDate()
  const strMonth = month < 10 ? `0${month}` : `${month}`
  const strDate = _date < 10 ? `0${_date}` : `${_date}`
  return [tempDate.getFullYear(), strMonth, strDate].join(separator)
}
//
export function useWindowSize() {
  const [size, setSize] = React.useState([0, 0])
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export function validateAdCampaign(data) {
  if (
    data.step3.ad_campaign_period === 0
    || data.step3.ad_campaign_asset === null
    || data.step3.ad_campaign_asset_id === ''
    || data.step3.ad_campaign_budget === 0
    || data.step3.ad_campaign_regions.length === 0
    || !data.step3.companyName
    || !data.step3.companySector
    || !data.step3.storeAddress
    || !data.step3.storeAddressDetail
  ) return false
  return true
}

export function extractContent(s) {
  const span = document.createElement('span')
  span.innerHTML = s
  return span.textContent || span.innerText
}

export function checkBusinessId(number) {
  const numberMap = number
    .replace(/-/gi, '')
    .split('')
    .map((d) => parseInt(d, 10))

  if (numberMap.length === 10) {
    const keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5]
    let chk = 0

    keyArr.forEach((d, i) => {
      chk += d * numberMap[i]
    })

    chk += parseInt((keyArr[8] * numberMap[8]) / 10, 10)
    return Math.floor(numberMap[9]) === (10 - (chk % 10)) % 10
  }

  return false
}

// 약식으로 만든 함수
export function transNumToKoreanMan(num, isFloor = true) {
  const flooredNum = isFloor ? Math.floor(num / 10000) * 10000 : num
  const arrManWord = ['', '만']
  const tempArr = String(flooredNum).split('').reverse()
  const word = []
  tempArr.forEach((c, i) => {
    const digitIndex = i % 4
    if (digitIndex === 0) {
      word.push(arrManWord[Math.floor(i / 4)])
    }
    word.push(c)
  })
  let result = word.reverse().join('')
  if (result.includes('0000')) {
    result = result.replace('0000', '')
  }
  return result
}
const randomChars = '0123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ'

export function randomString(length = 8, chars = randomChars) {
  let result = ''
  for (let i = length - 1; i > 0; i -= 1) {
    result += chars[Math.round(Math.random() * (chars.length - 1))]
  }
  return result
}

export function transformEstimateResult(estimateResult) {
  const {
    ad_campaign_regions, ad_campaign_dates, ad_campaign_length, ad_campaign_regions_count,
  } = estimateResult

  const strRegion = ad_campaign_regions.map((item) => item.value).join(',')
  const strDates = `${formatDate(ad_campaign_dates[0])} ~ ${formatDate(ad_campaign_dates[1])} (${
    moment(ad_campaign_dates[1]).diff(ad_campaign_dates[0], 'day') + 1
  }일)`
  const text = `[광고 견적내기]\n- 지역: ${strRegion}\n- 일정: ${strDates}\n- 길이: ${ad_campaign_length}초\n- 모수: ${ad_campaign_regions_count}명`
  return text
}

export const getStrTime = (time, isRange = false) => {
  const hour = Math.floor(time / 100)
  const min = time % 100
  const strMin = min === 0 ? '00' : `${min}`
  let strTime = `${hour}:${strMin}`
  if (isRange) {
    let afterHour = hour
    const afterMin = min === 40 ? 0 : min + 20
    if (afterMin === 0) afterHour += 1
    const strAfterMin = afterMin === 0 ? '00' : `${afterMin}`
    strTime = `${hour}:${strMin} ~ ${afterHour}:${strAfterMin}`
  }
  return strTime
}

export const convertCompanyTel = (_value) => {
  let value = _value
  if (!value) {
    return ''
  }

  value = value.replace(/[^0-9*]/g, '')

  const result = []
  let restNumber = ''

  // 지역번호와 나머지 번호로 나누기
  if (value.startsWith('02')) {
    // 서울 02 지역번호
    result.push(value.substr(0, 2))
    restNumber = value.substring(2)
  } else if (value.startsWith('1')) {
    // 지역 번호가 없는 경우
    // 1xxx-yyyy
    restNumber = value
  } else {
    // 나머지 3자리 지역번호
    // 0xx-yyyy-zzzz
    result.push(value.substr(0, 3))
    restNumber = value.substring(3)
  }

  if (restNumber.length === 7) {
    // 7자리만 남았을 때는 xxx-yyyy
    result.push(restNumber.substring(0, 3))
    result.push(restNumber.substring(3))
  } else {
    result.push(restNumber.substring(0, 4))
    result.push(restNumber.substring(4))
  }

  return result.filter((val) => val).join('-')
}

export const getPromotionText = (type, value) => {
  if (type === 'DISCOUNT') {
    return `${value}% 할인`
  }
  if (type === 'POINT') {
    return `${value} 포인트 적립`
  }
  return value
}
//
export function getFranchiseNoticeRow(data) {
  const dateFormat = 'YYYY/MM/DD'
  return data.map((item) => {
    let status = '청약 대기 중'
    let statusCode = 'WAIT'
    if (moment(item.startAt).isBefore(moment())) {
      status = '청약 접수 중'
      statusCode = 'ONGOING'
    } else if (moment(item.endAt).isBefore(moment())) {
      status = '청약 마감'
      statusCode = 'END'
    }
    const period = `${moment(item.startAt).format('MM/DD')}~${moment(item.endAt).format('MM/DD')}`
    return {
      ...item,
      id: item._id,
      status,
      statusCode,
      createdAt: moment(item.createdAt).format(dateFormat),
      period,
    }
  })
}
//
export function getFranchiseAdsRow(data) {
  return {
    ...data,
    docs: data.docs.map((item) => {
      let status = '대기'
      let statusCode = 'WAIT'
      if (item.subStatus === 'BROADCAST') {
        status = '집행 중'
        statusCode = 'ONGOING'
      } else if (item.subStatus === 'END') {
        status = '종료'
        statusCode = 'END'
      }
      const period = `${moment(item.adStartAt).format('MM/DD')}~${moment(item.adEndAt).format('MM/DD')}`
      const bonusPercent = `${Math.floor(item.bonusMultiplier * 100)}%`
      const amount = `${transNumToKoreanMan(item.amount)}원`
      const franchiseBudget = `${transNumToKoreanMan(item.franchiseBudget)}원`
      const headOfficeBudget = `${transNumToKoreanMan(item.amount - item.franchiseBudget)}원`
      const amountDetail = `${franchiseBudget}/${headOfficeBudget}`
      return {
        ...item,
        id: item._id,
        status,
        statusCode,
        period,
        bonusPercent,
        budget: item.amount,
        amount,
        amountDetail,
      }
    }),
  }
}
//
export const getCompanySectorText = (sector) => {
  switch (Number(sector)) {
      case 1:
        return '전통시장'
      case 2:
        return '외식업'
      case 3:
        return '카페/디저트'
      case 4:
        return '패스트푸드'
      case 5:
        return '마트/신선식품'
      case 6:
        return 'PC/통신기기/가전'
      case 7:
        return '기타 도/소매업'
      case 8:
        return '패션/잡화'
      case 9:
        return '미용/뷰티'
      case 10:
        return '학원/교육'
      case 11:
        return '스포츠/피트니스'
      case 12:
        return '취미/레저'
      case 13:
        return '자동차관리'
      case 14:
        return '하우징/인테리어'
      case 15:
        return '기타서비스'
      case 16:
        return '공공서비스'
      case 17:
        return '브랜드'
      case 18:
        return '분양광고'
      default:
        return null
  }
}

export const getThumbnail = (videoUrl) => {
  if (!videoUrl) return ''
  return videoUrl.replace('_merged', '').replace('.mp4', '.jpg')
}

export const getMarketingNotiText = (agree, timestamp) => {
  const resultText = [
    `귀하는 ${moment(timestamp).format('YYYY년 MM월 DD일 HH시 mm분')}`,
    `BTV우리동네광고의 마케팅 정보 수신을 ${agree ? '동의' : '거부'}하셨습니다.`,
  ].join('\n')
  const subText = '[마이페이지 - 회원 정보] 메뉴에서 마케팅 수신 정보 동의 설정을 변경하실 수 있습니다.'
  return { resultText, subText }
}

export const getTopFiveScores = (objects) => {
  // 객체 배열을 score 기준으로 내림차순으로 정렬합니다.
  objects.sort((a, b) => b.score || 0 - a.score || 0)

  // 상위 5개의 객체를 추출합니다.
  const topFive = objects.slice(0, 5)

  return topFive
}

export const createDateRangeArray = (startDate, endDate) => {
  const dateArray = []
  const currentDate = new Date(startDate)
  while (moment(currentDate).isBefore(moment(endDate))) {
    const year = currentDate.getFullYear().toString().slice(-2)
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    dateArray.push(`${year}/${month}`)

    // 다음 달로 이동
    currentDate.setMonth(currentDate.getMonth() + 1)
  }
  return dateArray
}

export const getFullCityName = (cityName) => {
  switch (cityName) {
      case '서울':
        return '서울특별시'
      case '부산':
        return '부산광역시'
      case '대구':
        return '대구광역시'
      case '인천':
        return '인천광역시'
      case '광주':
        return '광주광역시'
      case '대전':
        return '대전광역시'
      case '울산':
        return '울산광역시'
      case '세종':
        return '세종특별자치시'
      case '경기':
        return '경기도'
      case '강원':
        return '강원도'
      case '충북':
        return '충청북도'
      case '충남':
        return '충청남도'
      case '전북':
        return '전라북도'
      case '전남':
        return '전라남도'
      case '경북':
        return '경상북도'
      case '경남':
        return '경상남도'
      case '제주':
        return '제주특별자치도'
      default:
        return cityName
  }
}

/**
 * 보너스 노출량 계산 함수
 * WARNING: 서버에서 통일해서 사용하므로 이제 사용되지 않음
 * @param {number} impression - 현재 노출량
 * @param {number} budget - 사용자가 입력한 예산
 * @returns
 */
export const getBonusImpression = (impression, period, avgBudget, userType) => {
  let bonusMultiplier
  const isPlus = period >= 90
  if (Number(userType) !== 1 || avgBudget < 100000) {
    bonusMultiplier = 0
  } else if (avgBudget < 200000) {
    // bonusMultiplier = isPlus ? 0.3 : 0.2
    bonusMultiplier = 0
  } else if (avgBudget < 500000) {
    bonusMultiplier = isPlus ? 0.7 : 0.5
  } else if (avgBudget < 1000000) {
    bonusMultiplier = isPlus ? 1.0 : 0.7
  } else {
    bonusMultiplier = isPlus ? 1.0 : 0.9
  }
  return {
    bonusMultiplier,
    bonusImpression: Math.floor(impression * bonusMultiplier),
  }
}

/**
 * 보너스 노출량 조건을 조건 개월 수와 추가 개월 수로 구분짓는 함수
 * @param {string} rule - 조건 개월 수+추가 개월 수. Ex) "1+1", "3+1", "3+2"
 * @returns
 */
// export const convertImpressionRule = (rule) => {
//   const [conditionMonth, bonusMonth] = rule.split('+').map((item) => parseInt(item, 10))
//   return { conditionMonth, bonusMonth }
// }

/**
 * 보너스 노출량 계산 함수
 * @param {string} rule - 조건 개월 수 + 추가 개월 수 Ex) "1+1", "3+1", "3+2"
 * @param {number} impression - 현재 노출량
 * @param {number} period - 광고 기간 (일 단위)
 * @param {number} budget - 사용자가 입력한 예산
 * @param {number} minMonthlyBudgetForBonus - 보너스 노출 혜택 제공을 위한 최소 월 예산. 백오피스에서 설정 가능
 * @returns
 */
// export const getBonusImpressionBefore = (rule, impression, period, budget, minMonthlyBudgetForBonus) => {
//   if (!rule || !rule.includes('+')) throw new Error('rule is required.')
//   const month = parseInt(period / CONFIG.DAYS_PER_MONTH, 10)
//   const { conditionMonth, bonusMonth } = convertImpressionRule(rule)
//   const maxBonusMonth = Math.floor(month / conditionMonth) * bonusMonth
//   let bonusImpression = 0
//   let count = maxBonusMonth
//   while (count > 0) {
//     if (minMonthlyBudgetForBonus * conditionMonth * count <= budget) {
//       bonusImpression = ((impression / period) * CONFIG.DAYS_PER_MONTH) * count
//       break
//     } else {
//       count -= 1
//     }
//   }
//   let needBudget = 0
//   if (count !== maxBonusMonth) {
//     const nextBonusBudget = minMonthlyBudgetForBonus * conditionMonth * (count + 1)
//     needBudget = nextBonusBudget - budget
//   }
//   return {
//     bonusImpression,
//     needBudget,
//   }
// }

// TEST - 테스트 세팅이 되어있지 않아 임시로 작성했습니다.
// console.log(getBonusImpressionBefore('3+1', 60000, 90, 100000, 500000)) // { bonusImpression: 0, needBudget: 1400000 }
// console.log(getBonusImpressionBefore('3+1', 60000, 89, 1300000, 500000)) // { bonusImpression: 0, needBudget: 0 }
// console.log(getBonusImpressionBefore('3+1', 60000, 90, 1300000, 500000)) // { bonusImpression: 0, needBudget: 200000 }
// console.log(getBonusImpressionBefore('3+1', 60000, 90, 1500000, 500000)) // { bonusImpression: 20000, needBudget: 0 }

// console.log(getBonusImpressionBefore('1+1', 90000, 90, 1500000, 500000)) // { bonusImpression: 90000, needBudget: 0 }
// console.log(getBonusImpressionBefore('1+1', 89000, 89, 1500000, 500000)) // { bonusImpression: 60000, needBudget: 0 }
// console.log(getBonusImpressionBefore('1+1', 90000, 90, 1400000, 500000)) // { bonusImpression: 60000, needBudget: 100000 }

// console.log(getBonusImpressionBefore('3+1', 60000, 90, 1500000, 500000)) // { bonusImpression: 20000, needBudget: 0 }
// console.log(getBonusImpressionBefore('3+1', 80000, 120, 2000000, 500000)) // { bonusImpression: 20000, needBudget: 0 }
// console.log(getBonusImpressionBefore('3+1', 100000, 150, 2500000, 500000)) // { bonusImpression: 20000, needBudget: 0 }
// console.log(getBonusImpressionBefore('3+1', 120000, 180, 3000000, 500000)) // { bonusImpression: 40000, needBudget: 0 }
// console.log(getBonusImpressionBefore('3+1', 120000, 180, 2900000, 500000)) // { bonusImpression: 20000, needBudget: 100000 }
