import React from 'react'

const Steps = ({ steps, selected, onStepClick }) => {
  // const [state, setState] = React.useState(selected)
  const className = `grid grid-cols-${steps.length} steps`
  //
  const onClick = async (e, step) => {
    if (onStepClick) onStepClick(e, step)
  }
  //
  return (
    <div className={className}>
      {steps.map((item, index) => (
        <Step
          key={index}
          no={index}
          title={item.title}
          shortTitle={item.shortTitle}
          selected={selected}
          onClick={onClick}
        >
          {item.content}
        </Step>
      ))}
    </div>
  )
}

export const Step = ({
  title, shortTitle, no, children, selected, onClick,
}) => (
  <button onClick={(e) => onClick(e, no)}>
    <div
      className={`flex flex-col step${selected === no ? ' selected' : ''}`}
    >
      <h2 className="hidden xl:block">
        <span>{no + 1}</span>
        {title}
      </h2>
      <h2 className="block xl:hidden">
        <span>{no + 1}</span>
        {shortTitle}
      </h2>
      {children}
    </div>
  </button>
)

export default Steps
