import React from 'react'
import Lottie from 'lottie-react'
import cx from 'classnames'

import loading from '../../../assets/lottie/loading.json'

const RecommendLoading = () => (
  <div className={cx(
    'absolute top-0 left-0 flex flex-col flex-center',
    'w-full h-[300px] lg:h-[600px] bg-white z-20',
  )}
  >
    <Lottie animationData={loading} />
    <h3>조건에 맞는 상권을 분석 중 입니다</h3>
  </div>
)

export default RecommendLoading
