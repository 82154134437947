import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'

import AppContext from '../../services/context'
import BasicLayout from '../../layouts/basic'
import HeaderCarousal from './components/headerCarousal'
import VideoBanner from './components/banner'
import Notice from './components/notice'
//
import { WhitePaper } from './components/papers'
import VideoToggle from './components/videoToggle'
import LogosCarousel from './components/logosCarousel'
import VideoCarousel from '../../components/videoCarousal2'
//
import img1 from '../../assets/imgs/1@3x.png'
import img2 from '../../assets/imgs/3@2x.png'
import img3 from '../../assets/imgs/2@3x.webp'
import img4 from '../../assets/imgs/5.svg'
import rightIcon from '../../assets/icons/icon-outlined-directional-right-large-primary.svg'
import rightIcon2 from '../../assets/icons/icon-outlined-directional-right-large.svg'
import VideoPaperCarousel from '../../components/videoPaperCarousal'
import BottomContactBar from '../../components/BottomContactBar'
import { useWindowSize } from '../../services/utils'
import useScrollDirection from '../../hooks/useScrollDirection'
import PromotionModal from './components/promotionModal'

const sec2Content = [
  {
    p: '지역 타기팅',
    s1: '내가 원하는 동네만 콕 찍어',
    s2: 'TV광고를 노출할 수 있어요',
    s3: '',
    src: img1,
  },
  {
    p: '경제적인 광고 집행',
    s1: '1회 노출당 5원(15초 기준)으로',
    s2: '타 매체 대비 저렴',
    s3: '',
    desc: '예) 대상 가구 5천개인 지역에서 2개월 집행시 약 50만원 소요',
    src: img2,
  },
  {
    p: '손쉬운 영상 제작이 무료로',
    s1: '웹사이트에 사진과 문구만 넣으면',
    s2: '누구나 쉽게 무료로',
    s3: 'TV 영상 광고를 제작할 수 있어요',
    src: img3,
  },
  {
    p: '인기 채널에 광고노출',
    s1: '인기 채널에서',
    s2: '우리 가게 TV 광고가 노출돼요',
    s3: '',
    src: img4,
  },
]
//
const sec3Content = {
  h3: '광고 소재 제작',
  p: '5분 완성!',
  btn1: '원하는 템플릿 고르기',
  btn2: '맘에 들 때까지 편집하기',
  btn3: '광고 소재 완성!',
  v1src: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/5min_1.mp4',
  v2src: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/5min_2.mp4',
  v3src: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/5min_3.mp4',
}
//
const sec4Content = {
  h3: '원하는 만큼만',
  p: '광고 신청',
  btn1: '광고지역 추천받기',
  btn2: '원하는 지역 선택하기',
  btn3: '원하는 기간 설정하기',
  btn4: '원하는 예산 입력하기',
  v1src: '/videos/homd-vid-recommend.mp4',
  v2src: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/request_1.mp4',
  v3src: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/request_2.mp4',
  v4src: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/request_3.mp4',
}
//
const sec5Content = {
  h3: '한 눈에 확인하는',
  p: '광고 효과',
  btn1: '지역별로 확인하기',
  btn2: '날짜별로 확인하기',
  btn3: '채널별로 확인하기',
  btn4: '세그먼트별로 확인하기',
  v1src: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/effect_1.mp4',
  v2src: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/effect_2.mp4',
  v3src: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/effect_3.mp4',
  v4src: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/effect_4.mp4',
}
//
const Home = () => {
  const { appState } = React.useContext(AppContext)
  const scrollDirection = useScrollDirection()

  const data = appState.landingPageInfo
  const history = useHistory()
  const [width] = useWindowSize()
  const interviewSectionRef = React.useRef(null)

  // 24.06 더드림 초고속 서비스 프로모션 모달용 코드
  const [isShown, setIsShown] = React.useState(false)

  // React.useEffect(() => {
  //   if (sessionStorage.getItem('toGallery') !== 'false') {
  //     history.push('/community')
  //     sessionStorage.setItem('toGallery', 'false')
  //  }
  // }, [])

  React.useEffect(() => {
    if (appState.userInfo?.userId) {
      datadogRum.setUser({
        id: appState.userInfo?.userId,
        // name: appState.userInfo?.userName,
        type: appState.userInfo?.userType,
      })
    }
  }, [appState.userInfo?.userId, appState.userInfo?.userName, appState.userInfo?.userType])

  const toInterviewSection = () => {
    if (interviewSectionRef.current) {
      interviewSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  //
  return (
    <BasicLayout>
      <div className="home">
        <section className="section-01 relative">
          <HeaderCarousal data={data.main} toInterviewSection={toInterviewSection} />
        </section>
        <section className="section-02">
          <div className="container">
            <div>
              <h2 className="hidden md:inline">
                <span className="whitespace-pre-wrap">
                  {'우리 가게, 우리 동네 TV에서 광고하자.\n경제적인 비용으로, 광고 효과는 더 크게!'}
                </span>
              </h2>
              <h2 className="inline md:hidden">
                <span className="whitespace-pre-wrap">
                  {'우리 가게, 우리 동네\nTV에서 광고하자.\n경제적인 비용으로,\n광고 효과는 더 크게!'}
                </span>
              </h2>
            </div>
            <div className="grid md:grid-cols-2 gap-10">
              {/**/}
              <div className="grid grid-rows-2 gap-10 md:mb-32">
                <WhitePaper {...sec2Content[0]} />
                <WhitePaper {...sec2Content[1]} />
              </div>
              {/**/}
              <div className="grid grid-rows-2 gap-10 md:mt-32">
                <WhitePaper {...sec2Content[2]} />
                <WhitePaper {...sec2Content[3]} />
              </div>
              {/**/}
            </div>
          </div>
        </section>
        <section className="section-08" ref={interviewSectionRef}>
          <div className="container">
            <div className="flex">
              <h2 className="hidden md:inline">
                <span>늘어나는 고객, 커져가는 인지도</span>
                <br />
                <span>B tv 우리동네 광고에서 이뤄드릴게요!</span>
              </h2>
              <h2 className="inline md:hidden">
                <span>{'늘어나는 고객,\n'}</span>
                <br />
                <span>{'커져가는 인지도\n'}</span>
                <br />
                <span>{'B tv 우리동네 광고에서\n'}</span>
                <br />
                <span>이뤄드릴게요!</span>
              </h2>
            </div>
          </div>
          <VideoCarousel data={data.interview} />
        </section>
        <section className="section-06">
          <div className="container">
            <div className="flex justify-center">
              <h2 className="hidden md:inline">
                <span>B tv 우리동네광고에서 집행했던 </span>
                <br />
                <span>TV 광고 영상이에요</span>
              </h2>
              <h2 className="inline md:hidden">
                <span>B tv 우리동네광고에서 집행했던</span>
                <br />
                <span>TV 광고 영상이에요</span>
              </h2>
            </div>
            <VideoPaperCarousel data={data.adExample} />
            {/* <div className="grid md:grid-cols-2 gap-10 md:gap-20">
              {data.adExample.map((item, index) => (
                <VideoPaper
                  data={item}
                  key={index}
                  controlsList="nodownload"
                  onContextMenu={(e) => e.preventDefault()}
                />
              ))}
            </div> */}
            <div className="flex justify-center items-center button-wrapper">
              <Link to="/community">
                <button className="button primary">갤러리 바로가기</button>
              </Link>
            </div>
          </div>
        </section>
        <section className="section-11 relative flex flex-col items-center justify-center">
          <div className="container mt-4">
            <h2>
              <span>소재 제작부터 광고 관리까지</span>
              <br />
              <span>모두 한번에 끝내세요!</span>
            </h2>
          </div>
        </section>
        <section className="section-03">
          <div className="container">
            <VideoToggle {...sec3Content} />
          </div>
        </section>
        <section className="section-04 ">
          <div className="container">
            <VideoToggle {...sec4Content} />
          </div>
        </section>
        <section className="section-05">
          <div className="container">
            <VideoToggle {...sec5Content} />
          </div>
        </section>
        <section className="section-07">
          <div className="title-holder">
            <h2>
              <span>인기 채널에</span>
              <br />
              <span>우리 가게 TV 광고가 노출돼요.</span>
            </h2>
          </div>
          {/* <ImagesCarousel /> */}
          {/* <div className="sp" /> */}
          <LogosCarousel />
        </section>
        <section className="section-09">
          <div className="container">
            <h2>관련사이트</h2>
            <VideoBanner data={data.site} />
          </div>
        </section>
        {/* <section className="section-10 relative flex flex-col items-center justify-center">
          <h2>
            <span>{"B tv 우리 동네 광고와 함께 만드는"}</span>
            <br />
            <span>{"성공 스토리! 이제 당신이 주인공입니다"}</span>
          </h2>
          <div className="flex flex-col md:flex-row">
            <Link className="button larger dark md:mr-6" to="/portal/media">
              {"광고 만들기"}
            </Link>
            <button className="larger dark">
              {"광고 만들기 튜토리얼 보기"}
            </button>
          </div>
        </section> */}
        <section className="section-12 justify-center">
          <div className="h-full relative flex flex-col items-end p-0">
            {/**/}
            <div className="overlay-1 flex flex-col h-full justify-center container" style={{ zIndex: 1 }}>
              <div className="flex flex-col justify-center h-full items-start">
                <h2 className="hidden md:block">
                  <span>B tv 우리 동네 광고와 함께 만드는</span>
                  <br />
                  <span>성공 스토리! 이제 당신이 주인공입니다</span>
                </h2>
                <h2 className="block md:hidden">
                  <span>B tv 우리 동네 광고와</span>
                  <br />
                  <span>함께 만드는 성공 스토리!</span>
                  <br />
                  <span>이제 당신이 주인공입니다</span>
                </h2>
                <button className="right-icon" onClick={() => history.push('/portal/media')}>
                  새로운 광고 시작하기
                  <img src={rightIcon} alt="right" />
                </button>
              </div>
            </div>
            {/**/}
            <div className="overlay-2 flex flex-col justify-center h-full">
              <div>
                <h3>
                  <span>광고 만드는</span>
                  <br />
                  <span>자세한 방법이 궁금해요.</span>
                </h3>
                <Link to="/tutorial/ad">
                  광고 만들기 튜토리얼 보기
                  <img src={rightIcon2} alt="right" />
                </Link>
              </div>
            </div>
          </div>
        </section>
        <Notice />
        {/* <PromotionModal isShown={isShown} setIsShown={setIsShown} /> */}
        {width >= 798 && <BottomContactBar scrollDirection={scrollDirection} />}
      </div>
    </BasicLayout>
  )
}

export default Home
