import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize'
// import ImageResizeEditing from '@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting'
// import ImageResizeHandles from '@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles'
//
import { useCookies } from 'react-cookie'
import { CONFIG } from '../services/config'
//

const config = {
  toolbar: {
    items: [
      '|',
      'heading',
      '|',
      'fontSize',
      '|',
      'bold',
      '|',
      'italic',
      '|',
      'imageUpload',
      'imageStyle:alignLeft',
      'imageStyle:alignCenter',
      'imageStyle:alignRight',
      '|',
      'link',
      '|',
      'bulletedList',
      '|',
      'numberedList',
      '|',
      'outdent',
      '|',
      'indent',
      '|',
      'blockQuote',
      '|',
      'undo',
      '|',
      'redo',
      '|',
      'alignment',
      '|',
    ],
  },
  language: 'ko',
  licenseKey: '',
}
//
class UploadAdapter {
  constructor(loader, token) {
    this.loader = loader
    this.token = token
  }

  upload() {
    return this.loader.file.then(
      (file) => new Promise((resolve, reject) => {
        this._initRequest()
        this._initListeners(resolve, reject, file)
        this._sendRequest(file)
      }),
    )
  }

  abort() {
    if (this.xhr) this.xhr.abort()
  }

  _initRequest() {
    this.xhr = new XMLHttpRequest()
    this.xhr.open('POST', `${CONFIG.API_ENDPOINT}/community/uploader`, true)
    this.xhr.setRequestHeader('Authorization', `Bearer ${this.token}`)
    this.xhr.responseType = 'json'
  }

  _initListeners(resolve, reject) {
    const { xhr } = this
    // const loader = this.loader;
    const genericErrorText = '파일을 업로드 할 수 없습니다.'

    xhr.addEventListener('error', () => {
      reject(genericErrorText)
    })
    xhr.addEventListener('abort', () => reject())
    xhr.addEventListener('load', () => {
      const { response } = xhr
      if (!response) return reject(response)
      if (response.code === 'WRONG_FILE_FORMAT') {
        return reject('잘못된 파일 형식입니다')
      }
      if (response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText,
        )
      }

      resolve({
        default: response.url, // 업로드된 파일 주소
      })
    })
  }

  _sendRequest(file) {
    const data = new FormData()
    data.append('upload', file)
    this.xhr.send(data)
  }
}
//
function MyCustomUploadAdapterPlugin(token) {
  return (editor) => {
    if (editor.plugins.has('FileRepository')) {
      // eslint-disable-next-line no-param-reassign
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => new UploadAdapter(loader, token)
    }
  }
}
//
const Editor = ({
  onReady, onChange, onBlur, onFocus,
}) => {
  const [cookies] = useCookies(['jwt_token'])
  const myPlugin = MyCustomUploadAdapterPlugin(cookies.jwt_token?.accessToken)

  return (
    <div className="editor">
      <CKEditor
        editor={ClassicEditor}
        config={{
          ...config,
          extraPlugins: [myPlugin],
        }}
        onReady={onReady}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  )
}

export default Editor
