import React from 'react'
import classNames from 'classnames'

import playIcon from '../../../assets/icons/icon-play.svg'
import pauseIcon from '../../../assets/icons/icon-pause.svg'
import illust_m0 from '../../../assets/imgs/illust_voice_m0.webp'
import illust_m1 from '../../../assets/imgs/illust_voice_m1.webp'
import illust_m2 from '../../../assets/imgs/illust_voice_m2.webp'
import illust_f0 from '../../../assets/imgs/illust_voice_f0.webp'
import illust_f1 from '../../../assets/imgs/illust_voice_f1.webp'
import illust_f2 from '../../../assets/imgs/illust_voice_f2.png'

export const characters = [
  {
    id: '663343c5b1f85ebd9f4896b9',
    name: '은채',
    image: illust_f0,
    sampleAudio: '/audios/sample2_f0.mp3',
  },
  {
    id: '63622aa127f06f51e93a873c',
    name: '승연',
    image: illust_f1,
    sampleAudio: '/audios/sample2_f1.mp3',
  },
  {
    id: '6050baed630c0d0906e65cc5',
    name: '쇼린',
    image: illust_f2,
    sampleAudio: '/audios/sample_f2.mp3',
  },
  {
    id: '5eb55cf1f0b0a700071f89c7',
    name: '연우',
    image: illust_m0,
    sampleAudio: '/audios/sample2_m0.mp3',
  },
  {
    id: '62df8e2b761262eb5808fbd5',
    name: '정재',
    image: illust_m1,
    sampleAudio: '/audios/sample2_m1.mp3',
  },
  {
    id: '60228e8775ecc9e7ab8ef334',
    name: '이영광',
    image: illust_m2,
    sampleAudio: '/audios/sample2_m2.mp3',
  },
]

export const defaultCharacter = characters[0].id

const SelectCharacter = ({ character, onClickCharacter }) => {
  const [playingId, setPlayingId] = React.useState(false)
  const audio = React.useRef()

  const playSampleAudio = (id, source) => {
    if (audio.current) {
      if (playingId === id) {
        setPlayingId(null)
        audio.current.pause()
      } else {
        setPlayingId(id)
        audio.current.src = source
        audio.current.play()
      }
    }
  }

  return (
    <div className="flex gap-2">
      {characters.map((item) => {
        const selected = item.id === character
        const divClassNames = classNames('flex flex-col flex-center w-[88px] h-[99px] border-[1px] rounded cursor-pointer', {
          'border-main': selected,
          'border-line': !selected,
        })
        return (
          <div key={item.id} className={divClassNames} onClick={() => onClickCharacter(item.id)}>
            <div className="relative">
              <div className="w-[55px] h-[55px] mb-1 rounded-full overflow-hidden">
                <img className="w-full h-full" src={item.image} alt="illust" />
              </div>
              <div className="absolute right-0 bottom-0 w-[19px] h-[19px]" onClick={() => playSampleAudio(item.id, item.sampleAudio)}>
                {playingId === item.id ? <img src={pauseIcon} alt="pause" /> : <img src={playIcon} alt="play" />}
              </div>
              <audio ref={audio} className="hidden" src={item.sampleAudio} />
            </div>
            <span className="text-textSecondary font-medium">{item.name}</span>
          </div>
        )
      })}
    </div>
  )
}

export default SelectCharacter
