import React from 'react'
import { Link } from 'react-router-dom'
//
const Menu = ({
  items, state, onAction, activeLink,
}) => (
  <menu className="menu flex flex-col">
    {items.map((item, index) => (
      <MenuItem
        key={index}
        {...item}
        state={state}
        onAction={onAction}
        activeLink={activeLink}
      />
    ))}
  </menu>
)

export default Menu

const MenuItem = ({
  id,
  label,
  type,
  href,
  state,
  children,
  onAction,
  activeLink,
  activePathname = [],
}) => {
  const onItemAction = async (e) => {
    if (onAction) onAction(e, id)
  }
  //
  switch (type) {
      case 'space':
        return <div className="space" />
      case 'header':
        return <span className="header">{label}</span>
      case 'action':
        return (
          <button
            className={`action${activeLink === id ? ' active' : ''}`}
            onClick={onItemAction}
          >
            {label}
          </button>
        )
      case 'link':
        return (
          <Link
            className={
              `link${
                activeLink === href || activePathname.includes(activeLink)
                  ? ' active'
                  : ''}`
            }
            to={{
              pathname: href,
              state,
            }}
          >
            {label}
          </Link>
        )
      case 'group':
        return <span className="group">{label}</span>
      case 'accordion':
        return (
          <div className="parent">
            <span className="parent-header">{label}</span>
            <div className="parent-content">
              <Menu
                items={children}
                onAction={onAction}
                activeLink={activeLink}
              />
            </div>
          </div>
        )
      default:
        return <div />
  }
}
