import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { addDays } from 'date-fns'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import Select from 'react-select'

import AppContext from '../../../services/context'
import Formtextbox from '../../../components/formTextbox'
import Editor from '../../../components/editor'
import InputValidation from '../../../components/inputValidation'
import LabeledSection from '../../../components/labeledSection'
import { FormDateRangeBox } from '../../../components/dateRangeBox'
import deleteIcon from '../../../assets/icons/icon-x.svg'
import {
  deleteFranchiseNotice,
  editFranchiseNotice,
  getFranchiseNotices,
  uploadFranchiseNoticeFile,
  writeFranchiseNotice,
} from '../../../services/api/notice'
import { FRANCHISE_NOTICE_TYPE_OPTIONS } from '../../../services/constants'
//
const NoticeWriteForm = ({ notice }) => {
  const { appDispatch } = React.useContext(AppContext)
  const history = useHistory()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const [cookies] = useCookies(['jwt_token'])
  const fileInputRef = React.useRef()
  //
  const methods = useForm({
    criteriaMode: 'all',
    mode: 'onSubmit',
    defaultValues: !notice
      ? {
        title: '',
        type: FRANCHISE_NOTICE_TYPE_OPTIONS[0].value,
        period: [addDays(new Date(), 3), addDays(new Date(), 33)],
        videoCost: '',
        headOfficeBudget: '',
        franchiseBudget: '',
        attachedFiles: [],
      }
      : {
        title: notice.title,
        type: notice.type,
        period: [notice.startAt, notice.endAt],
        videoCost: notice.videoCost,
        headOfficeBudget: notice.headOfficeBudget,
        franchiseBudget: notice.franchiseBudget,
        attachedFiles: notice.attachedFiles,
      },
  })
  const { setValue } = methods
  const [contents, files, type] = methods.watch(['contents', 'attachedFiles', 'type'])
  const typeOption = FRANCHISE_NOTICE_TYPE_OPTIONS.find((item) => item.value === type)
  const isNormal = type === 'NORMAL'
  //
  const onCancelClick = async (e) => {
    e.preventDefault()
    history.goBack()
  }
  const onClickDelete = async () => {
    if (window.confirm('정말 삭제하시겠습니까?') === false) return
    await deleteFranchiseNotice(cookies.jwt_token, notice._id)
    const notices = await getFranchiseNotices(cookies.jwt_token)
    await appDispatch({ type: 'updateNoticeDataList', payload: notices })
    history.replace('/franchise/notice')
    enqueueSnackbar('삭제되었습니다', { variant: 'success' })
  }
  //
  const onSubmit = async (data) => {
    const noticeData = { ...data }
    const [startAt, endAt] = noticeData.period
    noticeData.startAt = startAt
    noticeData.endAt = endAt
    delete noticeData.period
    let result = null
    if (!notice) {
      result = await writeFranchiseNotice(cookies.jwt_token, noticeData)
    } else {
      result = await editFranchiseNotice(cookies.jwt_token, noticeData, notice._id)
    }
    if (!result) return
    if (!notice) {
      enqueueSnackbar('등록되었습니다', { variant: 'success' })
    } else {
      enqueueSnackbar('수정되었습니다', { variant: 'success' })
    }
    const notices = await getFranchiseNotices(cookies.jwt_token)
    await appDispatch({ type: 'updateNoticeDataList', payload: notices })
    history.replace('/franchise/notice')
  }
  //
  const onReady = async (editor) => {
    if (location.state?.contents) {
      await editor.setData(location.state.contents)
    }
  }
  const onChange = async (event, editor) => {
    const data = await editor.getData()
    await setValue('contents', data, {
      shouldValidate: true,
    })
  }
  const onChangeFile = async (e) => {
    const file = e.target.files[0]
    const uploadState = [
      ...files,
      {
        fileName: file.name,
        fileUrl: '',
        isUploading: true,
      },
    ]
    await setValue('attachedFiles', uploadState, { shouldValidate: true })
    const result = await uploadFranchiseNoticeFile(cookies.jwt_token, file)
    if (!result) return
    const attachedFiles = {
      fileName: file.name,
      fileUrl: result,
    }
    uploadState[uploadState.length - 1] = attachedFiles
    await setValue('attachedFiles', uploadState, { shouldValidate: true })
  }
  //
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="notices franchise-card franchise-notice !py-8 my-8 mlg:!border-0 mlg:!p-4 mlg:!my-0 mlg:!rounded-none">
          <LabeledSection label="공지사항 작성하기" hasDivider>
            <div className="flex flex-col">
              <div className="flex flex-col items-start gap-1 mb-4">
                <h3 className="text-base mb-2">공지 유형</h3>
                <Select
                  name="type"
                  value={typeOption}
                  className="min-w-[200px]"
                  onChange={(data) => setValue('type', data.value, { shouldValidate: true, shouldDirty: true })}
                  options={FRANCHISE_NOTICE_TYPE_OPTIONS}
                  isDisabled={!!notice}
                />
              </div>
              <div>
                <div className="flex flex-col gap-1 mb-4">
                  <h3 className="text-base mb-2">제목</h3>
                  <Formtextbox
                    className="larger border"
                    name="title"
                    autoComplete="off"
                    placeholder="제목을 입력해주세요"
                    validators={{
                      required: true,
                    }}
                    validations={['required']}
                  />
                </div>
                {isNormal ? null : (
                  <div className="flex flex-col gap-1 mb-4">
                    <h3 className="text-base mb-2">광고기간</h3>
                    <FormDateRangeBox name="period" />
                  </div>
                )}
                {/* <div className="flex flex-col gap-1 mb-4">
                  <h3 className="text-base mb-2">영상 제작비</h3>
                  <Formtextbox
                    className="larger border"
                    name="videoCost"
                    autoComplete="off"
                    placeholder="영상 제작비를 입력해주세요"
                  />
                </div> */}
              </div>
              {isNormal ? null : (
                <>
                  <h3 className="text-base mb-2">예산 (단위: 원)</h3>
                  <div className="flex gap-4">
                    <div className="flex-1 flex flex-col gap-1 mb-4">
                      <h3 className="text-base mb-2">본사 부담</h3>
                      <Formtextbox
                        className="larger border"
                        name="headOfficeBudget"
                        type="number"
                        autoComplete="off"
                        placeholder="예시) 1000000"
                        validators={{
                          required: true,
                        }}
                        validations={['required']}
                      />
                    </div>
                    <div className="flex-1 flex flex-col gap-1 mb-4">
                      <h3 className="text-base mb-2">매장 부담</h3>
                      <Formtextbox
                        className="larger border"
                        name="franchiseBudget"
                        type="number"
                        autoComplete="off"
                        placeholder="예시) 1000000"
                        validators={{
                          required: true,
                        }}
                        validations={['required']}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="editor-section mlg:!px-0">
                <Editor data={contents} onChange={onChange} onReady={onReady} />
              </div>
              <div className="flex flex-col mb-4">
                <input ref={fileInputRef} className="hidden" type="file" onChange={onChangeFile} />
                <button
                  className="self-start brand large mb-4"
                  onClick={(e) => {
                    e.preventDefault()
                    fileInputRef.current.click()
                  }}
                >
                  첨부하기
                </button>
                {!files || files.length === 0 ? (
                  <span>파일이 없습니다</span>
                ) : (
                  <div className="flex flex-col gap-2">
                    {files.map((file, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between p-3 space-x-1 border !border-brand rounded-[6px]"
                      >
                        <span className="text-brand">{file.fileName}</span>
                        {file.isUploading ? (
                          <span className="text-brand">업로드 중...</span>
                        ) : (
                          <button
                            className="py-0 px-0 mt-[-4px]"
                            onClick={async (e) => {
                              e.preventDefault()
                              const newFiles = files.filter((item, i) => i !== index)
                              await setValue('attachedFiles', newFiles, { shouldValidate: true })
                            }}
                          >
                            <img className="max-w-[30px] mmd:w-[14px]" src={deleteIcon} alt="delete" />
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="validations-section">
                <InputValidation names={['title', 'contents']} validations={['asset_required']} />
              </div>
            </div>
          </LabeledSection>
        </div>
        <div className="action-section flex flex-row justify-center gap-4">
          {!notice ? null : (
            <button
              onClick={onClickDelete}
              className="bg-white border !border-error !text-error large whitespace-nowrap"
            >
              삭제하기
            </button>
          )}
          <button
            type="cancel"
            onClick={onCancelClick}
            className="secondary large whitespace-nowrap !border-brand !text-brand"
          >
            취소하기
          </button>
          <button type="submit" className="brand large whitespace-nowrap">
            {!notice ? '등록하기' : '수정하기'}
          </button>
        </div>
      </form>
    </FormProvider>
  )
}

export default NoticeWriteForm
