import React, { useEffect } from 'react'
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs'
import { Container } from 'react-bootstrap'
import { useForm, FormProvider, useWatch } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useSnackbar } from 'notistack'
import moment from 'moment'
import BasicLayout from '../../layouts/basic'
import AppContext from '../../services/context'
import MapSelector from '../media/components/mapSelector'
import PeriodSelector from '../media/components/periodSelector'
import LengthSelector from './components/lengthSelector'
import useAuthModal from '../../hooks/useAuthModal'

import illust from '../../assets/imgs/estimate-illust.png'
import illust2x from '../../assets/imgs/estimate-illust@2x.png'
import illust3x from '../../assets/imgs/estimate-illust@3x.png'
import aiRobotIcon from '../../assets/icons/icon-ai-robot.svg'
import aiRobotDisabledIcon from '../../assets/icons/icon-ai-robot-disabled.svg'
import RecommendTab from '../media/components/recommendTab'
//
const Estimate = () => {
  const { appState } = React.useContext(AppContext)
  const { open, renderAuthModal } = useAuthModal({ coupon: 'estimate230830' })
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const methods = useForm({
    criteriaMode: 'all',
    mode: 'onSubmit',
    defaultValues: {
      ad_campaign_regions: [],
      ad_campaign_dates: [],
    },
  })

  const observedDiv = React.useRef(null)
  const [selectedTab, setSelectedTab] = React.useState(0)
  const [formVisible, setFormVisible] = React.useState(true)
  const [needLogin, setNeedLogin] = React.useState(false)

  const ad_campaign_regions_value = useWatch({
    name: 'ad_campaign_regions',
    control: methods.control,
  })
  const ad_campaign_dates_value = useWatch({
    name: 'ad_campaign_dates',
    control: methods.control,
  })
  const ad_campaign_length_value = useWatch({
    name: 'ad_campaign_length',
    control: methods.control,
  })

  useEffect(() => {
    const estimatedDate = localStorage.getItem('estimatedDate')
    if (
      !appState.userInfo?._id
      && estimatedDate
      && moment(estimatedDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    ) {
      setNeedLogin(true)
    }
  }, [])

  const recommendCallback = React.useCallback(() => {
    // setSelectedTab(1)
  }, [])

  const onClickTab = async (index) => {
    await setSelectedTab(index)
  }

  const handleRecommendCities = React.useCallback(
    (recommendRequestId, cities) => {
      methods.setValue('ad_campaign_regions', cities)

      setFormVisible(false)
      observedDiv.current.scrollIntoView({ behavior: 'smooth' })
      enqueueSnackbar('추천 지역이 반영되었습니다', { variant: 'success' })
    },
    [methods, appState.recommendForm],
  )

  const onClickSelectAgain = async () => {
    setFormVisible(true)
    observedDiv.current.scrollIntoView({ behavior: 'smooth' })
  }

  const onSubmit = async (data) => {
    if (!appState.userInfo?._id) {
      const estimatedDate = localStorage.getItem('estimatedDate')
      if (estimatedDate && moment(estimatedDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        open()
        return
      }
      localStorage.setItem('estimatedDate', moment().format('YYYY-MM-DD'))
      setNeedLogin(true)
    }

    history.push('/estimate/result', data)
  }

  const disabled = ad_campaign_regions_value.length === 0
    || ad_campaign_dates_value.length === 0
    || ![15, 30].includes(ad_campaign_length_value)

  return (
    <BasicLayout>
      <div className="page-headline" style={{ padding: 0 }}>
        <Container>
          <div className="flex justify-between items-center">
            <div>
              <h1>내게 맞는 광고비 알아보기</h1>
              <div className="mt-10 text-2xl">
                <span>원하는 동네, 기간, 영상길이만 선택하고 바로 견적을 받아보세요.</span>
                <br />
                <span>TV광고도 이제 저렴하고 빠르게!</span>
              </div>
            </div>
            <img src={illust} srcSet={`${illust2x} 2x, ${illust3x} 3x`} alt="illust" />
          </div>
        </Container>
      </div>
      <div ref={observedDiv} className="page-content estimate relative">
        <Container className="bg-bgColor lg:bg-white pb-0 md:pb-[56px]">
          <FormProvider {...methods}>
            <Tabs
              className="media-tabs tabs-secondary overflow-visible"
              selectedTabClassName="active"
              selectedIndex={selectedTab}
              onSelect={(index) => onClickTab(index)}
            >
              <TabList className="flex tab-list-secondary">
                <Tab className="tab-secondary flex-1 !flex flex-center px-8 gap-2">
                  <img src={selectedTab === 0 ? aiRobotIcon : aiRobotDisabledIcon} alt="robot" />
                  광고지역 추천받기
                </Tab>
                <Tab className="tab-secondary flex-1 px-8 !flex flex-center">광고지역 직접 선택하기</Tab>
              </TabList>
              <TabPanel>
                {formVisible && (
                  <RecommendTab handleRecommendCities={handleRecommendCities} recommendCallback={recommendCallback} />
                )}
                {ad_campaign_regions_value.length > 0 && (
                  <FormProvider {...methods}>
                    <MapSelector editable={false} />
                  </FormProvider>
                )}
                {!formVisible && (
                  <div className="flex flex-row justify-center space-x-4 mb-12 ">
                    <button type="button" className="primary font-bold min-w-[240px]" onClick={onClickSelectAgain}>
                      조건 다시 선택
                    </button>
                  </div>
                )}
              </TabPanel>
              <TabPanel>
                <FormProvider {...methods}>
                  <MapSelector />
                </FormProvider>
              </TabPanel>
            </Tabs>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <PeriodSelector />
              <LengthSelector />
              <div className="flex flex-row ad-buttons-holder w-full justify-center bg-white mlg:mt-[-8px]">
                <button
                  type="submit"
                  className="primary larger xs:h-[48px] mlg:w-full mlg:h-[48px]"
                  disabled={disabled}
                >
                  견적내기
                </button>
              </div>
            </form>
          </FormProvider>
        </Container>
        {!appState.userInfo?._id && needLogin && <div className="absolute top-0 left-0 w-full h-full" onClick={open} />}
      </div>
      {renderAuthModal()}
    </BasicLayout>
  )
}

export default Estimate
