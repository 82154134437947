import React from 'react'
import { useFormContext } from 'react-hook-form'
import InputValidation from './inputValidation'
//
const FormActionTextbox = ({
  name,
  validators,
  children,
  actionName,
  actionValidators,
  onClick,
  onAction,
  handleBlur,
  enableButton = false,
  ...props
}) => {
  const hiddenFieldName = `x_${name}_${actionName}`
  const [state, setState] = React.useState({
    disabled: !enableButton,
    validated: !validators?.required,
    value: false,
  })
  const methods = useFormContext()
  React.useEffect(() => {
    if (methods.getValues('clientBusinessId')) {
      setState({
        disabled: false,
        validated: true,
        value: methods.getValues('clientBusinessId'),
      })
    } else if (methods.getValues(name)) {
      setState({
        disabled: false,
        validated: true,
        value: true,
      })
    }
  }, [])
  //
  const { onChange, ...inputProps } = methods.register(name, validators)
  const { ...hiddenInputProps } = methods.register(hiddenFieldName, {
    validate: {
      [actionName]: () => state.validated,
      ...actionValidators,
    },
  })
  const inputClasses = ['flex-grow', 'textinput-with-button']
  if (props.className) inputClasses.push(props.className)
  //
  const onInputChange = async (e) => {
    await onChange(e)
    await setState({
      value: e.target.value,
      disabled: !enableButton && e.target.value === '',
      validated: !validators?.required,
    })
    await methods.setValue(hiddenFieldName, '', { shouldValidate: true })
  }
  //
  const onBlur = () => {
    if (handleBlur) {
      handleBlur()
      methods.trigger(name, { shouldValidate: true })
    }
  }
  //
  const onButtonClick = async (e) => {
    e.preventDefault()
    if (!onClick) return
    if (onBlur) onBlur()
    if (enableButton) {
      onClick()
      return
    }
    methods.trigger(name)
    // if (!(await methods.trigger(name))) return
    const result = await onClick(e, state.value)
    console.log({ result })
    if (result === null) return
    if (onAction) onAction()
    if (result === false) {
      await setState({ ...state, validated: !!result })
      await methods.setValue(hiddenFieldName, result, { shouldValidate: true })
      return
    }
    await setState({ ...state, validated: !!result, disabled: !enableButton })
    await methods.setValue(hiddenFieldName, result, { shouldValidate: true })
  }
  //
  return (
    <div className="textbox-action flex flex-row">
      <input {...props} {...inputProps} onChange={onInputChange} onBlur={onBlur} className={inputClasses.join(' ')} />
      <input type="hidden" {...hiddenInputProps} />
      <button className="primary large" onClick={onButtonClick} disabled={state.disabled}>
        {children}
      </button>
    </div>
  )
}

export const FormActionTextboxGroup = ({
  validators,
  validations,
  name,
  label,
  labelClass,
  noLabel = false,
  actionName,
  actionValidators,
  groupName,
  children,
  onClick,
  onBlur,
  ...props
}) => {
  const [validatedNames, setValidatedNames] = React.useState([])
  const labelClasses = []
  if (validators?.required) labelClasses.push('required')
  if (labelClass) labelClasses.push(labelClass)
  //
  const handleBlur = React.useCallback(() => {
    if (!validatedNames.includes(name)) {
      setValidatedNames([...validatedNames, name])
    }
    if (onBlur) onBlur()
  }, [name, validatedNames, onBlur])
  //
  const onAction = React.useCallback(() => {
    const tempActionName = `x_${name}_${actionName}`
    if (!validatedNames.includes(tempActionName)) {
      setValidatedNames([...validatedNames, tempActionName])
    }
  }, [name, actionName, validatedNames])

  const names = [name]
  if (validations && validations.includes('required')) {
    names.push(`x_${name}_${actionName}`)
  }

  return (
    <>
      {!noLabel && (
        <label htmlFor={name} className={labelClasses.join(' ')}>
          {label}
        </label>
      )}
      <FormActionTextbox
        className="larger"
        name={name}
        validators={validators}
        actionName={actionName}
        actionValidators={actionValidators}
        onClick={onClick}
        handleBlur={['user_id', 'agency_id'].includes(name) && handleBlur}
        onAction={onAction}
        {...props}
      >
        {children}
      </FormActionTextbox>
      <InputValidation groupName={groupName} names={names} validations={validations} validatedNames={validatedNames} />
    </>
  )
}

export default FormActionTextbox
