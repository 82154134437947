import React from 'react'
import moment from 'moment'
import { useCookies } from 'react-cookie'
import { useFormContext } from 'react-hook-form'

import { getCoupons } from '../../../services/api'
import { transNumToKoreanMan } from '../../../services/utils'

const defaultSelect = {
  _id: 'none',
  couponPolicy: {
    title: '선택하세요',
  },
}

const SelectCoupons = ({ name, budget }) => {
  const [coupons, setCoupons] = React.useState([])
  const [cookies] = useCookies(['jwt_token'])
  const methods = useFormContext()
  //
  React.useEffect(() => {
    (async () => {
      const result = await getCoupons(cookies.jwt_token)
      if (!result) return
      result.unshift(defaultSelect)
      await setCoupons(result)
    })()
  }, [cookies.jwt_token])
  //
  const onChange = async (e) => {
    const couponId = e.target.value
    if (couponId === 'none') {
      methods.setValue(name, 0, {
        shouldValidate: true,
      })
      return
    }
    const data = coupons.find((item) => item._id === couponId)
    if (data?.couponPolicy) {
      const coupon = data.couponPolicy
      if (coupon.discountPrice) {
        const discountValue = coupon.discountPrice
        // if (discountValue > budget) discountValue = budget
        methods.setValue(name, discountValue, {
          shouldValidate: true,
        })
      } else if (coupon.discountPcr) {
        const discount = (budget * (100 - coupon.discountPcr)) / 100
        methods.setValue(name, discount, {
          shouldValidate: true,
        })
      }
      methods.setValue('ad_campaign_coupon', couponId)
    }
  }
  const today = moment()
  const usableCoupons = coupons.filter((item) => !item.used && today.isSameOrBefore(moment(item.expireDate)))
  //
  return (
    <div className="w-full select">
      <select onChange={onChange} className="w-full" style={{ backgroundColor: 'white' }}>
        {usableCoupons.map((item) => {
          if (!item.couponPolicy) return null
          let { title } = item.couponPolicy
          let disabled = false
          if (item.couponPolicy.availablePrice) {
            title = `${title}(${transNumToKoreanMan(item.couponPolicy.availablePrice)}원이상)`
            if (budget < item.couponPolicy.availablePrice) {
              disabled = true
              title += ' - 최소 금액 미달'
            }
          }
          return (
            <option key={item._id} value={item._id} disabled={disabled}>
              {title}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default SelectCoupons
