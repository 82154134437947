import React from 'react'
import { useFormContext } from 'react-hook-form'
import cx from 'classnames'

const CheckboxGroup = ({
  className, direction, name, options, exclusive,
}) => {
  const { watch, setValue } = useFormContext()
  const selectedOptions = watch(name)

  const handleCheckboxChange = (e) => {
    const { value } = e.target
    const isChecked = e.target.checked

    if (exclusive === value) {
      if (isChecked) {
        options.forEach((option, i) => {
          if (option.value === value) return
          const _name = `${name}_${i}`
          document.getElementById(_name).checked = false
        })
        setValue(name, [value])
      } else {
        setValue(name, [])
      }
      return
    }

    const newArr = isChecked
      ? [...selectedOptions, value].filter((v) => v !== exclusive)
      : selectedOptions.filter((v) => v !== value)
    if (isChecked) {
      const exclusiveIndex = options.findIndex((option) => option.value === exclusive)
      const _name = `${name}_${exclusiveIndex}`
      const exclusiveElement = document.getElementById(_name)
      if (exclusiveElement) exclusiveElement.checked = false
    }

    setValue(name, newArr)
  }

  const wrapperCx = cx(
    'flex flex-wrap',
    {
      'flex-row': direction === 'horizontal',
      'flex-col': direction === 'vertical',
    },
    className,
  )
  return (
    <div className={wrapperCx}>
      {options.map((option, i) => {
        const _name = `${name}_${i}`
        return (
          <div key={option.value} className="flex flex-row items-center mr-4">
            <label className="checkbox" htmlFor={_name}>
              <input
                type="checkbox"
                id={_name}
                name={_name}
                value={option.value}
                onChange={handleCheckboxChange}
                checked={selectedOptions.includes(option.value)}
              />
              <span className="text-base -ml-1.5">{option.label}</span>
              <span className="checkmark" />
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default CheckboxGroup
