import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import Footer from '../../components/footer'
import { getInvoiceInfo } from '../../services/api'
import AppContext from '../../services/context'

const InicisCallback = () => {
  const [cookies] = useCookies(['jwt_token'])
  const history = useHistory()
  const { appState, appDispatch } = React.useContext(AppContext)
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  //
  React.useEffect(() => {
    if (query.get('isMobile') === 'true') {
      const queryPoint = query.get('point')
      if (queryPoint && Number(queryPoint) > 0) {
        const point = appState.userInfo.point || 0
        appDispatch({
          type: 'updateUserInfo',
          payload: point - Number(queryPoint),
        })
      }
      const invoiceId = query.get('invoiceId')
      getInvoiceInfo(cookies.jwt_token, invoiceId).then((result) => {
        appDispatch({ type: 'updateAdCampaign', payload: result.campaign })
        const requested = query.get('requested') === 'true'
        history.push({
          pathname: '/portal/media/create/result',
          state: {
            done: true,
            invoice_id: invoiceId,
            requested,
          },
        })
      })
      return
    }
    window.close()
  }, [])
  //
  return (
    <>
      <Navbar>
        <div className="container">
          <Nav className="me-auto">
            <Nav.Link
              className="go-back"
              onClick={() => history.push('/portal/media')}
            >
              나의광고 로 돌아가기
            </Nav.Link>
          </Nav>
        </div>
      </Navbar>
      <main className="flex flex-grow flex-col">
        <Container className="auth-paper" fluid="sm">
          <div className="flex flex-col justify-items-stretch items-center">
            <h1>결제 진행 중입니다</h1>
            <p>{query.invoiceId}</p>
            <p>{query.point}</p>
            <p>{query.requested}</p>
          </div>
        </Container>
      </main>

      <Footer />
    </>
  )
}

export default InicisCallback
