import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import AppContext from '../../../services/context'
import dotsIcon from '../../../assets/icons/icon-outlined-application-more.svg'
import playIcon from '../../../assets/icons/icon-filled-directional-play-circle.svg'
import pauseIcon from '../../../assets/icons/icon-filled-suggested-pause-circle.svg'
import pendingIcon from '../../../assets/icons/icon-filled-suggested-clock-circle.svg'
import successIcon from '../../../assets/icons/icon-filled-suggested-check-circle.svg'
import warningIcon from '../../../assets/icons/icon-filled-suggested-warning-circle.svg'
import infoIcon from '../../../assets/icons/icon-filled-suggested-exclamation-circle-26-px.svg'
import ToggleMenu, { menuActions } from '../../../components/toggleMenu'
import { formatDate } from '../../../services/utils'
import { copyCampaign, deleteCampaign, patchCampaign } from '../../../services/api'
// import calendarIcon from '../../../assets/icons/icon-outlined-application-calendar.svg'
import writeIcon from '../../../assets/icons/icon-outlined-editor-form.svg'
import statsIcon from '../../../assets/icons/icon-outlined-data-bar-chart.svg'
import uploadIcon from '../../../assets/icons/icon-outlined-application-to-top.svg'
import noteIcon from '../../../assets/icons/icon-outlined-suggested-exclamation-circle-xlarge.svg'
import MessageModal from '../../../components/modals'
//
import copyIcon from '../../../assets/icons/icon-outlined-editor-copy-medium.svg'
import editIcon1 from '../../../assets/icons/icon-outlined-editor-edit-medium.svg'
import editIcon2 from '../../../assets/icons/icon-outlined-editor-edit-medium-disable.svg'

import deleteIcon1 from '../../../assets/icons/icon-outlined-editor-delete-medium.svg'
import deleteIcon2 from '../../../assets/icons/icon-outlined-editor-delete-medium-disable.svg'
//

const menu = [
  {
    label: '이름변경',
    action: menuActions.CHANGE_NAME,
    disabled: false,
    enabledIcon: editIcon1,
    disabledIcon: editIcon2,
  },
  // {
  //   label: "상세보기",
  //   action: menuActions.DETAIL,
  //   disabled: false,
  //   enabledIcon: detailsIcon,
  //   disabledIcon: detailsIcon
  // },
  {
    label: '복사하기',
    action: menuActions.COPY,
    disabled: false,
    enabledIcon: copyIcon,
    disabledIcon: copyIcon,
  },
  {
    label: '삭제하기',
    action: menuActions.DELETE,
    disabled: false,
    enabledIcon: deleteIcon1,
    disabledIcon: deleteIcon2,
  },
]
//
const CardComponent = ({
  data, title, icon, actions, trigger, onClickChangeName, selectedMenu, onClickMenu,
}, ref) => {
  const [cookies] = useCookies(['jwt_token'])
  //
  const Actions = actions
  const onMenuClick = async (e, menuItem) => {
    if (menuItem.action === menuActions.CHANGE_NAME) {
      onClickChangeName(data._id, data.campaignName)
    } else if (menuItem.action === menuActions.COPY) {
      await copyCampaign(cookies.jwt_token, data._id)
      trigger(true)
    } else if (menuItem.action === menuActions.DELETE) {
      await deleteCampaign(cookies.jwt_token, data._id)
      trigger(true)
    }
  }
  //
  let shownMenu = menu
  if (!['INFO', 'ASSET', 'PAYMENT'].includes(data.subStatus)) {
    shownMenu = shownMenu.slice(1)
  }
  //
  return (
    <div ref={ref} className="ad-campaign-card relative flex flex-col flex-grow h-full">
      <div className="flex flex-none flex-row status">
        <img src={icon} alt="status" />
        <span className="flex-grow-1 status-title">{title}</span>
      </div>
      {/**/}
      <video
        className="media"
        muted
        controls
        controlsList="nodownload"
        onContextMenu={(e) => e.preventDefault()}
        alt="media"
      >
        <source src={data.asset?.vidoeUrl} />
      </video>
      {/**/}
      <div className="flex flex-row media-title ">
        <span className="flex-grow title">{data.campaignName}</span>
        {onClickMenu && (
          <ToggleMenu
            menu={shownMenu}
            onClick={onMenuClick}
            isSelected={selectedMenu === data._id}
            onClickMenu={() => onClickMenu(data._id)}
          >
            <img src={dotsIcon} alt="dots" />
          </ToggleMenu>
        )}
      </div>
      {/**/}
      <Content data={data} />
      {/**/}
      <Actions data={data} />
    </div>
  )
}

const AdCampaignCardComponent = React.forwardRef(CardComponent)

const Content = ({ data }) => {
  switch (data.subStatus) {
      case 'INSPECTION':
      case 'REJECT':
      case 'SUCCESS':
        return (
          <div className="flex flex-row dates">
            <div className="flex flex-col">
              <span>심사 신청일</span>
              <span>최초 생성일</span>
            </div>
            <div className="flex flex-col">
              <span>{formatDate(data.inspectedAt)}</span>
              <span>{formatDate(data.createdAt)}</span>
            </div>
          </div>
        )
      case 'BROADCAST':
      case 'END':
        return (
          <div className="flex flex-row dates">
            <div className="flex flex-col">
              <span>광고 기간</span>
              <span>최초 생성일</span>
            </div>
            <div className="flex flex-col">
              <span>{`${formatDate(data.adStartAt)} ~ ${formatDate(data.adEndAt)}`}</span>
              <span>{formatDate(data.createdAt)}</span>
            </div>
          </div>
        )
      case 'INFO':
      case 'ASSET':
      case 'PAYMENT':
      default:
        return (
          <div className="flex flex-row dates">
            <div className="flex flex-col">
              <span>마지막 수정일</span>
              <span>최초 생성일</span>
            </div>
            <div className="flex flex-col">
              <span>{formatDate(data.updatedAt)}</span>
              <span>{formatDate(data.createdAt)}</span>
            </div>
          </div>
        )
  }
}
//
const InfoAssetPaymentActions = ({ data }) => {
  const { appDispatch } = React.useContext(AppContext)
  const history = useHistory()
  const onActionClick = async () => {
    await appDispatch({ type: 'updateAdCampaign', payload: data })
    await appDispatch({ type: 'updateRecommendForm', payload: null })
    switch (data.subStatus) {
        case 'ASSET':
          history.push('/portal/media/create/2')
          break
        case 'PAYMENT':
          history.push('/portal/media/create/3')
          break
        case 'INFO':
        default:
          history.push('/portal/media/create/1')
          break
    }
  }
  return (
    <div className="actions">
      {data.subStatus === 'PAYMENT' && data.invoice?.method === 'bankbook' ? (
        <button className="flex items-center justify-center large w-full" disabled>
          무통장 입금 확인중입니다
        </button>
      ) : (
        <button onClick={onActionClick} className="flex items-center justify-center large w-full secondary left-icon">
          <img src={writeIcon} alt="icon" />
          광고 완성하기
        </button>
      )}
    </div>
  )
}
//
const InspectionActions = () => (
  <div className="actions">
    <span>심사는 최대 3일 소요됩니다.</span>
  </div>
)
//
const RejectedActions = ({ data }) => {
  const { appDispatch } = React.useContext(AppContext)
  const history = useHistory()
  const [isModalShown, setIsModalShown] = React.useState(false)
  const onActionClick = async () => {
    await appDispatch({ type: 'updateAdCampaign', payload: data })
    history.push('/portal/media/create/1')
  }
  const onModalClose = () => setIsModalShown(false)
  //
  return (
    <div className="actions grid grid-cols-2 gap-3">
      <button
        onClick={() => setIsModalShown(true)}
        className="flex items-center justify-center w-full large secondary left-icon"
      >
        <img src={noteIcon} alt="icon" style={{ width: 25 }} />
        반려 이유보기
      </button>
      <button onClick={onActionClick} className="flex items-center justify-center w-full large secondary left-icon">
        <img src={writeIcon} alt="icon" />
        광고 수정하기
      </button>
      <MessageModal type="warning" isShown={isModalShown} onClose={onModalClose}>
        <h3 className="text-center">반려 사유</h3>
        <p className="text-center">
          <span>{data.comment}</span>
        </p>
      </MessageModal>
    </div>
  )
}
//
const SuccessActions = () => (
  <div className="actions">
    <button className="w-full large left-icon" disabled>
      다시 집행하기
    </button>
  </div>
)
//
const BroadcastActions = ({ data }) => (
  <div className="actions grid grid-cols-2 gap-3">
    <Link
      to={{
        pathname: '/portal/report',
        state: data,
      }}
      className="button large w-full secondary left-icon"
    >
      <img src={statsIcon} alt="icon" />
      광고 효과보기
    </Link>
    <button
      // onClick={onActionClick}
      className="w-full large secondary left-icon"
      disabled
    >
      {/* <img src={calendarIcon} alt="icon" /> */}
      기간 연장하기
    </button>
  </div>
)
//
const EndActions = ({ data }) => {
  const { appDispatch } = React.useContext(AppContext)
  const history = useHistory()
  const [cookies] = useCookies(['jwt_token'])

  const onActionClick = async () => {
    const copied = await copyCampaign(cookies.jwt_token, data._id)
    console.log(copied)
    const result = await patchCampaign(cookies.jwt_token, copied._id, {
      adStartAt: null,
      adEndAt: null,
    })
    console.log(result)
    await appDispatch({ type: 'updateAdCampaign', payload: result })
    history.push('/portal/media/create/1')
  }
  return (
    <div className="actions grid grid-cols-2 gap-3">
      <Link
        to={{
          pathname: '/portal/report',
          state: data,
        }}
        className="button large w-full secondary left-icon"
      >
        <img src={statsIcon} alt="icon" />
        광고 효과보기
      </Link>
      <button onClick={onActionClick} className="w-full large secondary left-icon">
        <img src={uploadIcon} alt="icon" />
        다시 집행하기
      </button>
    </div>
  )
}
//
const AdCampaignCard = ({
  data, fetchCampaigns, onClickChangeName, selectedMenu, onClickMenu, trigger,
}, ref) => {
  switch (data.subStatus) {
      case 'INFO':
        return (
          <AdCampaignCardComponent
            ref={ref}
            data={data}
            trigger={trigger}
            title="청약 정보가 필요합니다"
            icon={infoIcon}
            fetchCampaigns={fetchCampaigns}
            onClickChangeName={onClickChangeName}
            actions={InfoAssetPaymentActions}
            selectedMenu={selectedMenu}
            onClickMenu={onClickMenu}
          />
        )
      case 'ASSET':
        return (
          <AdCampaignCardComponent
            ref={ref}
            data={data}
            trigger={trigger}
            title="소재 정보가 필요합니다"
            icon={infoIcon}
            fetchCampaigns={fetchCampaigns}
            onClickChangeName={onClickChangeName}
            actions={InfoAssetPaymentActions}
            selectedMenu={selectedMenu}
            onClickMenu={onClickMenu}
          />
        )
      case 'PAYMENT':
        return (
          <AdCampaignCardComponent
            ref={ref}
            data={data}
            trigger={trigger}
            title="결제가 필요합니다"
            icon={infoIcon}
            fetchCampaigns={fetchCampaigns}
            onClickChangeName={onClickChangeName}
            actions={InfoAssetPaymentActions}
            selectedMenu={selectedMenu}
            onClickMenu={onClickMenu}
          />
        )
      case 'INSPECTION':
        return (
          <AdCampaignCardComponent
            ref={ref}
            data={data}
            trigger={trigger}
            title="신청한 광고를 심사하고 있습니다"
            icon={pendingIcon}
            fetchCampaigns={fetchCampaigns}
            onClickChangeName={onClickChangeName}
            actions={InspectionActions}
            selectedMenu={selectedMenu}
            onClickMenu={onClickMenu}
          />
        )
      case 'REJECT':
        return (
          <AdCampaignCardComponent
            ref={ref}
            data={data}
            trigger={trigger}
            title="광고가 승인되지 않았습니다"
            icon={warningIcon}
            fetchCampaigns={fetchCampaigns}
            onClickChangeName={onClickChangeName}
            actions={RejectedActions}
            selectedMenu={selectedMenu}
            onClickMenu={onClickMenu}
          />
        )
      case 'SUCCESS':
        return (
          <AdCampaignCardComponent
            ref={ref}
            data={data}
            trigger={trigger}
            title="광고 승인되었습니다"
            icon={successIcon}
            fetchCampaigns={fetchCampaigns}
            onClickChangeName={onClickChangeName}
            actions={SuccessActions}
            selectedMenu={selectedMenu}
            onClickMenu={onClickMenu}
          />
        )
      case 'BROADCAST':
        return (
          <AdCampaignCardComponent
            ref={ref}
            data={data}
            trigger={trigger}
            title="광고가 집행 중입니다"
            icon={playIcon}
            fetchCampaigns={fetchCampaigns}
            onClickChangeName={onClickChangeName}
            actions={BroadcastActions}
            selectedMenu={selectedMenu}
            onClickMenu={onClickMenu}
          />
        )
      case 'END':
        return (
          <AdCampaignCardComponent
            ref={ref}
            data={data}
            trigger={trigger}
            title="광고 집행이 종료되었습니다"
            icon={pauseIcon}
            fetchCampaigns={fetchCampaigns}
            onClickChangeName={onClickChangeName}
            actions={EndActions}
            selectedMenu={selectedMenu}
            onClickMenu={onClickMenu}
          />
        )
      default:
        return <div />
  }
}

export default React.forwardRef(AdCampaignCard)
