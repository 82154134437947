import React from 'react'
import { Modal } from 'react-bootstrap'
import emptyMedia from '../../../assets/icons/empty-media.svg'

const DesignPaper = ({ data, onSelect }) => {
  const mediaSrc = data?.temThumbnail || emptyMedia
  //
  return (
    <button
      className="design-paper flex flex-col"
      onClick={(e) => onSelect(e, data)}
    >
      <div className="relative">
        <img src={mediaSrc} alt="media" />
        <span className="time">{`00:${data.videoDuration}`}</span>
      </div>
      <span className="w-full text-left">{data.temTitle}</span>
    </button>
  )
}

export const DesignModal = ({
  data: tempData, isShown, onClose, onPostTemplate,
}) => {
  const data = tempData || {}
  return (
    <Modal
      show={isShown}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
    >
      <Modal.Header closeButton className="justify-items-stretch items-center">
        <Modal.Title>템플릿 정보</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-full flex flex-col design-paper-modal">
          <video
            controls
            src={data.temPreviewVideo}
            controlsList="nodownload"
            onContextMenu={(e) => e.preventDefault()}
            poster={data.temThumbnail}
          />
          <p className="secs-title">{data.temTitle}</p>
          <div className="secs">
            <div className="gray-box flex flex-col">
              <span>템플릿 길이</span>
              <span>{`${data.videoDuration}초`}</span>
            </div>
            <div className="gray-box flex flex-col">
              <span>필요한 사진</span>
              <span>{`${data.needImage}개`}</span>
            </div>
            <div className="gray-box flex flex-col">
              <span>필요한 영상</span>
              <span>{`${data.needVideo}개`}</span>
            </div>
          </div>
          <button
            className="primary larger w-full"
            onClick={(e) => onPostTemplate(e, data)}
            style={{ marginBottom: 0 }}
          >
            소재 만들기
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DesignPaper
