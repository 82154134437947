import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import { useHistory, useLocation, Redirect } from 'react-router-dom'
import classNames from 'classnames'
import Footer from '../../components/footer'
import congrats from '../../assets/imgs/10137.svg'
import AppContext from '../../services/context'

const PaymentResult = () => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const history = useHistory()
  const location = useLocation()
  const isRequested = location.state?.requested
  //
  React.useEffect(() => () => {
    appDispatch({ type: 'resetAdCampaign' })
  }, [appDispatch])
  //
  if (!location.state?.done) return <Redirect to="/" />

  const onClickPromotion = () => {
    const { galleryId } = appState.adCampaign.step3
    history.push({
      pathname: '/portal/media/promotion',
      state: {
        galleryId,
      },
    })
  }
  //
  return (
    <>
      <Navbar>
        <div className="container">
          <Nav className="me-auto">
            <Nav.Link
              className="go-back"
              onClick={() => history.push('/portal/media')}
            >
              나의광고 로 돌아가기
            </Nav.Link>
          </Nav>
        </div>
      </Navbar>
      <main className="flex flex-grow flex-col">
        <Container className="auth-paper" fluid="sm">
          <div className="flex flex-col justify-items-stretch items-center">
            <img className="hero" src={congrats} alt="" />
            <div className="header text-center">
              <h1>결제 완료</h1>
              {isRequested ? (
                <p className="mb-14">
                  제작 비용 결제가 완료 되었습니다.
                  <br />
                  제작 담당자가 펀딩 참여자님께 연락 드리도록 하겠습니다.
                </p>
              ) : (
                <p className="mb-14">
                  <span>
                    고객님의 광고 청약이 완료 되었습니다.
                  </span>
                  <br />
                  <span>청약 완료 후 광고 심의/승인(최대 3일) 후 최종적으로 광고가 송출됩니다.</span>
                  <br />
                  <br />
                  <span>Tip!</span>
                  <br />
                  <span>광고가 송출되기 전 우리가게 소개와 이벤트 정보를 등록해 보세요.</span>
                  <br />
                  <span>광고갤러리 메뉴를 통해 다른 분들에게 우리가게를 홍보해 드립니다!</span>
                </p>
              )}
              <div className={
                classNames('flex flex-col-reverse items-center lg:flex-row gap-4', {
                  'justify-center': isRequested,
                })
              }
              >
                <button
                  className="flex-1 max-w-[264px] w-full secondary large"
                  onClick={() => history.push('/')}
                >
                  메인으로 가기
                </button>
                {!isRequested && (
                  <button
                    className="flex-1 max-w-[264px] w-full primary large"
                    onClick={onClickPromotion}
                  >
                    프로모션 정보 입력
                  </button>
                )}
              </div>
            </div>
          </div>
        </Container>
      </main>

      <Footer />
    </>
  )
}

export default PaymentResult
