import React from 'react'
import { Form, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { useFormContext, useWatch } from 'react-hook-form'

import InfoIcon from '../../../assets/icons/icon-filled-suggested-question-circle.svg'

const SectionWithTitle = ({
  className = '',
  title,
  description,
  required = false,
  isError = false,
  children,
  switchName,
  renderAdditionalView,
}) => {
  const methods = useFormContext()
  const switchRef = React.useRef(null)
  const switchValue = useWatch({ name: switchName, control: methods?.control })

  const onCheckChanged = (e) => {
    methods.setValue(switchName, e.target.checked)
  }
  return (
    <div
      className={`my-10 ${isError ? ' error' : ''} ${className}`}
    >
      <div className="flex mb-3">
        <h3 className="mb-0">{title}</h3>
        {required && <span className="text-main text-lg mt-[-3px]">*</span>}
        {description && (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 250 }}
            trigger={['hover', 'focus', 'click']}
            overlay={(
              <Tooltip>
                {description}
              </Tooltip>
            )}
          >
            <button className="self-center !w-fit !h-fit p-0" type="button">
              <img className="xs:w-[18px] xs:h-[18px] xxs:w-[15px] xxs:h-[15px] xxs:mx-[2px]" src={InfoIcon} alt="info" />
            </button>
          </OverlayTrigger>
        )}
        {switchName && (
          <Form.Check
            ref={switchRef}
            type="switch"
            {...methods.register(switchName)}
            className="ml-4"
            label={switchValue ? 'On' : 'Off'}
            onChange={onCheckChanged}
          />
        )}
        {renderAdditionalView && renderAdditionalView()}
      </div>
      {((switchName && switchValue) || !switchName) && (
        <div>
          {children}
        </div>
      )}
    </div>
  )
}

export default SectionWithTitle
