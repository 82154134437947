import React from 'react'
import moment from 'moment'
import { Table } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import Switch from 'react-switch'
//
import MyPageLayout from '../../layouts/myPageLayout'
import { getThumbnail } from '../../services/utils'
import { getPromotions, patchGalleryVisible } from '../../services/api'
import emptyMedia from '../../assets/icons/empty-media.svg'
//
const dateFormat = 'YYYY.MM.DD'
//
const Promotions = () => {
  const [cookies] = useCookies(['jwt_token'])
  const [data, setData] = React.useState([])
  //
  React.useEffect(() => {
    (async () => {
      const result = await getPromotions(cookies.jwt_token)
      setData(result.result)
      // if (!result) return
      //
    })()
  }, [cookies.jwt_token])
  //
  const onError = (e) => {
    e.target.onerror = null
    e.target.src = emptyMedia
  }
  const handleChange = React.useCallback(
    async (galleryId, checked) => {
      await patchGalleryVisible(cookies.jwt_token, galleryId, checked)
      setData((prev) => {
        const index = prev.findIndex((item) => item.galleryId === galleryId)
        const tempArr = [...prev]
        tempArr[index] = { ...tempArr[index], visible: checked }
        return tempArr
      })
    },
    [cookies.jwt_token],
  )
  //
  return (
    <MyPageLayout navigationTitle="프로모션 정보" isHeaderHiddenInMobile>
      <div className="hidden header lg:block mb-8">
        <h3>프로모션 정보</h3>
      </div>
      <div className="point-page hidden lg:block">
        <Table responsive>
          <thead>
            <tr className="text-center" valign="middle">
              <th>#</th>
              <th>캠페인 이름</th>
              <th>소재 정보</th>
              <th>
                최초
                <br />
                생성일
              </th>
              <th>
                마지막
                <br />
                수정일
              </th>
              <th>결제일</th>
              <th>
                프로모션
                <br />
                정보
              </th>
              <th>노출 여부</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0
              && data.map((item, index) => (
                <tr key={index} valign="middle">
                  <td>{index + 1}</td>
                  <td>{item.campaignName}</td>
                  <td><img src={getThumbnail(item.videoUrl)} alt="thumbnail" onError={onError} /></td>
                  <td>{moment(item.createdAt).format(dateFormat)}</td>
                  <td>{moment(item.updatedAt).format(dateFormat)}</td>
                  <td>{moment(item.paidAt).format(dateFormat)}</td>
                  <td>
                    <Link to={{
                      pathname: '/portal/media/promotion',
                      state: item,
                    }}
                    >
                      <button className="btn large primary min-w-[92px] text-white">{item.galleryId ? '수정하기' : '작성하기'}</button>
                    </Link>
                  </td>
                  <td>
                    {item.galleryId && (
                      <Switch
                        onColor="#ff9900"
                        onChange={(checked) => handleChange(item.galleryId, checked)}
                        checked={item.visible === undefined ? false : item.visible}
                      />
                    )}
                  </td>
                </tr>
              ))}
            {data.length === 0 && (
              <tr className="empty">
                <td colSpan="8">결제된 광고 캠페인 목록이 없습니다</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className="flex flex-col mx-[-25px] md:mx-[-100px] lg:hidden">
        {data.length > 0 && data.map((item) => (
          <div key={item._id} className="flex flex-col px-4 py-2 md:py-4 gap-4 border-b border-line">
            <div className="flex items-center gap-4">
              <div className="w-1/2 flex flex-col gap-1 md:gap-2">
                <h3 className="mb-2 text-textSecondary text-sm font-bold truncate md:text-base">{item.campaignName}</h3>
                <div className="flex justify-between text-xs sm:text-sm md:text-base">
                  <span>최초 생성일 </span>
                  <span>{moment(item.createdAt).format(dateFormat)}</span>
                </div>
                <div className="flex justify-between text-xs sm:text-sm md:text-base">
                  <span>마지막 수정</span>
                  <span>{moment(item.updatedAt).format(dateFormat)}</span>
                </div>
                <div className="flex justify-between text-xs sm:text-sm md:text-base">
                  <span>결제일</span>
                  <span>{moment(item.paidAt).format(dateFormat)}</span>
                </div>
              </div>
              <div className="w-1/2">
                <img className="w-full" src={getThumbnail(item.videoUrl)} alt="thumbnail" onError={onError} />
              </div>
            </div>
            {item.galleryId ? (
              <button className="btn large secondary">수정하기</button>
            ) : (
              <button className="btn large primary text-white">작성하기</button>
            )}
            {item.galleryId && (
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <span>노출여부</span>
                </div>
                <Switch
                  onColor="#ff9900"
                  onChange={(checked) => handleChange(item.galleryId, checked)}
                  checked={item.visible === undefined ? false : item.visible}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </MyPageLayout>
  )
}

export default Promotions
