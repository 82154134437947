import React from 'react'
import Header from '../components/header'
import { useWindowSize } from '../services/utils'
//
const CommunityLayout = ({ children }, forwardedRef) => {
  const [width] = useWindowSize()
  const ref = React.useRef()
  const [state, setState] = React.useState(0)
  //
  React.useEffect(() => {
    setState(ref.current.clientHeight)
  }, [width])
  //
  return (
    <>
      <Header sticky ref={ref} />
      <main
        ref={forwardedRef}
        className="flex flex-grow flex-col overflow-y-hidden max-h-[calc(100vh-106px)]"
        style={{
          marginTop: state,
        }}
      >
        {children}
      </main>
    </>
  )
}

export default React.forwardRef(CommunityLayout)
