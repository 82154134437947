import React from 'react'
import { useFormContext } from 'react-hook-form'
import validate from '../services/validator'
import errIcon from '../assets/icons/icon-outlined-suggested-error-medium.svg'
import sucIcon from '../assets/icons/icon-outlined-suggested-success-medium.svg'

const InputValidation = ({
  groupName,
  names,
  validations = [],
  validatedNames = [],
}) => {
  const { formState } = useFormContext()
  //
  let checkedNames = [...validatedNames]
  if (formState.isSubmitted) checkedNames = [...new Set([...validatedNames, ...names])]
  //
  return (
    <div className="validation">
      {checkedNames.length > 0
        && validate(checkedNames, formState.errors, validations, groupName).map(
          (item, index) => {
            if (index === 0) {
              const input = document.querySelector(
                `input[name=${checkedNames[index]}]`,
              )
              if (input) {
                // const typeProp = input.getAttribute('type')
                // let focusEle = input
                // if (typeProp === 'hidden') {
                //   focusEle = input.parentElement
                // }
                // focusEle?.scrollIntoView({
                //   behavior: 'smooth',
                //   block: 'center',
                //   inline: 'start',
                // })
              }
            }
            return (
              <small key={index} className={item.status ? 'success' : 'error'}>
                <img
                  src={item.status ? sucIcon : errIcon}
                  alt={String(item.status)}
                />
                {item.msg}
              </small>
            )
          },
        )}
    </div>
  )
}

export default InputValidation
