import React from 'react'
import { useWatch, useFormContext } from 'react-hook-form'

import { FormTextboxGroup } from '../../../components/formTextbox'
import { FormDateSelectBox } from '../../../components/dateSelectBox'

const promotionValueDesc = {
  DISCOUNT: '할인율을 입력해주세요',
  BUNDLE_DISCOUNT: '할인 정보를 입력해주세요',
  SERVICE: '추가 증정 내용을 입력해주세요',
  POINT: '적립 포인트를 입력해주세요',
  COUPON: '쿠폰명을 입력해주세요',
  OTHER: '프로모션 내용을 입력해주세요',
}

const SectionWithTitle = ({ title, children, required }) => (
  <div className="mt-6">
    <div className="flex">
      <h4 className="text-[#707070] font-bold">{title}</h4>
      {required && <span className="text-main text-lg mt-[-3px]">*</span>}
    </div>
    <div>{children}</div>
  </div>
)
const PromotionView = () => {
  const { register, control } = useFormContext()
  const promotionType = useWatch({
    name: 'promotionType',
    control,
  })

  return (
    <div className="flex flex-col">
      <SectionWithTitle title="카테고리" required>
        <div className="flex flex-col items-start gap-4 w-full lg:flex-row">
          <div className="select min-w-[200px] lg:min-w-[313px]">
            <select className="w-full" {...register('promotionType')}>
              <option value="DISCOUNT">% 할인</option>
              <option value="BUNDLE_DISCOUNT">묶음 할인</option>
              <option value="SERVICE">추가 증정</option>
              <option value="POINT">포인트 적립</option>
              <option value="COUPON">쿠폰 증정</option>
              <option value="OTHER">기타</option>
            </select>
          </div>
          <div className="w-full">
            <FormTextboxGroup
              name="promotionValue"
              autoComplete="off"
              placeholder={promotionValueDesc[promotionType] || '할인율을 입력해주세요'}
              validators={{
                required: true,
              }}
              validations={['required']}
            />
          </div>
        </div>
      </SectionWithTitle>
      <SectionWithTitle title="진행일" required>
        <div className="flex flex-col md:flex-row gap-4 mb-[16px]">
          <FormDateSelectBox
            name="promotionStartDate"
            placeholder="시작일을 입력해주세요"
            validators={{
              required: true,
            }}
          />
          <FormDateSelectBox name="promotionEndDate" placeholder="종료일을 입력해주세요 (선택)" />
        </div>
      </SectionWithTitle>
      <SectionWithTitle title="프로모션 링크">
        <div className="w-full">
          <FormTextboxGroup
            name="promotionLink"
            autoComplete="off"
            placeholder="링크를 입력해주세요"
            validators={{
              pattern:
                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
            }}
            validations={['pattern']}
          />
        </div>
      </SectionWithTitle>
      <SectionWithTitle title="소개 정보" required>
        <div className="textbox-action mb-[16px]">
          <textarea
            className="flex-grow large border-[1px] border-disabledLine rounded-[4px] text-[16px] font-medium"
            {...register('promotionDesc', { required: true })}
            placeholder="진행 중인 프로모션의 정보를 소개해주세요"
            rows={4}
          />
        </div>
      </SectionWithTitle>
    </div>
  )
}

export default PromotionView
