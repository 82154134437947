import React from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useForm, FormProvider } from 'react-hook-form'
import moment from 'moment'
import Select from 'react-select'

import MyPageLayout from '../../layouts/myPageLayout'
import InfoTextbox from '../../components/infoTextbox'
import AppContext from '../../services/context'
import { FormTextboxGroup } from '../../components/formTextbox'
import { FormActionTextboxGroup } from '../../components/formActionTextbox'
import { FormUploadBoxGroup } from '../../components/formUploadBox'
import { FormCheckbox } from '../../components/checkbox'
import MessageModal, { TwoButtonModal } from '../../components/modals'
import {
  checkBusinessIdValidity,
  uploadBusinessDocument,
  updateUserInfo,
  uploadSmallBusinessCert,
  leaveService,
} from '../../services/api'
import { getMarketingNotiText } from '../../services/utils'
import { FRANCHISE_REGION_OPTIONS } from '../../services/constants'
//
const MyPage = () => {
  const history = useHistory()
  const { appState, appDispatch } = React.useContext(AppContext)
  const [cookies, , removeCookie] = useCookies(['jwt_token'])
  const { enqueueSnackbar } = useSnackbar()
  const [modal, setModal] = React.useState({ type: 'success', visible: false, msg: '' })
  const [leaveModalVisible, setLeaveModalVisible] = React.useState(false)
  const isAgency = appState.userInfo.userType === 1
  //
  const methods = useForm({
    criteriaMode: 'all',
    mode: 'onSubmit',
    defaultValues: {
      business_name: appState.userInfo?.company?.companyName,
      business_id: appState.userInfo?.company?.companyNumber,
      email: appState.userInfo?.userEmail,
      x_business_id_validated: true,
      marketing_subscribe: appState.userInfo?.agreement?.marketing_subscribe,
      marketing_privacy: appState.userInfo?.agreement?.marketing_privacy,
      corporate_name: appState.userInfo?.company?.corporateName,
      team_name: appState.userInfo?.company?.teamName,
      region: appState.userInfo?.company?.region,
      tax_manager_name: appState.userInfo?.company?.taxManagerName,
      tax_manager_tel: appState.userInfo?.company?.taxManagerTel,
      tax_manager_email: appState.userInfo?.company?.taxManagerEmail,
    },
  })

  const regionValue = methods.watch('region')

  //
  const onSubmit = React.useCallback(
    async (_data) => {
      const currentValue = {
        business_name: appState.userInfo?.company?.companyName,
        business_id: appState.userInfo?.company?.companyNumber,
        email: appState.userInfo?.userEmail,
        marketing_subscribe: appState.userInfo?.agreement?.marketing_subscribe,
        marketing_privacy: appState.userInfo?.agreement?.marketing_privacy,
      }
      const data = { ..._data }
      Object.keys(data).forEach((key) => {
        if (
          (key !== 'x_business_document_url' && key !== 'x_cert_url' && key.includes('x_business'))
          || data[key] === currentValue[key]
        ) delete data[key]
      })
      if (
        Object.keys(data).length === 0
        || (Object.keys(data).length === 1 && data.x_business_document_url === '')
        || (Object.keys(data).length === 1 && data.x_cert_url === '')
      ) {
        enqueueSnackbar('변경한 정보가 없습니다.')
        return
      }
      const changedUserInfo = {}
      if (data.business_name) changedUserInfo.companyName = data.business_name
      if (data.business_id) changedUserInfo.companyNumber = data.business_id
      if (data.x_business_document_url) changedUserInfo.documentUrl = data.x_business_document_url
      if (data.x_cert_url) changedUserInfo.certUrl = data.x_cert_url
      if (data.email) changedUserInfo.userEmail = data.email
      if (data.marketing_subscribe !== undefined) {
        changedUserInfo.marketing_subscribe = data.marketing_subscribe
      }
      if (data.marketing_privacy !== undefined) {
        changedUserInfo.marketing_privacy = data.marketing_privacy
      }

      // Franchise Fields
      if (data.corporate_name) changedUserInfo.corporateName = data.corporate_name
      if (data.team_name) changedUserInfo.teamName = data.team_name
      if (data.region) changedUserInfo.region = data.region
      if (data.tax_manager_name) changedUserInfo.taxManagerName = data.tax_manager_name
      if (data.tax_manager_tel) changedUserInfo.taxManagerTel = data.tax_manager_tel
      if (data.tax_manager_email) changedUserInfo.taxManagerEmail = data.tax_manager_email

      const result = await updateUserInfo(cookies.jwt_token, changedUserInfo)
      if (result.code === 'SUCCESS') {
        enqueueSnackbar('회원 정보가 변경되었습니다.', { variant: 'success' })
        appDispatch({
          type: 'updateUserInfo',
          payload: {
            ...result.data,
          },
        })

        if (changedUserInfo.marketing_subscribe !== undefined) {
          const { marketing_subscribe, updatedAt } = result.data.agreement
          const { resultText } = getMarketingNotiText(marketing_subscribe, updatedAt)
          setModal({
            type: 'success',
            visible: true,
            msg: resultText,
          })
        }
      } else if (result.code === 'EXITS_COMPANY_NUMBER') {
        enqueueSnackbar('중복된 사업자등록번호입니다.', { variant: 'error' })
      }
    },
    [
      appState.userInfo?.company?.companyName,
      appState.userInfo?.company?.companyNumber,
      appState.userInfo?.userEmail,
      appState.userInfo?.agreement?.marketing_subscribe,
    ],
  )
  //
  const onBusinessIdAction = async (e, value) => {
    const predicate = await checkBusinessIdValidity(value, appState.userInfo?.userType)
    return predicate
  }
  //
  const onBusinessDocumentUpload = async (file) => {
    const result = await uploadBusinessDocument(file)
    return result
  }
  const onSmallBusinessCertUpload = async (file) => {
    const result = await uploadSmallBusinessCert(file)
    return result
  }
  //
  const onModalClose = () => {
    if (modal.onClick) modal.onClick()
    setModal({
      type: 'success',
      visible: false,
      msg: '',
    })
  }
  //
  const onClickLeave = async () => {
    const result = await leaveService(cookies.jwt_token)
    setLeaveModalVisible(false)
    if (result.code === 'SUCCESS') {
      removeCookie('jwt_token', {
        path: '/',
      })
      appDispatch({
        type: 'updateUserInfo',
        payload: {
          _id: null,
        },
      })
      history.push('/')
    } else {
      enqueueSnackbar('회원탈퇴에 실패하였습니다.\n채널톡으로 문의주세요.', { variant: 'error' })
    }
  }
  //
  const onSignOut = () => {
    localStorage.setItem('gallery-detail-count', JSON.stringify({ date: moment().format('YYYY-MM-DD'), count: 0 }))
    removeCookie('jwt_token', {
      path: '/',
    })
    appDispatch({
      type: 'updateUserInfo',
      payload: null,
    })
    history.push('/')
  }
  //
  return (
    <MyPageLayout navigationTitle="회원 정보">
      <div className="grid grid-cols-1 lg:grid-cols-4 setting-page">
        <div className="col-span-2">
          <div className="header">
            <h3>회원 정보</h3>
          </div>
          {/**/}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <InfoTextbox label="아이디" value={appState.userInfo?.userId} />
              {/**/}
              <InfoTextbox label="이름" value={appState.userInfo?.userName} />
              <InfoTextbox label="휴대폰 번호" value={appState.userInfo?.userPhonenumber} />
              <FormTextboxGroup
                label="이메일 주소"
                name="email"
                placeholder="이메일 주소를 입력해주세요"
                validators={{
                  pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                }}
                validations={['pattern']}
              />
              {isAgency ? (
                <>
                  <FormTextboxGroup
                    label="업체명"
                    name="business_name"
                    placeholder="업체명을 입력해주세요"
                    validators={{
                      required: true,
                    }}
                    validations={['required', 'required_business_id']}
                  />
                  <FormActionTextboxGroup
                    label="사업자등록번호"
                    type="number"
                    name="business_id"
                    placeholder="사업자등록번호를 입력해주세요"
                    actionName="validated"
                    onClick={onBusinessIdAction}
                    validators={{ required: true }}
                    validations={['required', 'validated']}
                  >
                    번호확인
                  </FormActionTextboxGroup>
                  <FormUploadBoxGroup
                    label="사업자등록증 또는 주민등록증(개인인 경우)"
                    name="business_document"
                    accept="image/*"
                    actionName="url"
                    onUpload={onBusinessDocumentUpload}
                    validators={{ required: true }}
                    validations={['required']}
                  >
                    첨부하기
                  </FormUploadBoxGroup>
                </>
              ) : (
                <>
                  <FormTextboxGroup
                    label="업체명"
                    name="business_name"
                    placeholder="업체명을 입력해주세요"
                    validators={{
                      validate: {
                        required_business_id: (v) => !(v === '' && methods.getValues('business_id') !== ''),
                      },
                    }}
                    validations={['required_business_id']}
                  />
                  <FormActionTextboxGroup
                    label="사업자등록번호"
                    type="number"
                    name="business_id"
                    placeholder="사업자등록번호를 입력해주세요"
                    actionName="validated"
                    onClick={onBusinessIdAction}
                    validators={{
                      validate: {
                        required_business_name: (v) => !(v === '' && methods.getValues('business_name') !== ''),
                      },
                    }}
                    actionValidators={{
                      validation_required: (v) => v === appState.userInfo?.company?.companyNumber
                        || !(methods.getValues('business_id') !== '' && v !== true),
                    }}
                    validations={['required_business_name', 'validation_required']}
                  >
                    번호확인
                  </FormActionTextboxGroup>
                  <FormUploadBoxGroup
                    label="사업자등록증"
                    name="business_document"
                    accept="image/*"
                    actionName="url"
                    onUpload={onBusinessDocumentUpload}
                  >
                    첨부하기
                  </FormUploadBoxGroup>
                  <FormUploadBoxGroup
                    label="소상공인증명서"
                    name="cert"
                    actionName="url"
                    onUpload={onSmallBusinessCertUpload}
                  >
                    첨부하기
                  </FormUploadBoxGroup>
                </>
              )}
              {appState.userInfo?.company?.companyType === 2 && (
                <>
                  <FormTextboxGroup
                    label="법인명"
                    name="corporate_name"
                    placeholder="법인명을 입력해주세요"
                    validators={{
                      required: true,
                    }}
                    validations={['required']}
                  />
                  <FormTextboxGroup
                    label="팀명(매장명)"
                    name="team_name"
                    placeholder="팀명(매장명)을 입력해주세요"
                    validators={{
                      required: true,
                    }}
                    validations={['required']}
                  />
                  <div className="textbox flex flex-col mb-4">
                    <span className="required font-bold">담당 지역</span>
                    <div className="h-3" />
                    <Select
                      classNames={{
                        control: () => '!border-disabledLine',
                        input: () => 'h-12 md:h-8',
                      }}
                      name="region"
                      value={FRANCHISE_REGION_OPTIONS.find((option) => option.value === regionValue)}
                      onChange={(data) => methods.setValue('region', data.value)}
                      options={FRANCHISE_REGION_OPTIONS}
                    />
                  </div>
                  <h3 className="mt-4">세금계산서 처리 담당자</h3>
                  <FormTextboxGroup
                    label="이름"
                    name="tax_manager_name"
                    placeholder="세금계산서 처리 담당자 이름을 입력해주세요"
                  />
                  <FormTextboxGroup
                    label="연락처(H/P)"
                    name="tax_manager_tel"
                    placeholder="세금계산서 처리 담당자 연락처를 입력해주세요"
                    validators={{
                      pattern: /^((?=.*\d).{10,11})$/,
                    }}
                    validations={['pattern']}
                  />
                  <FormTextboxGroup
                    label="이메일 주소"
                    name="tax_manager_email"
                    placeholder="세금계산서 처리 담당자 이메일 주소를 입력해주세요"
                    validators={{
                      pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    }}
                    validations={['pattern']}
                  />
                </>
              )}
              {/**/}
              <div className="bg-line h-[1px] mt-4 mb-4" />
              <span className="font-bold">마케팅 수신 동의 설정</span>
              <div className="flex gap-4 mt-3">
                <FormCheckbox name="marketing_privacy">
                  마케팅/프로모션을 위한 개인정보 수집/활용 동의(선택)
                </FormCheckbox>
              </div>
              <div className="flex gap-4 mt-3">
                <FormCheckbox name="marketing_subscribe">
                  마케팅/프로모션을 위한 광고성 정보 수신동의(선택)
                </FormCheckbox>
              </div>
              <button className="w-full primary large" type="submit" style={{ marginTop: '56px' }}>
                변경하기
              </button>
            </form>
          </FormProvider>
          {/**/}
          <div className="divider-primary" />
          {/**/}
          <div className="flex justify-between items-start">
            <button className="light large" onClick={onSignOut}>
              로그아웃
            </button>
            <button
              className="!p-0 text-textTertiary underline underline-offset-1"
              onClick={() => setLeaveModalVisible(true)}
            >
              회원탈퇴
            </button>
          </div>
          {leaveModalVisible && (
            <TwoButtonModal
              title="정말 탈퇴하시겠습니까?"
              primaryText="취소"
              secondaryText="탈퇴하기"
              onClickPrimary={() => {
                setLeaveModalVisible(false)
              }}
              onClickSecondary={onClickLeave}
              onClose={() => {
                setLeaveModalVisible(false)
              }}
            >
              {modal.msg}
            </TwoButtonModal>
          )}
          <MessageModal type={modal.type} isShown={modal.visible} onClose={onModalClose}>
            {modal.msg}
          </MessageModal>
        </div>
      </div>
    </MyPageLayout>
  )
}

export default MyPage
