import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useSnackbar } from 'notistack'

import InputValidation from './inputValidation'

const Popup = ({
  url,
  name,
  opts,
  children,
  onInit,
  onEvent,
  disabled,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar()
  //
  const browser = window.self
  let popup = null
  let timer = null
  //
  React.useEffect(() => {
    browser[name] = {}
    browser[name].onSuccess = async (res) => {
      if (onEvent) onEvent('SUCCESS', res, null)
    }
    browser[name].onError = async (error) => {
      if (onEvent) onEvent('ERROR', null, error)
    }
    browser[name].onOpen = async () => {
      if (onEvent) onEvent('OPENED', { name }, null)
    }
    browser[name].onClose = async () => {
      if (onEvent) onEvent('CLOSED', { name }, null)
    }
  }, [onEvent, name, browser])
  //
  const watcher = () => {
    if (popup === null) {
      clearInterval(timer)
      timer = null
    } else if (popup !== null && !popup.closed) {
      popup.focus()
    } else if (popup !== null && popup.closed) {
      clearInterval(timer)
      browser.focus()
      browser[name].onClose()
      timer = null
      popup = null
    }
  }
  //
  const onClick = async (e) => {
    e.preventDefault()
    if (popup && !popup.closed) {
      popup.focus()
      return
    }
    if (onInit) await onInit(e)
    popup = browser.open(url, name, opts)
    if (popup) {
      setTimeout(async () => {
        await popup.opener[name].onOpen()
      }, 0)
      if (timer === null) {
        timer = setInterval(watcher, 2000)
      }
    } else {
      enqueueSnackbar('팝업을 허용해주세요', { variant: 'error' })
    }
  }
  //
  return (
    <button {...props} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  )
}

export const FormPopup = ({
  name,
  validators,
  children,
  actionName,
  onInit,
  onEvent,
  ...props
}) => {
  const [state, setState] = React.useState({
    disabled: false,
    preValue: null,
  })
  const methods = useFormContext()
  //
  const { ...hiddenInputProps } = methods.register(name, validators)
  //
  const onPopupInit = async (e) => {
    e.preventDefault()
    if (!onInit) return
    const result = await onInit(e)
    if (result === false) return
    await setState({ ...state, preValue: result })
  }
  //
  const onPopupEvent = async (event, res, err) => {
    if (!onEvent) return
    if (event === 'SUCCESS') {
      await setState({ disabled: true })
      await methods.setValue(name, res, { shouldValidate: true })
    }
    await onEvent(event, { ...res, preValue: state.preValue }, err)
  }
  //
  return (
    <div className="flex flex-row">
      <input type="hidden" {...hiddenInputProps} />
      <Popup
        name={name}
        disabled={state.disabled}
        onInit={onPopupInit}
        onEvent={onPopupEvent}
        {...props}
      >
        {children}
      </Popup>
    </div>
  )
}

export const FormPopupGroup = ({
  validators,
  validations,
  name,
  url,
  label,
  groupName,
  children,
  onInit,
  onEvent,
  ...props
}) => {
  const labelClasses = []
  if (validators?.required) labelClasses.push('required')
  //
  return (
    <>
      <label htmlFor={name} className={labelClasses.join(' ')}>
        {label}
      </label>
      <FormPopup
        className="w-full large secondary"
        name={name}
        url={url}
        style={{ marginBottom: 0 }}
        validators={validators}
        onInit={onInit}
        onEvent={onEvent}
        {...props}
      >
        {children}
      </FormPopup>
      <InputValidation
        groupName={groupName}
        names={[name]}
        validations={validations}
      />
    </>
  )
}

export default Popup
