import React from 'react'
import { Container } from 'react-bootstrap'

import BasicLayout from '../../layouts/basic'
import NoticeContent from './components/noticeContent'
//
const NoticeDetailPage = () => (
  <BasicLayout className="community-editor">
    <Container className="mlg:bg-white mlg:!px-4">
      <div className="pb-12">
        <NoticeContent />
      </div>
    </Container>
  </BasicLayout>
)

export default NoticeDetailPage
