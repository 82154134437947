import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
//
import BasicLayout from '../../layouts/basic'
//
import { FormTextboxGroup } from '../../components/formTextbox'
import { FormActionTextboxGroup } from '../../components/formActionTextbox'
import { FormUploadBoxGroup } from '../../components/formUploadBox'
import { FormInfoTextboxGroup } from '../../components/infoTextbox'
//
import TermsConditions from './components/terms&Conditions'
import IdentityCheckPopupGroup from './components/identityCheckPopupGroup'
import UserIdFormActionBoxGroup from './components/userIdFormActionBoxGroup'
import SignUpHeader from './components/signUpHeader'
//
import AppContext from '../../services/context'
import {
  checkBusinessIdValidity, checkRecommnderIdValidity, uploadBusinessDocument, submitSignUpForm, uploadSmallBusinessCert,
} from '../../services/api'
//
const SignUpForm = () => {
  const { appState } = React.useContext(AppContext)
  const history = useHistory()
  const { search } = useLocation()
  const isSocial = appState.signUpMode.method !== 'EMAIL'
  const isAgency = appState.signUpMode.type === 'AGENCY'
  //
  let user_id_key = 'user_id'
  let user_type = 0
  let user_id_value = ''
  let method = 'skb'
  if (appState.signUpMode.type === 'AGENCY') {
    user_id_key = 'agency_id'
    user_type = 1
  }
  if (appState.signUpMode.method !== 'EMAIL') {
    user_id_value = appState.signUpMode.state?.userId
    method = appState.signUpMode.method?.toLowerCase()
  }
  const methods = useForm({
    criteriaMode: 'onSubmit',
    mode: 'onSubmit',
    defaultValues: {
      [user_id_key]: user_id_value,
      user_type,
      method,
    },
  })
  //
  const onSubmit = async (data) => {
    const form = data
    if (search.coupon) {
      form.coupon = search.coupon
    }
    const result = await submitSignUpForm(form)
    if (!result) return
    history.push('/sign-up/result', { agreement: result.agreement })
  }
  //
  const onBusinessIdAction = async (e, value) => {
    const formattedValue = value.replace(/-/gi, '')
    const predicate = await checkBusinessIdValidity(formattedValue, await methods.getValues('user_type'))
    if (value !== formattedValue) {
      methods.setValue('business_id', formattedValue)
    }
    return predicate
  }
  //
  const onRecommendationAction = async () => {
    const recommendation_id = await methods.getValues('recommendation_id')
    const result = await checkRecommnderIdValidity(recommendation_id)
    return result
  }
  //
  const onBusinessDocumentUpload = async (file) => {
    const result = await uploadBusinessDocument(file)
    return result
  }
  //
  const onSmallBusinessCertUpload = async (file) => {
    const result = await uploadSmallBusinessCert(file)
    return result
  }
  //
  return (
    <BasicLayout>
      <div className="grid xl:grid-cols-4">
        <Container className="auth-paper xl:col-start-2 xl:col-span-2">
          <div className="flex flex-col justify-items-stretch justify-center">
            <div className="header">
              <SignUpHeader userType={appState.signUpMode.type} />
            </div>
            <div className="divider-primary" />
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {!isSocial && (
                  <>
                    <UserIdFormActionBoxGroup userType={appState.signUpMode.type} />
                    <FormTextboxGroup
                      label="비밀번호"
                      name="password"
                      type="password"
                      autoComplete="off"
                      placeholder="비밀번호를 입력해주세요"
                      validators={{
                        required: true,
                        pattern: /^((?=.*\d)(?=.*[a-z])(?=.*[\W]).{8,16})$/,
                      }}
                      validations={['required', 'pattern']}
                    />
                    <FormTextboxGroup
                      label="비밀번호 확인"
                      name="confirm_password"
                      type="password"
                      autoComplete="off"
                      placeholder="비밀번호를 입력해주세요"
                      validators={{
                        required: true,
                        validate: {
                          unmatched: (v) => v === methods.getValues('password'),
                        },
                      }}
                      validations={['required', 'unmatched']}
                    />
                  </>
                )}
                {/**/}
                {isAgency ? (
                  <>
                    <FormTextboxGroup
                      label="업체명"
                      name="business_name"
                      placeholder="업체명을 입력해주세요"
                      validators={{
                        required: true,
                      }}
                      validations={['required', 'required_business_id']}
                    />
                    <FormActionTextboxGroup
                      label="사업자등록번호"
                      name="business_id"
                      placeholder="사업자등록번호를 입력해주세요"
                      actionName="validated"
                      onClick={onBusinessIdAction}
                      validators={{
                        required: true,
                        pattern: /^((?=.*\d).{10,10})$/,
                      }}
                      validations={['required', 'validated', 'pattern']}
                    >
                      번호확인
                    </FormActionTextboxGroup>
                    <FormUploadBoxGroup label="사업자등록증 (광고 청약 시 사업자 등록증 등록이 필요합니다)" name="business_document" actionName="url" onUpload={onBusinessDocumentUpload} validators={{ required: true }} validations={['required']}>
                      첨부하기
                    </FormUploadBoxGroup>
                  </>
                ) : (
                  <>
                    <FormTextboxGroup label="업체명" name="business_name" placeholder="업체명을 입력해주세요" />
                    <FormActionTextboxGroup
                      label="사업자등록번호"
                      name="business_id"
                      placeholder="사업자등록번호를 입력해주세요"
                      actionName="validated"
                      onClick={onBusinessIdAction}
                      validators={{
                        validate: {
                          required_business_name: (v) => !(v === '' && methods.getValues('business_name') !== ''),
                        },
                      }}
                      actionValidators={{
                        validation_required: (v) => !(methods.getValues('business_id') !== '' && v !== true),
                      }}
                      validations={['required_business_name', 'validation_required']}
                    >
                      번호확인
                    </FormActionTextboxGroup>
                    <FormUploadBoxGroup label="사업자등록증 (광고 청약 시 사업자 등록증 등록이 필요합니다)" name="business_document" actionName="url" onUpload={onBusinessDocumentUpload}>
                      첨부하기
                    </FormUploadBoxGroup>
                  </>
                )}
                {!isAgency && (
                  <FormUploadBoxGroup label="소상공인증명서 (등록하시면 향후 프로모션 혜택을 받으실 수 있습니다)" name="cert" actionName="url" onUpload={onSmallBusinessCertUpload}>
                    첨부하기
                  </FormUploadBoxGroup>
                )}
                {/**/}
                <IdentityCheckPopupGroup />
                {/**/}
                <FormInfoTextboxGroup name="full_name" label="이름" placeholder="본인 인증을 완료해주세요" validators={{ required: true }} validations={['required']} />
                <FormInfoTextboxGroup name="phone_no" label="휴대폰 번호" placeholder="본인 인증을 완료해주세요" validators={{ required: true }} validations={['required']} />
                {/**/}
                <FormTextboxGroup
                  label="이메일 주소"
                  name="email"
                  placeholder="이메일 주소를 입력해주세요"
                  validators={{
                    required: true,
                    pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                  }}
                  validations={['required', 'pattern']}
                />
                <FormActionTextboxGroup
                  label="추천인 아이디"
                  className="larger recommendation_id_input"
                  name="recommendation_id"
                  actionName="validated"
                  placeholder="추천인 아이디를 입력해주세요"
                  onClick={onRecommendationAction}
                  validations={['validated']}
                >
                  추천인 확인
                </FormActionTextboxGroup>
                {/**/}
                <div className="divider-primary" style={{ marginTop: 24 }} />
                <TermsConditions />
                {/**/}
                <button className="w-full primary large" type="submit">
                  회원가입 하기
                </button>
              </form>
            </FormProvider>
            {/**/}
          </div>
        </Container>
      </div>
    </BasicLayout>
  )
}

export default SignUpForm
