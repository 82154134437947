import React from 'react'

import { Container } from 'react-bootstrap'
import BasicLayout from '../../layouts/basic'
import CtaSection from './components/ctaSection'
import AdsTable from './components/AdsTable'
import { hideFab } from '../../services/fab'
//
const FranchisePage = () => {
  hideFab()

  return (
    <BasicLayout>
      <Container className="px-4 py-12 mlg:bg-white lg:px-0">
        <div className="mxl:my-12">
          <AdsTable />
          <CtaSection />
        </div>
      </Container>
    </BasicLayout>
  )
}

export default FranchisePage
