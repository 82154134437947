import React from 'react'
import { Container, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import { useCookies } from 'react-cookie'

import BasicLayout from '../../layouts/basic'
import AppContext from '../../services/context'
import { formatNumber, formatDate } from '../../services/utils'
import InfoIcon from '../../assets/icons/icon-filled-suggested-question-circle.svg'

import illust from '../../assets/imgs/estimate-illust.png'
import illust2x from '../../assets/imgs/estimate-illust@2x.png'
import illust3x from '../../assets/imgs/estimate-illust@3x.png'
import { calcBudget, createCampaign } from '../../services/api'
//
const EstimateResult = () => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const scrollFlag = React.useRef(null)
  const location = useLocation()
  const [cookies] = useCookies(['jwt_token'])
  const [priceData, setPriceData] = React.useState({})
  const history = useHistory()

  React.useEffect(() => {
    if (scrollFlag.current) {
      scrollFlag.current.scrollIntoView(false)
    }
  }, [scrollFlag])

  React.useEffect(() => {
    calcBudget(
      null,
      location.state.ad_campaign_regions,
      location.state.ad_campaign_regions_count,
      location.state.ad_campaign_period,
      location.state.ad_campaign_period_month,
      null,
      location.state.ad_campaign_region_multiplier,
      0,
    ).then((result) => {
      if (!result) return
      setPriceData(result)
    })
  }, [location.state])

  const onClickTutorial = () => {
    history.push('/tutorial/ad')
  }

  const onClickCampaign = async () => {
    if (!appState.userInfo || !cookies.jwt_token) {
      appDispatch({ type: 'saveEstimateResultTemp', payload: location.state })
      history.push(`/sign-in?previous=${encodeURIComponent('/portal/media/create/1')}`)
    }
    const result = await createCampaign(cookies.jwt_token, null, location.state)
    if (!result) return
    await appDispatch({ type: 'updateAdCampaign', payload: result })
    history.push('/portal/media/create/1')
  }

  const onClickConsult = () => {
    appDispatch({ type: 'saveEstimateResultTemp', payload: location.state })
    history.push('/consult', location.state)
  }
  //
  const getBudgetText = React.useCallback(
    (type) => {
      const index = location.state.ad_campaign_length === 15 ? 0 : 1
      const isMin = type === 'min'
      const prefix = isMin ? '최소' : '최대'
      const budget = isMin ? priceData?.minPrice?.[index] : priceData?.maxPrice?.[index]
      const impression = isMin
        ? priceData?.minImpression?.[index]
        : priceData?.maxImpression?.[index]
      return `${prefix}: ${formatNumber(budget)}원 (총 ${formatNumber(impression)}회 노출)`
    },
    [location.state.ad_campaign_length, priceData],
  )
  //
  return (
    <BasicLayout>
      <div className="page-headline" style={{ padding: 0 }}>
        <Container>
          <div className="flex justify-between items-center">
            <div>
              <h1>내게 맞는 광고비 알아보기</h1>
              <div className="mt-10 text-2xl">
                <span>원하는 동네, 기간, 영상길이만 선택하고 바로 견적을 받아보세요.</span>
                <br />
                <span>TV광고도 이제 저렴하고 빠르게!</span>
              </div>
            </div>
            <img src={illust} srcSet={`${illust2x} 2x, ${illust3x} 3x`} alt="illust" />
          </div>
        </Container>
      </div>
      <div className="page-content estimate">
        <Container className="bg-bgColor lg:bg-white pb-0 md:pb-[56px]">
          <div className="create-ad-part mmd:mb-0">
            <div className="flex flex-col items-center">
              <h3 className="text-2xl mb-[20px] self-center mmd:text-base">
                요청하신 견적이 완료되었습니다
              </h3>
              <span className="text-lg text-center mmd:text-sm">
                쿠폰, 프로모션 등의 할인이 적용되지 않은 기본 견적입니다.
              </span>
              <span className="text-lg text-center mmd:text-sm mb-[20px]">
                아래 전화상담 예약을 통해 전문 컨설턴트에게 보다 자세한 내용을 상담 받을 수 있어요
              </span>
            </div>
            <div className="white-holder-2 flex flex-col">
              <span className="label">선택한 지역</span>
              <div className="white-holder-4">
                {(location.state?.ad_campaign_regions || []).map((item, index) => (
                  <span key={index}>{item.value}</span>
                ))}
              </div>
            </div>
            <div className="flex flex-row amount mt-4">
              <div className="flex items-center flex-grow">
                <span>광고 대상 수</span>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 250 }}
                  trigger={['hover', 'focus', 'click']}
                  overlay={(
                    <Tooltip>
                      {
                        '선택하신 지역에서 광고가 노출될 수 있는 전체 모수를 의미합니다.\n예를 들어 광고 대상 수가 1,000명이면 1,000명의 총 시청가능 가구를 대상으로 광고를 노출시킵니다.'
                      }
                    </Tooltip>
                  )}
                >
                  <button type="button" className="mmd:!mt-[-1px]">
                    <img src={InfoIcon} alt="info" className="mmd:w-[18px] mmd:h-[18px]" />
                  </button>
                </OverlayTrigger>
              </div>
              <span>
                {`+ ${formatNumber(
                  location.state.ad_campaign_regions_count <= 100
                    ? 100
                    : Math.ceil(location.state.ad_campaign_regions_count / 100) * 100,
                )} 가구`}
              </span>
            </div>
            <div className="white-holder-3 flex flex-col">
              <span className="label">선택한 기간</span>
              <div>
                {location.state?.ad_campaign_dates?.length === 2 && (
                  <span className="value">
                    {`${formatDate(location.state.ad_campaign_dates[0])} ~ ${formatDate(
                      location.state.ad_campaign_dates[1],
                    )} (${
                      moment(location.state.ad_campaign_dates[1]).diff(
                        location.state.ad_campaign_dates[0],
                        'day',
                      ) + 1
                    }일)`}
                  </span>
                )}
              </div>
            </div>
            <div className="white-holder-3 flex flex-col">
              <span className="label">선택한 광고 영상 길이</span>
              <div className="value">
                {location.state?.ad_campaign_length}
                초
              </div>
            </div>
            <div className="white-holder-3 flex flex-col">
              <span className="label">권장 예산</span>
              <div className="flex flex-col amount">
                <div className="flex-grow">
                  <span className="mmd:text-base">{getBudgetText('min')}</span>
                  <br />
                  <span className="mmd:text-base">{getBudgetText('max')}</span>
                  <div className="font-normal mt-2 text-base">
                    <span className="mmd:!text-sm" style={{ lineHeight: 2 }}>
                      권장예산은 B tv 우리동네광고 예산 최적화 로직을 기준으로 제안됩니다.
                    </span>
                    <br className="mmd:hidden" />
                    <span className="mmd:!text-sm mmd:ml-1">
                      최소금액 10만원(VAT 별도)부터 광고가 가능하지만, 권장예산을 사용하시면 보다
                      높은 광고효과를 경험하실 수 있습니다.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <span ref={scrollFlag}>&nbsp;</span>
          </div>
          <div className="flex mmd:!flex-col ad-buttons-holder justify-center mlg:!bg-white mlg:mt-[-8px]">
            <button
              className="secondary larger mmd:rounded-[10px] mmd:!py-[5px] mmd:!min-h-[50px] mmd:!mb-4 mmd:rounded-br-none mmd:!text-base"
              onClick={onClickTutorial}
            >
              광고 만들기 튜토리얼 보기
            </button>
            <button
              className="secondary larger mmd:rounded-[10px] mmd:!py-[5px] mmd:!min-h-[50px] mmd:!mb-4 mmd:rounded-br-none mmd:!text-base"
              onClick={onClickCampaign}
            >
              직접 광고 청약
            </button>
            <button className="primary larger" onClick={onClickConsult}>
              전화상담 예약
            </button>
          </div>
        </Container>
      </div>
    </BasicLayout>
  )
}

export default EstimateResult
