import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import { useCookies } from 'react-cookie'

import AppContext from '../../../services/context'
import LabeledSection from '../../../components/labeledSection'
import usePageIndicator from '../../../hooks/usePageIndicator'
import StatusDot from '../../../components/statusDot'
import { useWindowSize } from '../../../services/utils'
import NoticeCard from './NoticeCard'
import { getCampaignsByFranchiseNotice, getFranchiseNotices } from '../../../services/api/notice'
import { FRANCHISE_NOTICE_TYPE, FRANCHISE_REGIONS } from '../../../services/constants'
//
const NoticeTable = ({ regions }) => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const data = appState.franchiseNoticeDataList
  const [width] = useWindowSize()
  const history = useHistory()
  const [cookies] = useCookies(['jwt_token'])
  const { pageOption, setPageOption, renderPageIndicator } = usePageIndicator(
    appState.franchiseNoticeOptions.pageOption,
  )
  //
  React.useEffect(() => {
    if (regions) {
      getFranchiseNotices(cookies.jwt_token, regions).then((result) => {
        appDispatch({ type: 'updateFranchiseNoticeDataList', payload: result })
      })
    }
  }, [regions])
  //
  const renderWriteButton = () => (appState.userInfo?.companyRank >= 8 ? (
    <Link to="/franchise/notice/write">
      <button className="brand small">작성하기</button>
    </Link>
  ) : null)
  //
  const onClickRow = (item) => {
    history.push(`/franchise/notice/${item.id}`)
  }
  //
  const onClickCount = async (item) => {
    const result = await getCampaignsByFranchiseNotice(cookies.jwt_token, item.id)
    await appDispatch({ type: 'updateAdsList', payload: result })
    history.push(`/franchise/notice/${item.id}/ads`)
  }
  //
  return (
    <div className="notices franchise-card p-4 flex flex-col min-h-[500px] mb-12 mlg:!border-0 mlg:!p-0 mlg:!rounded-none">
      <LabeledSection label="공지사항" renderButtons={renderWriteButton}>
        {width >= 798 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>등록일</th>
                <th>유형</th>
                <th>지역</th>
                <th>공지명</th>
                <th>광고기간</th>
                <th>참여매장수</th>
                <th>상태</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0
                && data.map((item, index) => {
                  const isNormal = item.type === 'NORMAL'
                  return (
                    <tr key={index} className="cursor-pointer">
                      <td onClick={() => onClickRow(item)}>{item.createdAt}</td>
                      <td onClick={() => onClickRow(item)}>{FRANCHISE_NOTICE_TYPE[item.type] || ''}</td>
                      <td onClick={() => onClickRow(item)}>{FRANCHISE_REGIONS[item.region]}</td>
                      <td onClick={() => onClickRow(item)}>{item.title}</td>
                      <td onClick={() => onClickRow(item)}>{isNormal ? '-' : item.period}</td>
                      {appState.userInfo?.companyRank >= 8 ? (
                        <td>
                          {isNormal ? (
                            '-'
                          ) : (
                            <div
                              className="cursor-pointer underline text-textTertiary"
                              onClick={() => {
                                onClickCount(item)
                              }}
                            >
                              {item.participationCount || 0}
                            </div>
                          )}
                        </td>
                      ) : (
                        <td onClick={() => onClickRow(item)}>{isNormal ? '-' : item.participationCount || 0}</td>
                      )}
                      <td onClick={() => onClickRow(item)}>
                        <div className="flex items-center gap-1">
                          {isNormal ? (
                            '-'
                          ) : (
                            <>
                              <StatusDot status={item.statusCode} />
                              {item.status}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        ) : (
          <div className="flex flex-col gap-4">
            {data.length > 0
              && data.map((item, index) => (
                <NoticeCard
                  key={item._id}
                  notice={item}
                  onClickCount={appState.userInfo?.companyRank >= 8 ? onClickCount : null}
                />
              ))}
          </div>
        )}
        {(!data || data.length < 1) && (
          <div className="empty">
            <p className="fir">등록된 공지사항이 없습니다</p>
          </div>
        )}
      </LabeledSection>
      <div className="flex-1" />
      <div className="flex justify-center items-center mt-4">{renderPageIndicator()}</div>
    </div>
  )
}

export default NoticeTable
