import React from 'react'
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs'
import { Container } from 'react-bootstrap'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'

import moment from 'moment'
import MapSelector from './components/mapSelector'
import PeriodSelector from './components/periodSelector'
import ProductSelector from './components/productSelector'
import BudgetSelector from './components/budgetSelector'
import StoreInfo from './components/StoreInfo'
import AppContext from '../../services/context'
import { patchCampaign, createPromotion, updatePromotion } from '../../services/api'
import useUploadStoreImages from './hooks/useUploadStoreImages'
import RecommendTab from './components/recommendTab'

import aiRobotIcon from '../../assets/icons/icon-ai-robot.svg'
import aiRobotDisabledIcon from '../../assets/icons/icon-ai-robot-disabled.svg'
import { formatNumber } from '../../services/utils'
//
const storeProgress = async (appDispatch, methods, appState, cookies, enqueueSnackbar) => {
  const data = await methods.getValues()

  if (appState.adCampaign?.ad_campaign_is_paid) {
    if (Number(data.ad_campaign_budget) !== appState.adCampaign.step1.ad_campaign_budget) {
      enqueueSnackbar('이미 결제된 캠페인은 예산 변경이 불가합니다.', { variant: 'error', autoHideDuration: 2000 })
      return false
    }
  }
  if (data.ad_campaign_budget < data.ad_campaign_min_budget) {
    enqueueSnackbar(`최소 예산 ${formatNumber(data.ad_campaign_min_budget)}원 이상 입력해주세요.`, {
      variant: 'error',
      autoHideDuration: 1000,
    })
    return false
  }
  if (data.clientBusinessId && data.clientBusinessId.length > 0) {
    if (!data.x_clientBusinessId_validated) {
      enqueueSnackbar('사업자 번호를 확인해주세요', { variant: 'error', autoHideDuration: 1000 })
      return false
    }
  }

  if (data.companyName && data.companySector && data.storeAddress && data.storeAddressDetail) {
    if (appState.adCampaign.step1?.galleryId) {
      await updatePromotion(cookies.jwt_token, {
        ...data,
        storeId: appState.adCampaign.step1?.storeId,
        galleryId: appState.adCampaign.step1?.galleryId,
        campaignId: data.ad_campaign_id,
        storeImages: data.storeImages,
      })
    } else {
      await createPromotion(cookies.jwt_token, {
        ...data,
        campaignId: data.ad_campaign_id,
        storeImages: data.storeImages,
      })
    }
  }
  const result = await patchCampaign(cookies.jwt_token, appState.adCampaign.id, data, 1)
  if (!result) return false
  await appDispatch({ type: 'updateAdCampaign', payload: result })
  return true
}
//
const AdCampaignStep1 = (_, forwardedRef) => {
  const history = useHistory()
  const [cookies] = useCookies(['jwt_token'])
  const { enqueueSnackbar } = useSnackbar()
  const { images, renderUploadView } = useUploadStoreImages()
  const buttonRef = React.useRef()
  const observedDiv = React.useRef(null)

  const { appState, appDispatch } = React.useContext(AppContext)
  const methods = useForm({
    criteriaMode: 'all',
    mode: 'onSubmit',
    defaultValues: {
      ...appState.adCampaign.step1,
      active_product: appState.adCampaign.step1?.ad_campaign_product ? 'true' : 'false',
    },
  })
  const regions = methods.watch('ad_campaign_regions') || []
  //
  const [selectedTab, setSelectedTab] = React.useState(
    (appState.adCampaign.step1.ad_campaign_regions?.length === 0
      || appState.adCampaign.step1.ad_campaign_recommend_request_id)
      && appState.adCampaign.step1.promotion?.id !== 'thedream_2406'
      ? 0
      : 1,
  )
  const [formVisible, setFormVisible] = React.useState(!appState.adCampaign.step1.ad_campaign_recommend_request_id)

  const [width, setWidth] = React.useState()
  //
  React.useEffect(
    () => {
      if (!observedDiv.current) {
        return
      }
      const resizeObserver = new ResizeObserver(() => {
        if (observedDiv.current.offsetWidth !== width) {
          setWidth(observedDiv.current.offsetWidth)
        }
      })

      resizeObserver.observe(observedDiv.current)

      return function cleanup() {
        resizeObserver.disconnect()
      }
    },
    // only update the effect if the ref element changed
    [observedDiv.current],
  )
  //
  React.useEffect(
    () => () => {
      appDispatch({ type: 'updateRecommendForm', payload: null })
    },
    [appDispatch],
  )
  //
  const onSubmit = async () => {
    await methods.setValue('storeImages', images)
    const result = await storeProgress(appDispatch, methods, appState, cookies, enqueueSnackbar)
    if (!result) return
    history.push('/portal/media/create/2')
  }
  //
  const recommendCallback = React.useCallback(() => {
    // setSelectedTab(1)
  }, [])
  //
  const handleRecommendCities = React.useCallback(
    (recommendRequestId, cities) => {
      methods.setValue('ad_campaign_recommend_request_id', recommendRequestId)
      methods.setValue('ad_campaign_regions', cities)

      methods.setValue('companySector', appState.recommendForm?.category)
      methods.setValue('storeLat', appState.recommendForm?.storeLat)
      methods.setValue('storeLon', appState.recommendForm?.storeLon)
      methods.setValue('storeAddress', appState.recommendForm?.storeAddress)
      methods.setValue('storeAddressKey', appState.recommendForm?.storeAddressKey)
      methods.setValue('storeKey', appState.recommendForm?.storeKey)
      methods.setValue('storePostalCode', appState.recommendForm?.storePostalCode)
      methods.setValue('storeTel', appState.recommendForm?.storeTel)

      setFormVisible(false)
      observedDiv.current.scrollIntoView({ behavior: 'smooth' })
      enqueueSnackbar('추천 지역이 반영되었습니다', { variant: 'success' })
    },
    [methods, appState.recommendForm],
  )
  //
  const handleRecommendBudget = React.useCallback(
    (startAt, endAt, budget) => {
      const period = moment(endAt).diff(moment(startAt), 'days') + 1

      methods.setValue('ad_campaign_period', period)
      methods.setValue('ad_campaign_dates', [startAt, endAt])
      methods.setValue('ad_campaign_budget', budget)

      enqueueSnackbar('추천 기간 및 예산이 반영되었습니다', { variant: 'success' })
    },
    [methods],
  )
  //
  const onClickTab = async (index) => {
    await setSelectedTab(index)
  }
  //
  const onClickSelectAgain = async () => {
    setFormVisible(true)
    observedDiv.current.scrollIntoView({ behavior: 'smooth' })
  }
  //
  React.useImperativeHandle(
    forwardedRef,
    () => ({
      //
      storeProgress: async () => {
        const result = await storeProgress(appDispatch, methods, appState, cookies, enqueueSnackbar)
        return result
      },
      onSubmit: async () => {
        buttonRef.current.click()
      },
    }),
    [appDispatch, methods, appState, cookies, buttonRef],
  )
  //
  return (
    <div ref={observedDiv}>
      <Container>
        <Tabs
          className="media-tabs tabs-secondary overflow-visible"
          selectedTabClassName="active"
          selectedIndex={selectedTab}
          onSelect={(index) => onClickTab(index)}
        >
          <TabList className="flex tab-list-secondary">
            <Tab className="tab-secondary flex-1 !flex flex-center px-8 gap-2">
              <img src={selectedTab === 0 ? aiRobotIcon : aiRobotDisabledIcon} alt="robot" />
              광고지역 추천받기
            </Tab>
            <Tab className="tab-secondary flex-1 px-8 !flex flex-center">광고지역 직접 선택하기</Tab>
          </TabList>
          <TabPanel>
            {formVisible && (
              <RecommendTab
                handleRecommendBudget={handleRecommendBudget}
                handleRecommendCities={handleRecommendCities}
                recommendCallback={recommendCallback}
              />
            )}
            {regions.length > 0 && (
              <FormProvider {...methods}>
                <MapSelector editable={false} />
              </FormProvider>
            )}
            {!formVisible && (
              <div className="flex flex-row justify-center space-x-4 mb-12 ">
                <button type="button" className="primary font-bold min-w-[240px]" onClick={onClickSelectAgain}>
                  조건 다시 선택
                </button>
              </div>
            )}
          </TabPanel>
          <TabPanel>
            <FormProvider {...methods}>
              <MapSelector />
            </FormProvider>
          </TabPanel>
        </Tabs>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <PeriodSelector />
            <ProductSelector />
            <BudgetSelector />
            <StoreInfo renderUploadView={renderUploadView} />
            <div className="flex flex-row ad-buttons-holder justify-center">
              <button type="button" className="secondary larger" disabled>
                이전으로
              </button>
              <button ref={buttonRef} type="submit" className="primary larger">
                다음으로
              </button>
            </div>
          </form>
        </FormProvider>
      </Container>
    </div>
  )
}

export default React.forwardRef(AdCampaignStep1)
