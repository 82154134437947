import React from 'react'
import { useFormContext } from 'react-hook-form'
import InputValidation from './inputValidation'

const InfoTextbox = ({ label, value, required }) => (
  <div>
    <span className={`font-bold ${required && 'required'}`}>{label}</span>
    <p className="input larger disabled mt-2">{value}</p>
  </div>
)

export default InfoTextbox
//
export const FormInfoTextbox = ({
  name, placeholder, validators, ...props
}) => {
  const methods = useFormContext()
  methods.register(name, validators)
  const watchName = methods.watch(name, false)
  const [state, setState] = React.useState(placeholder)
  //
  React.useEffect(() => {
    if (watchName && watchName !== '') setState(watchName)
    else setState(placeholder)
  }, [watchName, placeholder])
  //
  return (
    <p className="input larger disabled" {...props}>
      {state}
&nbsp;
    </p>
  )
}
//
export const FormInfoTextboxGroup = ({
  validators,
  validations,
  name,
  groupName,
  label,
  ...props
}) => {
  const labelClasses = []
  if (validators?.required) labelClasses.push('required')
  //
  return (
    <>
      <span className={labelClasses.join(' ')}>{label}</span>
      <FormInfoTextbox
        name={name}
        validators={validators}
        style={{ marginBottom: 0 }}
        {...props}
      />
      <InputValidation
        groupName={groupName}
        names={[name]}
        validations={validations}
      />
    </>
  )
}
