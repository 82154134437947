import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
//
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import BasicLayout from '../../layouts/basic'
//
import { FormTextboxGroup } from '../../components/formTextbox'
import { FormActionTextboxGroup } from '../../components/formActionTextbox'
import { FormUploadBoxGroup } from '../../components/formUploadBox'
import { FormInfoTextboxGroup } from '../../components/infoTextbox'
//
import IdentityCheckPopupGroup from './components/identityCheckPopupGroup'
//
import AppContext from '../../services/context'
import {
  checkBusinessIdValidity,
  checkRecommnderIdValidity,
  uploadBusinessDocument,
  uploadSmallBusinessCert,
  submitRegisterForm,
  createCampaign,
} from '../../services/api'
//
const RegisterForm = () => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const [cookies] = useCookies(['jwt_token'])
  //
  const methods = useForm({
    criteriaMode: 'onSubmit',
    mode: 'onSubmit',
    defaultValues: {
      _id: appState.userInfo?._id,
      user_type: 0,
      business_name: appState.userInfo?.company?.companyName,
      business_id: appState.userInfo?.company?.companyNumber,
      email: appState.userInfo?.userEmail,
    },
  })
  //
  const onSubmit = async (data) => {
    const form = data
    const result = await submitRegisterForm(form)
    if (!result) {
      enqueueSnackbar('이미 가입한 사용자입니다.', { variant: 'error' })
      return
    }
    await appDispatch({ type: 'updateUserInfo', payload: result })
    const campaign = await createCampaign(cookies.jwt_token)
    if (!campaign) return
    await appDispatch({ type: 'updateAdCampaign', payload: campaign })
    history.replace('/portal/media/create/1')
  }
  //
  const onBusinessIdAction = async (e, value) => {
    const formattedValue = value.replace(/-/gi, '')
    const predicate = await checkBusinessIdValidity(formattedValue, await methods.getValues('user_type'))
    if (value !== formattedValue) {
      methods.setValue('business_id', formattedValue)
    }
    return predicate
  }
  //
  const onRecommendationAction = async () => {
    const recommendation_id = await methods.getValues('recommendation_id')
    const result = await checkRecommnderIdValidity(recommendation_id)
    return result
  }
  //
  const onBusinessDocumentUpload = async (file) => {
    const result = await uploadBusinessDocument(file)
    return result
  }
  //
  const onSmallBusinessCertUpload = async (file) => {
    const result = await uploadSmallBusinessCert(file)
    return result
  }
  //
  return (
    <BasicLayout>
      <div className="grid xl:grid-cols-4">
        <Container className="auth-paper xl:col-start-2 xl:col-span-2">
          <div className="flex flex-col justify-items-stretch justify-center">
            <div className="header">
              <h1 className="hidden lg:block">회원정보 등록</h1>
            </div>
            <div className="divider-primary" />
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit, (e) => console.log(e))}>
                {/**/}
                <FormTextboxGroup label="업체명" name="business_name" placeholder="업체명을 입력해주세요" />
                <FormActionTextboxGroup
                  label="사업자등록번호"
                  name="business_id"
                  placeholder="사업자등록번호를 입력해주세요"
                  actionName="validated"
                  onClick={onBusinessIdAction}
                  validators={{
                    validate: {
                      required_business_name: (v) => !(v === '' && methods.getValues('business_name') !== ''),
                    },
                  }}
                  actionValidators={{
                    validation_required: (v) => !(methods.getValues('business_id') !== '' && v !== true),
                  }}
                  validations={['required_business_name', 'validation_required']}
                >
                  번호확인
                </FormActionTextboxGroup>
                <FormUploadBoxGroup
                  label="사업자등록증 (광고 청약 시 사업자 등록증 등록이 필요합니다)"
                  name="business_document"
                  actionName="url"
                  onUpload={onBusinessDocumentUpload}
                  validators={{ required: true }}
                  validations={['required']}
                >
                  첨부하기
                </FormUploadBoxGroup>
                <FormUploadBoxGroup
                  label="소상공인증명서 (등록하시면 향후 프로모션 혜택을 받으실 수 있습니다)"
                  name="cert"
                  actionName="url"
                  onUpload={onSmallBusinessCertUpload}
                >
                  첨부하기
                </FormUploadBoxGroup>
                {/**/}
                <IdentityCheckPopupGroup />
                {/**/}
                <FormInfoTextboxGroup
                  name="full_name"
                  label="이름"
                  placeholder="본인 인증을 완료해주세요"
                  validators={{ required: true }}
                  validations={['required']}
                />
                <FormInfoTextboxGroup
                  name="phone_no"
                  label="휴대폰 번호"
                  placeholder="본인 인증을 완료해주세요"
                  validators={{ required: true }}
                  validations={['required']}
                />
                {/**/}
                <FormTextboxGroup
                  label="이메일 주소"
                  name="email"
                  placeholder="이메일 주소를 입력해주세요"
                  validators={{
                    required: true,
                    pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                  }}
                  validations={['required', 'pattern']}
                />
                <FormActionTextboxGroup
                  label="추천인 아이디"
                  className="larger recommendation_id_input"
                  name="recommendation_id"
                  actionName="validated"
                  placeholder="추천인 아이디를 입력해주세요"
                  onClick={onRecommendationAction}
                  validations={['validated']}
                >
                  추천인 확인
                </FormActionTextboxGroup>
                {/**/}
                <div className="divider-primary" style={{ marginTop: 24 }} />
                {/**/}
                <button className="w-full primary large" type="submit">
                  회원정보 등록하기
                </button>
              </form>
            </FormProvider>
            {/**/}
          </div>
        </Container>
      </div>
    </BasicLayout>
  )
}

export default RegisterForm
