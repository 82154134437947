import React from 'react'
import QuestionMark from '../assets/icons/question_mark.svg'

const InfoPaper = ({ src, children }) => {
  const imgSrc = src || QuestionMark
  return (
    <div className="info-paper flex flex-col md:flex-row">
      <img src={imgSrc} alt="question_mark" />
      <div>{children}</div>
    </div>
  )
}

export default InfoPaper
