import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import Footer from '../../components/footer'
import Steps from '../../components/steps'
import AdCampaignStep1 from './adCampaignStep1'
import AdCampaignStep2 from './adCampaignStep2'
import AdCampaignStep3 from './adCampaignStep3'
import AppContext from '../../services/context'
import { validateAdCampaign } from '../../services/utils'
import MessageModal from '../../components/modals'
import { checkIsFirst } from '../../services/api'

const steps = [
  {
    title: '필요한 정보 입력하기',
    shortTitle: '정보 입력',
    content: (
      <p className="hidden xl:block">
        <span>원하는 지역과 기간을 선택하고</span>
        <br />
        <span>예산을 입력해주세요.</span>
      </p>
    ),
  },
  {
    title: '소재 등록하기',
    shortTitle: '소재 등록',
    content: (
      <p className="hidden xl:block">
        <span>광고에 사용할 영상을</span>
        <br />
        <span>등록해주세요.</span>
      </p>
    ),
  },
  {
    title: '예산 결제하기',
    shortTitle: '예산 결제',
    content: (
      <p className="hidden xl:block">
        <span>입력한 예산을</span>
        <br />
        <span>결제합니다.</span>
      </p>
    ),
  },
]

const initialModalState = { missing: false, docs: false }

const AdCampaign = () => {
  const step1Ref = React.useRef()
  const [isModalShown, setIsModalShown] = React.useState({ missing: false, docs: false })
  const { appState, appDispatch } = React.useContext(AppContext)
  //
  const history = useHistory()
  const params = useParams()
  const [selectedStep] = React.useState(Number(params.step))
  //
  React.useEffect(() => {
    if (!appState.userInfo?.company?.regAttachUrl) {
      setIsModalShown({ missing: false, docs: true })
    }
    if (appState.userInfo?.userType === '0') {
      checkIsFirst({ userId: appState.userInfo?._id }).then((result) => {
        if (result) {
          appDispatch({ type: 'updateAdCampaignStore', payload: { isFirst: result.isFirst } })
        }
      })
    }
  }, [])
  //
  const onStepClick = async (e, step) => {
    switch (selectedStep) {
        case 1:
          const result = await step1Ref.current.storeProgress()
          if (!result) return
          break
        default:
          break
    }
    if (step === 2 && !validateAdCampaign(appState.adCampaign)) {
      setIsModalShown({ missing: true, docs: false })
      return
    }
    if (step === selectedStep - 1) return
    history.replace(`/portal/media/create/${step + 1}`)
  }
  //
  const onModalClose = () => {
    setIsModalShown(initialModalState)
  }
  //
  return (
    <>
      <Navbar>
        <Container>
          <Nav className="me-auto">
            <Nav.Link className="go-back" onClick={() => history.replace('/portal/media')}>
              나의광고 로 돌아가기
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <main className="flex flex-grow flex-col ad-campaign-layout">
        <div className="page-headline" style={{ backgroundColor: 'var(--line-color)' }}>
          <Container>
            <h1 style={{ color: 'var(--text-primary)' }}>광고 완성하기</h1>
          </Container>
        </div>
        <div className="page-content create-ad">
          <Container>
            <Steps steps={steps} selected={selectedStep - 1} onStepClick={onStepClick} />
          </Container>
          {/* <div className="divider-tertiary" style={{ margin: "56px 0" }} /> */}
          {selectedStep === 1 && <AdCampaignStep1 ref={step1Ref} />}
          {selectedStep === 2 && <AdCampaignStep2 />}
          {selectedStep === 3 && <AdCampaignStep3 />}
        </div>
      </main>
      <MessageModal type="warning" isShown={isModalShown.missing} onClose={onModalClose}>
        <h3 className="text-center">
          <span>정보 또는 소재를 입력하지 않았습니다.</span>
          <br />
          <span>확인 후 다시 시도해주세요.</span>
        </h3>
      </MessageModal>
      <MessageModal
        type="warning"
        isShown={isModalShown.docs}
        onClose={() => {
          setIsModalShown(initialModalState)
          history.replace('/portal/my-page/settings')
        }}
      >
        <h3 className="text-center">
          <span>광고 청약시 사업자등록증 등록이 필요합니다.</span>
          <br />
          <br />
          <span>{'마이페이지 > 회원정보페이지에서 사업자등록증 첨부해 주세요.'}</span>
          <br />
          <span>(기타문의 : 채널톡으로 문의요망)</span>
        </h3>
      </MessageModal>
      <Footer />
    </>
  )
}

export default AdCampaign
