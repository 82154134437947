import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'

import { QueryClient, QueryClientProvider } from 'react-query'
import { datadogRum } from '@datadog/browser-rum'
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import reportWebVitals from './etc/reportWebVitals'
import RoutesContainer, { Routes } from './services/router'
import { AppContextProvider } from './services/context'
import { FabProvider } from './services/fab'

import './assets/assets'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'core-js/stable'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-loading-skeleton/dist/skeleton.css'
import ChannelTalk from './components/ChannelTalk'

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production'

if (isProduction) {
  ReactGA.initialize('UA-232215623-1', { debug: false })
  ReactGA.plugin.require('displayfeatures')
  ReactGA4.initialize('G-LH98JQZ0EZ', { debug: false })
} else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
  ReactGA4.initialize('G-0F78ZS40DN', { debug: false })
}
const queryClient = new QueryClient()

datadogRum.init({
  applicationId: '8f7b94c9-c1ba-4814-b756-218cca95bb69',
  clientToken: 'pub2d138ac969e6ae945fcf2459782db62b',
  site: 'datadoghq.com',
  service: 'btvtownad.com',
  allowedTracingOrigins: ['https://api.btvtownad.net'],
  env: 'client',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
})
datadogRum.startSessionReplayRecording()

if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
  ReactDOM.render(
    <div>Internet Explorer는 지원하지 않습니다. 엣지, 크롬 브라우저를 사용해주세요</div>,
    document.getElementById('root'),
  )
} else {
  ReactDOM.render(
    <React.StrictMode>
      <AppContextProvider>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
              <FabProvider>
                <RoutesContainer routes={Routes} />
              </FabProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </QueryClientProvider>
        <ChannelTalk />
      </AppContextProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
