import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import Select from 'react-select'
import cx from 'classnames'

import { RECOMMEND_FORM, SUBCATEGORY } from '../../../services/constants'
import InputAddress from './inputAddress'
import { FormRadiobox } from '../../../components/radio'
import CheckboxGroup from '../../../components/CheckboxGroup'
import AppContext from '../../../services/context'
import infoIcon from '../../../assets/icons/icon-filled-info-circle.svg'

const FormBox = ({
  className, label, required = false, children,
}) => (
  <div className={cx('flex items-center mlg:flex-col mlg:items-start', className)}>
    <h3 className="w-[140px] text-textPrimary text-lg mb-2">
      {label}
      {required && <span className="text-main text-lg ml-0.5 mt-[-3px]">*</span>}
    </h3>
    <div className="flex-1 mlg:flex-auto w-full">
      {children}
    </div>
  </div>
)

const RecommendForm = ({ step, goMapSection }) => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const [priority, setPriority] = useState(appState.recommendForm?.priority || [null, null, null, null])

  const methods = useForm({
    defaultValues: appState.recommendForm || {
      age: [],
      household: [],
      channel: [],
      prevMarketing: [],
      gender: 'mf',
      distance: RECOMMEND_FORM.distance[0].value,
      highSalary: RECOMMEND_FORM.salary[0].value,
    },
  })

  const { formState: { isDirty, isValid } } = methods

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      age: data.age.sort((a, b) => a - b),
      household: data.household.sort((a, b) => a - b),
      channel: data.channel.sort((a, b) => a - b),
      lat: data.storeLat,
      lon: data.storeLon,
      priority,
    }
    delete formData.storeLat
    delete formData.storeLon
    delete formData.storeAddress
    delete formData.storeAddressKey
    delete formData.storeKey
    delete formData.storePostalCode
    delete formData.storeTel

    await appDispatch({
      type: 'updateRecommendForm',
      payload: {
        ...data,
        priority,
      },
    })

    goMapSection(formData)
  }

  const wrapperCx = cx({
    hidden: step !== 0,
    'flex flex-col mx-auto': step === 0,
  })

  const category = methods.watch('category')
  methods.register('category', { required: true })
  const categoryOption = RECOMMEND_FORM.category.find((item) => item.value === category)

  const subcategory = methods.watch('subcategory')
  methods.register('subcategory')
  const subcategoryOption = (!category || category === '1') ? null : SUBCATEGORY[category].find((item) => item.value === subcategory)

  // const isFood = [1, 2, 3, 4].includes(category)

  const getPriorityItem = (value) => {
    if (!value) return null
    return RECOMMEND_FORM.priority.find((item) => item.value === value)
  }

  const onChangePriority = (data, index) => {
    const newPriority = [...priority]
    const existIndex = newPriority.findIndex((item) => item === data.value)
    if (existIndex !== -1) {
      newPriority[existIndex] = null
    }
    newPriority[index] = data.value
    setPriority(newPriority)
  }

  return (
    <div className={wrapperCx}>
      <FormProvider {...methods}>
        <form className="space-y-12" onSubmit={methods.handleSubmit(onSubmit)}>
          <FormBox label="매장 위치" required>
            <InputAddress mode="light" />
          </FormBox>
          <FormBox label="업종" required>
            <Select
              name="category"
              value={categoryOption}
              onChange={(data) => methods.setValue('category', data.value, { shouldValidate: true, shouldDirty: true })}
              options={RECOMMEND_FORM.category}
            />
          </FormBox>
          {category && category !== '1' && (
            <FormBox label="하위 업종">
              <Select
                name="subcategory"
                value={subcategoryOption}
                onChange={(data) => methods.setValue('subcategory', data.value)}
                options={SUBCATEGORY[category]}
              />
            </FormBox>
          )}
          <FormBox label="광고대상 연령">
            <CheckboxGroup name="age" options={RECOMMEND_FORM.age} />
          </FormBox>
          <FormBox label="광고대상 성별">
            <div className="flex space-x-4">
              {RECOMMEND_FORM.gender.map((item) => (
                <FormRadiobox
                  key={item.value}
                  className="font-[500]"
                  name="gender"
                  value={item.value}
                >
                  {item.label}
                </FormRadiobox>
              ))}
            </div>
          </FormBox>
          <FormBox label="광고대상 가구">
            <CheckboxGroup name="household" options={RECOMMEND_FORM.household} />
          </FormBox>
          <FormBox label="광고희망범위">
            <div className="flex space-x-4">
              {RECOMMEND_FORM.distance.map((item) => (
                <FormRadiobox
                  key={item.value}
                  className="font-[500]"
                  name="distance"
                  value={item.value}
                >
                  {item.label}
                </FormRadiobox>
              ))}
            </div>
          </FormBox>
          <FormBox label="소득수준">
            <div className="flex space-x-4">
              {RECOMMEND_FORM.salary.map((item) => (
                <FormRadiobox
                  key={item.value}
                  className="font-[500]"
                  name="highSalary"
                  value={item.value}
                >
                  {item.label}
                </FormRadiobox>
              ))}
            </div>
          </FormBox>
          <div className="flex flex-col items-start gap-3">
            <div className="self-stretch flex justify-between">
              <h3 className="min-w-[140px] text-textPrimary text-lg mb-2">
                추천 우선순위
              </h3>
              <div className="flex items-center">
                <img src={infoIcon} alt="info" />
                <span className="text-textTertiary text-sm ml-2">선택한 우선순위에 따라 광고지역을 추천해드립니다.</span>
              </div>
            </div>
            <div className="flex-1 mlg:flex-auto w-full">
              <div className="flex flex-wrap gap-4">
                {priority.map((item, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <span className="text-textPrimary text-lg">
                      {index + 1}
                      순위
                    </span>
                    <Select
                      className="min-w-[160px]"
                      name={`priority${index}`}
                      value={getPriorityItem(item)}
                      onChange={(data) => onChangePriority(data, index)}
                      options={RECOMMEND_FORM.priority}
                      defaultValue={RECOMMEND_FORM.priority[0]}
                      placeholder="선택하세요"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* {isFood && (
            <FormBox label="기존 광고경험">
              <CheckboxGroup name="prevMarketing" options={RECOMMEND_FORM.prevMarketing} exclusive="none" />
            </FormBox>
          )} */}
          <div className="flex flex-row justify-center space-x-4">
            <button
              type="submit"
              className="primary font-bold min-w-[240px]"
              disabled={
                (!appState.recommendForm && !isDirty) || !isValid
              }
            >
              추천받기
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default RecommendForm
