import React from 'react'
import { useHistory } from 'react-router-dom'
import HoverVideoPlayer from 'react-hover-video-player'
import moment from 'moment'
import cx from 'classnames'

import {
  // convertCompanyTel,
  getCompanySectorText,
  getPromotionText,
  getThumbnail,
} from '../../../services/utils'
import emptyMedia from '../../../assets/icons/empty-media.svg'
import iconLink from '../../../assets/icons/icon-outlined-application-select-medium.svg'
//
const localStoragekey = 'gallery-detail-count'
//
const PostSnippetWithVideo = ({
  data, authModalOpen, needAuth, checkAuth, saveHistory,
}, ref) => {
  const history = useHistory()
  const hoverRef = React.useRef(null)
  const timeoutRef = React.useRef(null)
  //
  const countUp = React.useCallback(() => {
    const galleryDetailCountObj = localStorage.getItem(localStoragekey)
    const today = moment().format('YYYY-MM-DD')
    if (!galleryDetailCountObj) {
      const date = today
      const count = 1
      localStorage.setItem(localStoragekey, JSON.stringify({ date, count }))
    } else {
      const { date, count } = JSON.parse(galleryDetailCountObj)
      if (date !== today) {
        localStorage.setItem(localStoragekey, JSON.stringify({ date: today, count: 1 }))
      } else {
        localStorage.setItem(localStoragekey, JSON.stringify({ date, count: count + 1 }))
      }
    }
  }, [])
  //
  const onHoverStart = React.useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      countUp()
      const isAuth = checkAuth()
      if (!isAuth) {
        authModalOpen()
      }
    }, 3000)
  }, [countUp])
  //
  const onHoverEnd = React.useCallback(() => {
    clearTimeout(timeoutRef.current)
  }, [])
  //
  const onClick = React.useCallback(() => {
    const isAuth = checkAuth()
    if (isAuth) {
      countUp()
      saveHistory()
      history.push(`/community/${data._id}`, { galleryId: data._id })
    } else {
      authModalOpen()
    }
  }, [countUp, checkAuth, authModalOpen, saveHistory])

  const onError = (e) => {
    e.target.onerror = null
    e.target.src = emptyMedia
  }
  //
  const thumbnailUrl = getThumbnail(data.videoUrl)
  //
  const hoverClassName = cx('flex flex-col transition-opacity delay-250', !needAuth && 'group-hover:opacity-[0]')
  //
  return (
    <div ref={ref} className="post-paper relative group cursor-pointer" onClick={onClick}>
      <div className={hoverClassName}>
        <div className="thumbnail relative aspect-video">
          <img className="w-full" alt="" src={thumbnailUrl} onError={onError} />
          {(data.duration !== undefined && data.duration !== null && data.duration > 0) && (
            <div className="absolute bottom-2 right-2 px-2 py-0.5 text-xs text-white font-bold rounded-full bg-[#333] opacity-70">
              {`${data.duration}s`}
            </div>
          )}
        </div>
        <div className="flex justify-between max-w-full mt-[9px] mb-[4px] overflow-hidden">
          <div className="flex items-center gap-2">
            <span className="font-bold text-base m-0 truncate xxs:text-sm md:text-md lg:text-sm">
              {data.companyName}
            </span>
            <span className="text-xs">{getCompanySectorText(data.companySector)}</span>
          </div>
          {data.promotionVisible !== false && data.promotionLink && (
            <a href={data.promotionLink} target="_blank" rel="noreferrer">
              <img className="mr-0 ml-2 min-w-[24px]" src={iconLink} alt="link" />
            </a>
          )}
        </div>
        <div className="flex flex-col text-xs md:text-sm lg:text-xs font-medium text-textSecondary">
          {/* <span className="line-clamp-1">{`${data.storeAddress}`}</span> */}
          <div className="flex mt-1">
            {data.promotionVisible !== false && data.promotionType && data.promotionValue && (
              <span className="flex-grow text-main mr-1 font-bold line-clamp-1">
                {getPromotionText(data.promotionType, data.promotionValue)}
              </span>
            )}
            {/* {data.storeTel &&
              <span className="min-w-[100px]">{convertCompanyTel(data.storeTel)}</span>} */}
          </div>
        </div>
      </div>
      {!needAuth && (
        <div
          ref={hoverRef}
          className="hover-video-view absolute inset-0 flex flex-col z-10 bg-white group-hover:visible group-hover:inset-[-30px] group-hover:rounded-lg group-hover:border border-[#ccc] shadow-lg transition-all duration-300"
        >
          <div className="aspect-video">
            <HoverVideoPlayer
              className="w-full h-full"
              videoSrc={data.videoUrl}
              muted={false}
              onHoverStart={onHoverStart}
              onHoverEnd={onHoverEnd}
              // pausedOverlay={(
              //   <img
              //     src={thumbnailUrl}
              //     alt=""
              //     style={{
              //       width: '100%',
              //       height: '100%',
              //       objectFit: 'cover',
              //     }}
              //   />
              // )}
              loadingOverlay={(
                <div className="flex flex-center w-full h-full bg-white">
                  <div className="video-loading" />
                </div>
              )}
              hoverTarget={() => hoverRef.current}
            />
          </div>
          <div className="flex justify-between max-w-full mt-[9px] mb-[4px] min-h-[24px] overflow-hidden group-hover:px-4 transition-all">
            <div className="flex items-center gap-2">
              <span className="font-bold text-base m-0 truncate xxs:text-sm md:text-md lg:text-sm">
                {data.companyName}
              </span>
              <span className="text-xs">{getCompanySectorText(data.companySector)}</span>
            </div>
            {data.promotionVisible !== false && data.promotionLink && (
              <a href={data.promotionLink} target="_blank" rel="noreferrer">
                <img className="mr-0 ml-2 min-w-[24px]" src={iconLink} alt="link" />
              </a>
            )}
          </div>
          <div className="flex flex-col text-xs md:text-sm lg:text-xs font-medium text-textSecondary group-hover:px-4 group-hover:pb-4 transition-all">
            {/* <span className="line-clamp-1">{`${data.storeAddress}`}</span> */}
            <span className="mt-1">
              {data.promotionVisible !== false && data.promotionType && data.promotionValue && (
                <span className="flex-grow text-main mr-1 font-bold line-clamp-1">
                  {getPromotionText(data.promotionType, data.promotionValue)}
                </span>
              )}
              {/* {data.storeTel && <span>{convertCompanyTel(data.storeTel)}</span>} */}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default React.forwardRef(PostSnippetWithVideo)
