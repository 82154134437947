import React from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'

import { useSnackbar } from 'notistack'
import { createFranchiseCampaign } from '../../../services/api/media'
import useTextBox from '../../../hooks/useTextBox'
import AppContext from '../../../services/context'

const useTeamNameModal = ({ noticeId, franchiseBudget, headOfficeBudget }, callback) => {
  const { appDispatch } = React.useContext(AppContext)
  const history = useHistory()
  const [cookies] = useCookies(['jwt_token'])
  const { enqueueSnackbar } = useSnackbar()

  const [isModalShown, setIsModalShown] = React.useState(false)
  const { textValue, setTextValue, handleChange } = useTextBox('')

  const openModal = (name) => {
    setTextValue(name)
    setIsModalShown(true)
  }

  const closeModal = () => {
    setIsModalShown(false)
  }

  const onClickModalDone = async () => {
    if (!textValue) {
      enqueueSnackbar('팀명을 입력해주세요.', { variant: 'error' })
      return
    }

    const result = await createFranchiseCampaign(cookies.jwt_token, {
      franchiseTeamName: textValue,
      franchiseNotice: noticeId,
      franchiseBudget,
      headOfficeBudget,
    })

    if (!result) return
    await appDispatch({ type: 'updateAdCampaign', payload: result })
    history.push('/portal/media/create/1')
    if (callback) callback()
  }

  return {
    isModalShown,
    openModal,
    closeModal,
    onClickModalDone,
    textValue,
    handleChange,
  }
}

export default useTeamNameModal
