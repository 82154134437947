import React from 'react'
import { Table, Accordion } from 'react-bootstrap'
import { useCookies } from 'react-cookie'

import MyPageLayout from '../../layouts/myPageLayout'
import { getInvoices } from '../../services/api'
import { formatDate, formatNumber } from '../../services/utils'
//
const methodNames = {
  bankbook: '무통장입금',
  skpay: '11pay',
}
//
const Invoice = () => {
  const [cookies] = useCookies(['jwt_token'])
  const [data, setData] = React.useState([])
  //
  React.useEffect(() => {
    (async () => {
      const result = await getInvoices(cookies.jwt_token)
      if (!result) return
      result.sort((a, b) => ((a.updatedAt || '1970-01-01') < (b.updatedAt || '1970-01-01') ? 1 : -1))
      await setData(result)
    })()
  }, [cookies.jwt_token])
  //
  return (
    <MyPageLayout navigationTitle="결제 내역">
      <div className="header">
        <h3>결제내역</h3>
      </div>
      <div className="invoice-page">
        <Table responsive>
          <thead>
            <tr>
              <th>광고이름</th>
              <th>결제금액</th>
              <th>결제방법</th>
              <th>결제날짜</th>
              {/* <th>{"영수증"}</th> */}
            </tr>
          </thead>
          <tbody style={{ borderWidth: 0 }}>
            {data.length > 0
              && data.map((item, index) => (
                <tr key={index}>
                  <td>{item.campaign?.campaignName}</td>
                  <td>{item.price}</td>
                  {item.method === 'bankbook' && <td>무통장입금</td>}
                  {item.method === 'skpay' && <td>11pay</td>}
                  {item.method === 'inicis' && <td>이니시스</td>}
                  {item.method === 'event' && <td>프로모션</td>}
                  <td>{formatDate(item.updatedAt, '.')}</td>
                </tr>
              ))}
            {data.length === 0 && (
              <tr className="table-empty">
                <td colSpan="4">결제 내역이 없습니다</td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="block lg:hidden">
          <Accordion>
            {data.map((item) => (
              <Accordion.Item key={item._id} eventKey={item._id}>
                <Accordion.Header>
                  <div className="flex flex-col">
                    <span
                      style={{
                        color: 'var(--text-primary)',
                        fontWeight: 700,
                        marginBottom: 10,
                      }}
                    >
                      {item.campaign?.campaignName}
                    </span>
                    <span>{formatDate(item.updatedAt, '.')}</span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="flex justify-between line-item">
                      <span>결제방법</span>
                      <span>{methodNames[item.method]}</span>
                    </div>
                    <div className="flex justify-between line-item">
                      <span>결제금액</span>
                      <span>{`${formatNumber(item.price)}원`}</span>
                    </div>
                    {/* <div>
                      <span>{"영수증"}</span>
                      <span>{"영수증보기"}</span>
                    </div> */}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          {data.length === 0 && (
            <div className="empty">
              <p className="fir">결제 내역이 없습니다</p>
            </div>
          )}
        </div>
      </div>
    </MyPageLayout>
  )
}

export default Invoice
