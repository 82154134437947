import React from 'react'
import { useCookies } from 'react-cookie'

import { ContentModal } from '../../../components/modals'
import { patchCampaign, updateAsset } from '../../../services/api'
import useTextBox from '../../../hooks/useTextBox'

const useChangeNameModal = (type, callback) => {
  const isCampaign = type === 'campaign'
  const [targetId, setTargetId] = React.useState(null)
  const [isModalShown, setIsModalShown] = React.useState(false)
  const [cookies] = useCookies(['jwt_token'])
  const { textValue, setTextValue, handleChange } = useTextBox('')

  const onClickChangeName = (id, name) => {
    setTargetId(id)
    setTextValue(name)
    setIsModalShown(true)
  }

  const onClickModalDone = async () => {
    const func = isCampaign ? patchCampaign : updateAsset
    const key = isCampaign ? 'campaignName' : 'assetName'
    await func(cookies.jwt_token, targetId, {
      [key]: textValue,
    })
    setIsModalShown(false)
    if (callback) callback(targetId, textValue)
  }

  const renderChangeNameModal = () => (
    <ContentModal
      title={`${isCampaign ? '광고' : '소재'} 이름 변경하기`}
      isShown={isModalShown}
      onClose={onClickModalDone}
    >
      <div className="textbox-wrapper w-full">
        <input
          className="w-full"
          type="text"
          value={textValue}
          onChange={handleChange}
        />
      </div>
    </ContentModal>
  )

  return { onClickChangeName, renderChangeNameModal }
}

export default useChangeNameModal
