import React from 'react'
import { Carousel } from 'react-bootstrap'
import leftIcon from '../../../assets/icons/icon-outlined-directional-left-circle.svg'
import rightIcon from '../../../assets/icons/icon-outlined-directional-right-circle.svg'
//
const TutorialCarousel = ({ data, onClickLastArrow }, forwardRef) => {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const onChange = async (e, state) => {
    if (state) {
      if (onClickLastArrow && activeIndex === data.length - 1) {
        if (onClickLastArrow('right')) {
          setActiveIndex(0)
        }
        return
      }
      setActiveIndex(Math.abs((activeIndex + 1) % data.length))
    } else {
      if (onClickLastArrow && activeIndex === 0) {
        if (onClickLastArrow('left')) {
          setActiveIndex(0)
        }
        return
      }
      setActiveIndex(Math.abs((data.length + activeIndex - 1) % data.length))
    }
  }
  //
  const handleSelect = async (selectedIndex) => {
    await setActiveIndex(Math.abs(selectedIndex % data.length))
  }
  //
  React.useImperativeHandle(forwardRef, () => ({
    //
    moveNext: async () => onChange(null, true),
  }))
  //
  return (
    <div className="video-carousel relative">
      <div className="flex flex-row justify-center">
        <div className="hidden justify-center lg:flex arrow-wrapper">
          <button
            onClick={async (e) => onChange(e, false)}
            style={{ padding: 0, margin: 0 }}
          >
            <img src={leftIcon} alt="left" style={{ margin: 0 }} />
          </button>
        </div>
        <Carousel
          className="flex-grow"
          style={{ maxWidth: 800 }}
          controls={false}
          indicators={false}
          activeIndex={activeIndex}
          onSelect={handleSelect}
          pause="hover"
          interval={null}
        >
          {data.map((item, index) => (
            <Carousel.Item className="relative" key={index}>
              <div
                className="flex justify-center tutorial-image-wrapper w-full"
                onClick={(e) => {
                  onChange(e, true)
                }}
              >
                <img className="object-contain" src={item} alt="tutorial" />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>

        <div className="hidden justify-center lg:flex arrow-wrapper">
          <button
            onClick={async (e) => onChange(e, true)}
            style={{ padding: 0, margin: 0 }}
          >
            <img src={rightIcon} alt="right" style={{ margin: 0 }} />
          </button>
        </div>
      </div>
      <div className="indicators w-full flex flex-col items-center">
        <div>
          {data.map((item, index) => (
            <span
              key={index}
              className={`dot${activeIndex === index ? ' active' : ''}`}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default React.forwardRef(TutorialCarousel)
