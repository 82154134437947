import React, { useCallback, useState } from 'react'
import { debounce } from 'lodash'
import cx from 'classnames'

const Slider = ({
  className, value, setValue, step = 5, getLabel = (val) => `${val}`,
}) => {
  const [uiValue, setUIValue] = useState(1)

  React.useEffect(() => {
    setUIValue(value)
  }, [value])

  const debouncedSetValue = useCallback(
    debounce((newValue) => {
      setValue(newValue)
    }, 500),
    [setValue],
  )

  const handleSliderChange = (event) => {
    const newValue = parseInt(event.target.value, 10)
    setUIValue(newValue)
    debouncedSetValue(newValue)
  }

  const wrapperCx = cx('flex items-center px-4 space-x-2 bg-white rounded-full', className)

  return (
    <div className={wrapperCx}>
      <span className="text-textSecondary text-sm">{getLabel(1)}</span>
      <input type="range" min={1} max={step} value={uiValue} onChange={handleSliderChange} className="w-full" />
      <span className="text-textSecondary text-sm">{getLabel(step)}</span>
      {/* <div className="flex justify-between">
        <span>{getLabel(1)}</span>
        <span>{getLabel(2)}</span>
        <span>{getLabel(3)}</span>
        <span>{getLabel(4)}</span>
        <span>{getLabel(5)}</span>
      </div> */}
    </div>
  )
}

export default Slider
