import React from 'react'

export default function useCommentWritter() {
  const [writter, setWritter] = React.useState('')

  const onChange = (e) => {
    setWritter(e.target.value)
  }

  const initWritterInput = () => {
    setWritter('')
  }

  const renderInput = () => (
    <div className="flex items-center comment-writter">
      <span className="mr-2">작성자 </span>
      <input value={writter} onChange={onChange} maxLength={15} />
    </div>
  )

  return [writter, renderInput, initWritterInput]
}
