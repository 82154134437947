import React from 'react'
import Carousel from 'react-multi-carousel'

function importAll(r) {
  return r.keys().map(r)
}
const images = importAll(
  require.context('../../../assets/logos', false, /\.(png|jpe?g|svg|webp)$/),
)

const group1 = images.splice(0, images.length / 2)
const group2 = images.splice(images.length / 2, images.length)

const LogosCarousel = () => (
  <div className="logos-carousel">
    <div style={{ marginBottom: '32px' }}>
      <Carousel
        draggable={false}
        infinite
        arrows={false}
        additionalTransfrom={0}
        autoPlay
        autoPlaySpeed={1}
        centerMode={false}
        focusOnSelect={false}
        keyBoardControl
        partialVisible={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        showDots={false}
        swipeable={false}
        slidesToSlide={1}
        transitionDuration={3000}
        minimumTouchDrag={80}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 570,
            },
            items: 10,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 559,
              min: 0,
            },
            items: 3,
            partialVisibilityGutter: 40,
          },
        }}
      >
        {group1.map((item, index) => (
          <div key={index} style={{ padding: ' 0 20px' }}>
            <img src={item} alt="logo" />
          </div>
        ))}
      </Carousel>
    </div>
    <Carousel
      draggable={false}
      infinite
      arrows={false}
      additionalTransfrom={0}
      autoPlay
      autoPlaySpeed={1}
      centerMode={false}
      focusOnSelect={false}
      keyBoardControl
      partialVisible={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      showDots={false}
      swipeable={false}
      slidesToSlide={1}
      transitionDuration={3000}
      minimumTouchDrag={80}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 10,
          partialVisibilityGutter: 40,
        },
      }}
    >
      {group2.map((item, index) => (
        <div key={index} style={{ padding: ' 0 20px' }}>
          <img src={item} alt="logo" />
        </div>
      ))}
    </Carousel>
  </div>
)

export default LogosCarousel
