import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import chroma from 'chroma-js'

import { formatNumber } from '../../../services/utils'

const GraphWrapper = ({
  cat, data, barColor, isHidden,
}) => {
  let margin
  let layout
  let tickRotation
  let colors
  const additionalProp = {}
  if (['regions', 'pp', 'timeseries'].includes(cat)) {
    layout = 'vertical'
    margin = {
      top: 0, right: 10, bottom: 70, left: 50,
    }
    tickRotation = -60
    additionalProp.width = 1440
  } else {
    layout = 'horizontal'
    margin = {
      top: 0, right: 10, bottom: 30, left: 100,
    }
    tickRotation = 0
    const colorScale = chroma
      .scale(['#65BD63', '#2575B7', '#F776A6'])
      .colors(data.data.length)
    colors = (item) => colorScale[item.index]
    if (cat === 'product') {
      additionalProp.height = 720
    }
  }
  const aClassName = isHidden ? '' : ' hidden'
  let style = {}
  if (additionalProp.height) {
    style = { height: `${additionalProp.height}px` }
  }
  return (
    <div className={`statistic-item${aClassName}`}>
      <h3>{data.name}</h3>
      {data.data && data.data.length ? (
        <>
          <div key={cat} className="graph-wrapper" style={style}>
            <ResponsiveBar
              {...additionalProp}
              data={data.data}
              keys={['value']}
              indexBy="name"
              margin={margin}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              valueFormat={{ format: '', enabled: false }}
              colors={colors || barColor}
              layout={layout}
              defs={[
                {
                  id: 'dots',
                  type: 'patternDots',
                  background: 'inherit',
                  color: '#38bcb2',
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#eed312',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              fill={[
                {
                  match: {
                    id: 'fries',
                  },
                  id: 'dots',
                },
                {
                  match: {
                    id: 'sandwich',
                  },
                  id: 'lines',
                },
              ]}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation,
                format(value) {
                  if (cat === 'regions') {
                    const tempArr = value.split(' ')
                    return tempArr[tempArr.length - 1]
                  }
                  return value
                },
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            />
          </div>
          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-row justify-between desc">
              <span>
                가장 높은
                {data.name}
              </span>
              <span>
                {
                  (data.data && data.data.length) && data.data.reduce((a, b) => {
                    if (a.value >= b.value) return a
                    return b
                  }).name
                }
              </span>
            </div>
            <div className="flex flex-row justify-between desc">
              <span>노출 횟수</span>
              <span>
                {formatNumber(data.data.reduce((a, b) => a + b.value, 0))}
              </span>
            </div>
          </div>
        </>
      ) : (
        <Empty />
      )}
    </div>
  )
}

const Empty = () => (
  <div className="empty">
    <p className="fir">데이터 조회 실패</p>
    <p className="sec">
      <span>이 카테고리에 해당되는 내용이 없습니다.</span>
    </p>
  </div>
)

export default GraphWrapper
