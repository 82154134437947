import React from 'react'
import { CloseButton, Modal } from 'react-bootstrap'

import cx from 'classnames'
import warningIcon from '../assets/icons/icon-outlined-suggested-exclamation-circle-xlarge.svg'
import successIcon from '../assets/icons/icon-outlined-suggested-success-circle-xlarge.svg'
import infoIcon from '../assets/icons/icon-outlined-suggested-info-circle-xlarge.svg'
import errorIcon from '../assets/icons/icon-outlined-suggested-close-circle-xlarge.svg'
import iconClose from '../assets/icons/icon-filled-suggested-close-circle.svg'

const MessageModal = ({
  type, isShown, onClose, isStatic = false, children,
}) => {
  //
  let src = null
  switch (type) {
      case 'success':
        src = successIcon
        break
      case 'error':
        src = errorIcon
        break
      case 'warning':
        src = warningIcon
        break
      default:
        src = infoIcon
        break
  }
  return (
    <Modal show={isShown} onHide={onClose} keyboard={false} size="lg" backdrop={isStatic ? 'static' : true} centered>
      <Modal.Body>
        <div
          className="w-full flex flex-col justify-items-stretch items-center"
          style={{
            padding: '48px 48px 36px',
          }}
        >
          <img src={src} alt="warning" style={{ marginBottom: '16px' }} />
          <div className="headline whitespace-pre-wrap" style={{ marginBottom: '40px' }}>
            {children}
          </div>
          <button className="primary large w-full cta-button" onClick={onClose}>
            확인
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export const ContentModal = ({
  onClose,
  title,
  titleClassName = '',
  isShown,
  size = 'lg',
  dialogClassName = '',
  bodyClassName = '',
  isStatic = false,
  style = { padding: '48px 48px 36px' },
  buttonStyle = {},
  disabled = false,
  buttonText = '확인',
  buttonVisible = true,
  buttonVariant = 'primary',
  onClickButton,
  children,
}) => (
  <Modal
    show={isShown}
    onHide={onClose}
    keyboard={false}
    size={size}
    dialogClassName={dialogClassName}
    backdrop={isStatic ? 'static' : true}
    centered
  >
    {title && (
      <Modal.Header className="justify-items-stretch items-center">
        <Modal.Title className={titleClassName}>{title}</Modal.Title>
        <CloseButton onClick={onClose} />
      </Modal.Header>
    )}
    <Modal.Body>
      <div className="flex flex-col">
        <div
          className={cx('w-full flex flex-col flex-grow justify-items-stretch items-center', bodyClassName)}
          style={style}
        >
          {children}
        </div>
        {buttonVisible && (
          <button
            className={cx('larger w-full cta-button', {
              primary: buttonVariant === 'primary',
              brand: buttonVariant === 'brand',
            })}
            onClick={() => {
              if (onClickButton) onClickButton()
              onClose()
            }}
            style={{ marginBottom: 0, ...buttonStyle }}
            disabled={disabled}
          >
            {buttonText}
          </button>
        )}
      </div>
    </Modal.Body>
  </Modal>
)

export const CustomModal = ({
  title, style, dialogClassName, children,
}) => (
  <div className="fixed inset-0 flex flex-center z-50">
    <div className="fixed inset-0 bg-[#dcdcdc] opacity-[.37]" />
    <div className={cx('flex flex-col items-center p-8 bg-white drop-shadow-md', dialogClassName)} style={style}>
      {title && <h3 className="text-lg mb-4">{title}</h3>}
      {children}
    </div>
  </div>
)

export const TwoButtonModal = ({
  title,
  width = '90%',
  maxWidth = 900,
  primaryText,
  secondaryText,
  onClickPrimary,
  onClickSecondary,
  onClose,
  buttonVariant = 'primary',
  children,
}) => (
  <CustomModal
    title={title}
    style={{
      width,
      maxWidth,
      position: 'relative',
    }}
  >
    {children}
    <div className="relative flex flex-col-reverse flex-center w-full mt-[40px] lg:flex-row gap-4">
      <button
        className={cx(
          'flex-1 max-w-[264px] w-full secondary large',
          buttonVariant === 'brand' && '!border-brand !text-brand',
        )}
        onClick={onClickSecondary}
      >
        {secondaryText}
      </button>
      <button
        className={cx('flex-1 max-w-[264px] w-full large', {
          primary: buttonVariant === 'primary',
          brand: buttonVariant === 'brand',
        })}
        onClick={onClickPrimary}
      >
        {primaryText}
      </button>
    </div>
    <img className="absolute top-4 right-4 cursor-pointer" src={iconClose} alt="x" onClick={onClose} />
  </CustomModal>
)

export default MessageModal
