import React from 'react'
import { useSnackbar } from 'notistack'
import { useCookies } from 'react-cookie'

import { createPromotion, patchCampaign, updatePromotion } from '../../../services/api'
import AppContext from '../../../services/context'

const useGiftInfoModal = (methods) => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const [isGiftModalShown, setIsGiftModalShown] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [cookies] = useCookies(['jwt_token'])

  const isBusiness = Number(appState.userInfo?.userType) === 1

  const business_id = isBusiness ? methods.getValues('clientBusinessId') : appState.userInfo?.company?.companyNumber

  React.useEffect(() => {
    // register values with validations...
  }, [])

  const openGiftModal = () => {
    setIsGiftModalShown(true)
  }
  //
  const onCloseGiftModal = () => {
    setIsGiftModalShown(false)
    methods.setValue('ad_campaign_gift', 'false')
    enqueueSnackbar('사은품 신청을 원하시면 필수 정보를 입력해주세요', { variant: 'error' })
  }
  //
  const onClickRegister = async (data) => {
    const [ad_campaign_id, storeImages] = methods.getValues(['ad_campaign_id', 'storeImages'])

    let clientBusinessId
    if (isBusiness) {
      methods.setValue('clientBusinessId', data.business_id)
      clientBusinessId = data.business_id
    }
    methods.setValue('companyName', data.companyName)
    methods.setValue('companySector', data.companySector)
    methods.setValue('storeAddress', data.storeAddress)
    methods.setValue('storeAddressDetail', data.storeAddressDetail)
    methods.setValue('storeAddressKey', data.storeAddressKey)
    methods.setValue('storeLat', data.storeLat)
    methods.setValue('storeLon', data.storeLon)
    methods.setValue('storePostalCode', data.storePostalCode)
    methods.setValue('storeTel', data.storeTel)
    methods.setValue('p_info_consent', true)
    setIsGiftModalShown(false)

    if (appState.adCampaign.step1?.galleryId) {
      await updatePromotion(cookies.jwt_token, {
        ...data,
        storeId: appState.adCampaign.step1?.storeId,
        galleryId: appState.adCampaign.step1?.galleryId,
        campaignId: ad_campaign_id,
        storeImages,
      })
    } else {
      await createPromotion(cookies.jwt_token, {
        ...data,
        campaignId: ad_campaign_id,
        storeImages,
      })
    }
    if (clientBusinessId) {
      const result = await patchCampaign(
        cookies.jwt_token,
        appState.adCampaign.id,
        {
          ...appState.adCampaign.step1,
          clientBusinessId,
        },
        1,
      )
      if (!result) return
      await appDispatch({ type: 'updateAdCampaign', payload: result })
    }
  }

  return {
    isGiftModalShown,
    business_id,
    isBusiness,
    openGiftModal,
    onCloseGiftModal,
    onClickRegister,
  }
}

export default useGiftInfoModal
