import React from 'react'

const useTextBox = ({ initialValue }) => {
  const [textValue, setTextValue] = React.useState(initialValue)

  const handleChange = (e) => {
    setTextValue(e.target.value)
  }

  return { textValue, setTextValue, handleChange }
}

export default useTextBox
