import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import BasicLayout from '../../layouts/basic'
import combined1 from '../../assets/imgs/combined1.jpg'
import combined2 from '../../assets/imgs/combined2.jpg'
import combined3 from '../../assets/imgs/combined3.jpg'

const SyrupPage = () => (
  <BasicLayout className="bg-white" noFooter>
    <Container>
      <img className="w-full" src={combined1} alt="combined1" />
      <img className="w-full" src={combined2} alt="combined2" />
      <img className="w-full" src={combined3} alt="combined3" />
      <Link
        to={{
          pathname: '/consult',
          state: { category: 'iptv+syrup' },
        }}
      >
        <button className="w-full btn brand large sm:!text-[2rem] sm:!py-4 font-bold hover:!text-white !font-bold">상담 신청하기</button>
      </Link>
    </Container>
  </BasicLayout>
)

export default SyrupPage
