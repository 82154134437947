import React from 'react'

export const WhitePaper = ({
  p, s1, s2, s3, desc, src,
}) => (
  <div className="white-paper flex flex-col">
    <div className="flex-grow">
      <p>{p}</p>
      <h3>
        <span>{s1}</span>
        <br />
        <span>{s2}</span>
        <br />
        <span>{s3}</span>
      </h3>
      {!!desc && (
        <span className="text-[14px] lg:text-[16px] font-semibold text-textTertiary">{desc}</span>
      )}
    </div>
    <img src={src} alt="loc" />
  </div>
)

export const VideoPaper = ({ data, videoClassName, onEnded }) => (
  <div className="video-paper mx-2">
    <video
      className={videoClassName}
      src={data.videoURL}
      controls
      controlsList="nodownload"
      onContextMenu={(e) => e.preventDefault()}
      onEnded={onEnded}
    />
    <div className="content">
      <h4>{data.title}</h4>
      <p>{data.body}</p>
    </div>
  </div>
)

export const IconPaper = ({
  src, p, s1, s2,
}) => (
  <div className="icon-paper flex-shrink-0">
    <img src={src} alt="empty" />
    <p>{p}</p>
    <small>
      <span>{s1}</span>
      <br />
      <span>{s2}</span>
    </small>
  </div>
)
