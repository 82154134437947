/* eslint-disable react/display-name */
import React from 'react'
import {
  Navbar, Container, NavDropdown, Nav, Tooltip, OverlayTrigger,
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useLocation } from 'react-router-dom'

import logo from '../assets/imgs/btv-logo.png'
import AppContext, { initialState } from '../services/context'
import { useWindowSize } from '../services/utils'
import {
  mainNav, anonNav, authNav, franchiseNav, franchiseAdminNav,
} from '../services/navbar'
import tworldLogo from '../assets/imgs/logo_tworld.png'

const HEADER_MODE = {
  IS_ANONYMOUS: [mainNav, anonNav],
  IS_AUTHENTICATED: [mainNav, authNav],
  IS_REGISTERING: [[], anonNav],
  IS_FRANCHISE_USER: [mainNav, franchiseNav],
  IS_FRANCHISE_ADMIN: [mainNav, franchiseAdminNav],
}

const MyNavDropDown = ({
  name, width, isSelected, children,
}) => {
  const ref = React.useRef()

  const onHover = (e, isEnter) => {
    if (width > 1200) {
      if ((!isEnter && e.target.className.includes('dropdown-toggle'))
      || (isEnter && e.target.className.includes('dropdown-menu show'))) return
      ref.current?.children[0].click()
    }
  }

  return (
    <NavDropdown
      ref={ref}
      title={name}
      className="relative active"
      onMouseEnter={(e) => onHover(e, true)}
      onMouseLeave={(e) => onHover(e, false)}
      active={isSelected}
    >
      {children.map((item, index2) => (
        <LinkContainer key={index2} to={item.link}>
          <NavDropdown.Item>{item.name}</NavDropdown.Item>
        </LinkContainer>
      ))}
    </NavDropdown>
  )
}

const RenderMainNavbar = ({ mainNav: tempMainNav }) => {
  const { appDispatch } = React.useContext(AppContext)
  const [width] = useWindowSize()
  const location = useLocation()
  //
  return tempMainNav.map((navData, index) => {
    let onClick
    if (navData.link === '/community') {
      onClick = () => {
        appDispatch({ type: 'updateGalleryOptions', payload: { ...initialState.galleryOptions } })
      }
    }
    if ('children' in navData) {
      const isSelected = navData.children.some((item) => {
        if (item.link === '/notice' && location.pathname.includes('franchise')) {
          return false
        }
        return location.pathname.includes(item.link)
      })
      return (
        <MyNavDropDown
          key={index}
          name={navData.name}
          width={width}
          isSelected={isSelected}
        >
          {navData.children}
        </MyNavDropDown>
      )
    }
    if ('childLinks' in navData) {
      const isSelected = navData.childLinks.some((item) => location.pathname.includes(item))
      return (
        <LinkContainer key={index} to={navData.link} active={isSelected}>
          <Nav.Link>{navData.name}</Nav.Link>
        </LinkContainer>
      )
    }
    return (
      <LinkContainer key={index} to={navData.link} onClick={onClick}>
        <Nav.Link>{navData.name}</Nav.Link>
      </LinkContainer>
    )
  })
}

const Note = ({ data }) => (
  <OverlayTrigger
    placement="bottom"
    delay={{ show: 0, hide: 400 }}
    overlay={<Tooltip className="badge">{data.badge}</Tooltip>}
  >
    <span>{data.name}</span>
  </OverlayTrigger>
)

const Header = (props, forwardedRef) => {
  const { appState } = React.useContext(AppContext)
  const location = useLocation()
  const menus = HEADER_MODE[appState.headerMode]
  //
  return (
    <Navbar className={props.className} ref={forwardedRef} expand="xl" fixed={props.sticky ? 'top' : null}>
      <Container>
        {/**/}
        <LinkContainer to="/">
          <Navbar.Brand>
            <div className="flex flex-center">
              <img
                src={logo}
                alt="logo"
                className="d-inline-block align-top w-[150px] h-[19px] lg:w-[220px] lg:h-[28px]"
              />
            </div>
          </Navbar.Brand>
        </LinkContainer>
        {/**/}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        {/**/}
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/**/}
            <RenderMainNavbar mainNav={menus[0]} />
          </Nav>
          <Nav>
            {menus[1].map((item, index) => {
              let { link } = item
              if (item.name.includes('franchise')) {
                return (
                  <LinkContainer
                    key={item.name}
                    to={item.name === 'franchiseAdmin' ? '/franchise/notice' : '/franchise'}
                    className="hover:!text-brand"
                  >
                    <Nav.Link className="relative nav-franchise">
                      <img src={tworldLogo} alt="tworld logo" className="d-inline-block mb-0.5 w-[20px] h-[21px]" />
                      전용
                    </Nav.Link>
                  </LinkContainer>
                )
              }
              if (link === '/sign-in') {
                link = `/sign-in?previous=${encodeURIComponent(location.pathname)}`
              }
              return (
                <LinkContainer key={index} to={link} className={item.className}>
                  <Nav.Link className="relative">
                    {!item.badge && item.name}
                    {item.badge && <Note data={item} />}
                  </Nav.Link>
                </LinkContainer>
              )
            })}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
//
export default React.forwardRef(Header)
