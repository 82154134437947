// 오류를 코드로 보내는 방식을 채택하면 더 명확한 코드 작성 가능.
import React, { useMemo } from 'react'
import cx from 'classnames'

import pendingIcon from '../../../assets/icons/icon-loading3.svg'
import readyIcon from '../../../assets/icons/icon-filled-suggested-check-circle.svg'
import errorIcon from '../../../assets/icons/icon-filled-suggested-error-circle.svg'

const AssetProgressText = ({ messages, category }) => {
  const text = messages.find((message) => {
    if (category === '오디오') return message.includes('오디오 평준화')
    return message.includes(category)
  })
  const errorText = messages.find((message) => message.includes(category) && message.includes('창을 닫아'))
  const isVideoError = category === '코덱'
    && messages.some((message) => message.includes('영상에 오류가 있어' || '영상의 비디오나 오디오에 문제가 있습니다'))

  const imageSrc = useMemo(() => {
    if (isVideoError) return errorIcon
    if (!text) return pendingIcon
    if (text.includes('확인하였습니다.')) return readyIcon
    if (text.includes('모두 확인하여')) return readyIcon
    return errorIcon
  }, [text, category, isVideoError])

  return (
    <li className="flex flex-col items-start xl:flex-row">
      <div className="flex items-center w-[80px]">
        <img className="w-[20px] h-[20px]" src={imageSrc} alt="statusIcon" />
        <span
          className={cx('ml-1 font-bold', {
            'text-textSecondary': !text,
            'text-textPrimary': text,
          })}
        >
          {category}
        </span>
      </div>
      {(isVideoError || !!text) && (
        <span
          className={cx('ml-6 xl:ml-3', {
            'text-textSecondary': !text,
            'text-textPrimary': text,
          })}
        >
          {text || errorText}
        </span>
      )}
    </li>
  )
}

export default AssetProgressText
