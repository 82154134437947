import React from 'react'
import moment from 'moment'
import { Table } from 'react-bootstrap'
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs'
import { useCookies } from 'react-cookie'

import MyPageLayout from '../../layouts/myPageLayout'
import { formatNumber } from '../../services/utils'
import { getPoints } from '../../services/api'
//
const dateFormat = 'YYYY.MM.DD'
const sinceList = [
  { label: '전체', value: 0 },
  { label: '1개월', value: 1 },
  { label: '3개월', value: 3 },
  { label: '6개월', value: 6 },
  { label: '9개월', value: 9 },
  { label: '12개월', value: 12 },
  // { label: "직접 입력", value: 100 }
]
//
const Point = () => {
  const [cookies] = useCookies(['jwt_token'])
  const [data, setData] = React.useState({
    tab1: [],
    tab2: [],
    tab3: [],
  })
  const [points, setPoints] = React.useState({
    urPoints: 0,
    plPoints: 0,
    mnPoints: 0,
  })
  //
  React.useEffect(() => {
    (async () => {
      const result = await getPoints(cookies.jwt_token)
      if (!result) return
      let plPoints = 0
      let mnPoints = 0
      let remain = 0
      const tab1Data = []
      const tab2Data = []
      const tab3Data = []
      //
      result.forEach((item) => {
        remain += item.pointNum
        const tempItem = { ...item, remain }
        tab1Data.push(tempItem)
        if (tempItem.pointNum > 0) {
          plPoints += tempItem.pointNum
          tab2Data.push(tempItem)
        } else {
          mnPoints += tempItem.pointNum
          tab3Data.push(tempItem)
        }
      })
      await setPoints({
        urPoints: plPoints + mnPoints || 0,
        plPoints: plPoints || 0,
        mnPoints: mnPoints || 0,
      })
      await setData({
        tab1: tab1Data,
        tab2: tab2Data,
        tab3: tab3Data,
      })
    })()
  }, [cookies.jwt_token])

  let pointWrapperClassName = 'flex items-center justify-between'
  if (
    points.urPoints >= 10000
    || points.plPoints >= 10000
    || points.mnPoints >= 10000
  ) {
    pointWrapperClassName += ' mobile-wrapper'
  }
  //
  return (
    <MyPageLayout navigationTitle="포인트 현황" isHeaderHiddenInMobile>
      <div className="hidden header lg:block">
        <h3>포인트 현황</h3>
      </div>
      <div className="point-page">
        <div className="point-box">
          <div className="point-box-section flex flex-row">
            <div className="flex flex-col">
              <span className="title">보유 포인트</span>
              <div className={pointWrapperClassName}>
                <span className="value">{points.urPoints}</span>
                <span className="unit"> Point</span>
              </div>
            </div>
            {/**/}
            <div className="flex flex-col">
              <span className="title">적립 포인트</span>
              <div className={pointWrapperClassName}>
                <span className="value">{points.plPoints}</span>
                <span className="unit"> Point</span>
              </div>
            </div>
            {/**/}
            <div className="flex flex-col">
              <span className="title">사용 포인트</span>
              <div className={pointWrapperClassName}>
                <span className="value">{-points.mnPoints}</span>
                <span className="unit"> Point</span>
              </div>
            </div>
          </div>
          <div className="divider" />
        </div>
        {/**/}
        <div className="list-section">
          {/**/}
          <Tabs className="tabs-primary" selectedTabClassName="active">
            <TabList className="flex tab-list-primary">
              <Tab className="px-4 tab-primary lg:px-8">전체</Tab>
              <Tab className="px-4 tab-primary lg:px-8">적립</Tab>
              <Tab className="px-4 tab-primary lg:px-8">사용</Tab>
              <div className="hidden flex-grow lg:block" />
            </TabList>
            {/**/}
            <TabPanel>
              <TabPanelView data={data.tab1} />
            </TabPanel>
            <TabPanel>
              <TabPanelView data={data.tab2} />
            </TabPanel>
            <TabPanel>
              <TabPanelView data={data.tab3} />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </MyPageLayout>
  )
}
//
const TabPanelView = ({ data }) => {
  const [tableData, setTableData] = React.useState(data)
  const [selectedSince, setSelectedSince] = React.useState(0)
  const date = new Date()
  //
  const onSinceSelect = async (e, item) => {
    await setSelectedSince(item.value)
    //
    switch (item.value) {
        case 0:
          await setTableData(data)
          return
        case 1:
          date.setMonth(date.getMonth() - 1)
          break
        case 3:
          date.setMonth(date.getMonth() - 3)
          break
        case 6:
          date.setMonth(date.getMonth() - 6)
          break
        case 9:
          date.setMonth(date.getMonth() - 9)
          break
        case 12:
          date.setMonth(date.getMonth() - 12)
          break
        case 100:
        default:
          break
    }
    const list = data.filter((item2) => new Date(item2.createdAt) > date)
    await setTableData(list)
  }
  //
  React.useEffect(() => {
    setTableData(data)
  }, [setTableData, data])
  //
  return (
    <>
      <div className="flex justify-between lg:flex-col list-header">
        <p style={{ marginButtom: 40 }}>거래기간</p>
        <div
          className="hidden border-holder flex-row lg:flex"
          style={{ marginButtom: 24 }}
        >
          {sinceList.map((item, index) => (
            <button
              key={index}
              className={
                `light medium${selectedSince === item.value ? ' active' : ''}`
              }
              onClick={(e) => onSinceSelect(e, item)}
            >
              {item.label}
            </button>
          ))}
        </div>
        <div className="block lg:hidden">
          <select
            onChange={(e) => onSinceSelect(
              e,
              sinceList.find((item) => `${item.value}` === e.target.value),
            )}
            className="select-option"
            style={{ backgroundColor: 'white' }}
          >
            {sinceList.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/**/}
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>날짜</th>
            <th>적립 / 사용내역</th>
            <th>적립</th>
            <th>사용</th>
            <th>포인트 잔액</th>
          </tr>
        </thead>
        <tbody className="point-table">
          {tableData.length > 0
            && tableData.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{moment(item.createdAt).format(dateFormat)}</td>
                <td>{item.description}</td>
                <td className="pls">
                  {item.pointNum > 0 && `+${formatNumber(item.pointNum)}`}
                </td>
                <td className="mns">
                  {item.pointNum < 0 && `${formatNumber(item.pointNum)}`}
                </td>
                <td>{`${formatNumber(item.remain)} p`}</td>
              </tr>
            ))}
          {tableData.length === 0 && (
            <tr
              className="empty"
              style={{ borderLeftWidth: 2, borderBottomWidth: 0 }}
            >
              <td colSpan="6">포인트 현황이 없습니다</td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="flex flex-col lg:hidden">
        {tableData.map((item, index) => (
          <div key={index} className="flex flex-col table-mobile-items">
            <div className="flex justify-between">
              <span style={{ fontWeight: 700 }}>{item.description}</span>
              {item.pointNum > 0 ? (
                <span className="pls">{`+${formatNumber(item.pointNum)}`}</span>
              ) : (
                <span className="mns">{`${formatNumber(item.pointNum)}`}</span>
              )}
            </div>
            <div className="flex justify-between">
              <span>잔액</span>
              <span>{`${formatNumber(item.remain)} p`}</span>
            </div>
            <div>
              <span>{moment(item.createdAt).format(dateFormat)}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Point
