import React from 'react'
import { Container } from 'react-bootstrap'
import { useHistory, useLocation, Redirect } from 'react-router-dom'

import BasicLayout from '../../layouts/navigationLayout'
import congratsUser from '../../assets/imgs/congrats.svg'
import congratsAgency from '../../assets/imgs/congrats_agency.svg'
import AppContext from '../../services/context'
import { getMarketingNotiText } from '../../services/utils'

const MarketingAgreement = () => {
  const location = useLocation()
  const { marketing_subscribe, updatedAt } = location.state.agreement
  const { resultText, subText } = getMarketingNotiText(marketing_subscribe, updatedAt)
  return (
    <div className="mb-6 text-textSecondary text-xs whitespace-pre-wrap text-center">
      <span>{resultText}</span>
      <br />
      <span>{subText}</span>
    </div>
  )
}

const UserResult = () => {
  const history = useHistory()
  return (
    <>
      <img className="hero" src={congratsUser} alt="" />
      <div className="header text-center">
        <h1 className="hidden lg:block">회원가입 완료</h1>
        <p>
          <span>축하합니다! 회원가입을 성공적으로 완료했습니다</span>
          <br />
          <span>이제부터 Btv 우리동네광고의 서비스를 이용할 수 있습니다</span>
          <br />
          <br />
          {/* <span>
            첫방문을 기념하여
            {' '}
            <span className="font-black text-main">5만원 쿠폰</span>
            을 지급해드렸으니
          </span>
          <br />
          <span>지금 바로 로그인 후 사용해보세요</span> */}
          <span>서비스 이용 쿠폰이 발급되었습니다</span>
          <br />
          <span className="text-[10px] lg:text-sm">{'(마이페이지 > 쿠폰 메뉴에서 확인 가능)'}</span>
        </p>
        <MarketingAgreement />
        <button type="submit" className="w-full primary large" onClick={() => history.push('/sign-in')}>
          지금 로그인 하기
        </button>
        <button type="submit" className="w-full secondary large" onClick={() => history.push('/')}>
          메인으로 가기
        </button>
      </div>
    </>
  )
}

const AgencyResult = () => {
  const history = useHistory()
  return (
    <>
      <img className="hero" src={congratsAgency} alt="" />
      <div className="header text-center">
        <h1 className="hidden lg:block">회원가입 신청 완료</h1>
        <p style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>
          등록하신 아이디는 심사를 거쳐 가입이 완료됩니다
        </p>
        <p>
          <span>심사가 완료되면 등록하신 이메일로 알려드리겠습니다</span>
          <br />
          <span>심사가 완료된 후 서비스를 이용할 수 있습니다</span>
          <br />
          <span>관련 문의사항은 채널톡으로 알려주시면 빠른 답변 드리겠습니다</span>
        </p>
        <MarketingAgreement />
        <button type="submit" className="w-full primary large" onClick={() => history.push('/')}>
          메인으로 가기
        </button>
      </div>
    </>
  )
}

const SignUpResult = () => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const result = appState.signUpMode?.type
  const isAgency = result === 'AGENCY'
  const Content = isAgency ? AgencyResult : UserResult
  //
  React.useEffect(
    () => () => {
      appDispatch({ type: 'updateSignUpMode', payload: { type: 'reset' } })
    },
    [appDispatch],
  )
  //
  if (!result) return <Redirect to="/" />
  //
  return (
    <BasicLayout
      navigationTitle={isAgency ? '회원가입 신청 완료' : '회원가입 완료'}
      footerVisible={false}
      backButtonVisible={false}
    >
      <Container className="auth-paper sign-up-result" fluid="sm">
        <div className="flex flex-col justify-items-stretch items-center">
          <Content />
        </div>
      </Container>
    </BasicLayout>
  )
}

export default SignUpResult
