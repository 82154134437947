import React from 'react'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Container } from 'react-bootstrap'

import NavigationLayout from '../../layouts/navigationLayout'
import GraphWrapper from './components/GraphWrapper'
import { FormDateRangeBox } from '../../components/dateRangeBox'
import Menu from '../../components/menu'
import { reportMenu } from '../../services/menus'
import { fetchStatistics } from '../../services/api'
import LoadingIndicator from '../../components/LoadingIndicator'

const cats = [
  {
    title: '전체',
    value: 'all',
  },
  {
    title: '지역',
    value: 'regions',
    barColor: '#F77272',
  },
  {
    title: '채널',
    value: 'pp',
    barColor: '#8BCE75',
  },
  {
    title: '날짜',
    value: 'timeseries',
    barColor: '#83BAE6',
  },
  {
    title: '성별',
    value: 'gender',
    barColor: { scheme: 'nivo' },
    // barColor: item => {
    //   if (typeof item?.data?.name === "string") {
    //     return item.data.name.includes('남') ? '#C5E6F7' : '#FDE2E9'
    //   }
    //   return { scheme: 'nivo' }
    // }
  },
  {
    title: '세대',
    value: 'generation',
    barColor: { scheme: 'nivo' },
  },
  {
    title: '관심사',
    value: 'interest',
    barColor: { scheme: 'nivo' },
  },
  {
    title: '컨텐츠',
    value: 'contents',
    barColor: { scheme: 'nivo' },
  },
  {
    title: '관심 상품',
    value: 'product',
    barColor: { scheme: 'nivo' },
  },
]

const dateFormName = 'ad_campaign_dates'

const Report = () => {
  const [selectedCat, setSelectedCat] = React.useState('all')
  const [grapthDatas, setGrapthDatas] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(true)
  const [isFirst, setIsFirst] = React.useState(true)
  //
  const location = useLocation()
  //
  const { setValue, ...methods } = useForm({
    criteriaMode: 'all',
    mode: 'onSubmit',
    defaultValues: {
      [dateFormName]: [null, null],
    },
  })
  const ad_campaign_dates_value = useWatch({
    name: dateFormName,
    control: methods.control,
  })

  React.useEffect(
    () => {
      (async () => {
        await setIsLoading(true)
        let [startDate, endDate] = ad_campaign_dates_value
        if (startDate) startDate = moment(startDate).format('YYYY-MM-DD')
        if (endDate) endDate = moment(endDate).format('YYYY-MM-DD')
        try {
          const result = await fetchStatistics(
            location.state?._id || 17242,
            startDate,
            endDate,
          )
          await setIsLoading(false)
          setGrapthDatas(result.data)
          if (isFirst) {
            setValue(
              dateFormName,
              [new Date(result.startAt), new Date(result.endAt)],
            )
            setIsFirst(false)
          }
        } catch (e) {
          console.log(e)
        }
        setIsLoading(false)
      })()
    },
    [location.state?._id, ad_campaign_dates_value],
  )

  const onClickCat = (cat) => {
    setSelectedCat(cat)
  }

  const onSubmit = () => {
    // fetchDatas()
  }

  const activeLink = location.pathname

  return (
    <NavigationLayout navigationTitle="리포트">
      <div className="flex flex-grow flex-col relative report bg-white">
        <div className="page-headline">
          <Container>
            <h1>광고 리포트보기</h1>
          </Container>
        </div>
        <div className="flex flex-row container">
          <div className="side-menu">
            <Menu
              items={reportMenu}
              activeLink={activeLink}
              state={location.state}
            />
          </div>
          <div className="content relative">
            <div className="header hidden xl:block">
              <h3>리포트</h3>
            </div>
            <FormProvider setValue={setValue} {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="flex date-wrapper">
                  <FormDateRangeBox
                    placeholder="광고 시작일과 광고 종료일을 선택해주세요"
                    name={dateFormName}
                    noDateLimit
                  />
                </div>
              </form>
            </FormProvider>
            <div className="bg-white">
              <div className="flex flex-row tab-buttons">
                {cats.map((item) => (
                  <button
                    key={item.value}
                    className={selectedCat === item.value ? 'active' : ''}
                    onClick={async () => onClickCat(item.value)}
                  >
                    {item.title}
                  </button>
                ))}
              </div>
              <div className="graphs">
                {Object.keys(grapthDatas).length > 0
                  && cats
                    .slice(1)
                    .map((item) => (
                      <GraphWrapper
                        key={item.value}
                        cat={item.value}
                        data={grapthDatas[item.value]}
                        barColor={item.barColor}
                        isHidden={
                          selectedCat === 'all' || selectedCat === item.value
                        }
                      />
                    ))}
              </div>
            </div>
          </div>
        </div>
        {isLoading && (
          <LoadingIndicator />
        )}
      </div>
    </NavigationLayout>
  )
}

export default Report
