import React from 'react'
import { useCookies } from 'react-cookie'
import {
  Redirect, useParams, useHistory, useLocation,
} from 'react-router-dom'
import queryString from 'query-string'
import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'

import { useQuery } from 'react-query'
import {
  getUserInfo,
  getNotices,
  getNotice,
  getFAQs,
  getPost,
  getStory,
  getPaymentInfo,
  getCommunityCategories,
  getLandingPageInfo,
  getGalleryCategories,
  getGalleryDetail,
  getEvents,
  getAsset,
} from './api'
import AppContext from './context'
import { CONFIG } from './config'
import LoadingLayout from '../layouts/loadingLayout'
import { validateAdCampaign } from './utils'
import useSessionTimeout from '../hooks/useSessionTimeout'
import { getCampaignsByFranchiseNotice, getFranchiseNotice, getFranchiseNotices } from './api/notice'
import { getMyFranchiseAdsList } from './api/media'
//
export const LogPath = (props, Next) => {
  const location = useLocation()
  React.useEffect(() => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
    ReactGA4.send('pageview')
  }, [])
  return <Next {...props} />
}
//
export const RedirectDomain = (props, Next) => {
  React.useEffect(() => {
    const fullUrl = window.location.href
    if (fullUrl.includes('stage.btvtownad.net')) {
      return
    }
    if (
      fullUrl.includes('www.btvtownad.co.kr')
      || fullUrl.includes('btvtownad.co.kr')
      || fullUrl.includes('btvtownad.net')
      || fullUrl.includes('www.btvtownad.com')
      || fullUrl.includes('www.btvtownad.net')
    ) {
      // const pathUrl = fullUrl.substring(fullUrl.indexOf(window.location.pathname))
      window.location.href = 'https://btvtownad.com'
    }
  }, [])
  return <Next {...props} />
}
//
export const UpdateHeaderMode = (props, Next) => {
  const { appDispatch } = React.useContext(AppContext)
  const [cookies] = useCookies(['jwt_token'])
  const { data: userInfo } = useQuery({
    queryKey: ['user', cookies.jwt_token],
    queryFn: () => getUserInfo(cookies.jwt_token),
    enabled: !!cookies.jwt_token,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5분
  })

  React.useEffect(() => {
    let action = {}
    const prefixes = ['/sign-in', '/sign-up', '/recover']
    //
    if ('jwt_token' in cookies) {
      if (userInfo && userInfo.company?.companyType === 2) {
        if (userInfo.companyRank >= 8) {
          action = { type: 'UpdateHeaderMode', payload: 'IS_FRANCHISE_ADMIN' }
        } else {
          action = { type: 'UpdateHeaderMode', payload: 'IS_FRANCHISE_USER' }
        }
      } else {
        action = { type: 'UpdateHeaderMode', payload: 'IS_AUTHENTICATED' }
      }
    } else {
      action = { type: 'UpdateHeaderMode', payload: 'IS_ANONYMOUS' }
      prefixes.some((prefix) => {
        if (props.location.pathname.indexOf(prefix) > -1) {
          action = { type: 'UpdateHeaderMode', payload: 'IS_REGISTERING' }
          return true
        }
        return false
      })
    }
    appDispatch(action)
  }, [userInfo, cookies, props.location.pathname])
  return <Next {...props} />
}
//
export const OnlyAnony = (props, Next) => {
  const [cookies] = useCookies(['jwt_token'])
  const [state, setState] = React.useState(null)
  React.useEffect(() => {
    if (!('jwt_token' in cookies)) setState(true)
    else setState(false)
  }, [cookies, setState])
  if (state === true) return <Next {...props} />
  if (state === false) return <Redirect to="/" />
  return <LoadingLayout />
}
//

export const OnlyAuth = (props, Next) => {
  const [cookies] = useCookies(['jwt_token'])
  const [state, setState] = React.useState(null)
  useSessionTimeout()

  React.useEffect(() => {
    if ('jwt_token' in cookies) setState(true)
    else setState(false)
  }, [cookies, setState])
  if (state === true) return <Next {...props} />
  if (state === false) return <Redirect to="/sign-in" />
  return <LoadingLayout />
}
//
export const CheckSignUpMode = (props, Next) => {
  const { appState } = React.useContext(AppContext)
  if (appState.signUpMode.method === null && appState.signUpMode.type === null) return <Redirect to="/sign-up/step/1" />
  return <Next {...props} />
}
//
export const CheckRegisterMode = (props, Next) => <Next {...props} />
// if (
//   typeof appState.userInfo?.method === 'string'
//   && appState.userInfo?.method !== 'skb'
//   && !appState.userInfo?.userPhonenumber
// ) return <Next {...props} />
// return <Redirect to="/portal/media" />

//
export const ResetSignUpMode = (props, Next) => {
  const { appDispatch } = React.useContext(AppContext)
  React.useEffect(() => {
    appDispatch({ type: 'updateSignUpMode', payload: { type: 'reset' } })
  }, [appDispatch])
  return <Next {...props} />
}
//
export const RedirectUrl = () => {
  if (!window.opener) {
    window.close()
    return <Redirect to="/" />
  }
  const result = queryString.parse(window.location.search)
  window.opener[window.name]?.onSuccess(result)
  window.close()
  return <Redirect to="/" />
}
//
export const SKPayRedirectUrl = () => {
  if (!window.opener) {
    window.close()
    return <Redirect to="/" />
  }
  const result = queryString.parse(window.location.search)
  try {
    window.opener.SKpaySDKCallback(result)
  } catch (e) {
    alert('해당 주문서 페이지를 이탈하여 결제가 진행되지 않습니다\n다시 결제를 시도해주세요')
  }
  window.close()
  return <Redirect to="/" />
  // return <div />
}
//
export const ForceUserInfo = (props, Next) => {
  const [cookies, , removeCookie] = useCookies(['jwt_token'])
  const { appState, appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  //
  React.useEffect(() => {
    (async () => {
      if (cookies.jwt_token !== undefined && appState.userInfo === null) {
        const result = await getUserInfo(cookies.jwt_token)
        if (!result) {
          await removeCookie('jwt_token', {
            path: '/',
          })
          await setState(false)
          return
        }
        let userTypeDetail
        if (result.company?.companyType === 2) {
          if (result.companyRank === 9) userTypeDetail = 'franchiseSuperAdmin'
          else if (result.companyRank === 8) userTypeDetail = 'franchiseAdmin'
          else userTypeDetail = 'franchiseUser'
        } else if (Number(result.userType) === 0) userTypeDetail = 'normalUser'
        else userTypeDetail = 'agencyUser'
        await appDispatch({ type: 'updateUserInfo', payload: { ...result, userTypeDetail } })
      }
      await setState(true)
    })()
  }, [appDispatch, removeCookie, appState.userInfo, cookies.jwt_token])
  //
  if (state === true) return <Next {...props} />
  if (state === false) return <Redirect to="/sign-in" />
  return <LoadingLayout />
}
//
export const UserInfo = (props, Next) => {
  const [cookies, , removeCookie] = useCookies(['jwt_token'])
  const { appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  //
  React.useEffect(() => {
    (async () => {
      if (cookies.jwt_token) {
        const result = await getUserInfo(cookies.jwt_token)
        if (!result) {
          await removeCookie('jwt_token', {
            path: '/',
          })
          await setState(true)
          return
        }
        let userTypeDetail
        if (result.company?.companyType === 2) {
          if (result.companyRank === 9) userTypeDetail = 'franchiseSuperAdmin'
          else if (result.companyRank === 8) userTypeDetail = 'franchiseAdmin'
          else userTypeDetail = 'franchiseUser'
        } else if (Number(result.userType) === 0) userTypeDetail = 'normalUser'
        else userTypeDetail = 'agencyUser'
        await appDispatch({ type: 'updateUserInfo', payload: { ...result, userTypeDetail } })
      }
      await setState(true)
    })()
  }, [appDispatch, removeCookie, cookies.jwt_token])
  //
  if (state) return <Next {...props} />
  return <LoadingLayout />
}
//
export const PaymentInfo = (props, Next) => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  // const params = useParams()
  //
  React.useEffect(() => {
    (async () => {
      if (appState.paymentInfo === null) {
        const result = await getPaymentInfo()
        await appDispatch({ type: 'updatePaymentInfo', payload: result })
      }
      await setState(true)
    })()
  }, [appDispatch, appState.paymentInfo])
  //
  if (state === true) return <Next {...props} />
  if (state === false) return <Redirect to="/" />
  return <LoadingLayout />
}
//
export const LandingPageInfo = (props, Next) => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  //
  React.useEffect(() => {
    (async () => {
      if (appState.landingPageInfo === null) {
        const result = await getLandingPageInfo()
        await appDispatch({ type: 'updateLandingInfo', payload: result })
      }
      await setState(true)
    })()
  }, [appDispatch, appState.landingPageInfo])
  //
  if (state === true) return <Next {...props} />
  if (state === false) return <Redirect to="/" />
  return <LoadingLayout />
}
//
export const CommunityCategoriesInfo = (props, Next) => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  const params = useParams()
  //
  React.useEffect(() => {
    (async () => {
      if (appState.paymentInfo === null) {
        const result = await getCommunityCategories('community')
        await appDispatch({
          type: 'updateCommunityCategoriesInfo',
          payload: result,
        })
      }
      await setState(true)
    })()
  }, [appDispatch, appState.paymentInfo, params.step])
  //
  if (state === true) return <Next {...props} />
  if (state === false) return <Redirect to="/" />
  return <LoadingLayout />
}
//
export const ValidateAdCampaign = (props, Next) => {
  const { appState } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  const history = useHistory()
  const params = useParams()
  //
  React.useEffect(() => {
    //
    (async () => {
      if (params.step === '3' && !validateAdCampaign(appState.adCampaign)) {
        history.replace('/portal/media/create/1')
        return false
      }
      await setState(true)
    })()
  }, [appState.adCampaign, history, params.step])
  //
  if (state === true) return <Next {...props} />
  if (state === false) return <Redirect to="/" />
  return <LoadingLayout />
}
//
export const PostData = (props, Next) => {
  const { appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  const [cookies] = useCookies(['jwt_token'])
  const params = useParams()
  //
  React.useEffect(() => {
    //
    (async () => {
      const result = await getPost(params.boardId, params.id)
      if (!result.comment) result.comment = []
      await appDispatch({ type: 'updateCurrentPost', payload: result })
      await setState(true)
    })()
  }, [appDispatch, params.id, cookies.jwt_token])
  //
  if (state === true) return <Next {...props} />
  if (state === false) return <Redirect to="/" />
  return <LoadingLayout />
}
//
export const AssetData = (props, Next) => {
  const [asset, setAsset] = React.useState(null)
  const [cookies] = useCookies(['jwt_token'])
  const location = useLocation()
  //
  React.useEffect(() => {
    if (location.state.assetId) {
      getAsset(cookies.jwt_token, location.state.assetId).then((result) => {
        setAsset(result)
      })
    }
  }, [location.state.assetId])
  //
  if (asset) return <Next asset={asset} {...props} />
  return <LoadingLayout />
}
//
export const SuccessStoryData = (props, Next) => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  const params = useParams()
  //
  React.useEffect(() => {
    //
    (async () => {
      const result = await getStory(params.id)
      await appDispatch({ type: 'updateCurrentStory', payload: result })
      await setState(true)
    })()
  }, [appDispatch, appState.noticeDataList, params.id])
  //
  if (state === true) return <Next {...props} />
  if (state === false) return <Redirect to="/" />
  return <LoadingLayout />
}
//
export const NoticeDataList = (props, Next) => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  //
  React.useEffect(() => {
    //
    (async () => {
      if (appState.noticeDataList === null) {
        const result = await getNotices()
        const data = {
          all: [],
          notices: [],
          services: [],
          operations: [],
          others: [],
        }
        result.forEach((item) => {
          const obj = { ...item }
          obj.tag = CONFIG.NOTICE_CATEGORIES[obj.categories]
          data.all.push(obj)
          switch (obj.categories) {
              case 'operate':
                data.operations.push(obj)
                break
              case 'notice':
                data.notices.push(obj)
                break
              case 'service':
                data.services.push(obj)
                break
              default:
              // "etc"
                data.others.push(obj)
                break
          }
        })
        await appDispatch({ type: 'updateNoticeDataList', payload: data })
      }
      await setState(true)
    })()
  }, [appDispatch, appState.noticeDataList])
  //
  if (state === true) return <Next {...props} />
  if (state === false) return <Redirect to="/" />
  return <LoadingLayout />
}
//
export const NoticeInfo = (props, Next) => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  const params = useParams()
  //
  React.useEffect(() => {
    (async () => {
      if (appState.notices[params.id] === null || appState.notices[params.id] === undefined) {
        const result = await getNotice(params.id)
        result.tag = CONFIG.NOTICE_CATEGORIES[result.categories]
        await appDispatch({ type: 'updateNotice', payload: result })
      }
      await setState(true)
    })()
  }, [appDispatch, appState.notices, params.id])
  //
  if (state === true) return <Next {...props} />
  if (state === false) return <Redirect to="/" />
  return <LoadingLayout />
}
//
export const FAQDataList = (props, Next) => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  //
  React.useEffect(() => {
    (async () => {
      if (appState.FAQDataList === null) {
        const result = await getFAQs()
        const data = {
          bests: [],
          accounts: [],
          payments: [],
          adReviews: [],
          adServices: [],
          reportings: [],
        }
        result.forEach((item) => {
          const obj = { ...item }
          obj.tag = CONFIG.FAQ_CATEGORIES[obj.categories]
          switch (obj.categories) {
              case 'best':
                data.bests.push(obj)
                break
              case 'account':
                data.accounts.push(obj)
                break
              case 'payment':
                data.payments.push(obj)
                break
              case 'adReview':
                data.adReviews.push(obj)
                break
              case 'adService':
                data.adServices.push(obj)
                break
              case 'reporting':
                data.reportings.push(obj)
                break
              default:
                break
          }
        })
        await appDispatch({ type: 'updateFAQDataList', payload: data })
      }
      await setState(true)
    })()
  }, [appDispatch, appState.FAQDataList])
  //
  if (state === true) return <Next {...props} />
  if (state === false) return <Redirect to="/" />
  return <LoadingLayout />
}
//
export const GalleryCategoryData = (props, Next) => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  //
  React.useEffect(() => {
    if (appState.galleryCategories && appState.galleryCategories.length) {
      setState(true)
      return
    }
    getGalleryCategories()
      .then((result) => {
        if (result.data) {
          const { data } = result
          appDispatch({ type: 'updateGalleryCategories', payload: data })
        }
        setState(true)
      })
      .catch(() => {
        setState(false)
      })
  }, [])
  //
  if (state === true) return <Next {...props} />
  if (state === false) return <Redirect to="/" />
  return <LoadingLayout />
}
//
export const StoreData = (props, Next) => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  //
  React.useEffect(() => {
    if (!appState.adCampaign?.step1?.galleryId) {
      setState(true)
      return
    }
    getGalleryDetail(appState.adCampaign?.step1?.galleryId, false)
      .then(async (result) => {
        if (result) {
          const {
            address: storeAddress,
            addressDetail: storeAddressDetail,
            key: storeAddressKey,
            postalCode: storePostalCode,
            lat: storeLat,
            lon: storeLon,
          } = result.store.storeAddress
          const store = {
            ...result.store,
            storeId: result.store._id,
            storeAddress,
            storeAddressDetail,
            storeAddressKey,
            storePostalCode,
            storeLat,
            storeLon,
            videoUrl: result.videoUrl,
            duration: result.duration,
          }
          delete store._id
          await appDispatch({ type: 'updateAdCampaignStore', payload: store })
          setState(true)
        }
        setState(false)
      })
      .catch(() => {
        setState(false)
      })
  }, [])
  //
  if (state === null) return <LoadingLayout />
  return <Next {...props} />
}
//
export const PromotionData = (props, Next) => {
  const [state, setState] = React.useState(null)
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  //
  React.useEffect(() => {
    const galleryId = location.state?.galleryId || query.get('galleryId')
    if (!galleryId) {
      setState({
        result: true,
        data: {
          campaignId: query.get('campaignId'),
          campaignName: query.get('campaignName'),
          videoUrl: query.get('videoUrl'),
        },
      })
      return
    }
    getGalleryDetail(galleryId)
      .then((result) => {
        if (result) {
          const {
            address: storeAddress,
            addressDetail: storeAddressDetail,
            key: storeAddressKey,
            postalCode: storePostalCode,
            lat: storeLat,
            lon: storeLon,
          } = result.store.storeAddress
          const data = {
            ...result.store,
            ...result.promotion,
            _id: result._id,
            campaignName: result.campaignName,
            videoUrl: result.videoUrl,
            duration: result.duration,
            storeId: result.storeId,
            promotionId: result.promotionId,
            storeAddress,
            storeAddressDetail,
            storeAddressKey,
            storePostalCode,
            storeLat,
            storeLon,
          }
          delete data.store
          setState({ result: true, data })
          return
        }
        setState({ result: false })
      })
      .catch(() => {
        setState({ result: false })
      })
  }, [])
  //
  if (state?.result) return <Next {...props} defaultValues={state.data} />
  return <LoadingLayout />
}
//
export const OnlyStage = (props, Next) => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const fullUrl = window.location.href
  if (
    (fullUrl.includes('stage.btvtownad.net') || fullUrl.includes('localhost'))
    && query.get('app_key') === process.env.REACT_APP_BACKOFFICE_APP_KEY
  ) {
    return <Next {...props} />
  }
  return <Redirect to="/" />
}
//
export const EventListData = (props, Next) => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  //
  React.useEffect(() => {
    if (appState.events && appState.events.length) {
      setState(true)
      return
    }
    getEvents()
      .then((result) => {
        if (result) {
          appDispatch({ type: 'updateEvents', payload: result })
        }
        setState(true)
      })
      .catch(() => {
        setState(false)
      })
  }, [])
  //
  if (state === true) return <Next {...props} />
  if (state === false) return <Redirect to="/" />
  return <LoadingLayout />
}
//
export const CollectReferrer = (props, Next) => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const [, setCookie] = useCookies(['referrer'])
  //
  React.useEffect(() => {
    const referrer = query.get('btv_ref')
    if (referrer) {
      setCookie('referrer', referrer, {
        path: '/',
        maxAge: 2592000 /* 30 day = (60 * 60 * 24 * 30) */,
      })
    }
  }, [])
  //
  return <Next {...props} />
}
/**
 * Franchise Wrappers
 */
export const FranchiseNoticeDataList = (props, Next) => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  const [cookies] = useCookies(['jwt_token'])
  //
  React.useEffect(() => {
    //
    (async () => {
      if (appState.franchiseNoticeDataList === null) {
        const result = await getFranchiseNotices(cookies.jwt_token)
        await appDispatch({ type: 'updateFranchiseNoticeDataList', payload: result })
      }
      await setState(true)
    })()
  }, [appDispatch, appState.franchiseNoticeDataList])
  //
  if (state === true) return <Next {...props} />
  if (state === false) return <Redirect to="/" />
  return <LoadingLayout />
}
//
export const FranchiseNoticePage = (props, Next) => {
  const { appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  const [cookies] = useCookies(['jwt_token'])
  const params = useParams()
  //
  React.useEffect(() => {
    //
    (async () => {
      const result = await getFranchiseNotice(cookies.jwt_token, params.id)
      await appDispatch({ type: 'updateCurrentFranchiseNotice', payload: result })
      await setState(true)
    })()
  }, [appDispatch, params.id, cookies.jwt_token])
  //
  if (state === true) return <Next {...props} />
  if (state === false) return <Redirect to="/" />
  return <LoadingLayout />
}
//
export const FranchiseNoticeCampaignList = (props, Next) => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  const [cookies] = useCookies(['jwt_token'])
  const params = useParams()
  //
  React.useEffect(() => {
    //
    (async () => {
      if (appState.franchiseAdsList === null) {
        const result = await getCampaignsByFranchiseNotice(cookies.jwt_token, params.id)
        await appDispatch({ type: 'updateFranchiseAdsList', payload: result })
      }
      await setState(true)
    })()
  }, [appDispatch, appState.franchiseAdsList])
  //
  if (state === true) return <Next {...props} />
  if (state === false) return <Next {...props} />
  return <LoadingLayout />
}
//
export const MyFranchiseAdsList = (props, Next) => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const [state, setState] = React.useState(null)
  const [cookies] = useCookies(['jwt_token'])
  //
  React.useEffect(() => {
    //
    (async () => {
      if (appState.franchiseAdsList === null) {
        const result = await getMyFranchiseAdsList(cookies.jwt_token)
        await appDispatch({ type: 'updateFranchiseAdsList', payload: result })
      }
      await setState(true)
    })()
  }, [appDispatch, appState.franchiseAdsList])
  //
  if (state === true) return <Next {...props} />
  if (state === false) return <Next {...props} />
  return <LoadingLayout />
}
