import React from 'react'
import { Container } from 'react-bootstrap'

import BasicLayout from '../../layouts/basic'
import NoticeWriteForm from './components/noticeWriteForm'
//
const NoticeWritePage = () => (
  <BasicLayout className="community-editor">
    <Container>
      <div className="pb-12">
        <NoticeWriteForm />
      </div>
    </Container>
  </BasicLayout>
)

export default NoticeWritePage
