import React from 'react'
import { Container } from 'react-bootstrap'

import { CONFIG } from '../services/config'
//
const Footer = () => (
  <footer>
    <Container className="flex flex-col items-start md:items-center">
      <p className="hidden lg:inline">
        SK브로드밴드(주) 대표이사 사장 박진효
        <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
        서울시 중구 퇴계로 24 SK남산그린빌딩
        <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
        사업자등록번호 214-86-18758
        <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
        {'통신판매신고번호 서울 중구 제 2008-1608호 '}
      </p>
      <p className="hidden lg:inline">
        <a href={CONFIG.memberTermURL} target="_blank" rel="noopener noreferrer">
          회원 이용약관
        </a>
        <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
        <a href={CONFIG.terms2URL} target="_blank" rel="noopener noreferrer">
          서비스 이용약관
        </a>
        <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
        <a href={CONFIG.privacyPtatementURL} target="_blank" rel="noopener noreferrer">
          <em>개인정보 처리방침</em>
        </a>
        <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
        COPYRIGHT © 2020 BY SK broadband CO.LTD.ALL RIGHTS RESERVED.
      </p>
      <p className="hidden md:inline lg:hidden">
        <span>SK브로드밴드(주) 대표이사 사장 박진효</span>
        서울시 중구 퇴계로 24 SK남산그린빌딩
        <br />
        사업자등록번호 214-86-18758
        {'통신판매신고번호 서울 중구 제 2008-1608호 '}
        <br />
        <a href={CONFIG.memberTermURL} target="_blank" rel="noopener noreferrer">
          회원 이용약관
        </a>
        <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
        <a href={CONFIG.terms2URL} target="_blank" rel="noopener noreferrer">
          서비스 이용약관
        </a>
        <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
        <a href={CONFIG.privacyPtatementURL} target="_blank" rel="noopener noreferrer">
          <em>개인정보 처리방침</em>
        </a>
        <br />
        COPYRIGHT © 2020 BY SK broadband CO.LTD.ALL RIGHTS RESERVED.
      </p>
      <p className="inline md:hidden">
        <span>SK브로드밴드(주)</span>
        <br />
        대표이사 사장 박진효
        <br />
        서울시 중구 퇴계로 24 SK남산그린빌딩
        <br />
        사업자등록번호 214-86-18758
        <br />
        {'통신판매신고번호 서울 중구 제 2008-1608호 '}
        <br />
        <br />
        <a href={CONFIG.memberTermURL} target="_blank" rel="noopener noreferrer">
          회원 이용약관
        </a>
        <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
        <a href={CONFIG.terms2URL} target="_blank" rel="noopener noreferrer">
          서비스 이용약관
        </a>
        <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
        <a href={CONFIG.privacyPtatementURLMobile} target="_blank" rel="noopener noreferrer">
          <em>개인정보 처리방침</em>
        </a>
        <br />
        <br />
        COPYRIGHT © 2020 BY SK broadband
        <br />
        CO.LTD.ALL RIGHTS RESERVED.
      </p>
    </Container>
  </footer>
)

export default Footer
