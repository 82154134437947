import React from 'react'

import SectionTitle from '../../../components/SectionTitle'
import { TwoButtonModal } from '../../../components/modals'
import SelectCharacter from './SelectCharacter'

const ModifyModal = ({ voiceBlock, onClose, handleModify }) => {
  const [character, setCharacter] = React.useState(voiceBlock.actor_id)
  const [content, setContent] = React.useState(voiceBlock.content)

  const onClickCharacter = React.useCallback(
    (id) => setCharacter(id),
    [],
  )

  const onChangeContent = React.useCallback(
    (e) => { setContent(e.target.value) },
  )

  const onClick = () => {
    handleModify({ ...voiceBlock, actor_id: character, content })
  }

  return (
    <TwoButtonModal
      title="성우 정보"
      primaryText="수정"
      secondaryText="취소"
      onClickPrimary={onClick}
      onClickSecondary={onClose}
      onClose={onClose}
    >
      <div className="flex flex-col gap-4 w-[480px]">
        <div className="flex flex-col gap-3">
          <SectionTitle>캐릭터</SectionTitle>
          <SelectCharacter
            character={character}
            onClickCharacter={onClickCharacter}
          />
        </div>
        <div className="flex flex-col gap-3">
          <SectionTitle>내용</SectionTitle>
          <div className="textbox-wrapper">
            <input className="w-full" type="text" value={content} onChange={onChangeContent} />
          </div>
        </div>
      </div>
    </TwoButtonModal>
  )
}

export default ModifyModal
