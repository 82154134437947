import React from 'react'
import loadingImg from '../assets/imgs/4296.svg'
import loadingSpinner from '../assets/icons/loading-loop-spinner.svg'

const LoadingIndicator = () => (
  <div className="fixed inset-0 flex justify-center items-center z-[20]">
    <div className="absolute inset-0 bg-[#222] opacity-30 z-[1]" />
    <img src={loadingImg} alt="loading-container" />
    <img
      src={loadingSpinner}
      alt="loading"
      className="spinner absolute"
    />
  </div>
)

export default LoadingIndicator
