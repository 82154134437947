import React from 'react'
import { FormActionTextboxGroup } from '../../../components/formActionTextbox'
import { checkUserIdAvailability } from '../../../services/api'
//
let id = null
let placeholder = null
let pattern = null
//
const UserIdFormActionBoxGroup = ({ userType }) => {
  if (userType === 'INDIVISUAL') {
    id = 'user_id'
    placeholder = '8~21자의 영문/숫자 사용가능'
    pattern = /^[a-zA-Z0-9]{8,21}$/
  } else {
    id = 'agency_id'
    placeholder = '6~21자의 영문, 숫자, 특수기호(_-.) 조합'
    pattern = /^[_a-zA-Z0-9]{6,21}$/
  }
  //
  const onUserIdAction = async (e, value) => {
    const data = await checkUserIdAvailability(value)
    return data
  }
  //
  return (
    <FormActionTextboxGroup
      label="아이디"
      name={id}
      placeholder={placeholder}
      validators={{
        validated: true,
        required: true,
        pattern,
      }}
      actionName="validated"
      validations={['required', 'pattern', 'validated']}
      onClick={onUserIdAction}
    >
      중복확인
    </FormActionTextboxGroup>
  )
}

export default UserIdFormActionBoxGroup
