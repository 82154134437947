import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import NavigationLayout from '../../layouts/navigationLayout'
import TutorialCarousel from './components/TutorialCarousel'
import { assetResources, adResources } from './tutorialRes'

const TutorialDetail = () => {
  const history = useHistory()
  const location = useLocation()
  const carouselRef = React.useRef(null)
  const [data, setData] = React.useState({
    title: '',
    images: [],
  })
  //
  const onClickLast = React.useCallback(
    (direction) => {
      if (direction === 'left') return
      const tempArr = location.pathname.split('/')
      const resIndex = Number(tempArr[tempArr.length - 1])
      const type = tempArr[tempArr.length - 2]
      const resources = type === 'asset' ? assetResources : adResources
      if (resIndex < resources.length - 1) {
        history.push(`/tutorial/${type}/${resIndex + 1}`)
        return true
      }
      return false
    },
    [location, history],
  )
  //
  React.useEffect(() => {
    const tempArr = location.pathname.split('/')
    const resIndex = tempArr[tempArr.length - 1]
    const type = tempArr[tempArr.length - 2]
    const resources = type === 'asset' ? assetResources : adResources
    setData(resources[resIndex])
  }, [location.pathname])

  return (
    <NavigationLayout navigationTitle={data.title} footerVisible={false}>
      <div className="flex flex-grow flex-col bg-white tutorial-detail">
        <TutorialCarousel
          ref={carouselRef}
          data={data.images}
          onClickLastArrow={onClickLast}
        />
      </div>
      <div className="flex flex-column tutorial-button-wrapper">
        <button
          className="button primary"
          onClick={() => {
            if (carouselRef.current) carouselRef.current.moveNext()
          }}
        >
          다음
        </button>
      </div>
    </NavigationLayout>
  )
}

export default TutorialDetail
