import React from 'react'
import moment from 'moment'
import { ko } from 'date-fns/locale'
import { Calendar } from 'react-date-range'
import { useFormContext, useWatch } from 'react-hook-form'

import RightIcon from '../assets/icons/icon-outlined-directional-right-small.svg'
import LeftIcon from '../assets/icons/icon-outlined-directional-left-small.svg'

const format = 'YYYY년 MM월 DD일'
const formatYM = 'YYYY년 M월'

const Header = ({ date, changeShownDate }) => {
  const onClick = (e, st) => {
    e.preventDefault()
    if (st) changeShownDate(moment(date).subtract(1, 'M').toDate())
    else changeShownDate(moment(date).add(1, 'M').toDate())
  }
  //
  return (
    <div className="datepicker-header">
      <button onClick={(e) => onClick(e, true)}>
        <img src={LeftIcon} alt="prev" />
      </button>
      <div className="flex flex-grow">
        <h4>{`${moment(date).format(formatYM)}`}</h4>
      </div>
      <button onClick={(e) => onClick(e, false)}>
        <img src={RightIcon} alt="next" />
      </button>
    </div>
  )
}

const DatePickerInputComponent = (props, forwardedRef) => {
  //
  const inputClasses = ['flex-grow', 'xlarger', 'w-full']
  if (props.className) inputClasses.push(props.className)
  //
  return (
    <input
      placeholder={props.placeholder}
      ref={forwardedRef}
      className={inputClasses.join(' ')}
      value={props.value}
      readOnly
    />
  )
}

const DatePickerInput = React.forwardRef(DatePickerInputComponent)

const DateSelectBox = ({
  children,
  setDateValue,
  renderNote,
  noDateLimit,
  ...props
}) => {
  const [showCalendar, setShowCalendar] = React.useState(false)
  const [currentDate, setCurrentDate] = React.useState()

  React.useEffect(() => {
    if (props.data) {
      setCurrentDate(new Date(props.data))
    }
  }, [])

  const onChange = (selectedDate) => {
    setCurrentDate(selectedDate)
    setShowCalendar(false)
    setDateValue(moment(selectedDate).format('YYYY-MM-DD'))
  }
  //
  const onClick = () => {
    setShowCalendar(!showCalendar)
  }

  const navigatorRenderer = (date, changeShownDate) => (
    <Header date={date} changeShownDate={changeShownDate} />
  )

  return (
    <div className="date-select-box flex-grow">
      <div className="relative cursor-pointer">
        <DatePickerInput value={currentDate && moment(currentDate).format(format)} {...props} />
        <div className="absolute inset-0" onClick={onClick} />
      </div>
      {showCalendar && (
        <div className="absolute z-[5] shadow flex flex-col">
          <Calendar
            className="border rounded"
            locale={ko}
            date={currentDate}
            minDate={new Date()}
            onChange={onChange}
            color="#ff9000"
            navigatorRenderer={navigatorRenderer}
            {...props}
          />
        </div>
      )}
    </div>
  )
}

export const FormDateSelectBox = ({
  name, validators, noDateLimit, ...props
}) => {
  const { register, setValue, control } = useFormContext()
  const val = useWatch({ name, control })
  let validate = {}
  if (validators?.required) {
    validate = {
      ...validators.validate,
      required: (value) => value[0] !== null && value[1] !== null,
    }
  }
  const { ...hiddenInputProps } = register(name, { ...validators, validate })
  //
  const setDateValue = async (update) => {
    await setValue(name, update, { shouldValidate: true })
  }
  //
  return (
    <>
      <DateSelectBox
        setDateValue={setDateValue}
        noDateLimit={noDateLimit}
        {...props}
        name={name}
        data={val}
      />
      <input type="hidden" {...hiddenInputProps} />
    </>
  )
}

export default DateSelectBox
