import React from 'react'
import { Carousel } from 'react-bootstrap'

import leftIcon from '../assets/icons/icon-outlined-directional-left-circle.svg'
import rightIcon from '../assets/icons/icon-outlined-directional-right-circle.svg'
//
const VideoCarousel = ({ data, isPlayOnInit = false }) => {
  const [activeIndex, setActiveIndex] = React.useState(0)
  //
  React.useEffect(() => {
    if (isPlayOnInit && data?.length > 0) {
      const vids = document.getElementsByClassName('vid-group-22')
      if (vids && vids.length > 0) vids[0].play()
    }
  }, [isPlayOnInit, data])
  //
  const onChange = async (e, state) => {
    let index = null
    if (state) index = Math.abs((activeIndex + 1) % data.length)
    else index = Math.abs((data.length + activeIndex - 1) % data.length)

    await setActiveIndex(index)
    const vids = document.getElementsByClassName('vid-group-22')
    vids.forEach((vid) => vid.pause())
    vids[index].play()
  }
  //
  const handleSelect = async (selectedIndex) => {
    await setActiveIndex(Math.abs(selectedIndex % data.length))
  }
  //
  return (
    <div className="video-carousel relative">
      <div className="video-carousel-wrapper flex flex-row justify-center">
        <div className="arrow-wrapper flex justify-center">
          <button
            onClick={(e) => onChange(e, false)}
            style={{ padding: 0, paddingRight: 24, margin: 0 }}
          >
            <img src={leftIcon} alt="left" style={{ margin: 0 }} />
          </button>
        </div>
        <Carousel
          className="flex-grow"
          controls={false}
          indicators={false}
          activeIndex={activeIndex}
          onSelect={handleSelect}
          pause="hover"
          interval={null}
        >
          {data.map((item, index) => (
            <Carousel.Item className="relative" key={index}>
              <div>
                <video
                  className={`vid-group-22 vid-${index}`}
                  controls
                  controlsList="nodownload"
                  onContextMenu={(e) => e.preventDefault()}
                  src={item.video}
                />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>

        <div className="arrow-wrapper flex justify-center">
          <button
            onClick={(e) => onChange(e, true)}
            style={{ padding: 0, paddingLeft: 24, margin: 0 }}
          >
            <img src={rightIcon} alt="right" style={{ margin: 0 }} />
          </button>
        </div>
      </div>
      <div className="indicators w-full flex flex-col items-center">
        <div>
          {data.map((item, index) => (
            <span
              key={index}
              className={`dot${activeIndex === index ? ' active' : ''}`}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default VideoCarousel
