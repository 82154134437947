import React from 'react'
import { Container } from 'react-bootstrap'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useCookies } from 'react-cookie'

import CampaignPaymentView from './components/campaignPaymentView'
import CampaignPaymentActions from './components/campaignPaymentActions'
import AppContext from '../../services/context'
import { getUserInfo, requestInspection, requestTheDreamInspection } from '../../services/api'
import LoadingIndicator from '../../components/LoadingIndicator'
import useGiftInfoModal from './hooks/useGiftInfoModal'
import GiftInfoModal from './components/giftInfoModal'
import { formatNumber } from '../../services/utils'
//
const AdCampaignStep3 = () => {
  const [cookies] = useCookies(['jwt_token'])
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const paymentActionsRef = React.useRef()
  const { appState, appDispatch } = React.useContext(AppContext)

  const [isLoading, setIsLoading] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  console.log(appState.adCampaign?.step1.promotion?.id)
  const isTheDreamPromotion = appState.adCampaign?.step1.promotion?.id === 'thedream_2406'

  const methods = useForm({
    criteriaMode: 'all',
    mode: 'onSubmit',
    defaultValues: appState.adCampaign?.step3,
  })
  const { register, setValue } = methods
  //
  const {
    isGiftModalShown, isBusiness, openGiftModal, onCloseGiftModal, onClickRegister, business_id,
  } = useGiftInfoModal(methods)
  //
  React.useEffect(() => {
    (async () => {
      await register('ad_campaign_payment_method', { required: true })
      await setValue('ad_campaign_payment_method', 'skpay')
    })()
  }, [register, setValue])
  //
  React.useEffect(() => {
    (async () => {
      const result = await getUserInfo(cookies.jwt_token)
      await appDispatch({ type: 'updateUserInfo', payload: result })
    })()
  }, [appDispatch, cookies.jwt_token])
  //

  //
  const onSubmit = async (data) => {
    if (submitted) return
    const { ad_campaign_gift, p_info_consent } = data
    if (ad_campaign_gift === 'true' && !p_info_consent) {
      enqueueSnackbar('개인정보 수집 및 이용에 동의해주세요.', {
        variant: 'error',
      })
      return
    }

    await setSubmitted(true)
    setIsLoading(true)
    setTimeout(() => {
      setSubmitted(false)
    }, 10000)

    if (isTheDreamPromotion) {
      await requestTheDreamInspection(cookies.jwt_token, appState.adCampaign.id)
      alert('신청이 완료되었습니다.\n광고심사까지 영업일 기준 3~4일 소요 예상됩니다.')
      history.push('/portal/media')
      return
    }

    if (appState.adCampaign?.ad_campaign_is_paid) {
      const result = await requestInspection(cookies.jwt_token, appState.adCampaign.id)
      if (result?.subStatus === 'INSPECTION') {
        window.alert('수정된 항목에 대한 심사 요청을 완료했습니다.')
        history.replace('/portal/media')
      }
    } else {
      if (data.ad_campaign_budget < data.ad_campaign_min_budget) {
        enqueueSnackbar(`최소 예산 ${formatNumber(data.ad_campaign_min_budget)}원 이상 입력해주세요.`, {
          variant: 'error',
        })
        return
      }
      await paymentActionsRef.current?.initiatePayment(data)
      setIsLoading(false)
    }

    // 결제 완료 후 프로세스 테스트를 위한 코드
    // await history.push({
    //   pathname: '/portal/media/create/result',
    //   state: {
    //     done: true,
    //   },
    // })
  }

  const noPayment = isTheDreamPromotion

  return (
    <Container>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="payment-holder">
            <div className="payment-content">
              {/**/}
              <CampaignPaymentView type="auto" openGiftModal={openGiftModal} noPayment={noPayment} meta={isTheDreamPromotion ? { id: 'thedream_2406' } : undefined} />
              {/**/}
              {noPayment ? (
                <button type="submit" className="primary larger w-[250px] mx-auto">
                  신청하기
                </button>
              ) : (
                <CampaignPaymentActions ref={paymentActionsRef} type="auto" />
              )}
              {/**/}
            </div>
          </div>
        </form>
      </FormProvider>
      <GiftInfoModal
        isModalShown={isGiftModalShown}
        business_id={business_id}
        isBusiness={isBusiness}
        onClose={onCloseGiftModal}
        onClickRegister={onClickRegister}
        {...methods.watch()}
      />
      {isLoading && <LoadingIndicator />}
    </Container>
  )
}

export default AdCampaignStep3
