import React from 'react'
import { Container } from 'react-bootstrap'

import BasicLayout from '../../layouts/basic'
import not_found from '../../assets/imgs/404.svg'

const NotFound = ({ history }) => (
  <BasicLayout>
    <Container>
      <div
        className="flex flex-col justify-items-stretch items-center text-center"
        style={{ margin: '110px 0' }}
      >
        <img src={not_found} alt="" style={{ marginBottom: '24px' }} />
        <div className="header">
          <h1>페이지를 찾을 수 없습니다</h1>
          <p style={{ fontWeight: 'bold' }}>
            <span style={{ color: 'var(--primary-color)' }}>
              입력한 주소가 잘못되었거나, 사용이 일시 중단되어
            </span>
            <br />
            <span>{' 요청하신 페이지를 찾을 수 없습니다'}</span>
          </p>
        </div>
        <div className="content">
          <p style={{ color: 'var(--text-secondary)' }}>
            <span>페이지의 주소가 정확한지 다시 한번 확인해주세요</span>
            <br />
            <span>
              관련 문의사항은 채널톡으로 알려주시면 빠른 답변 드리겠습니다
            </span>
          </p>
          <button
            type="submit"
            className="w-full"
            onClick={() => history.goBack()}
          >
            이전페이지로 가기
          </button>
          <button
            type="submit"
            className="w-full secondary"
            onClick={() => history.push('/')}
          >
            메인으로 가기
          </button>
        </div>
      </div>
    </Container>
  </BasicLayout>
)

export default NotFound
