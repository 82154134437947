import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import imgCellphoneHand from '../assets/imgs/graphic-cellphone-hand@2x.png'
import icCellphone from '../assets/icons/ic-cellphone@2x.png'

const BottomContactBar = ({ scrollDirection }) => (
  <div
    className={cx(
      'fixed bottom-0 w-full px-[16px] z-10',
      'overflow-hidden left-1/2 transform -translate-x-1/2',
      'max-w-[300px] sm:max-w-[calc(100%-200px)] xl:max-w-[1080px] xl:px-20',
    )}
    style={{
      pointerEvents: scrollDirection === 'DOWN' ? 'none' : 'auto',
    }}
  >
    <div
      className={cx(
        'flex items-center h-[90px] px-[20px] gap-[30px] bg-brand rounded-t-xl',
        'shadow transition-transform duration-300 ease-in-out',
        'xl:h-[125px] xl:px-10',
      )}
      style={{
        transform: scrollDirection === 'DOWN' ? 'translateY(100%)' : 'translateY(0)',
        pointerEvents: scrollDirection === 'DOWN' ? 'none' : 'auto',
      }}
    >
      <img
        className={cx(
          'self-end h-[92px] mb-[-15px]',
          'xl:h-[130px] xl:mb-[-20px]',
        )}
        src={imgCellphoneHand}
        alt="cellphone-hand"
      />
      <div className="flex-1">
        <h4 className={cx('mb-0 text-[18px] text-white', 'xl:text-[26px]')}>
          B tv 우리동네광고 상담 신청
        </h4>
        <p className={cx('mb-0 text-[14px] text-white', 'xl:text-[18px]')}>전화 상담을 원하시면 1566-7762 으로 문의 바랍니다.</p>
        <p className="mb-0 text-[12px] text-[#d8d0ff]">평일 AM 09시 ~ PM 18시</p>
      </div>
      <Link to="/consult">
        <div className={cx(
          'flex items-center !px-[16px] py-2.5 !gap-3 bg-black rounded-xl',
          'xl:!px-[30px]',
        )}
        >
          <img className={cx('h-6 !mx-0 xl:h-10')} src={icCellphone} alt="cellphone" />
          <p className={cx(
            'mb-0 text-sm text-white font-bold whitespace-pre-wrap',
            'xl:text-lg',
          )}
          >
            {'전화상담\n예약하기'}
          </p>
        </div>
      </Link>
    </div>
  </div>
)

export default BottomContactBar
