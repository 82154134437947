import React from 'react'
import {
  getAdCampaign,
  buildAdCampaign,
  setAdCampaign,
  resetAdCampaign,
  getFranchiseNoticeRow,
  getFranchiseAdsRow,
} from './utils'
//
export const initialState = {
  headerMode: 'IS_ANONYMOUS',
  signUpMode: {
    method: null,
    type: null,
    state: null,
  },
  userInfo: null,
  paymentInfo: null,
  landingPageInfo: null,
  noticeDataList: null,
  notices: {},
  FAQDataList: null,
  adAreasDataList: null,
  currentPost: null,
  currentStory: null,
  communityCategoriesInfo: null,
  adCampaign: getAdCampaign(),
  tempEstimateResult: null,
  galleryCategories: [],
  galleryOptions: {
    state: {
      companySector: {},
      region: [],
      search: null,
    },
    pageOption: {
      currentPage: 1,
      pageCount: 0,
    },
  },
  addressData: [],
  events: [],
  products: [],
  recommendForm: null,
  channelTalkBooted: false,
  channelTalkHide: false,

  // Franchise Data
  franchiseNoticeDataList: null,
  franchiseNoticeOptions: {
    pageOption: {
      currentPage: 1,
      pageCount: 1,
    },
  },
  currentfranchiseNotice: null,
  franchiseAdsList: null,
  franchiseAdsOptions: {
    pageOption: {
      currentPage: 1,
      pageCount: 1,
    },
  },
}
//
const AppContext = React.createContext(initialState)

export const AppContextProvider = ({ children }) => {
  const [appState, appDispatch] = React.useReducer((state, action) => {
    switch (action.type) {
        //
        case 'updateLandingInfo':
          return { ...state, landingPageInfo: action.payload }
          //
        case 'updateCommunityCategoriesInfo':
          return { ...state, communityCategoriesInfo: action.payload }
          //
        case 'updatePaymentInfo':
          return { ...state, paymentInfo: action.payload }
          //
        case 'updateCurrentStory':
          return { ...state, currentStory: action.payload }
          //
        case 'resetAdCampaign':
          resetAdCampaign()
          return { ...state, adCampaign: resetAdCampaign() }
          //
        case 'updateAdCampaign':
          const adCampaign = buildAdCampaign(action.payload)
          setAdCampaign(adCampaign)
          return { ...state, adCampaign }
          //
        case 'updateAdCampaignStore':
          return {
            ...state,
            adCampaign: {
              ...state.adCampaign,
              step1: { ...state.adCampaign.step1, ...action.payload },
              step2: { ...state.adCampaign.step2, ...action.payload },
              step3: { ...state.adCampaign.step3, ...action.payload },
            },
          }
          //
        case 'updateCurrentPost':
          return { ...state, currentPost: action.payload }
          //
        case 'updateFAQDataList':
          return { ...state, FAQDataList: action.payload }
          //
        case 'updateNotice':
          return {
            ...state,
            notices: Object.assign(state.notices, {
              [action.payload.id]: action.payload,
            }),
          }
          //
        case 'updateNoticeDataList':
          return { ...state, noticeDataList: action.payload }
          //
        case 'updateAddressData':
          return { ...state, addressData: action.payload }
          //
        case 'updateRecommendForm':
          return { ...state, recommendForm: action.payload }
          //
        case 'saveEstimateResultTemp':
          return { ...state, tempEstimateResult: action.payload }
          //
        case 'updateGalleryCategories':
          return { ...state, galleryCategories: action.payload }
          //
        case 'updateGalleryOptions':
          const companySector = {}
          Array.from(action.payload.state.companySector).forEach((item) => {
            companySector[item] = true
          })
          return {
            ...state,
            galleryOptions: {
              ...action.payload,
              state: {
                ...action.payload.state,
                companySector,
              },
            },
          }
          //
        case 'updateEvents':
          return { ...state, events: action.payload }
          //
        case 'updateProducts':
          return { ...state, products: action.payload }
          //
        case 'updateUserInfo':
          return { ...state, userInfo: action.payload }
          //
        case 'UpdateHeaderMode':
          return { ...state, headerMode: action.payload }
          //
        case 'updateSignUpMode':
          switch (action.payload.type) {
              case 'type':
                return {
                  ...state,
                  signUpMode: Object.assign(state.signUpMode, {
                    type: action.payload.content,
                  }),
                }
              case 'method':
                return {
                  ...state,
                  signUpMode: Object.assign(state.signUpMode, {
                    method: action.payload.content,
                  }),
                }
              case 'reset':
                return {
                  ...state,
                  signUpMode: {
                    method: null,
                    type: null,
                    state: null,
                  },
                }
              default:
                return {
                  ...state,
                  signUpMode: Object.assign(state.signUpMode, {
                    state: action.payload.content,
                  }),
                }
          }
        case 'updateChannelTalkBooted':
          return { ...state, channelTalkBooted: action.payload }
        case 'updateChannelTalkHide':
          return { ...state, channelTalkHide: action.payload }
          //
        case 'updateFranchiseNoticeDataList':
          const franchiseNoticeDataList = getFranchiseNoticeRow(action.payload)
          return { ...state, franchiseNoticeDataList }
          //
          // Franchise Actions
        case 'updateNoticeOptions':
          return {
            ...state,
            noticeOptions: {
              ...action.payload,
            },
          }
          //
        case 'updateCurrentFranchiseNotice':
          console.log({ ...state, currentFranchiseNotice: action.payload })
          return { ...state, currentFranchiseNotice: action.payload }
          //
        case 'updateFranchiseAdsOptions':
          return {
            ...state,
            franchiseAdsOptions: {
              ...action.payload,
            },
          }
          //
        case 'updateFranchiseAdsList':
          const franchiseAdsList = getFranchiseAdsRow(action.payload)
          return {
            ...state,
            franchiseAdsList,
          }
        default:
          return state
    }
  }, initialState)

  const value = React.useMemo(() => ({ appState, appDispatch }), [appState])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export default AppContext
