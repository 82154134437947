import React from 'react'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { useLocation } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import moment from 'moment'

import NavigationLayout from '../../layouts/navigationLayout'
import Menu from '../../components/menu'
import { reportMenu } from '../../services/menus'
import imgDownload from '../../assets/icons/icon-outlined-application-download-medium.svg'

const dateFormat = 'YYYY년 MM월 DD일'

const ReportDetail = () => {
  //
  const location = useLocation()
  //
  const onClickDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `${filename}.mp4`)
      })
  }
  //
  const {
    campaignName,
    regionsObj = [],
    asset,
    adStartAt,
    adEndAt,
  } = location.state
  const regionNames = regionsObj.map((item) => item.name.split(' ').slice(1).join(' '))
  const regionTitle = `${regionNames[0]} 외 ${regionNames.length - 1}곳`

  return (
    <NavigationLayout navigationTitle="광고 리포트 상세정보">
      <div className="flex flex-grow flex-col relative bg-white report report-detail">
        <div className="page-headline">
          <Container>
            <h1>광고 리포트보기</h1>
          </Container>
        </div>
        <div className="flex flex-row container">
          <div className="side-menu">
            <Menu
              items={reportMenu}
              activeLink={location.pathname}
              state={location.state}
            />
          </div>
          <div className="content">
            <div className="header hidden xl:block">
              <h3>상세정보</h3>
            </div>
            <div className="flex flex-col content-inner">
              <h3>{campaignName || '제목 없음'}</h3>
              <div className="flex flex-col info-section relative">
                <span className="subtitle">{asset?.assetName}</span>
                <div className="flex items-end video-wrapper">
                  <video
                    controlsList="nodownload"
                    onContextMenu={(e) => e.preventDefault()}
                    controls
                  >
                    <source src={asset?.vidoeUrl} type="video/mp4" />
                  </video>
                  <button
                    onClick={() => onClickDownload(
                      asset?.vidoeUrl,
                      asset?.assetName || '제목없음',
                    )}
                  >
                    <img src={imgDownload} alt="download" />
                    소재 다운로드
                  </button>
                </div>
              </div>
              <div className="flex flex-col info-section">
                <span className="subtitle">선택한 지역</span>
                {regionNames.length > 1 && (
                  <span className="subtitle-bold">{regionTitle}</span>
                )}
                <div className="flex region-tags">
                  <div className="flex">
                    {regionNames.map((item, index) => (
                      <div key={index} className="region-item">
                        <span>{item}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-col info-section">
                <span className="subtitle">광고 기간</span>
                <span className="subtitle-bold">
                  {`${moment(adStartAt).format(
                    dateFormat,
                  )} ~ ${moment(adEndAt).format(dateFormat)}`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NavigationLayout>
  )
}

export default ReportDetail
