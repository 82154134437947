import React from 'react'
import { useQuery } from 'react-query'
import { useCookies } from 'react-cookie'
import Skeleton from 'react-loading-skeleton'
import cx from 'classnames'
import { Carousel } from 'react-bootstrap'

import { formatNumber, transNumToKoreanMan } from '../../../services/utils'
import { getCampaignResult } from '../../../services/api/dashboard'
import { FRANCHISE_REGIONS } from '../../../services/constants'
import CompetitionGraph from './CompetitionGraph'

const DATA_TYPE_TEXT = {
  count: '청약 건수',
  expense: '청약 금액',
}

const Dot = ({ isSelected, onClick }) => (
  <div
    className={cx('w-[8px] h-[8px] rounded-full cursor-pointer', {
      'bg-brand': isSelected,
      'bg-[#bbb]': !isSelected,
    })}
    onClick={onClick}
  />
)

const ThisYearDataView = () => {
  const [cookies] = useCookies(['jwt_token'])

  const [type, setType] = React.useState('count')

  const isCount = React.useMemo(() => type === 'count', [type])
  const unit = React.useMemo(() => (isCount ? '건' : '원'), [isCount])
  const thisYear = new Date().getFullYear()

  React.useEffect(() => {
    const interval = setInterval(() => {
      setType((prevType) => (prevType === 'count' ? 'expense' : 'count'))
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  const { data: _data } = useQuery('thisYearData', () => getCampaignResult(cookies.jwt_token), {
    enabled: !!cookies.jwt_token,
  })

  const data = Object.keys(FRANCHISE_REGIONS).map((region, index) => {
    const regionData = _data?.find((item) => item.region === region)
    if (!regionData) {
      return {
        region,
        count: 0,
        expense: 0,
      }
    }
    return {
      region,
      count: regionData.count,
      expense: regionData.expense,
    }
  })

  // 임의 데이터
  // const data = [
  //   {
  //     region: 'capital',
  //     count: 1,
  //     expense: 100000,
  //   },
  //   {
  //     region: 'western',
  //     count: 1,
  //     expense: 200000,
  //   },
  //   {
  //     region: 'midland',
  //     count: 3,
  //     expense: 300000,
  //   },
  //   {
  //     region: 'daegu',
  //     count: 4,
  //     expense: 400000,
  //   },
  //   {
  //     region: 'busan',
  //     count: 6,
  //     expense: 500000,
  //   },
  //   {
  //     region: 'jeju',
  //     count: 6,
  //     expense: 600000,
  //   },
  // ]
  const totalData = React.useMemo(() => {
    if (!data) return null
    return data.reduce(
      (acc, cur) => {
        acc.count += cur.count
        acc.expense += cur.expense
        return acc
      },
      { count: 0, expense: 0 },
    )
  }, [data])

  const winnerValue = React.useMemo(() => {
    if (!data) return null
    const sortedData = [...data].sort((a, b) => b[type] - a[type])
    return sortedData[0][type]
  }, [type, data])

  return (
    <div className="mb-10 lg:mb-[100px]">
      <h3 className="text-[16px] lg:text-[22px] !mb-1">
        {`${thisYear}년 `}
        <span className="font-normal">{`현재 총 ${DATA_TYPE_TEXT[type]}${isCount ? '는' : '은'}`}</span>
      </h3>
      <h2 className="text-[32px] lg:text-[48px]">
        <span className="text-brand">
          {!totalData ? <Skeleton /> : `${transNumToKoreanMan(totalData[type], type === 'expense')}${unit}`}
        </span>
        &nbsp;입니다.
      </h2>
      {winnerValue > 0 && (
        <div className="flex items-end gap-2 lg:gap-8">
          <div className="flex flex-col mb-1 gap-1 shrink-0">
            <button
              type="button"
              className={cx('font-bold !p-0 text-[12px] lg:text-[16px]', {
                'text-brand': isCount,
                'text-[#bbb]': !isCount,
              })}
              onClick={() => setType('count')}
            >
              청약 건수
            </button>
            <button
              type="button"
              className={cx('font-bold !p-0 text-[12px] lg:text-[16px]', {
                'text-brand': !isCount,
                'text-[#bbb]': isCount,
              })}
              onClick={() => setType('expense')}
            >
              청약 금액
            </button>
          </div>
          <CompetitionGraph type={type} data={data} winnerValue={winnerValue} />
          <div className="flex flex-col mb-[14px] gap-3 shrink-0">
            <Dot isSelected={isCount} onClick={() => setType('count')} />
            <Dot isSelected={!isCount} onClick={() => setType('expense')} />
          </div>
        </div>
      )}
    </div>
  )
}

export default ThisYearDataView
