export const myPageMenu = [
  {
    label: '마이페이지',
    type: 'header',
  },
  {
    label: '나의 정보',
    type: 'group',
  },
  {
    label: '계정',
    type: 'link',
    href: '/portal/my-page/account',
    activePathname: ['/portal', '/portal/my-page'],
  },
  {
    label: '회원 정보',
    type: 'link',
    href: '/portal/my-page/settings',
  },
  {
    label: '비밀번호 변경',
    type: 'link',
    href: '/portal/my-page/change-password',
  },
  {
    label: '프로모션 정보',
    type: 'link',
    href: '/portal/my-page/promotion',
  },
  {
    label: '포인트 현황',
    type: 'link',
    href: '/portal/my-page/point',
  },
  {
    label: '쿠폰',
    type: 'link',
    href: '/portal/my-page/coupon',
  },
  {
    label: '비즈니스 관리',
    type: 'link',
    href: '/portal/my-page/business-management',
  },
  {
    label: '대리점 관리',
    type: 'link',
    href: '/portal/my-page/franchise-management',
  },
  {
    type: 'space',
  },
  {
    label: '결제',
    type: 'group',
  },
  {
    label: '결제내역',
    type: 'link',
    href: '/portal/my-page/payment-history',
  },
]

export const assetsMenu = [
  {
    label: '소재',
    type: 'group',
  },
  {
    id: 1,
    label: '제작 완료',
    type: 'action',
  },
  {
    id: 2,
    label: '내 컴퓨터',
    type: 'action',
  },
]

export const reportMenu = [
  {
    label: '1차',
    type: 'group',
  },
  {
    id: 1,
    label: '상세정보',
    type: 'link',
    href: '/portal/report/detail',
  },
  {
    id: 2,
    label: '리포트',
    type: 'link',
    href: '/portal/report',
  },
]

export const tutorialAssetMenu = [
  {
    label: '템플릿 고르기',
    type: 'group',
  },
  {
    id: 0,
    label: '추천 템플릿',
    type: 'action',
  },
  {
    id: 1,
    label: '광고효과',
    type: 'action',
  },
  {
    id: 2,
    label: '업종',
    type: 'action',
  },
  {
    id: 3,
    label: '템플릿 상세정보',
    type: 'action',
  },
  {
    type: 'space',
  },
  // {
  //   label: '소재 완성하기',
  //   type: 'group',
  // },
  // {
  //   id: 4,
  //   label: '글자 구성요소',
  //   type: 'action',
  // },
  // {
  //   id: 5,
  //   label: '사진 구성요소',
  //   type: 'action',
  // },
  // {
  //   id: 6,
  //   label: '영상 구성요소',
  //   type: 'action',
  // },
  // {
  //   id: 7,
  //   label: '음악 변경',
  //   type: 'action',
  // },
  // {
  //   id: 8,
  //   label: '완료',
  //   type: 'action',
  // },
  // {
  //   type: 'space',
  // },
  // {
  //   label: '렌더링',
  //   type: 'group',
  // },
  // {
  //   id: 9,
  //   label: '렌더링',
  //   type: 'action',
  // },
]
export const tutorialAdMenu = [
  {
    label: '나의 광고',
    type: 'group',
  },
  {
    id: 0,
    label: '모든 광고',
    type: 'action',
  },
  {
    id: 1,
    label: '광고 제작',
    type: 'action',
  },
  {
    id: 2,
    label: '광고 심사',
    type: 'action',
  },
  {
    id: 3,
    label: '광고 집행',
    type: 'action',
  },
  {
    type: 'space',
  },
  {
    label: '제작 과정',
    type: 'group',
  },
  {
    id: 4,
    label: '지역',
    type: 'action',
  },
  {
    id: 5,
    label: '기간',
    type: 'action',
  },
  {
    id: 6,
    label: '예산',
    type: 'action',
  },
  {
    id: 7,
    label: '소재',
    type: 'action',
  },
  {
    id: 8,
    label: '성우',
    type: 'action',
  },
]
