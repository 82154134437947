import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

import { useCookies } from 'react-cookie'
import { FormCurrencyTextbox } from '../../../components/currencyTextbox'
import InfoIcon from '../../../assets/icons/icon-filled-suggested-question-circle.svg'
import InputValidation from '../../../components/inputValidation'
import { calcBudget } from '../../../services/api'
import AppContext from '../../../services/context'
import { formatNumber } from '../../../services/utils'
//
// const MIN_BONUS_DAY = 90
// const MIN_BONUS_BUDGET_MONTHLY = 500000
const DATE_IN_MONTH = 31
//
let fetchTimeoutHandle = null
//
const BudgetSelector = () => {
  const { appState } = React.useContext(AppContext)
  const [cookies] = useCookies(['jwt_token'])
  const {
    getValues, setValue, watch, ...methods
  } = useFormContext()
  const [state, setState] = React.useState({ isError: false })
  const [priceData, setPriceData] = React.useState({})
  const [impression, setImpression] = React.useState({ default: 0, bonus: 0, multiplier: 0 })
  //
  const [
    ad_campaign_budget_value,
    ad_campaign_min_budget_value,
    ad_campaign_recommended_budget_value,
    ad_campaign_regions_value,
    ad_campaign_regions_count_value,
    ad_campaign_period_value,
    ad_campaign_period_month_value,
    ad_campaign_product_multiplier_value,
    ad_campaign_region_multiplier_value,
    ad_campaign_product_name_value,
    is_franchise,
    fixedBudget,
  ] = watch([
    'ad_campaign_budget',
    'ad_campaign_min_budget',
    'ad_campaign_recommended_budget',
    'ad_campaign_regions',
    'ad_campaign_regions_count',
    'ad_campaign_period',
    'ad_campaign_period_month',
    'ad_campaign_product_multiplier',
    'ad_campaign_region_multiplier',
    'productName',
    'is_franchise',
    'fixedBudget',
  ])
  const avgBudget = ad_campaign_period_value > 0 && !Number.isNaN(Number(ad_campaign_budget_value))
    ? Math.floor((Number(ad_campaign_budget_value) / ad_campaign_period_value) * DATE_IN_MONTH)
    : 0
  //
  React.useEffect(() => {
    if (fixedBudget !== ad_campaign_budget_value) {
      setValue('ad_campaign_budget', fixedBudget)
    }
  }, [fixedBudget])
  //
  React.useEffect(() => {
    setState({
      isError: methods.formState.isSubmitted && methods.formState.errors?.ad_campaign_budget,
    })
  }, [methods.formState])
  //
  React.useEffect(() => {
    window.clearTimeout(fetchTimeoutHandle)
    fetchTimeoutHandle = window.setTimeout(async () => {
      if (!ad_campaign_period_value || _.isNil(ad_campaign_period_month_value)) return
      const result = await calcBudget(
        cookies.jwt_token,
        ad_campaign_regions_value,
        ad_campaign_regions_count_value,
        ad_campaign_period_value,
        ad_campaign_period_month_value,
        ad_campaign_budget_value,
        ad_campaign_product_multiplier_value + ad_campaign_region_multiplier_value - 1,
        appState.userInfo?.userType || 0,
      )
      if (!result) return
      setPriceData(result)
      const { targetImpression, bonusMultiplier, bonusImpression } = result
      setImpression({
        default: targetImpression - bonusImpression,
        bonus: bonusImpression,
        multiplier: bonusMultiplier,
      })
      await setValue('ad_campaign_target_impression', targetImpression)
      await setValue('ad_campaign_bonus_impression', bonusImpression)
      await setValue('ad_campaign_bonus_multiplier', bonusMultiplier)
    }, 300)
  }, [
    ad_campaign_regions_value,
    ad_campaign_regions_count_value,
    ad_campaign_period_value,
    ad_campaign_period_month_value,
    ad_campaign_budget_value,
    ad_campaign_product_multiplier_value,
    ad_campaign_region_multiplier_value,
    // bonusCount,
    // bonusRule,
    setValue,
    getValues,
  ])

  //
  return (
    <div className={`create-ad-part map-selector${state.isError ? ' error' : ''}`}>
      <h3 className="mb-3">사용할 예산을 입력해주세요</h3>
      {/* {typeof fixedBudget === 'number' ? null : (
        <div className="flex justify-between">
          <p className="mb-3 text-sm">
            최소 예산:
            {' '}
            <strong>{`${formatNumber(ad_campaign_min_budget_value)}`}</strong>
            원
          </p>
          <p className="mb-3 text-sm text-brand">
            권장 예산:
            {' '}
            <strong>{`${formatNumber(ad_campaign_recommended_budget_value)}`}</strong>
            원
          </p>
        </div>
      )} */}
      <div className="flex justify-between">
        <p className="mb-3 text-sm">
          최소 예산:
          {' '}
          <strong>{`${formatNumber(ad_campaign_min_budget_value)}`}</strong>
          원
        </p>
        <p className="mb-3 text-sm text-brand">
          권장 예산:
          {' '}
          <strong>{`${formatNumber(ad_campaign_recommended_budget_value)}`}</strong>
          원
        </p>
      </div>
      <div className="flex flex-col justify-items-stretch justify-center">
        <div className="relative">
          {/**/}
          <FormCurrencyTextbox
            name="ad_campaign_budget"
            placeholder="최대 예산 이하의 예산을 입력해주세요"
            validators={{
              validate: { value_min: (val) => val >= 100000 },
            }}
            validations={['value_min']}
            disabled={is_franchise || typeof fixedBudget === 'number'}
          />
          {/**/}
          {/* {impression.needBudget > 0 && (
            <button
              type="button"
              className="absolute top-[88px] p-0 text-xs text-primary"
              onClick={() => {
                setValue(
                  'ad_campaign_budget',
                  `${Number(ad_campaign_budget_value) + impression.needBudget}`,
                )
              }}
            >
              {`* 예산 ${formatNumber(impression.needBudget)}원 추가 시 추가 노출 혜택이 제공됩니다.`}
            </button>
          )} */}
        </div>
        <div className="flex flex-row eval mb-4">
          <div className="flex-grow">
            <span>• 31일 평균 광고 예산</span>
          </div>
          <span>
            {formatNumber(avgBudget)}
            원
          </span>
        </div>
        <div className="flex flex-row eval">
          <div className="flex-grow">
            <span>• 특정지역 할증</span>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 250 }}
              trigger={['hover', 'focus', 'click']}
              overlay={(
                <Tooltip>
                  <div className="flex flex-col items-start">
                    <span>
                      할증율(30%) :
                      서울(강남구,광진구,서초구,송파구),경기(분당구,일산서구/동구,화성시),인천연수구,대구달서구,부산해운대구
                    </span>
                    <span>
                      할증율(20%) : 서울(강동구,노원구),경기(김포시,광명시,부천시,수원 영통/장안/팔달구,용인시),천안시
                    </span>
                  </div>
                </Tooltip>
              )}
            >
              <button type="button">
                <img src={InfoIcon} alt="info" />
              </button>
            </OverlayTrigger>
          </div>
          <span>
            {((ad_campaign_region_multiplier_value - 1) * 100).toFixed(0)}
            %
          </span>
        </div>
        <div className="flex flex-row eval">
          <div className="flex-grow">
            <span>• 추가옵션 할증</span>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 250 }}
              trigger={['hover', 'focus', 'click']}
              overlay={(
                <Tooltip>
                  <div className="flex flex-col items-start">
                    <span>{ad_campaign_product_name_value || '추가 옵션 없음'}</span>
                  </div>
                </Tooltip>
              )}
            >
              <button type="button">
                <img src={InfoIcon} alt="info" />
              </button>
            </OverlayTrigger>
          </div>
          <span>
            {((ad_campaign_product_multiplier_value - 1) * 100).toFixed(0)}
            %
          </span>
        </div>
        {impression.multiplier > 0 && (
          <div className="flex flex-row eval">
            <div className="flex-grow">
              <span>• 보너스율</span>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 250 }}
                trigger={['hover', 'focus', 'click']}
                overlay={(
                  <Tooltip>
                    월 평균 광고청약 금액 10~20만원 미만(20%), 20~50만원 미만(50%),50~100만원 미만(70%),100만이상(90%)
                  </Tooltip>
                )}
              >
                <button type="button">
                  <img src={InfoIcon} alt="info" />
                </button>
              </OverlayTrigger>
            </div>
            <span>{`${Math.floor(impression.multiplier * 100)}%`}</span>
          </div>
        )}
        <div className="flex flex-row eval mt-2">
          <div className="flex-grow">
            <span className="title">최대 예산</span>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 250 }}
              trigger={['hover', 'focus', 'click']}
              overlay={(
                <Tooltip>
                  선택하신 기간과 지역에 광고를 청약하실 수 있는 최대 예산 입니다. 해당 지역의 기간 동안 진행 중인
                  광고상황에 따라 최대 예산은 변동될 수 있습니다.
                </Tooltip>
              )}
            >
              <button type="button">
                <img src={InfoIcon} alt="info" />
              </button>
            </OverlayTrigger>
          </div>
        </div>
        <div className="flex flex-row eval">
          <div className="flex-grow">
            <span>• 15초 소재 광고</span>
          </div>
          <span>{`${formatNumber(priceData?.maxPrice?.[0])}원`}</span>
        </div>
        <div className="flex flex-row eval">
          <div className="flex-grow">
            <span>• 30초 소재 광고</span>
          </div>
          <span>{`${formatNumber(priceData?.maxPrice?.[1])}원`}</span>
        </div>
        <div className="eval-spacer" />
        {/**/}
        <div className="note">
          <span>꼭 알아두세요!</span>
          <span style={{ color: 'var(--text-secondary)' }}>• 최소 예산은 월 10만원 기준입니다.</span>
        </div>
        {/**/}
        <div className="flex flex-row amount">
          <div className="flex-grow">
            <span>광고노출 횟수</span>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 250 }}
              trigger={['hover', 'focus', 'click']}
              overlay={(
                <Tooltip>
                  {
                    '입력하신 예산에 따라 광고가 노출될 수 있는 가능한 총 노출 수를 의미합니다.\n광고가 종료되는 시점에 해당 노출 수를 모두 채우지 못하게 될 경우 해당 차액만큼 환불을 하시거나 광고를 추가로 연장하실 수 있습니다.'
                  }
                </Tooltip>
              )}
            >
              <button type="button">
                <img src={InfoIcon} alt="info" />
              </button>
            </OverlayTrigger>
          </div>
          <div className={`relative flex justify-end flex-1 ${impression.bonus > 0 ? 'mt-[-5px]' : ''}`}>
            <div className={`flex items-center ${impression.bonus > 0 ? 'text-primary' : ''}`}>
              <span>{formatNumber(impression.default + impression.bonus)}</span>
              <span>&nbsp;회</span>
            </div>
            {impression.bonus > 0 && (
              <div className="absolute top-[30px] right-0 text-xs text-textSecondary font-medium">
                <span>{`(기본 ${formatNumber(impression.default)}회`}</span>
                <span className="text-primary">{` + 추가 ${formatNumber(impression.bonus)}회)`}</span>
              </div>
            )}
          </div>
        </div>
        <input type="hidden" {...methods.register('ad_campaign_target_impression')} />
        <InputValidation names={['ad_campaign_budget']} validations={['value_min']} />
      </div>
    </div>
  )
}

export default BudgetSelector
