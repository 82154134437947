import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Navbar, Container, Breadcrumb, Nav,
} from 'react-bootstrap'
import {
  Tabs, TabList, Tab, TabPanel,
} from 'react-tabs'
import Footer from '../../components/footer'
import Tab1 from './tab1'
import Tab2 from './tab2'
import Tab3 from './tab3'
import { getAssetsCategories, getDesigns } from '../../services/api'
import AppContext from '../../services/context'
import TabFranchise from './tabFranchise'
//
const MakingAssets = () => {
  const { appState } = React.useContext(AppContext)
  const history = useHistory()
  const [cats, setCats] = React.useState({
    recommend: null,
    business: null,
    effect: null,
    style: null,
  })
  const [tab1Data, setTab1Data] = React.useState({
    loadState: 0,
    selected: {},
    designs: [],
  })
  const [loadValue, setLoadValue] = React.useState(0)
  //
  React.useEffect(() => {
    (async () => {
      const result = await getAssetsCategories()
      if (!result) return
      await setCats(result)
    })()
  }, [])
  //
  const onSelect = async (e, item) => {
    const altered = { ...tab1Data.selected }
    if (item.id in altered) {
      delete altered[item.id]
    } else {
      if (Object.keys(altered).length === 5) return
      altered[item.id] = item
    }
    await setTab1Data({ ...tab1Data, selected: altered })
  }
  //
  const onLoad = async () => {
    await setTab1Data({ ...tab1Data, loadState: 1 })
    const result = await getDesigns('recommend', Object.keys(tab1Data.selected))
    if (!result) return
    //
    let value = 0
    await new Promise((resolve) => {
      const intervalHandler = setInterval(async () => {
        if (value > 130) {
          window.clearInterval(intervalHandler)
          resolve('done')
          return
        }
        value += 3
        await setLoadValue(value)
      }, 60)
    })
    await setTab1Data({ ...tab1Data, designs: result.result, loadState: 2 })
  }
  //
  const onReset = async () => {
    await setLoadValue(0)
    await setTab1Data({
      loadState: 0,
      selected: {},
      designs: [],
    })
  }
  //
  return (
    <>
      <Navbar>
        <Container>
          <Nav className="me-auto">
            <Nav.Link className="go-back" onClick={() => history.goBack()}>
              나의광고 로 돌아가기
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <main className="flex flex-grow flex-col">
        <div className="page-headline" style={{ backgroundColor: 'var(--line-color)' }}>
          <Container>
            <h1 style={{ color: 'var(--text-primary)' }}>소재 만들기</h1>
          </Container>
        </div>
        <div className="page-content create-ad">
          <Container>
            <div className="breadcrumb-section">
              <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/portal/media' }}>
                  광고 만들기
                </Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/portal/media/create/2' }}>
                  소재 등록하기
                </Breadcrumb.Item>
                <Breadcrumb.Item active>소재 만들기</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            {/**/}
            <div className="tabs-section">
              <Tabs className="media-tabs tabs-secondary" selectedTabClassName="active">
                <TabList className="flex tab-list-secondary">
                  {appState.userInfo?.company?.companyType === 2 && (
                    <Tab className="tab-secondary px-8">전용 템플릿</Tab>
                  )}
                  <Tab className="tab-secondary px-8">추천 템플릿</Tab>
                  <Tab className="tab-secondary px-8">광고효과</Tab>
                  <Tab className="tab-secondary px-8">업종</Tab>
                </TabList>
                {appState.userInfo?.company?.companyType === 2 && (
                  <TabPanel>
                    <TabFranchise
                      cats={cats.recommend}
                      onLoad={onLoad}
                      onSelect={onSelect}
                      onReset={onReset}
                      loadState={tab1Data.loadState}
                      loadValue={loadValue}
                      selected={tab1Data.selected}
                      designs={tab1Data.designs}
                    />
                  </TabPanel>
                )}
                <TabPanel>
                  <Tab1
                    cats={cats.recommend}
                    onLoad={onLoad}
                    onSelect={onSelect}
                    onReset={onReset}
                    loadState={tab1Data.loadState}
                    loadValue={loadValue}
                    selected={tab1Data.selected}
                    designs={tab1Data.designs}
                  />
                </TabPanel>
                <TabPanel>
                  <Tab2 cats={cats.effect} styles={cats.style} />
                </TabPanel>
                <TabPanel>
                  <Tab3 cats={cats.business} styles={cats.style} />
                </TabPanel>
              </Tabs>
            </div>
          </Container>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default MakingAssets
