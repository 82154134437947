import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Footer from '../../components/footer'
import RecommendForm from './components/recommendForm'

const RecommendPage = () => {
  const history = useHistory()
  return (
    <>
      <Navbar>
        <Container>
          <Nav className="me-auto">
            <Nav.Link className="go-back" onClick={() => history.replace('/portal/media')}>
              나의광고 로 돌아가기
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <main className="flex flex-grow flex-col ad-campaign-layout">
        <div className="page-headline" style={{ backgroundColor: 'var(--line-color)' }}>
          <Container>
            <h1 style={{ color: 'var(--text-primary)' }}>광고 완성하기</h1>
          </Container>
        </div>
        <div className="page-content create-ad">
          <Container>
            <RecommendForm />
          </Container>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default RecommendPage
