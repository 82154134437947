import React, { useEffect } from 'react'
import cx from 'classnames'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { useCookies } from 'react-cookie'

import Radio from '../../../components/radio'
import { getBudgetData } from '../../../services/api'

const DATE_FORMAT = 'YYYY.MM.DD'

const RecommendBudget = ({
  step, category, handleRecommendBudget, closeModal,
}) => {
  const [cookies] = useCookies(['jwt_token'])
  const [budgetData, setBudgetData] = React.useState(null)

  const today = React.useMemo(() => new Date(), [])

  const shortUntilDate = React.useMemo(() => {
    const tempDate = new Date()
    tempDate.setMonth(tempDate.getMonth() + 2)
    return tempDate
  }, [])

  const longUntilDate = React.useMemo(() => {
    const tempDate = new Date()
    tempDate.setMonth(tempDate.getMonth() + 6)
    return tempDate
  }, [])

  useEffect(() => {
    if (category) {
      getBudgetData(cookies.jwt_token, category).then((data) => {
        setBudgetData(data)
      }).catch((err) => {
        console.log(err)
      })
    }
  }, [cookies.jwt_token, setBudgetData, category])

  const methods = useForm({
    defaultValues: {
      option: 'short',
    },
  })
  const wrapperCx = cx({
    hidden: step !== 2,
    'flex flex-col mx-auto': step === 2,
  })

  const onSubmit = async (data) => {
    if (data.option === 'short') {
      const startAt = today
      const endAt = shortUntilDate
      console.log(today, shortUntilDate)
      const budget = 600000
      handleRecommendBudget(startAt, endAt, budget)
    } else {
      const startAt = today
      const endAt = longUntilDate
      const budget = 2700000
      handleRecommendBudget(startAt, endAt, budget)
    }
    closeModal()
  }

  return (
    <div className={wrapperCx}>
      <h1 className="mb-4 text-[28px] leading-10 text-textPrimary font-bold text-center whitespace-pre-wrap">
        광고집행 가이드
      </h1>
      <h2 className="text-lg font-medium text-center whitespace-pre-wrap">
        {'고객님 업종의 광고주분들은\n평균 광고기간'}
        <span className="ml-1 font-bold text-2xl">{`${(budgetData?.avgMonths || 0).toFixed(1)}개월`}</span>
        , 월 평균 광고금액
        <span className="ml-1 font-bold text-2xl">{`${Math.round((budgetData?.avgAmount || 0) / 10000)}만원`}</span>
        을 집행하였습니다.
      </h2>
      <div className="bg-bgColor mx-[-32px] py-4 mb-8">
        <h2 className="m-0 text-xl text-center whitespace-pre-wrap">
          {'B tv 우리동네광고에서는 아래와 같이\n광고집행을 가이드 드립니다.'}
        </h2>
      </div>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <table className="w-full">
          <tbody>
            <tr>
              <td className="border-0" rowSpan={2}>
                <Radio
                  value="short"
                  {...methods.register('option')}
                />
              </td>
              <td className="w-[100px] font-bold whitespace-pre-wrap" rowSpan={2}>{'단기 집중\n노출형'}</td>
              <td className="text-sm font-medium text-start whitespace-pre-wrap">{'선택한 지역에 단기간 집중해 광고를 노출하는 형태로,짧은 기간에 고객님 매장의 인지도를 향상 시킬 수 있습니다.\n\n신규 매장 오픈시, 사은품, 할인 등 이벤트시, 단기간에 매장 홍보가 필요한 경우 등에 적합한 광고 형태입니다.'}</td>
            </tr>
            <tr>
              <td className="flex flex-col items-start font-bold border-y-0 border-l-0">
                <span>{`광고기간: ${moment(today).format(DATE_FORMAT)} ~ ${moment(shortUntilDate).format(DATE_FORMAT)}(2개월)`}</span>
                <span>광고비: 60만원(월 30만원)</span>
              </td>
            </tr>
            <tr>
              <td className="border-0" rowSpan={2}>
                <Radio
                  value="long"
                  {...methods.register('option')}
                />
              </td>
              <td className="w-[100px] font-bold whitespace-pre-wrap" rowSpan={2}>{'장기\n노출형'}</td>
              <td className="text-sm font-medium text-start whitespace-pre-wrap">{'선택한 지역에 장기간 광고를 노출하는 형태로, 지역에서 장기간 영업을 한 매장, 지속적인 고객 인지도 향상을 원하는 매장, 꾸준한 광고를 원하는 매장 등에 적합한 광고 형태입니다.\n\n광고기간 6개월을 제안드리며 종료 후에도 지속적인 광고를 제안드립니다.'}</td>
            </tr>
            <tr>
              <td className="flex flex-col items-start font-bold border-t-0 border-l-0">
                <span>{`광고기간: ${moment(today).format(DATE_FORMAT)} ~ ${moment(longUntilDate).format(DATE_FORMAT)}(6개월)`}</span>
                <span>광고비: 270만원(월 45만원)</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="flex flex-row justify-center space-x-4 mt-12">
          <button type="button" className="secondary min-w-[240px]" onClick={closeModal}>
            닫기
          </button>
          <button type="submit" className="primary font-bold min-w-[240px]">
            광고 청약
          </button>
        </div>
      </form>
    </div>
  )
}

export default RecommendBudget
