import React from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import moment from 'moment'
import NavigationLayout from '../../layouts/navigationLayout'
import { Maps } from '../../components/maps'
import CommentWrapper from './components/commentWrapper'
import ImageWrapper from './components/imageWrapper'
import { DesignModal } from '../making_assets/components/designPaper'

import AppContext from '../../services/context'
import useCommentWritter from './hooks/useCommentWritter'
import iconPin from '../../assets/icons/map-pin.svg'
import cmntIcon from '../../assets/icons/icon-filled-application-comment-26-px-primary.svg'
import telIcon from '../../assets/icons/icon-telephone.svg'
import {
  postCommunityComment, getGalleryDetail, postAsset, getSubtemplate, createCampaign,
} from '../../services/api'
import ChannelService from '../../services/channel'
import { ContentModal, TwoButtonModal } from '../../components/modals'
import ImageCarousel from '../media/components/ImageCarousel'
import { convertCompanyTel, getPromotionText } from '../../services/utils'
import LoadingIndicator from '../../components/LoadingIndicator'

const Line = () => <div className="w-full h-[2px] my-4 bg-line" />

const CommunityDetail = () => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const mapRef = React.useRef(null)
  const location = useLocation()
  const history = useHistory()
  const [cookies] = useCookies(['jwt_token'])
  const { enqueueSnackbar } = useSnackbar()
  const [comment, setComment] = React.useState('')
  // const [snippetData, setSnippetData] = React.useState(appState.currentPost)
  const [loading, setLoading] = React.useState(false)
  const [gallery, setGallery] = React.useState({})
  const [commentList, setCommentList] = React.useState([])
  const [modalVisible, setModalVisible] = React.useState(false)
  const [designModalVisible, setDesignModalVisible] = React.useState(false)
  const [design, setDesign] = React.useState(null)
  const [consultModalVisible, setConsultModalVisible] = React.useState(false)
  const [writter, renderWritterInput, initWritterInput] = useCommentWritter()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const galleryId = location.state?.galleryid || location.pathname.split('/')[2]
  //
  React.useEffect(() => {
    setLoading(true)
    getGalleryDetail(galleryId)
      .then((result) => {
        setGallery(result)
        setCommentList(result.comments)
        if (result.store?.storeAddress?.lat && result.store?.storeAddress?.lon) {
          const { lat, lon } = result.store.storeAddress
          mapRef.current.setMarker(lat, lon)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])
  //
  const onClickConsult = () => {
    history.push('/consult', {
      asset: {
        pathname: location.pathname,
        assetName: gallery.assetName,
      },
    })
  }
  //
  const onClickMakeAsset = async () => {
    if (!appState.userInfo || !appState.userInfo._id) {
      enqueueSnackbar('로그인이 필요합니다', { variant: 'warning' })
      return
    }
    const result = await getSubtemplate(gallery.assetId)
    if (result === null) {
      setConsultModalVisible(true)
      // enqueueSnackbar('자체 제작 광고영상으로 해당 컨셉으로 광고영상을 만들 수 없습니다.', { variant: 'warning' })
      return
    }
    setDesign(result)
    setDesignModalVisible(true)
  }
  //
  const onPostTemplate = async (e, data) => {
    await setDesignModalVisible(false)
    const result = await createCampaign(cookies.jwt_token)
    if (!result) {
      enqueueSnackbar('오류가 발생했습니다.', { variant: 'error' })
      return
    }
    await appDispatch({ type: 'updateAdCampaign', payload: result })
    const assetResult = await postAsset(cookies.jwt_token, data._id)
    if (!assetResult) {
      enqueueSnackbar('오류가 발생했습니다.', { variant: 'error' })
      return
    }
    //
    history.push({
      pathname: '/portal/media/build',
      state: {
        userId: appState.userInfo.id,
        assetId: assetResult._id,
      },
    })
  }
  //
  const onInputChange = async (e) => {
    await setComment(e.target.value)
  }
  //
  const onCommentClick = React.useCallback(async () => {
    if (!comment) return
    let result = null
    if (!appState.userInfo || !appState.userInfo._id) {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available')
        return
      }
      const token = await executeRecaptcha('comment')
      console.log('recaptcha token: ', token)
      result = await postCommunityComment(null, 'gallery', gallery._id, comment, token, writter)
      initWritterInput()
    } else {
      result = await postCommunityComment(cookies.jwt_token, 'gallery', gallery._id, comment)
    }
    if (!result) return
    result.user = {
      _id: result.user,
      userId: appState.userInfo?.userId,
    }
    await setCommentList((prev) => prev.concat([result]))
    await setComment('')
  }, [executeRecaptcha, appState.userInfo, comment, cookies.jwt_token, writter, gallery._id, initWritterInput])
  return (
    <NavigationLayout navigationTitle="광고 갤러리 돌아가기" backButtonVisible>
      <div className="bg-white">
        <Container>
          <div className="bg-white p-[24px]">
            <div>
              <video
                className="w-full"
                src={gallery.videoUrl}
                controls
                controlsList="nodownload"
                onContextMenu={(e) => e.preventDefault()}
              />
              <div className="lg:flex-grow lg:mt-6">
                <h3 className="mt-[16px] mb-[4px] lg:mt-0 lg:text-[30px] lg:mb-2">{`상호명: ${gallery.store?.companyName}`}</h3>
                <span className="text-sm text-textSecondary lg:text-[22px] lg:font-medium">
                  {`${
                    appState.galleryCategories.find((c) => c.code === gallery.store?.companySector)?.title
                  }`}
                </span>
                <div className="flex items-center mt-1">
                  <img className="w-[13px] mb-[-3px] mr-1" src={iconPin} alt="pin" />
                  <span>{`${gallery.store?.storeAddress?.address} ${gallery.store?.storeAddress?.addressDetail}`}</span>
                  <img className="w-5 h-5 mb-[-2px] mr-1 ml-4" src={telIcon} alt="tel" />
                  <span>{convertCompanyTel(gallery.store?.storeTel)}</span>
                </div>
              </div>
            </div>
            <Line />
            <h4 className="mb-2 font-black lg:text-[20px]">매장 소개</h4>
            <p className="text-textSecondary font-medium white whitespace-pre-wrap">{gallery.store?.storeDesc}</p>
            <div className="block my-4 lg:flex lg:justify-between lg:items-start">
              <ImageWrapper images={gallery.store?.storeImages} onClick={() => setModalVisible(true)} />
              <div className="mt-[1rem] lg:mt-0 lg:p-4 lg:border border-line">
                <div className="w-full aspect-video lg:w-[500px] lg:h-[300px]">
                  <Maps ref={mapRef} />
                </div>
                <div className="flex my-[16px]">
                  <img className="w-[17px]" src={iconPin} alt="pin" />
                  <span className="w-full ml-[0.75rem] text-textSecondary font-medium truncate lg:w-[324px]">{`${gallery.store?.storeAddress?.address} ${gallery.store?.storeAddress?.addressDetail}`}</span>
                </div>
              </div>
            </div>
            {gallery.promotion && gallery.promotion?.promotionVisible !== false && (
              <>
                <Line />
                <div className="flex items-center">
                  <h4 className="!mb-0 font-black lg:text-[20px]">이벤트</h4>
                  {gallery.promotion?.promotionLink && (
                    <a
                      className="ml-2 px-2 py-1 bg-main text-white text-sm font-bold rounded-lg"
                      href={gallery.promotion?.promotionLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>바로가기</span>
                    </a>
                  )}
                </div>
                <div className="flex flex-col my-[16px] font-medium">
                  <span className="text-main text-[20px]">
                    {getPromotionText(gallery.promotion?.promotionType, gallery.promotion?.promotionValue)}
                  </span>
                  <span className="mt-2 text-textPrimary">
                    {`${moment(gallery.promotion?.promotionStartDate).format('YYYY.MM.DD')} ~ ${
                      gallery.promotion?.promotionEndDate
                        ? moment(gallery.promotion?.promotionEndDate).format('YYYY.MM.DD')
                        : '미정'
                    }`}
                  </span>
                </div>
                <p className="text-textSecondary font-medium whitespace-pre-wrap">{gallery.promotion?.promotionDesc}</p>
              </>
            )}
            {gallery.store?.storeTel && (
              <>
                <h4 className="mt-4 font-black lg:text-[20px]">연락처</h4>
                <span className="font-medium text-[20px] text-textPrimary">
                  {convertCompanyTel(gallery.store?.storeTel)}
                </span>
              </>
            )}
            <div className="flex mt-6 mlg:!flex-col-reverse justify-center mlg:bg-white lg:mt-12 lg:mb-12">
              <button
                className="w-full secondary larger rounded !m-0 mlg:!min-h-[50px] mlg:!text-base"
                onClick={onClickConsult}
              >
                해당 컨셉으로 광고 상담
              </button>
              <div className="hidden lg:block w-4" />
              <button
                className="w-full primary larger mlg:rounded-[10px] mlg:!min-h-[50px] !mx-0 mlg:!mb-4 mlg:rounded-br-none mlg:!text-base"
                onClick={onClickMakeAsset}
              >
                해당 컨셉으로 광고 제작
              </button>
            </div>
            {/* <Line />
            <div className="flex lg:hidden">
              <Link
                to={{
                  pathname: `/board/gallery/post/${gallery._id}/comment`,
                  state: {
                    _id: gallery._id,
                    comment: gallery.comments,
                  },
                }}
                className="flex items-center reaction-button"
              >
                <img src={cmntIcon} alt="cmnt" />
                <span>{`댓글 수 ${gallery.commentCnt}`}</span>
              </Link>
            </div>
            <div className="hidden lg:block">
              <h4 className="mb-3 font-black lg:text-[20px]">댓글</h4>
              {!appState.userInfo?.userId ? (
                renderWritterInput()
              ) : (
                <div className="commenter-id">{appState.userInfo?.userId}</div>
              )}
              <div className="textbox-action flex flex-row mt-2">
                <textarea
                  onChange={onInputChange}
                  className="flex-grow large border rounded-lg"
                  placeholder="댓글을 남겨주세요."
                  value={comment}
                  rows={1}
                />
                <button className="large dark min-w-[114px]" onClick={onCommentClick} disabled={comment === ''}>
                  등록
                </button>
              </div>
              <div className="comments-section">
                {commentList
                  && commentList.map((item, index) => (
                    <CommentWrapper key={index} commentData={item} postId={gallery._id} />
                  ))}
              </div>
            </div>
            {commentList.length === 0 && (
              <div className="comments-section-empty">
                <span>댓글이 없습니다.</span>
              </div>
            )} */}
          </div>
        </Container>
        <ContentModal
          isShown={modalVisible}
          title="상세 이미지"
          onClose={() => setModalVisible(false)}
          style={{}}
          buttonStyle={{
            maxWidth: 108,
            alignSelf: 'flex-end',
            marginBottom: 32,
            marginRight: 37,
          }}
          buttonText="닫기"
        >
          <div className="w-full">
            <ImageCarousel data={gallery.store?.storeImages} />
          </div>
        </ContentModal>
        <DesignModal
          isShown={designModalVisible}
          data={design}
          onClose={() => setDesignModalVisible(false)}
          onPostTemplate={onPostTemplate}
        />
        {consultModalVisible && (
          <TwoButtonModal
            title="해당 광고 영상은 추가적인 상담이 필요합니다. 채팅 상담을 연결해 드릴까요?"
            primaryText="상담 연결"
            secondaryText="취소"
            onClickPrimary={() => {
              if (!appState?.channelTalkBooted) {
                ChannelService.boot()
                appDispatch({ type: 'updateChannelTalkBooted', payload: true })
                ChannelService.showMessenger()
              }
              ChannelService.openChat()
              setConsultModalVisible(false)
            }}
            onClickSecondary={() => {
              setConsultModalVisible(false)
            }}
            onClose={() => {
              setConsultModalVisible(false)
            }}
          >
            {/* {<p className="text-lg mb-0">해당 광고 영상은 추가적인 상담이 필요합니다. 채팅 상담을 연결해 드릴까요?</p>} */}
          </TwoButtonModal>
        )}
        {loading && <LoadingIndicator />}
      </div>
    </NavigationLayout>
  )
}

const CaptchaWrapped = () => (
  <GoogleReCaptchaProvider reCaptchaKey="6LeJdeAcAAAAAHBYBocoZ5czU4gCIWoLPx9KWbtS" useEnterprise>
    <CommunityDetail />
  </GoogleReCaptchaProvider>
)

export default CaptchaWrapped
