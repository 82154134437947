// asset images
import imgAsset0_0 from '../../assets/tutorials/tutorial-asset-0-0@3x.webp'
import imgAsset1_0 from '../../assets/tutorials/tutorial-asset-1-0@3x.webp'
import imgAsset1_1 from '../../assets/tutorials/tutorial-asset-1-1@3x.webp'
import imgAsset1_2 from '../../assets/tutorials/tutorial-asset-1-2@3x.webp'
import imgAsset1_3 from '../../assets/tutorials/tutorial-asset-1-3@3x.webp'
import imgAsset2_0 from '../../assets/tutorials/tutorial-asset-2-0@3x.webp'
import imgAsset2_1 from '../../assets/tutorials/tutorial-asset-2-1@3x.webp'
import imgAsset2_2 from '../../assets/tutorials/tutorial-asset-2-2@3x.webp'
import imgAsset2_3 from '../../assets/tutorials/tutorial-asset-2-3@3x.webp'
import imgAsset3_0 from '../../assets/tutorials/tutorial-asset-3-0@3x.webp'
import imgAsset3_1 from '../../assets/tutorials/tutorial-asset-3-1@3x.webp'

// asset large images
import imgAssetLarge0_0 from '../../assets/tutorials/tutorial-large-asset-0-0@3x.webp'
import imgAssetLarge1_0 from '../../assets/tutorials/tutorial-large-asset-1-0@3x.webp'
import imgAssetLarge1_1 from '../../assets/tutorials/tutorial-large-asset-1-1@3x.webp'
import imgAssetLarge1_2 from '../../assets/tutorials/tutorial-large-asset-1-2@3x.webp'
import imgAssetLarge1_3 from '../../assets/tutorials/tutorial-large-asset-1-3@3x.webp'
import imgAssetLarge2_0 from '../../assets/tutorials/tutorial-large-asset-2-0@3x.webp'
import imgAssetLarge2_1 from '../../assets/tutorials/tutorial-large-asset-2-1@3x.webp'
import imgAssetLarge2_2 from '../../assets/tutorials/tutorial-large-asset-2-2@3x.webp'
import imgAssetLarge2_3 from '../../assets/tutorials/tutorial-large-asset-2-3@3x.webp'
import imgAssetLarge3_0 from '../../assets/tutorials/tutorial-large-asset-3-0@3x.webp'
import imgAssetLarge3_1 from '../../assets/tutorials/tutorial-large-asset-3-1@3x.webp'

// ad images
import imgAd0_0 from '../../assets/tutorials/tutorial-ad-0-0@3x.webp'
import imgAd0_1 from '../../assets/tutorials/tutorial-ad-0-1@3x.webp'
import imgAd1_0 from '../../assets/tutorials/tutorial-ad-1-0@3x.webp'
import imgAd1_1 from '../../assets/tutorials/tutorial-ad-1-1@3x.webp'
import imgAd1_2 from '../../assets/tutorials/tutorial-ad-1-2@3x.webp'
import imgAd1_3 from '../../assets/tutorials/tutorial-ad-1-3@3x.webp'
import imgAd2_0 from '../../assets/tutorials/tutorial-ad-2-0@3x.webp'
import imgAd2_1 from '../../assets/tutorials/tutorial-ad-2-1@3x.webp'
import imgAd2_2 from '../../assets/tutorials/tutorial-ad-2-2@3x.webp'
import imgAd3_0 from '../../assets/tutorials/tutorial-ad-3-0@3x.webp'
import imgAd3_1 from '../../assets/tutorials/tutorial-ad-3-1@3x.webp'
import imgAd3_2 from '../../assets/tutorials/tutorial-ad-3-2@3x.webp'
import imgAd4_0 from '../../assets/tutorials/tutorial-ad-4-0@3x.webp'
import imgAd4_1 from '../../assets/tutorials/tutorial-ad-4-1@3x.webp'
import imgAd4_2 from '../../assets/tutorials/tutorial-ad-4-2@3x.webp'
import imgAd4_3 from '../../assets/tutorials/tutorial-ad-4-3@3x.webp'
import imgAd5_0 from '../../assets/tutorials/tutorial-ad-5-0@3x.webp'
import imgAd5_1 from '../../assets/tutorials/tutorial-ad-5-1@3x.webp'
import imgAd5_2 from '../../assets/tutorials/tutorial-ad-5-2@3x.webp'
import imgAd6_0 from '../../assets/tutorials/tutorial-ad-6-0@3x.webp'
import imgAd7_0 from '../../assets/tutorials/tutorial-ad-7-0@3x.webp'
import imgAd7_1 from '../../assets/tutorials/tutorial-ad-7-1@3x.webp'

// ad large images
import imgAdLarge0_0 from '../../assets/tutorials/tutorial-large-ad-0-0@3x.webp'
import imgAdLarge0_1 from '../../assets/tutorials/tutorial-large-ad-0-1@3x.webp'
import imgAdLarge1_0 from '../../assets/tutorials/tutorial-large-ad-1-0@3x.webp'
import imgAdLarge1_1 from '../../assets/tutorials/tutorial-large-ad-1-1@3x.webp'
import imgAdLarge1_2 from '../../assets/tutorials/tutorial-large-ad-1-2@3x.webp'
import imgAdLarge1_3 from '../../assets/tutorials/tutorial-large-ad-1-3@3x.webp'
import imgAdLarge2_0 from '../../assets/tutorials/tutorial-large-ad-2-0@3x.webp'
import imgAdLarge2_1 from '../../assets/tutorials/tutorial-large-ad-2-1@3x.webp'
import imgAdLarge2_2 from '../../assets/tutorials/tutorial-large-ad-2-2@3x.webp'
import imgAdLarge3_0 from '../../assets/tutorials/tutorial-large-ad-3-0@3x.webp'
import imgAdLarge3_1 from '../../assets/tutorials/tutorial-large-ad-3-1@3x.webp'
import imgAdLarge3_2 from '../../assets/tutorials/tutorial-large-ad-3-2@3x.webp'
import imgAdLarge4_0 from '../../assets/tutorials/tutorial-large-ad-4-0@3x.webp'
import imgAdLarge4_1 from '../../assets/tutorials/tutorial-large-ad-4-1@3x.webp'
import imgAdLarge4_2 from '../../assets/tutorials/tutorial-large-ad-4-2@3x.webp'
import imgAdLarge4_3 from '../../assets/tutorials/tutorial-large-ad-4-3@3x.webp'
import imgAdLarge5_0 from '../../assets/tutorials/tutorial-large-ad-5-0@3x.webp'
import imgAdLarge5_1 from '../../assets/tutorials/tutorial-large-ad-5-1@3x.webp'
import imgAdLarge5_2 from '../../assets/tutorials/tutorial-large-ad-5-2@3x.webp'
import imgAdLarge6_0 from '../../assets/tutorials/tutorial-large-ad-6-0@3x.webp'
import imgAdLarge7_0 from '../../assets/tutorials/tutorial-large-ad-7-0@3x.webp'
import imgAdLarge7_1 from '../../assets/tutorials/tutorial-large-ad-7-1@3x.webp'
import imgAdLarge8_0 from '../../assets/tutorials/tutorial-large-ad-8-0.png'
import imgAdLarge8_1 from '../../assets/tutorials/tutorial-large-ad-8-1.png'
import imgAdLarge8_2 from '../../assets/tutorials/tutorial-large-ad-8-2.png'
import imgAdLarge8_3 from '../../assets/tutorials/tutorial-large-ad-8-3.png'
import imgAdLarge8_4 from '../../assets/tutorials/tutorial-large-ad-8-4.png'
import imgAdLarge8_5 from '../../assets/tutorials/tutorial-large-ad-8-5.png'
import imgAdLarge8_6 from '../../assets/tutorials/tutorial-large-ad-8-6.png'
import imgAdLarge8_7 from '../../assets/tutorials/tutorial-large-ad-8-7.png'
import imgAdLarge8_8 from '../../assets/tutorials/tutorial-large-ad-8-8.png'
import imgAdLarge8_9 from '../../assets/tutorials/tutorial-large-ad-8-9.png'
import imgAdLarge8_10 from '../../assets/tutorials/tutorial-large-ad-8-10.png'
import imgAdLarge8_11 from '../../assets/tutorials/tutorial-large-ad-8-11.png'
import imgAdLarge8_12 from '../../assets/tutorials/tutorial-large-ad-8-12.png'

export const assetResources = [
  {
    title: '추천 템플릿',
    images: [imgAsset0_0],
    largeImages: [imgAssetLarge0_0],
  },
  {
    title: '광고 효과',
    images: [imgAsset1_0, imgAsset1_1, imgAsset1_2, imgAsset1_3],
    largeImages: [
      imgAssetLarge1_0,
      imgAssetLarge1_1,
      imgAssetLarge1_2,
      imgAssetLarge1_3,
    ],
  },
  {
    title: '업종',
    images: [imgAsset2_0, imgAsset2_1, imgAsset2_2, imgAsset2_3],
    largeImages: [
      imgAssetLarge2_0,
      imgAssetLarge2_1,
      imgAssetLarge2_2,
      imgAssetLarge2_3,
    ],
  },
  {
    title: '템플릿 상세정보',
    images: [imgAsset3_0, imgAsset3_1],
    largeImages: [imgAssetLarge3_0, imgAssetLarge3_1],
  },
  {
    title: '글자 구성요소',
    images: [],
    largeImages: [],
  },
  {
    title: '사진 구성요소',
    images: [],
    largeImages: [],
  },
  {
    title: '영상 구성요소',
    images: [],
    largeImages: [],
  },
  {
    title: '음악 변경',
    images: [],
    largeImages: [],
  },
  {
    title: '완료',
    images: [],
    largeImages: [],
  },
  {
    title: '렌더링',
    images: [],
    largeImages: [],
  },
]

export const adResources = [
  {
    title: '모든 광고',
    images: [imgAd0_0, imgAd0_1],
    largeImages: [imgAdLarge0_0, imgAdLarge0_1],
  },
  {
    title: '광고 제작',
    images: [imgAd1_0, imgAd1_1, imgAd1_2, imgAd1_3],
    largeImages: [imgAdLarge1_0, imgAdLarge1_1, imgAdLarge1_2, imgAdLarge1_3],
  },
  {
    title: '광고 심사',
    images: [imgAd2_0, imgAd2_1, imgAd2_2],
    largeImages: [imgAdLarge2_0, imgAdLarge2_1, imgAdLarge2_2],
  },
  {
    title: '광고 집행',
    images: [imgAd3_0, imgAd3_1, imgAd3_2],
    largeImages: [imgAdLarge3_0, imgAdLarge3_1, imgAdLarge3_2],
  },
  {
    title: '지역',
    images: [imgAd4_0, imgAd4_1, imgAd4_2, imgAd4_3],
    largeImages: [imgAdLarge4_0, imgAdLarge4_1, imgAdLarge4_2, imgAdLarge4_3],
  },
  {
    title: '기간',
    images: [imgAd5_0, imgAd5_1, imgAd5_2],
    largeImages: [imgAdLarge5_0, imgAdLarge5_1, imgAdLarge5_2],
  },
  {
    title: '예산',
    images: [imgAd6_0],
    largeImages: [imgAdLarge6_0],
  },
  {
    title: '소재',
    images: [imgAd7_0, imgAd7_1],
    largeImages: [imgAdLarge7_0, imgAdLarge7_1],
  },
  {
    title: '성우',
    images: [
      imgAdLarge8_0,
      imgAdLarge8_1,
      imgAdLarge8_2,
      imgAdLarge8_3,
      imgAdLarge8_4,
      imgAdLarge8_5,
      imgAdLarge8_6,
      imgAdLarge8_7,
      imgAdLarge8_8,
      imgAdLarge8_9,
      imgAdLarge8_10,
      imgAdLarge8_11,
      imgAdLarge8_12,
    ],
    largeImages: [
      imgAdLarge8_0,
      imgAdLarge8_1,
      imgAdLarge8_2,
      imgAdLarge8_3,
      imgAdLarge8_4,
      imgAdLarge8_5,
      imgAdLarge8_6,
      imgAdLarge8_7,
      imgAdLarge8_8,
      imgAdLarge8_9,
      imgAdLarge8_10,
      imgAdLarge8_11,
      imgAdLarge8_12,
    ],
  },
]
