import React from 'react'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import AppContext from '../../services/context'
import BasicLayout from '../../layouts/basic'
import indivisual from '../../assets/imgs/indivisual.svg'
import agency from '../../assets/imgs/agency.svg'
import targetRight from '../../assets/icons/icon-outlined-directional-right-large-primary.svg'
import MessageModal from '../../components/modals'

const SignUpType = () => {
  const history = useHistory()
  const { appDispatch } = React.useContext(AppContext)
  const [modalVisible, setModalVisible] = React.useState(false)

  const startSignUp = async (type) => {
    await appDispatch({
      type: 'updateSignUpMode',
      payload: { type: 'type', content: type },
    })
    history.push('/sign-up/step/3')
  }

  return (
    <BasicLayout>
      <Container className="auth-paper">
        <div className="flex flex-col justify-items-stretch items-center">
          <div className="header">
            <h1 className="text-center">회원유형 선택</h1>
            <p>
              <span>해당되는 회원 유형을 선택해주세요</span>
            </p>
          </div>
          <div className="content">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
              <div className="w-full choice flex flex-col items-center text-center">
                <h3>일반 회원</h3>
                <p className="small">일반 고객 전용</p>
                <div className="w-full inset-0 flex items-center">
                  <div className="line w-full" />
                </div>
                <img src={indivisual} alt="indivisual" />
                <p>
                  개인, 법인사업자 등 전반적인 서비스를 이용하고자 하는 고객
                </p>

                <button
                  className="secondary large right-icon"
                  onClick={() => startSignUp('INDIVISUAL')}
                >
                  일반 회원가입
                  <img src={targetRight} alt="target-right" />
                </button>
              </div>
              <div className="w-full choice flex flex-col items-center text-center">
                <h3>에이전시 회원</h3>
                <p className="small">에이전시업체 전용</p>
                <div className="w-full inset-0 flex items-center">
                  <div className="line w-full" />
                </div>
                <img src={agency} alt="agency" />
                <p>에이전시 업체에서 전반적인 서비스를 이용하고자 하는 고객</p>

                <button
                  className="secondary large right-icon"
                  onClick={() => setModalVisible(true)}
                >
                  에이전시 회원가입
                  <img src={targetRight} alt="target-right" />
                </button>
              </div>
            </div>
            <div className="w-full auth-note">
              <span>꼭 알아두세요!</span>
              <span style={{ color: 'var(--text-secondary)' }}>
                • 회원가입 이후 회원 유형 변경은 불가능합니다
              </span>
            </div>
          </div>
        </div>
      </Container>
      <MessageModal type="warning" isShown={modalVisible} onClose={() => startSignUp('AGENCY')}>
        <p className="text-center">
          에이전시 회원은 당사와 영업 대행 계약을 체결한 협력사에게 자격이 부여됩니다.
          <br />
          <br />
          당사와 영업 대행을 희망하시는 경우,
          <br />
          소개 자료를
          {' '}
          <strong>sk_btvtownad@skbroadband.com</strong>
          으로 보내주시면 검토후 연락드리겠습니다.
          <br />
          ※회사 소개서 및 영업 계획서 (양식 자유)
        </p>
      </MessageModal>
    </BasicLayout>
  )
}

export default SignUpType
