import React from 'react'

import AppContext from '../services/context'
import ChannelService from '../services/channel'

const ChannelTalk = () => {
  const { appState, appDispatch } = React.useContext(AppContext)

  const onClick = () => {
    ChannelService.boot()
    ChannelService.showMessenger()
    appDispatch({ type: 'updateChannelTalkBooted', payload: true })
  }

  return (
    !appState?.channelTalkBooted && !appState?.channelTalkHide && (
      <div
        style={{
          boxShadow: 'rgba(255, 255, 255, 0.12) 0px 0px 2px 0px inset, rgba(0, 0, 0, 0.05) 0px 0px 2px 1px, rgba(0, 0, 0, 0.22) 0px 4px 20px',
          borderRadius: 26,
          backdropFilter: 'blur(30px)',
        }}
        className="fixed bottom-6 right-6 w-[60px] h-[60px] z-[10000000] bg-white cursor-pointer"
        onClick={onClick}
      >
        <div className="absolute inset-0 w-12 h-12 m-auto">
          <img
            className="absolute"
            src="https://cf.channel.io/asset/plugin/images/shadow-body.png"
            alt=""
            role="presentation"
            color="#ff8e00"
            defaultopacity="1"
            hoveredopacity="1"
            margintop="0"
            marginright="0"
            marginbottom="0"
            marginleft="0"
            withtheme="false"
          />
          <svg
            className="absolute"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            foundation="[object Object]"
            color="#ff8e00"
            defaultopacity="1"
            hoveredopacity="1"
            margintop="0"
            marginright="0"
            marginbottom="0"
            marginleft="0"
            withtheme="false"
          >
            <path
              fill="#ff8e00"
              fillOpacity="0.6"
              d="M24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42C26.5503 42 28.9767 41.4696 31.175 40.5132C32.4937 39.9394 33.9715 39.7362 35.3397 40.1796L39.7048 41.5942C40.8697 41.9717 41.9717 40.8697 41.5942 39.7048L40.1796 35.3397C39.7362 33.9715 39.9394 32.4937 40.5132 31.175C41.4696 28.9767 42 26.5503 42 24C42 14.0589 33.9411 6 24 6Z"
            />
          </svg>
          <img
            className="absolute w-12 h-12"
            src="https://cf.channel.io/asset/plugin/images/gradient-body.png"
            alt=""
            role="presentation"
            color="#ff8e00"
            defaultopacity="1"
            hoveredopacity="1"
            margintop="0"
            marginright="0"
            marginbottom="0"
            marginleft="0"
            withtheme="false"
          />
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="#ff8e00"
            defaultopacity="1"
            hoveredopacity="1"
            margintop="0"
            marginright="0"
            marginbottom="0"
            marginleft="0"
            withtheme="false"
            className="absolute"
          >
            <g filter="url(#filter0_i)">
              <path
                fill="white"
                fillOpacity="0.01"
                d="M24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42C26.5503 42 28.9767 41.4696 31.175 40.5132C32.4937 39.9394 33.9715 39.7362 35.3397 40.1796L39.7048 41.5942C40.8697 41.9717 41.9717 40.8697 41.5942 39.7048L40.1796 35.3397C39.7362 33.9715 39.9394 32.4937 40.5132 31.175C41.4696 28.9767 42 26.5503 42 24C42 14.0589 33.9411 6 24 6Z"
              />
            </g>
            <defs>
              <filter id="filter0_i" x="4" y="2" width="38" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="-2" dy="-4" />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.5568627450980392 0 0 0 0 0 0 0 0 0.2 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
              </filter>
            </defs>
          </svg>
          <svg
            className="absolute"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="#ff8e00"
            defaultopacity="1"
            hoveredopacity="1"
            margintop="0"
            marginright="0"
            marginbottom="0"
            marginleft="0"
            withtheme="false"
          >
            <g filter="url(#filter0_f)">
              <path fill="white" fillOpacity="0.15" d="M19.8462 20.1538C19.8462 22.448 19.2404 24.3077 17.4231 24.3077C15.6058 24.3077 15 22.448 15 20.1538C15 17.8597 15.6058 16 17.4231 16C19.2404 16 19.8462 17.8597 19.8462 20.1538Z" />
              <path
                fill="white"
                fillOpacity="0.15"
                d="M24.0001 29.8462C21.201 29.8462 19.7599 27.5828 19.3109 25.9843C19.1615 25.4526 19.6016 25.0001 20.1539 25.0001H27.8462C28.3985 25.0001 28.8386 25.4526 28.6893 25.9843C28.2402 27.5828 26.7992 29.8462 24.0001 29.8462Z"
              />
              <path
                fill="white"
                fillOpacity="0.15"
                d="M30.5768 24.3077C32.3941 24.3077 32.9999 22.448 32.9999 20.1538C32.9999 17.8597 32.3941 16 30.5768 16C28.7595 16 28.1537 17.8597 28.1537 20.1538C28.1537 22.448 28.7595 24.3077 30.5768 24.3077Z"
              />
            </g>
            <path fill="#ff8e00" d="M19.8462 19.1538C19.8462 21.448 19.2404 23.3077 17.4231 23.3077C15.6058 23.3077 15 21.448 15 19.1538C15 16.8597 15.6058 15 17.4231 15C19.2404 15 19.8462 16.8597 19.8462 19.1538Z" />
            <path fill="#17113F" fillOpacity="0.7" d="M19.8462 19.1538C19.8462 21.448 19.2404 23.3077 17.4231 23.3077C15.6058 23.3077 15 21.448 15 19.1538C15 16.8597 15.6058 15 17.4231 15C19.2404 15 19.8462 16.8597 19.8462 19.1538Z" />
            <path
              fill="#ff8e00"
              d="M24.0001 28.8462C21.201 28.8462 19.7599 26.5828 19.3109 24.9843C19.1615 24.4526 19.6016 24.0001 20.1539 24.0001H27.8462C28.3985 24.0001 28.8386 24.4526 28.6893 24.9843C28.2402 26.5828 26.7992 28.8462 24.0001 28.8462Z"
            />
            <path
              fill="#17113F"
              fillOpacity="0.7"
              d="M24.0001 28.8462C21.201 28.8462 19.7599 26.5828 19.3109 24.9843C19.1615 24.4526 19.6016 24.0001 20.1539 24.0001H27.8462C28.3985 24.0001 28.8386 24.4526 28.6893 24.9843C28.2402 26.5828 26.7992 28.8462 24.0001 28.8462Z"
            />
            <path fill="#ff8e00" d="M30.5768 23.3077C32.3941 23.3077 32.9999 21.448 32.9999 19.1538C32.9999 16.8597 32.3941 15 30.5768 15C28.7595 15 28.1537 16.8597 28.1537 19.1538C28.1537 21.448 28.7595 23.3077 30.5768 23.3077Z" />
            <path fill="#17113F" fillOpacity="0.7" d="M30.5768 23.3077C32.3941 23.3077 32.9999 21.448 32.9999 19.1538C32.9999 16.8597 32.3941 15 30.5768 15C28.7595 15 28.1537 16.8597 28.1537 19.1538C28.1537 21.448 28.7595 23.3077 30.5768 23.3077Z" />
            <defs>
              <filter id="filter0_f" x="13" y="14" width="21.9999" height="17.8462" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur" />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
    )
  )
}

export default ChannelTalk
