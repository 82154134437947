import React from 'react'
import { useForm, FormProvider, useWatch } from 'react-hook-form'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'

import MyPageLayout from '../../layouts/myPageLayout'
import { FormTextboxGroup } from '../../components/formTextbox'
import { submitChangePwdForm } from '../../services/api'
//
const ChangePassword = () => {
  const { enqueueSnackbar } = useSnackbar()
  const methods = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
  })
  const [cookies] = useCookies(['jwt_token'])
  const current_password = useWatch({
    name: 'current_password',
    control: methods.control,
  })
  const password = useWatch({ name: 'password', control: methods.control })
  const confirm_password = useWatch({
    name: 'confirm_password',
    control: methods.control,
  })
  //
  const [state, setState] = React.useState({
    disabled: true,
  })
  //
  React.useEffect(() => {
    const empty = [current_password, password, confirm_password].every(
      (item) => item === '' || item === undefined,
    )
    if (empty) setState({ disabled: true })
    else setState({ disabled: false })
  }, [current_password, password, confirm_password, setState])
  //
  const onSubmit = async (data) => {
    const result = await submitChangePwdForm(cookies.jwt_token, data)
    if (result.code === 'FAIL') {
      enqueueSnackbar(result.message, { variant: 'error' })
      return
    }
    if (result.status === 401) {
      enqueueSnackbar('현재 비밀번호가 일치하지 않습니다.', {
        variant: 'error',
      })
      return
    }
    if (result.code === 'SUCCESS') {
      enqueueSnackbar('비밀번호가 변경되었습니다.', { variant: 'success' })
    }
  }
  return (
    <MyPageLayout navigationTitle="비밀번호 변경">
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div>
          <div className="header">
            <h3>비밀번호 변경</h3>
          </div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <FormTextboxGroup
                label="현재 비밀번호"
                name="current_password"
                type="password"
                autoComplete="off"
                placeholder="비밀번호를 입력해주세요"
                validators={{
                  required: true,
                  pattern: /^((?=.*\d)(?=.*[a-z])(?=.*[\W]).{8,16})$/,
                }}
                validations={['required', 'pattern']}
              />
              <FormTextboxGroup
                label="변경할 비밀번호"
                name="password"
                type="password"
                autoComplete="off"
                placeholder="비밀번호를 입력해주세요"
                validators={{
                  required: true,
                  pattern: /^((?=.*\d)(?=.*[a-z])(?=.*[\W]).{8,16})$/,
                }}
                validations={['required', 'pattern']}
              />
              <FormTextboxGroup
                label="변경할 비밀번호 확인"
                name="confirm_password"
                type="password"
                autoComplete="off"
                placeholder="비밀번호를 입력해주세요"
                validators={{
                  required: true,
                  validate: {
                    unmatched: (v) => v === methods.getValues('password'),
                  },
                }}
                validations={['required', 'unmatched']}
              />
              <button
                className="w-full primary large"
                type="submit"
                style={{ marginTop: '56px' }}
                disabled={state.disabled}
              >
                비밀번호 변경하기
              </button>
            </form>
          </FormProvider>
        </div>
      </div>
    </MyPageLayout>
  )
}

export default ChangePassword
