import React from 'react'

const useScrollDirection = () => {
  const [scrollDir, setScrollDir] = React.useState('UP')

  React.useEffect(() => {
    let lastScrollY = window.scrollY

    const updateScrollDir = () => {
      const { scrollY } = window
      const direction = scrollY > lastScrollY ? 'DOWN' : 'UP'
      if (direction !== scrollDir && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDir(direction)
      }
      lastScrollY = scrollY > 0 ? scrollY : 0
    }

    window.addEventListener('scroll', updateScrollDir)
    return () => {
      window.removeEventListener('scroll', updateScrollDir)
    }
  }, [scrollDir])

  return scrollDir
}

export default useScrollDirection
