import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import BasicLayout from '../../layouts/basic'

import imgSection1 from '../../assets/imgs/illust-cabletv-section1@3x.webp'
import imgSection1_mobile from '../../assets/imgs/illust-cabletv-section1-mobile@3x.webp'
import imgSection2_1 from '../../assets/imgs/illust-cabletv-section2-1@3x.webp'
import imgSection2_2 from '../../assets/imgs/illust-cabletv-section2-2@3x.webp'
import imgSection2_3 from '../../assets/imgs/illust-cabletv-section2-3@3x.webp'
import imgSection2_1_mobile from '../../assets/imgs/illust-cabletv-section2-1-mobile@3x.webp'
import imgSection2_2_mobile from '../../assets/imgs/illust-cabletv-section2-2-mobile@3x.webp'
import imgSection2_3_mobile from '../../assets/imgs/illust-cabletv-section2-3-mobile@3x.webp'
import imgSection3 from '../../assets/imgs/illust-cabletv-section3@3x.webp'
import imgSection3_mobile from '../../assets/imgs/illust-cabletv-section3@2x.webp'
import imgSection5 from '../../assets/imgs/img-cabletv-section5-background@3x.webp'
import { useWindowSize } from '../../services/utils'
import useScrollDirection from '../../hooks/useScrollDirection'
import BottomContactBar from '../../components/BottomContactBar'

const regionContents = {
  seoul: {
    title: '서울권',
    broadcast: [{
      name: '강서',
      regions: ['강서구'],
    }, {
      name: '동대문',
      regions: ['동대문구'],
    }, {
      name: '도봉·강북·노원',
      regions: ['도봉구', '강북구', '노원구'],
    }, {
      name: '광진·성동',
      regions: ['광진구', '성동구'],
    }, {
      name: '종로·중구·서대문',
      regions: ['종로구', '중구', '서대문구'],
    }],
    broadcast2: '강서방송 : 강서구 | 동대문방송 : 동대문구 | 광진·성동방송 : 광진구, 성동구\n도봉·강북·노원방송 : 도봉구, 강북구, 노원구 | 종로·중구·서대문방송 : 종로구, 중구, 서대문구',
  },
  incheon: {
    title: '인천권',
    broadcast: [{
      name: '새롬',
      regions: ['서구'],
    }, {
      name: '남동',
      regions: ['남동구'],
    }, {
      name: '서해',
      regions: ['중구', '동구', '옹진', '강화'],
    }],
    broadcast2: '새롬방송 : 서구 | 남동방송 : 남동구 | 서해방송 : 중구, 동구, 옹진, 강화',
  },
  gyeonggi: {
    title: '경기권',
    broadcast: [{
      name: '수원',
      regions: ['수원', '오산', '화성'],
    }, {
      name: 'ABC',
      regions: ['안양', '과천', '의왕', '군포'],
    }, {
      name: '한빛',
      regions: ['광명', '안산', '시흥'],
    }, {
      name: '기남',
      regions: ['용인', '평택', '이천', '안성'],
    }],
    broadcast2: '수원 방송 : 수원, 오산, 화성 | ABC 방송 : 안양, 과천, 의왕, 군포 | 한빛방송 : 광명, 안산, 시흥\n기남방송 : 용인, 평택, 이천, 안성',
  },
  jungbu: {
    title: '중부권',
    broadcast: [{
      name: '중부',
      regions: ['천안', '아산', '연기'],
    }, {
      name: '전주',
      regions: ['전주', '완주', '무주', '진안', '장수'],
    }],
    broadcast2: '중부방송 : 천안, 아산, 연기 | 전주방송 : 전주, 완주, 무주, 진안, 장수',
  },
  yeongnam: {
    title: '영남권',
    broadcast: [{
      name: '낙동',
      regions: ['사상구', '강서구'],
    }, {
      name: '동남',
      regions: ['남구', '수영구'],
    }, {
      name: '서부산',
      regions: ['서구', '사하구'],
    }, {
      name: '북부산',
      regions: ['북구', '강서구'],
    }, {
      name: '대구·대경',
      regions: ['중구', '남구', '서구'],
    }, {
      name: 'TCN대구',
      regions: ['달성', '달서구'],
    }],
    broadcast2: '낙동방송 : 사상구, 강서구 | 동남방송 : 남구, 수영구 | 서부산방송 : 서구,사하구\n북부산방송 : 북구, 강서구 | 대구·대경방송 : 중구, 남구, 서구\nTCN대구방송 : 달성, 달서구',
  },
}
//
const products = {
  ucam: {
    name: '유캠 광역광고',
    companies: 'SKB, 딜라이브, LGHV, HCN, CMB 영등포방송, \n아름방송, 남인천방송',
    regions: '서울권, 수도권, 인천권 선택가능',
    users: '600만',
    features: '동일한 큐톤 위치, 시간, 방송채널 동시 송출',
    channels: '상위 15개 채널',
  },
  symphony: {
    name: '심포니 광역광고',
    companies: 'SKB, 딜라이브, LGHV, HCN',
    regions: '전국',
    users: '1,500만',
    features: '동일한 큐톤 위치, 시간, 방송채널 동시 송출',
    channels: '상위 30개 채널',
  },
}
//
const RegionBox = ({ region }) => {
  const { title, broadcast } = region
  return (
    <div className="flex flex-col px-[28px] py-[30px] bg-white rounded-[20px]">
      <h4 className="text-[25px] text-brand font-bold mb-[17px]">{title}</h4>
      {broadcast.map((b) => (
        <span key={b.name} className="mt-[2px] text-[16px] text-[#808080]">
          <span className="font-bold">
            {b.name}
            방송
          </span>
          {' '}
          :
          {' '}
          {b.regions.join(',')}
        </span>
      ))}
    </div>
  )
}
//
const ProudctBox = ({ product }) => (
  <dl className="flex flex-col text-[12px]">
    <div className="flex items-center bg-[#eee] rounded-t-[6px]">
      <dt className="min-w-[75px] pl-[13px]">구분</dt>
      <dd className="py-[8px]">
        |
        <span className="pl-[8px]">{product.name}</span>
      </dd>
    </div>
    <div className="flex items-center">
      <dt className="min-w-[75px] pl-[13px]">참여사</dt>
      <dd className="pl-[8px] py-[8px]">{product.companies}</dd>
    </div>
    <div className="flex items-center border-t border-line">
      <dt className="min-w-[75px] pl-[13px]">송출지역</dt>
      <dd className="pl-[8px] py-[8px]">{product.regions}</dd>
    </div>
    <div className="flex items-center border-t border-line">
      <dt className="min-w-[75px] pl-[13px]">가입자</dt>
      <dd className="pl-[8px] py-[8px]">{product.users}</dd>
    </div>
    <div className="flex items-center border-t border-line">
      <dt className="min-w-[75px] pl-[13px]">특징</dt>
      <dd className="pl-[8px] py-[8px]">{product.features}</dd>
    </div>
    <div className="flex items-center border-t border-line">
      <dt className="min-w-[75px] pl-[13px]">채널</dt>
      <dd className="pl-[8px] py-[8px]">{product.channels}</dd>
    </div>
  </dl>
)
//
const CableTV = () => {
  const [width] = useWindowSize()
  const scrollDirection = useScrollDirection()

  const [selected, setSelected] = React.useState(0)
  const [folded, setFolded] = React.useState(true)

  const onClickCard = (index) => {
    setSelected(index)
  }

  const onClickFold = () => {
    setFolded((prev) => !prev)
  }
  //
  return (
    <BasicLayout>
      <div className="cabletv">
        {/* <section className="bg-gradient-to-b from-[#707cde] to-[#8b68e8]"> */}
        <section className="mmd:p-[24px] md:bg-[#5E3BE1]">
          <div className={cx(
            'flex justify-between mx-auto px-[24px] mmd:bg-[#5E3BE1] mmd:rounded-[15px]',
            'md:px-0 md:max-w-[550px] lg:max-w-[760px] xl:max-w-[1104px]',
          )}
          >
            <div className={cx(
              'flex flex-col items-start mt-[26px] mb-[24px] md:my-[50px]',
              'xl:my-[105px]',
            )}
            >
              <p className={cx(
                'hidden text-white md:block md:text-[20px] lg:text-[26px] font-normal leading-[1.3]',
                'xl:text-[38px]',
              )}
              >
                <span>지역 주민과 가장 가까운</span>
                <br />
                <span className="font-bold">케이블TV 광고</span>
                <br />
                <span>지금 바로 시작해보세요!</span>
              </p>
              <p className="text-[21px] text-white md:hidden">
                <span className="font-bold">케이블TV 광고의</span>
                <br />
                <span>시작!</span>
              </p>
              <Link to="/consult">
                <button className={
                  cx(
                    'py-[5px] px-[10px] text-[10px] text-white font-bold bg-black rounded-full',
                    'md:mt-[20px] md:py-[12px] md:px-[17px] md:text-[14px] md:rounded-[5px]',
                    'lg:mt-[30px] lg:py-[14px] lg:px-[20px] lg:text-[16px]',
                    'xl:mt-[70px] xl:py-[19px] xl:px-[29px] lg:text-[20px]',
                  )
                }
                >
                  전화 상담 하러가기
                </button>
              </Link>
              {/* <div className="flex items-center gap-[18px] lg:mt-[50px] xl:mt-[70px]">
                <span className="text-white">
                  1
                  <span className="text-[#a792fb]">/1</span>
                </span>
                <div className="mt-1 bg-white rounded-full w-[37px] h-[8px]" />
              </div> */}
            </div>
            <div className="relative flex">
              <img className="hidden md:block md:w-[260px] md:h-[173px] lg:w-[400px] lg:h-[266px] xl:w-[573px] xl:h-[381px]" src={imgSection1} alt="tv" />
              <img className="w-[100px] h-[102px] md:hidden" src={imgSection1_mobile} alt="tv" />
            </div>
          </div>
        </section>
        <section className="md:bg-white mmd:p-[24px]">
          <div className={
            cx(
              'flex flex-col mx-auto md:max-w-[550px] lg:max-w-[760px] xl:max-w-[1104px]',
              'py-[24px] md:py-[100px] xl:py-[143px]',
            )
          }
          >
            <h3 className="font-bold text-[17px] mb-[18px] md:text-[33px] md:text-center md:mb-[33px]">케이블TV를 통한 지역광고</h3>
            <div className="grid grid-cols-2 gap-[10px] md:grid-cols-3">
              <div className="flex flex-col pl-[12px] pb-[15px] bg-[#edf2f6] rounded-[15px] xl:pl-[32px] xl:pb-[37px]">
                <img className="xl:hidden self-end w-[77px] h-[90px] mt-[11px] mb-[18px]" src={imgSection2_1_mobile} alt="channel" />
                <img className="hidden xl:block self-end w-[150px] h-[178px] mt-[22px] mb-[21px]" src={imgSection2_1} alt="channel" />
                <h4 className="mb-1 text-[12px] xl:text-[25px] font-black">인기 채널만</h4>
                <p className="xl:hidden mb-0 xs:text-[11px] text-[12px] text-textSecondary whitespace-pre-wrap">{'시청률 상위 채널에 집중\n편성하여 광고 노출 효과 증가'}</p>
                <p className="hidden xl:block mb-0 text-[20px] text-textSecondary whitespace-pre-wrap">{'시청률 상위 채널(30개 수준)에\n집중 편성하여 광고 노출 효과 증가'}</p>
              </div>
              <div className="flex flex-col pl-[12px] pb-[15px] bg-[#efeff9] rounded-[15px] xl:pl-[32px] xl:pb-[37px]">
                <img className="xl:hidden self-end w-[69px] h-[91px] mr-[17px] mb-[28px]" src={imgSection2_2_mobile} alt="channel" />
                <img className="hidden xl:block self-end w-[135px] h-[181px] mr-[30px] mb-[41px]" src={imgSection2_2} alt="channel" />
                <h4 className="mb-1 text-[12px] xl:text-[25px] font-black">타겟팅 지역으로</h4>
                <p className="mb-0 xs:text-[11px] text-[12px] xl:text-[20px] text-textSecondary whitespace-pre-wrap">{'홍보가 필요한 타겟팅 지역\n집중 송출'}</p>
              </div>
              <div className="relative flex flex-col pl-[12px] pb-[15px] bg-[#cad6f5] rounded-[15px] xl:pl-[32px] xl:pb-[37px]">
                <img className="absolute xl:hidden w-[119px] h-[143px] top-0 right-0" src={imgSection2_3_mobile} alt="channel" />
                <img className="absolute hidden xl:block w-[214px] h-[222px] top-0 right-0" src={imgSection2_3} alt="channel" />
                <h4 className="mt-[119px] mb-1 text-[12px] xl:text-[25px] font-black xl:mt-[222px]">효율성 높게</h4>
                <p className="mb-0 xs:text-[11px] text-[12px] xl:text-[20px] text-textSecondary whitespace-pre-wrap">{'경제적인 비용으로 광고 대상\n지역에 월450회 노출 보장'}</p>
              </div>
            </div>
          </div>
        </section>
        <section className="mmd:p-[24px]">
          <div className={
            cx(
              'flex flex-col mx-auto md:max-w-[550px] lg:max-w-[760px] xl:max-w-[1104px]',
              'py-[24px] md:py-[100px] xl:py-[143px]',
            )
          }
          >
            <h3 className={
              cx(
                'font-bold text-[21px] mb-[10px] leading-[1.3]',
                'md:text-[33px] md:text-center md:mb-[33px]',
              )
            }
            >
              케이블TV 광고는
              <br />
              전국
              {' '}
              <span className="text-brand">23개 권역</span>
              {' '}
              가입자에게
              <br />
              광고를 송출합니다.
            </h3>
            <p className="text-[13px] text-[#7e7e7e] whitespace-pre-wrap leading-[1.2] md:text-[21px] md:text-center">
              {'송출 권역을 선정하실 수 있으며,\n각 권역별 가입자에 따라 광고비가 상이할 수 있습니다.'}
            </p>
            <div className="lg:hidden flex flex-col items-center">
              <img className="w-[191px] h-[279px] mb-[65px]" src={imgSection3_mobile} alt="korea_map" />
              <div className="self-stretch flex flex-col gap-[10px] mb-[40px]">
                {['seoul', 'incheon', 'gyeonggi', 'jungbu', 'yeongnam'].map((region) => {
                  const { title, broadcast, broadcast2 } = regionContents[region]
                  return (
                    <div
                      key={region}
                      className={cx(
                        'flex flex-col items-start py-[17px] pl-[14px] pr-[11px] gap-[8px]',
                        'bg-white rounded-[15px]',
                      )}
                    >
                      <div className={cx(
                        'px-[7px] py-[4px] text-brand text-[12px]',
                        'font-black bg-[#f2efff] rounded-[4px]',
                      )}
                      >
                        {title}
                      </div>
                      <p className="my-0 text-[12px] font-bold text-[#010101]">
                        {broadcast.map((b) => b.name).join(',')}
                        방송
                      </p>
                      <p className="my-0 text-[10px] text-[#b3b3b3] font-bold whitespace-pre-wrap">
                        {broadcast2}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="hidden lg:flex items-center justify-between my-[106px]">
              <div className="flex flex-col gap-[30px]">
                {['incheon', 'gyeonggi', 'jungbu'].map((region) => (
                  <RegionBox key={region} region={regionContents[region]} />
                ))}
              </div>
              <img className="w-[298px] h-[435px]" src={imgSection3} alt="korea_map" />
              <div className="flex flex-col gap-[30px]">
                {['seoul', 'yeongnam'].map((region) => (
                  <RegionBox key={region} region={regionContents[region]} />
                ))}
              </div>
            </div>
            <div className="self-center">
              <Link to="/consult">
                <button className={
                  cx(
                    'py-[10px] px-[18px] text-[12px] text-white font-bold bg-black rounded-[8px]',
                    'xl:py-[17px] xl:px-[38px] xl:text-[25px] xl:rounded-full',
                  )
                }
                >
                  전화 상담 하러가기
                </button>
              </Link>
            </div>
          </div>
        </section>
        <section className={cx('md:bg-white')}>
          <div className={cx(
            'flex flex-col mx-auto p-[24px]',
            'md:max-w-[550px] lg:max-w-[760px] xl:max-w-[1104px]',
            'md:py-[60px] lg:py-[145px]',
          )}
          >
            <h2 className={
              cx(
                'hidden md:block font-bold',
                'text-[33px] mb-[72px]',
              )
            }
            >
              <span className="text-brand">궁금한 점이 있으신가요?</span>
            </h2>
            <h3 className={
              cx(
                'font-bold text-[17px] mb-[10px]',
                'md:text-[25px] md:mb-[30px]',
              )
            }
            >
              자주 묻는 질문
              {' '}
              <span className="text-brand">Q&A</span>
            </h3>
            <div className="overflow-hidden">
              <div className={cx(
                'flex items-start gap-[10px]',
                'overflow-x-scroll scrollbar-hidden',
                'lg:grid lg:grid-cols-3 lg:gap-[18px] lg:overflow-visible',
                'xl:gap-[36px]',
              )}
              >
                <div
                  className={cx(
                    'min-w-[150px] min-h-[200px] px-[9px] py-[17px] rounded-[15px]',
                    'transition duration-150',
                    selected === 0 ? 'bg-brand' : 'bg-[#f3f3f3]',
                    'lg:px-[16px] lg:py-[25px]',
                    'xl:px-[29px] xl:py-[34px]',

                  )}
                  onClick={() => onClickCard(0)}
                >
                  <h5 className={cx(
                    'hidden lg:block lg:text-[16px] xl:text-[22px] mb-[23px] whitespace-pre-wrap',
                    selected === 0 && 'text-white',
                  )}
                  >
                    {'케이블TV 광고는\nB tv 우리동네광고와는\n다른건가요?'}
                  </h5>
                  <h5 className={cx(
                    'lg:hidden text-[12px] whitespace-pre-wrap',
                    selected === 0 && 'text-white',
                  )}
                  >
                    {'케이블TV 광고는 B tv\n우리동네광고와는\n다른건가요?'}
                  </h5>
                  {folded ? (
                    <p className={cx(
                      'text-[12px] xl:text-[16px] break-words',
                      selected === 0 ? 'text-white' : 'text-[#8d8d8d]',
                    )}
                    >
                      B tv 우리동네광고는 IPTV 가입자를 대상으로 하며, 행정동 단위로 송출이 가능합니다.
                    </p>
                  ) : (
                    <p className={cx(
                      'text-[12px] xl:text-[16px]',
                      selected === 0 ? 'text-white' : 'text-[#8d8d8d]',
                    )}
                    >
                      {'B tv 우리동네광고는 IPTV 가입자를 대상으로 하며, 행정동 단위로 송출이 가능합니다. 케이블TV 광고는 케이블  가입자를 대상으로 하며,  '}
                      <span className="font-bold">특정 권역 전체의 모든 케이블방송 시청자에게 동시</span>
                      에 송출이 됩니다. IPTV (B tv 우리동네광고)와 케이블TV 광고를 결합하시면 더 나은 광고 효과를 기대하실 수 있습니다.
                    </p>
                  )}
                  {folded && (
                    <button
                      className={
                        cx(
                          'py-[6px] px-[8px] text-[8px] text-white font-bold bg-black rounded-full',
                          'lg:py-[8px] lg:px-[16px] lg:text-[12px]',
                          'xl:py-[11px] xl:px-[20px] xl:text-[16px]',
                        )
                      }
                      onClick={onClickFold}
                    >
                      자세히 알아보기
                    </button>
                  )}
                </div>
                <div
                  className={cx(
                    'min-w-[150px] min-h-[200px] px-[9px] py-[17px] rounded-[15px]',
                    'group transition duration-150',
                    selected === 1 ? 'bg-brand' : 'bg-[#f3f3f3]',
                    'lg:min-h-[234px] xl:min-h-[306px]  xl:px-[29px] xl:py-[34px]',
                  )}
                  onClick={() => onClickCard(1)}
                >
                  <h5 className={cx(
                    'min-h-[29px] text-[12px] whitespace-pre-wrap lg:text-[16px] xl:text-[22px] xl:mb-[23px]',
                    selected === 1 && 'text-white',
                  )}
                  >
                    {'케이블TV 광고비는 어떻게\n되나요?'}
                  </h5>
                  <p className={cx(
                    'text-[12px] xl:text-[16px]',
                    selected === 1 ? 'text-white' : 'text-[#8d8d8d]',
                  )}
                  >
                    {'케이블TV는 권역별 가입자\n차이로 광고비가 상이하게\n책정됩니다.'}
                  </p>
                </div>
                <div
                  className={cx(
                    'min-w-[150px] min-h-[200px] px-[9px] py-[17px] rounded-[15px]',
                    'group transition duration-150',
                    selected === 2 ? 'bg-brand' : 'bg-[#f3f3f3]',
                    'lg:min-h-[234px] xl:min-h-[306px] xl:px-[29px] xl:py-[34px]',
                  )}
                  onClick={() => onClickCard(2)}
                >
                  <h5 className={cx(
                    'min-h-[29px] text-[12px] lg:text-[16px] xl:text-[22px] xl:mb-[23px]',
                    selected === 2 && 'text-white',
                  )}
                  >
                    어떤 채널에 송출되나요?
                  </h5>
                  <p className={cx(
                    'text-[12px] xl:text-[16px]',
                    selected === 2 ? 'text-white' : 'text-[#8d8d8d]',
                  )}
                  >
                    {'케이블TV 광고는 인기있는\n주요 30여개 채널에\n송출됩니다.'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="mlg:px-[24px] mlg:pt-[60px] mlg:pb-[24px] lg:bg-[#333]"> */}
        <section className="relative lg:bg-[#333]">
          <img className="absolute inset-0 hidden lg:block w-full h-full object-cover bg-[#000]/80" src={imgSection5} alt="배경" />
          <div className={cx(
            'relative flex flex-col mx-auto p-[24px]',
            'md:max-w-[550px] lg:max-w-[760px] xl:max-w-[1104px]',
            'md:py-[60px] lg:py-[136px]',
          )}
          >
            <h3 className={
              cx(
                'font-bold text-[17px] mb-[10px]',
                'md:text-[25px] md:mb-[30px]',
                'lg:text-center lg:text-white lg:text-[33px]',
              )
            }
            >
              추가 상품 소개 진행
            </h3>
            <p className="text-[13px] text-[#7e7e7e] whitespace-pre-wrap leading-[1.2] lg:text-white lg:text-[21px] lg:text-center lg:pb-[40px] lxlg:pb-[66px]">
              {'케이블TV 광역광고는 SKB 권역 外 타사와의 공동 광고를 통해\n광고 효과를 제고시킬 수 있습니다.'}
            </p>
            <div className="lg:hidden flex flex-col gap-[36px]">
              <ProudctBox product={products.ucam} />
              <ProudctBox product={products.symphony} />
            </div>
            <div className="hidden lg:block">
              <div className="flex flex-col text-[12px]">
                <div className="flex items-center bg-brand py-[14px] text-white rounded-t-[25px]">
                  <span className="min-w-[130px] xl:min-w-[160px] text-[16px] xl:text-[25px] text-center font-bold">구분</span>
                  <div className="w-[1px] lg:h-[30px] xl:h-[48px] bg-white" />
                  <div className="flex-1 flex items-center">
                    <span className="flex-1 pl-[8px] font-bold text-[16px] xl:text-[25px] text-center">{products.ucam.name}</span>
                    <div className="w-[1px] lg:h-[30px] xl:h-[48px] bg-white" />
                    <span className="flex-1 pl-[8px] font-bold text-[16px] xl:text-[25px] text-center">{products.symphony.name}</span>
                  </div>
                </div>
                <div className="flex items-center justify-center min-h-[80px] xl:min-h-[120px] text-white">
                  <span className="min-w-[130px] xl:min-w-[160px] text-[12px] xl:text-[21px] text-center font-bold">참여사</span>
                  <div className="flex-1 flex items-center">
                    <span className="flex-1 pl-[8px] px-[30px] text-[12px] xl:text-[21px] text-center whitespace-pre-wrap">{products.ucam.companies}</span>
                    <span className="flex-1 pl-[8px] px-[30px] text-[12px] xl:text-[21px] text-center">{products.symphony.companies}</span>
                  </div>
                </div>
                <div className="flex items-center justify-center min-h-[80px] xl:min-h-[120px] text-white border-t">
                  <span className="min-w-[130px] xl:min-w-[160px] text-[12px] xl:text-[21px] text-center font-bold">송출지역</span>
                  <div className="flex-1 flex items-center">
                    <span className="flex-1 pl-[8px] px-[30px] text-[12px] xl:text-[21px] text-center whitespace-pre-wrap">{products.ucam.regions}</span>
                    <span className="flex-1 pl-[8px] px-[30px] text-[12px] xl:text-[21px] text-center">{products.symphony.regions}</span>
                  </div>
                </div>
                <div className="flex items-center justify-center min-h-[80px] xl:min-h-[120px] text-white border-t">
                  <span className="min-w-[130px] xl:min-w-[160px] text-[12px] xl:text-[21px] text-center font-bold">가입자</span>
                  <div className="flex-1 flex items-center">
                    <span className="flex-1 pl-[8px] px-[30px] text-[12px] xl:text-[21px] text-center whitespace-pre-wrap">{products.ucam.users}</span>
                    <span className="flex-1 pl-[8px] px-[30px] text-[12px] xl:text-[21px] text-center">{products.symphony.users}</span>
                  </div>
                </div>
                <div className="flex items-center justify-center min-h-[80px] xl:min-h-[120px] text-white border-t">
                  <span className="min-w-[130px] xl:min-w-[160px] text-[12px] xl:text-[21px] text-center font-bold">특징</span>
                  <div className="flex-1 flex items-center">
                    <span className="flex-1 pl-[8px] px-[30px] text-[12px] xl:text-[21px] text-center whitespace-pre-wrap">{products.ucam.features}</span>
                    <span className="flex-1 pl-[8px] px-[30px] text-[12px] xl:text-[21px] text-center">{products.symphony.features}</span>
                  </div>
                </div>
                <div className="flex items-center justify-center min-h-[80px] xl:min-h-[120px] text-white border-t">
                  <span className="min-w-[130px] xl:min-w-[160px] text-[12px] xl:text-[21px] text-center font-bold">채널</span>
                  <div className="flex-1 flex items-center">
                    <span className="flex-1 pl-[8px] px-[30px] text-[12px] xl:text-[21px] text-center whitespace-pre-wrap">{products.ucam.channels}</span>
                    <span className="flex-1 pl-[8px] px-[30px] text-[12px] xl:text-[21px] text-center">{products.symphony.channels}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {width >= 798 && <BottomContactBar scrollDirection={scrollDirection} />}
      </div>
    </BasicLayout>
  )
}

export default CableTV
