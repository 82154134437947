import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import cx from 'classnames'
import Select from 'react-select'

import { useQuery } from 'react-query'
import LabeledSection from '../../../components/labeledSection'
import { FRANCHISE_REGIONS, chartColors } from '../../../services/constants'
import MonthPicker from './MonthPicker'
import FranchisePieGraph from './FranchisePieGraph'
import FranchiseLineGraph from './FranchiseLineGraph'
import AppContext from '../../../services/context'
import { createDateRangeArray, useWindowSize } from '../../../services/utils'
import { getCampaignResult } from '../../../services/api/dashboard'

// const transformData = (data, dateRange) => {
//   const ㄱㄷ녀ㅣㅅ = data.reduce((acc, { region, yearmonth, count }) => {
//     const found = acc.find((item) => item.id === region)
//     if (found) {
//       found.data.push({ x: yearmonth, y: count })
//     } else {
//       acc.push({
//         id: region,
//         data: [{ x: yearmonth, y: count }],
//       })
//     }
//     return acc
//   }, [])
// }

const RegionDataView = ({ selectedRegions, setSelectedRegions }) => {
  const { appState } = React.useContext(AppContext)
  const [width] = useWindowSize()
  const [cookies] = useCookies(['jwt_token'])
  const isDesktop = React.useMemo(() => width >= 798, [width])
  const isSuperAdmin = appState?.userInfo?.companyRank === 9

  const today = new Date()
  const [dateRange, setDateRange] = useState([
    new Date(today.getFullYear(), today.getMonth() - 2, 1),
    new Date(today.getFullYear(), today.getMonth() + 1, 0),
  ])

  const { data: regionData } = useQuery(
    ['regionData', dateRange],
    () => getCampaignResult(cookies.jwt_token, dateRange, true),
    {
      enabled: !!cookies.jwt_token,
    },
  )

  // const pieGraphDataRegion = isSuperAdmin ? data : data.filter((item) => selectedRegions.includes(item.region))

  const { countData, expenseData } = !regionData
    ? { countData: [], expenseData: [] }
    : Object.keys(FRANCHISE_REGIONS).reduce(
      (acc, region, i) => {
        const { count, expense } = regionData.reduce(
          (_acc, cur) => {
            const tempAcc = { ..._acc }
            if (cur.region === region) {
              tempAcc.count += cur.count
              tempAcc.expense += cur.expense
            }
            return tempAcc
          },
          { count: 0, expense: 0 },
        )
        const isMyRegion = !isSuperAdmin && region === selectedRegions[0]
        const isSelected = isSuperAdmin && selectedRegions.includes(region)
        const id = isSelected || isMyRegion ? region : 'other'
        const label = isSelected || isMyRegion ? FRANCHISE_REGIONS[region] : '타지역'
        const tempData = {
          id,
          label,
          color: isSelected || isMyRegion ? (isDesktop ? chartColors[i] : 'white') : isDesktop ? '#ddd' : '#6B5AD1',
        }
        if (acc.countData.find((item) => item.id === id)) {
          acc.countData.find((item) => item.id === id).value += count
          acc.expenseData.find((item) => item.id === id).value += expense
          return acc
        }
        acc.countData.push({
          ...tempData,
          value: count,
        })
        acc.expenseData.push({
          ...tempData,
          value: expense,
        })
        return acc
      },
      { countData: [], expenseData: [] },
    )

  countData.sort((a, b) => {
    if (a.id === 'other') return 1
    if (b.id === 'other') return -1
    return a.value - b.value
  })
  expenseData.sort((a, b) => {
    if (a.id === 'other') return 1
    if (b.id === 'other') return -1
    return a.value - b.value
  })

  const lineGraphData = !regionData
    ? []
    : selectedRegions.map((region, i) => {
      const data = createDateRangeArray(dateRange[0], dateRange[1]).map((date) => {
        const found = regionData.find((item) => item.region === region && item.yearmonth === date)
        return {
          x: date,
          y: found ? found.count : 0,
        }
      })
      return {
        id: FRANCHISE_REGIONS[region],
        data,
        color: chartColors[i],
      }
    })

  return (
    <div className="flex flex-col mb-10 space-y-4">
      <h3 className="text-lg font-bold !mb-2 lg:text-xl">지역별 현황</h3>
      {!isDesktop ? (
        <div className="flex flex-col gap-2">
          <Select
            className="border-0 mb-3"
            classNamePrefix="react-select-shadow"
            value={selectedRegions.map((region) => ({
              value: region,
              label: FRANCHISE_REGIONS[region],
            }))}
            onChange={(_data) => setSelectedRegions([_data.value])}
            options={Object.keys(FRANCHISE_REGIONS).map((region) => ({
              value: region,
              label: FRANCHISE_REGIONS[region],
            }))}
            isDisabled={!isSuperAdmin}
          />
          <MonthPicker isDesktop={isDesktop} dateRange={dateRange} setDateRange={setDateRange} />
        </div>
      ) : (
        <div className="flex gap-4">
          <LabeledSection className="franchise-card flex-1 !pb-0" label="지역 선택">
            <div className="max-h-[460px] overflow-y-scroll">
              <div className="space-y-4 pb-4">
                {Object.keys(FRANCHISE_REGIONS)
                  .filter((region) => {
                    if (isSuperAdmin) return true
                    return selectedRegions.includes(region)
                  })
                  .map((region) => {
                    const isSelected = selectedRegions.includes(region)
                    return (
                      <div
                        key={region}
                        className={cx(
                          'flex justify-between items-center h-[70px] px-4 font-bold rounded-lg cursor-pointer',
                          isSelected ? 'bg-brand text-white' : 'bg-[#eee]',
                        )}
                        onClick={() => {
                          if (!isSuperAdmin) return
                          if (isSelected) {
                            setSelectedRegions(selectedRegions.filter((r) => r !== region))
                          } else {
                            setSelectedRegions([...selectedRegions, region])
                          }
                        }}
                      >
                        {FRANCHISE_REGIONS[region]}
                      </div>
                    )
                  })}
              </div>
            </div>
          </LabeledSection>
          <LabeledSection className="franchise-card flex-1" label="기간 조회">
            <MonthPicker isDesktop={isDesktop} dateRange={dateRange} setDateRange={setDateRange} />
          </LabeledSection>
        </div>
      )}
      <div className="mlg:space-y-2">
        <h3 className="text-lg font-bold !mb-2 lg:hidden">올해 현황</h3>
        <div className="flex gap-4">
          <LabeledSection
            className="franchise-card relative flex-1 h-[420px] mlg:h-[220px] mlg:!p-4"
            containerClassName="absolute inset-0 overflow-visible bg-brand lg:bg-white"
            labelWrapperClassName="relative z-10"
            labelClassName="!text-[14px] text-white lg:!text-textPrimary lg:!text-lg"
            label="총 청약 건수"
          >
            <FranchisePieGraph
              data={countData}
              margin={{
                top: isDesktop ? 130 : 60,
                right: isDesktop ? 0 : 20,
                bottom: isDesktop ? 50 : 20,
                left: isDesktop ? 0 : 20,
              }}
              type="count"
              selectedRegions={selectedRegions}
              isDesktop={isDesktop}
            />
          </LabeledSection>
          <LabeledSection
            className="franchise-card relative flex-1 h-[420px] mlg:h-[220px] mlg:!p-4"
            containerClassName="absolute inset-0 overflow-visible bg-brand lg:bg-white"
            labelWrapperClassName="relative z-10"
            labelClassName="!text-[14px] text-white lg:!text-textPrimary lg:!text-lg"
            label="총 청약 금액"
          >
            <FranchisePieGraph
              data={expenseData}
              margin={{
                top: isDesktop ? 130 : 60,
                right: isDesktop ? 0 : 20,
                bottom: isDesktop ? 50 : 20,
                left: isDesktop ? 0 : 20,
              }}
              type="expense"
              selectedRegions={selectedRegions}
              isDesktop={isDesktop}
            />
          </LabeledSection>
        </div>
      </div>
      <div className="mlg:max-w-[325px] mlg:overflow-x-scroll">
        <LabeledSection
          className="franchise-card relative flex-1 w-[800px] h-[450px] shrink-0 lg:w-auto"
          containerClassName="absolute inset-0 overflow-visible"
          labelWrapperClassName="relative z-10"
          label="월별 청약 건수"
        >
          <FranchiseLineGraph data={lineGraphData} dateRange={dateRange} type="count" />
        </LabeledSection>
      </div>
    </div>
  )
}

export default RegionDataView
