export const validations = {
  name: {
    pattern: {
      error: '한글/영어만 입력 가능합니다',
      success: null,
    },
    required: {
      error: '이름을 입력해주세요',
      success: null,
    },
  },
  tel: {
    pattern: {
      error: '올바른 전화번호가 아닙니다',
      success: null,
    },
    required: {
      error: '전화번호를 입력해주세요',
      success: null,
    },
  },
  user_id: {
    required: {
      error: '아이디를 입력해주세요',
      success: null,
    },
    notExisted: {
      error: '존재하지 않는 아이디 입니다. 아이디를 확인해주세요.',
      success: null,
    },
    pattern: {
      error: '영문 소문자/숫자 사용가능 (7~20자)',
      success: null,
      // success: "영문 소문자/숫자 사용가능 (7~20자)"
    },
  },
  x_user_id_validated: {
    validated: {
      error: '아이디 중복확인을 해주세요',
      success: '아이디 중복확인 완료',
    },
  },
  agency_id: {
    required: {
      error: '아이디를 입력해주세요',
      success: null,
    },
    pattern: {
      error: '영문 숫자 특수기호(_-.) 사용가능 (5~20자)',
      success: null,
      // success: "영문 숫자 특수기호(_-.) 사용가능 (5~20자)"
    },
    validated: {
      error: '아이디 중복확인을 해주세요',
      success: '아이디 중복확인 완료',
    },
  },
  current_password: {
    required: {
      error: '비밀번호를 입력해주세요',
      success: null,
    },
    pattern: {
      error: '영문/숫자/특수문자 모두 필요 (8~16자)',
      success: null,
      // success: "영문/숫자/특수문자 모두 필요 (8~16자)"
    },
  },
  password: {
    required: {
      error: '비밀번호를 입력해주세요',
      success: null,
    },
    pattern: {
      error: '영문/숫자/특수문자 모두 필요 (8~16자)',
      success: null,
      // success: "영문/숫자/특수문자 모두 필요 (8~16자)"
    },
    notCorrect: {
      error: '잘못된 비밀번호 입니다. 비밀번호를 다시 확인해주세요.',
      success: null,
    },
  },
  confirm_password: {
    required: {
      error: '비밀번호를 입력해주세요',
      success: null,
    },
    unmatched: {
      error: '비밀번호와 일치하지 않습니다',
      success: '비밀번호와 일치',
    },
  },
  business_name: {
    required: {
      error: '업체명을 입력해주세요',
      success: null,
    },
    required_business_id: {
      error: '업체명을 입력해주세요',
      success: null,
    },
  },
  business_id: {
    required: {
      error: '사업자등록번호 입력해주세요',
      success: null,
    },
    pattern: {
      error: '\'-\' 제외한 10자리 숫자 입력',
      success: null,
    },
    required_business_name: {
      error: '사업자번호를 입력해주세요',
      success: null,
    },
  },
  x_business_id_validated: {
    validated: {
      error: '사업자등록번호를 확인해주세요',
      success: '사업자등록번호 확인',
    },
  },
  clientBusinessId: {
    required: {
      error: '사업자등록번호 입력해주세요',
      success: null,
    },
    pattern: {
      error: '\'-\' 제외한 10자리 숫자 입력',
      success: null,
    },
    required_business_name: {
      error: '사업자번호를 입력해주세요',
      success: null,
    },
  },
  x_clientBusinessId_validated: {
    validated: {
      error: '사업자등록번호를 확인해주세요',
      success: '사업자등록번호 확인',
    },
  },
  full_name: {
    required: {
      error: '이름을 입력해주세요',
      success: null,
    },
  },
  phone_no: {
    required: {
      error: '휴대폰 번호를 입력해주세요',
      success: null,
    },
    pattern: {
      error: '올바른 전화번호가 아닙니다',
      success: null,
    },
  },
  email: {
    required: {
      error: '이메일 주소 입력해주세요',
      success: null,
    },
    pattern: {
      error: '올바른 이메일이 아닙니다',
      success: null,
    },
  },
  identity_check: {
    required: {
      error: '본인 인증을 해주세요',
      success: '본인 인증 완료',
    },
  },
  x_recommendation_id_validated: {
    validated: {
      error: '아아디를 확인해주세요',
      success: '추천인 아이디 확인',
    },
  },
  signup_checkboxes: {
    required: {
      error: '(필수)항목을 체크해주세요',
      success: null,
    },
  },
  ad_campaign_checkboxes: {
    required: {
      error: '(필수)항목을 체크해주세요',
      success: null,
    },
  },
  p_info_consent: {
    required: {
      error: '(필수)항목을 체크해주세요',
      success: null,
    },
  },
  x_business_document_url: {
    url: {
      error: '사업자등록증을 첨부해주세요',
      success: '사업자등록증 첨부 완료',
    },
  },
  x_company_intro_url: {
    url: {
      error: '회사 소개서를 첨부해주세요',
      success: '회사소개서 첨부 완료',
    },
  },
  x_clientBusinessDocs_url: {
    url: {
      error: '사업자등록증을 첨부해주세요',
      success: '사업자등록증 완료',
    },
  },
  //
  ad_campaign_regions_count: {
    min: {
      error: '광고 대상 수가 부족합니다. 더 많은 지역을 선택해주세요.',
      success: null,
    },
  },
  ad_campaign_dates: {
    required: {
      error: '광고를 시작할 수 없는 날짜를 선택했습니다. 기간을 다시 선택해주세요.',
      success: null,
    },
  },
  ad_campaign_period: {
    min: {
      error: '최소 게재 기간은 30일 입니다.',
      success: null,
    },
  },
  ad_campaign_budget: {
    value_min: {
      error: '최소 예산 100,000원 이상 입력해주세요.',
      success: null,
    },
  },
  ad_campaign_point_val: {
    min: {
      error: '잘못된 값입니다.',
      success: null,
    },
    max: {
      error: '포인트가 부족합니다',
      success: null,
    },
  },
  community_post_asset: {
    asset_required: {
      error: 'asset required.',
      success: null,
    },
  },
  community_post_title: {
    required: {
      error: 'title required.',
      success: null,
    },
  },
  community_post_html: {
    required: {
      error: 'body text required.',
      success: null,
    },
  },
  community_post_category: {
    required: {
      error: 'category required.',
      success: null,
    },
  },
  companyName: {
    required: {
      error: '상호명을 입력해주세요',
      success: null,
    },
  },
  companySector: {
    required: {
      error: '업종을 입력해주세요',
      success: null,
    },
  },
  storeTel: {
    pattern: {
      error: '올바른 전화번호가 아닙니다',
      success: null,
    },
    required: {
      error: '전화번호를 입력해주세요',
    },
  },
  storePostalCode: {
    required: {
      error: '주소 검색을 통해 입력해주세요',
      success: null,
    },
  },
  storeAddressDetail: {
    required: {
      error: '상세 주소를 입력해주세요',
      success: null,
    },
  },
  promotionValue: {
    required: {
      error: '프로모션 정보를 입력해주세요',
      success: null,
    },
  },
  promotionStartDate: {
    required: {
      error: '프로모션 시작일을 입력해주세요',
      success: null,
    },
  },
  promotionLink: {
    pattern: {
      error: '올바른 URL이 아닙니다',
      success: null,
    },
  },
}
//
function generateMessages(name, criteria, errTypes) {
  const result = []
  if (!validations[name]) return []
  if (!(criteria in validations[name])) return []
  const errMsg = validations[name][criteria].error
  const sucMsg = validations[name][criteria].success
  if (criteria in errTypes && errMsg != null) result.push({ msg: errMsg, status: false })
  else if (!(criteria in errTypes) && sucMsg != null) result.push({ msg: sucMsg, status: true })
  return result
}
//
export default function Validate(names, errors, criterias, groupName) {
  const errTypes = {}
  let result = []
  names.forEach((name) => {
    errTypes[errors[name]?.type] = errors[name]?.type
  })
  criterias.forEach((criteria) => {
    if (groupName) {
      result = result.concat(generateMessages(groupName, criteria, errTypes))
    } else {
      names.forEach((name) => {
        result = result.concat(generateMessages(name, criteria, errTypes))
      })
    }
  })
  return result
}
