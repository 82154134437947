import React from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'
import { getDesigns, postAsset } from '../../services/api'
import DesignPaper, { DesignModal } from './components/designPaper'
import AppContext from '../../services/context'
//
const TabFranchise = () => {
  const [cookies] = useCookies(['jwt_token'])
  const { appState } = React.useContext(AppContext)
  const history = useHistory()
  const [isModalShown, setIsModalShown] = React.useState(false)
  const [designs, setDesigns] = React.useState([])
  const [activeDesign, setActiveDesign] = React.useState(null)
  //
  React.useEffect(() => {
    (async () => {
      const result = await getDesigns('franchise')
      await setDesigns(result.result)
    })()
  }, [])
  //
  const onTemplateSelect = async (e, data) => {
    setActiveDesign(data)
    await setIsModalShown(true)
  }
  //
  const onPostTemplate = async (e, data) => {
    await setIsModalShown(false)
    const result = await postAsset(cookies.jwt_token, data._id)
    if (!result) return
    //
    history.push({
      pathname: '/portal/media/build',
      state: {
        userId: appState.userInfo.id,
        assetId: result._id,
      },
    })
    //
  }

  return (
    <div className="tab3">
      <div className="tab1-header">
        <h3>SKT 대리점 전용 템플릿</h3>
      </div>
      <div className="grid grid-cols-4 gap-4">
        {designs.map((item, index) => (
          <DesignPaper data={item} key={index} onSelect={onTemplateSelect} />
        ))}
      </div>
      {designs.length === 0 && <Empty />}
      <DesignModal
        isShown={isModalShown}
        data={activeDesign}
        onClose={() => setIsModalShown(false)}
        onPostTemplate={onPostTemplate}
      />
    </div>
  )
}

const Empty = () => (
  <div className="empty">
    <p className="fir">해당되는 템플릿이 없습니다</p>
    <p className="sec">
      <span>선택하신 내용에 해당되는 템플릿이 없습니다.</span>
      <br />
      <span>다른 항목을 선택해 다시 시도해주세요.</span>
    </p>
  </div>
)

export default TabFranchise
