import React from 'react'
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs'
import { Container, Accordion } from 'react-bootstrap'

import BasicLayout from '../../layouts/basic'
import AppContext from '../../services/context'
import InfoPaper from '../../components/infoPaper'
import AutoSuggestBox from '../../components/autoSuggestBox'
import { getFAQs } from '../../services/api'
//
const cats = [
  {
    title: '질문 BEST',
    value: 'bests',
  },
  {
    title: '계정',
    value: 'accounts',
  },
  {
    title: '광고제작 및 심의',
    value: 'adReviews',
  },
  {
    title: '광고 서비스',
    value: 'adServices',
  },
  {
    title: '결제',
    value: 'payments',
  },
  {
    title: '광고효과 및 리포팅',
    value: 'reportings',
  },
]

const FAQs = () => {
  const { appState } = React.useContext(AppContext)
  const data = appState.FAQDataList
  //
  const [selectedTab, setSelectedTab] = React.useState(0)
  const [activeItem, setActiveItem] = React.useState(0)
  //
  const onSelectTab = (index) => {
    setSelectedTab(index)
  }
  const onSearchRequested = async (e, value) => {
    const result = await getFAQs(value)
    if (!result) return []
    const searchData = result.map((item) => ({
      key: item._id,
      value: item.title,
      cat: item.categories,
    }))
    return searchData
  }
  //
  const onSearchResultSelected = async (e, searchData) => {
    let index = -1
    if (
      cats.some((item, i) => {
        if (`${searchData.cat}s` === item.value) {
          index = i
          return true
        }
        return false
      })
    ) {
      setSelectedTab(index)
      setActiveItem(searchData.key)
    }
  }
  const onSelectPanel = (id) => {
    setActiveItem(id)
  }

  return (
    <BasicLayout>
      <div className="page-headline">
        <Container>
          <h1>자주 묻는 질문</h1>
        </Container>
      </div>
      <div className="flex flex-col page-content faq">
        <div className="faq-content">
          <div>
            <div className="search">
              <p className="info">
                <span>무엇을 찾고 싶으신가요?</span>
                <br />
                <span>찾으시는 내용을 입력해주세요</span>
              </p>
              <AutoSuggestBox
                placeholder="궁금하신 내용을 입력해주세요"
                onRequested={onSearchRequested}
                onSelected={onSearchResultSelected}
              />
            </div>
            <Tabs
              className="tabs-secondary"
              selectedTabClassName="active"
              selectedIndex={selectedTab}
              onSelect={onSelectTab}
            >
              <TabList className="flex tab-list-secondary">
                {cats.map((cat) => (
                  <Tab key={cat.value} className="tab-secondary px-8">
                    {cat.title}
                  </Tab>
                ))}
                <div className="flex-grow" />
              </TabList>
              {cats.map((cat) => (
                <TabPanel key={cat.value}>
                  <Accordion activeKey={activeItem} onSelect={onSelectPanel}>
                    {data[cat.value]
                      && data[cat.value].map((item, index) => (
                        <Contents key={index} index={index} data={item} />
                      ))}
                  </Accordion>
                  {data[cat.value].length < 1 && <Empty />}
                </TabPanel>
              ))}
            </Tabs>
          </div>
          <InfoPaper>
            <p className="main">
              <span>아직 해결하지 못한 궁금증이 남아있나요?</span>
              <br />
              <span>
                전문 상담사가 여러분의 궁금증을 빠르게 해결 해드리겠습니다!
              </span>
            </p>
            <p className="sub">
              <span>
                문의사항은 오른쪽 채널톡을 이용해주시면 빠르게 답변 드리겠습니다
              </span>
            </p>
          </InfoPaper>
        </div>
      </div>
    </BasicLayout>
  )
}

const Contents = ({ data }) => {
  const contentRef = React.useRef()

  React.useEffect(() => {
    contentRef.current.innerHTML = data.contents
  }, [contentRef, data])

  return (
    <Accordion.Item eventKey={data._id}>
      <Accordion.Header>{data.title}</Accordion.Header>
      <Accordion.Body>
        <div ref={contentRef} style={{ whiteSpace: 'normal' }} />
      </Accordion.Body>
    </Accordion.Item>
  )
}

const Empty = () => (
  <div className="empty">
    <p className="fir">등록된 글이 없습니다</p>
  </div>
)

export default FAQs
