import React from 'react'
import { useFormContext } from 'react-hook-form'

const CheckboxComponent = ({
  children, onToggle, textClassName, ...props
}, forwardedRef) => {
  //
  const classes = ['checkbox']
  if (props.disabled) classes.push('disabled')
  if (props.className) classes.push(props.className)
  //
  const onInputToggle = async (e) => {
    if (onToggle) onToggle(e)
  }
  //
  return (
    <label className={classes.join(' ')} htmlFor={props.name}>
      <input ref={forwardedRef} type="checkbox" id={props.name} {...props} onChange={onInputToggle} />
      <span className={textClassName}>{children}</span>
      <span className="checkmark" />
    </label>
  )
}
const Checkbox = React.forwardRef(CheckboxComponent)
//
export const FormCheckbox = ({
  name, validators, children, onToggle, ...props
}) => {
  const methods = useFormContext()
  const { onChange, ...inputProps } = methods.register(name, validators)
  //
  const onInputToggle = async (e) => {
    await onChange(e)
    if (onToggle) onToggle(e)
  }
  //
  return (
    <Checkbox name={name} {...props} {...inputProps} onToggle={onInputToggle}>
      {children}
    </Checkbox>
  )
}
//
export default Checkbox
