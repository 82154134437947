import React from 'react'

import iconPlus from '../../../assets/icons/icon-filled-suggested-plus-circle.svg'
import iconClose from '../../../assets/icons/icon-filled-suggested-close-circle.svg'

const UploadStoreImages = ({
  images,
  onClickAdd,
  onClickDelete,
  onInputChange,
}, forwardRef) => (
  <div className="flex gap-4 overflow-x-scroll pt-[10px]">
    <input
      ref={forwardRef}
      className="hidden"
      type="file"
      onChange={onInputChange}
      multiple
    />
    <div className="flex flex-col flex-center shrink-0 !w-[144px] aspect-square border-[1px] border-main rounded-lg cursor-pointer" onClick={onClickAdd}>
      <img className="w-[21px] h-[21px]" src={iconPlus} alt="plus" />
      <span className="my-2 text-main font-bold text-[12px]">이미지 추가</span>
      <span className="text-textSecondary text-[10px]">jpg, png 형식 가능</span>
      <span className="text-textSecondary text-[10px]">용량 제한 30mb 이하</span>
    </div>
    {images.map((item, i) => {
      const src = typeof item === 'string' ? item : URL.createObjectURL(item)
      return (
        <div key={i} className="relative shrink-0 !w-[144px] aspect-square border-[1px] border-line">
          <img
            key={i}
            className="w-full h-full object-cover"
            src={src}
            alt="store"
          />
          <img className="absolute top-[-10px] right-[-10px] cursor-pointer" src={iconClose} alt="x" onClick={() => onClickDelete(i)} />
        </div>
      )
    })}
  </div>
)

export default React.forwardRef(UploadStoreImages)
