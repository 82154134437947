import React from 'react'

const ImageWrapper = ({
  images = [],
  onClick = () => {},
}) => {
  const imageClassName = 'w-[135px] h-[135px] lg:w-[200px] lg:h-[200px] border border-line object-cover'
  return images.length >= 1 && (
    <div className="flex gap-4 cursor-pointer" onClick={onClick}>
      <img className={imageClassName} src={images[0]} alt="image1" />
      {images.length > 1 && (
        <div className="relative">
          <img className={imageClassName} src={images[1]} alt="image2" />
          {images.length > 2 && (
            <>
              <div className="absolute inset-0 bg-[#333] opacity-50" />
              {/* <div className="absolute flex inset-0 items-center justify-center">
              </div> */}
              <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-black text-[24px] lg:text-[30px] bg-transparent text-white">{`+${images.length - 1}`}</p>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default ImageWrapper
