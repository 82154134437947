import React from 'react'
import { Link } from 'react-router-dom'
import StatusDot from '../../../components/statusDot'

const AdsCard = ({ adData }) => (
  <div className="franchise-card flex flex-col gap-2 !p-3 text-sm xs:text-xs">
    <div className="flex items-center gap-1">
      <StatusDot status={adData.statusCode} />
      {adData.status}
    </div>
    <span className="font-medium">{`${adData.franchiseName || ''}/${adData.franchiseTeamName}`}</span>
    <div>
      <span className="mr-4">
        <span className="font-medium">기간</span>
        {` ${adData.period}`}
      </span>
      <span>
        <span className="font-medium">광고비</span>
        {` ${adData.amount}`}
        <span className="text-textTertiary">{`(${adData.amountDetail})`}</span>
      </span>
    </div>
    <div className="flex gap-2">
      {/* <a
        className="underline text-sm xs:text-xs text-textTertiary"
        href="https://naver.com"
        target="_blank"
        rel="noreferrer"
      >
        SBA 링크
      </a> */}
      {!!adData.franchiseNotice && (
        <Link
          to={`/franchise/notice/${adData.franchiseNotice}`}
          className="underline text-sm xs:text-xs text-textTertiary"
        >
          공지사항 링크
        </Link>
      )}
    </div>
  </div>
)

export default AdsCard
