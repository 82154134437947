import React from 'react'
import { Container } from 'react-bootstrap'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import BasicLayout from '../../layouts/basic'

import { FormTextboxGroup } from '../../components/formTextbox'
import { FormUploadBoxGroup } from '../../components/formUploadBox'
import { requestPartner, uploadCompanyIntro } from '../../services/api'
import { FormCheckbox } from '../../components/checkbox'
import MessageModal from '../../components/modals'
import { CONFIG } from '../../services/config'
import targetRight from '../../assets/icons/icon-outlined-directional-right-medium.svg'
import InputValidation from '../../components/inputValidation'
//
const Partner = () => {
  const [doneModalInfo, setDoneModalInfo] = React.useState({
    title: '',
    isShown: false,
  })
  const history = useHistory()
  const methods = useForm({
    criteriaMode: 'all',
    mode: 'onSubmit',
  })
  //
  const onCompanyIntroUpload = async (file) => {
    const result = await uploadCompanyIntro(file)
    return result
  }
  const onSubmit = async (data) => {
    console.log(data)
    await requestPartner(data)
    setDoneModalInfo({
      title: '신청이 완료되었습니다',
      isShown: true,
    })
  }
  //
  const disabled = false
  return (
    <BasicLayout>
      <div className="grid xl:grid-cols-4">
        <Container className="auth-paper xl:col-start-2 xl:col-span-2 !pb-[0]">
          <div className="flex flex-row items-center justify-between mt-4">
            <h1 className="text-2xl font-bold">파트너 신청</h1>
          </div>
          <div className="mt-2 mb-4 lg:mt-4 lg:mb-8">
            <p className="text-sm text-gray-500">
              안녕하세요.
            </p>
            <p className="text-sm text-gray-500">
              Btv 우리동네광고 유통대행사 회원 신청 페이지입니다.
              <br />
              유통대행사 회원으로 활동을 원하는 분은 아래 양식에 기입해 주시면 상담연락을 드리도록 하겠습니다.
            </p>
          </div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <FormTextboxGroup
                label="업체명"
                name="business_name"
                placeholder="업체명을 입력해주세요"
                validators={{
                  required: true,
                }}
                validations={['required', 'required_business_id']}
              />
              <FormTextboxGroup
                label="성함"
                name="full_name"
                placeholder="성함을 입력해주세요"
                validators={{
                  required: true,
                }}
                validations={['required', 'required_business_id']}
              />
              <FormTextboxGroup
                label="휴대폰 번호"
                name="phone_no"
                placeholder={'휴대폰 번호는 "-" 없이 입력해 주세요'}
                validators={{
                  required: true,
                  pattern: /^01([016789])(\d{3}|\d{4})\d{4}$/,
                }}
                validations={['required']}
              />
              {/**/}
              <FormTextboxGroup
                label="이메일 주소"
                name="email"
                placeholder="이메일 주소를 입력해주세요"
                validators={{
                  required: true,
                  pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                }}
                validations={['required', 'pattern']}
              />
              <FormUploadBoxGroup
                label="회사 소개서"
                name="company_intro"
                actionName="url"
                onUpload={onCompanyIntroUpload}
                validators={{
                  required: true,
                }}
                validations={['url']}
              >
                첨부하기
              </FormUploadBoxGroup>
              <div className="mt-4 mb-8">
                <p className="text-sm text-gray-500">
                  감사합니다.
                  <br />
                  ※ 유통대행사 회원은 Btv 우리동네광고 담당과 협의 및 계약 체결후 활동이 가능합니다.
                </p>
              </div>
              <div className="flex items-center gap-4 mt-3">
                <FormCheckbox
                  className="self-center mt-2.5"
                  name="p_info_consent"
                  validators={{
                    required: true,
                  }}
                  validations={['required']}
                >
                  개인정보 수집 및 이용 동의 (필수)
                </FormCheckbox>
                <a
                  style={{ cursor: 'pointer', padding: 0 }}
                  className="flex items-center text-textSecondary smaller right-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={CONFIG.personalInfoTermURL}
                >
                  자세히
                  <img src={targetRight} alt="target" />
                </a>
              </div>
              <InputValidation
                names={['p_info_consent']}
                validations={['required']}
              />
              <div className="flex flex-row ad-buttons-holder w-full justify-center bg-white mlg:mt-8">
                <button type="submit" className="primary larger xs:h-[48px] mlg:w-full mlg:h-[48px]" disabled={disabled}>
                  신청하기
                </button>
              </div>
            </form>
          </FormProvider>
        </Container>
        <MessageModal
          type="success"
          isShown={doneModalInfo.isShown}
          onClose={() => { history.replace('/') }}
        >
          <h3>{doneModalInfo.title}</h3>
        </MessageModal>
      </div>
    </BasicLayout>
  )
}

export default Partner
