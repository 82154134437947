import React from 'react'
import Slider from 'react-slick'

import leftIcon from '../../../assets/icons/icon-outlined-directional-left-circle.svg'
import rightIcon from '../../../assets/icons/icon-outlined-directional-right-circle.svg'
//
const temp = [{
  designId: '604f0c021259f9929f97693e',
  assetId: '6439001c1b796514fcb80b84',
  subtemplateId: '6439001c1b796514fcb80b84',
  temTitle: 'Baking Steak',
  temThumbnail: 'https://skb-static-file.s3.ap-northeast-2.amazonaws.com/thum_img/%5BSKB%5DBaking%20Steak.png',
  temContent: '심플한 디자인으로 프로모션 영상을 제작해보세요!',
  videoDuration: 15,
  needVideo: 0,
  needImage: 6,
  temPreviewVideo: 'https://skb-static-file.s3.ap-northeast-2.amazonaws.com/preview/%5BSKB%5DBaking%20Steak.mp4',
  temColor: [
    null,
    null,
    null,
  ],
  bgm: null,
  projectPath: '[SKB]Baking Steak',
  group: 'design',
  languageCode: 'ko',
  categorize: 1,
  style: 1,
  templateRatio: 1,
  createdAt: '2021-03-15T07:25:54.363Z',
  updatedAt: '2021-03-23T05:39:18.564Z',
  listIndex: 3,
  kind: 2,
  needText: 8,
}, {
  designId: '60543f5c21ec3d74d6f4f310',
  assetId: '643cbe6f1b796514fcb80bba',
  subtemplateId: '643cbe6f1b796514fcb80bba',
  temTitle: 'Slide',
  temThumbnail: 'https://skb-static-file.s3.ap-northeast-2.amazonaws.com/thum_img/%5BSKB%5DSlide.png',
  temContent: '설명',
  videoDuration: 15,
  needVideo: 0,
  needImage: 9,
  temPreviewVideo: 'https://skb-static-file.s3.ap-northeast-2.amazonaws.com/preview/%5BSKB%5DSlide.mp4',
  temColor: [
    null,
    null,
    null,
  ],
  bgm: null,
  projectPath: '[SKB]Slide',
  group: 'design',
  languageCode: 'ko',
  categorize: 1,
  style: 1,
  templateRatio: 1,
  createdAt: '2021-03-19T06:06:20.543Z',
  updatedAt: '2021-03-19T06:06:20.543Z',
  listIndex: 3,
  kind: 1,
  needText: 3,
}, {
  designId: '60507ced25c6729f22513dd8',
  assetId: '643cc33c1b796514fcb80bbe',
  subtemplateId: '643cc33c1b796514fcb80bbe',
  temTitle: 'Bounce Shape',
  temThumbnail: 'https://skb-static-file.s3.ap-northeast-2.amazonaws.com/thum_img/%5BSKB%5DBounce%20Shape.png',
  temContent: '장소 이벤트를 소개하기 좋은 디자인입니다.',
  videoDuration: 15,
  needVideo: 4,
  needImage: 1,
  temPreviewVideo: 'https://skb-static-file.s3.ap-northeast-2.amazonaws.com/preview/%5BSKB%5DBounce%20Shape.mp4',
  temColor: [
    null,
    null,
    null,
  ],
  bgm: null,
  projectPath: '[SKB]Bounce Shape',
  group: 'design',
  languageCode: 'ko',
  categorize: 1,
  style: 5,
  templateRatio: 1,
  createdAt: '2021-03-16T09:39:57.701Z',
  updatedAt: '2021-03-23T05:43:12.655Z',
  listIndex: 3,
  kind: 5,
  needText: 12,
}, {
  designId: '605455c0f95c4a085a06fac6',
  assetId: '643cc19a1b796514fcb80bbc',
  subtemplateId: '643cc19a1b796514fcb80bbc',
  temTitle: 'Broken Text Intro',
  temThumbnail: 'https://skb-static-file.s3.ap-northeast-2.amazonaws.com/thum_img/%5BSKB%5DBroken%20Text%20Intro.png',
  temContent: '설명',
  videoDuration: 15,
  needVideo: 4,
  needImage: 1,
  temPreviewVideo: 'https://skb-static-file.s3.ap-northeast-2.amazonaws.com/preview/%5BSKB%5DBroken%20Text%20Intro.mp4',
  temColor: [
    null,
    null,
    null,
  ],
  bgm: null,
  projectPath: '[SKB]Broken Text Intro',
  group: 'design',
  languageCode: 'ko',
  categorize: 1,
  style: 5,
  templateRatio: 1,
  createdAt: '2021-03-19T07:41:52.031Z',
  updatedAt: '2021-03-23T05:21:26.901Z',
  listIndex: 3,
  kind: 5,
  needText: 12,
}]
//
const TemplateCarousel = ({ onSelect }) => {
  const sliderRef = React.useRef(null)
  const wrapperRef = React.useRef(null)
  //
  const onChange = async (e, state) => {
    if (state) sliderRef.current.slickNext()
    else sliderRef.current.slickPrev()
  }
  //
  const sliderSetting = {
    dots: true,
    infinite: true,
    arrows: false,
    focusOnSelect: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  //
  return (
    <div ref={wrapperRef} className="video-carousel relative pb-12">
      <div className="video-carousel-wrapper flex flex-row justify-center mx-auto max-w-full">
        <div className="arrow-wrapper hidden lg:flex justify-center z-50">
          <button
            onClick={(e) => onChange(e, false)}
            style={{ padding: 0, paddingRight: 24, margin: 0 }}
          >
            <img src={leftIcon} alt="left" style={{ margin: 0 }} />
          </button>
        </div>
        <div className="flex-grow max-w-full">
          <Slider ref={sliderRef} {...sliderSetting}>
            {temp.map((data) => (
              <button
                key={data.designId}
                className="design-paper flex flex-col !pr-4"
                onClick={(e) => onSelect(e, data)}
              >
                <div className="relative">
                  <img src={data.temThumbnail} alt="media" />
                  <span className="time">{`00:${data.videoDuration}`}</span>
                </div>
                <span className="w-full text-left">{data.temTitle}</span>
              </button>
            ))}
          </Slider>
        </div>

        <div className="arrow-wrapper hidden lg:flex justify-center">
          <button
            onClick={(e) => onChange(e, true)}
            style={{ padding: 0, paddingLeft: 24, margin: 0 }}
          >
            <img src={rightIcon} alt="right" style={{ margin: 0 }} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default TemplateCarousel
