import React from 'react'
import classNames from 'classnames'
import Draggable from 'react-draggable'

import { characters } from './SelectCharacter'
import closeCircle from '../../../assets/icons/icon-outlined-suggested-close-circle-xlarge.svg'
import iconEdit from '../../../assets/icons/icon-outlined-editor-edit-medium.svg'
import nullImage from '../../../assets/imgs/illust_voice_null.png'

const MIN_BLOCK_WIDTH = 45
const MIN_VALID_BLOCK_WIDTH = 70

const VoiceBlock = ({
  voiceBlock,
  isSelected,
  totalLength,
  unitLength,
  maxWidth,
  onStart,
  onDrag,
  onBlockStop,
  onClickDelete,
  onClickModify,
}) => {
  const {
    length, startTime, content, actor_id,
  } = voiceBlock
  const ref = React.useRef(null)
  const position = isSelected ? undefined : { x: (startTime / totalLength) * maxWidth, y: 0 }
  const width = Math.max(maxWidth * (length / totalLength), MIN_BLOCK_WIDTH)
  const actor = characters.find((item) => item.id === actor_id)

  const wrapperClass = classNames(
    'flex h-[75px] select-none',
    'font-bold text-textPrimary rounded',
    { 'bg-[#fff4e5]': onClickDelete },
    { 'bg-white border': !onClickDelete },
    { 'border-[1px] border-[#ff0000]': !actor },
  )

  return (
    <Draggable
      axis="x"
      handle=".handle"
      nodeRef={ref}
      defaultPosition={{ x: (startTime / totalLength) * maxWidth, y: 0 }}
      position={position}
      grid={[unitLength, 0]}
      scale={1}
      bounds={{ left: 0, right: maxWidth }}
      onStart={onStart}
      onDrag={onDrag}
      onStop={onBlockStop}
      disabled={!onBlockStop}
    >
      <div
        ref={ref}
        className={wrapperClass}
        style={{ width }}
      >
        <div className="handle flex-1 flex flex-col items-start justify-center pl-1 py-1 cursor-grab active:cursor-grabbing">
          {
            width <= MIN_VALID_BLOCK_WIDTH ? (<span>...</span>) : (
              <>
                <div className="flex items-center">
                  <div className="flex w-[18px] h-[18px] mb-1 rounded-full overflow-hidden">
                    <img className="w-full h-full" src={actor?.image || nullImage} alt="illust" />
                  </div>
                  <span className="text-[9px] ml-1 mt-[-2px]">{actor?.name}</span>
                </div>
                <div className="flex-1 flex items-center" style={{ maxWidth: width - 24 }}>
                  <span className="text-xs truncate">{content}</span>
                </div>
                <span className="bottom-2 right-2 text-xs">{`${length.toFixed(1)}s`}</span>
              </>
            )
          }
        </div>
        {onClickDelete && (
          <div className="flex flex-col gap-2 py-1 pr-1">
            <button type="button" className="w-[20px] h-[20px] p-0 cursor-pointer" onClick={onClickDelete}>
              <img className="m-0" src={closeCircle} alt="close" />
            </button>
            <button type="button" className="w-[20px] h-[20px] p-0 cursor-pointer" onClick={onClickModify}>
              <img className="m-0" src={iconEdit} alt="close" />
            </button>
          </div>
        )}
      </div>
    </Draggable>
  )
}

export default VoiceBlock
