import React from 'react'
import { Container } from 'react-bootstrap'

import BasicLayout from '../../layouts/basic'
import NoticeTable from './components/noticeTable'
import AppContext from '../../services/context'
import ThisYearDataView from './components/thisYearDataView'
import RegionDataView from './components/RegionDataView'
import { hideFab } from '../../services/fab'

//
const FranchiseNotice = () => {
  hideFab()
  const { appState } = React.useContext(AppContext)

  const [selectedRegions, setSelectedRegions] = React.useState(
    appState?.userInfo?.companyRank === 9
      ? ['capital', 'western', 'midland', 'busan', 'daegu', 'jeju']
      : [appState?.userInfo?.company?.region],
  )

  return (
    <BasicLayout>
      <Container className="px-4 mlg:bg-white lg:px-0">
        <div className="mt-6 mlg:max-w-[327px] mlg:mx-auto">
          {appState.userInfo?.companyRank >= 8 && (
            <>
              <ThisYearDataView />
              <RegionDataView selectedRegions={selectedRegions} setSelectedRegions={setSelectedRegions} />
            </>
          )}
          <NoticeTable regions={selectedRegions} />
        </div>
      </Container>
    </BasicLayout>
  )
}

export default FranchiseNotice
