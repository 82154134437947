import React from 'react'
import { Link } from 'react-router-dom'
import BasicLayout from '../../layouts/basic'
// section 3
import img3 from '../../assets/imgs/9037.webp'
import img4 from '../../assets/imgs/9079.webp'
import QuestionMark from '../../assets/icons/7848@3x.webp'
import { GrayPaper, InfoPaper } from './components/papers'
import img5 from '../../assets/imgs/9078.svg'
import VideoToggle from './components/videoToggle'
import vid1 from '../../assets/vids/vid11.mp4'
import vid2 from '../../assets/vids/vid12.mp4'
import vid3 from '../../assets/vids/vid13.mp4'
import imgCreateAsset1 from '../../assets/imgs/our_service_create_assets.gif'
import imgCreateAsset2 from '../../assets/imgs/our_service_create_assets2.gif'
import VideoTabs from './components/videoTabs'
import { useWindowSize } from '../../services/utils'

import smallDots from '../../assets/imgs/10035.svg'
import dots from '../../assets/imgs/10036.svg'
import step1 from '../../assets/imgs/10037.svg'
import step2 from '../../assets/imgs/10038.svg'
import step3 from '../../assets/imgs/10039.svg'
import imgReport from '../../assets/imgs/our-service-report.gif'
import rightIcon from '../../assets/icons/icon-outlined-directional-right-large-primary.svg'
import BottomContactBar from '../../components/BottomContactBar'
import useScrollDirection from '../../hooks/useScrollDirection'

const steps = [
  {
    id: 1,
    title: '광고 노출 지역',
    content: '시/군/구 또는 동 단위로 지역을 선택할 수 있습니다.\n지역을 선택하면 해당지역의 광고대상수가 정해집니다.',
  },
  {
    id: 2,
    title: '광고 기간',
    content: '광고 신청일 기준으로 3일 이후부터 광고를 시작할 수 있습니다.',
  },
  {
    id: 3,
    title: '광고 예산',
    content:
      '소상공인 경제 활성화를 위하여 24년 할인된 단가로 15초 영상은 1회 노출 당 5원(VAT별도)이 과금되고 30초 영상은 1회 노출 당 10원(VAT별도)이 과금됩니다.',
    point: '단, 일부 지역은 제외됩니다.',
  },
]
//
const sec6Contents = [
  {
    p: '노출 횟수',
    hs1: '광고는 몇 번 노출 할 때 가장 효과적인가요?',
    ps1: '1 가구당 최소 14회 이상 반복 노출 시 효과를 볼 수 있습니다.',
  },
  {
    p: '광고 기간',
    hs1: '광고 기간은 대체로 몇 개월로 설정하나요?',
    ps1: '3개월 이상을 추천 드립니다.',
  },
  {
    p: '노출 횟수',
    hs1: '광고 단가는 얼마이며, 추천 예산이 궁금해요.',
    ps1: '최소 10만원/월(VAT 별도)부터 광고가 가능하며, ‘광고비 알아보기’에서 광고 횟수를 확인하실 수 있습니다.\n\n24년 할인된 단가로 15초 영상은 1회 노출에 5원(VAT 별도)이 과금되고 30초 영상은 10원(VAT 별도)이 과금됩니다.',
    point: '단, 일부 지역은 제외',
  },
]
const sec6Contents2 = {
  p: '문의',
  hs1: '전문 상담사가 필요하신가요?',
  hs2: '우측 하단 채널톡으로 문의주시면 빠른 답변 드리겠습니다.',
  img: img4,
}
//
const OurServices = () => {
  const [width] = useWindowSize()
  const scrollDirection = useScrollDirection()

  const vid1Ref = React.useRef()
  const vid4Ref = React.useRef()
  const vid5Ref = React.useRef()
  //

  React.useEffect(() => {
    vid1Ref.current?.play()
    vid4Ref.current?.play()
    vid5Ref.current?.play()
  }, [])
  //
  return (
    <BasicLayout>
      <div className="our-services">
        <section className="section-01">
          <div className="container flex flex-col h-full relative">
            <h1 className="hidden md:block">
              <span>내가 만든 우리가게 TV 광고를</span>
              <br />
              <span>인기 채널에서</span>
              <br />
              <span>저렴하게 홍보할 수 있는 기회</span>
            </h1>
            <p className="flex-grow hidden md:block">
              <span>사람들이 좋아하는 인기 채널에서 방송되는 프로그램이 끝난 후</span>
              <br />
              <span>내가 만든 TV광고가 노출됩니다.</span>
            </p>
            <h1 className="block md:hidden">
              <span>내가 만든</span>
              <br />
              <span>우리가게 TV 광고를</span>
              <br />
              <span>인기 채널에서 저렴하게</span>
              <br />
              <span>홍보할 수 있는 기회</span>
            </h1>
            <p className="flex-grow block md:hidden">
              <span>사람들이 좋아하는 인기 채널에서 방송되는</span>
              <br />
              <span>{' 프로그램이 끝난 후'}</span>
              <br />
              <span>내가 만든 우리가게 TV광고가 노출됩니다.</span>
            </p>
            <div className="video-holder relative justify-end">
              <video
                className="w-full"
                ref={vid1Ref}
                controls
                autoPlay
                playsInline
                muted
                controlsList="nodownload"
                onContextMenu={(e) => e.preventDefault()}
                src="https://skb-static-file.s3.ap-northeast-2.amazonaws.com/about_service.mp4"
              />
            </div>
          </div>
        </section>

        <section className="section-02">
          <div className="container">
            <h2>모두가 할 수 있는 광고 만들기</h2>
            <p className="subtitle hidden lg:block">가장 쉽고 단순한 방법으로 나의 광고를 널리 알려보세요.</p>
            <p className="subtitle block lg:hidden whitespace-pre-wrap">
              {'가장 쉽고 단순한 방법으로\n나의 광고를 널리 알려보세요.'}
            </p>
            <div className="gray-steps-paper flex flex-row justify-center">
              <div className="flex flex-row lg:grid lg:grid-cols-5 items-center step-papaer">
                <div className="flex flex-col items-center">
                  <img src={step1} alt="step" />
                  <span>광고 정보 입력</span>
                </div>
                <div className="dots">
                  <img className="hidden lg:block" src={dots} alt="dots" />
                  <img className="block lg:hidden" src={smallDots} alt="dots" />
                </div>
                <div className="flex flex-col items-center">
                  <img src={step2} alt="step" />
                  <span>광고 소재 등록</span>
                </div>
                <div className="dots">
                  <img className="hidden lg:block" src={dots} alt="dots" />
                  <img className="block lg:hidden" src={smallDots} alt="dots" />
                </div>
                <div className="flex flex-col items-center">
                  <img src={step3} alt="step" />
                  <span>광고 예산 결제</span>
                </div>
              </div>
            </div>
            <div className="note">
              <span>광고 정보 입력</span>
              <h3>입력한 정보로 광고 대상 수, 기간, 노출 수를 확인할 수 있어요.</h3>
            </div>
            <VideoToggle data={steps} vid1={vid1} vid2={vid2} vid3={vid3} />
            <VideoTabs data={steps} vids={[vid1, vid2, vid3]} />
          </div>
        </section>
        <section className="section-03">
          <div className="container">
            <div className="flex flex-col lg:flex-row justify-between part-1">
              <div className="note flex flex-col">
                <span>광고 소재 등록</span>
                <h3 className="flex-grow lg:flex-grow-0">
                  <span>광고 소재가 없으신가요?</span>
                  <br />
                  <span>광고 소재가 없다면 간단한 작업으로 직접</span>
                  <br />
                  <span>광고를 만드실 수 있습니다.</span>
                </h3>
                <div>
                  <Link to="/tutorial/asset">
                    <button className="secondary large">소재 제작 방법 보기</button>
                  </Link>
                </div>
              </div>
              <div className="image-wrapper">
                <img src={imgCreateAsset1} alt="media" />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row-reverse justify-end">
              <div className="note flex flex-col ">
                <span>광고 소재 등록(기존 소재 업로드)</span>
                <h3 className="block md:hidden lg:block flex-grow lg:flex-grow-0">
                  <span>이미 제작한 소재가 있다면</span>
                  <br />
                  <span>기존 영상을 업로드할 수 있습니다.</span>
                  <br />
                  <span>B tv 우리동네광고 소재 가이드에 맞는지</span>
                  <br />
                  <span>확인하여 주세요.</span>
                </h3>
                <h3 className="hidden md:block lg:hidden flex-grow lg:flex-grow-0">
                  <span>이미 제작한 소재가 있다면 기존 영상을 업로드할 수 있습니다.</span>
                  <br />
                  <span>B tv 우리동네광고 소재 가이드에 맞는지 확인하여 주세요.</span>
                </h3>
                <div>
                  <Link to="/tutorial/asset">
                    <button className="secondary large">소재 가이드 확인하기</button>
                  </Link>
                </div>
              </div>
              <div className="image-wrapper lg:mr-10">
                <img src={imgCreateAsset2} alt="media" />
              </div>
            </div>
          </div>
        </section>
        <section className="section-04">
          <div className="container">
            <div className="flex flex-col lg:flex-row justify-between part-1">
              <div className="note flex flex-col">
                <span>광고 예산 결제</span>
                <h3 className="flex-grow">
                  <span>광고비를 결제하면 광고 심의 후</span>
                  <br />
                  <span>광고 송출이 시작됩니다.</span>
                </h3>
                <div>
                  <Link to="/tutorial/ad">
                    <button className="secondary large">{'광고 심사 가이드 확인하기 '}</button>
                  </Link>
                </div>
              </div>
              <div>
                <img src={img3} alt="r1" />
              </div>
            </div>
            <div>
              <InfoPaper src={QuestionMark}>
                <p className="main hidden whitespace-pre-wrap lg:block">
                  광고 만들기 과정에 대해 자세히 알아보고 싶으신가요?
                </p>
                <p className="main whitespace-pre-wrap lg:hidden">
                  {'광고 만들기 과정에 대해\n자세히 알아보고 싶으신가요?'}
                </p>
                <Link to="/tutorial/ad">
                  <button className="large secondary">광고 만드는 방법 자세히 보기</button>
                </Link>
              </InfoPaper>
            </div>
          </div>
        </section>
        <section className="section-05">
          <div className="container flex flex-col items-center">
            <h2 className="hidden md:block">내가 만든 광고의 효과를 한 눈에</h2>
            <p className="hidden lg:block">채널별 • 지역별 • 시간별로 놀라운 광고효과를 확인할 수 있어요.</p>
            <h2 className="block md:hidden whitespace-pre-wrap">{'내가 만든 광고의\n효과를 한 눈에'}</h2>
            <p className="block lg:hidden whitespace-pre-wrap">
              {'채널별 • 지역별 • 시간별로 놀라운\n광고효과를 확인할 수 있어요.'}
            </p>
            <img src={imgReport} alt="empty" />
          </div>
        </section>
        <section className="section-06">
          <div className="container">
            <h2>높은 효과의 시작은 단순하게</h2>
            <p className="subtitle">작은 숫자가 놀라운 효과를 가져옵니다.</p>
            <div className="grid grid-cols-1 gap-10">
              {sec6Contents.map((item, index) => (
                <GrayPaper {...item} key={index} />
              ))}
              <GrayPaper {...sec6Contents2} />
            </div>
          </div>
        </section>
        <section className="section-07">
          <div className="container flex flex-col md:flex-row lg:grid lg:grid-cols-3 lg:gap-16 relative">
            <div className="lg:col-span-2">
              <h2 className="hidden lg:block">
                <span>B tv 우리동네광고가</span>
                <br />
                <span>여러분의 사업에 힘이 되어 드리겠습니다.</span>
              </h2>
              <h2 className="hidden md:block lg:hidden">
                <span>B tv 우리동네광고가</span>
                <br />
                <span>여러분의 사업에 힘이 되어</span>
                <br />
                <span>드리겠습니다.</span>
              </h2>
              <h2 className="block md:hidden">
                <span>B tv 우리동네광고가</span>
                <br />
                <span>여러분의 사업에</span>
                <br />
                <span>힘이 되어 드리겠습니다.</span>
              </h2>
              <p>이제 당신의 차례입니다!</p>
              <Link className="button lg:larger secondary" to="/portal/media">
                새로운 광고 시작하기
                <img src={rightIcon} alt="right" />
              </Link>
            </div>
            <img className="section-image" src={img5} alt="r1" />
          </div>
        </section>
        {width >= 798 && <BottomContactBar scrollDirection={scrollDirection} />}
      </div>
    </BasicLayout>
  )
}

export default OurServices
