import React from 'react'
import { TabPanel } from 'react-tabs'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import MyAdsLayout from '../../layouts/myAdsLayout'
import { createCampaign, getCampaigns, fetchContracts } from '../../services/api'
import AppContext from '../../services/context'
import ChannelService from '../../services/channel'
import { useWindowSize } from '../../services/utils'
import AdCampaignCard from './components/adCampaignCard'
import useSelectCompanyModal from './hooks/useSelectCompanyModal'
import useChangeNameModal from './hooks/useChangeNameModal'
import { CreateAdCard } from '../../components/adCard'
import FloatingActionButton from '../../components/floatingActionButton'
import { TwoButtonModal } from '../../components/modals'

const initialSelectValue = {
  value: 'all',
  title: '전체',
}
const filterArr = ['all', 'asset', 'inspection', 'broadcast']
const initialPageOption = {
  page: 1,
  hasNextPage: false,
}

const Media = () => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const [width] = useWindowSize()
  const history = useHistory()
  const [cookies] = useCookies(['jwt_token'])
  //
  const [selectList, setSelectList] = React.useState([initialSelectValue])
  const [selectValue, setSelectValue] = React.useState('all')
  const [selectedMenu, setSelectedMenu] = React.useState(null)
  const [pageOption, setPageOption] = React.useState(initialPageOption)
  const [order, setOrder] = React.useState('updatedAt')
  const [selectedTab, setSelectedTab] = React.useState(0)
  const [loadFlag, setLoadFlag] = React.useState(false)
  const [modalVisible, setModalVisible] = React.useState(false)

  const [campaigns, setCampaigns] = React.useState([])
  const myCompany = {
    value: appState?.userInfo?.company?._id,
    title: appState?.userInfo?.company?.companyName,
  }

  const observer = React.useRef()

  const initializeList = async () => {
    await setCampaigns([])
    await setPageOption(initialPageOption)
  }

  const trigger = async (initialize = false) => {
    if (initialize) {
      await initializeList()
    }
    setLoadFlag((prev) => !prev)
  }

  //
  React.useEffect(() => {
    if (width < 798) {
      appDispatch({ type: 'updateChannelTalkHide', payload: true })
      ChannelService.hide()
    } else {
      appDispatch({ type: 'updateChannelTalkHide', payload: false })
      ChannelService.show()
    }
    return () => {
      appDispatch({ type: 'updateChannelTalkHide', payload: false })
      ChannelService.show()
    }
  }, [width])
  //
  React.useEffect(() => {
    const onClickOutside = async (e) => {
      if (e.path) {
        const isMenu = e.path.some((item) => item?.className === 'menu-wrapper')
        if (isMenu) return
      }
      const openedMenu = window.document.getElementsByClassName('menu-holder show')
      if (openedMenu.length > 0) {
        openedMenu[0].classList.remove('show')
      }
    }
    document.addEventListener('click', onClickOutside)
    return () => document.removeEventListener('click', onClickOutside)
  }, [])
  //
  const selectCompanyCallback = async (companyId) => {
    let result = null
    if (companyId === myCompany.value) {
      result = await createCampaign(cookies.jwt_token)
    } else {
      result = await createCampaign(cookies.jwt_token, companyId)
    }
    if (!result) return
    await appDispatch({ type: 'updateAdCampaign', payload: result })
    history.push('/portal/media/create/1')
  }
  const { onClickCreateCampaign, renderSelectCompanyModal } = useSelectCompanyModal(
    myCompany,
    selectList,
    selectCompanyCallback,
  )
  //
  const onCreateClick = async () => {
    if (Number(appState.userInfo?.userType) === 1) {
      onClickCreateCampaign()
    } else {
      if (appState.userInfo?.method !== 'skb' && !appState.userInfo?.userName) {
        setModalVisible(true)
        return
      }
      const result = await createCampaign(cookies.jwt_token)
      if (!result) return
      await appDispatch({ type: 'updateAdCampaign', payload: result })
      history.push('/portal/media/create/1')
    }
  }
  //
  // const fetchCampaigns = React.useCallback(async (initialize = false) => {

  // }, [cookies.jwt_token, selectValue, appState?.userInfo, pageOption.page, selectedTab, order])
  //
  React.useEffect(() => {
    (async () => {
      const result = await getCampaigns(
        cookies.jwt_token,
        selectValue,
        Number(appState?.userInfo?.companyRank),
        appState?.userInfo?.company?._id,
        order,
        pageOption.page,
        filterArr[selectedTab],
      )
      if (!result) return
      await setPageOption((prev) => ({
        ...prev,
        hasNextPage: result.hasNextPage,
      }))
      await setCampaigns((prev) => [...prev, ...result.docs])
    })()
  }, [loadFlag])

  React.useEffect(() => {
    (async () => {
      const result = await fetchContracts(
        cookies.jwt_token,
        Number(appState?.userInfo?.companyRank),
        appState?.userInfo?.company?._id,
      )
      if (!result) return
      const keyName = Number(appState.userInfo?.userType) === 0 ? 'business' : 'user'
      const formattedValue = result
        .filter((item) => item.status === 'accepted')
        .map((item) => ({
          value: item[keyName]?._id,
          title: `${item[keyName]?.company?.companyName || '회사명'} - ${item[keyName]?.userName}`,
        }))
      setSelectList([initialSelectValue, ...formattedValue])
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const lastElementRef = React.useCallback(
    (node) => {
      // if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && pageOption.hasNextPage) {
          setPageOption((prev) => ({ ...prev, page: prev.page + 1 }))
          trigger()
        }
      })
      if (node) observer.current.observe(node)
    },
    [pageOption.hasNextPage],
  )

  const changeNameCallback = (id, value) => {
    const newArr = [...campaigns]
    const i = newArr.findIndex((item) => item._id === id)
    newArr[i].campaignName = value
    setCampaigns(newArr)
  }

  const { onClickChangeName, renderChangeNameModal } = useChangeNameModal('campaign', changeNameCallback)

  const onSort1Click = async () => {
    await setOrder('updatedAt')
    trigger(true)
  }
  const onSort2Click = async () => {
    await setOrder('createdAt')
    trigger(true)
  }
  const onChangeSelect = async (e) => {
    await setSelectValue(e.target.value)
    trigger(true)
  }
  const onClickTab = async (index) => {
    await setSelectedTab(index)
    trigger(true)
  }
  const onClickMenu = (id) => {
    if (selectedMenu === id) setSelectedMenu(null)
    else setSelectedMenu(id)
  }

  return (
    <MyAdsLayout
      order={order}
      onSort1Click={onSort1Click}
      onSort2Click={onSort2Click}
      onChangeSelect={onChangeSelect}
      selectList={selectList}
      selectValue={selectValue}
      selectedTab={selectedTab}
      onClickTab={onClickTab}
    >
      <TabPanel>
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 asset-container">
          <CreateAdCard
            className="hidden lg:flex"
            title="광고 만들기"
            buttonText="새로운 광고 만들기"
            toText="광고 만드는 방법 보기"
            onClick={onCreateClick}
            to="/tutorial/ad"
          >
            <p className="flex-grow">
              <span>원하는대로 지역과 기간을 선택하고</span>
              <br />
              <span>소재를 넣어 광고를 만들어보세요.</span>
            </p>
          </CreateAdCard>
          {campaigns.map((item, index) => {
            const isLastElement = campaigns.length === index + 1
            return (
              <AdCampaignCard
                key={index}
                data={item}
                trigger={trigger}
                onClickChangeName={onClickChangeName}
                selectedMenu={selectedMenu}
                onClickMenu={onClickMenu}
                ref={isLastElement ? lastElementRef : null}
              />
            )
          })}
          {/**/}
        </div>
      </TabPanel>
      <TabPanel>
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 asset-container">
          {campaigns.map((item, index) => {
            const isLastElement = campaigns.length === index + 1
            return (
              <AdCampaignCard
                key={index}
                data={item}
                trigger={trigger}
                onClickChangeName={onClickChangeName}
                selectedMenu={selectedMenu}
                onClickMenu={onClickMenu}
                ref={isLastElement ? lastElementRef : null}
              />
            )
          })}
          {campaigns.length === 0 && <Empty title="제작한 광고가 없습니다" />}
        </div>
      </TabPanel>
      <TabPanel>
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 asset-container">
          {campaigns.map((item, index) => {
            const isLastElement = campaigns.length === index + 1
            return (
              <AdCampaignCard
                key={index}
                data={item}
                trigger={trigger}
                onClickChangeName={onClickChangeName}
                selectedMenu={selectedMenu}
                onClickMenu={onClickMenu}
                ref={isLastElement ? lastElementRef : null}
              />
            )
          })}
          {campaigns.length === 0 && <Empty title="심사중인 광고가 없습니다" />}
        </div>
      </TabPanel>
      <TabPanel>
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 asset-container">
          {campaigns.map((item, index) => {
            const isLastElement = campaigns.length === index + 1
            return (
              <AdCampaignCard
                key={index}
                data={item}
                trigger={trigger}
                onClickChangeName={onClickChangeName}
                selectedMenu={selectedMenu}
                onClickMenu={onClickMenu}
                ref={isLastElement ? lastElementRef : null}
              />
            )
          })}
          {campaigns.length === 0 && <Empty title="집행한 광고가 없습니다" />}
        </div>
      </TabPanel>
      <FloatingActionButton onClick={onCreateClick} />
      {renderChangeNameModal()}
      {renderSelectCompanyModal()}
      {modalVisible && (
        <TwoButtonModal
          title="회원 정보가 필요합니다."
          primaryText="회원정보 등록"
          secondaryText="취소"
          onClickPrimary={() => {
            history.push('/user/register')
          }}
          onClickSecondary={() => {
            setModalVisible(false)
          }}
          onClose={() => {
            setModalVisible(false)
          }}
        >
          <p className="text-lg mb-0 whitespace-pre-wrap text-center">
            소셜로그인 가입자는 추가적인 회원정보 입력이 필요합니다.
          </p>
        </TwoButtonModal>
      )}
    </MyAdsLayout>
  )
}

const Empty = ({ title }) => (
  <div className="empty lg:col-span-2 xl:col-span-3">
    <p className="fir">{title}</p>
    <p className="sec">
      <span>지금 바로 광고를 제작해</span>
      <br />
      <span>늘어나는 고객, 높아지는 인지도를 경험해보세요!</span>
    </p>
  </div>
)

export default Media
