import React from 'react'

import infoIcon from '../../../assets/icons/icon-info.svg'
import { formatDate, transNumToKoreanMan } from '../../../services/utils'

const NoticeInfo = ({ notice, status }) => {
  const amount = `총액 ${transNumToKoreanMan(notice.headOfficeBudget + notice.franchiseBudget)}원`
  const amountDetail = `(매장부담 ${transNumToKoreanMan(notice.franchiseBudget)}원, 본사 부담 ${transNumToKoreanMan(
    notice.headOfficeBudget,
  )}원)`
  return (
    <>
      <div className="hidden lg:flex mt-4 p-3 gap-3 bg-bgColor rounded-[6px]">
        <img src={infoIcon} alt="info" className="w-[20px] h-[20px]" />
        <div className="flex flex-col gap-2 font-bold">
          <span>광고 기간</span>
          <span>예산 (광고비)</span>
          {/* <span>영상 제작비</span> */}
          <span>상태</span>
        </div>
        <div className="flex flex-col gap-2">
          <span>{`${formatDate(notice.startAt, '.')} ~ ${formatDate(notice.endAt, '.')}`}</span>
          <span>{`${amount} ${amountDetail}`}</span>
          {/* <span>{notice.videoCost}</span> */}
          <span>{status}</span>
        </div>
      </div>
      <div className="flex flex-col p-3 gap-3 bg-bgColor lg:hidden">
        <img src={infoIcon} alt="info" className="w-[20px] h-[20px]" />
        <div className="flex flex-col">
          <span className="font-bold">광고 기간</span>
          <span>{`${formatDate(notice.startAt, '.')} ~ ${formatDate(notice.endAt, '.')}`}</span>
        </div>
        <div className="flex flex-col">
          <span className="font-bold">예산 (광고비)</span>
          <span>{amount}</span>
          <span>{amountDetail}</span>
        </div>
        {/* <div className="flex flex-col">
          <span className="font-bold">영상 제작비</span>
          <span>{notice.videoCost}</span>
        </div> */}
        <div className="flex flex-col">
          <span className="font-bold">상태</span>
          <span>{status}</span>
        </div>
      </div>
    </>
  )
}

export default NoticeInfo
