/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import { Table } from 'react-bootstrap'

import AppContext from '../../services/context'
import MyPageLayout from '../../layouts/myPageLayout'
import { getFranchiseList } from '../../services/api/dashboard'
import { formatNumber, transNumToKoreanMan } from '../../services/utils'
//
const FranchiseManagement = () => {
  const { appState } = React.useContext(AppContext)
  const { enqueueSnackbar } = useSnackbar()
  const [cookies] = useCookies(['jwt_token'])
  const [franchiseList, setFranchiseList] = React.useState([])

  React.useEffect(() => {
    if (appState.userInfo?.companyRank >= 8) {
      getFranchiseList(cookies.jwt_token).then((result) => {
        setFranchiseList(result)
      })
    } else {
      enqueueSnackbar('권한이 없습니다.', { variant: 'error' })
    }
  }, [appState.userInfo?.companyRank])
  //
  return (
    <MyPageLayout navigationTitle="대리점 관리" isHeaderHiddenInMobile>
      <div className="hidden header lg:block">
        <h3>대리점 관리</h3>
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th>대리점명</th>
            <th>청약건수</th>
            <th>청약금액</th>
          </tr>
        </thead>
        <tbody>
          {franchiseList.length > 0
            && franchiseList.map((item, index) => (
              <tr key={item._id}>
                <td>{item.companyName}</td>
                <td>
                  {item.count}
                  건
                </td>
                <td>
                  {transNumToKoreanMan(item.expense)}
                  원
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </MyPageLayout>
  )
}

export default FranchiseManagement
