import React from 'react'
import { useFormContext } from 'react-hook-form'

const RadioComponent = ({
  children, onSelect, value, ...props
}, forwardedRef) => {
  //
  const classes = ['radio']
  if (props.disabled) classes.push('disabled')
  if (props.className) classes.push(props.className)
  //
  const onInputSelect = async (e) => {
    if (onSelect) onSelect(e)
  }
  //
  return (
    <label className={classes.join(' ')} htmlFor={value}>
      <input
        ref={forwardedRef}
        type="radio"
        id={value}
        value={value}
        {...props}
        onChange={onInputSelect}
      />
      <span>{children}</span>
      <span className="checkmark" />
    </label>
  )
}
const Radio = React.forwardRef(RadioComponent)
//
export const FormRadiobox = ({
  name, value, validators, children, onSelect, ...props
}) => {
  const methods = useFormContext()
  const { onChange, ...inputProps } = methods.register(name, validators)
  //
  const onInputSelect = async (e) => {
    await onChange(e)
    if (onSelect) onSelect(e)
  }
  //
  return (
    <Radio name={name} value={value} {...props} {...inputProps} onSelect={onInputSelect}>
      {children}
    </Radio>
  )
}

export default Radio
