import moment from 'moment'

export const holidays = [
  // 2022
  // 추석(금,월)
  '2022-09-09',
  '2022-09-12',
  // 개천절(월)
  '2022-10-03',
  // 한글날(월)
  '2022-10-10',
]

export const getMinDateByBusinessDays = (days) => {
  const today = moment()
  let count = days
  while (count > 0) {
    today.add(1, 'd')
    const isWeekend = today.day() === 0 || today.day() === 6
    const isHolyday = holidays.includes(today.format('YYYY-MM-DD'))
    if (!isWeekend && !isHolyday) count -= 1
  }
  return today.toDate()
}
