import React from 'react'

import imgMarket from '../assets/imgs/community-market.svg'

const CategoryButton = ({
  selected, isTradMarket = false, onClick, children,
}) => {
  const temp = selected ? 'border-main bg-main text-white font-medium' : 'border-line bg-white'
  const catClassName = `py-[16px] rounded-full border-[1px] cursor-pointer ${temp}`
  if (isTradMarket) {
    return (
      <div
        className={`relative pl-[25px] pr-[35px] font-black text-secondary ${catClassName}`}
        onClick={onClick}
      >
        <img className="absolute top-[-13px] left-1/2 -translate-x-1/2" src={imgMarket} alt="market" />
        {children}
        <span className={`absolute top-2.5 right-3 text-[10px] text-${selected ? 'white' : 'main'}`}>HOT</span>
      </div>
    )
  }
  return (
    <div
      key={children}
      className={`px-[25px] ${catClassName}`}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default CategoryButton
