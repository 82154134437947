import { useQuery } from 'react-query'
import { CONFIG } from '../config'
import { getFranchiseAdsRow, transformAdCampaign } from '../utils'

export const getMyFranchiseAdsList = async (token) => {
  const REQUEST_URL = new URL('/franchise/media/myads', CONFIG.API_ENDPOINT)
  REQUEST_URL.searchParams.set('limit', 10)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  return result.data
}

export const useMyFranchiseAdsList = (token, enabled = false) => useQuery({
  queryKey: 'myFranchiseAdsList',
  queryFn: async () => {
    const result = await getMyFranchiseAdsList(token)
    return getFranchiseAdsRow(result)
  },
  enabled,
})

export const createFranchiseCampaign = async (token, { campaign, ...rest }) => {
  const REQUEST_URL = new URL('/franchise/media/campaign', CONFIG.API_ENDPOINT)
  let body = {
    ...rest,
  }
  if (campaign) {
    body = {
      ...body,
      campaign: transformAdCampaign(campaign, 1),
    }
  }
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}
