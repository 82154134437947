import React from 'react'
import QuestionMark from '../../../assets/icons/question_mark.svg'

export const GrayPaper = ({
  p, hs1, hs2, ps1, img, point,
}) => (
  <div className="intro-paper flex flex-col">
    <div className="gray-part flex flex-col lg:flex-row">
      <div className="flex-grow">
        <span className="note">{p}</span>
        <h3 className="flex-grow">
          <span>{hs1}</span>
          {hs2 && (
            <>
              <br />
              <span>{hs2}</span>
            </>
          )}
        </h3>
      </div>
      {img && <img className="self-end" src={img} alt="icon" />}
    </div>
    {/**/}
    {ps1 && (
      <div className="white-part">
        <p className="note-2 whitespace-word-wrap" style={{ marginBottom: 0, wordBreak: 'keep-all' }}>
          {ps1}
        </p>
        {point && (
          <p className="note-2" style={{ color: 'red' }}>
            {point}
          </p>
        )}
      </div>
    )}
  </div>
)

export const InfoPaper = ({ src, children }) => {
  const imgSrc = src || QuestionMark
  return (
    <div className="info-paper flex flex-col lg:flex-row">
      <img src={imgSrc} alt="question_mark" />
      <div>{children}</div>
    </div>
  )
}
