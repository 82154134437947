import React from 'react'
import { useFormContext } from 'react-hook-form'
// import { ContentModal } from "../../../components/modals"
import InputValidation from '../../../components/inputValidation'
import Checkbox, { FormCheckbox } from '../../../components/checkbox'
import targetRight from '../../../assets/icons/icon-outlined-directional-right-medium.svg'
import { CONFIG } from '../../../services/config'
//
const TermsConditions = () => {
  const toggleAllRef = React.useRef(null)
  const methods = useFormContext()
  //
  // const [state, setState] = React.useState({
  //   tc_detailsShown: false,
  //   p_info_detailsShown: false
  // })
  //
  const refresh = async () => {
    const values = await methods.getValues([
      'tc_agreement',
      'p_info_consent',
      'age_restriction',
      'email_notification',
      'sms_notification',
      'marketing_privacy',
      'marketing_subscribe',
    ])
    const all = values.every((item) => {
      if (item === false) {
        toggleAllRef.current.checked = false
        return false
      }
      return true
    })
    if (all) {
      toggleAllRef.current.checked = true
    }
  }
  //
  const onToggleNotifications = async (e) => {
    await methods.setValue('email_notification', e.target.checked)
    await methods.setValue('sms_notification', e.target.checked)
    await refresh()
  }
  //
  const onToggleAll = async (e) => {
    await methods.setValue('tc_agreement', e.target.checked)
    await methods.setValue('p_info_consent', e.target.checked)
    await methods.setValue('age_restriction', e.target.checked)
    await methods.setValue('marketing_privacy', e.target.checked)
    await methods.setValue('marketing_subscribe', e.target.checked)
    await onToggleNotifications(e)
    // marketingPrivacyRef.current.checked = e.target.checked
    // marketingSubscribeRef.current.checked = e.target.checked
    await refresh()
  }
  //
  const onFormCheckboxToggle = async () => {
    await refresh()
  }
  //
  // const onShowInfo = (e, type) => {
  //   e.preventDefault()
  //   if (type === "tc_agreement") setState({ ...state, tc_detailsShown: true })
  //   if (type === "p_info_consent")
  //     setState({ ...state, p_info_detailsShown: true })
  // }
  //
  return (
    <div className="checkboxes">
      <span className="required">이용약관동의</span>
      <InputValidation
        names={['tc_agreement', 'p_info_consent', 'age_restriction']}
        validations={['required']}
        groupName="signup_checkboxes"
      />
      <Checkbox className="primary" onToggle={onToggleAll} ref={toggleAllRef}>
        모두 동의합니다
      </Checkbox>
      {/**/}
      <div className="checkbox flex flex-row justify-between">
        <FormCheckbox
          name="tc_agreement"
          validators={{ required: true }}
          onToggle={onFormCheckboxToggle}
        >
          서비스 이용 약관 동의(필수)
        </FormCheckbox>
        <a
          style={{ cursor: 'pointer', padding: 0 }}
          className="smaller right-icon"
          target="_blank"
          rel="noopener noreferrer"
          href={CONFIG.terms2URL}
        >
          자세히
          <img src={targetRight} alt="target" />
        </a>
      </div>
      {/**/}
      <div className="checkbox flex flex-row justify-between">
        <FormCheckbox
          name="p_info_consent"
          validators={{ required: true }}
          onToggle={onFormCheckboxToggle}
        >
          개인정보 수집 및 이용 동의 (필수)
        </FormCheckbox>
        <a
          style={{ cursor: 'pointer', padding: 0 }}
          className="smaller right-icon"
          target="_blank"
          rel="noopener noreferrer"
          href={CONFIG.personalInfoTermURL}
        >
          자세히
          <img src={targetRight} alt="target" />
        </a>
      </div>
      {/**/}
      <FormCheckbox
        name="age_restriction"
        validators={{ required: true }}
        onToggle={onFormCheckboxToggle}
      >
        만 14세 이상 가입 동의 (필수)
      </FormCheckbox>
      <div className="checkbox flex flex-row justify-between">
        <FormCheckbox
          name="marketing_privacy"
          onToggle={onFormCheckboxToggle}
        >
          마케팅/프로모션을 위한 개인정보 수집/활용 동의(선택)
        </FormCheckbox>
        <a
          style={{ cursor: 'pointer', padding: 0 }}
          className="smaller right-icon"
          target="_blank"
          rel="noopener noreferrer"
          href={CONFIG.marketingPrivacyTermUrl}
        >
          자세히
          <img src={targetRight} alt="target" />
        </a>
      </div>
      <div className="checkbox flex flex-row justify-between">
        <FormCheckbox
          name="marketing_subscribe"
          onToggle={onFormCheckboxToggle}
        >
          마케팅/프로모션을 위한 광고성 정보 수신동의(선택)
        </FormCheckbox>
        <a
          style={{ cursor: 'pointer', padding: 0 }}
          className="smaller right-icon"
          target="_blank"
          rel="noopener noreferrer"
          href={CONFIG.marketingSubscribeTermUrl}
        >
          자세히
          <img src={targetRight} alt="target" />
        </a>
      </div>
    </div>
  )
}

export default TermsConditions
