import React from 'react'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useForm, useWatch, FormProvider } from 'react-hook-form'
import _ from 'lodash'
import moment from 'moment'

import CommunityLayout from '../../layouts/communityLayout'
import FloatingActionButton from '../../components/floatingActionButton'
import BestGalleries from './components/bestGalleries'
import PostSnippetWithVideo from './components/postSnippetWithVideo2'
import CategoryButton from '../../components/categoryButton'
import MapMultiSelectBox from '../media/components/mapMultiSelectBox'
import MapMultiSelectorMobile from '../media/components/mapMultiSelectorMobile'
import LoadingIndicator from '../../components/LoadingIndicator'
import Footer from '../../components/footer'
import FormMapSelectionBox from '../media/components/formMapSelectionBox'

// import PostSnippetWithoutVideo from "./components/postSnippetWithoutVideo"
import { getGalleries, searchGallery } from '../../services/api'
import AppContext from '../../services/context'
import ChannelService from '../../services/channel'
import { useWindowSize } from '../../services/utils'
import iconPin from '../../assets/icons/map-pin.svg'
import imgSearch from '../../assets/icons/icon-outlined-application-search-large-text-3.svg'
import usePageIndicator from '../../hooks/usePageIndicator'
import useAuthModal from '../../hooks/useAuthModal'
import BottomContactBar from '../../components/BottomContactBar'
// import Notice from '../home/components/notice'

const localStoragekey = 'gallery-detail-count'
const selectedRegionKey = 'selectedRegion'

const getRegionKeys = (region) => {
  if (region.children) {
    const keyArr = Object.keys(region.children).map((key) => getRegionKeys(region.children[key]))
    return `${region.key},${keyArr.join(',')}`
  }
  return region.key
}

const Community = () => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const { open, renderAuthModal } = useAuthModal()
  const [width] = useWindowSize()
  const history = useHistory()
  const [needAuth, setNeedAuth] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [mapModalVisible, setMapModalVisible] = React.useState(null)
  const formMapSelectionBoxRef = React.useRef(null)
  const scrollDivRef = React.useRef(null)
  const contentRef = React.useRef(null)
  // const [selectedRegions, setSelectdRegions] = React.useState([])
  const [searchInput, setSearchInput] = React.useState(appState.galleryOptions.state.search || '')
  const [state, setState] = React.useState({
    ...appState.galleryOptions.state,
    companySector: new Set(Object.keys(appState.galleryOptions.state.companySector)),
  })
  const [scrollDir, setScrollDir] = React.useState('UP')
  const [posts, setPosts] = React.useState([])
  const methods = useForm({
    criteriaMode: 'all',
    mode: 'onSubmit',
    defaultValues: {
      [selectedRegionKey]: [],
    },
  })
  const selectedRegions = useWatch({
    name: selectedRegionKey,
    control: methods.control,
  })
  //
  const checkAuth = React.useCallback(() => {
    if (needAuth) return false
    if (appState.userInfo === null || appState.userInfo._id === null) {
      const galleryDetailCountObj = localStorage.getItem(localStoragekey)
      const today = moment().format('YYYY-MM-DD')
      if (galleryDetailCountObj) {
        const { date, count } = JSON.parse(galleryDetailCountObj)
        if (date === today && count >= 5) {
          setNeedAuth(true)
          return false
        }
      }
    }
    return true
  }, [needAuth, appState.userInfo])
  //

  React.useEffect(() => {
    let lastScrollY = scrollDivRef.current.scrollTop

    const updateScrollDir = () => {
      const scrollY = scrollDivRef.current.scrollTop
      const direction = scrollY > lastScrollY ? 'DOWN' : 'UP'
      if (direction !== scrollDir && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDir(direction)
      }
      lastScrollY = scrollY > 0 ? scrollY : 0
    }

    scrollDivRef.current.addEventListener('scroll', updateScrollDir)
    return () => {
      scrollDivRef.current.removeEventListener('scroll', updateScrollDir)
    }
  }, [scrollDir])
  //
  React.useEffect(() => {
    checkAuth()
  }, [checkAuth])
  //
  React.useEffect(() => {
    if (width < 798) {
      appDispatch({ type: 'updateChannelTalkHide', payload: true })
      ChannelService.hide()
    } else {
      appDispatch({ type: 'updateChannelTalkHide', payload: false })
      ChannelService.show()
    }
    return () => {
      appDispatch({ type: 'updateChannelTalkHide', payload: false })
      ChannelService.show()
    }
  }, [width])
  //
  // const onClickPage = React.useCallback(
  //   async (page) => {
  //     if (state.search !== null) {
  //       setIsLoading(true)
  //       const result = await searchGallery(state.search)
  //       setIsLoading(false)

  //       setPosts(result)
  //     }
  //   },
  //   [],
  // )
  const { pageOption, setPageOption, renderPageIndicator } = usePageIndicator(appState.galleryOptions.pageOption)
  //
  React.useEffect(() => {
    (async () => {
      if (state.search !== null) return
      setIsLoading(true)
      const regionKeys = state.region.map((item) => getRegionKeys(item)).join(',')
      const result = await getGalleries(
        state.companySector.size === 0 ? undefined : Array.from(state.companySector).join(','),
        // getRegionKeys(state.region),
        regionKeys,
        pageOption?.currentPage,
      )
      setIsLoading(false)
      if (!result) return
      setPageOption({
        ...pageOption,
        pageCount: result.pageCount,
      })
      if (result.result) {
        setPosts(result.result)
        if (!!state.scrollValue && scrollDivRef.current) {
          scrollDivRef.current.scrollTop = Number(state.scrollValue)
        } else if (state.companySector.size > 0 || state.region.length > 0 || !!state.search) {
          contentRef.current.scrollIntoView({ behavior: 'smooth' })
        }
      }
    })()
  }, [pageOption?.currentPage, state.companySector, state.region, state.search])
  //
  React.useEffect(() => {
    if (state.search !== null) {
      setIsLoading(true)
      searchGallery(state.search, pageOption?.currentPage).then((result) => {
        setIsLoading(false)
        setState((prev) => ({ ...prev, region: [], companySector: new Set() }))
        if (!result) {
          setPageOption({
            currentPage: 1,
            pageCount: 1,
          })
          setPosts([])
          return
        }
        const { list, pageCount } = result
        setPageOption({
          ...pageOption,
          pageCount,
        })
        setPosts(list)
        if (state.scrollValue !== null && scrollDivRef.current) {
          scrollDivRef.current.scrollTop = Number(state.scrollValue)
        } else {
          contentRef.current.scrollIntoView({ behavior: 'smooth' })
        }
      })
    }
  }, [state.search, pageOption?.currentPage])
  //
  const onClickSector = React.useCallback(
    (code) => {
      setPageOption((prev) => ({ ...prev, currentPage: 1 }))
      if (code === 'all') {
        setState((prev) => ({
          ...prev,
          companySector: new Set(),
          search: null,
          scrollValue: null,
        }))
        setSearchInput('')
        return
      }
      const { companySector } = state
      if (companySector.has(code)) {
        companySector.delete(code)
      } else {
        companySector.add(code)
      }
      setState((prev) => ({
        ...prev,
        companySector: new Set(companySector),
        search: null,
        scrollValue: null,
      }))
      setSearchInput('')
    },
    [state.companySector],
  )
  //
  const onCreateClick = React.useCallback(async () => {
    history.push('/community/create')
  }, [history])
  //
  const getAddressName = React.useCallback(() => {
    if (_.isEmpty(state.region)) return '전국'
    const [region] = state.region
    const tempArr = region.value.split(' ')
    return tempArr[tempArr.length - 1]
  }, [state.region])
  //
  const onClickRegion = React.useCallback(() => {
    setMapModalVisible(true)
  }, [])
  //
  const onSelectAddress = React.useCallback(async (region) => {
    const currItems = await methods.getValues(selectedRegionKey)
    await methods.setValue(selectedRegionKey, [...currItems, region])
  }, [])
  //
  const onDeselectAddress = React.useCallback(async (region) => {
    const currItems = await methods.getValues(selectedRegionKey)
    await methods.setValue(
      selectedRegionKey,
      currItems.filter((item) => item.key !== region.key),
    )
  }, [])
  //
  const onCloseMapModal = React.useCallback(() => {
    setMapModalVisible(false)
  }, [])
  const onClickAddressDone = React.useCallback(() => {
    setPageOption((prev) => ({ ...prev, currentPage: 1 }))
    setState((prev) => ({
      ...prev,
      region: selectedRegions,
      search: null,
      scrollValue: null,
    }))
    setSearchInput('')
    onCloseMapModal(false)
  }, [selectedRegions, onCloseMapModal])
  //
  const onChangeInput = React.useCallback((e) => {
    setSearchInput(e.target.value)
    // setState((prev) => ({ ...prev, search: e.target.value }))
  })
  //
  const onClickSearch = React.useCallback(async () => {
    if (searchInput === '') {
      setState((prev) => ({ ...prev, search: null, scrollValue: null }))
      setPageOption((prev) => ({ ...prev, currentPage: 1 }))
      return
    }
    setState((prev) => ({ ...prev, search: searchInput, scrollValue: null }))
    // setIsLoading(true)
    // setPageOption((prev) => ({ ...prev, currentPage: 1 }))
    // const result = await searchGallery(state.search, 1)
    // setState((prev) => ({ ...prev, region: [], companySector: new Set() }))
    // setIsLoading(false)
    // if (!result || result.length === 0) {
    //   setPageOption({ currentPage: 1, pageCount: 0 })
    //   setPosts([])
    //   return
    // }
    // const { list, pageCount } = result
    // setPageOption((prev) => ({
    //   ...prev,
    //   pageCount,
    // }))
    // setPosts(list)
  }, [searchInput])
  //
  const saveHistory = React.useCallback(() => {
    const tempState = {
      ...state,
      scrollValue: scrollDivRef.current.scrollTop,
    }
    appDispatch({ type: 'updateGalleryOptions', payload: { state: tempState, pageOption } })
  }, [state, pageOption])

  //
  return (
    <>
      <CommunityLayout>
        <div ref={scrollDivRef} className="community bg-white overflow-y-scroll">
          <BestGalleries />
          <Container className="px-0">
            <div ref={contentRef} className="xxs:px-3 px-6 py-6 lg:px-0">
              <div className="hidden lg:flex flex-wrap gap-[16px] mb-[49px]">
                <CategoryButton selected={state.companySector.size === 0} onClick={() => onClickSector('all')}>
                  전체
                </CategoryButton>
                {appState.galleryCategories
                  && appState.galleryCategories.map((item) => (
                    <CategoryButton
                      key={item.code}
                      isTradMarket={item.code === '1'}
                      selected={state.companySector.has(item.code)}
                      onClick={() => onClickSector(item.code)}
                    >
                      {item.title}
                    </CategoryButton>
                  ))}
              </div>
              <div className="flex flex-col items-stretch mb-4 lg:flex-row lg:items-center lg:justify-between">
                <div className="flex justify-between items-center mb-[1rem] lg:mb-0">
                  <select className="text-main outline-0 lg:hidden" onChange={(e) => onClickSector(e.target.value)}>
                    <option value="all">전체</option>
                    {appState.galleryCategories
                      && appState.galleryCategories.map((item) => (
                        <option key={item.code} value={item.code} selected={state.companySector.has(item.code)}>
                          {item.title}
                        </option>
                      ))}
                  </select>
                  <div className="flex items-center">
                    <button
                      className="flex items-center py-2 pl-1.5 pr-5 lg:py-3 lg:pl-3 lg:pr-5 border"
                      onClick={onClickRegion}
                    >
                      <img className="w-[12px] lg:w-[17px]" src={iconPin} alt="pin" />
                      <span className="hidden lg:block font-medium mt-[-3px]">{getAddressName(selectedRegions)}</span>
                      <span className="lg:hidden font-medium mt-[-3px]">{getAddressName(selectedRegions)}</span>
                    </button>
                    <span className="hidden lg:block ml-2 text-sm text-textSecondary">
                      (클릭하시면 원하시는 지역을 선택하실 수 있습니다.)
                    </span>
                  </div>
                </div>
                <div className="textbox-wrapper relative lg:w-[351px]">
                  <input
                    className="w-full rounded-0 lg:text-md"
                    type="text"
                    value={searchInput}
                    placeholder="지금우리동네에 나오는 광고검색"
                    onChange={onChangeInput}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) onClickSearch()
                    }}
                  />
                  <button className="absolute p-0 right-2 top-[11px]" onClick={onClickSearch}>
                    <img src={imgSearch} alt="search" />
                  </button>
                </div>
              </div>
              {posts && (
                <>
                  {posts.length > 0 && (
                    <div className="flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-[12px] gap-y-[24px]">
                      {posts.map((item) => (
                        <PostSnippetWithVideo
                          key={item._id}
                          data={item}
                          authModalOpen={open}
                          needAuth={needAuth}
                          checkAuth={checkAuth}
                          saveHistory={saveHistory}
                        />
                      ))}
                    </div>
                  )}
                  {posts.length < 1 && <Empty />}
                </>
              )}
              {appState.userInfo === null || appState.userInfo._id === null ? (
                <div className="mt-[30px]" />
              ) : (
                <div className="flex justify-center items-center mt-[105px]">{renderPageIndicator()}</div>
              )}
              <FormProvider {...methods}>
                {mapModalVisible ? (
                  width >= 798 ? (
                    <div className="fixed inset-0 flex flex-center z-[20]">
                      <div className="fixed inset-0 bg-[#dcdcdc] opacity-[.37]" />
                      <div className="relative flex flex-col w-[90%] h-[80%] p-8 bg-white max-w-[900px] drop-shadow-md">
                        <h3 className="self-center text-lg mb-4">지역 직접 선택</h3>
                        <div className="flex-grow mb-10 overflow-y-scroll">
                          <MapMultiSelectBox
                            name={selectedRegionKey}
                            onAddressSelected={onSelectAddress}
                            onAddressDeselected={onDeselectAddress}
                          />
                        </div>
                        <div className="map-tags flex-grow my-6 h-[150px] border p-2 overflow-scroll">
                          <FormMapSelectionBox
                            ref={formMapSelectionBoxRef}
                            onAddressClick={onSelectAddress}
                            name={selectedRegionKey}
                          />
                        </div>
                        <div className="flex flex-col-reverse flex-center lg:flex-row gap-4">
                          <button
                            className="flex-1 max-w-[264px] w-full secondary large"
                            onClick={() => onCloseMapModal(false)}
                          >
                            취소
                          </button>
                          <button className="flex-1 max-w-[264px] w-full primary large" onClick={onClickAddressDone}>
                            선택 완료
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <MapMultiSelectorMobile
                      onAddressSelected={onSelectAddress}
                      onAddressDeselected={onDeselectAddress}
                      onPressBack={() => onCloseMapModal(false)}
                      onClickDone={onClickAddressDone}
                      name={selectedRegionKey}
                    />
                  )
                ) : null}
              </FormProvider>
            </div>
            <FloatingActionButton onClick={onCreateClick} />
          </Container>
          <Footer />
        </div>
        {width >= 798 && <BottomContactBar scrollDirection={scrollDir} />}
      </CommunityLayout>
      {isLoading && <LoadingIndicator />}
      {/* <Notice /> */}
      {renderAuthModal('계속 이용하시려면 로그인이 필요합니다.')}
      {/* {renderAuthModal('회원이 아니시면 회원가입이 필요하며\n회원 가입시 5만원 상당의 무료쿠폰을 발급해 드립니다.')} */}
    </>
  )
}

const Empty = () => (
  <div className="empty">
    <p className="fir">등록된 광고영상이 없습니다</p>
    <p className="sec">
      <span>지금 바로 광고영상을 제작하여</span>
      <br />
      <span>첫 게시물의 주인공이 되어보세요!</span>
    </p>
  </div>
)

export default Community
