import React, { useState } from 'react'
import cx from 'classnames'

import targetLeft from '../../../assets/icons/icon-outlined-directional-left-large.svg'
import targetRight from '../../../assets/icons/icon-outlined-directional-right-large.svg'

const MonthPicker = ({ isDesktop, dateRange, setDateRange }) => {
  const today = new Date()
  const [startMonth, setStartMonth] = useState({ month: dateRange[0].getMonth() + 1, year: dateRange[0].getFullYear() })
  const [endMonth, setEndMonth] = useState({ month: dateRange[1].getMonth() + 1, year: dateRange[1].getFullYear() })
  const [hoveredMonth, setHoveredMonth] = useState(null)
  const [year, setYear] = useState(today.getFullYear())
  const [pickerVisible, setPickerVisible] = useState(false)

  const months = Array.from({ length: 12 }, (_, i) => i + 1)

  const convertToValue = (_year, _month) => Number(`${_year}${`${_month}`.padStart(2, '0')}`)

  React.useEffect(() => {
    if (isDesktop) {
      setPickerVisible(true)
    }
  }, [isDesktop])

  const handleClick = (month) => {
    if (startMonth && !endMonth) {
      if (convertToValue(year, month) < convertToValue(startMonth.year, startMonth.month)) {
        setStartMonth({ month, year })
        setEndMonth(null)
      } else {
        setEndMonth({ month, year })
      }
    } else {
      setStartMonth({ month, year })
      setEndMonth(null)
    }
  }

  const handlePrevYear = () => {
    setYear(year - 1)
  }

  const handleNextYear = () => {
    setYear(year + 1)
  }

  const onClickCancel = () => {
    setStartMonth(null)
    setEndMonth(null)
  }

  const onClickDone = () => {
    const startDate = new Date(startMonth.year, startMonth.month - 1, 1)
    const endDate = new Date(endMonth.year, endMonth.month, 0)
    setDateRange([startDate, endDate])
    if (!isDesktop) {
      setPickerVisible(false)
    }
  }

  const onClickDateRange = () => {
    setPickerVisible(!pickerVisible)
  }

  return (
    <div className="relative flex flex-col items-center">
      <div className={cx('hidden items-center w-full mb-4 lg:flex')}>
        {!dateRange[0] ? (
          <span className="text-textTertiary">기간을 설정해주세요.</span>
        ) : (
          <>
            <div
              className={cx(
                'franchise-calendar flex-1 flex items-center',
                'px-3 py-2.5 text-lg font-medium',
                'border rounded',
              )}
            >
              <span>{`${dateRange[0].getFullYear()}.${String(dateRange[0].getMonth() + 1).padStart(2, '0')}`}</span>
            </div>
            <span className="mx-3">~</span>
            <div
              className={cx(
                'franchise-calendar flex-1 flex items-center',
                'px-3 py-2.5 text-lg font-medium',
                'border rounded',
              )}
            >
              {!!dateRange[1] && (
                <span>{`${dateRange[1].getFullYear()}.${String(dateRange[1].getMonth() + 1).padStart(2, '0')}`}</span>
              )}
            </div>
          </>
        )}
      </div>
      <div
        className={cx(
          'franchise-calendar larger flex w-full p-3 mb-4 font-bold',
          'shadow-[0_2px_8px_0px_rgba(0,0,0,0.1)] rounded-[8px]',
          'lg:hidden',
        )}
        onClick={onClickDateRange}
      >
        {!dateRange[0] ? (
          <span className="text-textTertiary">기간을 설정해주세요.</span>
        ) : (
          <>
            <span>{`${dateRange[0].getFullYear()}.${String(dateRange[0].getMonth() + 1).padStart(2, '0')}`}</span>
            <span className="mx-3">~</span>
            {!!dateRange[1] && (
              <span>{`${dateRange[1].getFullYear()}.${String(dateRange[1].getMonth() + 1).padStart(2, '0')}`}</span>
            )}
          </>
        )}
      </div>
      {pickerVisible && (
        <div
          className={cx(
            'bg-white',
            'mlg:absolute mlg:top-[72px] mlg:py-8 mlg:px-4 mlg:w-full mlg:z-20',
            'mlg:shadow-[0_2px_8px_0px_rgba(0,0,0,0.1)] mlg:rounded-[8px]',
          )}
        >
          <div className="flex justify-between items-center mb-2 gap-4">
            <button className="p-0" onClick={handlePrevYear}>
              <img src={targetLeft} alt="왼쪽 화살표" />
            </button>
            <span className="text-[20px] font-bold mb-[3px]">{year}</span>
            <button className="p-0" onClick={handleNextYear}>
              <img src={targetRight} alt="오른쪽 화살표" />
            </button>
          </div>
          <div className="self-center grid grid-cols-3 w-[300px]">
            {months.map((month) => {
              const currentValue = convertToValue(year, month)
              const startValue = !startMonth ? null : convertToValue(startMonth.year, startMonth.month)
              const endValue = !endMonth ? null : convertToValue(endMonth.year, endMonth.month)
              const hoverValue = !hoveredMonth ? null : convertToValue(year, hoveredMonth)

              const isStartOrEndMonth = currentValue === startValue || currentValue === endValue
              const isHoverSelected = startMonth && hoveredMonth && currentValue >= startValue && currentValue <= hoverValue
              const isSelected = startMonth && endMonth && currentValue > startValue && currentValue < endValue

              return (
                <button
                  key={month}
                  onClick={() => handleClick(month)}
                  onMouseEnter={() => {
                    if (startMonth && !endMonth) {
                      setHoveredMonth(month)
                    }
                  }}
                  onMouseLeave={() => setHoveredMonth(null)}
                  className={cx(
                    'relative transition-none px-2 py-0 my-1.5 lg:my-3',
                    !startValue || currentValue < startValue
                      ? ''
                      : {
                        'rounded-l-full bg-brandLight':
                            currentValue === startValue && (hoverValue >= startValue || endValue),
                        'rounded-r-full bg-brandLight': currentValue === endValue || currentValue === hoverValue,
                        'bg-brandLight': isHoverSelected || isSelected,
                        'rounded-none':
                            isHoverSelected
                            && currentValue !== startValue
                            && currentValue !== endValue
                            && currentValue !== hoverValue,
                        'bg-white':
                            startValue
                            && ((!endValue && !hoverValue) || (startValue === currentValue && startValue === hoverValue)),
                      },
                  )}
                >
                  <div
                    className={cx(
                      'relative z-10 w-full p-2 font-bold rounded-full border-1',
                      isStartOrEndMonth
                        ? 'bg-brand text-white border-brand'
                        : isHoverSelected
                          ? 'bg-brandLight text-brand border-brandLight'
                          : isSelected
                            ? 'bg-brandLight text-brand border-brandLight'
                            : 'bg-white text-textTertiary border-[#ccc]',
                    )}
                  >
                    {month}
                    월
                  </div>
                  {startValue === currentValue && (
                    <div className="absolute top-0 left-0 w-[44px] h-full bg-white rounded-full" />
                  )}
                  {endValue === currentValue && (
                    <div className="absolute top-0 right-0 w-[44px] h-full bg-white rounded-full" />
                  )}
                </button>
              )
            })}
          </div>
          <div className="flex w-[300px] mt-4 gap-2 px-4">
            <button className="secondary flex-1 !py-2 !border-brand !text-brand font-bold" onClick={onClickCancel}>
              취소
            </button>
            <button className="brand flex-1 !py-2 font-bold" onClick={onClickDone}>
              설정 완료
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default MonthPicker
