import CryptoJS from 'crypto-js'

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY // Must be 256 bits (32 characters)
const IV_LENGTH = 16 // For AES, this is always 16

export const encrypt = (text) => {
  const iv = CryptoJS.lib.WordArray.random(IV_LENGTH)
  const config = { iv, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC }
  const encrypted = CryptoJS.AES.encrypt(text, ENCRYPTION_KEY, config)
  const transitmessage = `${iv.toString()}:${encrypted.toString()}`
  return transitmessage
}

export const decrypt = (text) => {
  const [iv, encrypted] = text.split(':')
  const parsedIv = CryptoJS.enc.Hex.parse(iv)
  const config = { iv: parsedIv, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC }
  const decrypted = CryptoJS.AES.decrypt(encrypted, ENCRYPTION_KEY, config)
    .toString(CryptoJS.enc.Utf8)
  return decrypted
}
