import { CONFIG } from '../config'

export const getCampaignResult = async (token, dateRange, isMonthly = false) => {
  const REQUEST_URL = new URL('/franchise/dashboard/result', CONFIG.API_ENDPOINT)
  if (dateRange) {
    REQUEST_URL.searchParams.append('startDate', dateRange[0].toISOString())
    REQUEST_URL.searchParams.append('endDate', dateRange[1].toISOString())
  }
  if (isMonthly) {
    REQUEST_URL.searchParams.append('isMonthly', isMonthly)
  }
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  return result.data
}

export const getFranchiseList = async (token) => {
  const REQUEST_URL = new URL('/franchise/list', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  return result.data
}
