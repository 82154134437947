import React from 'react'
import { ko } from 'date-fns/locale'
import { Calendar } from 'react-date-range'
import moment from 'moment'

const useCalendar = () => {
  const [date, setDate] = React.useState(new Date())
  const onChange = (selectedDate) => {
    setDate(selectedDate)
  }
  const renderCalendar = ({ ...props }) => (
    <Calendar
      className="border rounded"
      locale={ko}
      date={date}
      minDate={new Date()}
      maxDate={moment().add(7, 'day').toDate()}
      onChange={onChange}
      color="#ff9000"
      {...props}
    />
  )
  return { date, renderCalendar }
}

export default useCalendar
