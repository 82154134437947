import React from 'react'
import { useHistory } from 'react-router-dom'

import AppContext from '../../../services/context'
import LabeledSection from '../../../components/labeledSection'
import Radio from '../../../components/radio'
import { TwoButtonModal } from '../../../components/modals'
//
const CtaSection = () => {
  const { appState } = React.useContext(AppContext)
  const history = useHistory()
  const [selectedItem, setSelectedItem] = React.useState(0)
  const [modalVisible, setModalVisible] = React.useState(false)

  const onClickButton = () => {
    if (!appState.userInfo?.company?.region) {
      setModalVisible(true)
      return
    }
    history.push(selectedItem === 0 ? '/portal/media' : '/franchise/notice')
  }

  const renderCtaButton = () => (
    <button className="brand large" onClick={onClickButton}>
      청약하기
    </button>
  )

  const onSelect = (e) => {
    setSelectedItem(e.target.value)
  }

  return (
    <div className="franchise-card mlg:!border-0 mlg:!p-0 mlg:!rounded-none">
      <LabeledSection label="신규 광고 청약하기" renderButtons={renderCtaButton}>
        <div className="flex flex-col gap-4 lg:flex-row">
          <Radio
            className="font-[500] brand"
            name="gender"
            value={0}
            checked={Number(selectedItem) === 0}
            onSelect={onSelect}
          >
            매장단독 - 본사지원 없음
          </Radio>
          <Radio
            className="font-[500] brand"
            name="gender"
            value={1}
            checked={Number(selectedItem) === 1}
            onSelect={onSelect}
          >
            SKT본사 일부 or 전체지원 (※T담당 매니저와 사전 협의 후 선택)
          </Radio>
        </div>
      </LabeledSection>
      {modalVisible && (
        <TwoButtonModal
          title="회원 정보가 필요합니다."
          primaryText="회원정보 등록"
          secondaryText="취소"
          buttonVariant="brand"
          onClickPrimary={() => {
            history.push('/portal/my-page/settings')
          }}
          onClickSecondary={() => {
            setModalVisible(false)
          }}
          onClose={() => {
            setModalVisible(false)
          }}
        >
          <p className="text-lg mb-0 whitespace-pre-wrap text-center">
            본사 지원 광고 청약을 위해 법인명,매장명,소속 지역 정보가 필요합니다.
          </p>
        </TwoButtonModal>
      )}
    </div>
  )
}

export default CtaSection
