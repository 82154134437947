import React from 'react'
import { Accordion } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'
import Checkbox from '../../components/checkbox'
import { getDesigns, postAsset } from '../../services/api'
import DesignPaper, { DesignModal } from './components/designPaper'
import AppContext from '../../services/context'
//

const durations = [
  { _id: 0, name: '전체' },
  { _id: 15, name: '15초' },
  { _id: 30, name: '30초' },
]

const initialState = {
  activeCategory: null,
  activeStyle: null,
  activeDuration: durations[0],
}

const Tab2 = ({ cats, styles }) => {
  const [cookies] = useCookies(['jwt_token'])
  const { appState } = React.useContext(AppContext)
  const history = useHistory()
  const [state, setState] = React.useState(initialState)
  const [isModalShown, setIsModalShown] = React.useState(false)
  const [designs, setDesigns] = React.useState([])
  const [activeDesign, setActiveDesign] = React.useState(null)
  const [isOnlyVideo, setIsOnlyVideo] = React.useState(false)
  const [isOnlyImage, setIsOnlyImage] = React.useState(false)
  //
  const onDepth1ButtonClick = React.useCallback(
    async (e, item) => {
      await setState({ ...state, activeCategory: item })
    },
    [state],
  )
  //
  const onStyleButtonClick = React.useCallback(
    async (e, item) => {
      await setState({ ...state, activeStyle: item })
    },
    [state],
  )
  //
  const onDurationButtonClick = React.useCallback(
    async (e, item) => {
      await setState({ ...state, activeDuration: item })
    },
    [state],
  )
  //
  const onClearClick = async () => {
    await setState(initialState)
  }
  //
  const onToggle = React.useCallback(
    (type) => async (e) => {
      const tempFunc = type === 'onlyImage' ? setIsOnlyImage : setIsOnlyVideo
      tempFunc(e.target.checked)
      if (type === 'onlyImage' && isOnlyVideo) {
        setIsOnlyVideo(false)
      } else if (type === 'onlyVideo' && isOnlyImage) {
        setIsOnlyImage(false)
      }
    },
    [isOnlyVideo, isOnlyImage],
  )
  //
  React.useEffect(() => {
    (async () => {
      const data = {}
      if (state?.activeStyle) data.style = state.activeStyle?._id
      if (state?.activeDuration?._id) data.duration = state.activeDuration._id
      const catId = []
      if (state.activeCategory?.id) catId.push(state.activeCategory.id)
      const result = await getDesigns('effect', catId, data)
      await setDesigns(result.result)
    })()
  }, [state])
  //
  const onTemplateSelect = async (e, data) => {
    setActiveDesign(data)
    setIsModalShown(true)
  }
  //
  const onPostTemplate = async (e, data) => {
    await setIsModalShown(false)
    const result = await postAsset(cookies.jwt_token, data._id)
    if (!result) return
    //
    history.push({
      pathname: '/portal/media/build',
      state: {
        userId: appState.userInfo.id,
        assetId: result._id,
      },
    })
  }
  //
  let shownList = designs
  if (isOnlyImage || isOnlyVideo) {
    /**
     * 'onlyImage' state means that 'needVideo' property is 0.
     * So we need to check the value of 'needVideo' property when 'onlyImage' is true.
     */
    const tempProp = isOnlyImage ? 'needVideo' : 'needImage'
    shownList = designs.filter((item) => item[tempProp] === 0)
  }

  return (
    <div className="tab3">
      <div className="tab3-header">
        <h3>광고할 상위 업종을 선택해주세요.</h3>

        <div className="button-group" style={{ marginBottom: 16 }}>
          {cats && cats.map((item, index) => (
            <div key={index}>
              <button
                className={
                  `light${
                    state.activeCategory?.id === item.id ? ' active' : ''}`
                }
                onClick={(e) => onDepth1ButtonClick(e, item)}
              >
                {item.name}
              </button>
              <span />
            </div>
          ))}
        </div>
        <div className="flex flex-row ch-section">
          <Checkbox onToggle={onToggle('onlyImage')} checked={isOnlyImage}>
            사진만 넣을래요
          </Checkbox>
          <Checkbox onToggle={onToggle('onlyVideo')} checked={isOnlyVideo}>
            영상만 넣을래요
          </Checkbox>
        </div>
        {/**/}
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey={0}>
            <Accordion.Header>필터</Accordion.Header>
            <Accordion.Body>
              <div className="styles flex flex-col">
                {/**/}
                <p>분위기</p>
                <div className="button-group" style={{ border: 0 }}>
                  {styles && styles.map((item, index) => (
                    <div key={index}>
                      <button
                        className={
                          `light${
                            state.activeStyle?._id === item._id ? ' active' : ''}`
                        }
                        onClick={(e) => onStyleButtonClick(e, item)}
                      >
                        {item.name}
                      </button>
                      <span />
                    </div>
                  ))}
                </div>
                {/**/}
                <div className="divider-tertiary" />
                <p>템플릿</p>
                <div className="button-group" style={{ border: 0 }}>
                  {durations.map((item, index) => (
                    <div key={index}>
                      <button
                        className={
                          `light${
                            state.activeDuration?._id === item._id
                              ? ' active'
                              : ''}`
                        }
                        onClick={(e) => onDurationButtonClick(e, item)}
                      >
                        {item.name}
                      </button>
                      <span />
                    </div>
                  ))}
                </div>
                {/**/}
                <div className="flex w-full flex-row-reverse">
                  <button
                    className="light small"
                    style={{ marginBottom: 0 }}
                    onClick={onClearClick}
                  >
                    초기화
                  </button>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 p-4">
        {shownList.map((item, index) => (
          <DesignPaper data={item} key={index} onSelect={onTemplateSelect} />
        ))}
      </div>
      {shownList.length === 0 && <Empty />}
      <DesignModal
        isShown={isModalShown}
        data={activeDesign}
        onClose={() => setIsModalShown(false)}
        onPostTemplate={onPostTemplate}
      />
    </div>
  )
}

const Empty = () => (
  <div className="empty">
    <p className="fir">해당되는 템플릿이 없습니다</p>
    <p className="sec">
      <span>선택하신 내용에 해당되는 템플릿이 없습니다.</span>
      <br />
      <span>다른 항목을 선택해 다시 시도해주세요.</span>
    </p>
  </div>
)

export default Tab2
