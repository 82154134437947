import React from 'react'

import StatusDot from '../../../components/statusDot'

const NoticeCard = ({ notice, onClick, onClickCount }) => {
  const isNormal = notice.type === 'NORMAL'
  return (
    <div className="franchise-card flex flex-col gap-2 !p-3 text-sm xs:text-xs" onClick={!onClick ? null : onClick}>
      <div className="flex items-center gap-1">
        {isNormal ? null : (
          <>
            <StatusDot status={notice.statusCode} />
            {notice.status}
          </>
        )}
      </div>
      <span className="font-bold">{notice.title}</span>
      {isNormal ? null : (
        <div>
          <span className="mr-4">
            <span className="font-medium">기간</span>
            {` ${notice.period}`}
          </span>
          <span>
            <span className="font-medium">참여 매장 수</span>
            {!onClickCount ? (
              <span>{` ${notice.participationCount}`}</span>
            ) : (
              <div
                className="cursor-pointer underline text-textTertiary"
                onClick={() => {
                  onClickCount(notice)
                }}
              >
                {` ${notice.participationCount || 0}`}
              </div>
            )}
          </span>
        </div>
      )}
      <span className="text-textTertiary">{notice.createdAt}</span>
    </div>
  )
}

export default NoticeCard
