// 참고 https://javascript.plainenglish.io/building-an-auto-logout-session-timeout-with-react-using-hooks-e7804ef973ec

import React from 'react'
import moment from 'moment'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'

import AppContext from '../services/context'

const events = ['click', 'load', 'scroll']

const useSessionTimeout = () => {
  const { appDispatch } = React.useContext(AppContext)
  const history = useHistory()
  const [cookies, , removeCookie] = useCookies(['jwt_token'])

  const isAuthenticated = 'jwt_token' in cookies
  // const [second, setSecond] = React.useState(0)

  let timeStamp
  const warningInactiveInterval = React.useRef()
  const startTimerInterval = React.useRef()

  // warning timer
  const warningInactive = React.useCallback(
    (timeString) => {
      clearTimeout(startTimerInterval.current)
      warningInactiveInterval.current = setInterval(() => {
        if (!isAuthenticated) {
          clearInterval(warningInactiveInterval.current)
        }
        const maxTime = 3 // Maximum ideal time given before logout
        const diff = moment.duration(moment().diff(moment(timeString)))
        const minPast = diff.minutes()
        const leftSecond = 60 - diff.seconds()

        if ((maxTime - minPast) === 2 && leftSecond === 30) {
          window.alert('세션이 곧 만료됩니다')
        }
        // setSecond(leftSecond)

        if (minPast === maxTime) {
          clearInterval(warningInactiveInterval.current)
          sessionStorage.removeItem('lastTimeStamp')
          removeCookie('jwt_token', {
            path: '/',
          })
          appDispatch({
            type: 'updateUserInfo',
            payload: {
              _id: null,
            },
          })
          history.push('/')
        }
      }, 1000)
    },
    [isAuthenticated],
  )

  // start inactive check
  const timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      const storedTimeStamp = sessionStorage.getItem('lastTimeStamp')
      // if (storedTimeStamp) warningInactive(storedTimeStamp)
      if (storedTimeStamp) warningInactive(storedTimeStamp)
    }, (process.env.REACT_APP_ENVIRONMENT === 'production' ? 58 : 480) * 60 * 1000)
  }

  // reset interval timer
  const resetTimer = React.useCallback(() => {
    clearTimeout(startTimerInterval.current)
    clearInterval(warningInactiveInterval.current)
    if (isAuthenticated) {
      timeStamp = moment()
      sessionStorage.setItem('lastTimeStamp', timeStamp)
    }
    timeChecker()
  }, [isAuthenticated])

  // Life cycle hook
  React.useEffect(() => {
    events.forEach((event) => {
      window.removeEventListener(event, resetTimer)
      window.addEventListener(event, resetTimer)
    })
    timeChecker()

    return () => {
      clearTimeout(startTimerInterval.current)
    }
  }, [resetTimer, events, timeChecker])
}

export default useSessionTimeout
