import React from 'react'
import { Container } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import NavigationLayout from './navigationLayout'
import Menu from '../components/menu'
import { myPageMenu } from '../services/menus'
import AppContext from '../services/context'

const MyPageLayout = ({ children, navigationTitle = '마이페이지' }) => {
  const { appState } = React.useContext(AppContext)
  const history = useHistory()
  const location = useLocation()
  const activeLink = location.pathname
  //
  const onPressBackButton = () => {
    if (location.pathname === '/portal' || location.pathname === '/portal/my-page') {
      history.push('/')
      return
    }
    history.push('/portal/my-page')
  }
  //
  const getMenus = () => {
    if (Number(appState.userInfo?.company?.companyType) === 2 && appState.userInfo?.companyRank === 8) {
      return myPageMenu
    }
    return myPageMenu.slice(0, 9).concat(myPageMenu.slice(9 + 1))
  }
  //
  return (
    <NavigationLayout navigationTitle={navigationTitle} onPressBackButton={onPressBackButton}>
      <div className="page-headline my-page-headline">
        <Container>
          <h1>
            <span>반갑습니다,</span>
            <br />
            <span>
              {appState.userInfo?.userName || '익명'}
              님
            </span>
          </h1>
        </Container>
      </div>
      {/**/}
      <div className="my-page page-content">
        <Container>
          <div className="flex xl:grid xl:grid-cols-6">
            <div className="hidden lg:block side-menu">
              <Menu items={getMenus()} activeLink={activeLink} />
            </div>
            <div className="content xl:col-span-5">{children}</div>
          </div>
        </Container>
      </div>
    </NavigationLayout>
  )
}

export default MyPageLayout
