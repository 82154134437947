import React from 'react'
import { useFormContext } from 'react-hook-form'

const CurrencyTextboxComponent = ({ ...props }, forwardedRef) => {
  const inputClasses = ['flex-grow', 'xlarger']
  if (props.className) inputClasses.push(props.className)
  //
  return (
    <div className="currency-textbox flex flex-row">
      <input
        ref={forwardedRef}
        {...props}
        type="number"
        step="1000"
        min="0"
        className={inputClasses.join(' ')}
      />
    </div>
  )
}

const CurrencyTextbox = React.forwardRef(CurrencyTextboxComponent)

export const FormCurrencyTextbox = ({ name, validators, ...props }) => {
  const { register } = useFormContext()
  const { ...inputProps } = register(name, validators)
  //
  return <CurrencyTextbox name={name} {...props} {...inputProps} />
}

export default CurrencyTextbox
