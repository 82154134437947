import React from 'react'
import { Carousel } from 'react-bootstrap'

import illust from '../assets/imgs/consult-illust-m-1.png'
import illust_2x from '../assets/imgs/consult-illust-m-1@2x.png'
import illust_3x from '../assets/imgs/consult-illust-m-1@3x.png'
import illust2 from '../assets/imgs/consult-illust-m-2.png'
import illust2_2x from '../assets/imgs/consult-illust-m-2@2x.png'
import illust2_3x from '../assets/imgs/consult-illust-m-2@3x.png'
import illust3 from '../assets/imgs/consult-illust-m-3.png'
import illust3_2x from '../assets/imgs/consult-illust-m-3@2x.png'
import illust3_3x from '../assets/imgs/consult-illust-m-3@3x.png'
import leftIcon from '../assets/icons/icon-outlined-directional-left-circle.svg'
import rightIcon from '../assets/icons/icon-outlined-directional-right-circle.svg'
//
const BannerCarousel = () => {
  const [activeIndex, setActiveIndex] = React.useState(0)
  //
  const onChange = async (e, state) => {
    let index = null
    if (state) index = Math.abs((activeIndex + 1) % 3)
    else index = Math.abs((3 + activeIndex - 1) % 3)

    await setActiveIndex(index)
  }
  //
  const handleSelect = async (selectedIndex) => {
    await setActiveIndex(Math.abs(selectedIndex % 3))
  }
  //
  return (
    <div className="relative bg-[#ffb74a] md:h-[250px]">
      <Carousel
        className="flex-grow"
        controls={false}
        indicators={false}
        activeIndex={activeIndex}
        onSelect={handleSelect}
        pause="hover"
        interval={null}
      >
        <Carousel.Item>
          <div className="flex items-center justify-between pt-4 pl-6 pr-6 pb-[75px]">
            <div className="flex flex-col">
              <span className="font-bold md:text-lg">1. 간단한 신청</span>
              <span className="text-[0.5rem] mt-2 md:text-sm">
                연락처와 상담을 희망하는 날짜를 알려주세요.
                <br />
                B tv 우리동네광고의 전문 컨설턴트가
                <br />
                연락드리겠습니다.
              </span>
            </div>
            <img src={illust} srcSet={`${illust_2x} 2x, ${illust_3x} 3x`} alt="illust" className="mb-[-24px]" />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="flex items-center justify-between pt-4 pl-6 pr-6 pb-[75px]">
            <div className="flex flex-col">
              <span className="font-bold md:text-lg">2. 전문 컨설턴트 배정</span>
              <span className="text-[0.5rem] mt-2 md:text-sm">
                고객님께서 희망하시는 광고에 대해 파악하고
                <br />
                쉽고 빠르게 광고를 제작해
                <br />
                청약하실 수 있도록 도와드리겠습니다.
              </span>
            </div>
            <img src={illust2} srcSet={`${illust2_2x} 2x, ${illust2_3x} 3x`} alt="illust2" className="mb-[-80px]" />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="flex items-center justify-between pt-4 pl-6 pr-8 pb-[75px]">
            <div className="flex flex-col">
              <span className="font-bold md:text-lg">3. TV광고 청약</span>
              <span className="text-[0.5rem] mt-2 md:text-sm">
                여러분의 비즈니스를 TV에서 광고해보세요.
                <br />
                B tv 우리동네광고가 여러분의 비즈니스
                <br />
                성장 파트너가 되어드리겠습니다.
              </span>
            </div>
            <img src={illust3} srcSet={`${illust3_2x} 2x, ${illust3_3x} 3x`} alt="illust3" className="mb-[-75px]" />
          </div>
        </Carousel.Item>
      </Carousel>
      <div className="absolute flex bottom-4 left-6 justify-center">
        <button
          onClick={(e) => onChange(e, false)}
          style={{ padding: 0, paddingRight: 10, margin: 0 }}
        >
          <img src={leftIcon} alt="left" className="m-0 w-[42px]" />
        </button>
        <button
          onClick={(e) => onChange(e, true)}
          style={{ padding: 0, paddingLeft: 10, margin: 0 }}
        >
          <img src={rightIcon} alt="right" className="m-0 w-[42px]" />
        </button>
      </div>
    </div>
  )
}

export default BannerCarousel
