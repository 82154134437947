import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
//
import { useSnackbar } from 'notistack'
//
import { FormActionTextboxGroup } from '../../../components/formActionTextbox'
//
import { checkBusinessIdValidity } from '../../../services/api'
import {
  AddressSection, CategorySection2, CompanyNameSection, TelSection,
} from '../promotion'
import { ContentModal } from '../../../components/modals'
import SectionWithTitle from './sectionWithTitle'
//
const GiftInfoModal = ({
  isModalShown,
  onClose,
  onClickRegister,
  business_id,
  isBusiness,
  companyName,
  companySector,
  storeAddress,
  storeAddressDetail,
  storeAddressKey,
  storeLat,
  storeLon,
  storePostalCode,
  storeTel,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  //
  const methods = useForm({
    criteriaMode: 'onSubmit',
    mode: 'onSubmit',
    defaultValues: {
      business_id,
      x_business_id_validated: !!business_id,
      companyName,
      companySector,
      storeAddress,
      storeAddressDetail,
      storeAddressKey,
      storeLat,
      storeLon,
      storePostalCode,
      storeTel,
    },
  })
  //
  const onSubmit = async (data) => {
    onClickRegister(data)
  }
  //
  const onBusinessIdAction = async (e, value) => {
    const formattedValue = value.replace(/-/gi, '')
    const predicate = await checkBusinessIdValidity(formattedValue, await methods.getValues('user_type'))
    if (value !== formattedValue) {
      methods.setValue('business_id', formattedValue)
    }
    if (predicate) {
      enqueueSnackbar('사업자등록번호가 확인되었습니다', { variant: 'success' })
    } else {
      enqueueSnackbar('사업자등록번호가 유효하지 않습니다', { variant: 'error' })
    }
    return predicate
  }
  //
  // const onBusinessDocumentUpload = async (file) => {
  //   const result = await uploadBusinessDocument(file)
  //   return result
  // }
  //
  return (
    <ContentModal
      title={'우리가게 위치 등록\n서비스 필수 정보'}
      titleClassName="mlg:whitespace-pre-wrap"
      isShown={isModalShown}
      onClose={onClose}
      buttonVisible={false}
      style={{}}
      bodyClassName="mlg:p-4 px-[48px] pt-[48px] pb-[36px]"
    >
      <div className="max-w-[100%] flex flex-col justify-items-stretch justify-center">
        <p>
          <span>우리가게 위치 등록 서비스 신청을 위해 필수로 입력해야 하는 항목입니다.</span>
        </p>
        {/* <div className="divider-primary" /> */}
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit, (e) => console.log(e))}>
            {/**/}
            <CompanyNameSection />
            <SectionWithTitle title="사업자등록번호" required>
              <FormActionTextboxGroup
                name="business_id"
                placeholder="사업자등록번호를 입력해주세요"
                actionName="validated"
                maxLength={12}
                className="mlg:max-w-[calc(100%-124px)] larger"
                noLabel
                disabled={!isBusiness}
                onClick={onBusinessIdAction}
                validators={{
                  validate: {
                    required_business_name: (v) => !(v === '' && methods.getValues('business_name') !== ''),
                  },
                  required: true,
                }}
                actionValidators={{
                  validation_required: (v) => !(methods.getValues('business_id') !== '' && v !== true),
                }}
                validations={['required_business_name', 'validation_required', 'required']}
              >
                번호확인
              </FormActionTextboxGroup>
            </SectionWithTitle>
            {/* <FormUploadBoxGroup
                  label="사업자등록증 (광고 청약 시 사업자 등록증 등록이 필요합니다)"
                  labelClass="text-xl"
                  name="business_document"
                  actionName="url"
                  onUpload={onBusinessDocumentUpload}
                  validators={{ required: true }}
                  validations={['required']}
                >
                  첨부하기
                </FormUploadBoxGroup> */}
            {/**/}
            <CategorySection2 name="companySector" />
            <AddressSection />
            <TelSection required />
            <div className="divider-primary" style={{ marginTop: 24 }} />
            {/**/}
            <button className="w-full primary large" type="submit">
              완료
            </button>
          </form>
        </FormProvider>
        {/**/}
      </div>
    </ContentModal>
  )
}

export default GiftInfoModal
