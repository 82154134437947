import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useWindowSize } from '../services/utils'

import imgLeftArrow from '../assets/icons/icon-outlined-directional-pagination-left.svg'
import imgDisabledLeftArrow from '../assets/icons/icon-outlined-directional-pagination-left-disable.svg'
import imgRightArrow from '../assets/icons/icon-outlined-directional-pagination-right.svg'
import imgDisabledRightArrow from '../assets/icons/icon-outlined-directional-pagination-right-disable.svg'
import { ReactComponent as ImgLeftDoubleArrow } from '../assets/icons/icon-double-arrow-left.svg'
import { ReactComponent as ImgRightDoubleArrow } from '../assets/icons/icon-double-arrow-right.svg'
import { ReactComponent as ImgLeftArrowEnd } from '../assets/icons/icon-arrow-end-left.svg'
import { ReactComponent as ImgRightArrowEnd } from '../assets/icons/icon-arrow-end-right.svg'

const PageBox = ({ className, onClick, children }) => (
  <div
    className={`w-[25px] h-[25px] flex items-center justify-center lg:w-[32px] lg:h-[32px] cursor-pointer ${className}`}
    onClick={onClick}
  >
    {children}
  </div>
)

const usePageIndicator = (defaultPageOption, onClickPage) => {
  const history = useHistory()
  const location = useLocation()
  const [width] = useWindowSize()
  const [pageOption, setPageOption] = React.useState(defaultPageOption)

  const renderPageIndicator = React.useCallback(() => {
    if (!pageOption || !pageOption?.pageCount) return null
    const { currentPage, pageCount } = pageOption
    const isFirst = currentPage === 1
    const isLast = currentPage === pageCount

    const availNum = Math.min(Math.min(Math.floor((Math.min(1134, Math.max(width, 250)) - 250) / 40), pageCount), 10)
    const numbers = Array.from(Array(Math.min(availNum, pageCount)).keys()).map((item, i) => {
      const pageNum = i + Math.max(1, currentPage - availNum + 1)
      const active = currentPage === pageNum
      const className = active ? 'text-main' : ''
      return (
        <PageBox
          key={i}
          className={className}
          onClick={() => {
            setPageOption({ pageCount, currentPage: pageNum })
          }}
        >
          {pageNum}
        </PageBox>
      )
    })

    // const numbers = Array.from(Array(Math.min(availNum, pageCount)).keys()).map((item, i) => {
    //   const pageNum = i + Math.max(1, currentPage - availNum + 1)
    //   const active = currentPage === pageNum
    //   const className = active ? 'text-main' : ''
    //   return (
    //     <PageBox
    //       key={i}
    //       className={className}
    //       onClick={() => {
    //         setPageOption({ pageCount, currentPage: pageNum })
    //       }}
    //     >
    //       {pageNum}
    //     </PageBox>
    //   )
    // })
    // console.log(numbers)

    return (
      <>
        <div className="flex gap-1">
          <PageBox
            className="border"
            onClick={() => {
              if (isFirst) return
              setPageOption((prev) => ({ ...prev, currentPage: 1 }))
              if (onClickPage) onClickPage(1)
            }}
          >
            <ImgLeftArrowEnd className="w-[8px]" fill={isFirst ? '#ccc' : '#666'} />
          </PageBox>
          <PageBox
            className="border"
            onClick={() => {
              if (isFirst) return
              setPageOption((prev) => ({
                ...prev,
                currentPage: Math.max(prev.currentPage - 10, 1),
              }))
              if (onClickPage) onClickPage(Math.max(currentPage - 10, 1))
            }}
          >
            <ImgLeftDoubleArrow className="w-[10px]" fill={isFirst ? '#ccc' : '#777'} />
          </PageBox>
          <PageBox
            className="border"
            onClick={() => {
              if (isFirst) return
              setPageOption((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }))
              if (onClickPage) onClickPage(currentPage - 1)
            }}
          >
            <img className="w-[13px]" src={isFirst ? imgDisabledLeftArrow : imgLeftArrow} alt="left" />
          </PageBox>
        </div>
        <div className="flex text-md mx-4 gap-2">
          {currentPage > availNum && <span>...</span>}
          {numbers}
          {!isLast && pageCount > availNum && <span>...</span>}
        </div>
        <div className="flex gap-1">
          <PageBox
            className="border"
            onClick={() => {
              if (isLast) return
              setPageOption((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }))
              if (onClickPage) onClickPage(currentPage + 1)
            }}
          >
            <img className="w-[12px]" src={isLast ? imgDisabledRightArrow : imgRightArrow} alt="right" />
          </PageBox>
          <PageBox
            className="border"
            onClick={() => {
              if (isLast) return
              setPageOption((prev) => ({
                ...prev,
                currentPage: Math.min(prev.currentPage + 10, pageCount),
              }))
              if (onClickPage) onClickPage(Math.min(currentPage + 10, pageCount))
            }}
          >
            <ImgRightDoubleArrow className="w-[10px]" fill={isLast ? '#ccc' : '#777'} />
          </PageBox>
          <PageBox
            className="border"
            onClick={() => {
              if (isLast) return
              setPageOption((prev) => ({ ...prev, currentPage: pageCount }))
              if (onClickPage) onClickPage(pageCount)
            }}
          >
            <ImgRightArrowEnd className="w-[8px]" fill={isLast ? '#ccc' : '#666'} />
          </PageBox>
        </div>
      </>
    )
  }, [width, pageOption, history, location])

  return {
    pageOption,
    setPageOption,
    renderPageIndicator,
  }
}

export default usePageIndicator
