import React from 'react'
//
let timeoutHandler = null
//
const Timeline = ({ data, onClick }) => {
  const [state, setState] = React.useState(0)
  //
  const onButtonClick = React.useCallback(
    async (e, index) => {
      window.clearTimeout(timeoutHandler)
      await setState(index)
      await onClick(e, index)
      timeoutHandler = setTimeout(() => {
        onButtonClick(e, (index + 1) % data.length)
      }, 6000)
    },
    [data.length, onClick],
  )
  //
  React.useEffect(() => {
    window.clearTimeout(timeoutHandler)
    timeoutHandler = setTimeout(async () => {
      await onButtonClick(null, 1 % data.length)
    }, 6000)
  }, [data.length, onButtonClick])
  //
  return (
    <div className="timeline flex flex-row">
      <ul className=" flex flex-col">
        {data
          && data.map((item, index) => (
            <li key={index} className={state === index ? 'selected' : ''}>
              <button
                className="content"
                onClick={(e) => onButtonClick(e, index)}
              >
                <span className="line" />
                <h4>{item.title}</h4>
                <p className="whitespace-pre-wrap break-words" style={{ wordBreak: 'keep-all' }}>
                  {item.content}
                </p>
                {item.point && (
                  <p className="note-2" style={{ marginTop: -15, color: 'red' }}>
                    {item.point}
                  </p>
                )}
              </button>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default Timeline
