import React from 'react'
import { Container } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import qs from 'query-string'

import BasicLayout from '../../layouts/basic'
import AppContext from '../../services/context'
import { createCampaign } from '../../services/api'
import logoSkBroadband from '../../assets/imgs/logo-sk-broadband.png'
import thedreamImage1 from '../../assets/imgs/thedream_img1.png'
import thedreamImage2 from '../../assets/imgs/thedream_img2.png'
import thedreamImage1_m from '../../assets/imgs/thedream_img1_m.png'
import thedreamImage2_m from '../../assets/imgs/thedream_img2_m.png'

const TheDreamPage = () => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const history = useHistory()
  const location = useLocation()
  const [cookies] = useCookies(['jwt_token'])
  const methods = useForm()
  //
  const onChange = (e) => {
    // 10자리 숫자만 입력 가능
    if (e.target.value.length > 10) {
      e.target.value = e.target.value.slice(0, 10)
    }
    e.target.value = e.target.value.replace(/[^0-9]/g, '')
  }

  //
  const onSubmit = async (data) => {
    if (data.service_number === '') {
      alert('서비스 번호를 입력해 주세요.')
      return
    }
    if (appState.userInfo === null || appState.userInfo._id === null) {
      alert('로그인이 필요합니다.')
      const previous = encodeURIComponent(location.pathname)
      const strQuery = qs.stringify(previous)
      history.push(`/sign-in?${strQuery}`)
      return
    }
    try {
      const result = await createCampaign(cookies.jwt_token, null, null, {
        promotion: {
          id: 'thedream_2406',
          data,
        },
        amount: 200000,
        fixedBudget: 200000,
      })
      if (result) {
        if (result.code === 'ALREADY_EXISTS_CAMPAIGN') {
          if (result.data.status < 2) {
            alert('이미 참여 중이신 서비스 번호입니다.\n작성 중인 캠페인으로 이동합니다.')
            await appDispatch({ type: 'updateAdCampaign', payload: result.data })
            history.push('/portal/media/create/1')
          } else {
            alert('이미 참여하신 서비스 번호입니다.')
          }
          return
        }
        await appDispatch({ type: 'updateAdCampaign', payload: result })
        history.push('/portal/media/create/1')
      } else {
        alert('신청에 실패했습니다.')
      }
    } catch (e) {
      alert('신청에 실패했습니다.')
    }
  }

  return (
    <BasicLayout className="bg-white" noFooter>
      <div className="bg-[#F9F7F1]">
        <div className="w-full mx-auto max-w-[393px] md:max-w-[1024px] flex flex-col items-center mmd:pt-[26px] pt-[100px]">
          <div className="px-3 py-1 md:px-[20px] md:py-[15px] font-bold text-[12px] md:text-[20px] bg-brand text-white rounded-full">
            더드림 성공팩 출시기념 홍보지원 이벤트
          </div>
          <div className="hidden flex-col items-center md:flex">
            <img src={thedreamImage1} alt="The Dream" className="w-[453px] h-[134px] mt-[28px] mb-[64px]" />
            <img src={thedreamImage2} alt="The Dream" className="w-[705px] h-[365px]" />
          </div>
          <div className="flex flex-col items-center md:hidden">
            <img src={thedreamImage1_m} alt="The Dream" className="w-[261px] h-[77px] mt-[14px] mb-[4px]" />
            <img src={thedreamImage2_m} alt="The Dream" className="w-full max-w-[393px] h-[56vw] max-h-[222px]" />
          </div>
        </div>
      </div>
      <div className="pt-[43px] mmd:pb-[34px] pb-[98px] bg-brand text-white text-[12px] md:text-[20px]">
        <Container className="flex flex-col items-center">
          <div className="flex flex-col gap-[12px]">
            <div className="flex items-center">
              <div className="shrink-0 mmd:text-[12px] w-[71px] md:w-[120px] mmd:px-[12px] mmd:py-[4px] px-[20px] py-[8px] mr-[16px] font-bold bg-[#000] rounded-full">
                지원 내용
              </div>
              <span className="font-bold">더드림 성공팩 출시기념 20만원 상당 무료광고 송출지원</span>
            </div>
            <div className="flex items-center">
              <div className="shrink-0 mmd:text-[12px] w-[71px] md:w-[120px] mmd:px-[12px] mmd:py-[4px] px-[20px] py-[8px] mr-[16px] font-bold bg-[#000] rounded-full">
                대상 고객
              </div>
              <span className="font-bold">고객센터 &gt; 공지사항 이벤트 글 참고</span>
            </div>
            <div className="flex items-center">
              <div className="shrink-0 mmd:text-[12px] w-[71px] md:w-[120px] mmd:px-[12px] mmd:py-[4px] px-[20px] py-[8px] mr-[16px] font-bold bg-[#000] rounded-full">
                기&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;간
              </div>
              <span className="font-bold">24년 7/1 ~ 8/31 (*예산 소진시 조기 종료 가능)</span>
            </div>
          </div>
          <div className="relative flex flex-col items-center w-full mmd:max-w-[393px] md:w-[570px] lg:w-[705px] pt-[50px] pb-[30px] mt-[90px] mmd:mt-[48px] border-y border-white mmd:px-4">
            <p className="text-white text-center font-bold mb-0 text-[12px] md:text-[20px]">
              더드림 성공팩 가입여부 확인을 위하여,
              <br />
              가입한
              {' '}
              <span className="text-main">{'\'초고속 인터넷 서비스 번호\''}</span>
              {' '}
              를 입력해 주세요.
            </p>
            <FormProvider {...methods}>
              <form className="w-full mmd:max-w-[393px] md:w-[500px] lg:w-[600px] mmd:my-[12px] my-[36px]" onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="w-full flex gap-2">
                  <input {...methods.register('service_number')} className="flex-1 rounded px-[15px] py-[10px]" onChange={onChange} />
                  <button
                    className="w-[80px] md:w-[159px] bg-[#000] font-bold rounded mmd:!px-[15px] mmd:!py-[5px] mmd:text-[12px]"
                    style={{ marginBottom: 0 }}
                  >
                    청약하기
                  </button>
                </div>
              </form>
            </FormProvider>
            <p className="text-white text-center font-bold mb-0 text-[12px] md:text-[20px]">
              *혹, 서비스 번호를 모르시는 경우 고객센터 (106)로 문의 바랍니다.
            </p>
            <div className="absolute top-[-19px] md:top-[-25px] lg:top-[-40px] bg-brand font-bold text-[24px] md:text-[30px] lg:text-[50px] px-[20px]">더드림 성공팩 프로모션</div>
          </div>
        </Container>
      </div>
      <footer className="bg-[#2c2c2c] px-[30px] py-[35px] xl:hidden border-brand">
        <p className="mb-8 text-[13px] text-[#8b8b8b] text-start">
          <strong>고객센터</strong>
          <br />
          대표 : 106(무료) ARS 안내
          <br />
          케이블방송/케이블인터넷 : 146600(무료)
          <br />
          대표이사 사장 박진효
          <br />
          사업자등록번호 214-86-18758 사업자정보확인
          <br />
          통신판매신고번호 서울 중구 제 2008-1608호
          <br />
          서울시 중구 퇴계로 24 SK남산그린빌딩
        </p>
        <img
          src={logoSkBroadband}
          alt="logo"
          className="d-inline-block align-top w-[136px] h-[48px]"
        />
      </footer>
      <footer className="hidden items-end justify-center bg-[#2c2c2c] px-[30px] py-[68px] xl:flex border-brand">
        <p className="mr-[80px] text-white text-start text-[15px] mb-0">
          고객센터 대표 : 106(무료) ARS 안내 케이블방송/케이블인터넷 : 146600(무료)
          <br />
          대표이사 사장 박진효 사업자등록번호 214-86-18758 사업자정보확인 통신판매신고번호 서울 중구 제 2008-1608호
          <br />
          서울시 중구 퇴계로 24 SK남산그린빌딩

        </p>
        <img
          src={logoSkBroadband}
          alt="logo"
          className="d-inline-block align-top w-[179px] h-[63px]"
        />
      </footer>
    </BasicLayout>
  )
}

export default TheDreamPage
