import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'

import Footer from '../../components/footer'

const InicisFail = () => {
  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  //
  return (
    <>
      <Navbar>
        <div className="container">
          <Nav className="me-auto">
            <Nav.Link
              className="go-back"
              onClick={() => history.replace('/portal/media')}
            >
              나의광고 로 돌아가기
            </Nav.Link>
          </Nav>
        </div>
      </Navbar>
      <main className="flex flex-grow flex-col">
        <Container className="auth-paper" fluid="sm">
          <div className="flex flex-col justify-items-stretch">
            <h1 className="text-center mb-4">결제 실패</h1>
            {query.get('P_RMESG1') && <p className="text-center">{query.get('P_RMESG1')}</p>}
            {query.get('resultMsg') && <p className="text-center">{query.get('resultMsg')}</p>}
            <button
              type="button"
              className="primary larger "
              onClick={
                () => history.replace('/portal/media')
              }
            >
              뒤로가기
            </button>
          </div>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default InicisFail
