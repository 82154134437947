import React from 'react'

import { ContentModal } from '../../../components/modals'
import imgClose from '../../../assets/icons/icon-outlined-suggested-error-large.svg'

const useSelectCompanyModal = (myCompany, selectList, callback) => {
  const [isModalShown, setIsModalShown] = React.useState(false)
  const [selectedCompany, setSelectedCompany] = React.useState(myCompany.value)

  const onClickCreateCampaign = () => {
    setIsModalShown(true)
  }

  const onClickClose = () => {
    setIsModalShown(false)
  }

  const onClickCompany = (companyId) => {
    setSelectedCompany(companyId)
  }

  const onClickModalDone = async () => {
    setIsModalShown(false)
    if (callback) callback(selectedCompany)
  }

  const renderSelectCompanyModal = () => {
    const shownList = [myCompany, ...selectList.slice(1)]
    return (
      <ContentModal
        title="광고주 선택하기"
        isShown={isModalShown}
        onClose={onClickModalDone}
      >
        <div className="flex flex-col items-center pl-4 pr-4 company-list">
          <div className="close-button" onClick={onClickClose}>
            <img src={imgClose} alt="닫기" />
          </div>
          {shownList.map((item, i) => {
            let className = 'flex items-center justify-center cursor-pointer company-item'
            if (selectedCompany === item.value) {
              className += ' active'
            }
            return (
              <div
                key={i}
                className={className}
                onClick={() => onClickCompany(item.value)}
              >
                {item.title}
              </div>
            )
          })}
        </div>
      </ContentModal>
    )
  }

  return { onClickCreateCampaign, renderSelectCompanyModal }
}

export default useSelectCompanyModal
