/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import _ from 'lodash'

import { getProducts } from '../../../services/api'
import Radio from '../../../components/radio'
import { PRODUCT_TYPE, PRODUCT_NAME } from '../../../services/constants'
import AppContext from '../../../services/context'
import { FormCheckbox } from '../../../components/checkbox'
//
const radioGroupName = 'select_product_type'
const productIdName = 'ad_campaign_product_id'
const productMultiplierName = 'ad_campaign_product_multiplier'
const productName = 'productName'
const additionalOptionName = 'additional_option'
//
const ProductSelector = () => {
  const { appState } = React.useContext(AppContext)
  const { getValues, setValue, ...methods } = useFormContext()
  const [products, setProducts] = React.useState([])
  const [selectedProduct, setSelectedProduct] = React.useState(null)

  const selectedType = useWatch({ name: radioGroupName, control: methods.control })
  const additionalOption = useWatch({ name: additionalOptionName, control: methods.control })
  //
  React.useEffect(() => {
    if (additionalOption && products.length > 0 && !selectedProduct) {
      const [product] = products
      const { key, options } = product
      setValue(radioGroupName, key)
      setSelectedProduct(options[0])
    } else if (!additionalOption) {
      setValue(radioGroupName, null)
      setValue(productIdName, null)
      setValue(productMultiplierName, 1)
      setValue(productName, '추가 옵션 없음')
      setSelectedProduct(null)
    }
  }, [additionalOption, products, selectedProduct])
  //
  React.useEffect(() => {
    getProducts().then(async (data) => {
      if (!data) return
      await setProducts(_.chain(data).groupBy('type').map((arr, key) => ({
        key,
        options: arr,
      })).value())
      if (appState.adCampaign?.step1?.ad_campaign_product) {
        setValue(additionalOptionName, true)
        setValue(
          radioGroupName,
          appState.adCampaign.step1.ad_campaign_product.type,
          { shouldValidate: true },
        )
        setValue('active_product', 'true')
        setSelectedProduct(appState.adCampaign.step1.ad_campaign_product)
      }
    })
  }, [])
  //
  React.useEffect(() => {
    if (selectedProduct) {
      setValue(productIdName, selectedProduct._id)
      setValue(productMultiplierName, selectedProduct.multiplier)
      setValue(productName, `${PRODUCT_NAME[selectedProduct.type]}: ${selectedProduct.name}`)
    } else {
      setValue(productIdName, null)
      setValue(productMultiplierName, 1)
      setValue(productName, '추가 옵션 없음')
    }
  }, [selectedProduct])
  //
  const onClickType = React.useCallback(
    (e) => {
      setValue(radioGroupName, e.target.value)
      if (e.target.value !== selectedType) {
        const product = products.find((item) => item.key === e.target.value)?.options?.[0]
        setSelectedProduct(product)
      }
    },
    [products, selectedType],
  )
  //
  const onClickOption = React.useCallback(
    (e) => {
      const { key, options } = products.find((item) => item.key === selectedType)
      const product = options.find((option) => option._id === e.target.value)
      setSelectedProduct(product)
      setValue(productName, `${PRODUCT_NAME[key]}: ${product.name}`)
    },
    [products, selectedType],
  )
  //
  const renderOptions = () => {
    if (selectedType === PRODUCT_TYPE.NORMAL || products.length === 0) return null
    const selectedProductType = products.find((item) => item.key === selectedType) || products[0]
    const { options } = selectedProductType
    return (
      <div className="ml-8 mb-4">
        {options.map((option) => (
          <Radio
            key={option._id}
            value={option._id}
            className="font-[500]"
            {...methods.register(productIdName)}
            onSelect={onClickOption}
            disabled={!additionalOption}
          >
            {option.name}
            {(option.multiplier && option.multiplier > 1) && (
              <span className="ml-2 text-xs text-[#f00] font-medium">
                {`* ${(Math.round(option.multiplier * 100)) - 100}% 할증`}
              </span>
            )}
          </Radio>
        ))}
      </div>
    )
  }

  return (
    <div
      className="create-ad-part map-selector "
      style={{ paddingTop: '40px', paddingBottom: '20px' }}
    >
      <div>
        <FormCheckbox
          name="additional_option"
          className="mb-4"
          textClassName="text-[1.125rem]"
        >
          추가 옵션
        </FormCheckbox>
        <div>
          {/* <h4 className="mt-4">옵션을 선택해주세요</h4> */}
          <div className="flex gap-8 pb-2 mb-3 border-b border-line">
            {products.map((item) => (
              <Radio
                key={item.key}
                className="font-[500]"
                {...methods.register(radioGroupName)}
                value={item.key}
                onSelect={onClickType}
                disabled={!additionalOption}
              >
                {PRODUCT_NAME[item.key]}
              </Radio>
            ))}
          </div>
          {renderOptions()}
          {selectedProduct?.desc && (
            <div className="note">
              <span>추가 옵션 설명</span>
              <span className="text-textSecondary whitespace-pre-wrap">
                {selectedProduct?.desc}
              </span>
            </div>
          )}
          {(!additionalOption && products.length > 0) && (
            <div className="note">
              <span>추가 옵션 설명</span>
              <span className="text-textSecondary whitespace-pre-wrap">
                {products[0].options[0].desc}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductSelector
